(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverModuleStories",
    app.collections.BaseCollection.extend({
      model: app.models.DiscoverModuleStoryItem,

      arrayKey: "stories"
    })
  );
})(window, _, wattpad, window.app);
