import libraryManagementList from "core/templates/mixins/library-management-list.handlebars";
import listsModal from "core/templates/mixins/lists-modal.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  /*
  Modal - Batch add stories to other reading lists.
  */

  app.add(
    "LibraryBatchAddStories",
    app.views.Base.extend({
      templates: {
        modal: listsModal,
        item: libraryManagementList
      },

      events: {
        "tap .on-modify-list": "onModifyList",
        "click .on-modify-list": "stopEvent",

        "tap .fa-remove-alt": "onDestroyModal",
        "click .fa-remove-alt": "stopEvent"
      },

      initialize: function(options) {
        var self = this;
        options = options || {};

        this.stories = options.stories;
        this.currentListId = options.currentListId;
        this.readingLists = new app.collections.ReadingLists([], {
          username: wattpad.utils.currentUser().get("username"),
          limit: 30
        });

        Promise.resolve(this.readingLists.fetchNextSet()).then(function() {
          self.render();
        });
      },

      onDestroyModal: function() {
        this.$(".modal").modal("hide");
        $(".modal-backdrop").remove();
        this.remove();
        Backbone.View.prototype.remove.call(this);
      },

      render: function() {
        this.$el.html(this.templates.modal());
        var $list = this.$(".lists-menu");

        _.each(
          this.readingLists.models,
          function(readingList) {
            if (readingList.get("id") !== this.currentListId) {
              var data = {
                id: readingList.get("id"),
                name: readingList.get("name"),
                hideStatus: false
              };
              $list.append(this.templates.item(data));

              this.listenTo(readingList, "stories:adding", function(e) {
                this.onListAdding(e);
              });

              this.listenTo(readingList, "stories:added", function(e) {
                this.onListAdded(e);
              });
            }
          },
          this
        );

        this.$(".modal").modal("show");

        return this;
      },

      onModifyList: function(e) {
        var listId = $(e.currentTarget).data("list-id"),
          readingList = this.readingLists.get(listId),
          stories = [],
          self = this;

        _.each(this.stories, function(storyId) {
          stories.push(
            new app.models.StoryModel({
              id: storyId
            })
          );
        });

        readingList.trigger("stories:adding", e.currentTarget);

        readingList.stories().add(stories, {
          success: function() {
            readingList.trigger("stories:added", e.currentTarget);
            app.local.clear(readingList.resource());
            app.local.clear(readingList.stories().resource());
          },
          error: function() {
            window.alert(
              wattpad.utils.trans(
                "The stories could not be added. Please contact Wattpad support to resolve this problem."
              )
            );
            $(e.currentTarget)
              .children(".status")
              .addClass("hidden");
          }
        });
      },

      onListAdded: function(currentTarget) {
        var $listStatus = $(currentTarget).children(".status");
        $listStatus.children(".spinner").addClass("hidden");
        $listStatus.children(".check").removeClass("hidden");
        $listStatus.parent().addClass("disabled");
      },

      onListAdding: function(currentTarget) {
        var $listStatus = $(currentTarget).children(".status");
        $listStatus.children(".spinner").removeClass("hidden");
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
