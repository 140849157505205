var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"report-reason radio\">\n              <label><input type=\"radio\" class=\"on-option-selected\" name=\"report-reason-option\"\n                            value=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-require-detail=\""
    + alias4(((helper = (helper = helpers.moreDetail || (depth0 != null ? depth0.moreDetail : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"moreDetail","hash":{},"data":data}) : helper)))
    + "\" data-intext=\""
    + alias4(((helper = (helper = helpers.intext || (depth0 != null ? depth0.intext : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"intext","hash":{},"data":data}) : helper)))
    + "\" data-tag=\""
    + alias4(((helper = (helper = helpers.tag || (depth0 != null ? depth0.tag : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"tag","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</label>\n            </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.extraOption : depth0),{"name":"each","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "            <div class=\""
    + alias1(container.lambda((depths[1] != null ? depths[1].tag : depths[1]), depth0))
    + " option checkbox hide\">\n              <label><input data-id=\""
    + alias1(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-checktag=\""
    + alias1(((helper = (helper = helpers.checktag || (depth0 != null ? depth0.checktag : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"checktag","hash":{},"data":data}) : helper)))
    + "\" data-nochecktag=\""
    + alias1(((helper = (helper = helpers.nochecktag || (depth0 != null ? depth0.nochecktag : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"nochecktag","hash":{},"data":data}) : helper)))
    + "\" type=\"checkbox\">"
    + alias1(((helper = (helper = helpers.text || (depth0 != null ? depth0.text : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"text","hash":{},"data":data}) : helper)))
    + "</label>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "          <div class=\"requester-contact-info hidden\">\n            <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please leave your contact information so that we may reach you.",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            <div class=\"form-group has-feedback\" data-attr=\"name\" id=\"requester-name\">\n              <label "
    + ((stack1 = (helpers.supportsPlaceholder || (depth0 && depth0.supportsPlaceholder) || alias2).call(alias1,(depth0 != null ? depth0.device : depth0),{"name":"supportsPlaceholder","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " for=\"requester-name\">Username</label>\n              <input class=\"form-control\" name=\"report-name\" type=\"text\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Name",{"name":"trans","hash":{},"data":data}))
    + "\">\n              "
    + ((stack1 = (helpers.validationElements || (depth0 && depth0.validationElements) || alias2).call(alias1,16,{"name":"validationElements","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"form-group has-feedback\" data-attr=\"email\" id=\"requester-email\">\n              <label "
    + ((stack1 = (helpers.supportsPlaceholder || (depth0 && depth0.supportsPlaceholder) || alias2).call(alias1,(depth0 != null ? depth0.device : depth0),{"name":"supportsPlaceholder","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " for=\"requester-email\">Username</label>\n              <input class=\"form-control\" name=\"report-email\" type=\"email\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Email",{"name":"trans","hash":{},"data":data}))
    + "\">\n              "
    + ((stack1 = (helpers.validationElements || (depth0 && depth0.validationElements) || alias2).call(alias1,16,{"name":"validationElements","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "class=\"sr-only\"";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "<div class=\"modal-dialog modal-sm\">\n\n  <div class=\"modal-content\"> \n    <div class=\"modal-header\">\n      <h3 class=\"modal-title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n      <div class=\"btn-prev on-prev hidden\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-left",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n      <div class=\"close top-right on-close\" data-dismiss=\"modal\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </div>\n    <div class=\"modal-body\">\n      <form data-ticket-form-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"><!-- inline-block fix\n     --><div class=\"modal-body-content step-1\">\n          <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Select a reason",{"name":"trans","hash":{},"data":data}))
    + "</span>\n          <div class=\"form-group\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.offenses : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div><!--\n\n     --><div class=\"modal-body-content step-1\"  \n          <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reason:",{"name":"trans","hash":{},"data":data}))
    + " <strong class=\"reason-selected\"></strong></span>\n          <div class=\"extra-option\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.offenses : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n          <textarea class=\"form-control details-input\" role=\"textbox\" aria-multiline=\"true\" placeholder=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please provide more details",{"name":"trans","hash":{},"data":data}))
    + "\"></textarea>\n        </div><!--\n     --><div class=\"modal-body-content step-1\">\n";
  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.noop,"inverse":container.program(6, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "          <div class=\"report-success faded\">\n            <h4>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Report submitted",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n            <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Thanks for taking time to support a positive Wattpad community.",{"name":"trans","hash":{},"data":data}))
    + "</span>\n          </div>\n        </div><!--\n   --></form>\n    </div>\n    <div class=\"modal-footer\">\n      <button class=\"btn btn-orange btn-next on-next\" disabled=\"true\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Submit a report",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});