"use strict";

const isServer = require("../is-server");
const isBackboneModel = require("../is-backbone-model");

/**
 * sets props in the model for use in react components
 * @param {object} model - the view's model (client) / the data object (server)
 * @param {string} name - name of the props, matching the name being passed into the react component
 * @param {object} props - the props to pass in
 */
module.exports = function(model, name, props) {
  const modelProps = { hash: { model: props } };
  if (isServer() || !isBackboneModel(model)) {
    model[name] = modelProps;
  } else {
    model.set(name, modelProps);
  }
};
