"use strict";

const { createUniqueAdId, getAd } = require("@wattpad/client-platform-web-ads");

/**
 * Prepends the library ad container and calls the appropriate function to display the ad (backbone only)
 * @param {object} element - jQuery reference to the view's element
 * @param {boolean} isDesktop - Indicates whether or not the user is on desktop
 */
module.exports = function(element, isDesktop) {
  const placement = isDesktop ? "library_banner" : "library_banner_mweb";
  const containerClassName = isDesktop
    ? "library-ad-container"
    : "library-ad-container-mweb";

  const uniquePlacement = createUniqueAdId(placement);
  element
    .find(".collection:last")
    .prepend(
      '<div class="' +
        containerClassName +
        '"> <div id="' +
        uniquePlacement +
        '" /> </div>'
    );
  getAd({
    placement: uniquePlacement,
    testGroups: wattpad.testGroups,
    context: { user: wattpad.utils.currentUser().attributes },
    deviceType: app.get("device"),
    userGeo: wattpad.userCountryCode
  });
};
