var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "      <div class=\"wattpad-empty\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Conversation has been disabled on this profile.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "      <section id=\"profile-messages\">"
    + ((stack1 = ((helper = (helper = helpers.messagesContent || (depth0 != null ? depth0.messagesContent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"messagesContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"col-sm-8 col-lg-7\" id=\"profile-message-wrapper\">\n"
    + ((stack1 = (helpers.isWattpad || (depth0 && depth0.isWattpad) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),{"name":"isWattpad","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>";
},"useData":true});