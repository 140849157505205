var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"paywall-container\">\n            "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"StoryPartPaywall",(depth0 != null ? depth0.id : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"container"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBonusPart : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n        <div class=\"row part-content\">\n            <div class=\""
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isBonusPart : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + " part-col part-navigation\">\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.nextPart : depth0)) != null ? stack1.isBlocked : stack1),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"row part-content\">\n            <div class=\"col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-3 part-col\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_actions"],depth0,{"name":"core.story_reading.part_actions","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n\n        <div class=\"row part-content part-comments\">\n            <div class=\"col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-3 part-col\">\n                "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"StoryPartComments","story-part",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-4 col-md-offset-0 col-lg-3 col-lg-offset-0 comments right-rail\">\n                <div class=\"reading-widget comments-ad-unit\">\n                    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"AdContainer","comments-ad",(depth0 != null ? depth0.commentsAdProps : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.anonymousUser : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"AdContainer","reading-bottom",(depth0 != null ? depth0.readingBottomProps : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " footer-bonus-part";
},"6":function(container,depth0,helpers,partials,data) {
    return "col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-3";
},"8":function(container,depth0,helpers,partials,data) {
    return "col-sm-6 col-sm-offset-3";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"StoryPartNavigation","story-part-navigation",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isMicroPart : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"hidden container promoted-stories-container similar-stories-footer\">\n                    <div class=\"row\">\n                        <div class=\"discover-rows\">\n                            <div id=\"similar-stories\" class=\"promoted-stories\">\n                                <div class=\"section-heading\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Promoted stories",{"name":"trans","hash":{},"data":data}))
    + "</div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <div class=\"hidden container similar-stories-container similar-stories-footer\">\n                    <div class=\"row\">\n                        <div class=\"discover-rows\">\n                            <div id=\"similar-stories\" class=\"similar-stories\">\n                                <div class=\"section-heading\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You'll also like",{"name":"trans","hash":{},"data":data}))
    + "</div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"part-recommendations-container\">\n            <div class=\"row\">\n                <div class=\"col-xs-10 col-xs-offset-1 col-sm-8 col-sm-offset-2 col-md-11 col-md-offset-1 col-lg-11 col-lg-offset-1 discover-rows\">\n                    <div class=\"collection\">\n                        <div class=\"discover-module promoted-recommended-stories-view hidden\"></div>\n                        <div class=\"colWrap no-promoted-section\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_recommendations"],depth0,{"name":"core.story_reading.part_recommendations","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n            </div>\n        </div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"bottom-ad-unit-wrapper\">\n        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"AdContainer","reading-bottom",(depth0 != null ? depth0.readingBottomProps : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n    \n\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.nextPart : depth0)) != null ? stack1.isBlocked : stack1),{"name":"unless","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"container\">\n        <div class=\"row part-content\">\n            <div class=\"col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_actions"],depth0,{"name":"core.story_reading.part_actions","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n    <div class=\"container\">\n        <div class=\"row part-content\">\n            <div class=\"col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3\">\n                "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"StoryPartComments","story-part",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.anonymousUser : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"row part-content\">\n            <div class=\"col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3\">\n                "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"StoryPartNavigation","story-part-navigation",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <div class=\"hidden promoted-stories-container\">\n            <h4 class=\"section-heading\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Promoted stories",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n            <div id=\"similar-stories\" class=\"promoted-stories\"></div>\n        </div>\n        <div class=\"hidden similar-stories-container\">\n            <h4 class=\"section-heading\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You'll also like",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n            <div id=\"similar-stories\" class=\"similar-stories\"></div>\n        </div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"part-recommendations-container promoted-recommended-stories container hidden\">\n            <div class=\"row\">\n                <div class=\"col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0 discover-rows\">\n                    <div class=\"collection\">\n                        <div class=\"discover-module promoted-recommended-stories-view\"></div>\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class=\"part-recommendations-container container\">\n            <div class=\"row\">\n                <div class=\"col-sm-10 col-sm-offset-1 col-md-12 col-md-offset-0 discover-rows\">\n                    <div class=\"collection\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_recommendations"],depth0,{"name":"core.story_reading.part_recommendations","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n            </div>\n        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<footer>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.nextPart : depth0)) != null ? stack1.isBlocked : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</footer>\n";
},"usePartial":true,"useData":true});