var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"ios-webview-padding\"";
},"3":function(container,depth0,helpers,partials,data) {
    return "          "
    + container.escapeExpression((helpers.connectImage || (depth0 && depth0.connectImage) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"wattpad-presents/MW-Banner@2x.png","Wattpad Presents",{"name":"connectImage","hash":{"klass":"logo"},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "          "
    + container.escapeExpression((helpers.connectImage || (depth0 && depth0.connectImage) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"wattpad-presents/DW-Banner@2x.png","Wattpad Presents",{"name":"connectImage","hash":{"klass":"logo"},"data":data}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " centre-one";
},"9":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n              <div class=\"video-module col-sm-12 col-xs-12"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depths[1] != null ? depths[1].numVideos : depths[1]),1,{"name":"ifequal","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                  "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depths[1] != null ? depths[1].numVideos : depths[1]),2,{"name":"ifequal","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depths[1] != null ? depths[1].numVideos : depths[1]),3,{"name":"ifequal","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <div class=\"video-story-meta\" id=\"story-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                  <div class=\"story-cover pull-left\">\n                    <img src=\""
    + alias4((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),14,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " by "
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n                  </div>\n                  <div class=\"story-title\">"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),45,false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</div>\n                  <span class=\"genre\">"
    + alias4(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</span>\n                  <a class='btn btn-link btn-xxs read-story pull-right' href=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n                    <span>Read story</span>\n                  </a>\n                </div>\n                <div id=\"player-"
    + alias4(((helper = (helper = helpers.videoId || (depth0 != null ? depth0.videoId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data}) : helper)))
    + "\"></div>\n              </div>\n\n";
},"10":function(container,depth0,helpers,partials,data) {
    return " col-md-12 col-lg-12";
},"12":function(container,depth0,helpers,partials,data) {
    return " col-md-6 col-lg-6";
},"14":function(container,depth0,helpers,partials,data) {
    return " col-md-4 col-lg-4";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"wattpad-presents\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.iosWebView : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n      <div class=\"container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n        <h1>Short videos based on popular Wattpad stories!</h1>\n\n        <div class=\"row\">\n          <div class=\"videos"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.numVideos : depth0),1,{"name":"ifequal","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.videoStoryPairs : depth0),{"name":"each","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"useData":true,"useDepths":true});