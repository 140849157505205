var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<br>\n<div>"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-lock",36,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n<h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Private Profile",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n<div class=\"disclaimer\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This profile is private. You need to follow this user to see their information.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"useData":true});