import values from "core/templates/getmobile/values.hbs";
import getmobile from "platform/getmobile/templates/getmobile.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "GetMobile",
    app.views.Base.extend({
      id: "get-mobile-page",
      template: getmobile,

      events: {
        // links
        "tap   .on-login": "onAuthPrompt",
        "click .on-login": "stopEvent",

        "tap   .on-signup": "onNavigatePage",
        "click .on-signup": "stopEvent",

        "tap   .on-password-forgot": "onNavigatePage",
        "click .on-password-forgot": "stopEvent",

        "tap    .on-pw-toggle": "onPasswordToggle",
        "click  .on-pw-toggle": "stopEvent",

        "submit #send-link": "onSendAppLink"
      },

      initialize: function(options) {
        this.timeout = null;
        this.deviceType =
          app.get("device") && app.get("device").is.mobile
            ? "mobile"
            : "desktop";

        // Set up for the auth prompt mixin, which can't be overridden as static properties
        this.title = wattpad.utils.trans("Log in to Wattpad");
      },

      render: function(options) {
        var locale,
          languages,
          interfaceLang,
          device,
          data = {};

        options = options || {};
        device = app.get("device");
        locale = wattpad.utils.getCookie("locale");
        languages = app.get("supported-languages");

        if (!locale || locale.trim().length === 0) {
          locale = "en_US"; //default to english
        }
        interfaceLang = languages.where({ locale: locale })[0].toJSON();

        data = {
          downloadAppUrl: wattpad.utils.getAppUrl("getmobile"),
          appUrl: wattpad.utils.getAllAppUrls("getmobile"),
          googlePlayImage: this._googlePlayImage(interfaceLang.code),
          languageCode: interfaceLang.code,
          device: device
        };

        if (device.is.ios) {
          data.ratingsText = wattpad.utils.trans("84,000+ 5 star ratings");
          data.appStoreImage = "homepages/appstore_dark.png";
          data.mobileOS = "iOS";
        } else if (device.is.windows) {
          data.ratingsText = "";
          data.appStoreImage = "homepages/windows_appstore.png";
          data.mobileOS = "windows";
        } else if (device.is.huawei) {
          data.ratingsText = "";
          data.appStoreImage = "homepages/huawei_appgallery_dark.png";
          data.mobileOS = "huawei";
        } else {
          data.ratingsText = wattpad.utils.trans("1,000,000+ 5 star ratings");
          data.appStoreImage = data.googlePlayImage;
          data.mobileOS = "Android";
        }

        Handlebars.registerPartial("core.getmobile.values", values);

        this.$el.html(this.template(data));
        return this;
      },

      onPasswordToggle: function(evt) {
        var $switch = $(evt.currentTarget),
          $pwInput = $switch.siblings("input"),
          type = $pwInput.attr("type");

        window.clearTimeout(this.timeout);

        if (type === "password") {
          $pwInput.attr("type", "text").focus();
          $switch.addClass("active");
        }

        if (type === "text") {
          $pwInput.attr("type", "password").focus();
          $switch.removeClass("active");
        }
      },

      onSendAppLink: function(evt) {
        var $input = $("#send-link #sendTo");

        // Give the validation a chance to run
        _.delay(function() {
          if ($input.parent().hasClass("valid")) {
            var $button = $("#send-link .on-send-app-link");
            $button.addClass("sending");
            Promise.resolve(
              $.ajax({
                type: "POST",
                url: "/apiv2/send_download_link",
                data: { to: $input.val() }
              })
            )
              .then(function() {
                $button.removeClass("sending");
                $button.addClass("sent");
                _.delay(function() {
                  $button.removeClass("sent");
                }, 5000);
              })
              ["catch"](function(resp) {
                // todo: error message
                $button.removeClass("sending");
                window.alert(wattpad.utils.trans(resp));
              });
          }
        }, 200);
        wattpad.utils.stopEvent(evt);
      },

      _googlePlayImage: function googlePlayImage(code) {
        switch (code.toLowerCase()) {
          case "ar":
            return "homepages/googleplay/ar.png";
          case "cs":
            return "homepages/googleplay/cs.png";
          case "de":
            return "homepages/googleplay/de.png";
          case "es":
            return "homepages/googleplay/es.png";
          case "fr":
            return "homepages/googleplay/fr.png";
          case "id":
            return "homepages/googleplay/id.png";
          case "it":
            return "homepages/googleplay/it.png";
          case "pl":
            return "homepages/googleplay/pl.png";
          case "pt":
            return "homepages/googleplay/pt.png";
          case "pt_pt":
            return "homepages/googleplay/pt.png";
          case "pt_br":
            return "homepages/googleplay/pt.png";
          case "ro":
            return "homepages/googleplay/ro.png";
          case "ru":
            return "homepages/googleplay/ru.png";
          case "sk":
            return "homepages/googleplay/sk.png";
          case "sv":
            return "homepages/googleplay/sv.png";
          case "tr":
            return "homepages/googleplay/tr.png";
          case "vi":
            return "homepages/googleplay/vi.png";

          case "en":
          /* falls through */
          default:
            return "homepages/googleplay/en.png";
        }
      }
    })
  );

  app.mixin(app.views.GetMobile, "ValidationView", "AuthPromptManagement");
})(window, _, wattpad, window.app);
