import addToLibraryDropdown from "core/templates/components/add-to-library-dropdown.hbs";
import addToLibraryModal from "core/templates/components/add-to-library-modal.hbs";
import browseStoryPreview from "core/templates/item-views/browse-story-preview.hbs";

(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "BrowseStoryPreview",
    app.views.Base.extend({
      template: browseStoryPreview,

      className: "browse-preview",

      events: {
        "tap .on-cover": "onCover",
        "tap .on-start-reading": "onStartReading",
        "tap .on-story-details": "onStoryDetails",
        "click .on-story-details": "stopEvent",
        keyup: "onKeyUp"
      },

      initialize: function(options) {
        options = options || {};
        this.isHome = options && options.isHome;
        this.showTagRankings = options && options.showTagRankings;

        _.bindAll(this, "clearLists");

        this.listenTo(
          app,
          "app:component:TagMetaItem:click",
          this.navigateToTagPage
        );
        this.listenTo(
          app,
          "app:component:TagMetaNumNotShown:click",
          this.onStoryDetails
        );

        this.adjustReadButtonCopy();

        var device = app.get("device");
        if (device.is.mobile) {
          Handlebars.registerPartial(
            "core.components.add_to_library_dropdown",
            function() {
              return "";
            }
          );
          Handlebars.registerPartial(
            "core.components.add_to_library_modal",
            addToLibraryModal
          );
        } else {
          Handlebars.registerPartial(
            "core.components.add_to_library_dropdown",
            addToLibraryDropdown
          );
          Handlebars.registerPartial(
            "core.components.add_to_library_modal",
            function() {
              return "";
            }
          );
        }
      },

      render: function() {
        var templateData = this.model.toJSON();

        templateData.tagMeta = { tags: templateData.tags };
        templateData.showTagRankings =
          templateData.tagRankings &&
          templateData.tagRankings.length &&
          this.showTagRankings;

        this.$el.html(this.template(templateData));
        return this;
      },

      adjustReadButtonCopy: function() {
        var self = this;
        var isPaywalled = this.model.get("isPaywalled");

        if (isPaywalled === false) {
          return;
        }

        // For a story that is paid but not yet purchased, the "Read"
        // button should instead show "Read now".
        var storyId = this.model.get("id"),
          parts = this.model.get("parts") || [];

        if (parts.length === 0) {
          // Some models don't have the parts list. We can't properly get
          // metadata and decide which text to show without the parts list,
          // so just bail out.
          return;
        }

        window.store
          .dispatch(
            window.app.components.actions.fetchPaidContentMetadata(
              storyId,
              parts
            )
          )
          .then(() => {
            const { paidMetadata } = window.store.getState();
            if (
              paidMetadata[storyId].story.isPaidPreview &&
              wattpad.utils.isPaidStory(self.model)
            ) {
              this.$(".on-start-reading").text(wattpad.utils.trans("Read now"));
            }
          });
      },

      onAddedToLibrary: function(storyId) {
        if (this.isHome) {
          window.te.push("event", "modal", "story_details", "library", "add", {
            page: this.getPage(),
            story_id: this.model.get("id")
          });
        }

        var $button = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $button.addClass("btn-teal").removeClass("btn-orange");
        $button
          .children(".fa")
          .removeClass("fa-plus")
          .addClass("fa-check");
        $button.children(".text").text(wattpad.utils.trans("In Library"));
      },

      onRemovedFromLibrary: function(storyId) {
        var $button = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $button.addClass("btn-orange").removeClass("btn-teal");
        $button
          .children(".fa")
          .addClass("fa-plus")
          .removeClass("fa-check");
        $button.children(".text").text(wattpad.utils.trans("Add to Library"));
      },

      onStoryDetails: function(evt) {
        if (this.isHome) {
          window.te.push(
            "event",
            "modal",
            "story_details",
            "details",
            "click",
            {
              page: this.getPage(),
              story_id: this.model.get("id")
            }
          );
        }

        app.router.navigate(
          wattpad.utils.formatStoryUrl(this.model.get("url")),
          {
            trigger: true
          }
        );
      },

      onStartReading: function(evt) {
        // WEB-5789 Double modal bugfix: on mobile, adding a story to a reading list opens a second modal and closing that second modal
        // will remove 'modal-open' on body (although the first modal is still open).
        // Without 'modal-open' the extra backdrop will remain when we navigate to the story page.
        $(document.body).addClass("modal-open");

        if (this.isHome) {
          window.te.push("event", "modal", "story_details", "read", {
            page: this.getPage(),
            story_id: this.model.get("id")
          });
        }

        var storyFirstPartId = this.model.get("firstPartId");
        var storyParts = this.model.get("parts");

        // For viewing drafts on your own profile
        if (storyFirstPartId === null && !_.isEmpty(storyParts)) {
          app.router.navigate(wattpad.utils.formatStoryUrl(storyParts[0].url), {
            trigger: true
          });
        } else {
          Promise.resolve(
            $.get("/apiv2/syncreadingposition", {
              story_id: storyFirstPartId
            })
          ).then(function(data) {
            var part = _.where(storyParts, {
              id: parseInt(data.part, 10)
            });
            if (part[0]) {
              app.router.navigate(wattpad.utils.formatStoryUrl(part[0].url), {
                trigger: true
              });
            } else {
              app.router.navigate("/" + storyFirstPartId, {
                trigger: true
              });
            }
          });
        }
      },

      onAuthPrompt: function() {
        $("#preview-modal").modal("hide");
      },

      onKeyUp: function(event) {
        // close modal on escape
        if (event.which === 27) {
          $("#preview-modal").modal("hide");
        }
      },

      onCover: function(event) {
        if (this.isHome) {
          window.te.push("event", "modal", "story_details", "cover", "click", {
            page: this.getPage(),
            story_id: this.model.get("id")
          });
        }
      },

      getPage: function() {
        var page = document.baseURI;

        // ie11 does not support document.baseURI
        if (page === undefined) {
          page = document.URL;
        }

        if (page.indexOf("tags/") > -1) {
          return "tag page";
        } else if (page.indexOf("home") > -1) {
          return "home";
        } else {
          return "browse page";
        }
      },

      // For the StoryInListsManagement mixin
      onAddToListsReady: function($btn, storyId) {
        var device = app.get("device");
        if (device.is.mobile) {
          this.$(".addToLibraryModal[data-story-id='" + storyId + "']").modal({
            show: true
          });
        } else {
          this.clearLists();

          var btnGrp = $btn.parent(".button-group");

          btnGrp.addClass("open");
          $btn.removeClass("on-lists-add-clicked");
          $btn.addClass("on-dismiss-lists");

          $(window.document).one("click.bs.dropdown.data-api", this.clearLists);
        }
      },

      clearLists: function(event) {
        var listClicked = false;

        if (event && event.which === 3) {
          return false;
        }

        var $el = null;
        if (event) {
          $el = $(event.target);
        }

        var btns = $(".btn-story-lists");
        _.each(btns, function(btn) {
          var $btn = $(btn);
          if (!$el || $btn.parent(".button-group").find($el).length === 0) {
            $btn.parent(".button-group").removeClass("open");
            $btn.removeClass("on-dismiss-lists");
            $btn.addClass("on-lists-add-clicked");
          } else if ($el) {
            listClicked = true;
          }
        });

        if (listClicked) {
          $(window.document).one("click.bs.dropdown.data-api", this.clearLists);
        }
        return true;
      },

      onDismissLists: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id"),
          btn = this.$(this.addButton + "[data-story-id='" + storyId + "']"),
          btnGrp = btn.parent(".button-group");

        btnGrp.removeClass("open");
        btn.removeClass("on-dismiss-lists");
        btn.addClass("on-lists-add-clicked");
      },

      openModal: _.debounce(function() {
        this.$(
          ".browse-preview[data-story-id=" + this.model.get("id") + "]"
        ).modal({
          show: true
        });
      }, 50),

      navigateToTagPage: function(evt) {
        var tag = $(evt.currentTarget)
            .text()
            .trim(),
          urlPath = "/stories/";
        app.router.navigate(urlPath + encodeURIComponent(tag), {
          trigger: true
        });
      }
    })
  );

  app.mixin(app.views.BrowseStoryPreview, "StoryInListsManagement");
})(window, _, wattpad, window.app);
