var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<header>\n  <a class=\"on-user avatar avatar-sm\"\n     data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n     href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n    <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),32,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n  </a>\n  <div class=\"clearfix\">\n    "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"%s added",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\n    <a class=\"on-navigate\"\n      href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.url : stack1), depth0))
    + "\">\n      <strong>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1), depth0))
    + "</strong>\n    </a>\n    "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"to",{"name":"trans","hash":{},"data":data}))
    + "\n    <a class=\"on-navigate\"\n         href=\"/list/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.list : stack1)) != null ? stack1.id : stack1), depth0))
    + "\">\n       <strong>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.list : stack1)) != null ? stack1.name : stack1), depth0))
    + "</strong>\n    </a>\n  </div>\n  <time>"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"dropSuffix":true},"data":data}))
    + "</time>\n</header>\n\n<hr>\n\n"
    + ((stack1 = container.invokePartial(partials["core.events.partials.user_event_story_meta"],depth0,{"name":"core.events.partials.user_event_story_meta","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials["core.events.partials.user_event_footer"],depth0,{"name":"core.events.partials.user_event_footer","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});