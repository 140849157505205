import incrementalList from "platform/components/templates/incremental-list.hbs";
import profileActivity from "platform/profile/templates/profile-activity.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Profile sub view -- Activity
   * @Class ProfileActivity
   * @constructor
   */

  app.add(
    "ProfileInteractions",
    app.views.CoreProfileActivity.extend({
      // Properties
      // ----------
      template: profileActivity,
      partials: {
        "incremental-list": incrementalList
      },

      // Methods
      // -------
      //
      initialize: function(options) {
        options = options || {};
        var currentUser = wattpad.utils.currentUser();
        this.pinnedItem = options.pinnedItem || null;
        if (
          currentUser.authenticated() &&
          currentUser.get("username") === this.model.get("username")
        ) {
          options.placeholder = wattpad.utils.trans("Post a Message");
        } else {
          options.placeholder = wattpad.utils.trans(
            "Write to %s",
            this.model.get("username")
          );
        }

        Handlebars.registerPartial(
          "desktop.components.incremental_list",
          this.partials["incremental-list"]
        );

        app.views.CoreProfileActivity.prototype.initialize.apply(
          this,
          arguments
        );
      },

      onShowMore: function(event) {
        if (event && !this.registered) {
          this.registerForScrolling();
          this.registered = true;
        }

        app.views.CoreProfileActivity.prototype.onShowMore.apply(
          this,
          arguments
        );
      },

      getTemplateData: function() {
        var data = {
          count: this.collection.total,
          containerId: "content",
          hasMore: this.collection.hasMore,
          username: this.model.get("username"),
          pinnedItem: this.pinnedItem ? this.pinnedItem.toJSON() : false
        };
        return data;
      }
    })
  );

  app.mixin(app.views.ProfileInteractions, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
