const FORCED_SIGNUP_FIRST_SESSION = 5;
const FORCED_SIGNUP_LAST_SESSION = 9;

/**
 * Takes the amount of logged out sessions the user has had and determines if they're within the range to be targeted by our forced signup campaign.
 *
 * @param {Integer} loggedOutSessionCount value representing the current count of logged out sessions that the user has had. This is determined in
 * the forced-signup middleware.
 * @returns {Boolean}
 */
const isForcedSignupSession = (loggedOutSessionCount = 0) => {
  if (typeof loggedOutSessionCount !== "number") {
    throw new Error(
      "isForcedSignupSession: invalid loggedOutSessionCount arugment provided"
    );
  }
  return (
    loggedOutSessionCount >= FORCED_SIGNUP_FIRST_SESSION &&
    loggedOutSessionCount <= FORCED_SIGNUP_LAST_SESSION
  );
};

module.exports = {
  isForcedSignupSession,
  FORCED_SIGNUP_FIRST_SESSION,
  FORCED_SIGNUP_LAST_SESSION
};
