var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                  <button class=\"btn btn-block btn-glass btn-left-align on-read\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Continue Reading",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                  <button class=\"btn btn-block btn-glass  btn-left-align on-read\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Start Reading",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                  <button class=\"btn btn-block btn-glass btn-left-align on-unarchive-story\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Unarchive",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "                  <button class=\"btn btn-block btn-glass btn-left-align on-archive-story\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Archive",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "    <span class=\"percentage-read\">\n        <span class=\"percentage-position\" style=\"width:"
    + container.escapeExpression((helpers.calcPercentRead || (depth0 && depth0.calcPercentRead) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.parts : depth0),(depth0 != null ? depth0.readingPosition : depth0),{"name":"calcPercentRead","hash":{},"data":data}))
    + "%\"></span>\n    </span>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "          <span class=\"label label-primary\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Updated",{"name":"trans","hash":{},"data":data}))
    + "</span>\n\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "          <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n          <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda;

  return "\n<div class=\"on-story-preview cover cover-lg\">\n    <div class=\"fixed-ratio fixed-ratio-cover\">\n        <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),256,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\"\">\n\n        <div class=\"story-overlay on-story-hover\" style=\"display: none;\">\n            <div class=\"overlay-bg\"></div>\n\n            <div class=\"quick-controls clearfix\">\n                <button class=\"btn btn-text on-delete-story\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-trash",20,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n            </div>\n\n            <div class=\"overlay-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasStarted : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                <button class=\"btn btn-block btn-glass btn-left-align on-details\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Details",{"name":"trans","hash":{},"data":data}))
    + "</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.archived : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "                  <button data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-block btn-glass btn-left-align on-reading-list btn-story-lists on-lists-add-clicked\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add to List",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n\n        </div>\n    </div>\n</div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.archived : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"content\">\n    <div class=\"info\">\n        <strong>"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</strong>\n        <small>"
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</small>\n    </div>\n    <a href=\"/user/"
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm on-navigate\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%s's Profile",((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n        <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.avatar : stack1),32,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\" alt=\"\">\n    </a>\n\n    <div class=\"meta\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.updated : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});