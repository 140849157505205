var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "module-header-story-reading";
},"3":function(container,depth0,helpers,partials,data) {
    return "module-header";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "        <a class=\"avatar on-avatar on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" data-target='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.name : stack1), depth0))
    + "'>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.sponsorAvatar : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "        </a>\n\n        <button class='btn btn-white btn-follow btn-fan on-follow' data-target='"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.name : stack1), depth0))
    + "'>\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias4).call(alias3,"fa-follow",15,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            <span class=\"follow-text\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"Follow",{"name":"trans","hash":{},"data":data}))
    + "</span>\n        </button>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                <img src=\""
    + container.escapeExpression((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.sponsorAvatar : depth0),60,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <img src=\""
    + container.escapeExpression((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.avatar : stack1),60,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a class=\"module-title on-navigate\" href=\""
    + alias4(((helper = (helper = helpers.moduleLink || (depth0 != null ? depth0.moduleLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"moduleLink","hash":{},"data":data}) : helper)))
    + "\" data-target='"
    + alias4(((helper = (helper = helpers.moduleTarget || (depth0 != null ? depth0.moduleTarget : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"moduleTarget","hash":{},"data":data}) : helper)))
    + "'>\n            <span>"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "</span>\n        </a>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-right",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " ";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-right",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " ";
},"15":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <div class=\"module-title\">"
    + container.escapeExpression(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"title","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <div class=\"module-subtitle\">\n            "
    + container.escapeExpression(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "\n        </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"promotion-description\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-badge-promoted",13,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Promoted by %s",(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<b>%s</b>",(depth0 != null ? depth0.sponsorName : depth0),{"name":"sprintf","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "rtl";
},"23":function(container,depth0,helpers,partials,data) {
    return "ltr";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " invisible owl-carousel"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.turnOffLooping : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0),"inverse":container.program(28, data, 0),"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    return " no-loop";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isUsers : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"29":function(container,depth0,helpers,partials,data) {
    return " users";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.items : stack1)) != null ? stack1.lists : stack1),{"name":"each","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"discover-module-lists\">\n"
    + ((stack1 = container.invokePartial(partials["core.item_views.discover_module_lists"],depth0,{"name":"core.item_views.discover_module_lists","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.promotedByUser : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(38, data, 0),"data":data})) != null ? stack1 : "");
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression;

  return "\n            <div class=\"discover-module-stories-large promoted-blurb\">\n                <div class=\"user-description\">"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.description : stack1),{"name":"if","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n                <a href=\"/user/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-xs2 on-navigate\">\n                    <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || helpers.helperMissing).call(alias1,((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n                </a>\n                <a href=\"/user/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"user-name on-navigate\">@"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a>\n            </div>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.moduleUser : depth0)) != null ? stack1.description : stack1),{"name":"linkify","hash":{"sanitization":"unsanitize"},"data":data})) != null ? stack1 : "")
    + "...";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.smallStories : stack1),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(42, data, 0),"data":data})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.items : stack1)) != null ? stack1.stories : stack1),{"name":"each","hash":{},"fn":container.program(40, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"40":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"discover-module-stories-small\">\n"
    + ((stack1 = container.invokePartial(partials["core.item_views.discover_module_stories_small"],depth0,{"name":"core.item_views.discover_module_stories_small","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.items : stack1)) != null ? stack1.stories : stack1),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        ";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"discover-module-stories-large\">\n"
    + ((stack1 = container.invokePartial(partials["core.item_views.discover_module_stories_large"],depth0,{"name":"core.item_views.discover_module_stories_large","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fromStoryReading : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.moduleUser : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.moduleLink : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.subtitle : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.promoted : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div style=\"direction:"
    + ((stack1 = helpers["if"].call(alias1,(helpers.rtlLanguage || (depth0 && depth0.rtlLanguage) || alias2).call(alias1,(depth0 != null ? depth0.languageId : depth0),{"name":"rtlLanguage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "\"\n     class=\"collection"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isHome : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n     data-target=\""
    + container.escapeExpression(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.readingLists : stack1),{"name":"if","hash":{},"fn":container.program(31, data, 0),"inverse":container.program(34, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n<div id=\"preview-modal\"></div>\n";
},"usePartial":true,"useData":true});