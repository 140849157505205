(function(window, _, $, wattpad, utils, app) {
  "use strict";
  const MIN_USER_AGE = 13;
  app.add(
    "UserSettings",
    app.views.DummyReactView.extend({
      component: "UserSettings",

      componentId: "user-settings",
      reportModalContainer: ".mute-accounts-options-modal",
      modalTitle: wattpad.utils.trans("Report a User"),
      requestType: "reported_users",
      isMainReportModal: true,
      conductType: "user_account_conduct",

      constructor: function(options) {
        this.model = options.model;
        this.componentData = this.getComponentData();
        this.mutedUsers = options.mutedUsers;
        this.errorMessage = options.errorMessage;
        this.reportModalType = "user";
        this.reportModalId = {
          location: window.location.href
        };

        if (this.errorMessage) {
          alert(this.errorMessage);
        }
        options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      render: function() {
        this.componentData = this.getComponentData();
        app.views.DummyReactView.prototype.render.apply(this);
      },

      getComponentData: function() {
        var mutedUsers = _.map(this.mutedUsers, function(user) {
          return user.toJSON();
        });
        return {
          userObj: this.model.toJSON(),
          mutedUsers: mutedUsers,
          app: {
            deviceType: wattpad.utils.getDeviceType(),
            language: app.get("language"),
            languages: app.get("supported-languages").toJSON()
          },
          saveAvatarChanges: this.saveAvatarChanges.bind(this),
          saveBackgroundChanges: this.saveBackgroundChanges.bind(this),
          onSaveAccountChanges: this.onSaveAccountChanges.bind(this),
          openUserSettingsModal: this.openUserSettingsModal.bind(this),
          updateNotifications: this.updateNotifications.bind(this),
          renderTooltips: this.renderTooltips.bind(this),
          testGroups: wattpad.testGroups
        };
      },

      reportMoreInfo: function() {
        return [{ key: "Reported User", val: window.location.href }];
      },
      saveAvatarChanges: function(fileData) {
        var self = this,
          file;

        if (fileData) {
          self
            .getFile(fileData)
            ["catch"](function(e) {
              return Promise.reject();
            })
            .then(function(imageData) {
              file = imageData;
              return self.model.updateAvatar(imageData);
            })
            .then(function(response) {
              if (response.success) {
                self.model.set("avatar", file.data);
                self.render();
              }
            });
        }
      },

      saveBackgroundChanges: function(fileData) {
        var self = this,
          file;

        if (fileData) {
          self
            .getFile(fileData)
            ["catch"](function(e) {
              return Promise.reject();
            })
            .then(function(imageData) {
              file = imageData;
              return self.model.updateBackground(imageData);
            })
            .then(function(response) {
              if (response.success) {
                self.model.set("backgroundUrl", file.data);
                self.render();
              }
            });
        }
      },

      getFile: function(file) {
        var reader = new FileReader();

        var result = new Promise(function(resolve, reject) {
          // Check that there is a file
          if (file) {
            reader.onload = function(data) {
              // File was read successfully
              resolve({
                data: data.target.result,
                parsed: data.target.result.match(/;base64,(.+)$/)[1]
              });
            };

            reader.onerror = function() {
              // Bad File, rejected!
              reject({ code: 1, message: utils.trans("Error reading file") });
            };

            // Initiate file read
            reader.readAsDataURL(file);
          } else {
            // No File, rejected!
            reject({
              code: 0,
              message: utils.trans("Please select a file to upload")
            });
          }
        });

        return result;
      },

      renderTooltips: function() {
        var $betaTooltip = this.$("#beta-tooltip .fa-info"),
          $wtgTooltip = this.$("#wtg-tooltip .fa-info"),
          $personalDataTooltip = this.$("#info-tooltip .fa-info"),
          $dobTooltip = this.$("#dob-tooltip .fa-info"),
          betaText = utils.trans(
            "Check out what&#39;s coming next on Wattpad&#39;s website and help give feedback by joining our web beta group"
          ),
          wtgText = utils.trans(
            "Revert to the previous design for the Homepage, Genre pages, Library, Reading Lists and Discover/Browse"
          ),
          personalDataText = utils.trans(
            "Request an HTML or JSON file of the personal information you have stored on Wattpad"
          ),
          dobDataText = utils.trans(
            "You need to enter the date you were born. This information will only be visible to you and Wattpad’s Support teams."
          );

        $betaTooltip.tooltip({
          trigger: "click hover manual",
          title: betaText,
          container: "body",
          html: true
        });

        $wtgTooltip.tooltip({
          trigger: "click hover manual",
          title: wtgText,
          container: "body"
        });

        $personalDataTooltip.tooltip({
          trigger: "click hover manual",
          title: personalDataText,
          container: "body"
        });

        $dobTooltip.tooltip({
          trigger: "click hover manual",
          title: dobDataText,
          container: "body"
        });
      },

      onSaveAccountChanges: function(event, userData) {
        var updatedAttrs = {},
          originalAttrs = {},
          formArray = $(event.currentTarget).serializeArray(),
          self = this,
          errMessage = "";

        _.each(formArray, function(entry) {
          var key = entry.name,
            value = entry.value,
            modelVal = self.model.get(key);

          if (modelVal === undefined) {
            return;
          }

          switch (key) {
            case "birthdate":
              // validate date of birth
              if (value.length === 0) {
                errMessage = utils.trans(
                  "Please enter your birthdate (mm-dd-yyyy)"
                );
                return;
              } else if (moment().diff(value, "years") < MIN_USER_AGE) {
                errMessage = utils.trans(
                  "You must be at least 13 years old to join"
                );
                return;
              }

              modelVal = moment(modelVal).format("MM-DD-YYYY");
              value = moment(value).format("MM-DD-YYYY");
              break;
            case "language":
              value = parseInt(value, 10);
              break;
          }

          if (value !== modelVal) {
            if (typeof modelVal !== undefined || key === "gender") {
              originalAttrs[key] = modelVal;
              updatedAttrs[key] = value;
            }
          }
        });

        // display error message if applicable
        if (errMessage.trim().length !== 0) {
          const toast = new app.views.ErrorToast({ message: errMessage });
          toast.render();
          return;
        }

        _.each(userData, function(entry) {
          var key = entry.name,
            value = entry.value,
            modelVal = self.model.get(key);

          originalAttrs[key] = modelVal;
          switch (key) {
            case "show_name":
            case "appear_offline":
              updatedAttrs[key] = parseInt(value, 10);
              break;
            case "beta_enabled":
              updatedAttrs[key] = value === true;
              break;
          }
        });

        app.local.clear("user." + this.model.get("username"));
        this.model.set(updatedAttrs);

        // persist model state to server
        this.model
          .save(updatedAttrs, {
            patch: true,
            type: "put"
          })
          .success(function(response) {
            self.model.set(updatedAttrs);
            var toast = new app.views.ErrorToast({
              message: utils.trans("Your changes were successful!")
            });
            toast.render();
          })
          .fail(function(response, xhr) {
            // rollback changes
            self.model.set(originalAttrs);
            var toast = new app.views.ErrorToast({
              message:
                utils.trans(
                  "Something went wrong, your changes were not saved. "
                ) + (response.responseJSON.message || "")
            });
            toast.render();
          });
      },

      //onUpdate and updatedValue are only used when type is email, they are used to update the email shown in the user setting page
      openUserSettingsModal: function(type, onUpdate, updatedValue) {
        if (type === "email") {
          var modalOptions = {
            email: updatedValue,
            isChangeEmailModal: true,
            isSuccess: false,
            username: this.model.get("username"),
            onUpdateEmail: onUpdate
          };
          wattpad.utils.showChangeEmailModal(modalOptions);
        } else {
          var userSettingsModal = new app.views.UserSettingsModal({
            type: type,
            userId: this.model.get("id"),
            username: this.model.get("username"),
            name: this.model.get("name")
          });
          userSettingsModal.showModal();
        }
      },

      updateNotifications: function(event) {
        var self = this,
          data = $(event.currentTarget);

        wattpad.utils.stopEvent(event);

        this.model
          .updateNotifications(data)
          .then(function(response) {
            self.model.set("notificationSettings", {
              alerts: response.alerts,
              alertssub: response.alertssub,
              subscribe: response.subscribe
            });
            var toast = new app.views.ErrorToast({
              message: utils.trans("Your changes were successful!")
            });
            toast.render();
          })
          .catch(function(error) {
            var toast = new app.views.ErrorToast({
              message:
                utils.trans(
                  "Something went wrong, your changes were not saved. "
                ) + error
            });
            toast.render();
          });
      }
    })
  );

  app.mixin(
    app.views.UserSettings,
    "AuthPromptManagement",
    "FacebookConnect",
    "TwitterConnect",
    "ReportManagement",
    "ReportConduct"
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
