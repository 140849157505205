var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"collection\">\n    "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.requests : depth0)) != null ? stack1.html : stack1), depth0)) != null ? stack1 : "")
    + "\n</div>\n<div class=\"show-more center-text"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.requests : depth0)) != null ? stack1.hasMore : stack1),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <button class=\"on-showmore\">\n        <span class='show-more-message'>\n            <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n        </span>\n        <span class='loading-message hidden'>\n            "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n            <span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n        </span>\n    </button>\n</div>\n<div class=\"loading-spinner center-text hidden\">\n  "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0.device : depth0),{"name":"iconifySpin","hash":{},"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"useData":true});