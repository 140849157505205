var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"notification aggregate vote panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"on-expand\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"count\">"
    + alias4(((helper = (helper = helpers.children || (depth0 != null ? depth0.children : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"children","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"content\">\n            <div class=\"heading\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%s people voted on <strong>%s - %s</strong>",(depth0 != null ? depth0.children : depth0),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<time datetime=\""
    + alias4(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4((helpers.calendar || (depth0 && depth0.calendar) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n        </div>\n    </div>\n</article>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " unread";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=helpers.helperMissing;

  return "<article class=\"notification vote panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <a href=\"/user/"
    + alias2(container.lambda(((stack1 = (depth0 != null ? depth0.voter : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm2 on-navigate\">\n        <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias3).call(alias1,((stack1 = (depth0 != null ? depth0.voter : depth0)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <a href=\""
    + alias2((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias3).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.part : stack1)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-notification-click content\">\n        <div class=\"heading\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias3).call(alias1,"<strong>%s</strong> voted on <strong>%s - %s</strong>",((stack1 = (depth0 != null ? depth0.voter : depth0)) != null ? stack1.name : stack1),(helpers.sanitize || (depth0 && depth0.sanitize) || alias3).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias3).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<time datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.calendar || (depth0 && depth0.calendar) || alias3).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n    </a>\n</article>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.children : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});