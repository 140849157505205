const { getEligibility } = require("@wattpad/client-platform-web-ads");
const {
  getStoryReadingPlacements,
  getSearchPlacements,
  getStoryDetailsPlacements,
  getBrowsePlacements
} = require("./get-ad-placements");
const isServer = require("../is-server")();

/**
 * For use in CONTROLLERS:
 * Retrieves ad eligibility response for a given list of placements
 * Add an identical request to the corresponding server and client controllers
 * Retrieve the response in the view
 *
 * @param {Array} placements - list of applicable placements
 * @returns {string} eligibility response
 */
const getAdEligibility = (placements, req, res) => {
  const sendAthaRequest = !isServer && typeof window?.__atha !== "undefined";
  let fetcher = null;
  let requestId = null;

  // Send request to Atha
  if (sendAthaRequest) requestId = window.__atha.sendEligibilityRequestEvent();

  if (isServer) {
    const mod = module;
    const api = mod.require("../../server/utils/api-http");
    const config = mod.require("../../server/config");

    fetcher = ({ url, body }) => {
      return api.post(
        config.application.frontControllerHost + url,
        {
          placements: body,
          contentType: "application/json",
          customUrl: true
        },
        res,
        req
      );
    };
  } else {
    fetcher = ({ url, body }) =>
      Promise.resolve(
        $.post({
          url: `${wattpad?.apiDomain}${url}`,
          data: JSON.stringify({
            placements: body
          }),
          xhrFields: {
            withCredentials: true
          },
          contentType: "application/json;"
        })
      );
  }

  return new Promise(resolve => {
    getEligibility({ fetcher, placements })
      // fetcher({
      //   url: "/ads/v1/eligibility",
      //   body: placements
      // })
      .then(response => {
        if (sendAthaRequest)
          window.__atha.sendEligibilityLoadEvent(requestId, "success");
        resolve(response);
      })
      .catch(error => {
        // TODO: If there’s a client side timeout, map it error_client_timeout
        if (sendAthaRequest)
          window.__atha.sendEligibilityLoadEvent(requestId, error.message);
        resolve([]);
      });
  });
};

/**
 * Retrieves a list of placements for story.js and story-reading.js
 * @param {number} params {Object}:
 *      - story_id {number} - story id
 *      - part_id {number} - part id
 * @param {object} req - server request
 * @param {object} res - server response
 * @returns {Array} placement array
 */
const getStoryReadingEligibility = (params, req, res) => {
  return getAdEligibility(getStoryReadingPlacements(params), req, res);
};

/**
 * Retrieves a list of placements for search
 * @param {object} req - server request
 * @param {object} res - server response
 * @returns {Array} placement array
 */
const getSearchEligibility = (req, res) => {
  return getAdEligibility(getSearchPlacements(), req, res);
};

/**
 * Retrieves a list of placements for story-details.js
 * @param {number} params {Object}:
 *      - story_id {number} - story id
 * @param {object} req - server request
 * @param {object} res - server response
 * @returns {Array} placement array
 */
const getStoryDetailsEligibility = (params, req, res) => {
  return getAdEligibility(getStoryDetailsPlacements(params), req, res);
};

/**
 * Retrieves a list of placements for browse.js (tag pages)
 * @param {object} req - server request
 * @param {object} res - server response
 * @returns {Array} placement array
 */
const getBrowseEligibility = (req, res) => {
  return getAdEligibility(getBrowsePlacements(), req, res);
};

module.exports = {
  getAdEligibility,
  getStoryReadingEligibility,
  getSearchEligibility,
  getStoryDetailsEligibility,
  getBrowseEligibility
};
