var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"button-group relative dropdown\">\n    <button class=\"btn btn-text btn-options dropdown-toggle\" data-toggle=\"dropdown\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n    <div class=\"triangle\"></div>\n    <ul class=\"dropdown-menu align-right\">\n        <li><a class=\"on-hide\" role=\"button\" href=\"#\" data-action=\"deleteEvent\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove-alt",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    </ul>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.profile : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});