import getApp from "core/templates/components/get-app.hbs";
import authForm from "core/templates/authentication/auth-form.hbs";
import authHeadline from "core/templates/authentication/auth-headline.hbs";
import browseTopics from "platform/browse/templates/browse-topics.hbs";
import discoverMenu from "core/templates/header/discover-menu.hbs";
import searchField from "core/templates/header/search-field.hbs";
import communityMenu from "core/templates/header/community-menu.hbs";
import authLayout from "platform/authentication/templates/auth-layout.hbs";
import authFooter from "platform/authentication/templates/auth-footer.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Authentication",
    app.views.Base.extend({
      className: "authentication-wrapper",
      template: authLayout,
      events: {
        // links
        "tap   .on-login": "loginClick",
        "click .on-login": "stopEvent",

        "tap   .on-signup": "onNavigatePage",
        "click .on-signup": "stopEvent",

        "tap   .on-password-forgot": "onPasswordForgot",
        "click .on-password-forgot": "stopEvent",

        "tap   .get-the-app": "onDownloadApp",
        "click .get-the-app": "stopEvent",

        // actions
        "tap    .on-pw-toggle": "onPasswordToggle",

        // AUDI-751 to trigger validation when leaving a picker and not choosing a value
        "blur     select": "onBlurPicker",

        "shown.bs.dropdown    #auth-header .button-group": "toggleMenu",
        "hidden.bs.dropdown   #auth-header .button-group": "toggleMenu",

        "tap    #language-options li a": "onChangeLanguage", //for desktop
        "change #language-options": "onChangeLanguage", //for mobile

        "tap   .scroll-arrow": "onScrollToValues",
        "click .scroll-arrow": "stopEvent",

        "tap   #back-to-top": "onScrollToForm",
        "click #back-to-top": "stopEvent",

        "mouseleave .show-related-tags": "onUnhoverCategory",
        "mouseenter .show-related-tags": "onCategoryHovered",
        "tap .show-related-tags": "onCategoryHovered",
        "click .show-related-tags": "stopEvent"
      },

      initialize: function(options) {
        options = options || { page: "signup" }; // by default its the signup page
        this.options = options;
        this.options.from = options.from || null;
        this.timeout = null;
        this.deviceType =
          app.get("device") && app.get("device").is.mobile
            ? "mobile"
            : "desktop";

        if (this.deviceType === "desktop") {
          this.deviceType =
            app.get("device") && app.get("device").is.tablet
              ? "tablet"
              : "desktop";
          // partials for desktop header on logged out home
          Handlebars.registerPartial(
            "core.header.community_menu",
            communityMenu
          );
          Handlebars.registerPartial("core.header.search_field", searchField);
          Handlebars.registerPartial("core.header.discover_menu", discoverMenu);
          Handlebars.registerPartial(
            "desktop.browse.browse_topics",
            browseTopics
          );
        }
        this.authToken = options.authToken;

        // Retrieve the URL the user should be directed to after they have logged in
        this.options.nextUrl = wattpad.utils.getNextUrl(options.nexturl);

        // Set up for the auth prompt mixin, which can't be overridden as static properties
        this.title = wattpad.utils.trans("Log in to Wattpad");

        // set partials
        Handlebars.registerPartial(
          "core.authentication.auth_headline",
          authHeadline
        );
        Handlebars.registerPartial("core.authentication.auth_form", authForm);
        Handlebars.registerPartial(
          "mobile.authentication.auth_footer",
          authFooter
        );
        if (this.deviceType === "desktop" || this.deviceType === "tablet") {
          Handlebars.registerPartial("core.components.get_app", getApp);
        }
      },

      render: function(options) {
        var locale,
          languages,
          signupFrom,
          templateOptions = {};

        options = options || {};
        templateOptions[this.options.page] = true;
        locale = wattpad.utils.getCookie("locale");
        languages = app.get("supported-languages");
        signupFrom = wattpad.utils.getCookie("signupFrom");

        if (!locale || locale.trim().length === 0) {
          locale = "en_US"; //default to english
        }
        var interfaceLang = languages.where({ locale: locale })[0].toJSON();
        templateOptions.googlePlayImage = this._googlePlayImage(
          interfaceLang.code
        );
        templateOptions.nextUrl = this.options.nextUrl;
        templateOptions.interfaceLang = languages
          .where({ locale: locale })[0]
          .toJSON();
        templateOptions.languages = languages.toJSON();
        templateOptions.translatedLangs = wattpad.translatedLangs;
        templateOptions.device = app.get("device");
        templateOptions.signupFrom = signupFrom;
        templateOptions.hybridLayout = this.options.hybridLayout;
        if (this.options.hybridLayout) {
          templateOptions.model = this.options.model;
        }
        templateOptions.notice = !!wattpad.utils.getParam("notice")
          ? wattpad.utils.trans(
              "If that email address is in our database, we will send you an email to reset your password."
            )
          : undefined;
        templateOptions.model = new app.models.Authsignup({});
        templateOptions.msg = wattpad.utils.getParam("msg");
        templateOptions.authToken = this.authToken;
        templateOptions.username = wattpad.user?.username;
        templateOptions.name = wattpad.user?.name;
        templateOptions.isFromPushNotification =
          wattpad.utils.getParam("utm_source") == "push-password_reset";
        // Force SSR load for the new variation of the signup page
        if (!options.isSSR && this.options.page === "signup") {
          wattpad.utils.redirectToServer(window.location.href);
          return;
        }

        this.$el.html(this.template(templateOptions));
        _.defer(this.afterRender.bind(this));

        return this;
      },

      afterRender: function() {
        var $dobTooltip = this.$(".on-dob-hover");
        $dobTooltip.tooltip({
          trigger: "click hover manual",
          title:
            "We ask for your date of birth so you can have the right Wattpad experience for your age."
        });
      },

      onNavigatePage: function(evt) {
        var $target = $(evt.currentTarget);
        app.router.navigate($target.attr("href"), { trigger: true });
      },

      onDownloadApp: function(evt) {
        if (this.deviceType === "mobile") {
          window.location.href = wattpad.utils.getAppUrl();
        } else {
          var $button = $(evt.currentTarget),
            downloadLink = $button.data("download-link"),
            downloadText = $button.data("download-text");

          this.$("#get-app-modal-container")
            .empty()
            .html(
              getApp({
                downloadLink: downloadLink,
                downloadText: downloadText
              })
            );

          var modal = this.$("#get-app-modal");

          modal.find("#phonenumber").intlTelInput({
            utilsScript:
              wattpad.intlTelInputUtilsScript || "/js/intl-tel-input-utils.js",
            preferredCountries: [],
            defaultCountry: "us"
          });

          var boundSend = _.bind(this.onSendAppLink, this);
          var sendButton = modal.find(".on-send-app-link").on("tap", boundSend);

          var cleanup = function() {
            sendButton.off("tap", boundSend);
            modal.off("hide.bs.modal", cleanup);
          };

          modal.modal("toggle").on("hide.bs.modal", cleanup);
        }
      },

      toggleMenu: function(evt) {
        $(evt.currentTarget)
          .find("> button > span")
          .toggleClass("fa-down fa-up");
      },

      onPasswordToggle: function(evt) {
        var $switch = $(evt.currentTarget),
          $pwInput = $switch.siblings("input"),
          type = $pwInput.attr("type");

        window.clearTimeout(this.timeout);

        if (type === "password") {
          $pwInput.attr("type", "text").focus();
          $switch.addClass("active");
        }

        if (type === "text") {
          $pwInput.attr("type", "password").focus();
          $switch.removeClass("active");
        }
      },

      onSendAppLink: function(evt) {
        var $input = $("#get-app-modal #phonenumber"),
          isValid = $input.intlTelInput("isValidNumber");

        this.model.validate("getApp");

        // Regardless of what the model thinks, if intlTelInput thinks the number
        // isn't valid, it isn't.
        // Ideally, this would be in the GetAppValidationRules mixin, but the
        // intlTelInput plugin is only available from the view.
        if (!isValid) {
          this.model.trigger(
            "attr:invalid",
            "getApp",
            wattpad.utils.trans("Please enter a valid SMS number.")
          );
        } else if (isValid) {
          this.model.trigger("attr:valid", "getApp");

          Promise.resolve(
            $.ajax({
              type: "POST",
              url: "/apiv2/send_download_link",
              data: { to: $input.intlTelInput("getNumber") }
            })
          )
            .then(function() {
              var $button = $("#get-app-modal .on-send-app-link");
              $button.addClass("sent");
              _.delay(function() {
                $button.removeClass("sent");
                $("#get-app-modal").modal("hide");
              }, 5000);
            })
            ["catch"](function(resp) {
              // todo: error message
              window.alert(wattpad.utils.trans(resp));
            });
        }
      },

      onChangeLanguage: function(evt) {
        var langCode = "en_US"; //default
        if (evt.currentTarget.selectedOptions) {
          langCode = $(evt.currentTarget.selectedOptions[0]).data("code");
        } else {
          langCode = $(evt.currentTarget).data("code");
        }
        wattpad.utils.setCookie("locale", langCode, 10 * 365, true);
        window.location.reload();
      },

      onScrollToValues: function(evt) {
        $("html, body").animate(
          {
            scrollTop: $("#value-props").offset().top
          },
          1000
        );
        wattpad.utils.stopEvent(evt);
      },

      onScrollToForm: function(evt) {
        $("html, body").animate(
          {
            scrollTop: 0
          },
          1000
        );
        $("#signup-username").focus();
        wattpad.utils.stopEvent(evt);
      },

      setSubElements: function(subview) {
        var classes,
          sel = "";
        if (subview) {
          classes = subview.className.trim().split(" ");
          _.each(classes, function(text) {
            sel += "." + text;
          });
          subview.setElement($(sel.trim()));
        }
      },

      loginClick: function(evt) {
        wattpad.utils.pushEvent({
          category: "login-btn",
          action: "click",
          label: evt.currentTarget.dataset["label"] || "under_form"
        });

        this.title = wattpad.utils.trans("Log in to Wattpad");
        this.onAuthPrompt(evt);
      },

      //this is used by the typing text mixin in between each typed string
      preType: function() {
        this.changeBg();
      },

      // Add a delay so that if user's mouse goes over another category
      // when try to visit content next to this menu
      // the category won't be changed
      currentTimer: null,
      onCategoryHovered: function(evt) {
        this.currentTimer = setTimeout(function() {
          // Hide the currently active category
          var $active = this.$("#main-browse-navigate .active");
          $($active.data("id")).addClass("hide");
          $active.removeClass("active");

          // Reveal the selected category
          var $target = $(evt.target);
          $($target.data("id")).removeClass("hide");
          $target.addClass("active");
        }, 50);
      },

      onUnhoverCategory: function(evt) {
        clearTimeout(this.currentTimer);
      },

      // Remember the username/email entered in the '#login-user' text box and use
      // it to autofill the forgot-password form.
      onPasswordForgot: function(evt) {
        var login = $("#login-user").val();
        var href = evt.currentTarget.href.replace(wattpad.siteRoot, "");

        app.router.navigate(href, true);

        $("#forgot-email").prop("value", login);
      },

      onBlurPicker: function(evt) {
        var picker = $(evt.currentTarget),
          value = picker.val(),
          attribute = picker.closest(".form-group").data("attr");

        if (value === null) {
          this.model.validate(attribute);
        }
      },

      _googlePlayImage: function googlePlayImage(code) {
        switch (code.toLowerCase()) {
          case "ar":
            return "homepages/googleplay/ar.png";
          case "cs":
            return "homepages/googleplay/cs.png";
          case "de":
            return "homepages/googleplay/de.png";
          case "es":
            return "homepages/googleplay/es.png";
          case "fr":
            return "homepages/googleplay/fr.png";
          case "id":
            return "homepages/googleplay/id.png";
          case "it":
            return "homepages/googleplay/it.png";
          case "pl":
            return "homepages/googleplay/pl.png";
          case "pt":
            return "homepages/googleplay/pt.png";
          case "pt_pt":
            return "homepages/googleplay/pt.png";
          case "pt_br":
            return "homepages/googleplay/pt.png";
          case "ro":
            return "homepages/googleplay/ro.png";
          case "ru":
            return "homepages/googleplay/ru.png";
          case "sk":
            return "homepages/googleplay/sk.png";
          case "sv":
            return "homepages/googleplay/sv.png";
          case "tr":
            return "homepages/googleplay/tr.png";
          case "vi":
            return "homepages/googleplay/vi.png";

          case "en":
          /* falls through */
          default:
            return "homepages/googleplay/en.png";
        }
      }
    })
  );

  app.mixin(
    app.views.Authentication,
    "ValidationView",
    "FacebookConnect",
    "AuthPromptManagement"
  );
})(window, _, wattpad, window.app);
