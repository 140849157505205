import libraryStoryItemPreview from "core/templates/library/library-story-item-preview.hbs";
import libraryStoryItem from "core/templates/library/library-story-item.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "LibraryCard",
    Monaco.View.extend({
      className: "library-story-item",

      templates: {
        card: libraryStoryItem,
        modal: libraryStoryItemPreview
      },

      initialize: function() {
        _.bindAll(this, ["archive", "unarchive", "discard", "removeView"]);
      },

      setElement: function() {
        Monaco.View.prototype.setElement.apply(this, arguments);

        if (this.model) {
          this.$("a,button,.on-story-hover,.on-story-preview").data(
            "cid",
            this.model.cid
          );
        }
      },

      render: function() {
        var data = this.model.toJSON();
        var position = data.readingPosition;

        if (this.model.collection instanceof app.collections.Archive) {
          data.archived = true;
        } else if (
          position.position > 0 ||
          position.partId !== data.parts[0].id
        ) {
          data.hasStarted = true;
        }

        this.$el.html(this.templates.card(data));
        this.$("a,button,.on-story-hover,.on-story-preview").data(
          "cid",
          this.model.cid
        );

        return this;
      },

      deactivate: function() {
        this.$(".on-story-hover").fadeOut(150);
      },

      activate: function() {
        this.$(".on-story-hover").fadeIn(150);
      },

      preview: function() {
        $("#preview-modal")
          .html(this.templates.modal(this.model.toJSON()))
          .modal("show")
          .find("button")
          .data("cid", this.model.cid);
      },

      read: function() {
        app.router.navigate(
          utils.formatStoryUrl(
            _.findWhere(this.model.get("parts"), {
              id: parseInt(this.model.get("readingPosition").partId)
            }).url
          ),
          { trigger: true }
        );
      },

      details: function() {
        app.router.navigate(utils.formatStoryUrl(this.model.get("url")), {
          trigger: true
        });
      },

      archive: function() {
        return new app.collections.Archive([], { user: utils.currentUser() })
          .addStory(this.model)
          .then(this.removeView);
      },

      unarchive: function() {
        return new app.collections.Archive([], { user: utils.currentUser() })
          .removeStory(this.model, true)
          .then(this.removeView);
      },

      discard: function() {
        return this.model.collection
          .removeStory(this.model)
          .then(this.removeView);
      },

      removeView: function(results) {
        // did the API action succeed?
        if (results === true || _.contains(results.success, this.model.id)) {
          this.$el.fadeOut(500, function() {
            $(this).remove();
          });
          return results;
        }
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
