var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, buffer = 
  "<div class=\"user-meta\">\n  <a class=\"on-user avatar avatar-lg\"\n     data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n     href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n    <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1), depth0))
    + "\">\n  </a>\n  <div class=\"clearfix\">\n     <a class=\"on-user alt-link\"\n     data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n     href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n     <strong class=\"title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "</strong>\n   </a>\n\n   <div class=\"subtitle meta\">\n     <span> "
    + alias2((helpers.sprintf || (depth0 && depth0.sprintf) || alias4).call(alias3,(helpers.ngettext || (depth0 && depth0.ngettext) || alias4).call(alias3,"%s Follower","%s Followers",(helpers.count || (depth0 && depth0.count) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.numFollowers : stack1),{"name":"count","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.numFollowers : stack1),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + " </span>\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.hasWorks : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "   </div>\n\n";
  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : alias4),(options={"name":"isLoggedIn","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias3,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    <a class=\"btn btn-grey btn-sm on-user\"\n      data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n      href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n      "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"View Profile",{"name":"trans","hash":{},"data":data}))
    + "\n    </a>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "       <span> "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Work","%s Works",(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.numWorks : stack1),{"name":"count","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.numWorks : stack1),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + " </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"isCurrentUser","hash":{},"fn":container.noop,"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1)) != null ? stack1.following : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "         <button class=\"btn btn-sm btn-teal on-unfollow-user\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following",14,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "         <button class=\"btn btn-sm btn-white on-follow-user\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",14,"wp-base-2-dark",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follow",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.meta : depth0)) != null ? stack1.user : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});