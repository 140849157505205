import layoutFooter from "platform/components/templates/layout-footer.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "Footer",
    Monaco.View.extend({
      template: layoutFooter,

      events: {
        "click .on-language": "stopEvent",
        "tap .on-language": "onLanguageSelected",

        "click .on-premium": "stopEvent",
        "tap .on-premium": "onPremium",

        "click .on-back-to-mobile": "stopEvent",
        "tap .on-back-to-mobile": "onBackToMobile"
      },

      render: function() {
        var data = {
          isCookieSet: wattpad.utils.getCookie("mw-no") ? true : false
        };
        this.$el.html(this.template(data));
        return this;
      },

      onLanguageSelected: function(evt) {
        var $iframe = $("#languageModal iframe");

        $iframe.attr("src", "/settings/language?jq=true");
        $iframe[0].onload = function() {
          $iframe[0].contentWindow.callbackFn = function(language) {
            var search = wattpad.utils.getWindowSearch() || "?";
            $("#languageModal").modal("hide");

            if (language) {
              if (search.indexOf("language") > -1) {
                search = search.replace(
                  /language=[^\&]*/,
                  "language=" + language
                );
              } else {
                search += "&language=" + language;
              }

              wattpad.utils.reloadWithQuery(search.replace(/\?\&/, "?"));
            }
          };
        };

        $("#languageModal")
          .modal({ show: true })
          .removeClass("hide");
      },

      onPremium: function(evt) {
        window.te.push("event", "subscriptions", "cta", null, "view", {
          cta_type: "footer_button"
        });
        wattpad.utils.redirectToServer(evt.currentTarget.href);
      },

      onBackToMobile: function(evt) {
        window.backToMobileWeb();
      },

      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      }
    })
  );
})(window, _, wattpad, window.app);
