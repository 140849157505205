(function(window, _, wattpad, app) {
  "use strict";

  window.wattpad = wattpad || {};
  var utils = window.wattpad.utils || (window.wattpad.utils = {});

  var Device = (utils.Device = function(ua) {
    if (ua == void 0) {
      ua = navigator.userAgent;
    }
    this.ua = ua.toLowerCase();
    this.is = {
      // MOBILE
      mobile:
        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|windows phone|xda|xiino/i.test(
          this.ua
        ) ||
        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
          this.ua.substr(0, 4)
        ),
      // apple
      iPhone: Boolean(this.ua.match(/iphone/)),
      iPod: Boolean(this.ua.match(/ipod/)),
      iPad: Boolean(this.ua.match(/ipad/)),
      // android
      android: Boolean(this.ua.match(/android/)),
      androidTablet: Boolean(
        this.ua.match(/android/) && !this.ua.match(/mobile/)
      ),
      googleweblight: Boolean(this.ua.match(/googleweblight/)),
      // amazon
      kindle: Boolean(this.ua.match(/(kindle)/)),
      // Windows Phone
      windows: Boolean(this.ua.match(/windows phone/)),
      // blackberry
      blackBerry: Boolean(this.ua.match(/blackberry/)),
      playBook: Boolean(this.ua.match(/playbook/)),
      //huawei
      huawei: Boolean(
        this.ua.match(/huawei/) ||
          this.ua ===
            "mozilla/5.0 (linux; android 9; vog-l29) applewebkit/537.36 (khtml, like gecko) chrome/73.0.3683.90 mobile safari/537.36" ||
          this.ua ===
            "mozilla/5.0 (linux; android 9; ele-l29) applewebkit/537.36 (khtml, like gecko) chrome/73.0.3683.90 mobile safari/537.36"
      ),
      // other
      palmDevice: Boolean(this.ua.match(/(palmos|palmsource| pre\/)/)),
      smartMobileHints: Boolean(this.ua.match(/(smartphone|iemobile)/)),
      tabletPC: Boolean(this.ua.match(/tablet pc/)),

      // DESKTOP
      ie: Boolean(this.ua.match(/msie/) || this.ua.match(/trident/i)),
      ie9: Boolean(this.ua.match(/msie 9./)),
      ie8: Boolean(this.ua.match(/msie 8./)),
      ie7: Boolean(this.ua.match(/msie 7./)),
      ie6: Boolean(this.ua.match(/msie 6./)),

      // Opera
      opera: Boolean(this.ua.match(/opera/)),

      // Chrome
      chrome: Boolean(this.ua.match(/chrome/)),

      // CRAWLERS
      facebookCrawler: Boolean(this.ua.match(/facebookexternalhit/)),
      twitterCrawler: Boolean(this.ua.match(/twitterbot/)),
      appleCrawler: Boolean(this.ua.match(/applebot/)),
      pinterestCrawler: Boolean(this.ua.match(/pinterest/)),
      googlePlusCrawler: Boolean(this.ua.match(/\+\/web\/snippet/)),
      otherCrawler:
        Boolean(this.ua.match(/googlebot/)) ||
        Boolean(this.ua.match(/yahoo/)) ||
        Boolean(this.ua.match(/yandex/)) ||
        Boolean(this.ua.match(/drupal/)) ||
        Boolean(this.ua.match(/bot/)) ||
        Boolean(this.ua.match(/nutch/)) ||
        Boolean(this.ua.match(/crawl/)) ||
        Boolean(this.ua.match(/spider/)) ||
        Boolean(this.ua.match(/search/)) ||
        Boolean(this.ua.match(/index/)) ||
        Boolean(this.ua.match(/rss/)) ||
        Boolean(this.ua.match(/simplepie/)) ||
        Boolean(this.ua.match(/tracker/)) ||
        Boolean(this.ua.match(/teoma/)) ||
        Boolean(this.ua.match(/wazzup/)) ||
        Boolean(this.ua.match(/finder/))
    };
    this.is.ios = this.is.iPhone || this.is.iPod || this.is.iPad;
    this.is.smartphone = this.isSmartPhoneLike();
    this.is.touch = this.isTouchDevice();
    this.is.desktop = this.isDesktop();
    this.is.tablet = this.isTablet();
    return this;
  });

  Device.prototype.isSmartPhoneLike = function() {
    return (
      (this.is.iPhone ||
        this.is.iPod ||
        (this.is.android && this.is.mobile) ||
        this.is.windows ||
        this.is.blackBerry ||
        this.is.palmDevice ||
        this.is.smartMobileHints) &&
      !this.isTablet()
    );
  };

  Device.prototype.isTablet = function() {
    return (
      (this.is.iPad ||
        this.is.tabletPC ||
        this.is.playBook ||
        this.is.androidTablet ||
        this.is.kindle) &&
      !this.isOperaMini()
    );
  };

  Device.prototype.isTouchDevice = function() {
    return this.isTablet() || this.isSmartPhoneLike();
  };

  Device.prototype.isDesktop = function() {
    return !this.isTouchDevice();
  };

  Device.prototype.isOperaMini = function() {
    return (
      Boolean(this.ua.match(/opera mini/)) ||
      Boolean(this.ua.match(/opera mobi/)) ||
      Boolean(this.ua.match(/opera tablet/))
    );
  };

  Device.prototype.isCrawler = function() {
    return (
      this.is.facebookCrawler ||
      this.is.twitterCrawler ||
      this.is.pinterestCrawler ||
      this.is.otherCrawler ||
      this.is.appleCrawler ||
      this.is.googlePlusCrawler
    );
  };

  Device.prototype.isWebView = function() {
    // Safari's user-agent is in all iOS browsers, but NOT webviews.
    return (
      (this.is.ios && !Boolean(this.ua.match(/safari/))) ||
      // Per https://developer.chrome.com/multidevice/user-agent
      // Webviews contain Version/X.Y, Chrome doesn't
      (this.is.android && Boolean(this.ua.match(/version\//)))
    );
  };

  Device.prototype.androidVersion = function() {
    var re = null,
      result = null;
    if (this.is.android) {
      re = /android (\d+(?:\.\d+)+)/gi;
      result = re.exec(this.ua);
      if (result.length == 2) {
        return result[1];
      }
    }
    return result;
  };
})(window, _, wattpad, window.app);
