var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<p data-media-type=\"video\" data-video-source=\"wattpad\" data-original-width=\""
    + alias4(((helper = (helper = helpers.origWidth || (depth0 != null ? depth0.origWidth : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"origWidth","hash":{},"data":data}) : helper)))
    + "\" data-original-height=\""
    + alias4(((helper = (helper = helpers.origHeight || (depth0 != null ? depth0.origHeight : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"origHeight","hash":{},"data":data}) : helper)))
    + "\"data-preview-image=\""
    + alias4(((helper = (helper = helpers.previewImageSrc || (depth0 != null ? depth0.previewImageSrc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"previewImageSrc","hash":{},"data":data}) : helper)))
    + "\" data-video-id=\""
    + alias4(((helper = (helper = helpers.videoId || (depth0 != null ? depth0.videoId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data}) : helper)))
    + "\">\n  <video poster=\""
    + alias4(((helper = (helper = helpers.previewImageSrc || (depth0 != null ? depth0.previewImageSrc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"previewImageSrc","hash":{},"data":data}) : helper)))
    + "\" autoplay autostart muted loop controls>\n    <source src=\""
    + alias4(((helper = (helper = helpers.videoSrc || (depth0 != null ? depth0.videoSrc : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoSrc","hash":{},"data":data}) : helper)))
    + "\" type=\"video/mp4\">\n    "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"There should be a GIF or video here. Update the app now to see it",{"name":"trans","hash":{},"data":data}))
    + "\n  </video>\n  <span class=\"place-holder\">&nbsp;</span>\n  <span contenteditable='false' class=\"on-remove-image\">\n    <span class=\"play fa-stack fa-lg\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled","auto","wp-neutral-1 fa-stack-2x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",18,"wp-neutral-5 fa-stack-1x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </span>\n  </span>\n</p>\n";
},"useData":true});