import { setupAdListener } from "@wattpad/client-platform-web-ads";

/*
 * This file is responsible for creating the Monaco application passing any necessary
 * options.
 * Use this file to perform any initial validation before creating the application,
 * such as API access; user credentials; etc...
 *
 */

window.mobileApp = window.mobileApp || {};
window.mobileApp.instantiate = function(window, _, Backbone, Monaco) {
  "use strict";

  /* -- APPLICATION ---------------------------------------------------- */
  // creates the application attached to window
  window.app = new Monaco.Application("mobile-web", {
    prefetched: window.prefetched,
    cachePolicy: "X-WPApp-Cache-Control"
  });

  const resizeHandler = _.debounce(function() {
    console.log(`Resize on: ${window.location.href}`);
  }, 1000);

  window.addEventListener("resize", resizeHandler);

  setupAdListener();
};
window.mobileApp.instantiate(window, window._, window.Backbone, window.Monaco);
