import recentlyRead from "core/templates/collection-views/recently-read.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RecentlyRead",
    app.views.Base.extend({
      template: recentlyRead,

      initialize: function() {
        this.collection.once("sync", this.render, this);
        this.collection.fetch({ data: { limit: 3 } });
      },

      render: function() {
        var data = this.collection.toJSON();

        _.forEach(data, function(story) {
          story.percentRead = 0;
          story.readingURL = story.url;
          if (story.readingPosition && story.readingPosition.partId !== 0) {
            var index = _.findIndex(story.parts, {
              id: parseInt(story.readingPosition.partId, 10)
            });
            if (index !== -1) {
              story.percentRead = (index / story.parts.length) * 100;
              story.percentRead +=
                (1 / story.parts.length) *
                100 *
                parseFloat(story.readingPosition.position);
              story.readingURL = story.parts[index].url;
            }
          }
        });

        this.$el.html(
          this.template({ recentlyRead: data, hasItems: data.length > 0 })
        );
        return this;
      },

      resource: function() {
        return (
          "user." +
          utils
            .currentUser()
            .get("username")
            .get("username") +
          ".recently-read"
        );
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
