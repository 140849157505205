(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:videos_landing",
    app.router.filter(["getTranslations", "getCategories"], function() {
      var data = JSON.parse(wattpad.spielbergoData);
      var promises = [],
        models = [];

      _.each(data, function(storyVideoItem) {
        var model = new app.models.StoryVideoModel({
          id: storyVideoItem.story,
          videoId: storyVideoItem.video
        });

        models.push(model);
        promises.push(model.fetch());
      });

      Promise.all(promises).then(
        function(responses) {
          var view = new app.views.VideosLanding({ videoModels: models });

          app.transitionTo(view, {
            hasHeader: false,
            hasFooter: false,
            pageTitle: "Wattpad Presents"
          });
        },
        function() {
          //  error handling
          app.router.trigger("route:error-404", "wattpad-presents");
        }
      );
    })
  );
})(window, _, jQuery, wattpad, window.app);
