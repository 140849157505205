import feedMessageReply from "core/templates/feed-items/feed-message-reply.hbs";
import { ReportTypes } from "../../../../../components/views/report/ReportModal";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "FeedMessageReply",
    Monaco.View.extend({
      // Properties
      // ----------
      reportModalContainer: ".message-report-modal-reply",
      requestType: "inappropriate_comments",
      isMainReportModal: false,
      modalTitle: wattpad.utils.trans("Report a Public Message"),
      tagName: "li",
      className: "list-group-item",
      conductType: "public_message_conduct",

      template: null,

      events: {
        // links
        "tap    .on-user": "onNavigate",
        "click  .on-user": "stopEvent",

        "tap    .on-user-mention": "onNavigate",
        "click  .on-user-mention": "stopEvent",

        // actions
        "tap  .on-reply": "onReply",
        "click .on-reply": "stopEvent",

        "tap  .on-send-reply-event": "sendEventForBeautifyUrl",
        "click .on-send-reply-event": "stopEvent"
      },

      // Methods
      // -------
      //

      initialize: function() {
        this.template = feedMessageReply;

        // React Report Modal props
        this.reportModalType = "public-message";
        this.reportModalId = {
          name: this.model.get("username") || this.model.get("from").name,
          location: window.location.href,
          body: utils.sanitizeHTML(this.model.get("body"))
        };
      },

      render: function() {
        var data = this.model.toJSON(),
          currentUserName = utils.currentUser().get("username"),
          self = this;

        // block HTML tags in message body
        if (data.body) {
          data.body = utils.sanitizeHTML(data.body);
        }

        // Mute or Unmute user data
        // show Mute or Unmute option if user is logged in
        var commentAuthor = this.model.get("from").name;
        data.showMuteOption =
          wattpad.utils.currentUser().get("username") &&
          commentAuthor !== wattpad.utils.currentUser().get("username");

        data.canMute =
          window.store &&
          window.store.getState().profileData &&
          window.store.getState().profileData.mutedUsers &&
          data.showMuteOption &&
          !window.store
            .getState()
            .profileData.mutedUsers.some(
              user => user.username == commentAuthor
            );

        data.canDeleteData = {
          canDelete:
            this.model.get("id") !== 0 &&
            (this.model.get("ownername") === currentUserName ||
              this.model.get("from").name === currentUserName ||
              wattpad.utils.currentUser().get("isSysAdmin")),
          isCommentAuthor:
            commentAuthor === wattpad.utils.currentUser().get("username")
        };

        data.onDeleteComment = this.onDelete.bind(this);

        data.commentAuthor = commentAuthor;

        data.canLink = false;

        data.reportType = ReportTypes.PUBLIC_MESSAGE;

        /* Checks the message model body to see if the user has embedded a valid story link -
       * if so, fetch extra metadata to beautify the URL and render the panel.
       */
        var beautifyUrlSettings = this.model.getBeautifyUrlSettings(data);
        if (beautifyUrlSettings && !this.model.get("beautifyUrlMetadata")) {
          self.model
            .getMetadataForBeautifyUrl(beautifyUrlSettings)
            .then(function(result) {
              data.beautifyUrlMetadata = result;
              self.$el.html(self.template(data));
            });
        }
        this.$el.html(this.template(data));
        return this;
      },

      sendEventForBeautifyUrl: function(e) {
        var data = $(e.currentTarget)
            .parent(".beautify-url")
            .data(),
          storyId = data.storyId,
          profileOwner = data.profileOwner;

        window.te.push("event", "profile", "message", "story", "click", {
          username: profileOwner,
          storyid: storyId
        });
      },

      onNavigate: function(event) {
        var $target = $(event.currentTarget);
        app.router.navigate($target.attr("href"), { trigger: true });
      },

      onReply: function(event) {
        this.stopEvent(event);
        this.trigger("message:mention", this.model.get("from").name);
      },

      onDelete: function(event) {
        this.stopEvent(event);
        this.model.destroy();
      },

      stopEvent: function(event) {
        utils.stopEvent(event);
      },

      reportMoreInfo: function() {
        var self = this;
        return [
          {
            key: "Message Author",
            val: this.model.get("username") || this.model.get("from").name
          },
          { key: "Message Location", val: window.location.href },
          {
            key: "Message Body",
            val: function() {
              return utils.sanitizeHTML(self.model.get("body"));
            }
          }
        ];
      }
    })
  );

  app.mixin(app.views.FeedMessageReply, "ReportManagement", "ReportConduct");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
