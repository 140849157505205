import feedAggregatesModal from "core/templates/feed-items/aggregates/feed-aggregates-modal.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "FeedAggregates",
    Monaco.View.extend({
      template: feedAggregatesModal,

      itemView: null,
      className: "modal-dialog",

      //model is the parent feed item
      //collection are the children

      initialize: function(options) {
        this.itemView = Monaco.View.extend({
          tagName: "li",
          template: options.itemTemplate,
          className: "list-group-item aggregate",
          initialize: function() {
            //Listen for the follow manager
            if (this.model.get("type") === "follow") {
              this.listenTo(app, "app:user:follow", this.onUpdateFollow);
              this.listenTo(app, "app:user:unfollow", this.onUpdateUnfollow);
            }
          },

          render: function() {
            var self = this;
            this.$el.html(this.template(this.model.toJSON()));
            _.defer(function() {
              self.$(".panel-body [data-toggle=tooltip]").tooltip();
            });
            return this;
          },

          onAddedToLibrary: function(target) {
            var $btn = this.$(
              this.libraryButton + "[data-story-id='" + target + "']"
            );
            $btn
              .children(".fa")
              .removeClass("fa-wp-neutral-2 fa-plus")
              .addClass("fa-wp-neutral-5 fa-check");
            $btn.children(".toggle-triangle").addClass("active");
            $btn.children(".toggle-shadow").addClass("active");
            $btn
              .attr("title", wattpad.utils.trans("Added to Library"))
              .tooltip("fixTitle");
          },

          onRemovedFromLibrary: function(target) {
            var $btn = this.$(
              this.libraryButton + "[data-story-id='" + target + "']"
            );
            $btn
              .children(".fa")
              .addClass("fa-wp-neutral-2 fa-plus")
              .removeClass("fa-wp-neutral-5 fa-check");
            $btn.children(".toggle-triangle").removeClass("active");
            $btn.children(".toggle-shadow").removeClass("active");
            $btn
              .attr("title", wattpad.utils.trans("Add to Library"))
              .tooltip("fixTitle");
          },

          onUpdateFollow: function(target) {
            var $btn = this.$(
              this.followButton + "[data-target=" + target + "]"
            );
            $btn
              .children(".fa")
              .removeClass("fa-follow fa-wp-neutral-2")
              .addClass("fa-following fa-wp-neutral-5");
            $btn.children(".toggle-triangle").addClass("active");
            $btn.children(".toggle-shadow").addClass("active");
            $btn
              .attr("title", wattpad.utils.trans("Following"))
              .tooltip("fixTitle");
          },

          onUpdateUnfollow: function(target) {
            var $btn = this.$(
              this.followButton + "[data-target=" + target + "]"
            );
            $btn
              .children(".fa")
              .removeClass("fa-following fa-wp-neutral-5")
              .addClass("fa-follow fa-wp-neutral-2");
            $btn.children(".toggle-triangle").removeClass("active");
            $btn.children(".toggle-shadow").removeClass("active");
            $btn
              .attr("title", wattpad.utils.trans("Follow"))
              .tooltip("fixTitle");
          }
        });

        app.mixin(this.itemView, "LibraryManagement", "FollowManager");
        this.title = options.title;
      },

      render: function() {
        var data = {};
        if (this.model.get("type") === "dedicate") {
          data.username = this.model.get("data").dedicator.name;
          data.avatar = this.model.get("data").dedicator.avatar;
        } else {
          data.username = this.model.get("data").user.name;
          data.avatar = this.model.get("data").user.avatar;
        }
        data.createDate = this.model.get("createDate");
        data.title = this.title;

        this.$el.html(this.template(data));

        //throw in the parent item
        this.$(".modal-body .list-group").append(
          new this.itemView({ model: this.model }).render().$el
        );

        //throw in the rest
        this.collection.each(function(model) {
          this.$(".modal-body .list-group").append(
            new this.itemView({ model: model }).render().$el
          );
        }, this);
        return this;
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
