(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "ReportConduct",
    Monaco.Mixin.create({
      reportForm: {
        id: 48854, // Ticket Form ID for Reporting Conduct
        offenses: [
          {
            name: wattpad.utils.trans("Explicit Content"), //Changed name from Pornographic Content - Amy
            tag: "explicit_content", //updated tag to match zendesk field - Amy
            moreDetail: true,
            intext: wattpad.utils.trans(
              "Please explain the violation of our policies and where it is located on the account with as much detail as possible. If the violation occurs in a story, please provide a link to the story part(s). For example: This user is promoting sexual conduct in their about section." // New text - Amy
            )
          },

          {
            name: wattpad.utils.trans("Hate & Harassment"), // Changed from Hate and Bullying
            tag: "hate_or_harassment_conduct",
            moreDetail: true,
            intext: wattpad.utils.trans(
              "Please explain the violation of our Code of Conduct and where it is located on the account. If the violation occurs within a story, please include a link to the story part(s). Please provide as much information as possible. For example: The user is sending me hateful private messages." // New text - Amy
            )
          },

          {
            name: wattpad.utils.trans("Violence"),
            tag: "violence_or_self_harm_conduct", //updated tag to match zendesk field - Amy
            moreDetail: true,
            intext: wattpad.utils.trans(
              "Please explain the violation of our Code of Conduct and where it takes place on this account with as much information as possible. If the violation occurs within a story, please include a link to the story part(s). For example: The profile image is depicting an act of graphic violence." // New text - Amy
            )
          },

          {
            name: wattpad.utils.trans("Release of Personal Info"),
            tag: "release_of_personal_information_conduct", //updated tag to match zendesk field - Amy
            moreDetail: true,
            intext: wattpad.utils.trans(
              "If a photo of you or your personal identifying information is being released, please tell us what the information is and where it is located on the account. If the violation occurs within a story, please include a link to the story part(s). For example: An image of my face is being used as the background photo." // New text - Amy
            )
          },

          {
            name: wattpad.utils.trans("Self-Harm"), // added a dash between the words - Amy
            tag: "violence_or_self_harm_conduct", //updated tag to match zendesk field - Amy
            moreDetail: true,
            intext: wattpad.utils.trans(
              "Please explain the violation of our policies and where it is located on the account. If the violation occurs within a story, please include a link to the story part(s). For example: There is a self-harm image in the avatar. Or: The user is talking about self-harming on their conversation board or comments." // New text - Amy
            )
          },

          {
            name: wattpad.utils.trans("Underage User"),
            tag: "underage_user", //updated tag to match zendesk field - Amy
            moreDetail: true,
            intext: "" // removed intext - Amy
          },

          {
            name: wattpad.utils.trans("Spam"),
            tag: "spam_conduct",
            moreDetail: true,
            intext: wattpad.utils.trans(
              "Please explain what type of spam you’ve encountered and where we can find it on the account. If the violation occurs within a story, please include a link to the story part(s). For example: This user is sharing malicious download links on their conversation board. Or: This is a publisher trying to recruit writers." // New text - Amy
            )
          }
        ]
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
