import storyItem from "core/templates/item-views/story-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "PromotedStoryItem",
    app.views.Base.extend({
      className: "promoted-story-section",

      events: {
        "tap .on-story-preview": "onStoryPreview",
        "click .on-story-preview": "stopEvent"
      },

      initialize: function(options) {
        Handlebars.registerPartial("core.item_views.story_item", storyItem);

        options = options || {};

        this.fromPage = options.fromPage || "";
        this.template = options.template || _.noop;
        this.storyId = options.storyId || null;
        this.promotedEl = options.promotedEl || "";

        this.viewed = false;

        this.model = new app.models.PromotedStoryModel(options);

        _.bindAll(this, "trackViews");
      },

      remove: function() {
        if (this.recommendedStories) {
          this.recommendedStories.remove();
        }
        $(window).off("scroll", this.trackViews);
        app.views.Base.prototype.remove.apply(this, arguments);
      },

      render: function() {
        var self = this;

        var fetchStory = self.model.fetch();

        fetchStory.then(function(story) {
          //do nothing if there are no promoted stories to show
          if (story) {
            if (self.templateName == "stories-similar") {
              var data = {
                hasStories: true,
                stories: [story]
              };

              self.$el.empty().append(self.template(data));

              $(window).on("scroll", self.trackViews);
              $(self.promotedEl).removeClass("hidden");

              // After the promoted story loads, check if it is on screen
              // If so, send impression events immediately
              _.defer(function() {
                self.trackViews();
              });
            } else {
              story.user.fullname = story.user.fullname || story.user.name;
              story.user.name = story.user.username;

              // Mimic the non-promoted recommendations panel
              // Let the Discover Module view handle tracking
              var recommendedStoryItems = {
                items: {
                  stories: [story]
                },
                tracking: {
                  impressionUrl: self.model.impressionUrls,
                  clickUrl: self.model.clickUrls
                }
              };

              var recommendedStoriesModel = new Monaco.Model();
              recommendedStoriesModel.set("data", recommendedStoryItems);
              recommendedStoriesModel.set("type", "promotedStoriesRecommended");

              self.recommendedStories = new app.views.DiscoverModule({
                model: recommendedStoriesModel,
                experience: "story_reading"
              });
              self.recommendedStories.setElement(
                $(".promoted-recommended-stories-view")
              );
              self.recommendedStories.render();

              // css for recommendation panel differs depending on whether promoted section exists
              $(".colWrap").removeClass("no-promoted-section");
              $(".colWrap").addClass("has-promoted-section");

              $(self.promotedEl).removeClass("hidden");
            }
          }
        });

        return this;
      },

      trackViews: _.throttle(function() {
        var isOnScreen = utils.isOnScreen(this.$el, 0.67);

        if (isOnScreen && !this.viewed) {
          this.viewed = true;

          _.each(this.model.impressionUrls, function(url) {
            $.ajax({
              type: "GET",
              url: url
            });
          });
        }
      }, 200),

      onStoryPreview: function(evt) {
        var target = $(evt.currentTarget),
          story = this.model.storyModel,
          self = this;

        if (this.fromPage == "storyReading") {
          window.te.push(
            "event",
            "reading",
            "similar_stories",
            "promoted_story",
            "click",
            {
              current_storyid: this.storyId,
              storyid: story.get("id"),
              campaignid: target.data("author-name")
            }
          );
        }

        _.each(this.model.clickUrls, function(url) {
          $.ajax({
            type: "GET",
            url: url
          });
        });

        var preview = new app.views.BrowseStoryPreview({
          isHome: false,
          model: story
        });

        self
          .$("#preview-modal")
          .empty()
          .append(preview.render().$el);
        preview.openModal();
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
