var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a class=\"cover cover-home empty-cover-large\">\n  <div class=\"fixed-ratio fixed-ratio-cover\"></div>\n</a>\n\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a class=\"cover cover-home on-story on-navigate\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n  <div class=\"fixed-ratio fixed-ratio-cover\">\n    <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),92,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.title : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n  </div>\n</a>\n\n<div class=\"content\">\n  <a class=\"title meta on-story on-navigate\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),28,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n  <a class=\"category meta on-navigate\" href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "\">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a>\n\n  <div class=\"meta social-meta\">\n    <span class=\"read-count\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</span>\n    <span class=\"vote-count\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</span>\n  </div>\n\n  <div class=\"description\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),130,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.empty : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});