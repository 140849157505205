import tagModuleStoriesLarge from "core/templates/item-views/tag-module-stories-large.hbs";
import browseResults from "platform/browse/templates/browse-results.hbs";

const TAG_SEARCH_AD_REFRESH_TIME = 5000;
const TAG_SEARCH_AD_PLACEMENT = "tag_search";
const TAG_SEARCH_AD_LOGGEDOUT_PLACEMENT = "tag_search_loggedout";

(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "BrowseResults",
    app.views.IncrementalList.extend({
      className: "browse-results",
      template: browseResults,

      containerId: "#browse-results-item-view", // For the itemView

      constructor: function(options) {
        options = options || {};
        this.adTimeRefresh = Date.now();

        this.events = {
          // For story results only
          // No story preview modal for mobile-web; see WEB-6235
          "tap    .on-story-preview": app.get("device").is.mobile
            ? "navigateToStory"
            : "onStoryPreview",
          "click  .on-story-preview": "stopEvent"
        };

        this.model = options.model;
        this.filter = options.filter;
        this.tag = options.tag || "";
        this.topicModel = options.topicModel || null;
        this.isStories = false;

        // Is a topic or tag
        if (_.indexOf(["hot", "new"], this.filter) !== -1) {
          this.itemView = app.views.BrowseStoryItem;
          this.collection = new app.collections.BrowseTagStories(
            this.model.get("data").items.stories,
            {
              tags: this.tag || this.topicModel.get("name"),
              filter: this.filter,
              nextUrl: this.model.get("data").items.nextUrl
            }
          );
          this.isStories = true;
          this.trackViews;
        }
        // Tag related writers
        else if (this.filter === "writers") {
          this.itemView = app.views.TagModuleWriters;
          this.collection = new app.collections.DiscoverModuleUsers(
            this.model.get("data").items.users
          );
        }
        // Tag related reading lists
        else if (this.filter === "lists") {
          this.itemView = app.views.TagModuleLists;
          this.collection = new app.collections.DiscoverModuleLists(
            this.model.get("data").items.lists
          );
        }

        app.views.IncrementalList.prototype.constructor.apply(this, arguments);
      },

      initialize: function() {
        Handlebars.registerPartial(
          "core.item_views.tag_module_stories_large",
          tagModuleStoriesLarge
        );
        this.listenTo(
          app,
          "app:component:TagMetaItem:click",
          this.navigateToTagPage
        );
        _.bindAll(this, "trackViews");
        this.initializeTracking();
      },

      remove: function() {
        $(window).off("scroll", this.trackViews);
        app.views.IncrementalList.prototype.remove.apply(this, arguments);
      },

      renderItem: function(model, $container, index) {
        app.views.IncrementalList.prototype.renderItem.apply(this, arguments);

        index = index || this.collection.indexOf(model);
        $container = $container || this.getContainer();
      },

      getTemplateData: function() {
        return { stories: this.isStories };
      },

      getItemViewOptions: function() {
        var options = {};

        if (this.filter === "hot") {
          options.showStoryRank = true;
        }
        return options;
      },

      // For the desktop-web experience
      onStoryPreview: function(evt) {
        wattpad.utils.stopEvent(evt);
        var storyId = $(evt.currentTarget).data("story-id"),
          story = this.collection.get(storyId),
          preview = new app.views.BrowseStoryPreview({ model: story });

        this.$("#preview-modal")
          .empty()
          .append(preview.render().$el);
        preview.openModal();
        this.sendStoryClickTrackingEvent(story);
      },

      // For the mobile-web experience
      navigateToStory: function(evt) {
        wattpad.utils.stopEvent(evt);
        var storyId = $(evt.currentTarget).data("story-id"),
          story = this.collection.get(storyId);
        app.router.navigate(wattpad.utils.formatStoryUrl(story.get("url")), {
          trigger: true
        });
        this.sendStoryClickTrackingEvent(story);
      },

      navigateToTagPage: function(evt) {
        var tag = $(evt.currentTarget)
            .text()
            .trim(),
          urlPath = "/stories/";
        app.router.navigate(urlPath + encodeURIComponent(tag), {
          trigger: true
        });
      },

      sendStoryClickTrackingEvent: function(story) {
        var storyId = story.id,
          sources = story.sources || "",
          index = this.collection.indexOf(story),
          type = this.model.get("type");
        var tags;
        if (!this.tag && !this.topicModel) {
          tags = [];
        } else if (this.tag) {
          tags = this.tag.split(",");
        } else {
          tags = [this.topicModel.get("topic")];
        }

        window.te.push("event", "app", "story", null, "click", {
          storyid: storyId,
          page: "tag"
        });

        window.te.push("event", "story", null, null, "click", {
          storyid: storyId,
          page: "tag",
          algo_source: sources,
          query: tags,
          position: index,
          type: type
        });
      },

      initializeTracking: function() {
        if (this.collection instanceof app.collections.BrowseTagStories) {
          $(window).off("scroll", this.trackViews);
          this.viewedStories = [];
          $(window).on("scroll", this.trackViews);
        }
      },

      refreshSearchTagAd: function() {
        const currentTime = Date.now();
        if (currentTime - this.adTimeRefresh >= TAG_SEARCH_AD_REFRESH_TIME) {
          this.adTimeRefresh = currentTime;
          const adPlacement = wattpad.utils.currentUser().authenticated()
            ? TAG_SEARCH_AD_PLACEMENT
            : TAG_SEARCH_AD_LOGGEDOUT_PLACEMENT;
          const refreshAdEvent = new CustomEvent(`refresh-${adPlacement}`);
          window.dispatchEvent(refreshAdEvent);
        }
      },

      onShowMore: function(evt) {
        if (evt) {
          utils.stopEvent(evt);
        }
        app.views.IncrementalList.prototype.onShowMore.apply(this, arguments);
        this.refreshSearchTagAd();
      },

      trackViews: _.throttle(function() {
        var self = this;
        _.each(this.collection.models, function(story) {
          var storyId = story.id,
            sources = story.sources || "",
            index = self.collection.indexOf(self.collection.get(storyId)),
            type = self.model.get("type");

          var tags;
          if (!self.tag && !self.topicModel) {
            tags = [];
          } else if (self.tag) {
            tags = self.tag.split(",");
          } else {
            tags = [self.topicModel.get("topic")];
          }

          if (_.indexOf(self.viewedStories, storyId) === -1) {
            var storyEl = self
              .$("#browse-results-item-view > .browse-story-item")
              .find("[data-story-id='" + storyId + "']");

            if (utils.isOnScreen(storyEl, 0.5)) {
              self.viewedStories.push(storyId);
              window.te.push("event", "story", null, null, "view", {
                storyid: storyId,
                page: "tag",
                algo_source: sources,
                query: tags,
                position: index,
                type: type
              });
            }
          }
        });
      }, 350)
    })
  );
  app.mixin(app.views.BrowseResults, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
