const {
  getStoryDetailsEligibility
} = require("../../../../helpers/ads/get-ad-eligibility");

(function(app) {
  "use strict";

  app.router.on(
    "route:storyDetails",
    app.router.filter(["getTranslations", "getCategories"], storyId => {
      const model = new app.models.StoryModel({ id: storyId });

      // Fetch latest data for power users as they need to observe moderation
      // outcomes from other power users.
      const flushCachesOptions = {
        fresh: true, // will discard localStorage cache for new data
        cache: false // prompt jQuery XHR to cachebust API call (adds unique _ts param to GET)
      };

      let modelFetch = model.fetch(flushCachesOptions);

      let userModel;
      // Fetches separate model for admins to obtain additional fields.
      const fetchUserModel = username => {
        if (!username) {
          return Promise.resolve();
        }
        userModel = new app.models.User({ username: username });

        var userOptions = {
          fresh: true,
          data: {
            fields: userModel._recursiveFields(
              userModel.fields.concat("age", "country")
            )
          }
        };

        return Promise.all([
          userModel.loaded(userOptions),
          $.get(
            "/api/v3/users/" +
              username +
              "/stories?fields=stories(readCount)&limit=200"
          )
        ]).then(function([, fetchedUserModel]) {
          if (fetchedUserModel.stories && fetchedUserModel.stories.length) {
            var totalReads = _.sum(
              _.pluck(fetchedUserModel.stories, "readCount")
            );

            userModel.set("totalReads", totalReads);
          }
        });
      };

      const similarStoriesCollection = new app.collections.SimilarStories([], {
        storyId,
        limit: 10,
        baseStory: {},
        showDescription: true
      });

      const similarStoriesFromLocalStorage = app.local.get(
        similarStoriesCollection
      );

      const fetchSimilarStoriesCollection = similarStoriesFromLocalStorage
        ? Promise.resolve()
        : Promise.resolve(similarStoriesCollection.fetch({ fresh: false }));

      let premiumPicksObj = {};

      let adEligibility = [];

      // TODO: Waiting on similar stories collection slows down render, but we can't trigger re-render in react from here if we don't wait.
      // Consider potentially moving to react.
      Promise.all([modelFetch, fetchSimilarStoriesCollection])
        .then(() => {
          if (!model.get("isPaywalled")) {
            return;
          }

          const storyId = model.get("id");
          return window.store.dispatch(
            window.app.components.actions.fetchPaidContentMetadata(
              storyId,
              model.get("parts")
            )
          );
        })
        .then(() => {
          // Fetch user-model after story model has been fetched to retrieve author username.
          return Promise.resolve(
            fetchUserModel(model.get("user") && model.get("user").username)
          );
        })
        .then(() => {
          return Promise.resolve();
        })
        .then(() => {
          // ADS-885: Ads Pageview Event
          if (typeof __atha !== "undefined") {
            var id = __atha.sendPageView(
              "storylanding",
              parseInt(model.get("id"), 10),
              undefined,
              model.attributes
            );
          }
          return new Promise(resolve => {
            if (!wattpad?.testGroups.AD_ELIGIBILITY) return resolve();

            getStoryDetailsEligibility({
              story_id: Number(model.get("id"))
            }).then(response => {
              adEligibility = response;
              return resolve();
            });
          });
        })
        .then(() => {
          const userData = userModel ? userModel.attributes : model.get("user");
          let storyDetailsSettings = {
            model,
            userData,
            premiumPicks: premiumPicksObj,
            similarStories:
              similarStoriesFromLocalStorage ||
              similarStoriesCollection.toJSON(),
            signupModel: new app.models.Authsignup({}),
            adEligibility
          };

          const view = new app.views.NewStoryDetails(storyDetailsSettings);

          // Vietnamese is special-cased to deal with scrapers with good SEO
          const pageTitle =
            parseInt(app.get("language"), 10) === 19
              ? "Đọc Truyện " +
                model.get("title") +
                " - " +
                model.get("user").name +
                " - Wattpad"
              : model.get("title") +
                (model.get("user") ? " - " + model.get("user").name : "");

          // TODO: migrate paid content calls

          let deviceOptions;

          if (wattpad.utils.getDeviceType() === "mobile") {
            deviceOptions = {
              footerOptions: {
                appUrl: wattpad.utils.getAppLink("story-landing", model)
              }
            };
          }

          const noIndex = model.get("noIndex");

          app.transitionTo(view, {
            pageTitle,
            hasHeader: true,
            hasFooter: true,
            noIndex: noIndex,
            deviceOptions
          });

          const shouldSeeOnboarding = wattpad.utils.shouldSeePaidOnboarding(
            model.get("isPaywalled") && wattpad.utils.isPaidStory(model)
          );

          if (shouldSeeOnboarding) {
            const storyId = model.get("id");
            wattpad.utils.showPaidOnboardingAfterDelay(storyId);
          }
        })
        .catch(function(error) {
          // We don't want to swallow any errors as they should be self-descriptive.
          if (error instanceof Error) {
            throw error;
          }

          // Check that the incoming obj is the expected rejected promise format.
          if (error && error.responseJSON) {
            // format we expect, console log message
            console.log(
              `Error in (new) story-details controller: ${
                error.responseJSON.message
              }`
            );
          } else {
            // We have gotten a promise response (or something else) back in a format that we do not
            // expect. Logging out an appropriate message to identify affected area.
            console.log("Unknown error in new story-details controller");
          }

          // TODO: `route:error-404` currently does not work on old story details or new.
          // Need to figure out what why error-404 controller performs a SSR level reload
          // on CSR. Not sure if we can 404 redirect on CSR independently if that's the case.
          // TODO: validate error handling works as intended. Couple with SSR error handling task to make sure both work independently.
          app.router.trigger("route:error-404", "story");
        });
    })
  );
})(window.app);
