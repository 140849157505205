var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<form class=\"form-horizontal dropdown\" role=\"search\" name=\"search\" action=\"/search/\" action=\"GET\" autocomplete=\"off\">\n  <input id=\"search-query\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search",{"name":"trans","hash":{},"data":data}))
    + "\" name=\"q\" type=\"text\" spellcheck=\"false\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search",{"name":"trans","hash":{},"data":data}))
    + "\" value=\""
    + alias3(((helper = (helper = helpers.searchTerm || (depth0 != null ? depth0.searchTerm : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"searchTerm","hash":{},"data":data}) : helper)))
    + "\">\n  <button type=\"submit\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Submit search",{"name":"trans","hash":{},"data":data}))
    + "\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-search",18,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </button>\n  <input type=\"hidden\" name=\"ref\" value=\"1\">\n  <div class=\"dropdown-menu items\" aria-label=\"search-dropdown\">\n      <ul aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Autocomplete",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"autocomplete-item-container\"></ul>\n  </div>\n  <div class=\"triangle\"></div>\n</form>\n";
},"useData":true});