var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <img class=\"header-image\" src=\"img/retargeting/mw-stories-img@2x.png\" alt=\""
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Wattpad Stories",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <img class=\"header-image\" src=\"img/retargeting/dw-stories-img@2x.png\"  alt=\""
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Wattpad Stories",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " owl-carousel";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "style=\"direction:"
    + ((stack1 = helpers["if"].call(alias1,(helpers.rtlLanguage || (depth0 && depth0.rtlLanguage) || helpers.helperMissing).call(alias1,(depth0 != null ? depth0.languageId : depth0),{"name":"rtlLanguage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\"";
},"8":function(container,depth0,helpers,partials,data) {
    return "rtl";
},"10":function(container,depth0,helpers,partials,data) {
    return "ltr";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"retargeting-landing\">\n  <div class=\"container-fluid\">\n    <div class=\"row\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      <div class=\"container\">\n        <div class=\"row\">\n          <div class=\"retargeting-header\">\n            <img class=\"logo\" src=\"img/retargeting/dw-logo.png\" alt=\"Wattpad Logo\">\n            <h1> "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Come around here often?",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + " 😉</h1>\n            <p>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sign up for Wattpad to get access to so much more",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n          </div>\n        </div>\n        <div class=\"row\">\n          <div class=\"value-props\">\n            <div class=\"value-props-wrapper"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n                 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n\n              <div class=\"col-md-4 col-xs-12 col-sm-12 col-lg-4\">\n                <div class=\"value-prop\">\n                  <span class=\"icon\">📚</span>\n                  <h6 class=\"title\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Endless Entertainment",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</h6>\n                </div>\n              </div>\n              <div class=\"col-md-4 col-xs-12 col-sm-12 col-lg-4\">\n                <div class=\"value-prop\">\n                  <span class=\"icon\">📱</span>\n                  <h6 class=\"title\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Read Offline Anywhere, Anytime",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</h6>\n                </div>\n              </div>\n              <div class=\"col-md-4 col-xs-12 col-sm-12 col-lg-4\">\n                <div class=\"value-prop bookmark\">\n                  <span class=\"icon\"><img src=\"img/retargeting/d-bookmark-icon@1x.png\" alt=\"bookmark\"></span>\n                  <h6 class=\"title\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Save Your Spot",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</h6>\n                </div>\n              </div>\n            </div>\n\n          </div>\n        </div>\n        <button class=\"btn on-signup\" role=\"button\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sign up",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"useData":true});