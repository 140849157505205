/*
 * OverlayTransition
 * - shows the toView and hides the fromView rather than destroying it
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "OverlayTransition",
    app.transitions.BaseTransition.extend({
      start: function(fromView, toView, options) {
        // walk the DOM for modals; close them before continuing
        if ($("body").hasClass("modal-open")) {
          var self = this;
          $(".modal.in").one("hidden.bs.modal", function() {
            self.changeViews(fromView, toView, options);
          });
          $(".modal.in").modal("hide");
        } else {
          this.changeViews(fromView, toView, options);
        }

        return toView;
      },

      changeViews: function(fromView, toView, options) {
        // opening the overlay
        if (!fromView.from) {
          toView.render(options);
          toView.from = fromView;
          fromView.$el.hide();
          this.injectDOM(fromView, toView, "#app-container");
        }

        // returning from the overlay
        else if (fromView.from && fromView.from === toView) {
          fromView.remove();
          toView.$el.show();
        }
      }
    })
  );
})(window, Monaco, wattpad, window.app);
