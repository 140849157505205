(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "OnboardingListStories",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      listId: null,

      eventsOn: false,

      fields: [
        "id",
        "name",
        {
          stories: [
            "id",
            "title",
            "cover",
            "voteCount",
            "readCount",
            "categories"
          ]
        },
        "total",
        "nextUrl"
      ],

      initialize: function(models, options) {
        options = options || {};
        if (!options.listId) {
          throw new Error("ReadingListStories: listId cannot be null");
        }

        this.listId = options.listId;

        if (options.nextUrl) {
          this.nextUrl = options.nextUrl;
        }

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      defaultUrl: function() {
        return "/api/v3/lists/" + this.listId + "/stories";
      },

      resource: function() {
        return "onboarding-list." + this.listId + ".stories";
      }
    })
  );
})(window, _, wattpad, window.app);
