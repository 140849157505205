const {
  ReaderPlacement,
  CommentPlacement,
  SearchPlacement,
  StoryDetailsPlacement,
  BrowsePlacement
} = require("@wattpad/client-platform-web-ads");
const generateUUID = require("../generate-uuid");

/**
 * Adds a unique uuid to to each placement
 * @param {Array} placements - placement array
 */
const addReferenceId = placements => {
  placements.forEach(placement => {
    placement.reference_id = generateUUID();
  });
};

const readerAndCommentPlacements = {
  [ReaderPlacement.ReaderBottom]: 0,
  [ReaderPlacement.ReaderMid]: 1,
  [ReaderPlacement.ReaderTop]: 2,
  [ReaderPlacement.ReaderVideo]: 3,
  [CommentPlacement.Comments]: 4,
  [CommentPlacement.CommentsInline]: 5
};

/**
 * Retrieves a list of placements for story.js and story-reading.js
 * @param {number} story_id - group id
 * @param {number} part_id - part id
 * @returns {Array} placement array
 */
const getStoryReadingPlacements = ({ story_id, part_id }) => {
  const placementPart = {
    story_id,
    part_id
  };

  const storyReadingPlacements = Object.keys(readerAndCommentPlacements).map(
    placementName => {
      return { ...placementPart, name: placementName };
    }
  );
  addReferenceId(storyReadingPlacements);

  return storyReadingPlacements;
};

/**
 * Retrieves a list of placements for the server and client search.js controller
 * @returns {Array} placement array
 */
const getSearchPlacements = () => {
  const searchPlacements = [
    {
      name: SearchPlacement.Search
    }
  ];
  addReferenceId(searchPlacements);

  return searchPlacements;
};

/**
 * Retrieves a list of placements for server and client story-details.js controller
 * @returns {Array} placement array
 */
const getStoryDetailsPlacements = ({ story_id }) => {
  const storyDetailsPlacements = [
    {
      name: StoryDetailsPlacement.StoryDetails,
      story_id
    }
  ];
  addReferenceId(storyDetailsPlacements);

  return storyDetailsPlacements;
};

/**
 * Retrieves a list of placements for server and client browse.js controllers (tag pages)
 * @returns {Array} placement array
 */
const getBrowsePlacements = () => {
  const browsePlacements = [
    {
      name: BrowsePlacement.TagSearch
    }
  ];
  addReferenceId(browsePlacements);

  return browsePlacements;
};

module.exports = {
  getStoryReadingPlacements,
  readerAndCommentPlacements,
  getSearchPlacements,
  getStoryDetailsPlacements,
  getBrowsePlacements
};
