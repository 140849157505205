const cheerio = require("cheerio");
const { getOutstreamAd } = require("@wattpad/client-platform-web-ads");
const isServer = require("../is-server")();

/**
 * Retrieves and inserts the outstream ad unit into the story text
 *
 * @param {string} html - story HTML
 * @param {Object} options - includes storyGroup and experience data
 * @returns {string} story text with inserted outstream advertisement
 */
module.exports = (html, options) => {
  const AD_INSERT_PARAGRAPH_INDEX = 2;
  const $dom = cheerio.load(html),
    $paragraphs = $dom("p");
  let user, testGroups;
  const placement = "outstream_video";

  if (isServer) {
    user = options?.data?.root?.currentUser || undefined;
    testGroups = options?.data?.root?.testGroups;
  } else {
    user = window?.wattpad?.utils?.currentUser()?.attributes || undefined;
    testGroups = window?.wattpad?.testGroups;
  }

  const storyGroup = {
    ...options.hash.storyGroup,
    brandSafetyLevel: options.data.root.brandSafetyLevel,
    brandSafetySource: options.data.root.brandSafetySource
  };

  const { renderAd, markup } = getOutstreamAd({
    storyGroup,
    user,
    experience: options.hash.experience
      ? options.hash.experience.toLowerCase()
      : void 0,
    timestamp: options.hash.timestamp,
    testGroups
  });

  if (!isServer) {
    if (!renderAd.result) {
      __atha.sendSkip(placement, renderAd.reason, storyGroup);
    }
  }

  // Need to pad AD_INSERT_PARAGRAPH_INDEX
  if ($paragraphs.length < AD_INSERT_PARAGRAPH_INDEX + 1) {
    $paragraphs.append(markup);
  } else {
    $paragraphs.each(function(index, paragraph) {
      if (index !== AD_INSERT_PARAGRAPH_INDEX) return;
      $dom(paragraph).after(markup);
    });
  }

  return $dom.html();
};
