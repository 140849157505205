import homeIndex from "core/templates/home/home-index.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "HomeIndex",
    app.views.RegionManager.extend({
      id: "home-index",
      template: homeIndex,

      subviews: {
        discover: app.views.DiscoverRows
      },

      events: {
        "tap .tab-control": "onTabNavigation", // mobile-web only
        "click .tab-control": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};
        this.section = options.section || "home";

        this.homeCollection = new app.collections.Home();
      },

      render: function() {
        this.$el.html(
          this.template({
            onBrowse: this.section === "browse",
            isMobile: app.get("device").is.mobile,
            testGroups: wattpad.testGroups
          })
        );

        this.renderRegion("#home-content");

        _.defer(function() {
          // Hack to show the spielbergo banner on this page only
          if ($("#announcements-container .spielbergo").length) {
            $("#announcements-container").removeClass("hide");
          }
        });
        return this;
      },

      renderRegion: function(regionId) {
        var self = this;

        if (this.section === "home") {
          var view = new this.subviews.discover({
            collection: this.homeCollection
          });

          if (this.homeCollection.length === 0) {
            // We want to get a new collection if the user refreshes
            Promise.resolve(
              this.homeCollection.fetchNextSet(
                utils.appOn() ? {} : { fresh: true }
              )
            ).then(function() {
              self.$(regionId).empty();
              self.setRegion(regionId, view.render());
            });
          } else {
            this.$(regionId).empty();
            this.setRegion(regionId, view.render());
          }
        }
        // Mobile-web only
        else if (this.section === "browse") {
          var view = new app.views.BrowseTopics();
          view.render();

          this.listenToOnce(view, "render:done", function() {
            self.$(regionId).empty();
            self.setRegion(regionId, view);
          });
        }
      },

      // Mobile-web only
      onTabNavigation: function(evt) {
        var $tab = $(evt.currentTarget);
        this.section = $tab.data("section");

        this.$(".tab-control").removeClass("active");
        $tab.addClass("active");

        this.renderRegion("#home-content");
        app.router.navigate($tab.find("a").attr("href"), { trigger: false });
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
