"use strict";
var _ = require("lodash");
var isServer = require("./is-server");

/**
 * Story Author AFP ClientId
 *
 * @param {object} AuthorUsername - The username of a wattpad Author. Hopefully one with stories
 * @param {object} serverReq - (Required only for backend) The request object when this utility is called on the server
 * @param {object} serverRes - (Required only for backend) The response object when this utility is called on the server
 * @returns {string} afpClientId -
 */

const serverAPICall = (url, req, res) => {
  const utilsFilePath = "../server/utils";
  const mod = module;
  const utils = mod.require(utilsFilePath);
  return utils.apiHttp
    .get(url, null, res, req)
    .then(result => {
      //apihttp does not catch 400 errors, but catches 500
      if (result?.code >= 400 && result?.code < 500) {
        throw result;
      } else {
        return result;
      }
    })
    .catch(err => {
      throw err;
    });
};

const clientAPICall = url => {
  return Promise.resolve(
    $.ajax({
      type: "GET",
      url: url
    })
  );
};

const logErrorCb = errorRes => {
  const error = JSON.stringify(errorRes) || "error stringifying errorRes";
  console.error(`AFP Author Lookup Error: ${error}`);
};

const apiCall = isServer() ? serverAPICall : clientAPICall;

module.exports = function(username, serverReq, serverRes) {
  return new Promise(async function(resolve) {
    if (!username) {
      return resolve(undefined);
    }

    const url = `/v5/adsense/authors/${username}/status`;

    return resolve(
      await apiCall(url, serverReq, serverRes)
        .then(userStatus => {
          if (userStatus && userStatus.isEnabled && userStatus.afpAccount) {
            return userStatus.afpAccount;
          } else {
            const respJson =
              JSON.stringify(userStatus) || "error stringifying errorRes";
            console.error(
              `AFP Author Lookup Error: malformed success response - ${respJson}`
            );
            return undefined;
          }
        })
        .catch(err => {
          if (
            // ignore 404's, they are expected for users that haven't been onboarded
            (!isServer() && err.responseJSON?.code === 404) ||
            (isServer() && err.code === 404) ||
            // ignore readyState === 0 errors on the client, they are due to cancelled xhr requests
            (!isServer() && err.readyState === 0)
          ) {
            return undefined;
          }
          logErrorCb(err);
          return undefined;
        })
    );
  });
};
