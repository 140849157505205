(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "OnboardingSuccess",
    app.views.DummyReactView.extend({
      component: "OnboardingSuccess",
      componentId: "onboarding-success",

      constructor: function(opts) {
        var options = {
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
