(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "OnboardingStories",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "url",
            "cover",
            "voteCount",
            "readCount",
            "numParts",
            "categories",
            "description",
            { user: ["name", "fullname", "avatar"] }
          ]
        },
        "total",
        "nextUrl"
      ],

      resource: function() {
        return "stories.onboarding";
      },

      defaultUrl: function() {
        return "/api/v3/stories?filter=onboarding";
      }
    })
  );
})(window, _, wattpad, window.app);
