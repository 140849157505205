var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "        <button class=\"btn btn-fan btn-white\" data-target=\""
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-follow",16,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span class=\"follow-text\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"Follow",{"name":"trans","hash":{},"data":data}))
    + "</span></button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<div class=\"author-info center-text hidden-sm hidden-md\">\n    <div class=\"avatar avatar-lg center-block\">\n        <a class=\"on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">\n            <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),128,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n        </a>\n    </div>\n    <div class=\"info\">\n        <a class=\"on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">\n            <span class=\"center-block\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"by <strong>%s</strong>",((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n        </a>\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),{"name":"isCurrentUser","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n";
},"useData":true});