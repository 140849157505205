(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverModuleUserItem",
    app.models.Base.extend({
      defaults: {
        avatar: null,
        description: null,
        name: null,
        numFollowers: null,
        numLists: null,
        numStoriesPublished: null,
        username: null
      }
    })
  );
})(window, _, wattpad, window.app);
