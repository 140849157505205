var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a class=\"cover cover-home empty-cover-large\">\n    <div class=\"fixed-ratio fixed-ratio-cover\">\n    </div>\n</a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a class=\"send-cover-event"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecentlyRead : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + " cover cover-home\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n    <div class=\"fixed-ratio fixed-ratio-cover\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(43, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return " on-continue-reading";
},"6":function(container,depth0,helpers,partials,data) {
    return " on-story-preview";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),142,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.title : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),92,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.title : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"content\" data-target=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPaidStory : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <a class=\"title meta "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecentlyRead : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n            "
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),(depth0 != null ? depth0.titleCharLimit : depth0),false,{"name":"simpleShorten","hash":{},"data":data}))
    + "\n        </a>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isProfileWorksView : depth0),{"name":"unless","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.promoted : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.program(26, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"description\">"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),(depth0 != null ? depth0.descCharLimit : depth0),false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTagRankings : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isProfileWorksView : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"supported-marker\">\n                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Wattpad Original",{"name":"trans","hash":{},"data":data}))
    + "\n            </div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "on-continue-reading";
},"17":function(container,depth0,helpers,partials,data) {
    return "on-story-preview";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a class=\"username meta on-navigate\" href=\"/user/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "        </a>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),30,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),30,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <span class=\"meta promotion-description-story-reading\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-badge-promoted",13,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Promoted by %s",(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<div class=\"lightbold\">%s</div>",(depth0 != null ? depth0.sponsorName : depth0),{"name":"sprintf","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </span>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <div class=\"meta social-meta\">\n                <span class=\"read-count\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",15,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n                </span>\n\n                <span class=\"vote-count\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",15,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n                </span>\n\n                <span class=\"part-count\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-list",15,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"count","hash":{},"data":data}))
    + "\n                </span>\n            </div>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                      data-toggle=\"tooltip\" data-placement=\"top\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Read","%s Reads",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n                    ";
},"29":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                      data-toggle=\"tooltip\" data-placement=\"top\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Vote","%s Votes",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n                    ";
},"31":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                      data-toggle=\"tooltip\" data-placement=\"top\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Part","%s Parts",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n                    ";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <div class=\"tag-ranking\">\n              "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/shared/ranking-medal-grey.png","ranking",{"name":"connectImage","hash":{"width":"22","height":"27","klass":"tag-ranking-medal"},"data":data}))
    + "\n              <span>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"#%1$s in %2$s",(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<b>%s</b>",((stack1 = ((stack1 = (depth0 != null ? depth0.tagRankings : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.rank : stack1),{"name":"sprintf","hash":{},"data":data}),(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<b>%s</b>",(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.tagRankings : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n              <a href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "/rankings\" class=\"on-ranking-navigate\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"See all rankings",{"name":"trans","hash":{},"data":data}))
    + "</a>\n            </div>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.tag_metadata"],depth0,{"name":"core.tag_metadata","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"isCurrentUser","hash":{},"fn":container.program(38, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"38":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.firstPartId : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0),"inverse":container.program(41, data, 0),"data":data})) != null ? stack1 : "");
},"39":function(container,depth0,helpers,partials,data) {
    return "\n        <span class=\"work-state\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Published",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"41":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <span class=\"work-state draft\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Draft",{"name":"trans","hash":{},"data":data}))
    + " <span class=\"text\">("
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"only visible to you",{"name":"trans","hash":{},"data":data}))
    + ")</span></span>\n        ";
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"content\" data-target=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPaidStory : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <a class=\"title meta "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecentlyRead : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),(depth0 != null ? depth0.titleCharLimit : depth0),false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</a>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isProfileWorksView : depth0),{"name":"unless","hash":{},"fn":container.program(46, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.promoted : depth0),{"name":"if","hash":{},"fn":container.program(49, data, 0),"inverse":container.program(51, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"description\">"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),(depth0 != null ? depth0.descCharLimit : depth0),false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTagRankings : depth0),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isProfileWorksView : depth0),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "            <div class=\"supported-marker meta\">\n                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Wattpad Original",{"name":"trans","hash":{},"data":data}))
    + "\n            </div>\n";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <a class=\"username meta on-navigate\" href=\"/user/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),{"name":"if","hash":{},"fn":container.program(47, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "")
    + "        </a>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),30,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"49":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <span class=\"meta promotion-description-story-reading\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-badge-promoted",11,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Promoted by %s",(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<div class=\"lightbold\">%s</div>",(depth0 != null ? depth0.sponsorName : depth0),{"name":"sprintf","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </span>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <div class=\"meta social-meta\">\n                <span class=\"read-count\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n                <span class=\"vote-count\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n                <span class=\"part-count\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-list",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.empty : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});