var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<section class=\"modal profile-modal fade\" id=\"userMute\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"userModal\" aria-hidden=\"true\">\n    <div class=\"modal-dialog modal-sm\">\n        <div class=\"modal-content\">\n            <header class=\"modal-header\">\n                <div class=\"pull-right top-right close\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                <h3 class=\"modal-title\" id=\"myModalLabel\">Ignore User</h3>\n            </header>\n            <div class=\"modal-body\">\n                <p>Both you and this user will be prevented from:</p>\n                <ul>\n                    <li>Messaging each other</li>\n                    <li>Commenting on each other's stories</li>\n                    <li>Dedicating stories to each other</li>\n                    <li>Following and tagging each other</li>\n                </ul>\n                <p><strong>Note:</strong><br />You will still be able to view each other's stories.</p>\n            </div>\n            <footer class=\"modal-footer\">\n                <a href=\"//support.wattpad.com/hc/articles/201463560\" class=\"pull-left\"><small>More Info</small></a>\n                <a role=\"button\" href=\"#\" class=\"btn btn-orange on-mute-user\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Continue",{"name":"trans","hash":{},"data":data}))
    + "</a>\n            </footer>\n        </div>\n    </div>\n</section>\n";
},"useData":true});