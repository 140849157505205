var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"panel panel-default2\">\n  <header class=\"panel-heading\">\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"panel-title\">\n      "
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Reading List","%s Reading Lists",(depth0 != null ? depth0.totalLists : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.totalLists : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "\n    </div>\n  </header>\n\n  <hr />\n\n  <div class=\"panel-body\">\n    <div id=\"lists-item-view\">"
    + ((stack1 = ((helper = (helper = helpers.listsItemViewContent || (depth0 != null ? depth0.listsItemViewContent : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"listsItemViewContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  </div>\n\n  <div class=\"show-more center-text hidden\">\n    <button class=\"btn btn-grey on-showmore\">\n      <span class='show-more-message'>\n        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n        <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n      </span>\n      <span class='loading-message hidden'>\n        "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading...",{"name":"trans","hash":{},"data":data}))
    + "</span>\n      </span>\n    </button>\n  </div>\n</div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <a href=\"/list\" class=\"settings on-navigate\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-settings",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",24,"wp-neutral-2","settings on-create-list",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "<div class=\"panel panel-default2 empty-placeholder\">\n  <header class=\"panel-heading\">\n    <div class=\"panel-title\"></div>\n    <div class=\"panel-subtitle\"></div>\n  </header>\n\n  <div class=\"panel-body\">\n    <div class=\"no-reading-list\">\n      <button class=\"btn btn-md btn-orange on-create-list\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Create Reading List",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n\n    <div class=\"story-cover\">\n      <div class=\"fixed-ratio fixed-ratio-cover\">\n      </div>\n    </div><!--\n\n --><div class=\"story-cover\">\n      <div class=\"fixed-ratio fixed-ratio-cover\">\n      </div>\n    </div><!--\n\n --><div class=\"story-cover\">\n      <div class=\"fixed-ratio fixed-ratio-cover\">\n      </div>\n    </div><!--\n\n --><div class=\"story-cover\">\n      <div class=\"fixed-ratio fixed-ratio-cover\">\n      </div>\n    </div>\n  </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.totalLists : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});