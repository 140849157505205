// This wrapper can render either the Block or UnBlock Modals
(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "BlockModal",
    app.views.DummyReactView.extend({
      constructor: function(opts) {
        this.modalType = opts.modalType;
        this.showRestrictedProfile = opts.showRestrictedProfile;

        this.component =
          this.modalType == "block" ? "BlockModal" : "UnblockModal";
        this.componentId =
          this.modalType == "block" ? "block-modal" : "unblock-modal";

        this.componentData = {
          username: opts.username,
          toggleModal: this.onClose,
          blockUser: this.onBlock.bind(this),
          onUnblock: this.onBlock.bind(this)
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      onBlock: function() {
        var username = this.componentData.username,
          actionType = this.modalType === "block" ? "PUT" : "DELETE",
          action = this.modalType,
          self = this,
          message;

        Promise.resolve(
          $.ajax({
            type: actionType,
            url: `/v4/users/${username}/blocks`
          })
        )
          .then(function(response) {
            if (response.success === false) {
              let errorMessage;
              try {
                if (response?.error?.message) {
                  errorMessage = response.error.message;
                }
              } catch {
                errorMessage = wattpad.utils.trans("There was an error blocking this user. Please try again."); //prettier-ignore
              }
              wattpad.utils.showToast(errorMessage, {
                type: "dismissable"
              });
            } else {
              window.store.dispatch(
                window.app.components.actions.setBlockedUsersFromBackBone(
                  username,
                  "block"
                )
              );
              if (self.showRestrictedProfile) {
                var userModel = new app.models.User({ username: username });
                Promise.resolve(userModel.loaded()).then(function() {
                  var blockedView = new app.views.RestrictedProfile({
                    profileType: "block",
                    model: userModel,
                    isMobile: app.get("device").is.mobile,
                    recentlyBlocked: true
                  });

                  app.transitionTo(blockedView, {
                    username: username,
                    hasHeader: true,
                    hasFooter: true
                  });

                  window.te.push("event", "app", "page", null, "view", {
                    page: "profile",
                    username: username
                  });
                });
                wattpad.utils.clearCommentLocalStorage();
                wattpad.utils.clearStoriesLocalStorage();
              } else {
                if (action == "block") {
                  message = utils.trans("%s is now blocked. You can unblock them at any time.", username); //prettier-ignore
                } else {
                  message = utils.trans("%s is now unblocked. You can block them at any time.", username); //prettier-ignore
                }
                utils.showToast(message, { type: "dismissable" });
              }
            }
          })
          .catch(function(err) {
            //This is used for internal system errors
            if (action == "block") {
              message = utils.trans("There was an error blocking this user. Please try again."); //prettier-ignore
            } else if (action == "unblocking") {
              message = utils.trans("There was an error unblocking this user. Please try again."); //prettier-ignore
            }

            utils.showToast(message, {
              type: "dismissable"
            });
          });
      },

      onClose: function(evt) {
        $("#generic-modal").modal("hide");
        // remove custom class with a delay so that there's no pop-in during
        // jQuery modal close animation
        setTimeout(function() {
          $("#generic-modal .modal-content").removeClass("block-modal-wrapper");
          $("#generic-modal .modal-dialog").removeClass("block-modal-dialog");
        }, 500);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
