(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "FacebookFriends",
    app.collections.BaseCollection.extend({
      arrayKey: "users",

      model: app.models.User,

      fields: [
        "total",
        {
          users: [
            "name",
            "avatar",
            "username",
            "following",
            "isPrivate",
            "numFollowers",
            "numFollowing",
            "backgroundUrl",
            "highlight_colour",
            "numStoriesPublished"
          ]
        }
      ],

      initialize: function() {
        this.username = wattpad.utils.currentUser().get("username");
        app.collections.BaseCollection.prototype.initialize.apply(
          this,
          arguments
        );
      },

      url: function() {
        return "/v4/users/" + this.username + "/facebook_friends";
      },

      resource: function() {
        return "v4." + this.username + ".fbfriends";
      },

      cacheLocal: true
    })
  );
})(window, _, wattpad, window.app);
