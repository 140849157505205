var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class='col-xs-12 col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-3'>\n  <header class='panel panel-reading'>\n    <h3 class=\"part-restart-chapter\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>  \n    <a href='"
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "' class='on-navigate restart-part'>\n      <div class='part-title'>\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Start from the beginning",{"name":"trans","hash":{},"data":data}))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-up",12,"wp-neutral-2","up-icon pull-right",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </a>\n  </header>\n</div>\n";
},"useData":true});