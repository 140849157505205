import userEventReplyBtn from "core/templates/events/partials/buttons/user-event-reply-btn.hbs";
import userEventFooter from "core/templates/events/partials/user-event-footer.hbs";
import message from "core/templates/events/message.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "MessageReply",
    app.views.Base.extend({
      // Properties
      // ----------
      tagName: "article",
      className: "user-event",

      template: message, // This looks the same as message activity item;
      // we should investigate merging this into the message
      // activity item view and trigger the behavior of reply
      // depending on whether isReply is true or false

      events: {
        // links
        "tap    .on-user": "onUser",
        "click  .on-user": "stopEvent",

        "tap    .on-user-mention": "onNavigate",
        "click  .on-user-mention": "stopEvent",

        // actions
        "tap  footer button.on-reply": "onReply",
        "click footer button.on-reply": "stopEvent",

        "tap  footer li .on-report": "onReport",
        "tap  footer li .on-delete": "onDelete"
      },

      // Methods
      // -------
      //

      initialize: function() {
        Handlebars.registerPartial(
          "core.events.partials.user_event_footer",
          userEventFooter
        );
      },

      render: function() {
        var data = {}, //TODO: Modify this (and activity item) so the data going in looks prettier and doesn't need 'data.'
          currentUserName = utils.currentUser().get("username");

        data.user = {
          name: this.model.get("from").name,
          avatar: this.model.get("from").avatar
        };

        data.message = this.model.toJSON();

        // block HTML tags in message body
        if (data.message.body) {
          data.message.body = utils.sanitizeHTML(data.message.body);
        }

        var canDelete =
          this.model.get("id") !== 0 &&
          (this.model.get("ownername") === currentUserName ||
            this.model.get("from").name === currentUserName ||
            wattpad.utils.currentUser().get("isSysAdmin"));

        var isValidMessage = this.model.get("id") !== 0;

        //If no menu options available, hide the menu
        var hideMenu = !isValidMessage;

        var templateData = {
          createDate: this.model.get("createDate"),
          data: data,
          canDelete: canDelete,
          isValidMessage: isValidMessage,
          repliesBtnText: wattpad.utils.trans("Reply"),
          hideMenu: hideMenu
        };
        templateData.leftButton = userEventReplyBtn(templateData);

        this.$el.html(this.template(templateData));

        if (this.model.get("from").name === this.model.get("ownername")) {
          this.$el.addClass("self");
        } else {
          this.$el.addClass("external");
        }

        return this;
      },

      // Navigation Events
      // -----------------
      onUser: function(event) {
        var $target = $(event.currentTarget);
        app.router.navigate("/user/" + $target.data("username"), {
          trigger: true
        });
      },

      onReply: function(event) {
        this.stopEvent(event);
        this.trigger("message:mention", this.model.get("from").name);
      },

      onReport: function(event) {
        this.stopEvent(event);
        var body = encodeURIComponent(this.model.get("body"));
        var author = this.model.get("from").name;

        utils.redirectToServer(
          "/help/report?author=" + author + "&comment=" + body
        );
      },

      onDelete: function(event) {
        this.stopEvent(event);
        if (
          window.confirm(
            wattpad.utils.trans("Are you sure you want to delete this comment?")
          )
        ) {
          this.model.destroy();
        }
      },

      stopEvent: function(event) {
        utils.stopEvent(event);
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
