var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return "        <img src=\""
    + container.escapeExpression(((helper = (helper = helpers.avatar || (depth0 != null ? depth0.avatar : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"avatar","hash":{},"data":data}) : helper)))
    + "\" alt=\"\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-user",26,"wp-neutral-2","vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "sr-only";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"avatar avatar-md\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.avatar : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n<div class=\"message\">\n    <label class=\"control-label "
    + ((stack1 = (helpers.supportsPlaceholder || (depth0 && depth0.supportsPlaceholder) || alias2).call(alias1,(depth0 != null ? depth0.device : depth0),{"name":"supportsPlaceholder","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" for=\""
    + alias4(((helper = (helper = helpers.viewId || (depth0 != null ? depth0.viewId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"viewId","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "</label>\n    <textarea id=\""
    + alias4(((helper = (helper = helpers.viewId || (depth0 != null ? depth0.viewId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"viewId","hash":{},"data":data}) : helper)))
    + "\" class=\"form-control count-input\" data-limit=\"2000\" data-counter=\"post-counter\" data-name=\"PostContent\" role=\"textbox\" aria-multiline=\"true\" placeholder=\""
    + alias4(((helper = (helper = helpers.placeholder || (depth0 != null ? depth0.placeholder : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholder","hash":{},"data":data}) : helper)))
    + "\"></textarea>\n    <div class=\"warning hidden\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",14,"wp-lighterror",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      <span class=\"text\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"There was a problem posting your message, please try again later.",{"name":"trans","hash":{},"data":data}))
    + "</span>\n    </div>\n    <div class=\"post-ui hidden\">\n        <button class=\"btn btn-sm btn-orange pull-right on-post\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Post",{"name":"trans","hash":{},"data":data}))
    + "</button>\n        <span class=\"post-counter hidden pull-right\"><span></span>/2000</span>\n    </div>\n</div>\n";
},"useData":true});