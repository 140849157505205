import profileRequest from "platform/profile/templates/profile-request.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Profile Sub-view -- Follower Request
   * @class ProfileRequest
   * @constructor
   */
  app.add(
    "ProfileRequest",
    Monaco.View.extend({
      // Properties
      // ----------
      className: "follower-request",

      template: profileRequest,

      events: {
        "tap   .on-request-addressed": "onRequestAddressed",
        "click .on-request-addressed": "stopEvent"
      },

      // Methods
      // -------
      initialize: function() {
        this.requestItemView = null;
        Handlebars.registerPartial(
          "core.profile.profile_follow_request_item",
          function() {
            return "";
          }
        );
      },

      render: function() {
        this.$el.html(this.template(this.model.toJSON()));
        this.renderRequestItem();
        return this;
      },

      renderRequestItem: function() {
        this.requestItemView = new app.views.FollowRequestItem({
          model: this.model,
          inProfile: true,
          ignored: this.model.get("followerRequest") === "ign"
        });
        this.$el
          .find(".request-item-container")
          .html(this.requestItemView.render().$el);
      },

      setElement: function(el) {
        var $el = $(el);
        Monaco.View.prototype.setElement.call(this, el);

        if (this.requestItemView) {
          this.requestItemView.setElement(
            $el.find(
              "." + this.requestItemView.className.trim().replace(/ /g, ".")
            )
          );
        }
      },

      // TODO: add a better transition for removing/replacing the request item view
      onRequestAddressed: function(evt) {
        var $btn = $(evt.currentTarget);

        this.requestItemView.remove();
        // accepted -- remove the view
        if ($btn.hasClass("on-approve")) {
          this.remove();
          // accepted -- show the view in ignored state
        } else {
          this.model.set("followerRequest", "ign");
          this.renderRequestItem();
        }
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
