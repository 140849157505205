import profileTimeoutModal from "platform/profile/templates/profile-timeout-modal.hbs";
import profileCloseModal from "platform/profile/templates/profile-close-modal.hbs";
import profileMuteModal from "platform/profile/templates/profile-mute-modal.hbs";
import profileEdit from "platform/profile/templates/profile-edit.hbs";
import profileLayout from "platform/profile/templates/profile-layout.hbs";

import userBadges from "core/templates/components/user-badges.hbs";
import { MODAL_ID } from "../../../../../components/shared-components";

(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Top level View for the Desktop-Web User Profile page
   * Controls a 'User Card', displaying a user's basic information, and a set of navigable sub-sections
   * @Class UserProfile
   * @constructor
   */
  app.add(
    "ProfileLayout",
    app.views.RegionManager.extend({
      // Properties
      // ----------
      className: "profile-layout",
      template: profileLayout,

      partials: {
        profile_edit: profileEdit,
        profile_mute_modal: profileMuteModal,
        profile_close_modal: profileCloseModal,
        profile_timeout_modal: profileTimeoutModal,
        user_badges: userBadges
      },

      events: {
        // section navigation
        "tap   .on-nav-item": "onSectionNavigate",
        "click .on-nav-item": "stopEvent",

        // Public profile follow/unfollow - also see FollowManager mixin
        "tap   .on-follow-user": "onFollowUser",
        "click .on-follow-user": "stopEvent",

        // Private profile Follow & Unfollow
        "tap   .on-following-request": "onFollowingRequest", // req to follow (private) profile user
        "tap   .on-follower-request": "onFollowerRequest", // accept follow req from (public/private) profile user

        // Message
        "tap   .on-message": "onMessage",
        "click .on-message": "stopEvent",

        // touch states (used on tablet)
        "touchstart .on-touch": "setTouchState",
        "touchend   .on-touch": "removeTouchState",

        // edit profile
        "tap    .on-edit-profile": "onEditProfile",
        "tap    .on-edit-cancel": "onCancelEdit",
        "tap    .on-edit-save": "onSaveEdit",

        // user moderation
        "tap    .on-change-invalid-image": "onChangeInvalidImage",

        "tap    .on-block-user": "onBlockUser",
        "keypress .on-block-user": "onBlockUser",
        "tap    .on-timeout-user": "onTimeoutUser",
        "tap    .on-close-user-account": "onCloseUserAccount",

        // notice banner
        "tap    .on-notice-action": "onNoticeAction",
        "tap    .on-notice-cancel": "onNoticeCancel",

        "tap   .scroll-to-element": "scrollToElement",
        "click .scroll-to-element": "stopEvent",

        "tap   .on-followers": "showFollowersModal",
        "click .on-followers": "stopEvent",

        "tap   .on-view-conversations": "viewAllConversations",
        "click .on-view-conversations": "stopEvent"
      },

      /**
       * Section map for subview navigation via {@link setSection}
       * @namespace sections
       * @properties about | following | conversations
       */
      sections: {
        about: {
          View: app.views.ProfileMain
        },
        following: {
          View: app.views.ProfileFollowing,
          Collection: app.collections.ProfileFollowing
        },
        conversations: {
          View: app.views.ProfileConversations
        }
      },

      editMode: null, // set to null for first load

      reportModalContainer: ".profile-options-modal",
      requestType: "reported_users", // request type for the form
      isMainReportModal: true,
      modalTitle: wattpad.utils.trans("Report a User"),
      conductType: "user_account_conduct",
      featureFlagsPromise: Promise.resolve(),

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};

        this.featureFlagsPromise = options.featureFlagsPromise;
        this.privateMessageFeatureFlags = options.privateMessageFeatureFlags;

        this.username = options.username;
        this.sectionView = null;
        this.isPrivateToMe = options.isPrivateToMe;
        this.recentlyUnmuted = options.recentlyUnmuted;
        this.recentlyUnblocked = options.recentlyUnblocked;
        this.section = options.section || "about";
        this.isFollowersSection = false;
        this.showEditProfile = options.showEditProfile;

        // React Report Modal Props
        // TODO: delete the props for the old Report Modal when React Modal is 100% shipped out
        this.reportModalType = "user";
        this.reportModalId = {
          name: options.username,
          location: window.location.href
        };

        if (this.recentlyUnmuted) {
          const toastProps = {
            className: "unmute",
            body: wattpad.utils.trans("%s is now unmuted. You can mute them at any time.", options.username) //prettier-ignore
          };
          // Trigger this asynchronously.
          // Remove settimeout when react root is set up.
          setTimeout(() => {
            window.store.dispatch(
              window.app.components.actions.toggleToast(toastProps)
            );
          }, 0);
        }
        if (this.recentlyUnblocked) {
          const toastProps = {
            className: "unblock",
            body: wattpad.utils.trans("%s is now unblocked. You can block them at any time.", options.username) //prettier-ignore
          };
          // Trigger this asynchronously.
          // Remove settimeout when react root is set up.
          setTimeout(() => {
            window.store.dispatch(
              window.app.components.actions.toggleToast(toastProps)
            );
          }, 0);
        }

        if (options.section === "followers") {
          this.section = "about";
          this.isFollowersSection = true;
        }

        Handlebars.registerPartial(
          "desktop.profile.profile_mute_modal",
          this.partials.profile_mute_modal
        );
        Handlebars.registerPartial(
          "desktop.profile.profile_close_modal",
          this.partials.profile_close_modal
        );
        Handlebars.registerPartial(
          "desktop.profile.profile_timeout_modal",
          this.partials.profile_timeout_modal
        );
        Handlebars.registerPartial(
          "core.components.user_badges",
          this.partials.user_badges
        );

        this.listenTo(app, "app:profile:edit", this.onEditProfile);
        this.listenTo(app, "app:user:follow", this.updateToFollowingButton);
        this.listenTo(app, "app:user:unfollow", this.updateToFollowButton);
        this.listenTo(
          app,
          "profile:featureflags:privatemessage:get",
          this.updateMessageButton
        );
      },

      render: function() {
        var data = this.model.toJSON(),
          self = this;

        // WRITER-871: staff badge takes precedence over ambassador and verified badges
        var isStaff = this.model.isStaff();
        var isAmbassador = wattpad.utils?.currentUser()?.get("ambassador");
        var isSysAdmin =
          wattpad.utils?.currentUser()?.get("isSysAdmin") &&
          window.location.href.endsWith("/admin");
        if (isStaff) {
          data.ambassador = false;
          data.verified = false;
        }

        const showModerationOptions = wattpad.utils
          .currentUser()
          .authenticated();
        let isMuted = this.model?.get("safety")?.isMuted;
        let isBlocked = this.model?.get("safety")?.isBlocked;

        this.$el.html(
          this.template(
            _.extend(data, {
              showModerationOptions: showModerationOptions,
              recentlyUnmuted: this.recentlyUnmuted,
              recentlyUnblocked: this.recentlyUnblocked,
              onUserMuted: this.onUserMuted.bind(this),
              isMuted: isMuted,
              isBlocked: isBlocked,
              isPrivateToMe: this.isPrivateToMe,
              staff: isStaff,
              isAmbassador: isAmbassador,
              isSysAdmin: isSysAdmin,
              followingRequested: this.model.get("followingRequest") === "req",
              dataModel: this.model,
              testGroups: wattpad?.testGroups,
              privateMessageFeatureFlags: this.privateMessageFeatureFlags,
              onWalletClick: () => {
                const buyCoinsModal = utils.getModal(MODAL_ID.BUY_COINS_MODAL);
                buyCoinsModal.setData({
                  page: "profile",
                  device: app.get("device"),
                  appUrl: utils.getAppUrl()
                });
                buyCoinsModal.show();
              }
            })
          )
        );
        this.setSection(this.section);

        _.defer(function() {
          self.$("header [data-toggle=tooltip]").tooltip();
          self.$("nav [data-toggle=tooltip]").tooltip();

          if (self.isFollowersSection) {
            self.showFollowersModal();
          }
        });

        if (self.showEditProfile) {
          // Wrap with setTimeout of 0 to asynchronously pull this from the callback queue once
          // rendering the section is over.
          setTimeout(() => {
            self.onEditProfile();
          }, 0);
        }

        _.defer(this.afterRender.bind(this));
        return this;
      },

      afterRender: function() {
        // Doing this in here, instead of Initialise, in case the backend response happens after initial render.
        this.featureFlagsPromise.then(privateMessageFeatureFlags => {
          app.trigger(
            "profile:featureflags:privatemessage:get",
            privateMessageFeatureFlags
          );
        });
      },

      // has to be implemented as a function because of dynamic data
      // please keep consistent with mw user-profile.js
      reportMoreInfo: function() {
        return [{ key: "Reported User", val: window.location.href }];
      },

      reportUserName: function() {
        return this.model.get("username");
      },

      setElement: function(el) {
        var $el = $(el);
        app.views.RegionManager.prototype.setElement.call(this, el);

        // Attach child views
        if (this.sectionView && this.sectionView.className) {
          var selectorString = "";
          var selector = this.sectionView.className.trim().split(" ");
          _.each(selector, function(text) {
            selectorString += "." + text;
          });
          this.sectionView.setElement($el.find(selectorString.trim()));
        }
      },

      /**
       * generates a `view` for the specified section (mapped via {@link sections}) and injects it into the main content region
       * @param section { string }    section map key
       */
      setSection: function(section) {
        var collection,
          followingCount,
          $followingCountEl,
          viewID,
          $pageNav = this.$("#page-navigation"),
          self = this;

        $pageNav.find("nav li").removeClass("active");
        $pageNav
          .find("nav li[data-section=" + section + "]")
          .addClass("active");
        this.clearRegion("#content");

        if (section === "about") {
          // The about section is a simple view displaying data already loaded in `this.model`
          this.sectionView = new this.sections[section].View({
            model: this.model,
            isPrivateToMe: this.isPrivateToMe
          });
          this.setRegion("#content", this.sectionView.render());
        } else if (section === "conversations") {
          this.sectionView = new this.sections[section].View({
            model: this.model,
            isPrivateToMe: this.isPrivateToMe
          });
          this.setRegion("#content", this.sectionView.render());
        } else if (section === "quests") {
          setTimeout(() => {
            this.sectionView = new app.views.DummyReactView({
              component: "Quests",
              componentId: "quests",
              componentData: this.model
            });
            this.setRegion("#content", this.sectionView.render());
          }, 0);
        } else {
          // other sections employ a `Collection` and `CollectionView` to display auxiliary user data ( makes call to API/localStorage )
          collection = new this.sections[section].Collection([], {
            username: this.username,
            limit: 12
          });
          this.sectionView = new this.sections[section].View({
            model: this.model,
            collection: collection
          });
          this.setRegion("#content", this.sectionView);
          viewID = this.sectionView.cid;
          $.when(collection.fetchNextSet()).done(function() {
            if (viewID === self.sectionView.cid) {
              // ensure THIS view didn't get pulled away
              self.sectionView.render();
            }
          });

          // Update following count when unfollowing users from Own Profile (following tab)
          if (
            this.model.get("username") ===
              utils.currentUser().get("username") &&
            section === "following"
          ) {
            followingCount = this.model.get("numFollowing");
            $followingCountEl = $pageNav.find(
              'li[data-section="following"] span'
            );

            // on collection remove (unfollow event), update the follow count UI element in real-time
            collection.on("remove", function() {
              $followingCountEl.html(utils.count(followingCount - 1));
            });
          }
        }

        this.section = section;
        this._setTitle(section);
      },

      _setTitle: function(section) {
        var profileTitle;
        if (section === "about") {
          if (!this.model.get("name")) {
            profileTitle = this.username;
          } else {
            profileTitle =
              this.model.get("name") + " (" + "@" + this.username + ")";
          }
        } else if (section === "quests") {
          var name = this.username;
          if (this.model.get("name")) {
            name = this.model.get("name") + " (" + "@" + this.username + ")";
          }
          profileTitle = wattpad.utils.trans("%s's quests", name);
        } else if (section === "conversations") {
          var name = this.username;
          if (this.model.get("name")) {
            name = this.model.get("name") + " (" + "@" + this.username + ")";
          }
          profileTitle = wattpad.utils.trans("%s's conversations", name);
        } else if (section === "following") {
          profileTitle = wattpad.utils.trans(
            "People followed by %s",
            this.username
          );
        } else if (section === "followers") {
          profileTitle = wattpad.utils.trans(
            "People following %s",
            this.username
          );
        }
        wattpad.utils.setTitle(profileTitle);
      },

      onSectionNavigate: function(evt) {
        var $target = $(evt.currentTarget),
          section = $target.parent().data("section"),
          url =
            section === "about"
              ? "/user/" + this.username
              : "/user/" + this.username + "/" + section;

        if (
          !wattpad.utils.currentUser().authenticated() &&
          (section === "conversations" || section === "following")
        ) {
          wattpad.utils.stopEvent(evt); // To avoid the window url from changing
          this.onAuthPrompt(evt);
          if ($target.hasClass("num-following")) {
            $(".modal-backdrop").addClass("profile-show");
          }
        } else {
          app.router.navigate(url, { replace: true });
          this.setSection(section);
          wattpad.utils.pushEvent({}, "virtualPageview");
        }
      },

      viewAllConversations: function(evt) {
        utils.stopEvent(evt);

        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(evt);
          $(".modal-backdrop").addClass("profile-show");
        } else {
          window.location = $(evt.currentTarget).attr("href");
        }
      },

      // Called immediately after button press
      onFollowUser: function(evt) {
        if (utils.currentUser().authenticated()) {
          var numFollowers = this.model.get("numFollowers");

          // 'following' state has not been updated yet
          // i.e. you were following the user and now you want to unfollow
          numFollowers = this.model.get("following")
            ? numFollowers - 1
            : numFollowers + 1;

          this.$(".followers-count").text(utils.count(numFollowers));
          this.model.set("numFollowers", numFollowers);
        }
      },

      // Event triggered by FollowManager mixin
      updateToFollowingButton: function(username) {
        if (this.model.get("username") === username) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-white").addClass("btn-teal");
          $btn
            .find(".fa")
            .removeClass("fa-follow fa-wp-base-2-dark")
            .addClass("fa-following fa-wp-neutral-5");
          $btn.find(".truncate").text(utils.trans("Following"));

          this.model.set("following", true);
        }
      },

      // Event triggered by FollowManager mixin
      updateToFollowButton: function(username) {
        if (this.model.get("username") === username) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-teal").addClass("btn-white");
          $btn
            .find(".fa")
            .removeClass("fa-following fa-wp-neutral-5")
            .addClass("fa-follow fa-wp-base-2-dark");
          $btn.find(".truncate").text(utils.trans("Follow"));

          this.model.set("following", false);
        }
      },

      updateMessageButton: function(privateMessageFeatureFlags) {
        if (privateMessageFeatureFlags?.canSendPrivateMessage) {
          $(".on-message").removeClass("hidden");
        }
      },

      onFollowingRequest: function(evt) {
        var self = this,
          $btn = this.$(evt.currentTarget),
          requested = this.model.get("followingRequest") === "req",
          action = requested ? "unfollow" : "follow",
          newBtnClass = requested ? "btn-white" : "btn-teal",
          newBtnContent = requested
            ? utils.iconify("fa-follow", 16, "wp-base-2") +
              " " +
              utils.trans("Request")
            : utils.iconify("fa-following-request", 16, "wp-neutral-5") +
              " " +
              utils.trans("Requested"),
          newReqState = requested ? "" : "req";

        if (utils.currentUser().authenticated()) {
          $btn
            .removeClass("btn-teal btn-white")
            .addClass(newBtnClass)
            .html(newBtnContent);
          Promise.resolve(
            wattpad.utils
              .currentUser()
              .following()
              [action](this.model)
          ).then(function() {
            self.model.set("followingRequest", newReqState);
          });
        } else {
          this.onAuthPrompt(evt);
        }
      },

      // Touch State Methods (for tablets)
      setTouchState: function(evt) {
        $(evt.currentTarget).addClass("touched");
      },

      removeTouchState: function(evt) {
        $(evt.currentTarget).removeClass("touched");
      },

      // Edit Profile Methods
      // --------------------
      initEditProfile: function() {
        var self = this,
          data = this.model.toJSON();
        data.aboutCount = data.description.length;

        this.$el.append(this.partials.profile_edit(data));
        _.bindAll(
          this,
          "onAvatarSubmit",
          "onBackgroundSubmit",
          "onFileUploadComplete",
          "onFileUploadError"
        );

        // instantiate file upload handlers
        this.uploadBG = new window.ss.SimpleUpload({
          url: "/api/v3/users/" + this.username + "/image",
          name: "file",
          allowedExtensions: ["jpg", "jpeg", "png", "gif"],
          accept: "image/jpeg, image/png, image/gif",
          data: { type: "background" },

          button: this.$("#edit-background button"),
          maxSize: 1024, // KB

          // callbacks
          onSubmit: this.onBackgroundSubmit,
          onComplete: this.onFileUploadComplete,
          onExtError: this.onExtentionError,
          onSizeError: this.onSizeError,
          onError: this.onFileUploadError
        });

        this.uploadAvatar = new window.ss.SimpleUpload({
          url: "/api/v3/users/" + this.username + "/image",
          name: "file",
          allowedExtensions: ["jpg", "jpeg", "png", "gif"],
          accept: "image/jpeg, image/png, image/gif",
          data: { type: "avatar" },

          button: this.$("#edit-avatar button"),
          maxSize: 1024, // KB

          // callbacks
          onSubmit: this.onAvatarSubmit,
          onComplete: this.onFileUploadComplete,
          onExtError: this.onExtentionError,
          onSizeError: this.onSizeError,
          onError: this.onFileUploadError
        });

        // register input fields
        this.inputFields = {};

        this.$('.edit-profile input[type="text"], .edit-profile textarea').each(
          function() {
            self.inputFields[$(this).data("field")] = $(this);
          }
        );

        self.inputFields["gender"] = this.$("#update-gender");
      },

      onExtensionError: function() {
        window.alert(
          utils.trans(
            "The type of file you are trying to upload is not supported."
          )
        );
      },

      onSizeError: function() {
        window.alert(
          utils.trans(
            "The image you are trying to upload is too large, please upload a file smaller than 1MB."
          )
        );
      },

      onEditProfile: function() {
        if (!wattpad.utils.currentUser().get("verified_email")) {
          utils.showPleaseVerifyModal();
          return;
        }
        if (this.editMode === null) {
          this.initEditProfile();
        }
        this.showEditUI();
        this.editMode = true;
      },

      onDescriptionUpdate: function(isAcceptable) {
        this.$(".on-edit-save").prop("disabled", !isAcceptable);
        if (isAcceptable) {
          this.$(".description-counter").removeClass("count-error");
        } else {
          this.$(".description-counter").addClass("count-error");
        }
      },

      onSaveEdit: function() {
        var key,
          value,
          modelVal,
          updatedAttrs = {},
          originalAttrs = {},
          self = this;

        // update model values to reflect changes in content
        for (key in this.inputFields) {
          if (this.inputFields.hasOwnProperty(key)) {
            value = this.inputFields[key].val();
            modelVal = this.model.get(key);

            if (value !== modelVal) {
              originalAttrs[key] = modelVal;
              updatedAttrs[key] = value;
            }
          }
        }

        app.local.clear("user." + this.username);
        this.model.set(updatedAttrs);

        // persist model state to server
        this.model
          .save(updatedAttrs, {
            patch: true,
            type: "put"
          })
          .fail(function(response, xhr) {
            // rollback changes
            self.model.set(originalAttrs);
            // TODO: Display UI Based error message to user, instead of alert
            window.alert(
              utils.trans(
                "Something went wrong, your changes were not saved. "
              ) + (response.responseJSON.message || "")
            );
          });

        this.editMode = false;
        this.hideEditUI();
        this.setSection("about");
      },

      onCancelEdit: function() {
        var key;
        this.hideEditUI();

        // restore input elements content to previous values
        for (key in this.inputFields) {
          if (this.inputFields.hasOwnProperty(key)) {
            this.inputFields[key].val(this.model.get(key));
          }
        }

        // restore ui
        this.$(".on-edit-save").prop("disabled", false);
        this.resetCount();
        this.editMode = false;
      },

      // DOM Manipulation/Transition Classes
      showEditUI: function() {
        this.$(".edit-profile").removeClass("hidden faded");
        $("#header, .background-lg h3, #content").addClass("hidden");

        let genderCode;
        let gender = this.model?.get("gender")?.toLowerCase();

        switch (gender) {
          case "she" || "female":
            genderCode = "F";
            break;
          case "he" || "male":
            genderCode = "M";
            break;
          case "zie" || "other":
            genderCode = "O";
            break;
          //TODO: add gender cases for Not listed and I'd rather not say, we're getting unknown for both
          default:
            genderCode = "";
        }

        this.$('[value="' + genderCode + '"]').attr({ selected: "selected" });
      },

      hideEditUI: function() {
        var name = this.model.get("name"),
          username = this.model.get("username"),
          display = this.$(".profile-name").text();

        if (name && display !== name) {
          this.$(".profile-name").text(name);
        } else if (!name && display !== username) {
          this.$(".profile-name").text(username);
        }

        // Hide edit layout
        this.$(".edit-profile").addClass("hidden faded");
        $("#header, .background-lg h3, #content").removeClass("hidden");

        // Hide not-so SimpleUpload floating input file types
        $('input[type="file"]')
          .parent()
          .css("visibility", "hidden");
      },

      // File Upload Handlers
      // --------------------
      onAvatarSubmit: function(filename, extension, buttonEl) {
        // set DOM Elements for transition
        var $el = $("#edit-avatar"),
          transitionData;

        transitionData = {
          image: this.$(".avatar img"),
          button: $el.find("button"),
          loading: $el.find(".loading, .loading-bg")
        };

        transitionData.oldSrc = transitionData.image.attr("src");
        transitionData.image.attr("src", "");

        this.uploadValues = { type: "avatar", data: transitionData };
        this.uploadSubmitUI(transitionData);
      },

      onBackgroundSubmit: function(filename, extension, buttonEl) {
        // set DOM Elements for transition
        var $el = $("#edit-background"),
          transitionData;

        transitionData = {
          image: $("header.background-lg"),
          button: $("#edit-background button, #edit-overlay"),
          loading: $el.find(".loading")
        };

        transitionData.oldSrc = transitionData.image.css("background-image");
        transitionData.image.css("background-image", "");

        this.uploadValues = { type: "background", data: transitionData };
        this.uploadSubmitUI(transitionData);
      },

      onFileUploadComplete: function(filename, response, buttonEl) {
        var type = this.uploadValues.type,
          data = this.uploadValues.data,
          findTimestamp = /\.(\d)+\.(jpe*g|gif|png)/,
          newSrc,
          newModelVal,
          holder;

        this.uploadCompleteUI(data);

        if (type === "avatar") {
          newSrc = data.oldSrc.replace(findTimestamp, "." + Date.now() + ".$2");
          data.image.attr("src", newSrc);
          app.local.clear("user." + this.username);
          this.model.set("avatar", newSrc);
        } else if (type === "background") {
          if (data.oldSrc === "none") {
            // if this is the first time user is uploading the background image,
            // we have to manually build out the src url as platform does not send back the url.
            holder =
              window.location.origin +
              "/userbgs/" +
              this.model.get("username") +
              ".1920." +
              Date.now() +
              ".jpg";
            newSrc = 'url("' + holder + '")';
            newModelVal = holder;
          } else {
            newSrc = data.oldSrc.replace(
              findTimestamp,
              "." + Date.now() + ".$2"
            );
            // due to file divergence from server side image processing,
            // we need to replace timestamps on the model value separately
            newModelVal = this.model
              .get("backgroundUrl")
              .replace(findTimestamp, "." + Date.now() + ".$2");
          }

          data.image.css("background-image", newSrc);

          app.local.clear("user." + this.username);
          this.model.set("backgroundUrl", newModelVal);
        }
      },

      onFileUploadError: function(
        filename,
        errorType,
        status,
        statusText,
        response,
        buttonEl
      ) {
        window.alert(
          "Error during upload - " +
            status +
            ":" +
            statusText +
            " - " +
            response.msg
        );
      },

      // DOM Manipulation/Transition Classes
      uploadSubmitUI: function(data) {
        if (utils.supportsTransition()) {
          data.button
            .on(utils.transitionEndStrings, function() {
              $(this)
                .off(utils.transitionEndStrings)
                .addClass("longfade");
            })
            .addClass("faded");
        } else {
          data.button.addClass("faded");
        }

        data.loading.each(function() {
          $(this).removeClass("faded");
        });
      },

      uploadCompleteUI: function(data) {
        if (utils.supportsTransition()) {
          window.setTimeout(function() {
            data.button.each(function() {
              $(this)
                .on(utils.transitionEndStrings, function() {
                  $(this)
                    .off(utils.transitionEndStrings)
                    .removeClass("longfade");
                })
                .removeClass("faded");
            });
          }, 3600);
        } else {
          window.setTimeout(function() {
            data.button.removeClass("faded");
          }, 4000);
        }

        data.loading.each(function() {
          $(this).addClass("faded");
        });
      },

      // Moderation Methods
      // ------------------

      // Quick tool for Trust & Safety to remove an invalid avatar or background image
      onChangeInvalidImage: function(e) {
        var type = $(e.currentTarget).data("imagetype");
        var user = this.model.get("username");
        var reason = window.prompt(
          "Why is this being banned? Options: 'copyright' or 'banned content' or 'self-harm'",
          "copyright OR banned content OR self-harm"
        );
        if (!reason) {
          window.alert("Banning was cancelled.");
        } else {
          this.deleteImage(user, type, reason);
          app.local.clear("user." + user);
        }
      },
      // Helper for deleting images
      deleteImage: function(user, typeOfImage, reason) {
        Promise.resolve(
          $.ajax({
            type: "POST",
            data: {
              reason: reason,
              typeOfImage: typeOfImage
            },
            url: "/api/v3/internal/users/" + user + "/remove_image"
          })
        )
          .then(function(data) {
            window.alert("Successfully deleted " + typeOfImage + "!");
            window.location.reload();
          })
          .catch(function(err) {
            console.error(err);
            window.alert("Failed to delete " + typeOfImage + ".");
            window.location.reload();
          });
      },

      onUserMuted: function() {
        var username = this.model.get("username");
        var self = this;

        // if we don't clear the cache, the updated isMuted attribute
        // won't be immediately reflected in the model
        app.local.clear("user." + username);
        wattpad.utils.clearCommentLocalStorage();
        self.model.set("safety", {
          isMuted: true,
          isBlocked: self.model?.get("safety")?.isBlocked
        });

        let mutedProfileView = new app.views.RestrictedProfile({
          profileType: "mute",
          model: self.model,
          recentlyMuted: true,
          isMobile: app.get("device").is.mobile
        });

        app.transitionTo(mutedProfileView, {
          username: username,
          hasHeader: true,
          hasFooter: true
        });
      },

      onBlockUser: function() {
        var username = this.model.get("username");
        var self = this;
        window.store.dispatch(
          window.app.components.actions.setBlockedUsersFromBackBone(
            username,
            "block"
          )
        );

        $.ajax({
          type: "PUT",
          url: `/v4/users/${username}/blocks`,
          success: function(response) {
            if (response.success === false) {
              let errorMessage;
              try {
                if (response?.error?.message) {
                  errorMessage = response.error.message;
                }
              } catch {
                errorMessage = wattpad.utils.trans("There was an error blocking this user. Please try again."); //prettier-ignore
              }
              wattpad.utils.showToast(errorMessage, {
                type: "dismissable"
              });
            } else {
              // if we don't clear the cache, the updated safety attribute
              // won't be immediately reflected in the model
              app.local.clear("user." + username);
              wattpad.utils.clearCommentLocalStorage();
              wattpad.utils.clearStoriesLocalStorage();

              let blockedProfileView = new app.views.RestrictedProfile({
                model: self.model,
                recentlyBlocked: true,
                profileType: "block",
                isMobile: app.get("device").is.mobile,
                isMuted: self.model?.get("safety")?.isMuted
              });

              app.transitionTo(blockedProfileView, {
                username: username,
                hasHeader: true,
                hasFooter: true
              });
            }
          },
          error: function() {
            // This is used for internal system errors
            let   errorMessage = wattpad.utils.trans("There was an error blocking this user. Please try again."); //prettier-ignore

            wattpad.utils.showToast(errorMessage, {
              type: "dismissable"
            });
          }
        });
      },

      onCloseUserAccount: function(evt, win) {
        win = win || window;
        let el = document?.getElementById("close-reason");
        let reason = el.value;
        let reasonText = el.options[el.selectedIndex].text;

        if (!reason) {
          win.alert("You must select an option");
        } else {
          let confirmation = `You are closing this users' account because of ${reasonText}. Are you sure?`;
          if (win.confirm(confirmation)) {
            $.ajax({
              type: "POST",
              url: "/api/v3/users/" + this.model.get("username") + "/close",
              data: {
                reason
              },
              success: function() {
                win.alert("successfully closed user account");
                win.location.reload();
              },
              error: function(data) {
                win.alert(JSON.parse(data.responseText).message);
              }
            });
          } else {
            win.alert("User's account has not been closed");
          }
        }
      },

      onTimeoutUser: function(evt, win) {
        win = win || window;
        let reason = document.getElementById("timeout-reason").value;

        if (reason === "") {
          win.alert("You must specify a reason");
        } else {
          let duration = "172800";
          $.ajax({
            type: "POST",
            url: "/v4/users/" + this.model.get("username") + "/timeout",
            data: {
              duration,
              reason
            },
            success: function() {
              win.alert("successfully timed-out user");
              win.location.reload();
            },
            error: function(data) {
              if (data.responseText) {
                win.alert(JSON.parse(data.responseText).message);
              } else {
                win.alert("Something went wrong. Please try again");
              }
            }
          });
        }
      },

      // Notice Methods
      // ------------------
      //
      onNoticeAction: function() {
        window.open(
          "//support.wattpad.com/hc/articles/203154850-The-New-Profile-is-Here-",
          "_blank"
        );
      },

      onNoticeCancel: function() {
        $("body").removeClass("show-notice");
        wattpad.utils.setCookie("x-newpro-note", 1, 60);
      },

      scrollToElement: function(evt) {
        var fireTriggerNow = true;

        // Switch to the About tab, if not already there
        if (this.section !== "about") {
          this.setSection("about");
          app.router.navigate("/user/" + this.username, { replace: true });
          fireTriggerNow = false;
        }

        this.sectionView.setScrollToElementId(
          $(evt.currentTarget).data("id"),
          fireTriggerNow
        );
      },

      showFollowersModal: function(evt) {
        var self = this;
        var collection = new app.collections.ProfileFollowers([], {
          username: this.username,
          limit: 10
        });

        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(evt);
        } else {
          Promise.resolve(collection.fetchNextSet()).then(function() {
            var view = new app.views.ProfileFollowersFollowingModal({
              model: self.model,
              collection: collection
            });

            self._setTitle("followers");
            self
              .$("#follower-modal")
              .empty()
              .append(view.render().$el);
            view.showModal();

            self.$("#follower-modal").on("hidden.bs.modal", function() {
              if (self.isFollowersSection) {
                self.isFollowersSection = false;
                var section =
                  self.section === "about" ? "" : "/" + self.section;
                app.router.navigate("/user/" + self.username + section, {
                  replace: true
                });
              }
              self._setTitle(self.section);
              view.remove();
            });
          });
        }
      },

      onMessage: function() {
        if (!wattpad.utils.currentUser().get("verified_email")) {
          wattpad.utils.showPleaseVerifyModal();
          return;
        }

        window.location.href = `/inbox/${this.username}`;
      }
    })
  );

  app.mixin(
    app.views.ProfileLayout,
    "InputCounterManagement",
    "ReportManagement",
    "ReportConduct",
    "AuthPromptManagement",
    "FollowManager",
    "FacebookConnect"
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
