import reportModal from "core/templates/report-modal.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ReportModal",
    app.views.Base.extend({
      className: "report-modal modal fade",

      attributes: { role: "dialog" },

      template: reportModal,

      events: {
        "tap   .on-option-selected": "onOptionSelected",
        "click .on-option-selected": "onOptionSelected", // can't prevent default b/c radio

        "keyup .details-input": "onInputDetails",

        "tap   .on-prev": "onPrev",
        "click .on-prev": "stopEvent",

        "tap   .on-next": "onNext",
        "click .on-next": "stopEvent"
      },

      buttonStates: {
        SUBMITTED: 1,
        SUBMIT: 2,
        NEXT: 3
      },

      zendeskCustomFieldIds: {
        REQUEST_TYPE: 21523158, // a form on zendesk can be used to report multiple things (user,comment,etc)
        // the request type tells community what is being reported
        CONDUCT_REPORT: 22875340,
        //REPORT_REASON: 25264726,
        PLATFORM: 21519886, // value options: ( "web" || "mobile_web" )
        UI_LANGUAGE: 22708584, // this field should contain the language code for the users locale ( e.g. "en_us" )
        CONDUCT_TYPE: 360041074311 // conduct type tells community where is this report originating from
        // value options: "user_account_conduct", "story_comment_conduct", "public_message_conduct", "private_message_conduct"
      },

      initialize: function(options) {
        options = options || {};
        if (!options.requestType) {
          throw new Error(
            "ReportModal Mixin | support ticket request type not specified"
          );
        }

        if (!options.reportForm) {
          throw new Error(
            "ReportModal Mixin | report form specifications not given"
          );
        }
        this.requestType = options.requestType;
        this.moreInfo = options.moreInfo;
        this.reportForm = options.reportForm;
        this.mainModal = options.mainModal;
        this.modalTitle = options.modalTitle;
        this.reportUserName = options.reportUserName;
        this.conductType = options.conductType;

        // state tracking fields
        this.step = 1;
        this.requireDetail = false;
      },

      render: function() {
        var self = this;

        this.$el.html(this.template(this.reportForm));
        this.$el.on("hidden.bs.modal", function() {
          self.resetModal();
        });

        // for mw where the report button is not in the same view
        this.$el.addClass("main-report-modal");
        this.$(".modal-title").text(this.modalTitle);
        return this;
      },

      onOptionSelected: function(evt) {
        var currentOption = this.$(evt.currentTarget),
          moreDetail = currentOption.data("require-detail"),
          intext = currentOption.data("intext"),
          tagSelected = currentOption.data("tag");

        if (moreDetail) {
          this.setButtonState(false, this.buttonStates.NEXT);
          this.$(".reason-selected").html(currentOption.val());
          this.$(".details-input").attr("placeholder", intext);
          this.$(".checkbox").addClass("hide");

          this.$("." + tagSelected).removeClass("hide");
          this.requireDetail = true;
        } else {
          this.setButtonState(false, this.buttonStates.SUBMIT);
          this.requireDetail = false;
        }
      },

      onInputDetails: function(evt) {
        this.setButtonState(
          !this.$(evt.currentTarget).val().length,
          this.buttonStates.SUBMIT
        );
      },

      onNext: function(evt) {
        if (this.step === 1 && this.requireDetail) {
          this.toStep2();
        } else {
          this.toStep3();
        }
      },

      onPrev: function(evt) {
        if (this.step === 3 && this.requireDetail) {
          this.toStep2();
        } else {
          this.toStep1();
          this.$(":checkbox").prop("checked", false); // clear any checkboxes selected in Step 2
          this.$(".details-input").val("");
        }
      },

      toStep1: function() {
        this.step = 1;
        this.$(".btn-prev").addClass("hidden");
        this.$(".modal-body-content")
          .removeClass("step-2 step-3")
          .addClass("step-1");

        // since ths function is used to go backwards
        // an option will always be selected at this point
        this.setButtonState(
          false,
          this.requireDetail ? this.buttonStates.NEXT : this.buttonStates.SUBMIT
        );
      },

      toStep2: function() {
        this.step = 2;
        this.$(".btn-prev").removeClass("hidden");
        this.$(".modal-body-content")
          .removeClass("step-1 step-3")
          .addClass("step-2");
        this.setButtonState(
          !this.$(".details-input").val().length,
          this.buttonStates.SUBMIT
        );
      },

      toStep3: function() {
        var buttonPrev = this.$(".btn-prev"),
          contactInfo = this.$(".requester-contact-info");

        this.step = 3;
        this.$(".modal-body-content")
          .removeClass("step-1 step-2")
          .addClass("step-3");

        if (this.isRequesterValid()) {
          // hide contact info form
          buttonPrev.addClass("hidden");
          contactInfo.addClass("hidden");

          this.submitReport();

          // show success state
          this.$(".report-success").removeClass("faded");
          this.setButtonState(true, this.buttonStates.SUBMITTED);

          // show contact info form
        } else {
          buttonPrev.removeClass("hidden");
          contactInfo.removeClass("hidden");
          this.setButtonState(false, this.buttonStates.SUBMIT);
        }
      },

      isRequesterValid: function() {
        return (
          (this.$("#requester-name").hasClass("valid") &&
            this.$("#requester-email").hasClass("valid")) ||
          wattpad.utils.currentUser().authenticated()
        );
      },

      setButtonState: function(isDisabled, state) {
        var nextButton = this.$(".modal-footer > button");
        nextButton.prop("disabled", isDisabled);

        switch (state) {
          case this.buttonStates.SUBMITTED:
            nextButton.html(wattpad.utils.trans("Report submitted"));
            break;
          case this.buttonStates.NEXT:
            nextButton.html(wattpad.utils.trans("Next"));
            break;
          case this.buttonStates.SUBMIT:
          /* falls through */
          default:
            nextButton.html(wattpad.utils.trans("Submit report"));
        }
      },

      submitReport: function() {
        var reportComments,
          moreInfoStr = "";
        var tagSelected = this.$(
          "input[name=report-reason-option]:checked"
        ).data("tag"); //does checked mean you are looking only if it checked?
        // array of checkboxes
        var extraOption = this.$("." + tagSelected + " input").toArray();
        var self = this;
        // loop through each option and set field
        extraOption.forEach(function(element) {
          if (element.checked) {
            // find value in data-id, call it option_id
            var option_id = self.$(element).data("id");
            // set field for zendesk ticket, pulls tag for checked field
            self.model.setCustomField(
              option_id,
              self.$(element).data("checktag")
            );
          } else {
            // find value in data-id, call it option_id
            var option_id = self.$(element).data("id");
            // set field for zendesk ticket, pulls tag for unchecked field
            self.model.setCustomField(
              option_id,
              self.$(element).data("nochecktag")
            );
          }
        });

        if (this.moreInfo) {
          // compile it into readable lines
          moreInfoStr = _.reduce(
            this.moreInfo,
            function(result, info) {
              return result + "\n" + info.key + ": " + _.result(info, "val");
            },
            "\n"
          );
        }
        reportComments = this.$(".details-input").val() + moreInfoStr;

        this.model.set("comment", reportComments);
        this.model.set(
          "ticket_form_id",
          parseInt(
            this.$("form[data-ticket-form-id]").data("ticket-form-id"),
            10
          )
        );

        if (this.reportUserName) {
          this.model.set("username", this.reportUserName);
        }

        if (this.requestType == "reported_users") {
          this.model.set("reportType", "user");
        }

        this.model.set(
          "reason",
          this.$("input[name=report-reason-option]:checked").data("tag")
        );

        this.model.setCustomField(
          this.zendeskCustomFieldIds.REQUEST_TYPE,
          this.requestType
        );

        this.model.setCustomField(
          this.zendeskCustomFieldIds.UI_LANGUAGE,
          wattpad.utils.getCookie("locale") || "en_US"
        );
        this.model.setCustomField(
          this.zendeskCustomFieldIds.PLATFORM,
          app._settings.device.is.mobile ? "mobile_web" : "web"
        );

        //Removed the Report_Reason option as we don't think it's used and if that's the case, we should just remove the code
        this.model.setCustomField(
          this.zendeskCustomFieldIds.CONDUCT_REPORT, // changed this from REPORT_REASON, which was calling hate_or_harassment tag
          this.$("input[name=report-reason-option]:checked").data("tag")
        );

        this.model.setCustomField(
          this.zendeskCustomFieldIds.CONDUCT_TYPE,
          this.conductType
        );

        this.model.setRequester();

        this.model.save();

        // setting field to null so previous tags aren't added to new ticket
        extraOption.forEach(function(element) {
          // find value in data-id, call it option_id
          var option_id = self.$(element).data("id");

          // set field back to null
          self.model.setCustomField(option_id, null);
        });
      },

      resetModal: function() {
        // restore it to step 1 state
        this.step = 1;
        this.$(".btn-prev").addClass("hidden");
        this.$(".modal-body-content")
          .removeClass("step-2 step-3")
          .addClass("step-1");
        this.setButtonState(true, this.buttonStates.SUBMIT);

        // clear all the answers
        this.$("input[name=report-reason-option]").attr("checked", false); //This will clear any conduct report reason that was checked in a previous report
        //this.$( '.on-option-selected').val('');
        // leave the above this caused the second time you loaded the modal to not load Reason

        // clear the check boxes
        this.$(":checkbox").prop("checked", false);

        this.$(".details-input").val("");
        this.$("#requester-name > input").val("");
        this.$("#requester-email > input").val("");

        // clear validation
        this.$("#requester-name").removeClass("valid invalid warning");
        this.$("#requester-email").removeClass("valid invalid warning");
      }
    })
  );

  app.mixin(app.views.ReportModal, "ValidationView");
})(window, _, wattpad, window.app);
