/**
 * Takes a targetView and determines if it is exempt from our forced signup campaign. This is for client Backbone/Monaco use only.
 *
 * @param {Monaco.View} targetView
 * @returns {Boolean}
 */
const isForcedSignupExemptView = targetView => {
  if (targetView instanceof app.views.Base === false) {
    console.error(
      "isForcedSignupExemptView: invalid loggedOutSessionCount arugment provided"
    );
    return false;
  }

  return (
    targetView instanceof app.views.Authentication ||
    targetView instanceof app.views.Landing ||
    targetView instanceof app.views.ValidationSuccess
  );
};

module.exports = {
  isForcedSignupExemptView
};
