import tagMetadata from "core/templates/tag-metadata.hbs";
import discoverModuleStoriesLarge from "core/templates/item-views/discover-module-stories-large.hbs";

(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  var APPROX_CHARS_IN_LINE = 40;

  app.add(
    "DiscoverModuleStoriesLarge",
    app.views.StoryItem.extend({
      // Properties
      // ----------
      className: "discover-module-stories-large on-discover-module-item",
      template: discoverModuleStoriesLarge,

      initialize: function() {
        Handlebars.registerPartial("core.tag_metadata", tagMetadata);
      },

      render: function() {
        var data = this.model ? this.model.toJSON() : { empty: true };

        data.isRecentlyRead = this.options.isRecentlyRead;
        data.type = "stories";

        data.isDesktop = app.get("device").isDesktop();
        data.isProfileWorksView = this.options.isProfileWorksView;

        // ProfileWorks' collection does not contain the user object
        if (!data.user) {
          data.user = this.options.user;
        }

        // Character limits
        data.descCharLimit = data.isDesktop ? 170 : 130;
        data.descCharLimit = this.options.descCharLimit || data.descCharLimit;

        data.titleCharLimit = data.isDesktop ? 35 : 24;
        data.titleCharLimit =
          this.options.titleCharLimit || data.titleCharLimit;

        data.tagMeta = _.extend(
          this.formatTags(
            data.tags,
            app.get("device") && app.get("device").is.mobile ? 2 : 3
          ),
          { storyId: data.id }
        );
        data.showTagRankings =
          data.tagRankings &&
          data.tagRankings.length &&
          data.isProfileWorksView;

        if (data.isPaywalled) {
          // Make room for paid stories badge
          data.descCharLimit -= APPROX_CHARS_IN_LINE;

          if (data.tagMeta) {
            // Don't shown "+X tags"
            data.tagMeta.numNotShown = 0;
          }
        }

        data.isPaidStory = data.isPaywalled && wattpad.utils.isPaidStory(data);

        data.testGroups = wattpad.testGroups;

        this.$el.html(this.template(data));

        return this;
      },

      // Identical to React component TagMeta; used on Home
      // Output: { tags: [ 'tag1',... ], numNotShown: <int indicating total number of tags not shown> }
      formatTags: function(tags, numShown) {
        tags = tags || null;
        numShown = numShown || 3;

        if (_.isEmpty(tags)) {
          return null;
        }

        var tags = _.clone(tags),
          newTags = {
            tags: [],
            numNotShown: Math.max(tags.length - numShown, 0)
          },
          randomlySelectedTag = null;

        if (tags.length <= numShown) {
          _.each(tags, function(tag) {
            newTags.tags.push({ name: tag });
          });
        } else {
          while (newTags.tags.length < numShown) {
            randomlySelectedTag = tags.splice(
              Math.floor(Math.random() * tags.length),
              1
            )[0];
            newTags.tags.push({ name: randomlySelectedTag });
          }
        }

        return newTags;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
