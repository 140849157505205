var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "            <li>"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n<h3>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Some stories are no longer available",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n<a class=\"on-inactive-stories\" role=\"button\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn more",{"name":"trans","hash":{},"data":data}))
    + "</a>\n\n<div class=\"inactive-stories modal fade\" role=\"dialog\">\n  <div class=\"modal-dialog\" id=\"generic-modal\">\n    <div class=\"modal-content\">\n      <h3 class=\"modal-title text-center\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unavailable Stories",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n      <div class=\"close\" data-dismiss=\"modal\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"modal-body clearfix\">\n        <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"The following stories are no longer available because they were unpublished or deleted",{"name":"trans","hash":{},"data":data}))
    + ":</p>\n        <ul>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.stories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});