import libraryListsCreate from "core/templates/library/library-controls/library-lists-create.hbs";
(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "LibraryListCreate",
    app.views.Base.extend({
      template: libraryListsCreate,

      events: {
        "tap .on-createlist": "onCreateList"
      },

      constructor: function() {
        this.model = new app.models.ReadingList();
        this.model.set("action", "create");
        app.views.Base.prototype.constructor.apply(this, arguments);
      },

      render: function() {
        this.$el.html(this.template({}));
        return this;
      },

      onCreateList: function() {
        var self = this;

        this.model.save().done(function() {
          utils.cacheBust("ReadingLists", {
            username: utils.currentUser().get("username")
          });
          app.router.navigate("list/" + self.model.get("id"), {
            trigger: true
          });
        });
      }
    })
  );

  app.mixin(app.views.LibraryListCreate, "ValidationView");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
