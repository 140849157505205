import feedItemMessageOptions from "core/templates/feed-items/feed-item-message-options.hbs";
import feedPinnedMessage from "core/templates/feed-items/feed-pinned-message.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "PinnedItemMessage",
    app.views.NewFeedItemMessage.extend({
      className: "feed-item-new panel pinned-item",
      template: feedPinnedMessage,

      initialize: function(options) {
        Handlebars.registerPartial(
          "core.feed_items.feed_item_message_options",
          feedItemMessageOptions
        );
        Handlebars.registerPartial(
          "core.feed_items.feed_message_reply",
          function() {
            return "";
          }
        );

        var target = this.model.get("username");
        var autoMention = this.model.get("from").name;

        this.newMessageView = new app.views.NewMessageNew({
          to: target,
          parentId: this.model.get("id"),
          placeholder: wattpad.utils.trans("Write a reply..."),
          canBroadcast: false,
          autoMention: autoMention
        });
        this.listenTo(this.newMessageView, "message:new", this.expand);
        this.listenTo(this.newMessageView, "message:new", function(model) {
          this.model.get("latestReplies").push(model.toJSON());
        });

        // React Report Modal props
        this.reportModalType = "public-message";
        this.reportModalId = {
          name: this.model.get("username") || this.model.get("from").name,
          location: window.location.href,
          body: utils.sanitizeHTML(this.model.get("body"))
        };
      },

      render: function() {
        var self = this,
          data = {
            pinnedItem: _.cloneDeep(this.model.toJSON())
          };

        data.pinnedItem.currentUser = wattpad.utils.currentUser().toJSON();

        data.pinnedItem.numReplies =
          data.pinnedItem.numReplies - data.pinnedItem.latestReplies.length;
        data.pinnedItem.showTarget = false;
        data.pinnedItem.pinnedItem = this.pinnedItem;

        this.getOptionsData(data);

        data.id = data.pinnedItem.id;

        this.collection = new app.collections.NewMessageReplies(
          data.pinnedItem.latestReplies,
          {
            parentId: data.pinnedItem.id,
            messageOwnerName: this.model.get("username"),
            limit: 10
          }
        );

        this.repliesView = new app.views.MessageRepliesList({
          collection: this.collection,
          forFeeds: true
        });

        /* Checks the message model body to see if the user has embedded a valid story link -
       * if so, fetch extra metadata to beautify the URL and render the panel.
       */
        var beautifyUrlSettings = this.model.getBeautifyUrlSettings(
          data.pinnedItem
        );
        if (beautifyUrlSettings && !this.model.get("beautifyUrlMetadata")) {
          self.model
            .getMetadataForBeautifyUrl(beautifyUrlSettings)
            .then(function(result) {
              data.pinnedItem.beautifyUrlMetadata = result;
              self.$el.html(self.template(data));
              self.renderLatestReplies();
            });
        }
        this.$el.html(this.template(data));
        this.renderLatestReplies();
        return this;
      },

      onDeleteMessage: function(evt) {
        var modelData = this.model.toJSON(),
          self = this,
          message = new app.models.Message({
            id: modelData.id,
            body: modelData.body,
            createDate: modelData.createDate,
            from: {
              name: modelData.from.name,
              avatar: modelData.from.avatar,
              isActive: true
            },
            isReply: false,
            parentId: null,
            ownername:
              this.model.get("ownername") ||
              this.model.get("username") ||
              wattpad.utils.currentUser().get("username")
          });

        Promise.resolve(message.destroy()).then(function() {
          self.trigger("pinnedItem:remove", self.model);
        });
      }
    })
  );
  app.mixin(app.views.PinnedItemMessage, "ReportManagement", "ReportConduct");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
