var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "          <li class=\"col-xs-6 lists-menu list-unstyled"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),((stack1 = (depths[1] != null ? depths[1].category : depths[1])) != null ? stack1.id : stack1),{"name":"ifequal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <a class=\"on-navigate\" href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,(depth0 != null ? depth0.name_english : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\">"
    + alias3(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>\n          </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "<div data-category-id=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"choose-category-modal modal\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <header class=\"modal-header\">\n        <h1 class=\"modal-title\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"Categories",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n        <div class=\"close\" data-dismiss=\"modal\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-remove",18,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </header>\n      <div class=\"modal-body\">\n        <ul class=\"lists-menu list-unstyled\" aria-label=\""
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"Categories",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true,"useDepths":true});