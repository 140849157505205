(function(window, _, wattpad, utils, app) {
  "use strict";

  /* Base Collection
   * Base application collection class that all other collections inherit. Please
   * consult with team before adding new methods to this class.
   */
  app.add(
    "StoryStats",
    Monaco.Collection.extend({
      arrayKey: "analytics",
      cacheLocal: true,
      resource: function() {
        return "storystats." + this.id + "." + this.statType;
      },

      initialize: function(collection, options) {
        this.id = options.id;
        this.statType = options.statType;
      },

      url: function() {
        var url = "/v4/stories/" + this.id + "/" + this.statType;

        if (this.statType === "activities") {
          url += "?interval=30";
        }
        return url;
      },

      errors: {
        fetchError: utils.trans(
          "We seem to have had some issues getting your story's analytics. Please try again"
        ),
        1097:
          utils.trans("I'm sorry, I can't do that for you ") +
          utils.currentUser().get("username") +
          utils.trans(
            ". Your story must be published before you can view Writer Analytics."
          ),
        1016:
          utils.trans("I'm sorry, I can't do that for you ") +
          utils.currentUser().get("username") +
          utils.trans(
            ". You may only view Writer Analytics for your own stories."
          ),
        1018:
          utils.trans("I'm sorry, I can't do that for you ") +
          utils.currentUser().get("username") +
          utils.trans(". You must be logged in to view Writer Analytics."),
        1017: utils.trans(
          "Our apologies, the story you requested was not found."
        )
      }
    })
  );

  // Add Generate Fields Mixin
  app.mixin(app.collections.StoryStats, "CollectionParsing");
})(window, _, wattpad, wattpad.utils, window.app);
