/**
 * Global Filters - the following filters are shared by at least two modules of the application
 */

(function(window, _, Backbone, wattpad, app) {
  "use strict";
  if (!app) {
    return;
  }

  window.wattpad = wattpad || {};
  window.wattpad.utils = window.wattpad.utils || (window.wattpad.utils = {});

  if (typeof app.router.addFilter === "undefined") {
    console.warn("Missing router method", {
      instanceofBackbone: app.router instanceof Backbone.Router,
      instanceofMonaco: app.router instanceof Monaco.Router,
      typeofFilter: typeof app.router.filter,
      typeof_filters: typeof app.router._filters,
      typeofAddFilter: typeof window.Monaco.Router.prototype.addFilter
    });
  }

  // caches the category collection in memory to be used on controllers that requires it
  app.router.addFilter("getCategories", function(func, args) {
    // enabled the commented part if you need one category call per language
    if (
      !app.get("categories") ||
      app.get("categories").resource !==
        "system.categories." + app.get("language")
    ) {
      var categories = new app.collections.Categories(),
        _selfFilter = this;
      Promise.resolve(categories.fetch())
        ["catch"](function(resp) {
          // automatic retry
          var retryCount = app.get("retry:categories") || 0,
            status = _.result(resp, "status"),
            retryCodes = [0, 404, 408, 500, 502, 503, 504];

          if (retryCodes.indexOf(status) >= 0 && retryCount < 2) {
            app.set("retry:categories", retryCount + 1);
            return app.router._filters.getCategories.call(
              _selfFilter,
              func,
              args
            );
          }
          app.set("retry:categories", 0);

          //Give it an empty set
          app.set("categories", categories);
          return false;
        })
        .then(function(data) {
          return func.apply(_selfFilter, args);
        });
    } else {
      return func.apply(this, args);
    }
  });

  app.router.addFilter("requireLogin", function(func, args) {
    if (!wattpad.utils.currentUser().authenticated()) {
      app.router.navigate(
        "/login?nexturl=" + encodeURIComponent(window.location.href),
        { trigger: true, replace: true }
      );
      return false;
    } else {
      return func.apply(this, args);
    }
  });

  app.router.addFilter("getTranslations", function(func, args) {
    if (app.get("translatedLanguage") === undefined) {
      var translations = {
        domain: "messages",
        locale_data: {
          messages: wattpad.translation
        }
      };
      var tl = new app.collections.TranslatedLanguage(
        {},
        { translations: translations }
      );
      tl.fetch();
      app.set("translatedLanguage", tl);
    }
    return func.apply(this, args);
  });
})(
  window,
  _,
  Backbone,
  wattpad,
  window.app || (typeof app !== "undefined" ? app : null)
);
