(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "UserInvite",
    app.models.Base.extend({
      defaults: {
        id: null,
        emails: null,
        content: null
      },

      v2Url: "/apiv2/userinvites"
    })
  );

  app.mixin(app.models.UserInvite, "ApiV2");
})(window, _, wattpad, window.app);
