var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Read now",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Read",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                <p class=\"description\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),220,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                  <a class=\"on-story-details\">  <span class=\"read-more\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"More",{"name":"trans","hash":{},"data":data}))
    + "</span></a>\n                </p>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <div class=\"tag-ranking\">\n                      "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/shared/ranking-medal.png","ranking",{"name":"connectImage","hash":{"width":"22","height":"27","klass":"tag-ranking-medal"},"data":data}))
    + "\n                      <span>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"#%1$s in %2$s",(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<b>%s</b>",((stack1 = ((stack1 = (depth0 != null ? depth0.tagRankings : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.rank : stack1),{"name":"sprintf","hash":{},"data":data}),(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"<b>%s</b>",(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.tagRankings : depth0)) != null ? stack1["0"] : stack1)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                      <a href=\""
    + alias3(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "/rankings\" class=\"on-ranking-navigate\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"See all rankings",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"TagMeta",(depth0 != null ? depth0.id : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",12,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span class=\"completed text\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"completed",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",12,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span class=\"ongoing text\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"ongoing",{"name":"trans","hash":{},"data":data}))
    + "</span>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.modifyDate : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                        <time datetime=\""
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.modifyDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "\">\n                            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Updated %s",(helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.modifyDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n                        </time>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.lastPublishedPart : depth0)) != null ? stack1.createDate : stack1),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                                <time datetime=\""
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.lastPublishedPart : depth0)) != null ? stack1.createDate : stack1),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "\">\n                                    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Updated %s",(helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.lastPublishedPart : depth0)) != null ? stack1.createDate : stack1),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n                                </time>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    <div class=\"mature\">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",10,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Mature",{"name":"trans","hash":{},"data":data}))
    + "\n                    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"modal fade browse-preview\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"modal-content\">\n        <div class=\"modal-header clearfix\">\n            <button type=\"button\" class=\"close close-white\" data-dismiss=\"modal\" aria-label=\"Close\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",28,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </button>\n            <button type=\"button\" class=\"close close-mobile\" data-dismiss=\"modal\" aria-label=\"Close\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",28,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </button>\n        </div>\n        <div class=\"modal-body clearfix\">\n            <a href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-story-details cover cover-lg pull-left on-cover\">\n                <img src=\""
    + alias4((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),368,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.title : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n            </a>\n            <div class=\"content\">\n                <h3 class=\"modal-title\"><a href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"modal-title on-story-details\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a></h3>\n\n                <div class=\"meta\">\n                    <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",10,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n                    <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",10,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n                    <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-list",10,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.numParts || (depth0 != null ? depth0.numParts : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"numParts","hash":{},"data":data}) : helper)))
    + " </span>\n                </div>\n\n                <div class=\"actions\">\n                    <button class=\"on-start-reading btn btn-sm btn-inline btn-orange btn-left-align\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPaidPreview : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </button>\n                    <div class=\"button-group relative inline-block\">\n                        <button data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn-story-lists btn btn-sm btn-inline btn-orange btn-left-align btn-right-align\">\n                            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",10,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n                        </button>\n"
    + ((stack1 = container.invokePartial(partials["core.components.add_to_library_dropdown"],depth0,{"name":"core.components.add_to_library_dropdown","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTagRankings : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n                <div class=\"info\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.completed : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mature : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n\n            </div>\n        </div>\n    </div>\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials["core.components.add_to_library_modal"],depth0,{"name":"core.components.add_to_library_modal","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});