import searchField from "core/templates/header/search-field.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "SearchField",
    app.views.SearchFieldBase.extend({
      // Properties
      className: "dropdown",
      template: searchField,
      enabled: true,

      events: _.extend(
        {
          "input    #search-query": "onSearchInput",
          "blur     #search-query": "onSearchBlur",
          "keydown  #search-query": "onSearchKeyDown",
          "submit form": "onSubmit",
          "mousedown  .triangle": "onPopupClick",
          "mousedown  .autocomplete-item-container": "onPopupClick"
        },
        app.views.SearchFieldBase.prototype.events
      ),

      initialize: function(options) {
        this.selectedItem = null;
        this.searchResults = null;
        this.targetSelector = "#search-query";

        this.listenTo(app, "navbar:search:enable", this.enable);
        this.listenTo(app, "navbar:search:disable", this.disable);
        this.listenTo(app, "navbar:search:set", this.set);

        app.views.SearchFieldBase.prototype.initialize.apply(this, arguments);
      },

      render: function() {
        this.$el.html(this.template());
        return this;
      },

      renderAutocomplete: function(results) {
        this.$("ul.autocomplete-item-container").append(
          this.autocompleteTemplate({ results: results })
        );
      },

      showAutocompleteSuggestions: _.debounce(function(searchTerm) {
        var collection = new app.collections.Autocomplete([], {
            searchTerm: searchTerm
          }),
          self = this;

        Promise.resolve(collection.fetch()).then(function() {
          self.cleanup();
          if (collection.length) {
            // Need to save for search result traversal
            self.searchResults = collection.toJSON();

            self.renderAutocomplete(self.searchResults);
            self.showResults();
          }
        });
      }, 500),
      cancelAutocompleteLookup: function() {
        // cancel any debounce in progress
        this.showAutocompleteSuggestions &&
          this.showAutocompleteSuggestions.cancel();
      },
      showResults: function() {
        var $el = this.$el.children("form");
        if (!$el.hasClass("open")) {
          $el.addClass("open");
        }
      },

      hideResults: function() {
        var $el = this.$el.children("form");
        if ($el.hasClass("open")) {
          $el.removeClass("open");
          this.cleanup();
        }
      },

      cleanup: function() {
        this.$("ul").empty();
        this.selectedItem = null;
        this.searchResults = null;
      },

      /*
        Search Results Traversal
     */
      selectPrev: function() {
        if (this.selectedItem === null) {
          this.selectedItem = this.searchResults.length - 1;
          this.highlightSelected();
          return;
        }

        var itemVal = this.selectedItem - 1;
        if (itemVal < 0) {
          itemVal = this.searchResults.length - 1;
        }

        this.selectedItem = itemVal;
        this.highlightSelected();
      },

      selectNext: function() {
        if (this.selectedItem === null) {
          this.selectedItem = 0;
          this.highlightSelected();
          return;
        }

        var itemVal = this.selectedItem + 1;

        if (itemVal >= this.searchResults.length) {
          itemVal = 0;
        }
        this.selectedItem = itemVal;
        this.highlightSelected();
      },

      highlightSelected: function() {
        var itemId = this.searchResults[this.selectedItem].itemId;

        this.$(".autocomplete-item").removeClass("selected");
        this.$(".autocomplete-item#" + itemId).addClass("selected");
      },

      loadSelection: function() {
        if (this.selectedItem !== null) {
          var itemId = this.searchResults[this.selectedItem].itemId;
          this.$(".autocomplete-item#" + itemId)
            .children("a")
            .trigger("tap");
        }
      },

      // Event Handlers
      onSearchKeyDown: function(evt) {
        if (
          (evt.which === 13 && this.selectedItem) || // prevent enter
          evt.which === 38 ||
          evt.which === 40
        ) {
          // prevent caret move
          wattpad.utils.stopEvent(evt);
        }

        // hide autocomplete key scenarios
        switch (evt.key) {
          case "Backspace":
          case "Esc":
          case "Escape":
            this.hideResults();
            return;
        }

        // don't handle the keys when there's no results
        if (!this.searchResults || this.searchResults.length < 1) {
          return;
        }

        switch (evt.which) {
          case 38: // up key
            this.selectPrev();
            break;
          case 40: // down key
            this.selectNext();
            break;
          case 13: // enter key
            this.loadSelection();
            break;
        }
      },

      onSearchInput: function(evt) {
        var searchTerm = this.$(this.targetSelector).val();

        this.hideResults();
        this.cancelAutocompleteLookup();

        if (searchTerm.trim().length >= 2) {
          this.showAutocompleteSuggestions(searchTerm.trim());
        }
      },

      onPopupClick: function(evt) {
        evt.preventDefault();
      },

      onSearchBlur: function(evt) {
        var self = this;
        // allow some lead time for the user to click on a result before hiding the dropdown
        window.setTimeout(function() {
          self.hideResults();
        }, 500);
      },

      enable: function() {
        this.enabled = true;
      },

      disable: function() {
        this.enabled = false;
      },

      set: function(term, update) {
        var $search = this.$(this.targetSelector);
        $search.val(term);

        if (update !== false) {
          app.trigger("navbar:search:query", term);
        }
      },

      onSubmit: function(event) {
        this.hideResults();
        this.cancelAutocompleteLookup();

        let searchTerm = this.$("#search-query").val();
        searchTerm = this.formatSearchTerm(searchTerm);
        document.getElementById("search-query").value = searchTerm;
        this.saveSearchTerm(searchTerm);

        if (!this.enabled) {
          wattpad.utils.stopEvent(event);
          app.trigger("navbar:search:query", searchTerm);
        } else if (this.searchXHR) {
          event.stopPropagation();

          this.searchXHR.abort();
        }
      },

      formatSearchTerm: function(searchTerm) {
        if (searchTerm?.includes("#")) {
          //get the search terms and tags as individual words
          searchTerm = searchTerm.replace(/#/g, " #");
          let searchTermWords = searchTerm.split(/\s+/);

          const uniqueTags = new Set();
          const searchTermWithUniqueTags = [];

          //make new list with search term words and unique tags
          searchTermWords.forEach(word => {
            if (word.charAt(0) === "#") {
              const tag = word.replace(/\\/g, "");
              //remove \ from tags before adding to new list
              if (!uniqueTags.has(tag) && tag.length > 1) {
                uniqueTags.add(tag);
                searchTermWithUniqueTags.push(tag);
              }
            } else {
              searchTermWithUniqueTags.push(word);
            }
          });

          searchTerm = searchTermWithUniqueTags.join(" ");
        }
        return searchTerm.replace(/\s+/g, " ").trim();
      }
    })
  );
})(window, _, wattpad, window.app);
