import listsModal from "core/templates/mixins/lists-modal.hbs";
import libraryReadingListMeta from "core/templates/library/reading-list-details/library-reading-list-meta.hbs";
import libraryReadingListSidebar from "core/templates/library/reading-list-details/library-reading-list-sidebar.hbs";
import libraryReadingList from "core/templates/library/reading-list-details/library-reading-list.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  /*
  View for the Reading List Details Page (/list/:listid).
  */

  app.add(
    "CoreLibraryReadingList",
    app.views.IncrementalList.extend({
      template: libraryReadingList,

      partials: {
        sidebar: libraryReadingListSidebar,
        meta: libraryReadingListMeta,
        listModal: listsModal
      },

      id: "reading-list",

      containerClass: ".list-group",

      itemView: app.views.LibraryReadingListStoryItem,

      events: _.extend(
        {
          "tap .on-delete-list": "onDeleteList",
          "click .on-delete-list": "stopEvent",

          "tap .on-edit-list": "onToggleEditUI",
          "click .on-edit-list": "stopEvent",

          "tap .on-done-edit": "onDoneEditList",
          "click .on-done-edit": "stopEvent",

          "tap .on-batch-remove": "onBatchRemove",
          "click .on-batch-remove": "stopEvent",

          "tap .on-batch-add": "onShowBatchAddModal",
          "click .on-batch-add": "stopEvent",

          "tap .on-check-story": "onSelected",
          "click .on-check-story": "stopEvent",

          "tap .on-select-all": "onSelectAll",
          "click .on-select-all": "stopEvent",

          "tap .list-group-item": "sendStoryClickTrackingEvent",
          "click .list-group-item": "stopEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function(options) {
        options = options || {};

        if (!options.model) {
          throw new Error(
            "Invalid Reading List provided to LibraryReadingList view."
          );
        }

        this.collection = this.model.stories();
        this.selectedStoryIDs = [];
        this.inEditMode = false;

        var returnEmptyString = function() {
          return "";
        };

        var hbsPath = "core.library.reading_list_details.library_reading_list_";
        Handlebars.registerPartial(hbsPath + "sidebar", this.partials.sidebar);
        Handlebars.registerPartial(hbsPath + "meta", this.partials.meta);
        Handlebars.registerPartial(hbsPath + "story_item", returnEmptyString);
        Handlebars.registerPartial(
          "core.mixins.lists_modal",
          this.partials.listModal
        );
        Handlebars.registerPartial("core.signup_prompt", function() {
          return "";
        });

        this.listenTo(app, "app:user:follow", this.updateToFollowingButton);
        this.listenTo(app, "app:user:unfollow", this.updateToFollowButton);

        this.collection.once("sync", this.render, this);
        this.collection.fetchNextSet();

        this.$el.html(
          this.template({
            isLogged: utils.currentUser().authenticated()
          })
        );
      },

      render: function() {
        app.views.IncrementalList.prototype.render.apply(this, arguments);
        if (!utils.currentUser().authenticated()) {
          this.signUpPrompt = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            subtitle: utils.trans(
              "Get notified when your favorite stories are updated."
            )
          });
          // The following will replicate the setRegion function in region manager
          this.$el
            .find("#authentication-panel")
            .append(this.signUpPrompt.render().$el);
        }
        return this;
      },

      getItemViewOptions: function() {
        return {
          isOwner:
            this.model.get("user").name ===
            wattpad.utils.currentUser().get("username"),
          id: this.model.get("id")
        };
      },

      getTemplateData: function() {
        var data = {
          list: this.model.toJSON(),
          hasStories: this.collection.total > 0,
          // prevents displaying empty list message prematurely
          noStories: this.collection.total === 0,
          isOwner:
            this.model.get("user").name ===
            wattpad.utils.currentUser().get("username"),
          testGroups: wattpad.testGroups
        };

        var meta = {
          id: this.model.get("id"),
          cover: this.model.get("cover"),
          author: this.model.get("user").name,
          name: this.model.get("name")
        };

        data.socialLinks = this.buildSocialShareLinksForReadingList(meta);
        return data;
      },

      // Override onShowMore() in incremental-list.js
      // Need to setup the newly fetched stories for the DragAndSort mixin
      onShowMore: function(event) {
        if (event) {
          utils.stopEvent(event);
        }

        this.$(".show-more-message").addClass("hidden");
        this.$(".loading-message").removeClass("hidden");

        var self = this;
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          // for the DragAndSort mixin
          self.setupDOM();
          // show drag and sort UI handle for these stories
          if (self.inEditMode) {
            self.$(".list-group .on-edit-show").show();
            self.$(".list-group .on-edit-hide").hide();
          }

          self.$(".show-more-message").removeClass("hidden");
          self.$(".loading-message").addClass("hidden");
        });
      },

      onSocialShareSelected: function(evt) {
        window.te.push("event", "library", "reading_list", null, "share", {
          reading_listid: this.model.get("id"),
          channel: $(evt.currentTarget).data("share-channel")
        });
      },

      // Override because it is not a direct child
      getContainer: function() {
        return this.containerClass ? this.$(this.containerClass) : this.$el;
      },

      // Methods for Selecting and Unselecting Stories

      // On selection of a story, toggles the icon on/off
      onSelected: function(e) {
        var checkIcon = this.$(e.currentTarget).find(".checked-in");
        var storyID = this.getSelectedStoryID(checkIcon);
        this.toggleInput(checkIcon, storyID);

        // Toggle select all checkbox
        if (this.selectedStoryIDs.length >= this.collection.total) {
          this.$(".select-all .checked-in").show();
        } else {
          this.$(".select-all .checked-in").hide();
        }
      },

      // Show/hides the icon and saves story ID
      toggleInput: function(checkIcon, storyID, showOrHide) {
        showOrHide =
          showOrHide || (checkIcon.css("display") === "none" ? "show" : "hide");
        if (showOrHide === "show") {
          checkIcon.show();
          this.selectStoryID(storyID);
        } else {
          checkIcon.hide();
          this.unselectStoryID(storyID);
        }
      },

      // Saves story ID and updates number of stories selected
      selectStoryID: function(storyID) {
        if (!_.includes(this.selectedStoryIDs, storyID)) {
          this.selectedStoryIDs.push(storyID);
        }
        this.showNumberOfSelectedStories(this.selectedStoryIDs.length);
      },

      // Removes story ID and updates number of stories selected
      unselectStoryID: function(storyID) {
        this.selectedStoryIDs = _.without(this.selectedStoryIDs, storyID);
        this.showNumberOfSelectedStories(this.selectedStoryIDs.length);
      },

      //  Returns story ID of the icon
      getSelectedStoryID: function(el) {
        return $(el)
          .parent()
          .data("id");
      },

      // Updates the number of selected stories in bottom toolbar
      // Slides up upon selection; slides down when none are selected or 'edit mode' toggling is off
      showNumberOfSelectedStories: function(num) {
        if (num) {
          this.$(".num-selected").text(wattpad.utils.trans("%s Selected", num));
          this.$("#btn-batch-add, #btn-batch-remove").removeAttr("disabled");
          this.$(".list-controls").slideDown();
        } else {
          this.$(".list-controls").slideUp();
        }
      },

      // Selects all the stories, toggles on/off, and saves/removes the IDs
      onSelectAll: function(evt) {
        var selectAllCheckbox = this.$(".select-all .checked-in");
        selectAllCheckbox.toggle();

        var self = this;
        var selectAll = selectAllCheckbox.is(":visible");
        var allCheckIcons = this.$(".list-group-item .checked-in");
        allCheckIcons.each(function(index, el) {
          self.toggleInput(
            $(this),
            self.getSelectedStoryID(el),
            selectAll ? "show" : "hide"
          );
        });
      },

      // Attaches the modal to batch add stories to another reading list
      onShowBatchAddModal: function(evt) {
        var view = new app.views.LibraryBatchAddStories({
          stories: this.selectedStoryIDs,
          currentListId: this.model.get("id")
        });
        this.$el.append(view.$el);
      },

      // Batch remove stories based on the array of IDs stored
      onBatchRemove: function(evt) {
        var self = this;

        var storiesToRemove = this.selectedStoryIDs.map(function(id) {
          return self.collection.get(id);
        });

        if (
          window.confirm(
            wattpad.utils.trans(
              "Are you sure you want to remove these stories?"
            )
          )
        ) {
          self.collection.remove(storiesToRemove, {
            success: function() {
              self.model.set(
                "numStories",
                self.model.get("numStories") - storiesToRemove.length
              );
              _.each(self.selectedStoryIDs, function(id) {
                self.unselectStoryID(id);
              });
            },
            error: function(err) {
              console.error(err);
              window.alert(
                wattpad.utils.trans(
                  "The stories could not be removed. Please contact Wattpad support to resolve this problem."
                )
              );
              return;
            }
          });
        }
      },

      // Methods to hide/show 'edit mode' overlay
      onToggleEditUI: function() {
        this.inEditMode = !this.inEditMode;

        this.$(".on-edit-show, .on-edit-hide").each(function() {
          var el = $(this);
          el.toggle();
        });
      },

      hideEditUI: function() {
        // Update the list name
        this.$(".list-name > span").text(this.model.get("name"));
        // Toggle back to List view
        this.onToggleEditUI();
        // Hide the bottom fixed modal
        this.$(".list-controls").hide();
        // Update number of stories in the list
        this.$("#reading-list-meta").replaceWith(
          libraryReadingListMeta({
            list: this.model.toJSON()
          })
        );
      },

      // Checks to see if list can be renamed and model can be saved before toggling views
      onDoneEditList: function() {
        var self = this;
        var newName = this.$("#list-name-input")
          .val()
          .trim();
        var previousName = this.model.get("name").trim();

        if (
          !_.isEmpty(newName) &&
          newName.toLowerCase() !== previousName.toLowerCase()
        ) {
          self.model.set("name", newName);
          return Promise.resolve(this.model.save())
            .then(function() {
              self.hideEditUI();
            })
            .catch(function(err) {
              console.error(err);
              // If another reading list has the same name, revert back and don't toggle the EditUI
              self.model.set("name", previousName);
            });
        } else {
          self.hideEditUI();
        }
      },

      onDragEnd: function() {
        var $story = this.DragAndSort.element;
        var listId = this.model.get("id");
        app.local.clear("reading-list." + listId + ".stories");

        Promise.resolve(
          $.ajax({
            type: "PUT",
            url:
              "/api/v3/lists/" +
              listId +
              "/stories/" +
              $story.children().data("id") +
              "/position",
            data: {
              pos: $story.index()
            }
          })
        ).catch(function(err) {
          console.error(err);
          window.alert(
            wattpad.utils.trans(
              "This story could not be re-ordered. Please contact Wattpad support to resolve this problem."
            )
          );
        });
      },

      onDeleteList: function() {
        if (
          window.confirm(
            wattpad.utils.trans(
              "Are you sure you want to delete this reading list?"
            )
          )
        ) {
          utils.cacheBust(
            ["ReadingLists"],
            [
              {
                username: wattpad.utils.currentUser().get("username")
              }
            ],
            this.model.get("id")
          );
          this.model.destroy({
            success: function(model, response) {
              app.router.navigate("/list", {
                trigger: true
              });
            },
            error: function(model, response) {
              console.error(response);
              if (
                response !== undefined &&
                response.responseJSON.error_code === 1008
              ) {
                window.alert(
                  wattpad.utils.trans(
                    "This is your default list; it cannot be deleted"
                  )
                );
              } else {
                window.alert(
                  wattpad.utils.trans(
                    "We could not delete this reading list. Please contact us to resolve this problem."
                  )
                );
              }
            }
          });
        }
      },

      updateToFollowingButton: function(username) {
        var $btn = this.$(".btn-fan");
        $btn.removeClass("btn-white").addClass("btn-teal");
        $btn
          .find(".fa")
          .removeClass("fa-follow fa-wp-base-2-dark")
          .addClass("fa-following fa-wp-neutral-5");

        this.$(".created-by-user").text(
          utils.trans("Selected by %s", username)
        );
      },

      updateToFollowButton: function(username) {
        var $btn = this.$(".btn-fan");
        $btn.removeClass("btn-teal").addClass("btn-white");
        $btn
          .find(".fa")
          .removeClass("fa-following fa-wp-neutral-5")
          .addClass("fa-follow fa-wp-base-2-dark");

        this.$(".created-by-user").text(utils.trans("Follow %s", username));
      },

      sendStoryClickTrackingEvent: function(evt) {
        window.te.push("event", "reading_list", "story", null, "click", {
          reading_listid: this.collection.listId,
          storyid: $(evt.currentTarget)
            .find("div.clearfix")
            .data("id")
        });
      }
    })
  );

  app.mixin(
    app.views.CoreLibraryReadingList,
    "InfiniteScroll",
    "DragAndSort",
    "SocialSharing",
    "FollowManager",
    "FacebookConnect"
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
