/*
 * FadeTransition
 * - applies classes to fade the page in when transitioning from another view
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "FadeTransition",
    app.transitions.DefaultTransition.extend({
      start: function(fromView, toView, options) {
        options = options || { hasHeader: true };
        var doFade = false,
          self = this;

        // empty the container by default
        if (options.emptyContainer !== void 0 && !options.emptyContainer) {
          $("#app-container").empty();
        }

        // case: refresh
        // TODO: Investigate whether this is ever useful; if we are re-rendering the same view, then what's the point of this?
        if (fromView && toView.el === fromView.el) {
          fromView.remove();
          toView.render(options);
        }

        // case: transition A -> B
        else if (fromView) {
          toView.render(options);
          doFade = true;
        }

        // case: render first page
        else {
          toView.render(options);
        }

        // append the content of the main view to the DOM
        this.injectDOM(fromView, toView, "#app-container");

        if (!fromView) {
          toView.$el.addClass("fade-transition-done");
          if (options.hasHeader) {
            this.setupHeader();
          }
          this.setupFooter();
        }

        if (!wattpad.utils.supportsTransition()) {
          doFade = false;
        }

        if (doFade) {
          if (toView.delayedRender) {
            this.listenTo(toView, "render", function() {
              self.doTransition(fromView, toView);
            });
          } else {
            this.doTransition(fromView, toView);
          }
        } else {
          //Might already have been removed if the elements were the same
          if (fromView) {
            fromView.remove();
          }
          toView.$el.addClass("fade-transition-done");
          toView.$el
            .children()
            .first()
            .css("height", "auto");
        }

        wattpad.utils.hideAddressBar();

        this.scrollTo(window, fromView);

        return toView;
      },

      doTransition: function(fromView, toView) {
        var toHeight = toView.$el
            .children()
            .first()
            .height(),
          fromHeight = fromView.$el.height();

        if (fromHeight > toHeight) {
          toView.$el
            .children()
            .first()
            .height(fromHeight);
        } else {
          fromView.$el.height(toHeight);
        }

        toView.$el
          .addClass("fade-transition")
          .children()
          .first()
          .on(
            "webkitAnimationEnd oanimationend msAnimationEnd animationend",
            function() {
              fromView.remove();
              toView.$el.addClass("fade-transition-done");
              toView.$el
                .children()
                .first()
                .css("height", "auto");
            }
          );
      }
    })
  );
})(window, Monaco, wattpad, window.app);
