var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"share-tools vertical hidden-xs hidden-sm\">\n    <div class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share",{"name":"trans","hash":{},"data":data}))
    + "</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.socialShareVisible : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"button-group relative inline-block dropup\">\n        <button class=\"btn btn-no-background dropdown-toggle btn-left-align\" data-toggle=\"dropdown\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Open More Story Options",{"name":"trans","hash":{},"data":data}))
    + "\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </button>\n        <div class=\"triangle\"></div>\n        <ul class=\"dropdown-menu\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.socialShareHidden : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n</div>\n<div class=\"share-tools horizontal scrolling hidden-xs hidden-sm\">\n    <div class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Send",{"name":"trans","hash":{},"data":data}))
    + "</div>\n    <div class=\"send-to-friend\">\n        <input type=\"text\" class=\"form-control send-input\" id=\"exampleInputAmount\" placeholder=\"email or mobile #\">\n        <button class=\"btn btn-block btn-teal on-send\" type=\"button\" disabled>Send to Friend</button>\n    </div>\n\n    <div class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share",{"name":"trans","hash":{},"data":data}))
    + "</div>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.socialShareVisible : depth0),{"name":"each","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"button-group relative inline-block\">\n        <button class=\"btn btn-no-background dropdown-toggle btn-left-align\" data-toggle=\"dropdown\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Open More Story Options",{"name":"trans","hash":{},"data":data}))
    + "\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </button>\n        <div class=\"triangle\"></div>\n        <ul class=\"dropdown-menu\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.socialShareHidden : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n    </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-hidden=\"true\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),24,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " social-share\" target=\"_blank";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <li>\n"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),"report",{"name":"ifequal","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "            </li>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ReportStoryPart","share-tools",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" rel=\"nofollow\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),20,"wp-neutral-2","pull-left",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper)))
    + "\n                    </a>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-hidden=\"true\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),24,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <li>\n                    <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" rel=\"nofollow\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),20,"wp-neutral-2","pull-left",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper)))
    + "\n                    </a>\n                </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.draft : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});