import storyGridItem from "platform/components/templates/item-views/story-grid-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Story Grid Item View - displays general story meta info, cover image & link
   * @Class StoryGridItem    View
   * @constructor
   */
  app.add(
    "StoryGridItem",
    app.views.Base.extend({
      // Properties
      // ----------
      className: "col-sm-2 col-xs-4 story-item story text-center",
      template: storyGridItem,

      // Methods
      // -------
      render: function() {
        var data = this.model.toJSON();

        // block HTML rendering of title/description
        data.url = utils.formatStoryUrl(this.model.get("url"));
        if (data.description) {
          data.description = utils.sanitizeHTML(data.description);
        }
        this.$el.html(this.template(data));
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
