var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " truncate-width";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + " ";
},"5":function(container,depth0,helpers,partials,data) {
    var helper;

  return " "
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)))
    + " ";
},"7":function(container,depth0,helpers,partials,data) {
    return " <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li> ";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"follow\">\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.noop,"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n</div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <a class=\"btn btn-block btn-teal on-navigate\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-user",14,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    <span class=\"truncate\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View My Profile",{"name":"trans","hash":{},"data":data}))
    + "</span>\n  </a>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "  <button class=\"btn btn-fan "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = helpers.disabled || (depth0 != null ? depth0.disabled : depth0)) != null ? helper : alias2),(options={"name":"disabled","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.disabled) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-target=\""
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" data-following=\""
    + alias4(((helper = (helper = helpers.following || (depth0 != null ? depth0.following : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"following","hash":{},"data":data}) : helper)))
    + "\" role=\"button\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "  </button>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showRemoveFollowersOption : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "btn-teal";
},"15":function(container,depth0,helpers,partials,data) {
    return "btn-white";
},"17":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span class=\"truncate\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",16,"wp-base-2-dark",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span class=\"truncate\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follow",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <button class=\"btn on-remove-follower\">\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-remove",16,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=helpers.blockHelperMissing, buffer = 
  "<div class=\"pull-left\">\n  <a class=\"avatar avatar-sm3 on-navigate\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    <img alt=\""
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" src=\""
    + alias4(((helper = (helper = helpers.avatar || (depth0 != null ? depth0.avatar : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data}) : helper)))
    + "\">\n  </a>\n</div>\n\n<div class=\"user-data pull-left";
  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n  <a class=\"alt-link on-navigate fullname\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    <span class=\"username\">@"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</span>\n  </a>\n\n  <ul>\n    "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.metadata : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </ul>\n</div>\n\n";
  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"useData":true});