import videosLanding from "core/templates/wattpad-presents/videos-landing.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "VideosLanding",
    app.views.Base.extend({
      template: videosLanding,

      id: "wattpad-presents",

      events: {},

      render: function() {
        var storyVideoData = [];
        _.each(this.options.videoModels, function(model) {
          storyVideoData.push(model.toJSON());
        });

        var data = {
          isMobile: app.get("device").is.mobile,
          videoStoryPairs: storyVideoData,
          numVideos: storyVideoData.length
        };

        this.$el.html(this.template(data));
        _.defer(this.afterRender.bind(this));
        return this;
      },

      afterRender: function() {
        var self = this;

        var tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        var firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = function() {
          self.initVideos();
        };
      },

      initVideos: function() {
        var videos = this.options.videoModels;

        _.each(videos, function(item) {
          var video = item.toJSON();
          var player = new window.YT.Player("player-" + video.videoId, {
            height:
              app.get("device").is.mobile || videos.length === 3
                ? "210"
                : "390",
            width: "640",
            videoId: video.videoId
          });
        });
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
