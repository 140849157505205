import featuredPicks from "platform/featured/templates/featured-picks.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "FeaturedPicks",
    app.views.IncrementalList.extend({
      template: featuredPicks,

      itemView: app.views.BrowseStoryItem,

      // either containerClass or containerId, but containerId overrides container class
      containerClass: ".featured-picks",

      events: _.extend(
        {
          "tap .on-story-preview": "onStoryPreview",
          "click .on-story-preview": "stopEvent",

          "tap .featured-list": "sendDropdownClickTrackingEvent",
          "click .featured-list": "stopEvent",

          // To track clicks through to story on 'Start Reading' button and story cover on the story preview modal
          // These elements exist in browse-story-preview
          "tap .on-story-details": "sendStoryClickTrackingEvent",
          "tap .on-start-reading": "sendStoryClickTrackingEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function(options) {
        options = options || {};

        this.collection = this.model.stories();
        this.pageTitle = this.model.get("name");

        Handlebars.registerPartial(
          "core.item_views.browse_story_item",
          function() {
            return "";
          }
        );

        wattpad.utils.setTitle(this.pageTitle);
        this.collection.once("sync", this.render, this);
        this.collection.fetchNextSet();

        this.listenTo(
          app,
          "app:component:TagMetaItem:click",
          this.navigateToTagPage
        );
        this.listenTo(
          app,
          "app:component:TagMetaNumNotShown:click",
          this.onStoryPreview
        );
      },

      // Override because it is not a direct child
      getContainer: function() {
        return this.$el.find(this.containerClass);
      },

      getTemplateData: function() {
        var readingList = this.model.toJSON();
        var isPremium = wattpad.utils.currentUser()?.get("isPremium");
        return {
          hasStories: this.collection.total > 0,
          readingList: readingList,
          title: readingList.name, // For the catalog header react component
          isPremium
        };
      },

      getItemViewOptions: function() {
        return {
          id: this.model.get("id")
        };
      },

      onStoryPreview: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        var story = this.collection.get(storyId);
        var preview = new app.views.BrowseStoryPreview({ model: story });

        this.$("#preview-modal")
          .empty()
          .append(preview.render().$el);
        preview.openModal();

        this.sendStoryClickTrackingEvent(evt);
      },

      navigateToTagPage: function(evt) {
        var tag = $(evt.currentTarget)
            .text()
            .trim(),
          urlPath = "/stories/";
        app.router.navigate(urlPath + encodeURIComponent(tag), {
          trigger: true
        });
      },

      sendStoryClickTrackingEvent: function(evt) {
        // When a user clicks on a story from the featured page
        var storyId =
          $(evt.currentTarget).data("story-id") ||
          this.$("#preview-modal .browse-preview.modal").data("story-id");
        window.te.push("event", "featured", "story", null, "click", {
          reading_listid: $(".current-list").data("list-id"),
          storyid: storyId
        });
      },

      sendDropdownClickTrackingEvent: function(evt) {
        // When a user clicks on the dropdown on the featured list page
        window.te.push("event", "featured", "dropdown", null, "click", {
          reading_listid: $(evt.currentTarget).data("list-id")
        });
      }
    })
  );

  app.mixin(app.views.FeaturedPicks, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
