import getStoryBrandSafety from "../../../helpers/get-story-brand-safety";
import getAfpClientId from "../../../helpers/get-afp-clientid";

(function(window, _, wattpad, app, utils) {
  "use strict";

  //This model is used to represent parts in a story - without text ( just metadata )
  app.add(
    "StoryPartModel",
    app.models.BaseModel.extend({
      fields: [
        "id",
        "title",
        "url",
        "modifyDate",
        "length",
        "wordCount",
        "videoId",
        "photoUrl",
        "commentCount",
        "voteCount",
        "readCount",
        "voted",
        "private",
        "pages",
        "text_url",
        "rating",
        "deleted",
        "draft",
        "isAdExempt",
        "hasBannedHeader",
        {
          group: [
            "id",
            "title",
            "cover",
            "url",
            {
              user: ["username", "name", "avatar", "twitter", "authorMessage"]
            },
            "categories",
            "isAdExempt",
            "deleted",
            "draft",
            "rating",
            "language",
            "description",
            "tags",
            "completed",
            "isPaywalled",
            "paidModel",
            {
              parts: [
                "title",
                "url",
                "id",
                "deleted",
                "draft",
                "rating",
                "scheduledPublishDatetime"
              ]
            },
            "rankings"
          ]
        },
        { dedication: ["name", "url"] },
        { source: ["url", "label"] }
      ],

      cacheLocal: true,

      bypassBrowserCache: true,

      urlRoot: "/v4/parts/",

      defaults: {
        private: false
      },

      resource: function() {
        return "part." + parseInt(this.get("id"), 10) + ".metadata";
      },

      fetch: function(options) {
        var self = this;
        options = options || {};

        var baseModelFetch = Promise.resolve(
          app.models.BaseModel.prototype.fetch.call(this, options)
        );

        var safetyFetch = function(result) {
          var groupId = _.get(result, "group.id");
          if (!groupId) {
            return result;
          }

          return Promise.resolve(
            $.ajax({
              type: "GET",
              url: "/v5/stories/" + groupId + "/classification/safety"
            })
          );
        };

        var brandSafetyPromise = result => {
          return Promise.resolve(getStoryBrandSafety(result.group, result));
        };

        var afpClientIdPromise = () => Promise.resolve(undefined);
        if (_.get(window, "wattpad.testGroups.USE_AFP", false)) {
          afpClientIdPromise = username => {
            return Promise.resolve(getAfpClientId(username));
          };
        }

        return baseModelFetch.then(function(result) {
          // The result was cached or localOnly fetched and no further processing is necessary
          if (result === true || !result) {
            return result;
          }
          const authorUsername = result?.group?.user?.username;
          return Promise.all([
            safetyFetch(result),
            brandSafetyPromise(result),
            afpClientIdPromise(authorUsername)
          ]).then(function([safetyLevel, brandSafety, afpClientId]) {
            // Safety processing
            var level = _.get(safetyLevel, "safe_for");
            if (level === 1) {
              _.extend(result.group, {
                isBrandSafe: true
              });
            } else if (level === 0) {
              // Known unsafe story. Disable indexing.
              _.extend(result.group, {
                noIndex: true
              });
            }

            // Brand Safety Processing (Ads Meta service, not to be confused with isBrandSafe)
            if (brandSafety) {
              self.set("brandSafetyLevel", brandSafety.brandSafetyLevel);
              self.set("brandSafetySource", brandSafety.brandSafetySource);
              if (_.get(window, "wattpad.testGroups.USE_IMAGE_MOD", false)) {
                self.set(
                  "imagesUnderModeration",
                  brandSafety.imagesUnderModeration
                );
              }
            }
            // add afpClientId to the user mode
            var group = self.get("group");
            if (afpClientId && group && group.user) {
              group.user.afpClientId = afpClientId;
              self.set("group", group);
            }

            return result;
          });
        });
      },

      //Consolidate the categories info
      parse: function(data) {
        if (data.group.categories && data.group.categories.length > 1) {
          data.group.categories = [
            data.group.categories[0] || data.group.categories[1]
          ];
        }

        _.each(data.group.parts, function(part) {
          if (part.id === data.id) {
            part.active = true;
          }
        });

        //Get the highest rank for the story which will be sent to ad services
        var rankingsArray = data.group.rankings ? data.group.rankings : [],
          rank = null;
        if (rankingsArray.length > 0) {
          _.each(rankingsArray, function(item) {
            if (item.rank > rank) {
              rank = item.rank;
            }
          });
        }
        data.rank = rank;

        return data;
      },

      //Saves the position in the current part or fetches the % read in the current part
      syncPosition: function(position) {
        var self = this;

        if (position) {
          $.post("/apiv2/syncreadingposition", {
            story_id: self.get("id"),
            position: position
          });
        } else {
          $.get("/apiv2/syncreadingposition", {
            story_id: self.get("id")
          }).done(function(data) {
            var position = data.position || 0;
            self.trigger(
              "reading-position-available",
              self.get("id") === data.part ? position : 0
            );
          });
        }
      },

      getCommentCountByParagraph: function() {
        var self = this;
        var getCommentCount = $.ajax({
          type: "GET",
          url: "/v4/parts/" + this.get("id") + "/paragraphs"
        });
        return Promise.resolve(getCommentCount).then(function(data) {
          self.set({
            paragraphs: data.paragraphs
          });
          return {
            paragraphs: data.paragraphs
          };
        });
      },

      // Gets the specified page number
      getPage: function(pageNumber) {
        if (!this.loadedPages) {
          /*

        This loads the SSR'd story text from window.prefetched to StoryPartModel.loadedPages.

        The SSR'ed story text is not loaded in this.loadedPages (due to WEB-3889, which
        removed duplicated calls to the CDN). However, the story text is needed for display
        in the InlineComments modal. Here, the story text is accessed through window.prefetched
        (or app.local) and loaded on this.loadedPages without making an extra call to the CDN.

        */
          this.loadedPages = {};

          var prefetchedData = app.local._getLocalData(this.resource()).resp;
          var prefetchedPageNum = prefetchedData.pageNumber;

          if (prefetchedData.storyText) {
            var prefetchedStoryText = wattpad.utils.unsanitizeHTML(
              prefetchedData.storyText
            );

            this.loadedPages[prefetchedPageNum] = new app.models.StoryText({
              url: url,
              token: this.get("text_url").refresh_token
            });
            this.loadedPages[prefetchedPageNum].set(
              "text",
              prefetchedStoryText
            );
            this.loadedPages[prefetchedPageNum].set(
              "paragraphs",
              this.loadedPages[prefetchedPageNum].parse(prefetchedStoryText)
                .paragraphs
            );
          }
        }

        if (pageNumber > this.get("pages")) {
          return Promise.resolve();
        }

        if (this.loadedPages[pageNumber]) {
          return Promise.resolve(this.loadedPages[pageNumber]);
        }

        var url = "";

        //Need to differentiate between a call to t.wattpad.com and the v2 api
        if (this.get("text_url").text.indexOf("/apiv2/") > -1) {
          url = this.get("text_url").text.replace(
            /(page=)([0-9]*)/i,
            "$1" + pageNumber
          );
        } else {
          url = this.get("text_url").text.replace(
            /([0-9]+-[0-9a-z]+)(\?.*)/i,
            "$1-" + pageNumber + "$2"
          );
        }

        var page = (this.loadedPages[pageNumber] = new app.models.StoryText({
          url: url,
          token: this.get("text_url").refresh_token
        }));

        var self = this;
        return Promise.resolve(page.fetch()).then(
          function() {
            return page;
          },
          // Something went wrong
          function() {
            self.handlePromiseExpected("");
          }
        );
      },

      vote: function() {
        var curVoteState = this.get("voted"),
          voteAjax = $.ajax({
            url:
              "/api/v3/stories/" +
              this.get("group").id +
              "/parts/" +
              this.get("id") +
              "/votes",
            type: curVoteState ? "DELETE" : "POST"
          }),
          self = this;

        return Promise.resolve(voteAjax).then(function(apiData) {
          self.set({
            voteCount: apiData.votes,
            voted: !curVoteState
          });
          return apiData;
        });
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
