var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"page-controls clearfix\" role=\"menu\">\n        <a href=\"/list\" class=\"pull-left btn btn-sm btn-text on-navigate\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-left",12,"fa-text-grey",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"My reading lists",{"name":"trans","hash":{},"data":data}))
    + "</a>\n        <div class=\"pull-right page-status\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-unlock",14,"fa-mediumgrey",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Public",{"name":"trans","hash":{},"data":data}))
    + "\n        </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div id=\"authentication-panel\" class=\"panel panel-basic\">\n                <div class=\"signup-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.signup_prompt"],depth0,{"name":"core.signup_prompt","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <div class=\"panel feed-item-new\">\n                <div class=\"select-all on-edit-show\">\n                    <div class=\"circular-checkbox fa-stack pull-right on-select-all\">\n                        <span class=\"circle-empty\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",30,"wp-neutral-3 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",26,"wp-neutral-5 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"checked-in\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled",22,"wp-base-2 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",17,"wp-neutral-5 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                    </div>\n\n                    <span class=\"text pull-right on-select-all\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Select All",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                </div>\n\n                <ul class=\"list-group\" data-drag=\"true\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.stories : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </ul>\n\n                <div class=\"show-more center-text hidden\">\n                    <button class=\"btn btn-link on-showmore\">\n                    <span class=\"show-more-message\">\n                        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                        <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n                    </span>\n                    <span class=\"loading-message hidden\">\n                        "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n                        <span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n                    </span>\n                    </button>\n                </div>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                    <li class=\"list-group-item\">\n"
    + ((stack1 = helpers["with"].call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"with","hash":{"list":depths[1]},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.library.reading_list_details.library_reading_list_story_item"],depth0,{"name":"core.library.reading_list_details.library_reading_list_story_item","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.noStories : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div class=\"text-center\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isOwner : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n            ";
},"11":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"No stories yet. Go add some!",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                    "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/shared/add_to_reading_list.png",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add a story to your reading list",{"name":"trans","hash":{},"data":data}),{"name":"connectImage","hash":{"klass":"add-to-list","height":"153"},"data":data}))
    + "\n                    <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Look for the \"+\" button to add a story to your reading list.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                    <p>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"This reading list is currently empty.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"list-controls\">\n        <span class=\"num-selected vcenter\"></span>\n        <button id=\"btn-batch-remove\" class=\"btn btn-lightgrey btn-md on-batch-remove pull-right\" disabled> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-trash",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n        <button id=\"btn-batch-add\" class=\"btn btn-lightgrey btn-md on-batch-add pull-right\" disabled> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = 
  "\n<div class=\"container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOwner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"row\">\n\n        <aside class=\"col-xs-12 col-sm-5 col-md-4 clearfix\">\n"
    + ((stack1 = container.invokePartial(partials["core.library.reading_list_details.library_reading_list_sidebar"],depth0,{"name":"core.library.reading_list_details.library_reading_list_sidebar","data":data,"indent":"           ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = helpers.isLogged || (depth0 != null ? depth0.isLogged : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isLogged","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0, blockParams, depths),"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.isLogged) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "        </aside>\n\n        <main class=\"col-xs-12 col-sm-7 col-md-8\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasStories : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(9, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </main>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOwner : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});