import quoteEmbed from "core/templates/components/quote-embed.hbs";
import highlightTooltip from "core/templates/highlight-tooltip.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "HighlightTooltip",
    app.views.Base.extend({
      events: {
        "tap .on-open-embed": "onQuoteEmbed",
        "click .on-open-embed": "stopEvent",

        "tap .on-facebook-share": "onFacebookShareQuote",
        "click .on-facebook-share": "stopEvent"
      },

      el: ".highlight-tooltip",

      template: highlightTooltip,

      embedModalTemplate: quoteEmbed,

      initialize: function(options) {
        options = options || {};

        this.quote = options.quoteString;
        this.storyId = options.storyId;
        this.url = options.url;
      },

      render: function() {
        var data = {};

        $("#tooltip-container").html(this.template(data));
        this.setElement(".highlight-tooltip");
        return this;
      },

      onFacebookShareQuote: function() {
        var self = this;
        this.url = wattpad.utils.addSource(
          this.url,
          "web",
          "facebook",
          "share_quote"
        );

        FB.ui(
          {
            method: "share",
            href: this.url,
            quote: this.quote
          },
          function() {
            // after the share dialog is closed, track in GA,
            // remove the tooltip and clear the selection
            wattpad.utils.pushEvent({
              category: "reading",
              action: "share:facebookquote",
              label: self.storyId
            });
            self.remove();
            window.getSelection().removeAllRanges();
          }
        );
      },

      onQuoteEmbed: function(event) {
        utils.pushEvent({
          category: "embed",
          action: "click",
          label: "quote"
        });

        var modal = $("#generic-modal"),
          data = {
            id: this.storyId,
            quoteText: this.quote,
            copyExec: document.queryCommandSupported
              ? document.queryCommandSupported("copy")
              : false
          };

        utils.stopEvent(event);

        modal
          .find(".modal-dialog")
          .addClass("embed")
          .find(".modal-body")
          .html(this.embedModalTemplate(data));

        var $copyEmbedCode = modal
          .find(".on-copy")
          .on("tap", this.onCopyEmbedCode);

        modal.modal("show").on("hide.bs.modal", this.closeEmbed);
      },

      onCopyEmbedCode: function() {
        var value = document.querySelector("#embed-url"),
          range = document.createRange();

        range.selectNode(value);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);

        if (document.execCommand("copy")) {
          $(".on-copy").text(utils.trans("Copied!"));
          utils.pushEvent({
            category: "embed",
            action: "copy",
            label: "quote"
          });
        } else {
          $(".on-copy").text(utils.trans("Sorry, unable to copy"));
        }
      },

      closeEmbed: function(evt) {
        $(this)
          .find(".modal-dialog")
          .removeClass("embed");
        this.tooltip.remove();
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
