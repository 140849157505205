var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"The Watty Awards",{"name":"trans","hash":{},"data":data}));
},"3":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Awards",{"name":"trans","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"triangle\"></div>\n<div class=\"dropdown-menu large\" aria-labelledby=\"community-dropdown\">\n  <ul aria-label=\"Community\" class=\"header-list\">\n    <li>\n      <a href=\"/awards\" rel=\"noopener noreferrer\" target=\"_blank\">\n        "
    + ((stack1 = (helpers.canTrans || (depth0 && depth0.canTrans) || alias2).call(alias1,"The Watty Awards",{"name":"canTrans","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </a>\n    </li>\n    <li>\n      <a href=\"/story/25279524\" rel=\"noopener noreferrer\" target=\"_blank\">\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Community Happenings",{"name":"trans","hash":{},"data":data}))
    + "\n      </a>\n    </li>\n    <li>\n      <a href=\"/823619\" rel=\"noopener noreferrer\" target=\"_blank\">\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Ambassadors",{"name":"trans","hash":{},"data":data}))
    + "\n      </a>\n    </li>\n  </ul>\n</div>\n";
},"useData":true});