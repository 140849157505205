(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverModule",
    app.models.Base.extend({
      defaults: {
        id: null,
        type: null,
        createDate: null,
        data: null
      },

      // list of supported types
      validTypes: [
        "listCurated",
        "storiesRecentlyRead",
        "storiesRecommended",
        "storiesRecommendedExplore",
        "storiesRecommendedRNN",
        "storiesRecommendedInfluential",
        "storiesRecentlyUpdated",
        "storiesSupported",
        "storiesTop",
        "storiesByFollowedHub",
        "storiesByPromotedUser",
        "tagTop",
        "tagRecommended",
        "storiesByRecentVote",
        "hubTop",
        "listsByFollowedHub",
        "storiesRecentlyVotedByFollowedHubs",
        "hubsFollowedByFollowedHub",
        "listTrending",
        "listPromoted",
        "hubTrending",
        "tagTrending",
        "storiesBySearchResults",
        "emergentStories"
      ],

      promotedModules: [
        "storiesByPromotedUser",
        "listPromoted",
        "promotedStoriesRecommended"
      ],

      isValid: function() {
        var curType = this.get("type");
        return _.contains(this.validTypes, curType);
      },

      parse: function(data) {
        if (wattpad.utils.currentUser().get("isPremium")) {
          if (!_(this.promotedModules).includes(data.type)) {
            return data;
          } else {
            return;
          }
        } else {
          return data;
        }
      }
    })
  );
})(window, _, wattpad, window.app);
