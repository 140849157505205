(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverCollection",
    app.collections.IncrementalFetch.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "description",
            "cover",
            "voteCount",
            "readCount",
            "readingPosition",
            "numParts",
            "tags",
            "categories",
            "modifyDate",
            "url",
            "firstPartId",
            { user: ["name", "avatar"] }
          ]
        },
        "total",
        "nexturl"
      ],

      initialize: function(models, options) {
        options = options || {};
        this.user = options.user || wattpad.utils.currentUser();
        this.url = options.url || this.url;
        this.layout =
          options.layout || wattpad.utils.PanelBuilder.Layouts.Default;
        this.cacheKey = options.cache;

        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      resource: function() {
        if (!this.cacheKey) {
          throw new Error("Discover :: Collection :: Please set the Resource");
        }

        return (
          "user." +
          (this.user.get("username") || "anonymous") +
          ".discover." +
          this.cacheKey
        );
      },

      url: function() {
        throw new Error("Discover :: Collection :: Please set the URL");
      },

      getPanelGroup: function() {
        return new wattpad.utils.PanelBuilder(this.toJSON(), this.layout)
          .panelGroup;
      }
    })
  );
})(window, _, wattpad, window.app);
