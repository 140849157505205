/**
 * Takes in three expected attributes, and returns a string representation of the container name to keep server and client both in sync on container creation.
 *
 * @param {String} componentName component name
 * @param {String} componentId unique ID of the component
 * @param {String} currentPath the current path, encoded using urlEncode/https://www.php.net/manual/en/function.urlencode.php
 * @returns {String} containerId
 */
module.exports = (componentName, componentId, currentPath) => {
  if (typeof componentName !== "string" || !componentName.length) {
    throw new TypeError("componentName is a mandatory string");
  }

  // if (typeof componentId !== "string" || !componentId.length) {
  //   throw new TypeError("componentId is a mandatory string");
  // }

  if (typeof currentPath !== "string" || !currentPath.length) {
    throw new TypeError("currentPath is a mandatory string");
  }

  // Must lower case the ID because a query string can be rendered differently client vs server
  // Server uses uppercase, client may use lowercase, so normalize to prevent mismatch
  return `component-${componentName}-${componentId}-${currentPath}`.toLowerCase();
};
