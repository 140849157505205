(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:browse-category",
    app.router.filter(["getTranslations", "getCategories"], function(
      categorySlug,
      filterSlug,
      params
    ) {
      var view,
        category,
        filter = filterSlug || "hot";

      if (
        typeof params === "object" &&
        (params["redirectfrom"] || params["redirectFrom"])
      ) {
        var toast = new app.views.ErrorToast(
          {
            message:
              wattpad.utils.trans(
                "We're sorry! The page you requested no longer exists."
              ) +
              " " +
              wattpad.utils.trans("So we have brought you to a related page.")
          },
          { type: "dismissable" }
        );
        toast.render();
      }
      if (!categorySlug || categorySlug.trim() === "") {
        view = new app.views.DiscoverLanding();
      } else {
        category = app
          .get("categories")
          .find(function(model, index, collection) {
            return (
              wattpad.utils.slugify(model.get("name_english")) ===
              categorySlug.toLowerCase()
            );
          });
        category = category || app.get("categories").at(0);
        view = new app.views.CoreBrowseCategory({
          category: category,
          filter: filter
        });
      }

      app.transitionTo(view, {
        hasHeader: true,
        hasFooter: true,
        pageTitle: category
          ? wattpad.utils.trans(
              "%s Stories and Books Free",
              category.get("name")
            )
          : "Discover"
      });

      window.te.push("event", "app", "page", null, "view", {
        page: "category",
        tags: category ? category : "discover"
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
