var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Before you publish %s",(depth0 != null ? depth0.partTitle : depth0),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=helpers.blockHelperMissing;

  return ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0),{"name":"story.id","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = alias2.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0),{"name":"story.id","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add Story Info",{"name":"trans","hash":{},"data":data}))
    + "\n        ";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Please enter your story details",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit Story Details",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-grey on-edit-close\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Close",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-grey on-edit-cancel\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-orange on-edit-save\"><span class=\"text\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Skip",{"name":"trans","hash":{},"data":data}))
    + "</span>\n        <div id=\"finish-tooltip\" data-toggle=\"popover\"></div>\n      </button>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isPublish : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-orange on-publish\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Publish",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "      <button class=\"btn btn-orange on-edit-save\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Save",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <div class=\"container\">\n       <div class=\"banned-image-messaging \">\n\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"warning-triangle",30,"wp-warning-01","warning-icon",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <span className=\"message\">\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"1 or more of your images was deemed unsafe and will only be visible to you.",{"name":"trans","hash":{},"data":data}))
    + "\n              <a\n              class=\"learn-more\"\n              rel=\"noopener\"\n              target=\"_blank\"\n              href= \"https://support.wattpad.com/hc/en-us/articles/360020840731-Image-Moderation-on-Wattpad\"\n            >\n              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn more",{"name":"trans","hash":{},"data":data}))
    + "\n            </a>\n        </span>\n\n      </div>\n      </div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    return "new-image";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "               <span class='banned-overlay'>\n\n                   "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"warning-triangle",60,"wp-warning-01","warning-icon",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n                </span>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return " on-trigger-tooltip";
},"30":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"32":function(container,depth0,helpers,partials,data) {
    return " data-toggle=\"dropdown\" ";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <span data-toggle=\"popover\" class=\"popover-icon\" id=\"image-tooltip\" title=\""
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add a story cover",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <button class=\"btn btn-orange btn-view-as-reader on-view-as-reader\" data-attr=\"wid-btn\" data-url=\""
    + container.escapeExpression((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n        "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.lang : depth0),"en",{"name":"ifequal","hash":{},"fn":container.program(37, data, 0),"inverse":container.program(39, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      </button>\n";
},"37":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"View as reader",{"name":"trans","hash":{},"data":data}));
},"39":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Preview",{"name":"trans","hash":{},"data":data}));
},"41":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"social-share-wrapper\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.published : stack1),{"name":"if","hash":{},"fn":container.program(42, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n";
},"42":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <h5>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Share ",{"name":"trans","hash":{},"data":data}))
    + "</h5>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.socialLinks : stack1),{"name":"each","hash":{},"fn":container.program(43, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"43":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n          "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),24,"wp-neutral-2","fa-2x social-icon",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </a>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return " social-share\" target=\"_blank";
},"46":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["desktop.create.works_contests"],depth0,{"name":"desktop.create.works_contests","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"48":function(container,depth0,helpers,partials,data) {
    return "publish-edit\n                ";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNew : depth0),{"name":"unless","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                ";
},"51":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"53":function(container,depth0,helpers,partials,data) {
    return " has-title";
},"55":function(container,depth0,helpers,partials,data) {
    return "on-trigger-tooltip";
},"57":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["desktop.create.works_character_item"],depth0,{"name":"desktop.create.works_character_item","hash":{"readonly":true},"data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["desktop.create.works_character_item"],depth0,{"name":"desktop.create.works_character_item","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"61":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.name : depth0),((stack1 = ((stack1 = (depths[1] != null ? depths[1].story : depths[1])) != null ? stack1.categoryObject : stack1)) != null ? stack1.id : stack1),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"63":function(container,depth0,helpers,partials,data) {
    return "                          <span data-toggle=\"popover\" class=\"popover-anchor on-trigger-tooltip\" id=\"prefilled-tag-tooltip\" title=\"Your submission has been tagged!\"></span>\n";
},"65":function(container,depth0,helpers,partials,data) {
    return "                      <p class=\"fa-wp-grey\"><small>Add the tag\n                        <a target=\"_blank\" href=\"/1136309084-the-maybelline-bravetogether-writeathon-for\">#BraveTogether</a>\n                        to your story this month and Wattpad will donate $1 to The Trevor Project which provides crisis support services for LGBTQ youth in need.</small>\n                      </p>\n";
},"67":function(container,depth0,helpers,partials,data) {
    return "selected";
},"69":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.isSupportedLanguage : stack1),{"name":"unless","hash":{},"fn":container.program(70, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " ";
},"70":function(container,depth0,helpers,partials,data) {
    return " disabled=\"disabled\" ";
},"72":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                              "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.name : depth0),((stack1 = ((stack1 = (depths[1] != null ? depths[1].story : depths[1])) != null ? stack1.language : stack1)) != null ? stack1.id : stack1),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"74":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                            <div class=\"unsupported-language-message "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.isSupportedLanguage : stack1),{"name":"if","hash":{},"fn":container.program(51, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                              <span class=\"info-icon\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                              <div class=\"unsupported-info-message\">\n                               "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"The language you are looking for may no longer be supported and cannot be changed. It is now categorized as under \"Other\".",{"name":"trans","hash":{},"data":data}))
    + "\n                               <a\n                                class=\"learn-more-link\"\n                                rel=\"noopener\"\n                                target=\"_blank\"\n                                href= \"https://w.tt/4bdMm4W\"\n                              >\n                              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn more",{"name":"trans","hash":{},"data":data}))
    + "\n                              </a>\n                              </div>\n                            </div>\n";
},"76":function(container,depth0,helpers,partials,data) {
    return " checked";
},"78":function(container,depth0,helpers,partials,data) {
    return "                    <p class=\"rating-warning\">\n                        "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"The Wattpad community has rated your story Mature. For more info, please read Wattpad's Content Guidelines:",{"name":"trans","hash":{},"data":data}))
    + " <a href='https://www.wattpad.com/guidelines'>https://www.wattpad.com/guidelines</a>\n                    </p>\n";
},"80":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <div class=\"form-group status-form\">\n                    <div class=\"form-wrapper\">\n                        <label class=\"rating-label\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story Status",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                        <span data-toggle=\"popover\" class=\"popover-icon\" id=\"story-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Mark as Complete",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"toggle-prompt\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Complete?",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                        <div class=\"onoffswitch\">\n                        <input type=\"checkbox\" name=\"onoffswitch\" class=\"onoffswitch-checkbox\" id=\"complete-switch\"\n                            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.completed : stack1),{"name":"if","hash":{},"fn":container.program(76, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <label class=\"onoffswitch-label\" for=\"complete-switch\">\n                            <span class=\"onoffswitch-inner\">\n                            <span class=\"on\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"ON",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                            <span class=\"off\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"OFF",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                            </span>\n                            <span class=\"onoffswitch-switch\"></span>\n                        </label>\n                        </div>\n                    </div>\n                    </div>\n";
},"82":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    <div class=\"form-group inline-form\">\n                        <div class=\"acknowledge-wrapper\">\n                            <span class=\"empty-warning hidden\" id=\"acknowledge-empty-warning\">\n                                *"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You must complete this field to proceed",{"name":"trans","hash":{},"data":data}))
    + "\n                            </span>\n                            "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"Acknowledge","acknowledge",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                        </div>\n                    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda, alias5="function";

  return "<div id=\"works-item-edit\">\n  <div id=\"edit-controls\" class=\"edit-profile\">\n    <button class=\"btn btn-text on-edit-cancel\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-left",24,{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n    <div class=\"works-item-metadata\">\n      <p class=\"small group-title\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPublish : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "      </p>\n      <span class=\"h4\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1), depth0))
    + "</span>\n    </div>\n\n    <div class=\"actions pull-right\">\n      <img class=\"save-working hidden\" src=\"/img/desktop-web/ajax-spinner-off-white.gif\" />\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPublish : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(13, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0, blockParams, depths),"inverse":container.program(17, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n  <div id=\"publish-status-banner\"></div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasBannedImages : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n\n\n  <div class=\"container\">\n    <div class=\"col-lg-3 col-md-3 col-sm-12\" id=\"picture-sidebar\">\n      <div class=\"image-placeholder "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-file-upload=\"drop\">\n        "
    + alias3((helpers.connectCover || (depth0 && depth0.connectCover) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.cover : stack1),((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),256,"cover cover-xlg edit-cover",{"name":"connectCover","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasBannedCover : depth0),{"name":"if","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <div class=\"new-cover-upload"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n          <span class=\"dropdown-holder\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"unless","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-image",60,"wp-neutral-2","fa-3x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n          <div class=\"add-cover-text dropdown-holder\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"unless","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add a cover",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"drop-cover-text dropdown-holder\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"unless","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Drop a cover",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"triangle\"></div>\n          <ul id=\"works-more-options-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"dropdown-menu cover-edit-dropdown\" role=\"menu\">\n            <li role=\"none\">\n              <a class=\"file-select\" role=\"menuitem\" href=\"#\" data-toggle=\"modal\" data-target=\"#worksDelete\">\n                "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upload Cover",{"name":"trans","hash":{},"data":data}))
    + "\n              </a>\n            </li>\n            <li role=\"none\">\n                <a class=\"list-item-no-style\" role=\"menuitem\" href=\"javascript:void(0);\">\n                    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"CanvaButton","canva-button-new",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                </a>\n            </li>\n          </ul>\n        </div>\n\n        <div id=\"cover-uploader\" class=\"button-group inline-block dropdown"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n          <div class=\"cover-edit-prompt\">\n            <div class=\"default-state\">\n              <span class=\"on-cover-edit hidden\" data-toggle=\"dropdown\">Edit your cover</span>\n              <span data-toggle=\"dropdown\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-edit",16,"wp-neutral-5","on-cover-edit-icon",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n              <div class=\"triangle\"></div>\n              <ul id=\"works-more-options-"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias5 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"dropdown-menu cover-edit-dropdown\" role=\"menu\">\n                <li role=\"none\">\n                  <a class=\"file-select\" role=\"menuitem\" href=\"#\" data-toggle=\"modal\" data-target=\"#worksDelete\">\n                    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upload Cover",{"name":"trans","hash":{},"data":data}))
    + "\n                  </a>\n                </li>\n                <li role=\"none\">\n                    <a class=\"list-item-no-style\" role=\"menuitem\" href=\"javascript:void(0);\">\n                        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"CanvaButton","canva-button-edit",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    </a>\n                </li>\n              </ul>\n            </div>\n            <div class=\"drag-state\">\n              <span class=\"on-cover-edit hidden\">Drop your cover</span>\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",16,"wp-neutral-5","on-cover-edit-icon",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n          </div>\n        </div>\n        <span class=\"empty-warning"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.needCover : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data}))
    + "</span>\n\n\n        <input accept=\"image/jpeg, image/png, image/gif\" type=\"file\" name=\"file\" style=\"position: absolute; right: 0px; top: 0px; opacity: 0; height: 0; width: 0\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story cover file upload",{"name":"trans","hash":{},"data":data}))
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"unless","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isPublish : depth0),{"name":"unless","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"col-lg-8 col-lg-offset-1 col-sm-12 col-md-8 col-md-offset-1\" id=\"main-edit-container\">\n      <div class=\"row\">\n        <div id=\"contest-banner-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.displayWattysBanner : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"work-section\">\n            "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"WorksItemsDetailsNavBar","works-nav-bar",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n            <form class=\"main-edit-form\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPublish : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0, blockParams, depths),"inverse":container.program(50, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\">\n\n                <div class=\"required-form-wrapper\">\n                    <div class=\"form-group title-form\">\n                    <label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Title",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                    <span class=\"empty-warning"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.needTitle : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"title-warning\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                    <div contenteditable=\"true\" class=\"story-title"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"unless","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1), depth0))
    + "</div>\n                    </div>\n\n                    <div class=\"form-group description-form\">\n                    <div class=\"form-wrapper\">\n                        <label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Description",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                        <span data-toggle=\"popover\" class=\"popover-icon\" id=\"description-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add a story description",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"empty-warning"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.needDescription : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"description-warning\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                    </div>\n                    <textarea type=\"text\" name=\"title\" class=\"story-description "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story description",{"name":"trans","hash":{},"data":data}))
    + "\" aria-multiline=\"true\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.description : stack1), depth0))
    + "</textarea>\n                    </div>\n\n                    <div class=\"form-group characters-form\">\n                        <div class=\"form-wrapper\">\n                            <label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Main Characters",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                            <span data-toggle=\"popover\" class=\"popover-icon\" id=\"characters-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Who are your Main Characters?",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        </div>\n\n                        <div class=\"characters"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.characters : depth0),{"name":"each","hash":{},"fn":container.program(57, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.characters : depth0)) != null ? stack1.length : stack1),(depth0 != null ? depth0.charactersLimit : depth0),{"name":"ifequal","hash":{},"fn":container.noop,"inverse":container.program(59, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n\n                    <div class=\"form-group category-form\">\n                      <div class=\"form-wrapper\">\n                        <label for=\"categoryselect\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Category",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                        <span data-toggle=\"popover\" class=\"popover-icon\" id=\"category-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Tell Wattpad the category of your story",{"name":"trans","hash":{},"data":data}))
    + "\">\n                          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                        </span>\n                        <select id=\"categoryselect\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                          <option value=\"-1\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Select a category",{"name":"trans","hash":{},"data":data}))
    + "</option>\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.categories : stack1),{"name":"each","hash":{},"fn":container.program(61, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </select>\n                        <span class=\"empty-warning"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.needCategory : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"category-empty-warning\">\n                          "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data}))
    + "\n                        </span>\n                      </div>\n                    </div>\n                    <div class=\"form-group tags-form\">\n                    <div class=\"form-wrapper\">\n                        <label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Tags",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                        <span data-toggle=\"popover\" class=\"popover-icon\" id=\"tags-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help readers find your story",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"empty-warning"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.needTag : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"tag-empty-warning\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Required",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                    </div>\n                    <div class=\"tag-container"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.prefilledTag : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        <div id=\"editable-tags\" style=\"margin-bottom: 5px;\">\n"
    + ((stack1 = container.invokePartial(partials["desktop.create.works_item_editable_tag"],depth0,{"name":"desktop.create.works_item_editable_tag","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                        <div id=\"add-tag\" class=\"tag-item with-icon on-add-tag\">\n                        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add a tag",{"name":"trans","hash":{},"data":data}))
    + "</span>"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",12,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                        </div>\n                        <span id=\"tag-input-wrapper\">\n                        <input id=\"tag-input\" class=\"hidden on-tag-input\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Separate tags with a space",{"name":"trans","hash":{},"data":data}))
    + "\"\n                            autocomplete=\"off\" />\n                        </span>\n                    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"CONTEST_TAG",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(65, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n\n                <div class=\"form-group target-audience-form\">\n                    <div class=\"form-wrapper\">\n                    <label for=\"target-audience\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Target Audience",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                    <span data-toggle=\"popover\" class=\"popover-icon\" id=\"target-audience-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Who is this story for?",{"name":"trans","hash":{},"data":data}))
    + "\">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    </span>\n                    <select id=\"target-audience\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        <option selected=\"true\" disabled=\"disabled\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Who is your primary audience?",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                        <option value=\"13-18\" "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.targetAudience : depth0),"13-18",{"name":"ifequal","hash":{},"fn":container.program(67, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Young Adult (13-18 years of age)",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                        <option value=\"18-25\" "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.targetAudience : depth0),"18-25",{"name":"ifequal","hash":{},"fn":container.program(67, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New Adult (18-25 years of age)",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                        <option value=\"25+\" "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.targetAudience : depth0),"25+",{"name":"ifequal","hash":{},"fn":container.program(67, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Adult (25+ years of age)",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                    </select>\n                    </div>\n                </div>\n\n                <div class=\"inline-form-wrapper\">\n                    <div class=\"form-group language-form\">\n                        <div class=\"form-wrapper\">\n                          <label>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                          <span data-toggle=\"popover\" class=\"popover-icon\" id=\"language-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"What language is your story in?",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                          <select id=\"languageselect\" class=\"form-control "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story language",{"name":"trans","hash":{},"data":data}))
    + "\"  "
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"UNSUPPORTED_LANGUAGES",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(69, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.languages : stack1),{"name":"each","hash":{},"fn":container.program(72, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                          </select>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"UNSUPPORTED_LANGUAGES",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(74, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                    <div class=\"form-group copyright-form\">\n                      <div class=\"form-wrapper\">\n                        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"Copyright","copyright",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                      </div>\n                    </div>\n\n                    <div class=\"form-group rating-form\">\n                    <div class=\"form-wrapper\">\n                        <label class=\"rating-label\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Rating",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                        <span data-toggle=\"popover\" class=\"popover-icon\" id=\"rating-tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Rate your story",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        <span class=\"toggle-prompt\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Mature",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                        <div class=\"onoffswitch "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(55, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        <input type=\"checkbox\" name=\"onoffswitch\" class=\"onoffswitch-checkbox\" id=\"mature-switch\"\n                            "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.mature : stack1),{"name":"if","hash":{},"fn":container.program(76, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.ratingLocked : stack1),{"name":"if","hash":{},"fn":container.program(70, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <label class=\"onoffswitch-label\" for=\"mature-switch\">\n                            <div class=\"onoffswitch-inner\">\n                            <span class=\"on\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"ON",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                            <span class=\"off\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"OFF",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                            </div>\n                            <span class=\"onoffswitch-switch\"></span>\n                        </label>\n                        </div>\n                    </div>\n\n                    <p class=\"rating-warning everyone"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.everyone : stack1),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Your story is appropriate for all audiences. The Wattpad community has the ability to rate your story Mature. For more info, please read Wattpad’s Content Guidelines:",{"name":"trans","hash":{},"data":data}))
    + " <a href='https://www.wattpad.com/guidelines'>https://www.wattpad.com/guidelines</a>\n                    </p>\n                    <p class=\"rating-warning writerMature"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.writerMature : stack1),{"name":"unless","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Your story contains graphic depictions of violence, sexuality, strong language, and/or other mature themes. For more info, please read Wattpad’s Content Guidelines:",{"name":"trans","hash":{},"data":data}))
    + " <a href='https://www.wattpad.com/guidelines'>https://www.wattpad.com/guidelines</a>\n                    </p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.communityMature : depth0),{"name":"if","hash":{},"fn":container.program(78, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"unless","hash":{},"fn":container.program(80, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAcknowledgeOriginalWorks : depth0),{"name":"if","hash":{},"fn":container.program(82, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n            </form>\n\n            <div class=\"table-of-contents "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isNew : depth0),{"name":"if","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(partials["desktop.create.works_table_of_contents"],depth0,{"name":"desktop.create.works_table_of_contents","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n            <div class=\"story-planner\">\n              "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"StoryNotes","editor-view",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </div>\n\n      </div>\n\n    </div>\n\n  </div>\n  <div id=\"details-modal\" class=\"modal fade\" role=\"dialog\">\n    <div class=\"modal-dialog\">\n      <div class=\"modal-content\">\n        <div class=\"modal-header\">\n          <span class=\"close pull-right\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n          <span class=\"sr-only\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Close",{"name":"trans","hash":{},"data":data}))
    + "</span>\n\n          <div class=\"modal-title delete-part-modal hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Confirm Part Delete",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"modal-title delete-published-part-modal hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Part?",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"modal-title unpublish-part-modal hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish Part?",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"modal-title unpublish-story-modal hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish Story?",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"modal-title delete-story-modal hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Confirm Story Delete",{"name":"trans","hash":{},"data":data}))
    + "</div>\n          <div class=\"modal-title delete-published-story-modal hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Story?",{"name":"trans","hash":{},"data":data}))
    + "</div>\n        </div>\n        <div class=\"modal-body\">\n"
    + ((stack1 = container.invokePartial(partials["desktop.components.modal.share_embed"],depth0,{"name":"desktop.components.modal.share_embed","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          <iframe id=\"stats-modal\" frameborder=\"0\" class=\"hidden\" data-href=\"/dialog/story_stats?groupid="
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "&load-font=1\"></iframe>\n\n          <div class=\"hidden warning delete-published-story-modal delete-story-modal delete-published-part-modal delete-part-modal\">\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"WARNING: This cannot be undone.",{"name":"trans","hash":{},"data":data}))
    + "\n          </div>\n\n          <div class=\"hidden question-modal unpublish-part-modal\">\n            <div class=\"prompt\">\n              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Draft stories can't be seen by other members of Wattpad, so they don't get any new reads, votes, or comments.",{"name":"trans","hash":{},"data":data}))
    + "\n            </div>\n\n            <div class=\"action-buttons two-btns\">\n              <button class=\"btn btn-long btn-teal on-do-unpublish-part\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-short btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n          </div>\n\n          <div class=\"hidden question-modal delete-published-part-modal\">\n            <div class=\"prompt\">\n              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"If you're unsure, it's better to <strong>unpublish</strong> your part. Unpublished parts can only be seen by you, so they don't get any new reads, votes, or comments.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"action-buttons\">\n              <button class=\"btn btn-red on-delete-part\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-teal on-do-unpublish-part\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n          </div>\n\n          <div class=\"hidden question-modal delete-part-modal\">\n            <div class=\"prompt\">\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>reads</strong> for this part will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>votes</strong> for this part will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>comments</strong> for this part will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n            </div>\n            <div class=\"action-buttons two-btns\">\n              <button class=\"btn btn-long btn-red on-do-delete-part\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Forever",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-short btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n          </div>\n\n          <div class=\"hidden question-modal unpublish-story-modal\">\n            <div class=\"prompt\">\n              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Draft stories can't be seen by other members of Wattpad, so they don't get any new reads, votes, or comments.",{"name":"trans","hash":{},"data":data}))
    + "\n            </div>\n            <div class=\"action-buttons two-btns\">\n              <button class=\"btn btn-long btn-teal on-do-unpublish-story cta\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-short btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n\n            <div id=\"working-wrapper\" class=\"hidden\">\n              <div id=\"unpublish-working\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Working",{"name":"trans","hash":{},"data":data}))
    + " &hellip;</div>\n              <div class=\"progress\">\n                <div class=\"progress-bar progress-bar-info\" role=\"progressbar\" aria-valuenow=\"0\" aria-valuemin=\"0\"\n                  aria-valuemax=\"100\" style=\"width: 0%;\"></div>\n              </div>\n            </div>\n          </div>\n\n          <div class=\"hidden question-modal delete-published-story-modal\">\n            <div class=\"prompt\">\n              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"If you're unsure, it's better to <strong>unpublish</strong> your story. Unpublished stories can only be seen by you, so they don't get any new reads, votes, or comments.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"action-buttons\">\n              <button class=\"btn btn-red on-delete-story\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-teal on-unpublish-story\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              <button class=\"btn btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n          </div>\n\n          <div class=\"hidden question-modal delete-story-modal\">\n            <div class=\"prompt\">\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>parts</strong> for this story will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>reads</strong> for this story will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>votes</strong> for this story will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n              <div>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"All <strong>comments</strong> for this story will be <strong>deleted</strong>.",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n            </div>\n\n            <form id=\"delete-story-form\">\n              <div class=\"hidden alert alert-danger\" role=\"alert\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",16,"wp-error-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <div>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please correct the following",{"name":"trans","hash":{},"data":data}))
    + "</div>\n                <ul class=\"messages\"></ul>\n              </div>\n\n              <div class=\"form-group\">\n                <input id=\"username\" class=\"form-control input-lg enable\" type=\"text\" autocomplete=\"off\" autocorrect=\"off\"\n                  autocapitalize=\"off\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Username",{"name":"trans","hash":{},"data":data}))
    + "\">\n              </div>\n              <div class=\"form-group last\">\n                <input id=\"confirm-username\" class=\"form-control input-lg enable\" type=\"text\" autocomplete=\"off\"\n                  autocorrect=\"off\" autocapitalize=\"off\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Confirm Username",{"name":"trans","hash":{},"data":data}))
    + "\">\n              </div>\n\n              <div class=\"action-buttons two-btns\">\n                <input type=\"submit\" class=\"btn btn-long btn-red\" value=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Forever",{"name":"trans","hash":{},"data":data}))
    + "\">\n                <button class=\"btn btn-short btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n              </div>\n            </form>\n          </div>\n\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true,"useDepths":true});