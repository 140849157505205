var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "<div class=\"col-sm-4 col-lg-5 follower-request-container\">\n    <section id=\"profile-request\">"
    + ((stack1 = ((helper = (helper = helpers.requestContent || (depth0 != null ? depth0.requestContent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"requestContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n</div>\n<div class=\"col-sm-8 col-lg-7 profile-disclaimer-container\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "<div class=\"col-sm-12 profile-disclaimer-container\">\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.followerRequested : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials["core.profile.private_profile_disclaimer"],depth0,{"name":"core.profile.private_profile_disclaimer","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"usePartial":true,"useData":true});