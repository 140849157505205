(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "FollowRequests",
    app.collections.ProfileFollowers.extend({
      cacheLocal: false,

      usernameErr:
        "FollowRequests collection requires a username string to be passed to the options hash on init",

      fields: [
        {
          users: ["username", "avatar"]
        },
        "total",
        "nextUrl"
      ],

      url: function() {
        return "/api/v3/users/" + this.username + "/followers/requests";
      },

      resource: function() {
        return "user." + this.username + ".follow-requests";
      }
    })
  );
})(window, _, wattpad, window.app);
