app.router.add({
  "user/:username/cookies": [
    "cookieManager",
    {
      regexp: {
        username: /[\w\-]+/
      }
    }
  ]
});
