var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " data-toggle=\"dropdown\" ";
},"3":function(container,depth0,helpers,partials,data) {
    return " data-toggle=\"modal\" data-target=\"#table-of-contents\" ";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "data-toggle=\"tooltip\" data-placement=\"bottom\" title=\""
    + container.escapeExpression((helpers.sanitize || (depth0 && depth0.sanitize) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    return "<span class=\"deleted-tag\">("
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted",{"name":"trans","hash":{},"data":data}))
    + ")</span>";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["desktop.story_reading.table_of_contents"],depth0,{"name":"desktop.story_reading.table_of_contents","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["mobile.story_reading.table_of_contents"],depth0,{"name":"mobile.story_reading.table_of_contents","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"preview-alert\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Previewing %s",(depth0 != null ? depth0.title : depth0),{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.isPaywalled : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <div class=\"button-group relative inline-block\">\n              <div class=\"wallet-container\">\n                "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Wallet",((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.id : stack1),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n              </div>\n          </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div id=\"admin-panel\" class=\"button-group relative inline-block hidden-mobile\">\n        <button class=\"btn btn-link dropdown-toggle on-admin-dropdown\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-lock",16,"wp-base-1-dark",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </button>\n        <div class=\"triangle\"></div>\n    </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"button-group relative inline-block hidden-mobile writer-button-group\">\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isPreview : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"PublishButton","publish-button",(depth0 != null ? depth0.publishButtonSettings : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <a class=\"on-navigate btn btn-grey on-write\" href=\"/myworks/"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.id : stack1), depth0))
    + "/write/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Back to writing",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "        <a class=\"btn btn-link\" href=\"/myworks/"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.id : stack1), depth0))
    + "/write/"
    + alias1(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-edit",16,"wp-base-1-dark",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n          <span class=\"hidden-mobile hidden-xs hidden-sm story-edit\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"Edit part",{"name":"trans","hash":{},"data":data}))
    + "</span>\n        </a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.draft : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isPreview : depth0),{"name":"unless","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, buffer = 
  "\n    <div class=\"button-group relative inline-block\">\n        <button class=\"btn btn-story-lists btn-sm btn-orange\" data-story-id=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.id : stack1), depth0))
    + "\" aria-label=\""
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"Add to List",{"name":"trans","hash":{},"data":data}))
    + "\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-plus",10,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </button>\n";
  stack1 = ((helper = (helper = helpers.group || (depth0 != null ? depth0.group : depth0)) != null ? helper : alias3),(options={"name":"group","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias2,options) : helper));
  if (!helpers.group) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    </div>\n    ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.components.add_to_library_dropdown"],depth0,{"name":"core.components.add_to_library_dropdown","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.draft : depth0),{"name":"unless","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n    <div class=\"button-group relative inline-block\">\n        <button class=\"btn btn-link on-vote\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.voted : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data})) != null ? stack1 : "")
    + "        </button>\n        <div id=\"vote-tool-tip\" data-toggle=\"tooltip\" data-placement=\"bottom\" data-original-title= \""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"There was an error registering your vote",{"name":"trans","hash":{},"data":data}))
    + "\"></div>\n    </div>\n    ";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            <span class=\"hidden-mobile hidden-xs hidden-sm story-vote\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Voted",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            <span class=\"hidden-mobile hidden-xs hidden-sm story-vote\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Vote",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"BuyCoinsReactModal","buy-coins-modal",(depth0 != null ? depth0.buyCoinsModal : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n<div id=\"funbar-story\" class=\"dropdown\">\n  <button id=\"funbar-part-details\" type=\"button\" class=\"btn btn-toc dropdown-toggle\"\n          "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + ">\n      <span class=\"toc-full\">\n        <span class=\"cover cover-xxs\" "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n          <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.cover : stack1),27,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}))
    + "\">\n        </span>\n\n        <span class=\"info\">\n          <h2 class=\"title h5\">\n            "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.title : stack1), depth0))
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deleted : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n          </h2>\n          <span class=\"author h6\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),{"name":"trans","hash":{},"data":data}))
    + "</span>\n        </span>\n\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-down",20,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </span>\n  </button>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPreview : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div id=\"funbar-controls\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showAdminPanel : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAuthor : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.deleted : stack1),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.deleted : depth0),{"name":"unless","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n</div>\n\n<div id=\"funbar-reading-progress\">\n  <div id=\"progressbar\"></div>\n  <div id=\"progresstooltip\" data-toggle=\"tooltip\" data-placement='bottom'></div>\n</div>\n";
},"usePartial":true,"useData":true});