var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "        <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"header clearfix on-navigate\">\n            <div class=\"cover cover-xs pull-left\">\n                <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),50,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n            </div>\n            <div class=\"content\">\n                <h4 class=\"h3\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + " - "
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.part : depth0)) != null ? stack1.title : stack1), depth0))
    + "</h4>\n                <small>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + " &middot; "
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Part","%s Parts",(depth0 != null ? depth0.numParts : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numParts : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</small>\n                <div class=\"meta\">\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<small class=\"reads\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<small class=\"votes\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<header class=\"panel-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm2 on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n      <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <h3 class=\"h6\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"%s commented on %s - %s",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"trans","hash":{},"data":data}))
    + "</h3>\n    <time datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_options"],depth0,{"name":"core.feed_items.feed_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n\n<div class=\"panel-body\">\n    <article class=\"panel comment\">\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1), depth0),{"name":"data.story","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <blockquote class=\"comment-body\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.comment : stack1)) != null ? stack1.body : stack1),2000,true,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</blockquote>\n    </article>\n\n    <button class=\"btn btn-triangle btn-library\" data-icon=\"true\" data-story-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"Add to Library",{"name":"trans","hash":{},"data":data}))
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias4).call(alias3,"fa-plus",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <span class=\"toggle-triangle\"></span>\n        <span class=\"toggle-shadow\"></span>\n    </button>\n</div>\n\n<footer class=\"panel-footer\">\n    <a class=\"btn btn-text on-navigate\" href=\""
    + alias2((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.comment : stack1)) != null ? stack1.deeplink_url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias4).call(alias3,"fa-comment",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"View Conversation",{"name":"trans","hash":{},"data":data}))
    + "</a>\n</footer>\n";
},"usePartial":true,"useData":true});