window.mobileApp = window.mobileApp || {};
window.mobileApp.config = function(appWindow, Monaco, wattpad, app) {
  "use strict";

  if (app === null || typeof app === "undefined") {
    return;
  }

  wattpad = wattpad || (appWindow.wattpad || (appWindow.wattpad = {}));
  var utils = wattpad.utils || (wattpad.utils = {});

  if (!appWindow.app) {
    appWindow.app = app;
  }

  // ===== Language
  app.set("default-language", "1"); // English

  var supportedLanguages = new app.collections.Languages(
    wattpad.supportedLangs
  ); // from layout.blade.php
  app.set("supported-languages", supportedLanguages);

  // find language code based on locale
  var cookieLocale =
    wattpad && wattpad.utils && wattpad.utils.getCookie
      ? utils.getCookie("locale")
      : false;
  cookieLocale =
    cookieLocale === null || cookieLocale === false ? "en_US" : cookieLocale; // ensure value is legit
  var langId = supportedLanguages.find(function(model) {
    return model.get("locale") === cookieLocale;
  });

  langId = langId ? langId.get("id").toString() : "";

  if (
    (!langId || langId === "") &&
    (!app.get("language") || app.get("language") === "")
  ) {
    app.set("language", app.get("default-language"), true);
    wattpad.language = app.get("default-language");
  } else if (langId && langId !== "") {
    app.set("language", langId, true);
    wattpad.language = langId;
  }

  app.set("api-key-web", wattpad.apiAuthKey);

  // ===== Hammer
  if (appWindow.jQuery) {
    appWindow
      .jQuery(
        "body > div:not(#header-container,.shadow-banner), #header, #footer-container"
      )
      .hammer({
        behavior: {
          userSelect: true
        }
      });

    if (utils.modernizr("fontface")) {
      appWindow.jQuery("body").removeClass("ff-unsupported");
    } else {
      appWindow.jQuery("link#font-awesome").remove();
    }
  }

  app.set("device", new utils.Device());

  // == properties needed for server/client binding
  app._firstRun = true;

  //Set up cookies for server support
  // ===== Font Face Support

  if (
    wattpad &&
    wattpad.utils &&
    wattpad.utils.getCookie &&
    wattpad.utils.setCookie
  ) {
    var ffCookie = utils.getCookie("ff");
    var ffSupported = wattpad.utils.modernizr
      ? utils.modernizr("fontface")
        ? 1
        : 0
      : 0;
    if (!ffCookie || ffCookie === null) {
      utils.setCookie("ff", ffSupported);
    }

    // ===== Pixel Density Ratio
    if (window.devicePixelRatio) {
      utils.setCookie("dpr", window.devicePixelRatio);
    }

    // ===== Time Zone offset
    // Always set up the tz offset, to account for changing time zones (eg DST).
    var tzOffset = new Date().getTimezoneOffset() / 60;
    utils.setCookie("tz", tzOffset);

    let timeZone = Intl && Intl.DateTimeFormat().resolvedOptions().timeZone;
    if (timeZone) {
      utils.setCookie("X-Time-Zone", timeZone);
    }
  }

  // == Experiments
  _.each(window.wattpad.experiments, function(experiment, id) {
    var variations = {};
    variations[experiment.variation] = {
      suffix: wattpad.utils.toPascalCase(experiment.variation)
    };
    app.experiments.set(id, variations, {
      users: 1.0,
      experimentKey: experiment.key,
      status: experiment.status
    });
    app.experiments.get(id).split();
  });
};
window.mobileApp.config(window, Monaco, wattpad, window.app);
