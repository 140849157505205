(function(window, _, wattpad, utils, app) {
  "use strict";
  /**
   * Handles validation of attributes on a model - includes a base suite of generic validation methods
   * Tests are called via a `validate` method, which runs in conjunction with a `validationRules` hash
   * defining which methods to test against each attribute. [ example @ `core/models/auth-signup.js` ]
   *
   * Any async tests using API endpoints for validation are deferred until client-side checks are complete.
   * - The rule hash for API tests requires an `async: true` property.
   * - An async function should return a Promise instead of a boolean.
   * - Due to complications with returning asynchronous results to synchronous functions, API checks
   *     will not affect the return value of `validate` (as used by `set` & `save`).
   * @mixin ValidationModel
   */
  app.add(
    "ValidationModel",
    Monaco.Mixin.create({
      regex: {
        email: /^[a-zA-Z0-9_\.\'\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/,
        az09: /^[a-z0-9_-]+$/i,
        phone: /^\+?\d{8,16}$/, // loose validation supporting various international formats
        hasLetter: /[a-z]/i,
        hasNumber: /\d/,
        isNumber: /^\d+$/
      },

      // client side validation (synchronous)
      validate: function(attributes, options, n) {
        var validationErrors = {},
          asyncTests = {},
          attrsCopy,
          ignore = {};

        options = options || {};

        if (options.ignore) {
          // Ignored rules can be in string or array format:
          // { ignore: { <attribute>: 'Rule1' }} or
          // { ignore: { <attribute>: [ 'Rule1', 'Rule2' ]}
          ignore = options.ignore;
        }

        if (options.validation && options.validation.ignore) {
          ignore = options.validation.ignore;
        }

        if (!this.validationRules) {
          return null; // no validation rules, no validation. duh.
        }

        // Normalize function input:
        // -- attributes can be a String (attr key), Array (attr keys), or Object (this.attributes)
        // -- defaults to `this.attributes` if no arguments are supplied
        switch (typeof attributes) {
          case "string":
            attributes = [attributes];
            break;
          case "undefined":
            attributes = this.attributes;
            break;
        }

        if (_.isArray(attributes)) {
          attrsCopy = attributes;
          attributes = {};
          _.each(
            attrsCopy,
            function(key) {
              attributes[key] = this.get(key);
            },
            this
          );
        }

        // run tests defined in validationRules hash
        _.forOwn(
          attributes,
          function(value, key) {
            if (this.validationRules[key]) {
              _.each(
                this.validationRules[key],
                function(rule) {
                  var valid;

                  // If validation rules for a certain attribute are set to be ignored,
                  // then ignore these rules and return null.
                  if (_.includes([].concat(ignore[key]), rule.func) || !rule) {
                    return;
                  }

                  // defer api based validation (push rules to asyncTests obj)
                  if (rule.async) {
                    if (!asyncTests[key]) {
                      asyncTests[key] = {
                        value: value,
                        rules: []
                      };
                    }
                    asyncTests[key].rules.push(rule);
                  } else {
                    valid = this[rule.func](value, rule);
                    if (!valid) {
                      // pass attribute key & error message to view via validation event
                      this.trigger("attr:invalid", key, rule.msg);
                      validationErrors[key] = rule.msg;
                      return false;
                    }
                  }
                },
                this
              );

              // if there are no errors, and no outstanding (async) tests trigger a `valid` event
              if (!validationErrors[key] && !asyncTests[key]) {
                this.trigger("attr:valid", key);
              }
            }
          },
          this
        );

        // if any asynchronous tests exist call them now
        if (_.keys(asyncTests).length) {
          this.validateAsync(asyncTests);
        }

        n();

        // return hash of messages about errors caught during validation or `null` if all tests validate (client-side only)
        return _.keys(validationErrors).length ? validationErrors : null;
      },

      validateAsync: function(asyncTests, n) {
        _.forOwn(
          asyncTests,
          function(attribute, key) {
            var promises = [],
              valid = true,
              self = this;

            _.each(
              attribute.rules,
              function(rule) {
                var promise = this[rule.func](attribute.value, rule)["catch"](
                  function(error) {
                    var msg = rule.msg
                        ? rule.msg
                        : error.responseJSON.message || null,
                      err = error.responseJSON.error_code || 0;

                    if (attribute.value === self.get(key)) {
                      // Wattpad API errors have a code between 1000 & 1100 & have a message
                      if (
                        err >= 1000 &&
                        err < 1100 &&
                        error.responseJSON.message
                      ) {
                        self.trigger("attr:invalid", key, msg);
                      } else {
                        self.trigger(
                          "attr:warning",
                          key,
                          utils.trans("An error occurred during validation.")
                        );
                      }
                    }

                    valid = false;
                  }
                );

                promises.push(promise);
              },
              this
            );

            Promise.all(promises).then(function() {
              if (attribute.value === self.get(key) && valid) {
                self.trigger("attr:valid", key);
              }
            });
          },
          this
        );
        n();
      },

      // Base Validation Tests
      // -- validation functions **must** take two arguments ( value, options ) & return a boolean
      isRequired: function(value, options, n) {
        n();
        return !_.isEmpty(value);
      },
      isEmail: function(value, options, n) {
        n();
        return value.match(this.regex.email) !== null;
      },
      isAlphaNumeric: function(value, options, n) {
        n();
        return value.match(this.regex.az09) !== null;
      },
      isMatch: function(value, options, n) {
        n();
        return value === this.get(options.matchesKey);
      },
      hasNumber: function(value, options, n) {
        n();
        return value.match(this.regex.hasNumber) !== null;
      },
      hasLetter: function(value, options, n) {
        n();
        return value.match(this.regex.hasLetter) !== null;
      },
      minLength: function(value, options, n) {
        n();
        return value.length >= options.length;
      },
      maxLength: function(value, options, n) {
        n();
        return value.length <= options.length;
      },
      isNumber: function(value, options, n) {
        n();
        return value.match(this.regex.isNumber) !== null;
      },
      minValue: function(value, options, n) {
        n();
        return (
          !value ||
          (value &&
            (typeof value === "string" ? parseInt(value, 10) : value) >=
              options.min)
        );
      },
      isEq: function(value, options, n) {
        n();
        return value === options.val;
      },
      isNotEq: function(value, options, n) {
        n();
        return !this.isEq(value, options);
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
