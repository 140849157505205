(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "ManagePayment",
    app.views.DummyReactView.extend({
      component: "ManagePayment",

      componentId: "user",

      showCancelModal: function(cancelDate) {
        var cancelModal = new app.views.DummyReactView({
          component: "CancelSubscriptionModal",
          componentId: "PaymentManagement",
          componentData: {
            cancelDate: cancelDate,
            cancelSubscription: this.cancelSubscription.bind(this)
          }
        });
        cancelModal.render();

        $("#generic-modal .modal-body").html(cancelModal.$el);
        $("#generic-modal").modal("show");
      },

      cancelSubscription: function() {
        // Mock code for testing, to be replaced by an API request to the subscriptions service
        console.log("Making cancel subscription API request...");
        return new Promise(function(resolve, reject) {
          setTimeout(function() {
            console.log("Subscription successfully cancelled");
            resolve();

            // console.log("Subscription failed to cancel");
            // reject();
          }, 500);
        });
      },

      updatePaymentInformation: function(token, email) {
        // TODO: hit the API
        return Promise.resolve();
      },

      constructor: function() {
        var user = wattpad.user;
        this.componentData = {
          isPremium: user.isPremium,
          showCancelModal: this.showCancelModal.bind(this),
          updatePaymentInformation: this.updatePaymentInformation.bind(this),
          username: user.username,
          locale: wattpad.utils.getCookie("locale")
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
