import getSearchTitle from "../../../helpers/client.get-search-title";
import tagsHelper from "../../../helpers/tags-helper";
import { isForcedSignupSession } from "../../../helpers/forced-signup";
import { isForcedSignupExemptView } from "../../../helpers/client.forced-signup";
import { isUnsafe } from "../../../helpers/is-unsafe";
import generateUUID from "../../../helpers/generate-uuid";
import isPaidStory from "../../../helpers/is-paid-story";

(function(window, _, wattpad) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});
  var utils = wattpad.utils || (wattpad.utils = {});

  utils.bootstrapReactRoot = function() {
    var rootComponent = window.app.components["ReactRoot"];
    var wrappedComponent = window.app.components.withRedux(
      rootComponent,
      window.store
    );
    ReactDOM.render(
      React.createElement(wrappedComponent),
      document.getElementById("react-client-root")
    );
  };

  utils.getWrapParams = function(args) {
    return Array.prototype.slice.call(args, 1);
  };

  utils.isToday = function(date) {
    var today = moment();
    return (
      today.isSame(date, "day") &&
      today.isSame(date, "month") &&
      today.isSame(date, "year")
    );
  };

  // Intercepts the paste event and returns only plaintext
  utils.pastePlainText = function(e) {
    var content;
    e.preventDefault();
    if ((e.originalEvent || e).clipboardData) {
      content = (e.originalEvent || e).clipboardData.getData("text/plain");
      document.execCommand("insertText", false, content);
    } else if (window.clipboardData) {
      //  Solution for IE
      content = window.clipboardData.getData("Text");
      // Solution for < IE 11 and Edge
      var selection = document.selection || document.getSelection();
      selection.createRange().pasteHTML(content);
    }
    return content;
  };

  utils.pixelRatio = function(decimal) {
    var dpr = window.devicePixelRatio;

    if (!decimal) {
      return (
        dpr ||
        (this.pixelRatio(3)
          ? 3
          : this.pixelRatio(2)
            ? 2
            : this.pixelRatio(1.5)
              ? 1.5
              : this.pixelRatio(1)
                ? 1
                : 0)
      );
    }

    if (dpr && dpr > 0) {
      return dpr >= decimal;
    }

    var matchMedia = window.matchMedia || window.msMatchMedia;

    if (!matchMedia) {
      return false;
    }

    decimal = "only all and (min--moz-device-pixel-ratio:" + decimal + ")";
    if (matchMedia(decimal).matches) {
      return true;
    }
    return !!matchMedia(decimal.replace("-moz-", "")).matches;
  };

  utils.getUserAgent = function(maxLength) {
    if (maxLength > 0) {
      return window.navigator.userAgent.substr(0, maxLength);
    }
    return window.navigator.userAgent;
  };

  utils.getCookie = function(key) {
    var result = new RegExp(
      "(?:^|; )" + encodeURIComponent(key) + "=([^;]*)"
    ).exec(window.document.cookie);
    if (result && result.length > 0) {
      return window.decodeURIComponent(result[1]);
    }
    return null;
  };

  utils.setCookieWithExpiry = function(key, value, expiryDate, useBaseDomain) {
    var expires = "";
    if (expiryDate) expires += "; expires=" + expiryDate.toGMTString();

    value = window.encodeURIComponent(value);
    var cookieString =
      key + "=" + value + expires + "; path=/; SameSite=Lax; Secure;";
    if (useBaseDomain) {
      var domain = window.document.domain.split(".");
      domain =
        "." + domain[domain.length - 2] + "." + domain[domain.length - 1];
      cookieString += " domain=" + domain + ";";
    }
    window.document.cookie = cookieString;
    return cookieString;
  };

  //sending a 0 in the days column will create a cookie
  //that expires at the end of your session
  utils.setCookie = function(key, value, days, useBaseDomain) {
    if (days !== 0) {
      var date = new Date();
      days = days && _.isNumber(days) ? days : 365;
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      return utils.setCookieWithExpiry(key, value, date, useBaseDomain);
    } else {
      return utils.setCookieWithExpiry(key, value, "", useBaseDomain);
    }
  };

  utils.destroyCookie = function(key, useBaseDomain) {
    utils.setCookie(key, "{}", -365, useBaseDomain);
  };

  utils.getLocalStorage = function(key) {
    var result = window.localStorage.getItem(key);
    if (!result) return null;
    result = JSON.parse(result);
    var expiresAt = result.expiresAt;

    if (expiresAt && expiresAt < new Date()) {
      window.localStorage.removeItem(key);
      return null;
    }

    return result.value;
  };

  utils.setLocalStorageWithExpiry = function(key, value, expiryDate) {
    try {
      window.localStorage.setItem(
        key,
        JSON.stringify({
          expiresAt: expiryDate || null,
          value: value
        })
      );
    } catch (e) {
      if (
        DOMException &&
        e &&
        (DOMException.QUOTA_EXCEEDED_ERR &&
          e.code &&
          e.code === DOMException.QUOTA_EXCEEDED_ERR)
      ) {
        console.warn("localStorage quota reached");
      } else {
        console.warn("localStorage not supported");
      }
    }
  };

  utils.supportPushState = function() {
    return !!(window.history && window.history.pushState);
  };

  utils.serializeObject = function(array) {
    var o = {};
    $.each(array, function() {
      if (o[this.name] !== undefined) {
        if (!o[this.name].push) {
          o[this.name] = [o[this.name]];
        }
        o[this.name].push(this.value || "");
      } else {
        o[this.name] = this.value || "";
      }
    });
    return o;
  };

  utils.modernizr = function(test) {
    var modernizrTest, i;
    if (!window.Modernizr) {
      return false;
    }

    //-- BEGIN False Positives --//
    if (utils.isOperaMini) {
      if (test === "input.placeholder") {
        return false;
      }
    }

    if (utils.isLTIE10Mobile) {
      if (test === "fontface") {
        return false;
      }
    }
    //-- END False Positives --//

    modernizrTest = window.Modernizr;
    test = test.split(".");

    for (i = 0; i < test.length; i++) {
      modernizrTest = modernizrTest[test[i]];
    }

    return modernizrTest;
  };

  utils.supportsTransition = function() {
    var thisBody = window.document.body || window.document.documentElement,
      thisStyle = thisBody.style;

    return (
      thisStyle.transition !== undefined ||
      thisStyle.WebkitTransition !== undefined ||
      thisStyle.MozTransition !== undefined ||
      thisStyle.MsTransition !== undefined ||
      thisStyle.OTransition !== undefined
    );
  };
  utils.transitionEndStrings =
    "webkitTransitionEnd oTransitionEnd MSTransitionEnd transitionend";

  utils.scrollIntoView = function(element) {
    var $element;
    if (element) {
      $element = $(element);
      element = $element[0];

      if ($element.length) {
        //Opera Mini & Android < 3
        if (
          utils.isOperaMini ||
          utils.isAndroidStockBrowser(2, true) ||
          !element.scrollIntoView
        ) {
          window.scrollTo(0, $element.offset().top + 10);
        } else {
          element.scrollIntoView(true);
        }
      }
    }
  };

  utils.isOperaMini = (function() {
    return Boolean(window.navigator.userAgent.match(/(Opera Mini)/));
  })();

  utils.isLTIE10Mobile = (function() {
    return (
      Boolean(window.navigator.userAgent.match(/(MSIE 9.+ IEMobile)/)) ||
      Boolean(window.navigator.userAgent.match(/(MSIE 8.+ IEMobile)/)) ||
      Boolean(window.navigator.userAgent.match(/(MSIE 7.+ IEMobile)/))
    );
  })();

  utils.chromeAndroidVer = function() {
    var chromeRegex = /Chrome\/([0-9.]+)\ Mobile/;
    var chromeVer = chromeRegex.exec(window.navigator.userAgent);
    return chromeVer ? parseInt(chromeVer[1]) : 0;
  };

  /*
   * Checks if the current browser is android and what the version is
   * @version = android maj. version to check
   * @eq = true(exact match), false(less than or equal to)
   */
  utils.isAndroid = function(version, eq) {
    var major = 0;

    if (!version || typeof version != "number") {
      return Boolean(window.navigator.userAgent.match(/(Android)/));
    } else if (Boolean(window.navigator.userAgent.match(/(Android)/))) {
      major = window.navigator.userAgent.match(/Android (.)/);

      //Fix for the case when the major version is missing (firefox)
      if (major) {
        major = parseInt(major[1], 10);
      } else {
        return false;
      }

      if (!eq) {
        return major >= version;
      }

      return major === version;
    }

    return false;
  };

  /**
   * format a number
   *
   * @param integer value: number to be formatted
   * @param integer decimals: length of decimal, default is 0
   * @param integer sections: length of sections, default is 3
   */
  utils.format = function(value, decimals, sections) {
    var re =
      "\\d(?=(\\d{" +
      (sections || 3) +
      "})+" +
      (decimals > 0 ? "\\." : "$") +
      ")";
    return value
      .toFixed(Math.max(0, ~~decimals))
      .replace(new RegExp(re, "g"), "$&,");
  };
  Handlebars.registerHelper("format", utils.format);

  /*
   * Checks to ensure that the client browser is the stock android browser
   */
  utils.isAndroidStockBrowser = function(version, eq) {
    if (utils.isAndroid(version, eq)) {
      return (
        parseFloat(
          window.navigator.userAgent.match(/AppleWebKit\/([\d.]+)/)[1]
        ) < 535
      );
    }

    return false;
  };

  utils.openOnDesktop = function(win) {
    win = win && win.location ? win : window;
    var sanitizedUrl = utils.getDesktopUrl(win);

    win.document.cookie = "mw-no=true; path=/";

    if (win.location.href === sanitizedUrl) {
      win.location.reload();
    } else {
      win.location.href = sanitizedUrl;
    }
  };

  utils.getDesktopUrl = function(win) {
    win = win && win.location ? win : window;
    //sanitize url
    return win.location.href.replace(/\/paragraph\/[\w]+/, "");
  };

  utils.hideAddressBar = function() {
    //remove address bar once loaded (WEB-910)
    //Opera Mini can't use setTimeout
    if (wattpad.utils.isOperaMini) {
      utils.scrollToTop();
    }

    window.setTimeout(function() {
      if (window.scrollY < 5) {
        utils.scrollToTop();
      }
    }, 0);
  };

  utils.scrollToTop = function() {
    var scrollTop =
        window.document.body.scrollTop ||
        window.document.documentElement.scrollTop,
      scrollTo = window.scrollTo;

    if (scrollTo) {
      scrollTo(0, 0);
    } else {
      scrollTop = 0;
    }
  };

  utils.currentUser = function() {
    var result = app.get("currentUser");

    if (!result) {
      result = new app.models.User(wattpad.user);
      app.set("currentUser", result, false);
    }

    return result;
  };

  // Emulates the PHP urlEncode() function
  utils.urlEncode = function(str) {
    str = (str + "").toString();

    var urlEncodedString = "";

    try {
      urlEncodedString = encodeURIComponent(str).replace(/!/g, "%21");
    } catch (error) {
      // If the string we're encoded appears to have been shortened, account for the ellipsis when cutting off the end
      if (str.substr(-3) == "...") {
        str = str.substr(0, str.length - 4) + "...";
      } else {
        str = str.substr(0, str.length - 1);
      }
      urlEncodedString = encodeURIComponent(str).replace(/!/g, "%21");
    }

    return urlEncodedString
      .replace(/'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/\*/g, "%2A")
      .replace(/%20/g, "+");
  };

  utils.urlEncodeWithSpace = function(str) {
    str = (str + "").toString();

    return encodeURIComponent(str)
      .replace(/!/g, "%21")
      .replace(/'/g, "%27")
      .replace(/\(/g, "%28")
      .replace(/\)/g, "%29")
      .replace(/\*/g, "%2A");
  };

  utils.urlDecode = function(str) {
    str = (str + "").toString();

    return decodeURIComponent(
      str
        .replace(/%21/g, "!")
        .replace(/%27/g, "'")
        .replace(/%28/g, "(")
        .replace(/%29/g, ")")
        .replace(/%2A/g, "*")
        .replace(/\+/g, " ")
    );
  };

  // Returns an array of mention(s) or null
  utils.getMentions = function(text) {
    var regex = /((?![\/\.\#])(?:^|\W))@([\S\-]+)/gi; // same regex from linkify()
    return text.match(regex);
  };

  utils.linkify = function(text, options) {
    var patterns = {
        mention: /((?![\/\.\#])(?:^|\W))@([\w\d\-]+)/gi,
        url: /(http(s)?:\/\/[^\s]+)/gi,
        tag: /(?:^|\W)#([^\s\?\/]+)/gi
      },
      replacements = {
        mention: '$1<a class="on-user-mention" href="/user/$2">@$2</a>',
        url: '<a href="$1" rel="nofollow" target="_blank">$1</a>',
        tag: ' <a href="/tags/$1" class="on-navigate">#$1</a>'
      };

    options = options || {};

    if (options.hash) {
      if (options.hash.sanitization) {
        options.sanitization = options.hash.sanitization;
      }
    }
    options.sanitization = options.sanitization || "sanitize";
    options.doTags = options.doTags === false ? false : true;
    options.doUrl =
      options.doUrl === false || options.sanitization === "unsanitize"
        ? false
        : true;
    options.doMention = options.doMention === false ? false : true;

    switch (options.sanitization) {
      case "sanitize":
        text = wattpad.utils.sanitizeHTML(text);
        break;
      case "unsanitize":
        text = wattpad.utils.unsanitizeHTML(text);
        break;
      case "none":
        break;
    }
    if (options.doMention) {
      text = text.replace(patterns.mention, replacements.mention);
    }
    if (options.doUrl) {
      text = text.replace(patterns.url, replacements.url);
    }
    if (options.doTags) {
      text = text.replace(patterns.tag, replacements.tag);
    }

    return text;
  };

  /*
   find and return media links in a text, optionally replacing the found
   media link with a placeholder

   options          object
   text          string    required - where the search for media links will be performed
   placeholder   string    optional - media links found in the text will be replaced with this if provided
   firstOnly     boolean   optional - flag indicating if only the first media link should be processed (default: false)
   */
  utils.findMediaInText = function(options) {
    var patterns = {
        image: /(?:https?:)?\/\/(?:[\w\-]+\.)+[a-z]{2,6}(?:\/[^\/#?]+)+\.(?:jpg|gif|png)/
      },
      found = [],
      flags = "i",
      re,
      items;

    if (options.text) {
      options.placeholder =
        options.placeholder !== void 0 ? "" + options.placeholder : false;
      options.firstOnly = !!options.firstOnly;
      if (!options.firstOnly) {
        flags += "g";
      }

      _.each(patterns, function(pattern) {
        re = new RegExp(pattern.source, flags);
        items = options.text.match(re);
        if (items) {
          if (options.placeholder) {
            options.text = options.text.replace(re, options.placeholder);
          }
          found = found.concat(items);
        }
      });
    }

    return {
      text: options.text,
      media: found
    };
  };

  utils.stopEvent = function(evt) {
    var srcEvent =
        evt && evt.hasOwnProperty("gesture") ? evt.gesture.srcEvent : evt,
      cmdOrCtrlKeyPressed = srcEvent
        ? srcEvent.metaKey || srcEvent.ctrlKey
        : false;

    if (evt && !cmdOrCtrlKeyPressed) {
      evt.preventDefault();
      evt.stopPropagation();
      // tap event -> stop touchstart & touchend events
      if (evt.hasOwnProperty("gesture")) {
        evt.gesture.preventDefault();
        evt.gesture.stopPropagation();
        evt.gesture.stopDetect();
      }
    }
  };

  utils.supportLocalstorage = function() {
    var app = window.app,
      test = "wattpad.com.test";

    try {
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      return true;
    } catch (e) {
      if (
        DOMException &&
        e &&
        (DOMException.QUOTA_EXCEEDED_ERR &&
          e.code &&
          e.code === DOMException.QUOTA_EXCEEDED_ERR)
      ) {
        if (typeof app !== "undefined" && app.local !== "undefined") {
          app.local.clear();
        }
        return true;
      } else {
        console.warn("localStorage not supported");
      }
    }
    return false;
  };

  utils.setRedirectParams = function(pathname, params = {}) {
    const redirectObject = { pathname, params };
    if (navigator.cookieEnabled) {
      utils.setCookie("redirectParams", JSON.stringify(redirectObject), 0.0025);
    } else {
      app.set("redirectParams", redirectObject);
    }
  };

  utils.redirectWithParams = function(pathname, params = {}, trigger = true) {
    utils.setRedirectParams(pathname, params);
    app.router.navigate(pathname, {
      trigger
    });
  };

  utils.getRedirectParams = function() {
    if (navigator.cookieEnabled) {
      try {
        const cookieRedirectData = JSON.parse(
          utils.getCookie("redirectParams")
        );
        utils.destroyCookie("redirectParams");
        if (cookieRedirectData.pathname == window.location.pathname) {
          return cookieRedirectData.params;
        }
        return {};
      } catch (e) {
        return {};
      }
    }
    const appRedirectData = app.get("redirectParams");
    if (!appRedirectData) {
      return {};
    }
    app.set("redirectParams", null);
    return appRedirectData.pathname == window.location.pathname
      ? appRedirectData.params
      : {};
  };

  utils.redirectToCSR = function(pathname, options) {
    options = options || {};
    app.router.navigate(pathname, options);
  };

  utils.redirectToServer = function(url) {
    if ($("body").hasClass("js-app-off")) {
      return;
    } else if (url) {
      utils._redirectToServer(url);
    } else {
      utils._reloadFromServer();
    }
  };

  utils.redirectToPublishedPart = function(url, parts, isDraft) {
    // If this is the first time publishing a part for the story, set firstPublish to true.
    var redirectUrl = wattpad.utils.formatStoryUrl(url);
    var hasNoPublishedStoryParts = _.every(parts, function(obj) {
      return obj.draft === true;
    });

    _.delay(function() {
      if (isDraft && hasNoPublishedStoryParts) {
        wattpad.utils.redirectWithParams(redirectUrl, { firstPublish: true });
        return;
      }
      app.router.navigate(redirectUrl, {
        trigger: true
      });
    }, 500);
  };

  utils._redirectToServer = function(url) {
    window.location.href = url;
  };

  utils._reloadFromServer = function() {
    window.location.reload();
  };

  utils.goBackInHistory = function() {
    window.history.go(-1);
  };

  utils.getWindowSearch = function() {
    return window.location.search;
  };

  utils.reloadWithQuery = function(query) {
    window.location =
      "//" +
      window.location.hostname +
      window.location.pathname +
      query +
      window.location.hash;
  };

  utils.cacheBust = function(resources, options, id, isModel) {
    var result = [];
    resources = Array.isArray(resources) ? resources : [resources];
    options = Array.isArray(options) ? options : [options];

    _.forEach(resources, function(item, i) {
      var resource, prom;
      if (isModel) {
        resource = new app.models[item](options[i]);
      } else {
        resource = new app.collections[item]([], options[i]);
      }

      if (resource instanceof app.collections.IncrementalFetch) {
        prom = Promise.resolve(resource.fetchNextSet({ localOnly: true }));
      } else {
        prom = Promise.resolve(resource.fetch({ localOnly: true }));
      }

      prom.then(function() {
        if (!id || resource.get(id)) {
          app.local._clearItem(_.result(resource, "resource"));
        }
      });

      result.push(prom);
    });

    return Promise.all(result);
  };

  utils.shouldSeePaidOnboarding = function(storyIsPaywalled = false) {
    // Use a cookie to track if the user has seen the paid
    // onboarding, so they only see it once.
    const onboardingCookie = "seen-wo-onboard";
    const hasSeenPaidOnboarding =
      parseInt(wattpad.utils.getCookie(onboardingCookie)) || false;

    return !hasSeenPaidOnboarding && storyIsPaywalled;
  };

  utils.showPaidOnboardingAfterDelay = function() {
    const MODAL_SHOW_DELAY = 1500;

    // The user might interact with the page before the modal is
    // shown. The paid onboarding is a very important, one-time
    // interaction, so block other interactions until the modal opens.
    $("body").addClass("js-app-off");

    const startRoute = window.location.pathname;
    setTimeout(() => {
      // TODO: evaluate if we can use inert for this:
      // https://css-tricks.com/focus-management-and-inert/

      // NOTE: THIS LINE MUST RUN, or the app will be unusable.
      // Don't add any code that could cause this to not run in any case.
      $("body").removeClass("js-app-off");

      // The user may have navigated to a place where the onboarding
      // modal shouldn't be shown, so skip showing the modal this
      // time.
      const currentRoute = window.location.pathname;
      const userHasNavigated = currentRoute !== startRoute;
      if (userHasNavigated) {
        return;
      }

      window.app.components.showPaidOnboardingModal({
        premium: wattpad.utils.currentUser().get("isPremium")
      });
    }, MODAL_SHOW_DELAY);
  };

  utils.getAppUrl = function(medium) {
    medium = medium || "mobileweb";

    var device = app.get("device");
    if (device && device.is.ios) {
      return "https://my.w.tt/kwXixwM3J5";
    }
    if (device && device.is.huawei) {
      return "https://appgallery5.huawei.com/#/app/C100252597";
    }
    if (device && device.is.android) {
      return "https://my.w.tt/ngdWYy82J5";
    }
    if (device && device.is.kindle) {
      return "https://www.amazon.com/Unlimited-Stories-Wattpad-Free-Reader/dp/B004K56MNU";
    }
    if (device && device.is.windows) {
      return "https://www.microsoft.com/store/apps/9nblggh6gm17";
    }
    return "/getmobile";
  };

  utils.getAllAppUrls = function(medium) {
    medium = medium || "mobileweb";

    return {
      ios: "https://my.w.tt/kwXixwM3J5",
      android: "https://my.w.tt/ngdWYy82J5",
      kindle:
        "https://www.amazon.com/Unlimited-Stories-Wattpad-Free-Reader/dp/B004K56MNU",
      windows: "https://www.microsoft.com/store/apps/9nblggh6gm17",
      huawei: "https://appgallery5.huawei.com/#/app/C100252597",
      fallback: "/getmobile"
    };
  };

  utils.openPopup = function(url, title, width, height) {
    var dualScreenLeft =
        window.screenLeft !== undefined ? window.screenLeft : screen.left,
      dualScreenTop =
        window.screenTop !== undefined ? window.screenTop : screen.top,
      windowWidth = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
          ? document.documentElement.clientWidth
          : screen.width,
      windowHeight = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
          ? document.documentElement.clientHeight
          : screen.height,
      left = windowWidth / 2 - width / 2 + dualScreenLeft,
      top = windowHeight / 2 - height / 2 + dualScreenTop,
      newWindow = window.open(
        url,
        title,
        "scrollbars=yes, width=" +
          width +
          ", height=" +
          height +
          ", top=" +
          top +
          ", left=" +
          left
      );

    if (window.focus) {
      newWindow.focus();
    }

    return newWindow;
  };

  /* This function returns a reordered list to make column display easier
   * The input list needs to be pre-sorted ( ie. this is the order the list
   * should be in if numColumns = 1 )

  ie. input = ( [a, b, c, d, e, f ], 2 )
   returns = [ a, d, b, e, c, f ]

  input = ( [ a, b, c, d, e, f, g, h ], 3 )
  returns = [ a, d, g, b, e, h, c, f ]

  */
  utils.sortByColumns = function(list, numColumns) {
    var newList = [],
      count = 0,
      adjustCount = 0,
      colNum = 0,
      rowNum = 0,
      numInLastRow = list.length % numColumns;

    while (newList.length < list.length) {
      while (count < list.length) {
        //Incrementally walk the list to pick out the right values
        newList.push(list[count]);
        count += Math.ceil(list.length / numColumns) - adjustCount;
        //Next column
        colNum++;

        // Last row will not be full; adjust `count` accordingly
        if (numInLastRow && colNum >= numInLastRow) {
          adjustCount = 1;
        }

        if (newList.length >= list.length) {
          break;
        }
      }

      colNum = 0;
      adjustCount = 0;
      //Next row
      count = ++rowNum;
    }

    return newList;
  };

  /**
   * printf will take the text, parse for %s, and replace each subsequent one
   * with the value that matches index in args (string array)
   *
   */

  utils.sprintf = function(text, args) {
    var s = "";
    if (args.length === 0) {
      return text;
    }

    var pieces = text.split("%s");
    for (var i = 0; i < pieces.length; i++) {
      s +=
        pieces[i] +
        (args[i] !== null && args[i] !== undefined ? args[i].toString() : "");
    }

    return s;
  };

  // Returns the URI part of a request URL
  //
  // eg. For URL: https://www.wattpad.com/foo/bar?baz=1 it returns /foo/bar?baz=1.
  //
  utils.extractUriFromUrl = function(url) {
    let schemeHostPortMatcher = /^((https?:)?\/\/)?[a-z-.]*[a-z-](:\d+)?(?=\/)/i;
    return url.replace(schemeHostPortMatcher, "");
  };

  utils.formatStoryUrl = function(storyUrl) {
    return utils.extractUriFromUrl(storyUrl);
  };

  utils.getCopyright = function(copyright) {
    var label = "";
    switch (copyright) {
      case 0:
        label = utils.trans("Not Specified");
        break;
      case 1:
        label = utils.trans("All Rights Reserved");
        break;
      case 2:
        label = utils.trans("Public Domain");
        break;
      case 3:
        label = utils.trans("Creative Commons (CC) Attribution");
        break;
      case 4:
        label = utils.trans("(CC) Attrib. NonCommercial");
        break;
      case 5:
        label = utils.trans("(CC) Attrib. NonComm. NoDerivs");
        break;
      case 6:
        label = utils.trans("(CC) Attrib. NonComm. ShareAlike");
        break;
      case 7:
        label = utils.trans("(CC) Attribution-ShareAlike");
        break;
      case 8:
        label = utils.trans("(CC) Attribution-NoDerivs");
        break;
      default:
        break;
    }
    return label;
  };

  utils.getCopyrightIcon = function(copyrightValue) {
    var label = null;

    switch (copyrightValue) {
      case 0:
        break;
      case 1:
        label = "fa-copyright";
        break;
      case 2:
        label = "fa-public-domain";
        break;
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        label = "fa-creative-commons";
        break;
      default:
        break;
    }

    return label;
  };

  /** Taken straight from url/url_utils.php
   * Adds the source query parameter to the URL for GA tracking
   * 	Moves hash fragments to the end of the URL so that it will go to the intended section of the web page
   *
   * @param url The URL to go to, before any tracking is added
   * @param source The source which the user is clicking the link from (web, iOS, Android)
   * @param medium (optional) The medium which the user is clicking the link from (email, facebook, twitter, etc.)
   * @param content(optional) The utm_content
   * @param page (optional) the wp_page
   * @return The final URL including tracking parameters
   */

  utils.addSource = function(url, source, medium, content, page) {
    var source_append =
      (url.indexOf("?") > 0 ? "&" : "?") + "utm_source=" + source;

    var return_url = url + source_append;

    if (medium) {
      return_url += "&utm_medium=" + medium;
    }

    if (content) {
      return_url += "&utm_content=" + content;
    }

    if (page) {
      return_url += "&wp_page=" + page;
    }

    var hash_fragments = url.indexOf("#");
    if (hash_fragments > 0) {
      return_url =
        url.substr(0, url.indexOf("#") + 1) +
        source_append +
        "#" +
        hash_fragments;
    }
    return return_url;
  };

  utils.setTitle = function(newTitleContent, appendWattpad) {
    if (typeof appendWattpad === "undefined") {
      appendWattpad = true;
    }
    var inboxCount = 0;
    var suffix = appendWattpad ? " - Wattpad" : "";
    var newTitle = newTitleContent ? newTitleContent + suffix : "Wattpad";

    if (wattpad.utils.currentUser().get("inbox")) {
      inboxCount = wattpad.utils.currentUser().get("inbox").unread;
    }

    if (app.get("device").is.desktop && inboxCount > 0) {
      newTitle = "(" + inboxCount + ") " + newTitle;
    }

    if (window.document.title !== newTitle) {
      window.document.title = wattpad.utils.unsanitizeHTML(newTitle);
    }
  };

  utils.getNextUrl = function(preferredUrl, defaultUrl) {
    var nextUrl,
      isValidNextUrl = function(testUrl) {
        return (
          typeof testUrl === "string" &&
          !/(\/|%2F)(getmobile|login|signup)\/?[^\/\s]*/i.test(testUrl)
        );
      };

    // clear the urls passed in
    preferredUrl = isValidNextUrl(preferredUrl) ? preferredUrl : "";
    defaultUrl = (isValidNextUrl(defaultUrl) ? defaultUrl : "") || "/home";

    // the many places of nexturl
    nextUrl =
      preferredUrl || utils.getParam("nextUrl") || utils.getParam("nexturl");

    return nextUrl && typeof nextUrl === "string" && isValidNextUrl(nextUrl)
      ? encodeURIComponent(nextUrl)
      : encodeURIComponent(defaultUrl);
  };

  utils.getParam = function(name) {
    name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
    var regex = new RegExp("[\\?&]" + name + "=([^&#]*)"),
      results = regex.exec(window.location.search);
    return results === null
      ? ""
      : decodeURIComponent(results[1].replace(/\+/g, " "));
  };

  utils.npgettext = function(context, singular, plural, count) {
    function derpNgettext(singular, plural, count) {
      if (count === 1) {
        return singular;
      } else {
        return plural;
      }
    }

    var trans = app.get("translatedLanguage");

    count = parseInt(count, 10);

    if (!trans || !trans.jed) {
      // XXX very bad: we'll just return english if the translation hasn't been loaded!
      return derpNgettext(singular, plural, count);
    } else {
      return trans.jed.npgettext(context, singular, plural, count);
    }
  };

  utils.ngettext = function(singular, plural, count) {
    return utils.npgettext(undefined, singular, plural, count);
  };

  utils.toPascalCase = function(str) {
    var camelCase = str
      .toLowerCase()
      .replace(/[\W_]+(\w)/g, function(match, firstChar) {
        return firstChar.toUpperCase();
      });
    return camelCase.charAt(0).toUpperCase() + camelCase.slice(1);
  };

  utils.experimentSelected = function(experiment) {
    if (experiment && experiment.current) {
      window.te.push("event", "experiment", "variation", null, "select", {
        experiment: experiment.options.experimentKey,
        variation: experiment.variationLoki
          ? experiment.variationLoki
          : "unknown"
      });
    }
  };

  utils.isHttp = function() {
    return window.location.protocol === "http:";
  };

  utils.pushEvent = function(newEvent, gaEventName) {
    if (!newEvent || typeof newEvent !== "object" || !window.dataLayer) {
      return;
    }
    newEvent.event = gaEventName || "ga-event";
    window.dataLayer.push(newEvent);
  };

  // TODO: remove iOS/Android check once iOS reach parity with Android in deeplink
  utils.getAppLink = function(route, model) {
    var device = app.get("device");

    var routeRequiresModel = !_.includes(["library", "homepage"], route);
    if (
      typeof route === "undefined" ||
      (routeRequiresModel && typeof model === "undefined") ||
      (!device.is.ios && !device.is.android && !device.is.windows)
    ) {
      return null;
    }

    switch (route) {
      case "homepage":
        return "wattpad://discover";

      case "story-landing":
        if (model instanceof app.models.StoryModel) {
          return "wattpad://story/" + model.get("id");
        }
        return null;

      case "story-reading":
        if (model instanceof app.models.StoryPartModel) {
          return device.is.ios
            ? "wattpad:///read?groupid=" +
                model.get("group").id +
                "&partid=" +
                model.get("id")
            : "wattpad://story/" +
                model.get("group").id +
                "/part/" +
                model.get("id");
        }
        return null;

      case "user-profile":
        if (model instanceof app.models.User) {
          return device.is.ios
            ? "wattpad:///profile?username=" + model.get("username")
            : "wattpad://user/" + model.get("username");
        }
        return null;

      case "library":
        if (device.is.android) {
          return "wattpad://library";
        }
        return null;
    }
    return device.is.ios
      ? "iOS"
      : device.is.android
        ? "android"
        : device.is.windows
          ? "windows"
          : null;
  };

  utils.appOn = function() {
    return !$("body").hasClass("js-app-off");
  };

  utils.categorySubtitle = function(category) {
    switch (category.id) {
      case 1: //Teen Fiction
        return utils.trans(
          "Ride the emotional rollercoaster of young adulthood with these free coming-of-age stories on love, friendship, high school drama, popularity and awkward teen moments."
        );
      case 2: //Poetry
        return utils.trans(
          "Immerse yourself with the best poems online about love, life, and the human experience."
        );
      case 3: //Fantasy
        return utils.trans(
          "Enter the realm of magic, dragons, princesses, elves, and faraway kingdoms, where a series of most unexpected events come to life."
        );
      case 4: //Romance
        return utils.trans(
          "Discover free love stories for any passion or persuasion. Book a date with your favorite author and read everything from sweet young romance to steamy new adult."
        );
      case 5: //Science Fiction
        return utils.trans(
          "Travel to the interstellar worlds of science fiction. Discover free books about space odysseys, time travel, dystopian futures, alien planets, and post-apocalyptic universes."
        );
      case 6: //Fanfiction
        return utils.trans(
          "From Supernatural to Harry Potter, One Direction to Percy Jackson, read the best fanfiction from whatever fandom you are in."
        );
      case 7: //Humor
        return utils.trans(
          "From tongue-in-cheek satire to literary comedy, enjoy these funny stories for adults and teens."
        );
      case 8: //Mystery / Thriller
        return utils.trans(
          "Welcome to the world of mystery, crime, and intrigue. Investigate psychological thrillers, blood boiling murders, and detective stories."
        );
      case 9: //Horror
        return utils.trans(
          "Feeling brave? Prepare for a scare with these creepy tales of eerie encounters, urban legends, and the unknown."
        );
      case 10: //Classics
        return utils.trans(
          "Immerse yourself in must read classic novels and literature that have stood the test of time."
        );
      case 11: //Adventure
        return utils.trans(
          "Go on an epic journey with these free adventure books. Travel to the realm of heroes, villains, pirates, and other fairy folk."
        );
      case 12: //Paranormal
        return utils.trans(
          "Beware: ghosts, zombies, demons, clairvoyants, and ghouls. Read these free paranormal stories at your own risk."
        );
      case 13: //Spiritual
        return utils.trans(
          "Embark on a spiritual journey with stories that nourish the soul. Find your answers through self-reflection, healing, and spirituality."
        );
      case 14: //Action
        return utils.trans(
          "Looking for danger, risk, and excitement? Find your hero in the best action books for teens and adults from your favorite authors."
        );
      case 16: //Non-fiction
        return utils.trans(
          "Read fascinating biographies, intimate memoirs, real stories of personal growth, and other non-fiction books."
        );
      case 17: //Short Stories
        return utils.trans(
          "On the go? Read these slice-of-life short stories that span across genres."
        );
      case 18: //Vampire
        return utils.trans(
          "Sink your teeth into these vampire books about century-old love, coven drama, and paranormal transformation."
        );
      case 19: //Random
        return utils.trans(
          "Read stories that defy definition. Whether you are looking for things to do when you are bored, top 10 lists, guides, rants, diary entries, or contests, find your fix here."
        );
      case 21: //General Fiction
        return utils.trans(
          "Get your fix of contemporary fiction. Experience a new perspective with the best free fiction from your next favorite author."
        );
      case 22: //Werewolf
        return utils.trans(
          "From alpha to omega, find your one true mate with these free werewolf books that will make you howl."
        );
      case 23: //Historical Fiction
        return utils.trans(
          "Fall back in time with fictional period pieces, war stories, and tales of vikings, kings and queens that capture the essence of history."
        );
      case 24: //Chick Lit
        return utils.trans(
          "Discover your next favorite chicklit author with these tales of dating, drama, and disasters. Book your next experience in classic trials of modern womanhood."
        );
    }
  };

  utils.isYouTubeUrl = function(url) {
    return (
      url.indexOf("http://youtu.be/") === 0 ||
      url.indexOf("https://youtu.be/") === 0 ||
      url.indexOf("http://www.youtube.com/watch?v=") === 0 ||
      url.indexOf("https://www.youtube.com/watch?v=") === 0
    );
  };

  utils.extractYouTubeId = function(url) {
    var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
    var match = url.match(regExp);
    if (match && match[1].length == 11) {
      // YouTube video IDs are 11 characters long
      return match[1];
    } else {
      return "";
    }
  };

  utils.connectAsset = function(assetUrl) {
    if (wattpad.assetServer) {
      assetUrl = wattpad.assetServer + assetUrl;
    }
    return assetUrl;
  };

  utils.resizeImages = function(imageParagraphs, pageWidth) {
    var device = app.get("device"),
      pageWidth = pageWidth || (device.isDesktop() && 650) || 210;

    imageParagraphs.each(function(i, paragraph) {
      var $image = $(paragraph).children("img");
      // image width is very close to the column width
      if ($image.data("original-width") > pageWidth) {
        var aspectRatio =
          ($image.data("original-height") / $image.data("original-width")) *
          100;
        $(paragraph)
          .addClass("fixed-ratio")
          .css({ "padding-bottom": aspectRatio + "%" });
      } else {
        $image.attr("width", $image.data("original-width"));
        $image.attr("height", $image.data("original-height"));
        $(paragraph)
          .removeClass("fixed-ratio")
          .css({ "padding-bottom": "" });
      }
    });
  };

  // Returns true when at least a specified percentage (default 100%)
  // of the element becomes in view
  utils.isOnScreen = function($element, percentageVisible) {
    percentageVisible = percentageVisible || 1;

    if (!$element.length) {
      return false;
    }

    // Check if they are visible, i.e. if they consume space in the document (width, height > 0)
    if (!$element.is(":visible")) {
      return false;
    }

    var screenTop = $(window).scrollTop();
    var screenBottom = screenTop + $(window).height();

    var elementTop = $element.offset().top;
    var elementBottom = elementTop + $element.height();
    var elementVisibleHeight = $element.height() * percentageVisible;

    return (
      (elementBottom <= screenBottom && elementTop >= screenTop) ||
      (elementTop <= screenTop &&
        elementBottom > screenTop &&
        elementBottom - screenTop >= elementVisibleHeight) ||
      (elementBottom >= screenBottom &&
        elementTop < screenBottom &&
        screenBottom - elementTop >= elementVisibleHeight)
    );
  };

  utils.generateBranchLink = function(link, deepLink, options) {
    var params = {
      source: options.source,
      fields: "url",
      uuid: utils.getCookie("wp_id"),
      url: link,
      shorten: 1,
      post: 0,
      shouldFallback: false
    };

    // If desktopRedirect is set to true and passed in the api call,
    // the branch link will have $desktop_url set to the link passed in.
    if (options.desktopRedirect) {
      params.desktopRedirect = true;
    }

    if (deepLink) {
      params.deeplink = deepLink;
      params.medium = options.medium;
      params.content = options.content;
      params.page = options.page;
      if (options.campaign) {
        params.campaign = options.campaign;
      }
      //If the link came from a friend then there will be an origin param that we collected for utm stuff
      // layout.handlebars is where this happens
      if (window._utms["originator"]) {
        params.ref_ori = window._utms["originator"];
      }
    }

    return $.get("/v4/link", params);
  };

  utils.qsReplace = function(queryString, parameter, replacement) {
    if (
      typeof queryString !== "string" ||
      typeof parameter !== "string" ||
      queryString.indexOf(parameter + "=") < 0
    ) {
      return queryString;
    }
    var rx = new RegExp(parameter + "=[^&]*");
    return queryString.replace(
      rx,
      parameter +
        "=" +
        (replacement === undefined || replacement === null ? "" : replacement)
    );
  };

  utils.eligibleForDirectSoldAds = function() {
    return _.includes(
      JSON.parse(wattpad.directAdCountries),
      wattpad.userCountryCode
    );
  };

  utils.isMatureStory = function(storyGroup) {
    if (!storyGroup) {
      return true;
    }
    return storyGroup.rating >= 4;
  };

  utils.getGenderTargeting = function(user) {
    var gender;

    if (!user || !user.gender) {
      return "Ke28";
    }

    switch (user.gender) {
      case "Male":
      case "He":
        gender = "IU10";
        break;
      case "Female":
      case "She":
        gender = "Bu44";
        break;
      default:
        gender = "Ke28";
        break;
    }

    return gender;
  };

  utils.cacheBustPaidMetadata = function(storyId, partIds) {
    return window.store.dispatch(
      window.app.components.actions.cacheBustPaidContentMetadata(
        storyId,
        partIds
      )
    );
  };

  // Dev-only endpoint
  utils.revertPaidContentAccess = function(storyId, partId) {
    var username = utils.getCurrentUserAttr("username");

    if (!username) {
      throw new Error("User must be logged in to revert access");
    }

    var partParam = "";
    if (partId) {
      // partId argument is optional
      partParam = "/part/" + partId;
    }

    return Promise.resolve(
      $.ajax({
        type: "DELETE",
        url:
          "/v5/users/" + username + "/story/" + storyId + partParam + "/access"
      })
    ).then(function() {
      utils.cacheBustPaidMetadata(storyId, [partId]);
    });
  };

  utils.buyPaidContent = function(currency, storyId, partId) {
    var username = utils.getCurrentUserAttr("username");

    if (!username) {
      throw new Error("User must be logged in to buy a part");
    }

    var partParam = "";
    if (partId) {
      // partId argument is optional
      partParam = "/part/" + partId;
    }

    return Promise.resolve(
      $.post(
        "/v5/users/" +
          username +
          "/story/" +
          storyId +
          partParam +
          "/access?currency=" +
          currency
      )
    )
      .then(function(response) {
        // Errors are returned as 200 for Android compatibility, so normalize first
        var isError = !!response.code;
        if (isError) {
          throw response;
        } else {
          return response;
        }
      })
      .catch(function convertErrorToDisplayableMessage(err) {
        var message;
        try {
          var code = err.code;
          if (code === 3005) {
            message = wattpad.utils.trans("Sorry, there weren't enough funds in your wallet to make this purchase."); // prettier-ignore
          } else {
            throw "Unknown error code";
          }
        } catch (parseError) {
          message = wattpad.utils.trans(
            "Something went wrong, please try again. Don't worry, you won't be charged twice!"
          );
        }

        throw message;
      });
  };

  utils.fetchPaidContentStoryMetadata = function(storyId, partIds) {
    var commaSeparatedParts = partIds.join(",");
    return Promise.resolve(
      $.ajax({
        type: "GET",
        url: "/v5/story/" + storyId + "/paid-content/metadata",
        data: { parts: commaSeparatedParts }
      })
    ).catch(function(err) {
      console.error("Couldn't fetch story metadata", err);
      return {};
    });
  };

  utils.isPaidStory = isPaidStory;

  utils.numPartsBetweenCurrentAndPaywall = function(parts, currentPart) {
    // The number of parts between the current part and the next
    // paywalled part, exclusive. For invalid cases (current part is
    // paywalled, there is no future paywalled part, etc), return a
    // negative int.
    if (currentPart.isBlocked) {
      return -1;
    }

    var currentIndex = _.findIndex(parts, { id: currentPart.id });
    var partsAfterCurrent = parts.slice(currentIndex + 1);

    var firstPaywallIndexAfterCurrentPart = _.findIndex(
      partsAfterCurrent,
      function(part) {
        return part.isBlocked;
      }
    );

    return firstPaywallIndexAfterCurrentPart;
  };

  utils.getIndexForPartId = function(partId, story) {
    const parts = story.parts;

    return _.findIndex(parts, part => part.id === partId);
  };

  utils.getAgeTargeting = function(user) {
    var age;

    if (!user || !user.age) {
      age = "UNKn";
    } else if (user.age < 15) {
      age = "13HW";
    } else if (user.age >= 15 && user.age <= 17) {
      age = "59ss";
    } else if (user.age >= 18 && user.age <= 24) {
      age = "09Pv";
    } else if (user.age >= 25 && user.age <= 34) {
      age = "68OK";
    } else if (user.age >= 35 && user.age <= 40) {
      age = "71aa";
    } else if (user.age >= 41 && user.age <= 45) {
      age = "76PT";
    } else if (user.age >= 46 && user.age <= 49) {
      age = "74xS";
    } else if (user.age >= 50 && user.age <= 54) {
      age = "68zo";
    } else if (user.age >= 55 && user.age <= 59) {
      age = "33es";
    } else if (user.age >= 60 && user.age <= 64) {
      age = "84Vv";
    } else if (user.age >= 65) {
      age = "29kM";
    } else {
      age = "UNKn";
    }

    return age;
  };

  utils.showToast = function(message, options) {
    var toast = new app.views.ErrorToast(
      {
        message: message
      },
      options
    );
    toast.render();
  };

  // ADS-324: Pass prefilled tags to myworks/new
  utils.validatePrefilledTagText = function(tag) {
    var cleanedTag = wattpad.utils.sanitizeHTMLExceptQuotes(tag.split(" ")[0]);
    cleanedTag = cleanedTag
      .replace(/<(br|p|div)(\s*)>/gi, "\n")
      .replace(/(<([^>]+)>)/gi, "")
      .substring(0, 128);

    if (cleanedTag.length < 2) {
      return null;
    }
    return cleanedTag;
  };
  utils.validateImageURLs = function(imageURL) {
    var cleanedImageUrls = wattpad.utils.sanitizeHTMLExceptQuotes(
      imageURL.split(" ")[0]
    );

    var regexp = new RegExp(
      /https?:\/\/(www\.)?(em|a|d|img).wattpad.(dev-prod|com)\/+\w+/
    );
    var match = cleanedImageUrls.match(regexp);

    return !!match;
  };

  // header bid again to get a new ad to show in adUnitId
  // in: the html id for the ad we want to refresh
  // out: the new html id on that div.
  utils.refreshAd = function(adId) {
    var element = document.getElementById(adId);

    if (!element) {
      return;
    }

    var htSlotName = adId.split("-")[0];
    var adPlacement = htSlotName + "-" + Date.now();
    return adPlacement;
  };

  utils.generateUUID = generateUUID;

  utils.showPleaseVerifyModal = function() {
    var view = new app.views.PleaseVerifyModal();
    $("#generic-modal .modal-body").html(view.render().$el);
    $("#generic-modal .modal-content").addClass("please-verify-modal-wrapper");
    $("#generic-modal").modal({});
    return;
  };

  utils.showSentEmailModal = function(modalOptions) {
    var view = new app.views.VerifyEmailModal(modalOptions);
    $("#generic-modal .modal-body").html(view.render().$el);
    $("#generic-modal .modal-content").addClass("sent-email-modal-wrapper");
    $("#generic-modal").modal({});
    return;
  };

  utils.showChangeEmailModal = function(modalOptions) {
    var view = new app.views.VerifyEmailModal(modalOptions);
    $("#generic-modal .modal-body").html(view.render().$el);
    $("#generic-modal .modal-content").addClass("change-email-modal-wrapper");
    $("#generic-modal").modal({});
    return;
  };

  utils.registerModal = (id, type, options = {}) => {
    if (id == "add") {
      console.error("Cannot use protected modal id 'add'");
      return;
    }
    const { isOpen, show, hide, setData, data } = options;
    const errorCallback = () => {
      console.error("Unsupported function");
    };
    window.wattpad.modals[id] = {
      id,
      type,
      isOpen: isOpen || null,
      show: show || errorCallback,
      hide: hide || errorCallback,
      setData: setData || errorCallback,
      data: data || {}
    };
  };

  utils.getModal = function(id) {
    const modal = wattpad.modals[id];
    if (!modal) {
      console.error(`Failed to find modal '${id}'`);
      return null;
    }
    return modal;
  };

  utils.openModal = function(id) {
    const modal = utils.getModal(id);
    modal && modal.show();
    return;
  };

  utils.closeModal = function(id) {
    if (id) {
      const modal = utils.getModal(id);
      modal && modal.hide();
      return;
    } else {
      if (wattpad.modals) {
        _.each(wattpad.modals, (val, key) => {
          const modal = wattpad.modals[key];
          if (modal.id != id && modal.isOpen()) wattpad.modals[key].hide();
        });
      }
    }
  };

  /**
   * Return a roles structure consistent with server side code so that we are using the same names in our role checks.
   * @param {Object} currentUser - object passed representing the User structure.
   * @param {Boolean} currentUser.isSysAdmin - currently indicates that the user is staff on the company VPN connection or office IP
   * @param {Boolean} currentUser.ambassador - indicates that the user is a wattpad ambassador
   *
   * @returns {Object} contains all related role flags for the current user
   */
  utils.getCurrentUserRoles = function({
    isSysAdmin = false,
    ambassador = false
  } = {}) {
    return {
      isSysAdmin,
      isAmbassador: ambassador
    };
  };

  utils.clearCommentLocalStorage = function() {
    const partCommentsregex = /mobile-web#part.\w*.comments/;
    const commentReplyregex = /mobile-web#comments.\w*.replies/;

    let keys = Object.keys(window.localStorage);
    keys.forEach(key => {
      if (partCommentsregex.test(key) || commentReplyregex.test(key)) {
        window.localStorage.removeItem(key);
      }
    });
  };

  /* The following function is used to clear the cached story data once a user block/unblocks another user. 
  This will improve the user exprience and ensures users see the expected page on CSR.
  If user A has blocked user B, they should see story 404 when navigating to user B's stories
  If user A has unblocked user B, they should see user B's story 
  */

  utils.clearStoriesLocalStorage = function() {
    const storyReadingRegex = /mobile-web#story.read.\w*.metadata/;
    const storyPartRegex = /mobile-web#part.\w*.metadata/;
    const storiesRegex = /mobile-web#stories.\w*/;
    const readingListRegex = /mobile-web#reading-list.\w*.stories/;

    let keys = Object.keys(window.localStorage);
    keys.forEach(key => {
      if (
        storyReadingRegex.test(key) ||
        storyPartRegex.test(key) ||
        storiesRegex.test(key) ||
        readingListRegex.test(key)
      ) {
        // Remove from memory
        let baseKey = key.split("#")[1];
        app.local._clearItem(baseKey);

        // Remove from local storage
        window.localStorage.removeItem(key);
      }
    });
  };

  utils.getSearchTitle = getSearchTitle;
  utils.tagsHelper = tagsHelper;
  utils.isUnsafe = isUnsafe;
  utils.forcedSignupHelper = {
    isForcedSignupSession,
    isForcedSignupExemptView
  };
})(window, window._, window.wattpad);
