(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * Purpose: canonical way of counting text in textarea/input
   * Requirements:
   *    - input or textarea element that has class `count-input` and these data attributes:
   *        - data-name: used for the callback function
   *        - data-limit: the character limit associated with this input
   *        - data-counter: the associated counter span that displays the char counts
   *        - ex:
   *            <textarea data-name="SomeField" data-limit="9000" data-counter="some-field-counter"></textarea>
   *    - counter span element with the class that is same as data-counter, has a nested span element
   *        - ex:
   *            <div class="some-field-counter"><span></span>/9000</div>
   * Options:
   *    - function on + data-name + update as a callback function everytime length is evaluated
   *        - single boolean argument pass to it, indicates if the text under limit
   *            - true: the text in the field is under the limit
   *            - false: the text in the field is over the limit
   *        - ex. callback function = onSomeFieldUpdate( isUnderLimit )
   *
   * @mixin InputCounterManagement
   */
  app.add(
    "InputCounterManagement",
    Monaco.Mixin.create({
      events: {
        "keyup .count-input": "onCount"
      },

      onCount: function(evt, noop) {
        var input, limit, count, callback;
        input = $(evt.currentTarget);
        limit = parseInt(input.data("limit"), 10);
        count = this.setCount(input);
        callback = input.data("name");

        // make call to the correct callback
        if (typeof this["on" + callback + "Update"] === "function") {
          this["on" + callback + "Update"](count && count <= limit);
        }

        noop();
      },

      setCount: function(input, noop) {
        if (!input || input.val() === undefined) {
          return;
        }

        var counter, count;

        counter = this.$("." + input.data("counter"));
        count = input.val().length;

        counter.children("span").html(count);
        if (count > 0) {
          counter.removeClass("hidden");
        } else {
          counter.addClass("hidden");
        }

        noop();
        return count;
      },

      resetCount: function(noop) {
        this.setCount(this.$(".count-input"));
        noop();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
