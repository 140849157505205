(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "CoreFeed",
    app.views.IncrementalList.extend({
      itemView: app.views.UserEvent,

      getItemView: function(model) {
        switch (model.get("type")) {
          case "message":
          case "newMessage":
            return app.views.NewFeedItemMessage;
          case "comment":
          case "inline_comment":
          case "library":
          case "upload":
          case "vote":
          case "placeholder":
          case "dedicate":
          case "follow":
          /* falls through */
          default:
            return app.views.FeedItem;
        }
      },

      renderItem: function(model, $container) {
        this.itemView = this.getItemView(model);
        app.views.IncrementalList.prototype.renderItem.apply(this, arguments);
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
