import incrementalList from "platform/components/templates/incremental-list.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * UserList CollectionView - List of UserItem views
   * @Class UserList
   * @constructor
   */
  app.add(
    "ProfileFollowing",
    app.views.IncrementalList.extend({
      className: "user-following row",
      template: incrementalList,
      itemView: app.views.UserItem,

      getTemplateData: function() {
        var data = {};
        data.count = this.collection.total;
        data.containerId = "content";
        data.emptyMessage = utils.trans(
          "This user is not following anyone yet."
        );
        return data;
      },

      setElement: function(el) {
        app.views.IncrementalList.prototype.setElement.apply(this, arguments);
        this.setButtonState();
      }
    })
  );

  app.mixin(app.views.ProfileFollowing, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
