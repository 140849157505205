import getStoryBrandSafety from "../../../helpers/get-story-brand-safety";
import getAfpClientId from "../../../helpers/get-afp-clientid";

const DEFAULT_FIELDS = [
  "id",
  "title",
  "createDate",
  "modifyDate",
  "voteCount",
  "readCount",
  "commentCount",
  "description",
  "url",
  "firstPublishedPart",
  "cover",
  "language",
  "isAdExempt",
  {
    user: [
      "name",
      "username",
      "avatar",
      "location",
      "highlight_colour",
      "backgroundUrl",
      "numLists",
      "numStoriesPublished",
      "numFollowing",
      "numFollowers",
      "twitter"
    ]
  },
  "completed",
  "isPaywalled",
  "paidModel",
  "numParts",
  "lastPublishedPart",
  {
    parts: [
      "id",
      "title",
      "length",
      "url",
      "deleted",
      "draft",
      "createDate",
      "scheduledPublishDatetime"
    ]
  },
  "tags",
  "categories",
  "rating",
  "rankings",
  "tagRankings",
  "language",
  "storyLanguage",
  "copyright",
  "sourceLink",
  "firstPartId",
  "deleted",
  "draft",
  "hasBannedCover",
  "length"
];

// CJ-107: Qualified Unique Readers
const QUR_FIELDS = [...DEFAULT_FIELDS, "qualifiedUniqueReaders"];

(function(window, _, wattpad, app, utils) {
  "use strict";

  //This is the new story model
  app.add(
    "StoryModel",
    app.models.BaseModel.extend({
      defaults: {
        cover: "",
        title: "",
        description: ""
      },

      fields: DEFAULT_FIELDS,

      cacheLocal: true,

      urlRoot: "/api/v3/stories/",

      resource: function() {
        return "story.read." + parseInt(this.get("id"), 10) + ".metadata";
      },

      fetch: function(options) {
        options = options || {};

        if (options.isAnalyticsPage) {
          this.fields = QUR_FIELDS;
        }

        var storyFetch = app.models.BaseModel.prototype.fetch.call(
          this,
          options
        );
        var cached = storyFetch === true;
        var cacheBusting = !storyFetch;
        if (cached || cacheBusting) {
          return Promise.resolve(storyFetch);
        }

        var self = this;

        // ADS-444: Fix Story Landing Metadata Generation
        _.bindAll(
          this,
          "fetchPromotionStatus",
          "buildAdRequestData",
          "parsePromotionStatus",
          "parseStorySafetyLevel"
        );

        var safeStoryFetch = Promise.resolve(
          $.get("/v5/stories/" + this.get("id") + "/classification/safety")
        )
          .then(self.parseStorySafetyLevel)
          .catch(function() {});

        var promotionStatusFetch = Promise.resolve([]);

        if (
          wattpad.utils.eligibleForDirectSoldAds() &&
          !wattpad.utils.currentUser().get("isPremium")
        ) {
          promotionStatusFetch = Promise.resolve(self.fetchPromotionStatus())
            .then(self.parsePromotionStatus)
            ["catch"](function() {
              // Something went horribly wrong on the way to the Adzerk promise land.
            });
        }

        var storyBrandSafetyPromise = result => {
          return Promise.resolve(getStoryBrandSafety(result));
        };

        var afpClientIdPromise = () => Promise.resolve(undefined);
        if (_.get(window, "wattpad.testGroups.USE_AFP", false)) {
          afpClientIdPromise = username => {
            return Promise.resolve(getAfpClientId(username));
          };
        }

        return storyFetch.then(function(result) {
          // The result was cached or localOnly fetched and no further processing is necessary
          if (result === true || !result) {
            return result;
          }
          const authorUsername = result?.user?.username;
          return Promise.all([
            promotionStatusFetch,
            safeStoryFetch,
            storyBrandSafetyPromise(result),
            afpClientIdPromise(authorUsername)
          ]).then(function([
            promotionStatus,
            safeStory,
            storyBrandSafety,
            afpClientId
          ]) {
            if (storyBrandSafety) {
              self.set("brandSafetySource", storyBrandSafety.brandSafetySource);
              self.set("brandSafetyLevel", storyBrandSafety.brandSafetyLevel);
              if (_.get(window, "wattpad.testGroups.USE_IMAGE_MOD", false)) {
                self.set(
                  "imagesUnderModeration",
                  storyBrandSafety.imagesUnderModeration
                );
              }
            }
            var user = self.get("user");
            if (afpClientId && user) {
              user.afpClientId = afpClientId;
              self.set("user", user);
            }
            return promotionStatus;
          });
        });
      },

      fetchPromotionStatus: function() {
        var postData = this.buildAdRequestData();

        var result = Promise.resolve(
          $.ajax({
            type: "POST",
            url: "https://adz.wattpad.com/api/v2",
            dataType: "json",
            contentType: "text/plain",
            data: JSON.stringify(postData),
            xhr: function() {
              // Get new xhr object using default factory
              var xhr = jQuery.ajaxSettings.xhr();
              // Copy the browser's native setRequestHeader method
              var setRequestHeader = xhr.setRequestHeader;
              // Replace with a wrapper
              xhr.setRequestHeader = function(name, value) {
                // Ignore the Authorization  header
                if (name == "Authorization") return;
                // Otherwise call the native setRequestHeader method
                // Note: setRequestHeader requires its 'this' to be the xhr object,
                // which is what 'this' is here when executed.
                setRequestHeader.call(this, name, value);
              };
              // pass it on to jQuery
              return xhr;
            }
          })
        );

        return result;
      },

      buildAdRequestData: function() {
        var currentLanguageName = app
          .get("supported-languages")
          .find({ id: parseInt(app.get("language"), 10) })
          .get("nameEnglish");
        var currentUserKey =
          wattpad.utils.currentUser().get("externalId") || "";

        return {
          placements: [
            {
              divName: "azk70876",
              networkId: 9660,
              siteId: 421790,
              adTypes: [318],
              zoneIds: this.get("zoneIds"),
              properties: {
                userLanguage: currentLanguageName,
                storyId: String(this.get("id"))
              },
              user: {
                key: currentUserKey
              },
              time: "123455",
              keywords: [this.get("id")]
            }
          ]
        };
      },

      parsePromotionStatus: function(adDecision) {
        if (_.get(adDecision, "decisions.azk70876")) {
          this.set(
            "sponsorName",
            _.get(
              adDecision,
              "decisions.azk70876.contents[0].data.customData.sponsorName"
            )
          );
          this.set("promoted", true);
          this.set(
            "clickUrl",
            _.get(adDecision, "decisions.azk70876.clickUrl")
          );
          this.set(
            "impressionUrl",
            _.get(adDecision, "decisions.azk70876.impressionUrl")
          );
          this.set(
            "thirdParty",
            _.get(
              adDecision,
              "decisions.azk70876.contents[0].data.customData.thirdParty"
            )
          );
        }

        return this;
      },

      parseStorySafetyLevel: function(safetyLevel) {
        var level = _.get(safetyLevel, "safe_for");
        if (level === 1) {
          this.set("isBrandSafe", true);
        } else if (level === 0) {
          // Known unsafe story. Disable indexing.
          this.set("noIndex", true);
        }
        return this;
      },

      parse: function(data) {
        if (data.categories && data.categories.length > 1) {
          data.categories = [data.categories[0] || data.categories[1]];

          //We lazy load categories, we have to ensure that we don't crash here
          if (app.get("categories")) {
            var category = app.get("categories").get(data.categories[0]);
            if (category) {
              //TODO: Rename this
              data.categoryObject = category.toJSON();
            }
          }
        }

        data.storyLanguage = data.language?.name;

        if (data.language) {
          data.language = data.language
            ? data.language.id || data.language
            : this.get("language") || 1;
        }

        //Get the highest rank for the story which will be sent to ad services
        var rankingsArray = data.rankings ? data.rankings : [],
          rank = null;
        if (rankingsArray.length > 0) {
          _.each(rankingsArray, function(item) {
            if (item.rank > rank) {
              rank = item.rank;
            }
          });
        }
        data.rank = rank;

        if (data.promoted) {
          if (data.sponsor) {
            data.sponsorName = data.sponsor.name
              ? data.sponsor.name
              : data.user.name;
          }
          if (data.contest) {
            data.contest.ctaLabel =
              data.contest.ctaLabel || wattpad.utils.trans("SUBMIT NOW");
          }
        }
        return data;
      },

      syncPosition: function(position) {
        var self = this;

        if (position) {
          $.post("/apiv2/syncreadingposition", {
            story_id: this.get("firstPartId"),
            position: position
          });
        } else {
          $.get("/apiv2/syncreadingposition", {
            story_id: this.get("firstPartId")
          }).done(function(data) {
            self.trigger("reading-position-available", data);
          });
        }
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
