import emailVerifiedSuccessAnnouncement from "core/templates/components/email-verified-success-announcement.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "EmailVerifiedSuccess",
    app.views.Announcement.extend({
      template: emailVerifiedSuccessAnnouncement,

      state: "default",

      className:
        app.views.Announcement.prototype.className + " email-verified-success",

      events: _.extend(
        {
          "tap    .on-close": "onClose",
          "click  .on-close": "stopEvent"
        },
        app.views.Announcement.prototype.events
      ),

      onClose: function(evt) {
        this.remove();
      }
    })
  );
})(window, _, wattpad, window.app);
