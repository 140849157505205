var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.dismissibleBanner : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.fatBanner : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <div class=\"bottom-banner nondismissible\">\n        <div class=\"row\">\n          <div class=\"col-xs-12 col-sm-4\">\n            <span class=\"wattpad-logo\"></span>\n            <span class=\"logo-text\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad App - Read, Write, Connect",{"name":"trans","hash":{},"data":data}))
    + "</span>\n          </div>\n        </div>\n        <div class=\"row\">\n          <a class=\"open-app\" href=\""
    + alias3(((helper = (helper = helpers.branchLink || (depth0 != null ? depth0.branchLink : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"branchLink","hash":{},"data":data}) : helper)))
    + "\" data-gtm=\"Download|bottom_banner|open_app\">\n            <span class=\"open-app-text\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Start Reading",{"name":"trans","hash":{},"data":data}))
    + "</span>\n          </a>\n        </div>\n        <div class=\"row login\">\n          <a class=\"on-navigate bottom-login login-text\" data-form=\"login\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log in with Browser",{"name":"trans","hash":{},"data":data}))
    + "</a>\n        </div>\n      </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <div class=\"bottom-banner dismissible\">\n        <div class=\"bottom-content\"><img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.bottomBannerImage : depth0),27,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,(depth0 != null ? depth0.bottomBannerTitle : depth0),{"name":"sanitize","hash":{},"data":data}))
    + "\"><span class=\"bottom-message\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Where stories live. Discover now",{"name":"trans","hash":{},"data":data}))
    + "</span></div>\n        <div class=\"bottom-controls\">\n          <button class=\"btn bottom-login\" data-form=\"login\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log in",{"name":"trans","hash":{},"data":data}))
    + "</button><button class=\"btn bottom-signup\" data-form=\"signup\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sign Up",{"name":"trans","hash":{},"data":data}))
    + "</button>\n        </div>\n        <div class=\"dismiss\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n      </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showBottomBanner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});