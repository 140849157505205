var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"container\">\n        <header class=\"background background-lg text-left\" style=\"background-image: url("
    + alias3(((helper = (helper = helpers.assetServer || (depth0 != null ? depth0.assetServer : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"assetServer","hash":{},"data":data}) : helper)))
    + "/img/category-banners/browse-category-header.png);\">\n             <div id=\"authentication-panel\">\n                <div class=\"signup-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.signup_prompt"],depth0,{"name":"core.signup_prompt","data":data,"indent":"                  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </div>\n            <div id=\"sign-up-header\">\n                <h1>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Discover Stories",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n                <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Read books and stories free on Wattpad. Choose from millions of titles about romance, fanfiction, fantasy, science fiction, non fiction, teen fiction, and more.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n            </div>\n            <div class=\"overlay\"></div>\n        </header>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.panels : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lists : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.placeholder : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.tags : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["desktop.discover.discover_stories"],depth0,{"name":"desktop.discover.discover_stories","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["desktop.discover.discover_lists"],depth0,{"name":"desktop.discover.discover_lists","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"discover-"
    + alias4(((helper = (helper = helpers.placeholderType || (depth0 != null ? depth0.placeholderType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholderType","hash":{},"data":data}) : helper)))
    + "-desktop\" data-placeholder=\"true\" data-type=\"discover-"
    + alias4(((helper = (helper = helpers.placeholderType || (depth0 != null ? depth0.placeholderType : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"placeholderType","hash":{},"data":data}) : helper)))
    + "\" data-url=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\" data-cache=\""
    + alias4(((helper = (helper = helpers.cache || (depth0 != null ? depth0.cache : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cache","hash":{},"data":data}) : helper)))
    + "\" data-layout=\""
    + alias4(((helper = (helper = helpers.layout || (depth0 != null ? depth0.layout : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"layout","hash":{},"data":data}) : helper)))
    + "\">\n                <h2 class=\"story-grid-title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h2>\n                <h3><small>"
    + alias4(((helper = (helper = helpers.subtitle || (depth0 != null ? depth0.subtitle : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subtitle","hash":{},"data":data}) : helper)))
    + "</small></h3>\n                <div class=\"loading-placeholder\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading...",{"name":"trans","hash":{},"data":data}))
    + "</div>\n            </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.discover.discover_tags"],depth0,{"name":"core.discover.discover_tags","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div id=\"recommended-users\" class=\"clearfix\">\n        <div class=\"recommended-users-view\">"
    + ((stack1 = container.invokePartial(partials["core.collection_views.recommended_follow"],depth0,{"name":"core.collection_views.recommended_follow","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.currentUser : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class=\"container\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.panelGroups : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.currentUser : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(partials["core.discover.discover_categories"],depth0,{"name":"core.discover.discover_categories","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n\n<div id=\"preview-modal\"></div>\n";
},"usePartial":true,"useData":true});