(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Base",
    Monaco.Model.extend({
      _loaded: null,

      _dependencies: null,

      initialize: function() {
        this._loaded = $.Deferred();

        this.on("sync", this._setResolved, this);
        this.on("error", this._setRejected, this);

        if (this.init) {
          this.init.apply(this, arguments);
        }

        this._setResource();
        this._bindEvents();
      },

      _setResource: function() {
        var self = this;

        if (this.resource && this.get(this.idAttribute)) {
          if (!this.collection) {
            this.collection = new Monaco.Collection();
            this.collection.cacheLocal = true;
          }

          this.collection.resource =
            this.collection.resource ||
            function() {
              return typeof self.resource === "function"
                ? self.resource()
                : self.resource;
            };
        }
      },

      _bindEvents: function() {
        var self = this;

        if (this.events && typeof this.events === "object") {
          _.forEach(this.events, function(value, key) {
            self.on(key, self[value]);
          });
        }
      },

      _setResolved: function() {
        this._loaded.resolve(this);
      },

      _setRejected: function() {
        this._loaded.reject(this);
      },

      _loadCheck: function() {
        return true;
      },

      dependsOn: function(master, id) {
        var self = this;

        this._dependencies = this._dependencies || [];
        this._dependencies.push(master);

        if (id) {
          if (master.get(id)) {
            this._resolveDepenencyProperty(master, id);
          } else {
            this.listenTo(master, "change:" + id, function() {
              self._resolveDepenencyProperty(master, id);
            });
          }
        }
      },

      _resolveDepenencyProperty: function(master, id) {
        this.set(this.idAttribute, master.get(id));
      },

      generateFields: function(options) {
        options = options || {};

        if (this.fields && (!options.data || !options.data.fields)) {
          options.data = options.data || {};
          options.data.fields = this._recursiveFields(this.fields);
        }

        return options;
      },

      _recursiveFields: function(fields) {
        var self = this,
          result = "";

        if (typeof fields === "string") {
          return fields;
        } else if (Array.isArray(fields)) {
          result += _.map(fields, function(field) {
            return self._recursiveFields(field);
          }).join(",");
        } else {
          var temp = [];
          _.forIn(fields, function(val, key) {
            temp.push(key + "(" + self._recursiveFields(val) + ")");
          });

          result += temp.join(",");
        }

        return result;
      },

      loaded: function(options) {
        var self = this;
        options = this.generateFields(options);

        if (!this._loadCheck()) {
          this._loaded.reject();
        } else if (this._loaded.state() === "pending") {
          if (!this._dependencies) {
            self._setResource();
            this.fetch(options);
          } else {
            $.when
              .apply(
                $,
                _.map(this._dependencies, function(object) {
                  return object.loaded();
                })
              )
              .done(function() {
                self._setResource();
                self.fetch(options);
              });
          }
        }

        return this._loaded;
      }
    })
  );
})(window, _, wattpad, window.app);
