var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<header class=\"panel-heading\">\n  "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/shared/ic_terms.png","paper icon",{"name":"connectImage","hash":{"width":"55","height":"73","klass":"paper-icon"},"data":data}))
    + "\n  <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"trans","hash":{},"data":data}))
    + "</h4>\n</header>\n\n<div class=\"panel-body\">\n  <p>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,(depth0 != null ? depth0.body : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n  <div class=\"buttons-wrapper\">\n      <a href=\"https://support.wattpad.com/hc/en-us/articles/360000812323-Updates-to-our-Terms-of-Service-and-Privacy-Policy\" class=\"policies-link\">  \n        <button class=\"btn btn-default learn-more\"> \n          "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn more",{"name":"trans","hash":{},"data":data}))
    + "\n        </button>\n      </a>\n    <button class=\"btn btn-orange on-continue\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Got it",{"name":"trans","hash":{},"data":data}))
    + "</button>\n  </div>\n</div>\n";
},"useData":true});