(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "VerifyEmailModal",
    app.views.DummyReactView.extend({
      component: "VerifyEmail",
      componentId: "verify-email",

      constructor: function(opts) {
        this.componentData = {
          isChangeEmailModal: opts.isChangeEmailModal,
          email: opts.email
            ? opts.email
            : wattpad.utils.currentUser().attributes.email,
          userId: wattpad.utils.currentUser().get("id"),
          isSuccess: opts.isSuccess,
          hideModal: this.onClose,
          onUpdateEmail: opts.onUpdateEmail
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      onClose: function(evt) {
        $("#generic-modal").modal("hide");
        // remove custom class with a delay so that there's no pop-in during
        // jQuery modal close animation
        setTimeout(function() {
          $("#generic-modal .modal-content").removeClass(
            "sent-email-modal-wrapper"
          );
          $("#generic-modal .modal-content").removeClass(
            "change-email-modal-wrapper"
          );
        }, 500);
      }
    })
  );

  app.mixin(app.views.VerifyEmailModal);
})(window, _, wattpad, window.app, wattpad.utils);
