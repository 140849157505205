(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Works",
    app.collections.IncrementalFetchNextUrl.extend({
      initialize: function(collection, options) {
        options = options || {};

        if (!options.username) {
          throw new Error("Works Collection - Missing parameter username");
        }

        if (options.nextUrl) {
          this.nextUrl = options.nextUrl;
        }

        this.username = options.username;
        this.published = options.published || false;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      model: app.models.StoryCreateModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "categories",
            "description",
            "cover",
            "completed",
            "numParts",
            "lastPublishedPart",
            "url",
            "tags",
            "language",
            "user",
            "hasBannedCover",
            {
              parts: [
                "draft",
                "title",
                "id",
                "modifyDate",
                "hasBannedImages",
                "scheduledPublishDatetime"
              ]
            }
          ]
        },
        "total",
        "nextUrl"
      ],

      defaultUrl: function() {
        if (this.published) {
          return this.urlPublished();
        }
        return "/api/v3/users/" + this.username + "/stories";
      },

      urlPublished: function() {
        return "/v4/users/" + this.username + "/stories/published";
      },

      hasBannedImages: function() {
        // take into account inline images and story cover
        var hasBannedImages = _.some(this.models, function(story) {
          var storyhasBannedImages = _.some(story.get("parts"), function(p) {
            if (p.deleted !== true && p.hasBannedImages) {
              return true;
            }
          });
          if (storyhasBannedImages || story.get("hasBannedCover")) {
            return true;
          }
        });

        return hasBannedImages;
      },

      resource: function() {
        return (
          "user." +
          this.username +
          ".works" +
          (this.published ? ".published" : "")
        );
      },

      cacheLocal: true
    })
  );
})(window, _, wattpad, window.app);
