var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span class=\"badge\">"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.inbox : stack1)) != null ? stack1.unread : stack1), depth0))
    + "</span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <span id=\"notifications-menu-badge\" class=\"badge\">"
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.notifications : stack1)) != null ? stack1.unread : stack1),{"name":"count","hash":{},"data":data}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "            <li><a class=\"on-navigate\" href=\"/checkout\">Checkout</a></li>\n            <li><a class=\"on-navigate\" href=\"/manage_payment\">Manage Payment</a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "          <li>\n            <a class=\"on-navigate\" href=\"/user/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.username : stack1), depth0))
    + "/cookies\">Cookie Manager</a>\n          </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<div class=\"triangle\"></div>\n<div class=\"dropdown-menu dropdown-menu-right large\" role=\"menu\" aria-labelledby=\"profile-dropdown\">\n    <ul aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Profile",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"header-list\">\n        <li><a class=\"on-navigate\" href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.username : stack1), depth0))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"My Profile",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n        <li role=\"presentation\" class=\"divider\"></li>\n        <li><a href=\"/inbox\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Inbox",{"name":"trans","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.inbox : stack1)) != null ? stack1.unread : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </a></li>\n        <li><a href=\"/notifications\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Notifications",{"name":"trans","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.notifications : stack1)) != null ? stack1.unread : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </a></li>\n        <li><a href=\"/library\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Library",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.testGroups : depth0)) != null ? stack1.WEB_PAYMENTS : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <li role=\"presentation\" class=\"divider\"></li>\n        <li><a href=\"/settings/language?jq=true\" class=\"on-language\"\n          data-ignore=\"true\" >"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.contentLang : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a></li>\n        <li><a href=\"//support.wattpad.com\" >"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n        <li>\n          <a class=\"on-navigate\" href=\"/settings\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Settings",{"name":"trans","hash":{},"data":data}))
    + "</a>\n        </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"COOKIE_MANAGER",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <li><a href=\"/logout\" class=\"on-logout\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log Out",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    </ul>\n</div>\n";
},"useData":true});