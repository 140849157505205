import searchHeaderTags from "core/templates/search/partials/search-header-tags.hbs";
import suggestedSearches from "core/templates/search/partials/suggested-searches.hbs";
import searchHeader from "core/templates/search/partials/search-header.hbs";
import browseTopics from "core/templates/search/partials/browse-topics.hbs";

import getEducationalBannerMessages from "../../../../helpers/get-educational-banner-messages";

(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "SearchHeader",
    app.views.RegionManager.extend({
      className: "container",

      template: searchHeader,
      suggestedSearchesTemplate: suggestedSearches,
      tagTemplate: searchHeaderTags,
      browseTopicsTemplate: browseTopics,

      events: {
        // Remove when new tag cloud filter rollsout 100% for DW
        // Edit: these are still invoked and used by RefineByTags
        // this should be handled in search-landing until we figure
        // out where it lives.
        ...(app.get("device").is.mobile
          ? {
              "tap .on-toggle-tag": "toggleTag",
              "click .on-toggle-tag": "stopEvent"
            }
          : {})
      },

      resultsContainerClass: ".list-group",

      initialize: function(options) {
        options = options || {};
        this.searchTerm = options.searchTerm;
        this.storyCollection = options.storyCollection;
        this.topicCollection = options.topicCollection;
        this.userCollection = options.userCollection;

        if (app.get("device").is.mobile) {
          // for MW, select new search input from page container.
          this.targetSelector = "search-bar__input";
        } else {
          // DW, rely solely on layout header/nav search input
          this.targetSelector = "search-query";
        }

        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          Handlebars.registerPartial(
            "core.search.partials.suggested_searches",
            this.suggestedSearchesTemplate
          );
          // Use the browse topics partial and fetch topics for new home experience
          Handlebars.registerPartial(
            "core.search.partials.browse_topics",
            this.browseTopicsTemplate
          );

          Promise.resolve(options.topicCollection.fetch()).then(() => {
            this.topicCollection = options.topicCollection;
          });

          // Ensures rendering suggested searches for empty queries via SSR
          // The recent/popular suggested searches aren't being rendered in the server-side code
          // because we can't access the localstorage to determine what data to render.
          if (_.isEmpty(this.searchTerm)) {
            // Render browse topics in new home experience
            this.renderBrowseTopics();
          }

          Handlebars.registerPartial(
            "core.search.partials.search_header_tags",
            this.tagTemplate
          );

          this.storyCollection.once("sync", this.updateTags, this);
        }

        // Remove when new tag cloud filter rollsout 100% for DW
        if (app.get("device").is.mobile) {
          this.listenTo(
            app,
            "app:component:TagCarouselItem:click",
            this.toggleTag
          );
        }
      },

      render: function() {
        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          // Remove when new tag cloud filter rollsout 100% for DW
          this.updateTags();
        }

        // TODO: Can be removed when NEW_SEARCH_LAYOUT is released
        const handleSearchSubmit = searchTerm => {
          app.trigger("navbar:search:set", searchTerm);
        };

        // TODO: Can be removed when NEW_SEARCH_LAYOUT is released
        const handleSaveSearchTerm = searchTerm => {
          app.trigger("navbar:search:save", searchTerm);
        };

        // Remove when new tag cloud filter rollsout 100% for DW
        const relatedTags = app.get("device").is.mobile
          ? {
              tags: this.storyCollection?.tags?.toJSON(),
              withIcon: true
            }
          : undefined;

        const searchTerm = this.storyCollection?.searchTerm || "";

        this.$el.html(
          this.template({
            relatedTags,
            searchTerm,
            bannerMessages: getEducationalBannerMessages(searchTerm),
            isMobile: app.get("device").is.mobile,
            browseTopics: this.topicCollection.toJSON(),
            numStories: this.storyCollection?.length,
            handleSearchSubmit,
            handleSaveSearchTerm
          })
        );

        this.listenTo(this.storyCollection, "sync", this.afterCollectionSync);

        // Ensures rendering suggested searches for empty queries via SSR
        // The recent/popular suggested searches aren't being rendered in the server-side code
        // because we can't access the localstorage to determine what data to render.
        if (
          !wattpad?.testGroups["NEW_SEARCH_LAYOUT"] &&
          _.isEmpty(this.searchTerm)
        ) {
          // Render browse topics in new home experience
          _.defer(this.renderBrowseTopics.bind(this));
          _.defer(this.renderSuggestedSearches.bind(this));
        }

        return this;
      },

      afterCollectionSync: function() {
        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          // Remove when new tag cloud filter rollsout 100% for DW
          this.updateTags();
          this.renderTags();

          // Render browse topics in new home experience
          this.renderBrowseTopics();
          this.renderSuggestedSearches();
        }
      },

      // Remove when new tag cloud filter rollsout 100% for DW
      updateTags: function() {
        if (!this.storyCollection.tags) {
          this.storyCollection.tags = new Monaco.Collection();
          this.storyCollection.tags._app = app; //Bug in monaco, temporary band-aid
        }
      },

      // Remove when new tag cloud filter rollsout 100% for DW
      renderTags: function() {
        const harmMessages = getEducationalBannerMessages(
          this.storyCollection.searchTerm
        );
        if (this.storyCollection.length === 0) {
          this.$("#tag-holder").html(
            this.tagTemplate({
              bannerMessages: harmMessages,
              numStories: 0,
              isMobile: app.get("device").is.mobile
            })
          );
        } else {
          this.$("#tag-holder").html(
            this.tagTemplate({
              relatedTags: {
                tags: this.storyCollection.tags.toJSON(),
                withIcon: true
              },
              numStories: this.storyCollection.total,
              bannerMessages: harmMessages,
              isMobile: app.get("device").is.mobile
            })
          );
          // TODO: Remove with future new search refactoring,
          // ".searching" isn't used for anything.
          const input = this.$el.find("#search-field");
          if (
            input.length > 0 &&
            this.storyCollection.searchTerm.trim() === input.val().trim()
          ) {
            this.$("#search-field").removeClass("searching");
          }
        }
      },

      renderSuggestedSearches: function() {
        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) return;
        const searchTerm = this.storyCollection?.searchTerm?.trim();
        let recentSearches =
          (localStorage &&
            JSON.parse(localStorage.getItem("recentSearches"))) ||
          [];
        recentSearches = recentSearches.slice(0, 3);
        let hideRecentSearches = searchTerm || recentSearches.length === 0;

        this.$("#suggested-searches").html(
          this.suggestedSearchesTemplate({
            searchTerm,
            suggestedSearchTerms: hideRecentSearches ? [] : recentSearches
          })
        );
      },

      renderBrowseTopics: function() {
        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) return;
        if (
          this.topicCollection &&
          this.storyCollection?.searchTerm?.trim().length === 0
        ) {
          this.$("#browse-topics").html(
            this.browseTopicsTemplate({
              browseTopics: this.topicCollection.toJSON(),
              searchTerm: this.storyCollection.searchTerm
            })
          );
        } else {
          this.$("#browse-topics").html({
            searchTerm: this.storyCollection.searchTerm
          });
        }
      },

      // Remove when new tag cloud filter rollsout 100% for DW
      toggleTag: function(tag, event) {
        var $element = $(event.currentTarget);

        // new search bar uses class.
        // old search and nav search input use ID
        const searchInputElement =
          this.targetSelector === "search-bar__input"
            ? document.getElementsByClassName(this.targetSelector)[0]
            : document.getElementById(this.targetSelector);

        if ($element.hasClass("active")) {
          searchInputElement.value = searchInputElement.value
            .trim()
            .replace(" #" + tag, "");
          this.storyCollection?.tags?.get(tag).set("active", false);
        } else {
          searchInputElement.value =
            searchInputElement.value.trim() + " #" + tag;
          this.storyCollection?.tags?.get(tag).set("active", true);

          // old tag add event. possibly remove based on RJ-4504
          window.te.push("event", "search", "story", "tag", "add", {
            tags: searchInputElement.value.match(/#[\w]+(?=\s|$)/g)
          });
        }
        this.renderTags();
        app.trigger("navbar:search:set", searchInputElement.value, true);
      },

      updateQuery: function(query) {
        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          var $el = this.$el.find(this.targetSelector).addClass("searching");

          $(".story-card-skeleton-container").removeClass("hidden");
          $(".story-card-container").addClass("hidden");

          $(".profile-skeleton-container").removeClass("hidden");
          $("#results-people .list-group").addClass("hidden");
          $("#results-people .load-more").addClass("hidden");

          $el.val(query);
          this.renderTags();
        }
      },

      updateCollection: function(collection) {
        this.stopListening(this.storyCollection, "sync");
        this.storyCollection.off("sync");

        this.storyCollection = collection;
        this.listenTo(this.storyCollection, "sync", this.afterCollectionSync);
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
