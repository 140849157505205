var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"reading-widget hidden-xxs col-xs-12 col-sm-12 col-md-12 col-lg-12 hidden promoted-stories-container\">\n      <h4 class=\"panel-title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Promoted stories",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n      <div id=\"similar-stories\" class=\"promoted-stories\"></div>\n    </div>\n    <div class=\"reading-widget hidden-xxs col-xs-12 col-sm-12 col-md-12 col-lg-12 hidden similar-stories-container\">\n      <h4 class=\"panel-title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You'll also like",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n      <div id=\"similar-stories\" class=\"similar-stories\"></div>\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"row\">\n"
    + ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isMicroPart : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});