(function(window, _, wattpad, app) {
  "use strict";

  //TODO We should have this extend off an existing story collection
  app.add(
    "Library",
    app.collections.Base.extend({
      eventsOn: false,

      //=== INITIALIZE ===//
      init: function(collection, options) {
        var self = this;
        options = options || {};

        if (!options.user) {
          this._loaded.reject();
          this._user = new app.models.User();
        } else {
          this._user = options.user;
        }

        this.on("request", function() {
          if (this.eventsOn) {
            self.off("add", self.saveItem);
            self.off("remove", self.deleteItem);
            this.eventsOn = false;
          }
        });

        this.on("sync", function() {
          if (!this.eventsOn) {
            this.eventsOn = true;
            self.on("add", self.saveItem, self);
            self.on("remove", self.deleteItem, self);
          }
        });
      },

      //=== METHODS ===//
      saveItem: function(model) {
        var self = this;
        if (this.url()) {
          $.post(this.url(), {
            stories: model.get("id")
          })
            .done(function() {
              self.trigger("added", model);
            })
            .fail(function(xhr) {
              self.trigger("error-adding", xhr);
            });

          var page = window.te.getPage();
          window.te.push("event", "app", "library", "story", "add", {
            storyid: model.get("id"),
            page: page
          });

          if (page === "story_details") {
            window.te.push(
              "event",
              "story_details",
              "story",
              "library",
              "add",
              {
                storyid: model.get("id")
              }
            );
          }
        }
      },

      deleteItem: function(model) {
        var self = this;

        if (this.url()) {
          $.ajax({
            url: this.url(true) + "/" + model.get("id"),
            type: "DELETE"
          })
            .done(function() {
              self.trigger("removed", model.get("id"));
            })
            .fail(function(xhr) {
              self.trigger("error-removing", xhr);
            });
          window.te.push("event", "wattpad", "story", "library", "remove", {
            storyid: model.get("id")
          });
        }
      },

      //=== ENTITIES ===//
      _user: null,

      user: function() {
        return this._user;
      },

      //=== MONACO ===//
      resource: function() {
        return this.user().authenticated()
          ? "user." + this.user().get("username") + ".library"
          : null;
      },

      url: function(ignoreFilter) {
        return this.user().authenticated()
          ? "/api/v3/users/" +
              this.user().get("username") +
              "/library" +
              (!ignoreFilter ? "?fields=stories(id)" : "")
          : null;
      },

      parse: function(data) {
        return data.stories;
      }
    })
  );
})(window, _, wattpad, window.app);
