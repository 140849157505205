(function(window, _, $, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:checkout",
    app.router.filter(["getTranslations", "requireLogin"], function(username) {
      var showWebPayments = _.get(wattpad, ".testGroups.WEB_PAYMENTS") || false;
      var isDesktop = app.get("device").isDesktop();

      if (!_.every([showWebPayments, isDesktop])) {
        wattpad.utils.redirectToCSR("/home");
        return;
      }

      var checkoutView = new app.views.SubscriptionCheckout({});

      app.transitionTo(checkoutView, {
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
