(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "Authlogin",
    Monaco.Model.extend({
      defaults: {
        user: null,
        password: null
      },

      validationRules: {
        user: [
          {
            func: "isRequired",
            msg: utils.trans("Username/Email is required.")
          },
          {
            func: "isUsernameOrEmail",
            minLength: 6,
            maxLength: 20,
            msg: utils.trans("You have not entered a valid email or username.")
          }
        ],
        password: [
          { func: "isRequired", msg: utils.trans("Password is required.") },
          {
            func: "minLength",
            length: 6,
            msg: utils.trans("Passwords must be 6-20 characters long.")
          },
          {
            func: "maxLength",
            length: 20,
            msg: utils.trans("Passwords must be 6-20 characters long.")
          }
        ]
      },

      isUsernameOrEmail: function(value, options) {
        var validLength, isEmail, isAlphaNumeric, hasLetter;
        isEmail = value.match(this.regex.email) !== null;

        if (isEmail) {
          return true;
        } else {
          isAlphaNumeric = value.match(this.regex.az09) !== null;
          hasLetter = value.match(this.regex.hasLetter) !== null;
          validLength =
            value.length >= options.minLength &&
            value.length <= options.maxLength;

          if (isAlphaNumeric && hasLetter && validLength) {
            return true;
          }
        }
        return false;
      }
    })
  );

  app.mixin(app.models.Authlogin, "ValidationModel", "GetAppValRules");
})(window, _, wattpad, wattpad.utils, window.app);
