(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   *  Handles validation of form/input elements on a view and binds them to attributes on `this.model`
   *  works in conjunction with {{ @link mixin:ValidationModel }}
   *
   *  tmpl reqs:  input elements must be wrapped in bootstrap `.form-group' with `.has-feedback`
   *              a `data-attr` prop is req'd to reference the model attribute you want to be bind to
   *              The `validationElements` handlebars helper will generate the necessary validation
   *              elements (icons & alert)
   *
   *    <div class="form-group has-feedback" data-attr="username">
   *        <label class="sr-only" for="auth-username">Username</label>
   *        <input id=auth-username" class="form-control" type="password" placeholder="Username">
   *        {{{ validationElements }}}
   *    </div>
   *
   * @mixin ValidationView
   */
  app.add(
    "ValidationView",
    Monaco.Mixin.create({
      events: {
        "keyup    .has-feedback input": "clearValidation",
        "blur     .has-feedback input": "validateInput",
        "change   .has-feedback input": "clearAndValidateInput",
        "change   .has-feedback select.validate": "clearAndValidateInput"
      },

      initialize: function(options, next) {
        this.bindValidationEvents();
        next(options);
      },

      bindValidationEvents: function(n) {
        this.listenTo(this.model, "attr:valid", this.setInputValid);
        this.listenTo(this.model, "attr:invalid", this.setInputInvalid);
        n();
      },

      setInputValid: function(key, n) {
        var $myel = this.$('.has-feedback[data-attr="' + key + '"]').addClass(
          "valid"
        );

        this.$(".on-invalid").each(function() {
          if ($myel.children(".on-invalid")[0] === this) {
            $(this).tooltip("destroy");
          } else {
            $(this).tooltip("hide");
          }
        });

        n();
      },

      setInputInvalid: function(key, msg, n) {
        var $myel = this.$('.has-feedback[data-attr="' + key + '"]')
          .addClass("invalid")
          .find(".on-invalid");

        this.$el.find(".on-invalid").each(function() {
          if ($myel[0] === this) {
            $(this).tooltip("destroy");
          } else {
            $(this).tooltip("hide");
          }
        });

        $myel
          .tooltip({ trigger: "click hover manual", title: msg, html: true })
          .tooltip("show");
        n();
      },

      validateInput: function(evt, n) {
        var $el = $(evt.currentTarget),
          $parent = $el.parents(".has-feedback"),
          attribute = $parent.data("attr"),
          self = this;

        this.activateTooltips();

        // Don't revalidate if validation is already active
        if (
          !$parent.hasClass("valid") &&
          !$parent.hasClass("invalid") &&
          !$parent.hasClass("warning")
        ) {
          this.model.set(attribute, $el.val());
          this.timeout = window.setTimeout(function() {
            self.model.validate(attribute, {});
          }, 120);
        }

        n();
      },

      clearValidation: function(evt, n) {
        var $el = $(evt.currentTarget).parents(".has-feedback");
        if (
          $el.hasClass("valid") ||
          $el.hasClass("invalid") ||
          $el.hasClass("warning")
        ) {
          $el
            .removeClass("valid invalid warning")
            .children(".on-invalid")
            .tooltip("destroy");
        }
        n();
      },

      clearAndValidateInput: function(evt, noop) {
        this.clearValidation(evt);
        this.validateInput(evt);
        noop();
      },

      activateTooltips: function(n) {
        var $tooltips = this.$el.find(".on-invalid");
        $tooltips.off("show.bs.tooltip").on("show.bs.tooltip", function(evt) {
          var $this = $(evt.currentTarget);

          $tooltips.each(function() {
            if ($this[0] !== this) {
              $(this).tooltip("hide");
            }
          });
        });
        n();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
