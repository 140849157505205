var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a class=\"on-view-convo btn btn-sm btn-grey\"\n  href='"
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.comment : stack1)) != null ? stack1.deeplink_url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "'>\n  "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View Conversation",{"name":"trans","hash":{},"data":data}))
    + "\n</a>\n";
},"useData":true});