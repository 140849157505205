import recommendedStoryItemCover from "core/templates/item-views/recommended-story-item-cover.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RecommendedStoryItemCover",
    app.views.Base.extend({
      className: "recommended-story-cover story-item",

      template: recommendedStoryItemCover,

      render: function() {
        this.$el.html(this.template(this.model.toJSON()));

        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
