import discoverModuleStoriesSmall from "core/templates/item-views/discover-module-stories-small.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "DiscoverModuleStoriesSmall",
    app.views.StoryItem.extend({
      // Properties
      // ----------
      className: "discover-module-stories-small on-discover-module-item",
      template: discoverModuleStoriesSmall,

      // Methods
      // -------
      render: function() {
        var data = this.model ? this.model.toJSON() : { empty: true };

        data.isRecentlyRead = this.options.isRecentlyRead;

        data.isDesktop = this.options.isDesktop;
        var categoryName = this.model ? this.model.get("category") : null;
        data.type = "stories";

        // Character limits
        data.titleCharLimit = data.isDesktop ? 35 : 25;
        data.titleCharLimit =
          this.options.titleCharLimit || data.titleCharLimit;

        if (categoryName) {
          data.category = app
            .get("categories")
            .get(categoryName)
            .toJSON();
        }

        this.$el.html(this.template(data));

        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
