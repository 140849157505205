import notificationItemOptions from "core/templates/item-views/notification-item-options.hbs";
import notificationFriendJoin from "core/templates/notifications/items/notification-friend-join.hbs";
import notificationForumPost from "core/templates/notifications/items/notification-forum_post.hbs";
import notificationError from "core/templates/notifications/items/notification-error.hbs";
import notificationBase from "core/templates/notifications/items/notification-base.hbs";
import notificationUpload from "core/templates/notifications/items/notification-upload.hbs";
import notificationLibrary from "core/templates/notifications/items/notification-library.hbs";
import notificationDedicate from "core/templates/notifications/items/notification-dedicate.hbs";
import notificationComment from "core/templates/notifications/items/notification-comment.hbs";
import notificationMessage from "core/templates/notifications/items/notification-message.hbs";
import notificationVote from "core/templates/notifications/items/notification-vote.hbs";
import notificationFollowerApproved from "core/templates/notifications/items/notification-follower-approved.hbs";
import notificationFollow from "core/templates/notifications/items/notification-follow.hbs";
(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "NotificationEvent",
    app.views.Base.extend({
      templates: {
        follow: notificationFollow,
        follower_approved: notificationFollowerApproved,
        vote: notificationVote,
        message: notificationMessage,
        comment: notificationComment,
        inline_comment: notificationComment,
        dedicate: notificationDedicate,
        library: notificationLibrary,
        upload: notificationUpload,
        base: notificationBase,
        error: notificationError,
        forum_post: notificationForumPost,
        friend_join: notificationFriendJoin
      },

      trackingEvents: {
        follow: "new_followers",
        follower_approved: "follow_approved",
        vote: "votes",
        message: {
          default: "profile_posts",
          replies: "replies",
          mentions: "mentions",
          broadcast: "announcements"
        },
        comment: {
          default: "comments",
          replies: "replies",
          mentions: "mentions"
        },
        inline_comment: {
          default: "comments",
          replies: "replies",
          mentions: "mentions"
        },
        dedicate: "dedications",
        library: "reading_list_adds",
        upload: "story_updates",
        friend_join: "friend_joins"
      },

      template: null,

      events: {
        "click .show-full": "stopEvent",
        "tap  .show-full": "showFullMessage",

        "click .expanded-text": "stopEvent",
        "tap  .expanded-text": "navigateTo",

        "tap .on-notification-click": "navigateAndSendTracking",
        "click .on-notification-click": "stopEvent",

        "tap  .on-delete": "onDeleteComment",
        "click  .on-delete": "stopEvent",

        "tap  .on-report": "onReportComment",
        "click  .on-report": "stopEvent"
      },

      reportModalContainer: ".comment-report-modal",
      modalTitle: wattpad.utils.trans("Report a Comment"),
      requestType: "inappropriate_comments",
      isMainReportModal: false,
      conductType: "story_comment_conduct",

      initialize: function() {
        Handlebars.registerPartial(
          "core.item_views.notification_item_options",
          notificationItemOptions
        );
        this.template = this.templates[this.model.get("type")];

        // React Report Modal props (only needed for comment notifications)
        if (this.model.get("data") && this.model.get("data").comment) {
          this.reportModalType = "comment";
          this.reportModalId = {
            name: this.model.get("data").comment.user.name,
            location: this.model
              .get("data")
              .comment.deeplink_url.split("/comment")[0],
            body: utils.sanitizeHTML(this.model.get("data").comment.body),
            deepLink: this.model.get("data").comment.deeplink_url
          };
        }
      },

      render: function() {
        var data = this.model.get("data");
        var notificationType = this.model.get("type");
        data.createDate = this.model.get("createDate");
        data.isRead = this.model.get("isRead");
        data.id = this.model.get("id");
        data.isNotification = false;
        data.commentAuthor = data.comment?.user?.name;
        data.commentBody = data.comment?.body;

        if (
          ["comment", "inline_comment", "message"].includes(notificationType)
        ) {
          var objectData = data.comment || data.message;
          this.hasMentions = !_.isEmpty(utils.getMentions(objectData.body));
          data.hasMentions = this.hasMentions;
        }
        if (notificationType === "message") {
          data.toMe = this.model.collection.username === data.message.to.name;
          data.theirFeed = data.message.from.name === data.message.to.name;
        }
        if (this.model.get("children").length > 0) {
          data.children = this.model.get("children").length + 1; //include itself
          data.groupUrl = this.model.get("group_url");
        }

        var message = "";
        if (
          notificationType === "message" &&
          typeof data.message !== "undefined"
        ) {
          var device = app.get("device");
          message = data.message.body;

          if (device.is.mobile) {
            data.navigateTo =
              "/user/" +
              data.message.to.name +
              "/conversations#" +
              data.message.id;
          } else {
            data.navigateTo =
              "/user/" +
              data.message.to.name +
              "/conversations#" +
              data.message.id;
          }
        } else if (
          (notificationType === "inline_comment" ||
            notificationType === "comment") &&
          typeof data.comment !== "undefined"
        ) {
          message = data.comment.body;

          // data.comment.deeplink_url contains https://www.wattpad.com, which has 23 chars,
          // we're removing that portion of the url for router CSR call
          data.navigateTo = data.comment.deeplink_url.substring(23);
          data.isCommentNotification = true;
          data.deeplink = data.comment.deeplink_url;

          if (wattpad.utils.currentUser().authenticated()) {
            var isAmbassador =
              wattpad.utils.currentUser().get("ambassador") ||
              wattpad.utils.currentUser().get("isSysAdmin");
            var isOwner =
              data.comment.user.name ===
              wattpad.utils.currentUser().get("username");
            data.canDelete = isAmbassador || isOwner;
          }
        }
        data.maxTextLength = 465;
        data.isTruncated = message.length > data.maxTextLength;

        data.canLink = false;

        this.$el.html(this.template(data));

        return this;
      },

      showFullMessage: function(evt) {
        var notificationType = this.model.get("type");

        if (
          notificationType === "message" ||
          notificationType === "comment" ||
          notificationType === "inline_comment"
        ) {
          var data = this.model.get("data");

          if (data.isTruncated) {
            this.$el.find(".shown-message").addClass("hidden");
            this.$el.find(".show-full").addClass("hidden");
            this.$el
              .find(".full-message")
              .removeClass("hidden")
              .addClass("expanded-text");
          }
        }

        utils.stopEvent(evt);
      },

      navigateTo: function(evt) {
        var notificationType = this.model.get("type");

        if (
          notificationType === "message" ||
          notificationType === "comment" ||
          notificationType === "inline_comment"
        ) {
          var data = this.model.get("data");

          if (typeof data.navigateTo !== "undefined") {
            app.router.navigate(data.navigateTo, { trigger: true });
          }
        }
        this.sendTrackingEvent();
      },

      navigateAndSendTracking: function(evt) {
        this.sendTrackingEvent();
        app.views.Base.prototype.onNavigate.apply(this, arguments);
      },

      sendTrackingEvent: function() {
        var trackingEvent = this.trackingEvents[this.model.get("type")],
          data = this.model.get("data");

        // For notification type 'comment', 'inline_comment', and 'message'
        if (trackingEvent && typeof trackingEvent === "object") {
          if (this.hasMentions) {
            trackingEvent = trackingEvent.mentions;
          } else if (data.message && data.message.wasBroadcast) {
            trackingEvent = trackingEvent.broadcast;
          } else if (
            (data.comment && data.comment.parentId) ||
            (data.message && data.message.parentId)
          ) {
            trackingEvent = trackingEvent.replies;
          } else {
            trackingEvent = trackingEvent.default;
          }
        }

        window.te.push("event", "notifications_feed", "item", null, "click", {
          notification_type: trackingEvent,
          storyid: data.story && data.story.id ? data.story.id : null,
          username:
            data.message && data.message.from ? data.message.from.name : null,
          reading_listid: data.list && data.list.id ? data.list.id : null
        });
      },

      onDeleteComment: function(evt) {
        utils.stopEvent(evt);
        var commentData = this.model.get("data").comment;

        window.te.push(
          "event",
          "notifications_feed",
          "comment",
          null,
          "delete",
          {
            commenter_username: commentData.user.name,
            commentid: commentData.id,
            partid: this.model.get("data").story.part.id
          }
        );

        if (
          window.confirm(
            wattpad.utils.trans("Are you sure you want to delete this comment?")
          )
        ) {
          var comment = new app.models.CommentModel({
            id: commentData.id,
            parentId: null
          });
          return Promise.resolve(comment.destroy()).then(function(response) {
            window.location.reload();
          });
        }
      },

      onReportComment: function(evt) {
        var commentData = this.model.get("data").comment;
        window.te.push(
          "event",
          "notifications_feed",
          "comment",
          null,
          "report",
          {
            commenter_username: commentData.user.name,
            commentid: commentData.id,
            partid: this.model.get("data").story.part.id
          }
        );
      }
    })
  );

  app.mixin(app.views.NotificationEvent, "ReportManagement", "ReportConduct");
})(window, wattpad, wattpad.utils, window.app);
