var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "class=\"new-search-page\" ";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div id=\"blank-search\" class=\"container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.blank_search"],depth0,{"name":"core.search.partials.blank_search","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"7":function(container,depth0,helpers,partials,data) {
    return " new-row";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <section "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " >\n                        <div id=\"authentication-panel\" class=\"panel panel-basic\">\n                            <div class=\"signup-modal\">\n"
    + ((stack1 = container.invokePartial(partials["core.signup_prompt"],depth0,{"name":"core.signup_prompt","data":data,"indent":"                                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                            </div>\n                        </div>\n                    </section>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "id=\"section-authentication\"";
},"12":function(container,depth0,helpers,partials,data) {
    return "class=\"col-sm-12 col-md-4\"";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div id=\"search-filters-container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.search_filters"],depth0,{"name":"core.search.partials.search_filters","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isLogged : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.program(19, data, 0),"data":data})) != null ? stack1 : "");
},"17":function(container,depth0,helpers,partials,data) {
    return "col-sm-12";
},"19":function(container,depth0,helpers,partials,data) {
    return "col-sm-12 col-md-8";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.search.partials.results_stories"],depth0,{"name":"core.search.partials.results_stories","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.search.partials.stories_list"],depth0,{"name":"core.search.partials.stories_list","data":data,"indent":"                            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <div class=\"right-ele\">\n                    <div class=\"sticky\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.right_rail_ad"],depth0,{"name":"core.search.partials.right_rail_ad","data":data,"indent":"                        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                    </div>\n                </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"search-page\" "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n\n    <header id=\"search-controls\">\n        <div id=\"search-header\">\n            <div class=\"container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.search_header"],depth0,{"name":"core.search.partials.search_header","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <div id=\"search-tabs-container\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.search_tabs"],depth0,{"name":"core.search.partials.search_tabs","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </header>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"NEW_SEARCH_LAYOUT",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <main class=\"container"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.searchTerm : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <div class=\"row"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <div class=\"left\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isLogged : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n            <section id=\"section-results-stories\" class=\"results "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.resultType : depth0),"people",{"name":"ifequal","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" >\n                <article id=\"results-stories\">\n                    <div class=\"feed-item-new panel new-container\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </div>\n                </article>\n            </section>\n\n            <section id=\"section-results-people\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.resultType : depth0),"stories",{"name":"ifequal","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <div id=\"results-people\" class=\"results"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depth0 != null ? depth0.resultType : depth0),"stories",{"name":"ifequal","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                    <div id=\"results-people-region\" class=\"results-people-update\">\n                        <div class=\"feed-item-new panel\">\n"
    + ((stack1 = container.invokePartial(partials["core.search.partials.results_people"],depth0,{"name":"core.search.partials.results_people","data":data,"indent":"                          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n                </div>\n            </section>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"unless","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </main>\n</div>\n";
},"usePartial":true,"useData":true});