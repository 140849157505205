var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"row\">\n    <article class=\"col-sm-7 col-md-8\" id=\"notification-feed-col\">\n        <header><h1 class=\"h3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Notifications",{"name":"trans","hash":{},"data":data}))
    + "</h1></header>\n        <main id=\"notification-feed\">\n            <div>"
    + ((stack1 = container.invokePartial(partials["core.notifications.notifications"],depth0,{"name":"core.notifications.notifications","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n        </main>\n    </article>\n    <article class=\"col-sm-5 col-md-4"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hasRequests : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" id=\"follow-requests-col\">\n        <header><h1 class=\"h3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follower Requests",{"name":"trans","hash":{},"data":data}))
    + "</h1></header>\n        <main id=\"follow-requests\">\n            <div>"
    + ((stack1 = container.invokePartial(partials["core.notifications.follow_requests"],depth0,{"name":"core.notifications.follow_requests","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n        </main>\n    </article>\n    <div class=\"col-sm-5 col-md-4\">\n        <div class=\"wp-component invite-panel-component\">\n"
    + ((stack1 = container.invokePartial(partials["core.invite_friends.components.invite_panel"],depth0,{"name":"core.invite_friends.components.invite_panel","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n    </div>\n</div>\n<div id=\"expand-modal\" class=\"modal fade\" role=\"dialog\" tabindex='-1'>\n    <div class=\"modal-dialog\">\n        <div class=\"modal-content\">\n            <div class=\"modal-header notification aggregate panel\">\n                <div class=\"count\"></div>\n                <div class=\"content\">\n                  <div class=\"heading\"></div>\n                  <time></time>\n                </div>\n                <button type=\"button\" class=\"close btn btn-text\" data-dismiss=\"modal\" aria-label=\"Close\">\n                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                </button>\n            </div>\n            <div class=\"modal-body\"></div>\n            <div class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-left-align btn-grey btn-block\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Close",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});