(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "NodeLibrary",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "cover",
            "voteCount",
            "readCount",
            "readingPosition",
            "url",
            { parts: ["id", "url"] },
            { user: ["name", "avatar"] },
            { lastPublishedPart: ["createDate"] }
          ]
        },
        "total",
        "nextUrl"
      ],

      libraryType: "current-reads",

      initialize: function(models, options) {
        options = options || {};
        this.user = options.user || wattpad.utils.currentUser();

        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      resource: function() {
        return "user." + this.user.get("username") + ".library.new";
      },

      defaultUrl: function() {
        return "/api/v3/users/" + this.user.get("username") + "/library";
      },

      addStory: function(stories) {
        var self = this,
          storyIds;

        stories = _.isArray(stories) ? stories : [stories];
        storyIds = _.map(stories, function(story) {
          return story.get("id");
        });

        return Promise.resolve(
          $.post(this.defaultUrl() + "/" + storyIds.join(","))
        ).then(function(response) {
          _.each(response.results.success, self.reportAdd);
          return response.results;
        });
      },

      reportAdd: function(id) {
        window.te.push("event", "app", "library", "story", "add", {
          storyid: id,
          page: window.te.getPage()
        });
      },

      removeStory: function(stories, unarchive) {
        var storyIds;

        stories = _.isArray(stories) ? stories : [stories];
        storyIds = _.map(stories, function(story) {
          return story.get("id");
        });

        return Promise.resolve(
          $.ajax({
            url: this.defaultUrl() + "/" + storyIds.join(),
            type: "DELETE",
            data: unarchive ? { unarchive: 1 } : null
          })
        )
          .then(function(response) {
            return response && response.results ? response.results : true;
          })
          ["catch"](function(response) {
            return response && response.results ? response.results : false;
          });
      },

      reportRemove: function(id) {
        window.te.push("event", "wattpad", "story", "library", "remove", {
          storyid: id
        });
      }
    })
  );
})(window, _, wattpad, window.app);
