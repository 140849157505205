(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * Purpose: vote button for a story part that will prompt for signup if user is not logged in
   * Requirements:
   *    - this.voteButton that is a selector for a button in the dom
   *      meant for voting. By default it is .on-vote
   *    - this.model is a StoryPartModel
   * Options:
   *    - app.listen for 'app:story:vote' event to fire a callback function
   *      after the vote call returns
   * @mixin VoteManagement
   */
  app.add(
    "VoteManagement",
    app.mixins.AuthPromptManagement.extend({
      events: {
        "tap .on-vote": "onVote",
        "click .on-vote": "stopEvent"
      },

      voteButton: ".on-vote",

      title: function() {
        return wattpad.utils.trans("Sign up to Vote");
      },

      subtitle: function() {
        return wattpad.utils.trans(
          "Sign up for Wattpad and start commenting, saving stories, reading them offline, or writing your own."
        );
      },

      onVote: function(evt, next) {
        var self = this;
        var $voteTooltip = this.$("#vote-tool-tip");

        this.stopEvent(evt);
        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthPrompt(evt);
          return;
        }

        if (!wattpad.utils.currentUser().get("verified_email")) {
          utils.showPleaseVerifyModal();
          return;
        }

        this.$(this.$voteTooltip).tooltip({
          trigger: "manual",
          selector: "#vote-tool-tip"
        });
        this.$(this.voteButton).prop("disabled", true);

        var localVotedStatus = !this.model.get("voted");
        var localVoteCount = localVotedStatus
          ? this.model.get("voteCount") + 1
          : this.model.get("voteCount") - 1;

        app.trigger("app:story:vote", {
          voted: localVotedStatus,
          voteCount: localVoteCount,
          storyId: self.model.get("id")
        });

        this.model
          .vote()
          .then(function() {
            var vote = self.$(".vote-text"),
              voteFa = self.$(".footer-vote .fa-vote");
            self.$(self.voteButton).prop("disabled", false);
            if (self.model.get("voted")) {
              vote.html("Voted");
              voteFa.removeClass("fa-wp-neutral-2");
              voteFa.addClass("fa-wp-base-1");
            } else {
              vote.html("Vote");
              voteFa.removeClass("fa-wp-base-1");
              voteFa.addClass("fa-wp-neutral-2");
            }

            next(evt);
          })
          ["catch"](function(apiData) {
            apiData = apiData.responseJSON;

            app.trigger("app:story:vote", {
              voted: self.model.get("voted"),
              voteCount: self.model.get("voteCount"),
              storyId: self.model.get("id")
            });
            if (apiData.message) {
              $voteTooltip.attr(
                "data-original-title",
                wattpad.utils.trans(apiData.message)
              );
            }
            $voteTooltip.tooltip("show");
            setTimeout(function() {
              $voteTooltip.tooltip("hide");
            }, 3000);
            self.$(self.voteButton).prop("disabled", false);
            next(evt);
          });
      },

      stopEvent: function(e, noop) {
        wattpad.utils.stopEvent(e);
        noop();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
