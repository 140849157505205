(function(window, _, wattpad, utils, app) {
  "use strict";

  // A promoted story is just a normal story, except it is pulled from the ads server instead of the stories api
  app.add(
    "PromotedStoryModel",
    app.models.BaseModel.extend({
      cacheLocal: false,

      initialize: function(options) {
        options = options || {};
        this.storyId = options.storyId || "";
        this.zoneIds = options.zoneIds || [155799];
        this.descCharLimit = options.descCharLimit || 170;
        this.baseStoryCategory = options.baseStoryCategory;

        _.bindAll(this, "checkforPromotedStory", "fetchPromotedStory", "parse");
      },

      fetch: function() {
        var self = this;
        if (
          !utils.eligibleForDirectSoldAds() ||
          wattpad.utils.currentUser().get("isPremium")
        ) {
          return Promise.resolve();
        }

        return Promise.resolve(self.checkforPromotedStory())
          .then(self.fetchPromotedStory)
          .then(self.parse)
          ["catch"](function() {
            // Something went horribly wrong on the way to the Adzerk promise land.
            self.storyModel = null;
          });
      },

      parse: function(data) {
        if (data) {
          data.promoted = true;

          if (data.description) {
            data.description = utils.sanitizeHTML(data.description);
          }
          data.descCharLimit = this.descCharLimit;

          if (this.promotedCover && this.promotedCover.length) {
            data.cover = this.promotedCover;
          }

          data.sponsorName = this.sponsor ? this.sponsor.name : data.user.name;
        }

        return data;
      },

      checkforPromotedStory: function() {
        var postData = this.buildAdRequestData();

        var result = Promise.resolve(
          $.ajax({
            type: "POST",
            url: "https://adz.wattpad.com/api/v2",
            dataType: "json",
            contentType: "text/plain",
            data: JSON.stringify(postData),
            xhr: function() {
              // Get new xhr object using default factory
              var xhr = jQuery.ajaxSettings.xhr();
              // Copy the browser's native setRequestHeader method
              var setRequestHeader = xhr.setRequestHeader;
              // Replace with a wrapper
              xhr.setRequestHeader = function(name, value) {
                // Ignore the Authorization  header
                if (name == "Authorization") return;
                // Otherwise call the native setRequestHeader method
                // Note: setRequestHeader requires its 'this' to be the xhr object,
                // which is what 'this' is here when executed.
                setRequestHeader.call(this, name, value);
              };
              // pass it on to jQuery
              return xhr;
            }
          })
        );

        return result;
      },

      buildAdRequestData: function() {
        var currentLanguageName = app
          .get("supported-languages")
          .find({ id: parseInt(app.get("language"), 10) })
          .get("nameEnglish");
        var currentUserKey =
          wattpad.utils.currentUser().get("externalId") || "";

        var baseStoryCategoryName = "";
        if (this.baseStoryCategory) {
          baseStoryCategoryName = app
            .get("categories")
            .find({ id: this.baseStoryCategory })
            .get("name_english");
        }

        return {
          placements: [
            {
              divName: "azk70876",
              networkId: 9660,
              siteId: 421790,
              adTypes: [318],
              zoneIds: this.zoneIds,
              properties: {
                userLanguage: currentLanguageName,
                storyCategory: [baseStoryCategoryName],
                storyId: String(this.storyId)
              },
              user: {
                key: currentUserKey
              },
              time: "123455",
              keywords: [baseStoryCategoryName]
            }
          ]
        };
      },

      fetchPromotedStory: function(data) {
        this.storyModel = this.parsePromotedStory(data);
        var hasPromoted = !!this.storyModel;
        return hasPromoted ? this.storyModel.fetch() : Promise.resolve(null);
      },

      parsePromotedStory: function(adDecision) {
        //ensure that a story is sent

        if (
          _.get(
            adDecision,
            "decisions.azk70876.contents[0].data.customData.story.id"
          )
        ) {
          var customData =
            adDecision.decisions["azk70876"].contents[0].data.customData;

          // have to save the promoted cover because it may get replaced on fetch()
          this.promotedCover = customData.story.cover;
          this.sponsor = customData.story.sponsor;

          this.clickUrls = [adDecision.decisions["azk70876"].clickUrl];
          this.impressionUrls = [
            adDecision.decisions["azk70876"].impressionUrl
          ];
          if (customData.thirdParty) {
            if (customData.thirdParty.clickUrls) {
              this.clickUrls = this.clickUrls.concat(
                customData.thirdParty.clickUrls
              );
            }
            if (customData.thirdParty.impressionUrls) {
              this.impressionUrls = this.impressionUrls.concat(
                customData.thirdParty.impressionUrls
              );
            }
          }

          return new app.models.StoryModel({
            id: customData.story.id
          });
        }

        return null;
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
