import pictureViewer from "core/templates/story-reading/picture-viewer.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "PictureViewer",
    app.views.Base.extend({
      events: {
        "tap .on-report-image": "onReportImage",
        "tap .on-dismiss": "onDismiss",

        "click .on-dismiss": "stopEvent",
        "click .on-report-image": "stopEvent",

        "tap .vertical-align-helper": "onTapPicture",
        "click .vertical-align-helper": "stopEvent"
      },

      template: pictureViewer,

      id: "picture-viewer",

      initialize: function(options) {
        options = options || {};
        this.contents = "";
        this.mediaLink = "";
        this.reportLink = "#";

        var imageUrl;

        if (options.contents) {
          this.contents = options.contents;
          this.mediaLink = $(this.contents).attr("src");
          imageUrl = $(options.contents).attr("src");
        }

        if (options.paragraph) {
          this.reportLink = utils.sprintf(
            "http://www.wattpad.com/help/content/1?story_link=%s&media_link=%s",
            [window.encodeURIComponent(window.location.href), this.mediaLink]
          );
        }

        this.mediaShare = options.mediaShare || [];

        if (this.mediaShare[1]) {
          var pinterestUrl = this.mediaShare[1].href;

          pinterestUrl = this.preparePinterestUrl(imageUrl, pinterestUrl);

          this.mediaShare[1].href = pinterestUrl;
        }

        this.model = options.partModel || {};
      },

      render: function() {
        var device = app.get("device");
        var isTouchDevice = device.isTouchDevice();
        this.$el.html(
          this.template({
            contents: this.contents,
            reportLink: this.reportLink,
            mediaShare: this.mediaShare,
            isTouchDevice: isTouchDevice
          })
        );
        return this;
      },

      onReportImage: function(evt) {
        return utils.openPopup(
          $(evt.currentTarget).attr("href"),
          utils.trans("Report Story"),
          700,
          400
        );
      },

      onDismiss: function() {
        $("#picture-viewer").one("hidden.bs.modal", function() {
          $(".modal-backdrop").removeClass("black");
        });
        $("#picture-viewer").modal("hide");
      },

      onSharePinterest: function(evt) {
        var targetUrl = $(evt.currentTarget).attr("href");
        window.open(targetUrl);
      },

      onTapPicture: function(evt) {
        utils.stopEvent(evt);
        var device = app.get("device");
        var isTouchDevice = device.isTouchDevice();

        if (isTouchDevice) {
          $(".icon-wrapper").toggleClass("hidden");
        }
      }
    })
  );

  app.mixin(app.views.PictureViewer, "MediaSharing");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
