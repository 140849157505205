(function(window, _, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "Languages",
    Monaco.Collection.extend({
      model: app.models.Language,

      initialize: function() {
        this.on("sync", function() {
          // update the cached translated text because the lazy load will not be updated until now
          app.set("supported-languages", this);
        });
      },

      url: "/api/v3/internal/languages",

      resource: "supported-languages",

      cacheLocal: true,

      expireLocal: 1440 // 24 hours
    })
  );
})(window, _, Monaco, wattpad, window.app);
