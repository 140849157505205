(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "UserSettings",
    app.models.User.extend({
      idAttribute: "username",

      fields: [
        "username",
        "name",
        "email",
        "description",
        "avatar",
        "backgroundUrl",
        "createDate",
        "location",
        "birthdate",
        "gender",
        "genderCode",
        "facebook",
        "twitter",
        "website",
        "bubok",
        "smashwords",
        "lulu",
        "password",
        "verified",
        "verified_email",
        "highlight_colour",
        "html_enabled",
        "ambassador",
        "wattpad_squad",
        "is_staff",
        "isPrivate",
        "isMuted",
        { safety: ["isMuted", "isBlocked"] },
        "externalId",
        "beta_enabled",
        "appear_offline",
        "show_name",
        "has_password",
        {
          notificationSettings: ["alerts", "subscribe", "alertssub"]
        }
      ],

      urlRoot: "/api/v3/users/",

      resource: function() {
        return "user." + this.get("username");
      },

      _mutedUsers: [],

      mutedUsers: function() {
        return this._mutedUsers;
      },

      fetchIgnoreList: function() {
        var self = this;

        return Promise.resolve(
          $.ajax({
            url:
              "/apiv2/ignoreuser?id=" +
              this.get("id") +
              "&action=get_ignored_web",
            type: "POST"
          })
        )
          .then(function(data) {
            if (data.result === "SUCCESS") {
              self._mutedUsers = data.list.map(function(user) {
                return new app.models.UserModel({
                  id: user.ID,
                  username: user.NAME,
                  avatar:
                    wattpad.mediaServer + "/useravatar/" + user.NAME + ".jpg"
                });
              });
            }
            return self._mutedUsers;
          })
          .catch(function(error) {
            console.error(error);
            return false;
          });
      },

      updateAvatar: function(file) {
        return Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/apiv2/updateuserprofilepic",
            data: {
              image: file.parsed
            }
          })
        )
          .then(function(result) {
            return result;
          })
          .catch(function(error) {
            return error;
          });
      },

      updateBackground: function(file) {
        return Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/apiv2/updateuserbackground",
            data: {
              image: file.parsed
            }
          })
        )
          .then(function(result) {
            return result;
          })
          .catch(function(error) {
            return error;
          });
      },

      updateNotifications: function(data) {
        var formData,
          formSet = data,
          formElements = formSet[0].elements,
          submask = 0;

        // The following are bitwise operations, where each bit sets a particular type of alert, ie.
        // Someone writes on your message board
        // Someone starts following you
        // Someone comments on your story
        // Someone mentions you in a comment
        // You receive a message
        if (formElements["alertssub"].checked) {
          submask = submask | 0xfc;
        }

        formElements["alerts"].value = formElements["alerts"].checked ? 1 : 0;
        formElements["alertssub"].value = formElements["alertssub"].checked
          ? 1
          : 0;
        formElements["subscribe"].value = formElements["subscribe"].checked
          ? 1
          : 0;
        formElements["alertssubmask"].value = submask;
        formData = formSet.serialize();

        return Promise.resolve(
          $.ajax({
            type: "POST",
            url: "apiv2/usernotifications",
            data: formData,
            processData: false
          })
        )
          .then(function(data) {
            if (data.result === "SUCCESS") {
              return {
                alerts: data.alerts,
                alertssub: data.alertssub,
                subscribe: data.subscribe
              };
            }
          })
          .catch(function(error) {
            console.error(error);
            return error;
          });
      }
    })
  );
})(window, _, wattpad, window.app);
