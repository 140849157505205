(function(window, wattpad, app) {
  "use strict";
  app.router.add({
    "home(/:section)": [
      "home.index",
      {
        regexp: {
          section: /browse/
        }
      }
    ]
  });
})(window, window.wattpad, window.app);
