import discoverStories from "platform/discover/templates/discover-stories.hbs";
(function(window, wattpad, utils, app, _) {
  "use strict";

  app.add(
    "DiscoverStories",
    app.views.Base.extend({
      id: "discover-landing",

      template: discoverStories,

      events: {
        "tap .on-story-preview": "onStoryPreview",
        "click .on-story-preview": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};

        this.setElement(options.el);
        if (options.placeholder) {
          this.collection.once("sync", this.render, this);
        }
        this.collection.fetch();
      },

      render: function() {
        var data = {
          panels: this.collection.getPanelGroup(),
          dp: window.devicePixelRatio >= 2 ? 2 : 1
        };

        if (data.panels.length > 0) {
          // easy hack mobile panels (2x 1up)
          data.mobilePanels = [
            { story: this.collection.at(0).toJSON() },
            { story: this.collection.at(1).toJSON() }
          ];
        }

        // We won't have the title, so just replace the stories area.
        this.$(".loading-placeholder")
          .addClass("hidden")
          .after($(this.template(data)).find(".story-grid")); //only want to append the grid

        return this;
      },

      onStoryPreview: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        var story = this.collection.get(storyId);
        var $previewModal = $("#preview-modal");
        var preview = new app.views.BrowseStoryPreview({ model: story });

        if (!$previewModal.length) {
          return app.router.navigate(utils.formatStoryUrl(story.get("url")), {
            trigger: true
          });
        }

        $previewModal.empty().append(preview.render().$el);
        preview.openModal();
      }
    })
  );

  app.mixin(app.views.DiscoverStories, "LibraryManagement");
})(window, wattpad, wattpad.utils, window.app, _);
