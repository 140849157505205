(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Notifications",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.NotificationEvent,

      username: null,
      arrayKey: "feed",

      more: null,

      hasUnread: false,
      useLocalCache: true,

      fields: [
        {
          feed: [
            "id",
            "type",
            "createDate",
            "data",
            "isRead",
            "children",
            "group_url"
          ]
        },
        "nextUrl"
      ], // selector for what returns from the database

      initialize: function(collection, options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error("Notifications: options.username cannot be null");
        }
        this.username = options.username;
        if (
          options.useLocalCache !== undefined &&
          options.useLocalCache !== null
        ) {
          this.useLocalCache = options.useLocalCache;
        }

        this.on("sync", this.checkUnreads, this);

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      checkUnreads: function(collection, response, options) {
        $.ajax({
          url: this.defaultUrl(),
          type: "PUT",
          data: {
            username: this.username,
            read: 1
          }
        });

        //remove the red bubble on navbar
        $("#notifications-menu-badge, .notification-badge").css(
          "display",
          "none"
        );
        //  }
      },

      defaultUrl: function() {
        return "/api/v3/users/" + this.username + "/notifications";
      },

      // what Monaco uses for caching local storage
      resource: function() {
        if (this.useLocalCache) {
          return "user." + this.username + ".notifications";
        } else {
          return null;
        }
      }
    })
  );
})(window, _, wattpad, window.app);
