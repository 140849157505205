import discoverRows from "core/templates/collection-views/discover-rows.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "DiscoverRows",
    app.views.IncrementalList.extend({
      className: "discover-rows",
      template: discoverRows,
      itemView: app.views.DiscoverModule,
      containerId: "#module-collection",

      initialize: function() {
        this.setCollectionfilter(app.models.DiscoverModule.prototype.isValid);
        _.bindAll(this, "moduleInView");

        this.pageNum = 1;
      },

      renderCollection: function() {
        app.views.IncrementalList.prototype.renderCollection.apply(
          this,
          arguments
        );
        _.defer(this.afterRender.bind(this));
      },

      afterRender: function() {
        $(window).on("scroll", this.moduleInView);
        this.initCarousel();
      },

      getTemplateData: function() {
        var data = {
          hasRows: this.collection.length > 0,
          testGroups: wattpad.testGroups
        };

        return data;
      },

      getItemViewOptions: function() {
        return { pageNum: this.pageNum };
      },

      remove: function() {
        $(window).off("scroll", this.moduleInView);
      },

      onShowMore: function(event) {
        if (event) {
          utils.stopEvent(event);
        }

        this.pageNum++;

        this.$(".show-more-message").addClass("hidden");
        this.$(".loading-message").removeClass("hidden");

        var self = this;
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          self.$(".show-more-message").removeClass("hidden");
          self.$(".loading-message").addClass("hidden");

          // Carousel for the newly fetched items
          self.initCarousel();
        });
      },

      renderItem: function(model, $container, index) {
        app.views.IncrementalList.prototype.renderItem.apply(this, arguments);
      },

      initCarousel: function() {
        var options = {
          nav: true,
          navText: [
            '<span class="fa fa-left fa-wp-neutral-2 carousel-left"></span>',
            '<span class="fa fa-right fa-wp-neutral-2 carousel-right"></span>'
          ]
        };

        if (utils.rtlLanguage(parseInt(app.get("language"), 10))) {
          _.extend(options, { rtl: true });
        }

        if (app.get("device").is.mobile) {
          _.extend(options, {
            responsive: {
              0: { items: 1 },
              600: { items: 2 },
              900: { items: 3 }
            }
          });
        } else {
          _.extend(options, {
            items: 2,
            autoWidth: true, // don't let the carousel adjust the width of the items
            margin: 30 // margin-right(px) on item
          });
        }

        // Unhide carousel items once they're loaded
        this.$(".owl-carousel").one("initialized.owl.carousel", function() {
          $(this).removeClass("invisible");
        });

        this.$(".owl-carousel.no-loop, .owl-carousel.users").owlCarousel(
          options
        );

        // Loop items in modules except for the users' module
        _.extend(options, { loop: true });
        this.$(".owl-carousel:not(.no-loop):not(.users)").owlCarousel(options);
      },

      moduleInView: _.throttle(function() {
        var $elem = $(".discover-module"),
          self = this;
        this.visitedModule = this.visitedModule || {};

        $elem.each(function(i, module) {
          if (!self.visitedModule[i] && utils.isOnScreen($(module), 0.67)) {
            var moduleType = $(module)
                .children(".collection")
                .data("target"),
              moduleModel = self.collection.findWhere({
                type: moduleType
              }),
              data = moduleModel.get("data"),
              items =
                data.items.stories || data.items.lists || data.items.users,
              subjectType,
              subjectId;

            if (data.user) {
              subjectType = "user";
              subjectId = data.user.name;
            } else if (data.list) {
              subjectType = "reading_list";
              subjectId = data.list.id;
            } else if (data.tag) {
              subjectType = "tag";
              subjectId = data.tag.name;
            } else {
              subjectType = null;
              subjectId = null;
            }

            self.visitedModule[i] = module;
            window.te.push("event", "home", "module", null, "view", {
              module_type: moduleModel.get("type"),
              content_type: data.items.stories ? "stories" : "reading_lists",
              contentids: _.pluck(items, "id").join("|"),
              content_count: items.length,
              item_type: subjectType,
              itemid: subjectId
            });
            var typesPromoted = ["storiesByPromotedUser", "listPromoted"];
            if (_.indexOf(typesPromoted, moduleModel.get("type")) != -1) {
              window.te.push("event", "home", "promoted_module", null, "view", {
                module_type: moduleModel.get("type"),
                content_type: data.items.stories ? "stories" : "reading_lists",
                contentids: _.pluck(items, "id").join("|"),
                content_count: items.length,
                item_type: subjectType,
                itemid: subjectId
              });
            }
          }
        });
      }, 500)
    })
  );

  app.mixin(app.views.DiscoverRows, "InfiniteScroll");
})(window, wattpad, wattpad.utils, window.app);
