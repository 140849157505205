(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "Base",
    Monaco.View.extend({
      constructor: function(options) {
        this.events = this.events || {};
        var events = {};
        var tapKey = "tap .on-navigate";
        var clickKey = "click .on-navigate";
        const keydown = "keydown .on-navigate";
        var copyKey = "copy";
        events[tapKey] = "onNavigate";
        events[clickKey] = "stopEvent";
        events[keydown] = "onNavigateKeydown";

        _.each(this.events, function(value, key) {
          if (key.match(/tap(\s)+\.on-navigate/)) {
            delete events[tapKey];
          }
          if (key.match(/click(\s)+\.on-navigate/)) {
            delete events[clickKey];
          }
          if (key.match(/keydown(\s)+\.on-navigate/)) {
            delete events[keydown];
          }
        });
        //We don't want to overwrite any values
        //see desktop-web/header for example
        this.events = _.defaults(this.events, events);

        Monaco.View.prototype.constructor.apply(this, arguments);
      },

      initialize: function() {
        // setting up a wrapper for all child views to ensure they call 'afterRender'
        _.bindAll(this, "render", "afterRender");
        this.render = _.wrap(this.render, function(render) {
          render();
          this.afterRender();
          return this;
        });
      },

      render: function() {
        return this;
      },

      afterRender: function() {
        if (
          app.get("transition_timer") &&
          !app.get("transition_after_render")
        ) {
          var self = this;
          app.set(
            "transition_after_render",
            $(document).ajaxStop(function() {
              $(this).unbind("ajaxStop");
              self.endTransitionTimer();
            })
          );
        }
      },

      endTransitionTimer: function() {
        app.get("transition_timer").loaded();
        app.set("transition_after_render", null);
        app.set("transition_timer", null);
      },

      onNavigate: function(evt) {
        var url = $(evt.currentTarget)
            .attr("href")
            .replace(/[^\/]*\/\/[^\/]*/, ""),
          cmdOrCtrlKeyPressed =
            evt && evt.gesture
              ? evt.gesture.srcEvent.metaKey || evt.gesture.srcEvent.ctrlKey
              : false;

        var navMatches = $(evt.currentTarget)[0].className.match(
          /(navtest-create-.+)/
        );
        if (wattpad.utils.currentUser().authenticated() && navMatches) {
          utils.pushEvent({
            category: "myworks-nav",
            action: "click",
            label: navMatches[0]
          });
        }

        if (!cmdOrCtrlKeyPressed) {
          var timeSinceAppLoadInHours =
            (Date.now() - window.wattpad.pageLoadTimestamp) / 1000 / 60 / 60;

          // If it's been more than the configured MAX hours since our last SSR page load, redirect to the server for a fresh reload
          if (
            timeSinceAppLoadInHours >= window.wattpad.maxTimeSinceAppLoadInHours
          ) {
            window.location.href = url;
            return;
          }

          app.router.navigate(url, {
            trigger: true
          });
          $(".modal-backdrop").hide();
        }
      },
      onNavigateKeydown: event => {
        if (event.key === "Enter") {
          const currentTarget = event.currentTarget;

          if (currentTarget && currentTarget.href) {
            const url = currentTarget.href.replace(/[^\/]*\/\/[^\/]*/, "");

            app.router.navigate(url, {
              trigger: true
            });
          }
        }
      },
      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      },

      getDeepLinkPage: function() {
        return "homepage";
      },

      getDeepLink: function() {
        return utils.getAppLink(this.getDeepLinkPage(), this.model || {});
      }
    })
  );

  app.mixin(app.views.Base, "ErrorHandler");
})(window, wattpad, wattpad.utils, window.app);
