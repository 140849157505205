(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "BrowseStories",
    app.collections.IncrementalFetchNextUrl.extend({
      category: null,

      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "tags",
            "rankings",
            {
              user: ["name", "avatar"]
            },
            "description",
            "cover",
            "completed",
            "rating",
            "mature",
            "url",
            "numParts",
            "modifyDate",
            "firstPartId"
          ]
        },
        "total",
        "tags",
        "nextUrl"
      ],

      initialize: function(collection, options) {
        options = options || {};
        if (!options.category && !options.tag) {
          throw new Error("BrowseStories - Need to pass in a category");
        }

        this.filter = options.filter || "hot";
        this.category = options.category;
        this.nextUrl = this.nextUrl || options.nextUrl || null;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      parse: function(response) {
        this.tags = response.tags;
        return app.collections.IncrementalFetchNextUrl.prototype.parse.apply(
          this,
          arguments
        );
      },

      revertParse: function() {
        var result = app.collections.IncrementalFetchNextUrl.prototype.revertParse.apply(
          this,
          arguments
        );
        result.tags = this.tags;
        return result;
      },

      resource: function() {
        return (
          "stories.category." +
          wattpad.utils.slugify(this.category.get("name_english")) +
          "." +
          this.filter
        );
      },

      defaultUrl: function() {
        return (
          "/api/v3/stories?filter=" +
          this.filter +
          "&category=" +
          this.category.get("id")
        );
      }
    })
  );
})(window, _, wattpad, window.app);
