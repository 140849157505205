var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <div class=\"download-link\">\n                <a href=\""
    + alias4(((helper = (helper = helpers.downloadLink || (depth0 != null ? depth0.downloadLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"downloadLink","hash":{},"data":data}) : helper)))
    + "\" rel=\"nofollow\" target=\"_blank\">"
    + alias4(((helper = (helper = helpers.downloadText || (depth0 != null ? depth0.downloadText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"downloadText","hash":{},"data":data}) : helper)))
    + "</a>\n            </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<section class=\"modal fade\" id=\"get-app-modal\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"getAppModalTitle\" aria-hidden=\"true\">\n    <div class=\"modal-dialog\"><div class=\"modal-content\">\n\n        <header class=\"modal-header\">\n            <div class=\"pull-right close\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove-alt",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n            <h2 class=\"modal-title\" id=\"getAppModalTitle\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the app in seconds.",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n            <h3 class=\"modal-subtitle\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"It's easy as 1-2-3. Enter your cell phone number and we'll send you a link to install the Wattpad app.",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n        </header>\n\n        <div class=\"modal-body\">\n            <div class=\"form-group has-feedback\" data-attr=\"getApp\">\n                <div class=\"input-group\">\n                    <label class=\"control-label sr-only\" for=\"phonenumber\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Enter your phone number",{"name":"trans","hash":{},"data":data}))
    + "</label>\n                    <input id=\"phonenumber\" name=\"phonenumber\" class=\"form-control input-lg on-invalid\" type=\"text\">\n\n                    <span class=\"input-group-btn\">\n                        <button class=\"on-send-app-link btn btn-lg btn-orange\">\n                            <span class=\"hidden-if-sent\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Send",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                            <span class=\"shown-if-sent\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                        </button>\n                    </span>\n                </div>\n            </div>\n            <div class=\"feetext\">\n                <small>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Standard messaging fees apply",{"name":"trans","hash":{},"data":data}))
    + "</small>\n            </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.downloadLink : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n\n    </div>\n</section>\n";
},"useData":true});