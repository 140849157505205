import notificationsPage from "core/templates/notifications/notifications-page.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "NotificationsPage",
    app.views.RegionManager.extend({
      id: "notifications",
      className: "container",

      template: notificationsPage,

      events: {
        "tap .on-expand": "onExpandAggregation",
        "click .on-expand": "stopEvent"
      },

      initialize: function() {
        this.username = wattpad.utils.currentUser().get("username");
        this.collection = {
          notifications: new app.collections.Notifications([], {
            username: this.username
          }),
          requests: new app.collections.FollowRequests([], {
            username: this.username
          })
        };

        Handlebars.registerPartial(
          "core.notifications.notifications",
          function() {
            return "";
          }
        );
        Handlebars.registerPartial(
          "core.notifications.follow_requests",
          function() {
            return "";
          }
        );
        Handlebars.registerPartial(
          "core.invite_friends.components.invite_panel",
          function() {
            return "";
          }
        );
      },

      render: function() {
        // instantiate sub-view ( notifications collection-view )
        var self = this,
          promises = [],
          isPrivate = wattpad.utils.currentUser().get("isPrivate"),
          notificationsView,
          inviteView,
          requestsView;

        // always hide requests column first, will correct later if necessary
        this.$el.html(
          this.template({ hasRequests: false, testGroups: wattpad.testGroups })
        );

        promises = [this.collection.notifications.fetchNextSet()];
        if (isPrivate) {
          promises.push(this.collection.requests.fetchNextSet());
        }

        Promise.all(promises).then(function(response) {
          notificationsView = new app.views.Notifications({
            collection: self.collection.notifications
          });

          self.$("#notification-feed").empty();
          self.setRegion("#notification-feed", notificationsView.render());

          inviteView = new app.views.InvitePanel({
            action: "select_from_notifications",
            content: "invite_notifications"
          });

          self.$(".invite-panel-component").empty();
          self.setRegion(".invite-panel-component", inviteView.render());

          // only show follow requests if there's something
          if (isPrivate && self.collection.requests.total > 0) {
            self.$("#follow-requests-col").removeClass("hidden");
            requestsView = new app.views.FollowRequests({
              collection: self.collection.requests
            });
            self.$("#follow-requests").empty();
            self.setRegion("#follow-requests", requestsView.render());
          }
        });

        return this;
      },

      onExpandAggregation: function(evt) {
        var id = this.$(evt.currentTarget).data("id"),
          self = this,
          model = this.collection.notifications.get(id),
          children = model.get("children"),
          collection = new app.collections.Notifications([], {
            username: this.username,
            useLocalCache: false,
            turnOffPagination: true
          }),
          groupUrl;

        //TODO: Paging for children...right now, we want to fetch all of the children at once....
        groupUrl = collection.url() + "?event_ids=" + id + ",";
        _.each(children, function(child, index) {
          groupUrl += child;
          if (index < children.length - 1) {
            groupUrl += ",";
          }
        });
        collection.limit = children.length + 1;

        Promise.resolve(collection.fetchNextSet({ url: groupUrl })).then(
          function() {
            //API will always give us 'hasMore' as true in this scenario
            //We are not interested in more, so we say there is no more
            collection.more = false;
            var view = new app.views.Notifications({
              collection: collection
            });

            var header = "";
            if (model.get("type") === "follow") {
              header = wattpad.utils.trans(
                "You have <strong>%s new followers</strong>",
                collection.limit
              );
            } else if (model.get("type") === "vote") {
              header = wattpad.utils.trans(
                "%s people voted on <strong>%s - %s</strong>",
                collection.limit,
                model.get("data").story.title,
                model.get("data").story.part.title
              );
            } else if (model.get("type") === "forum_post") {
              header = wattpad.utils.trans(
                "There are <strong>%s new comments</strong> on club thread <strong>%s</strong>",
                collection.limit,
                model.get("data").discussion.discussion
              );
            } else if (model.get("type") === "message") {
              // Only broadcast messages can return children
              header = wattpad.utils.sprintf(
                wattpad.utils.ngettext(
                  "You have %s new announcement",
                  "You have %s new announcements",
                  collection.limit
                ),
                [collection.limit]
              );
            }
            self
              .$("#expand-modal .modal-header > .content > time")
              .html(wattpad.utils.calendar(model.get("createDate")));
            self
              .$("#expand-modal .modal-header > .count")
              .html(collection.limit);
            self
              .$("#expand-modal .modal-header > .content > .heading")
              .html(header);

            self.$("#expand-modal .modal-body").html(view.render().$el);
            self.$("#expand-modal").modal({ keyboard: true });
            self.$("#expand-modal").modal("show");
          }
        );
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
