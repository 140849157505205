import subscriptionModal from "platform/profile/templates/subscription-modal.hbs";
/* To use:
 * - Add a container to your template: <div id="subscription-modal" />
 * - Construct a subscription modal view
 * - Render the view into the container
 * - Call showModal() on the view
 */
(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "SubscriptionModal",
    app.views.Base.extend({
      template: subscriptionModal,

      className: "subscription-modal modal fade",

      events: {
        "tap .on-track-event": "sendTrackingEvent",
        "hidden.bs.modal": "sendTrackingEvent",
        "shown.bs.modal": "sendTrackingEvent"
      },

      initialize: function(options) {
        this.model.set("mode", "go_premium");
        app.views.Base.prototype.initialize.apply(this, arguments);
      },

      showModal: function() {
        this.$el.modal("show");
      },

      sendTrackingEvent: function(e) {
        var $target = $(e.currentTarget);
        var ctaType = null;
        var modalType = this.model.get("mode");

        var action;
        if (e.type === "shown") {
          action = "prompt";
        } else if (e.type === "hidden") {
          action = "dismiss";
        } else {
          action = $target.data("action-type");
        }

        var details = {
          country: _.get(wattpad.utils.currentUser().toJSON(), "country"),
          partid: this.model.get("id"),
          modal_type: modalType,
          cta_type: ctaType
        };

        window.te.push(
          "event",
          "subscriptions",
          "modal",
          null,
          action,
          details
        );
      },

      render: function() {
        this.$el.html(
          this.template(
            _.assign(this.model.toJSON(), {
              appUrl: utils.getAppUrl("homepage")
            })
          )
        );
        return this;
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
