var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"button-group inline-block relative pull-right\">\n    <button class=\"btn btn-grey dropdown-toggle\" data-toggle=\"dropdown\"\n            role=\"button\" aria-controls=\"activity-more-options\" aria-expanded=\"false\" aria-label=\"more options\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(alias1,"fa-more",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </button>\n    <div class=\"triangle\"></div>\n    <ul id=\"activity-more-options\" class=\"dropdown-menu align-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isValidMessage : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "    </ul>\n  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <li>\n          <a class=\"on-report\" role=\"button\" href=\"#\"\n            data-toggle=\"modal\" data-action=\"reportMessage\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-flag",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Report Message",{"name":"trans","hash":{},"data":data}))
    + "\n          </a>\n        </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.canDelete : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          <li>\n            <a class=\"on-delete\" role=\"button\" href=\"#\" data-toggle=\"modal\"\n              data-action=\"deleteMessage\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-trash",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Message",{"name":"trans","hash":{},"data":data}))
    + "\n            </a>\n          </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          <li>\n            <a class=\"on-hide\" role=\"button\" href=\"#\"\n              data-toggle=\"modal\" data-action=\"deleteEvent\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove-alt",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Remove",{"name":"trans","hash":{},"data":data}))
    + "\n            </a>\n          </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<footer class=\"clearfix\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hideMenu : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(1, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = ((helper = (helper = helpers.leftButton || (depth0 != null ? depth0.leftButton : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"leftButton","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n</footer>\n";
},"useData":true});