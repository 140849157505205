var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <li>\n              <a href=\"#\" role=\"button\" class=\"on-setfilter search-filter"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.stories : depth0)) != null ? stack1.filters : stack1)) != null ? stack1.mature : stack1), depth0),{"name":"stories.filters.mature","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-filter=\"mature\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",17,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled",17,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Include mature",{"name":"trans","hash":{},"data":data}))
    + "</a>\n            </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"search-filter-modal modal\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <header class=\"modal-header\">\n        <h1 class=\"modal-title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Search Filters",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n        <div class=\"close\" data-dismiss=\"modal\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",18,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </header>\n      <div class=\"modal-body\">\n        <div>\n          <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Last Updated",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n          <select id=\"last-updated-filters\">\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"any",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Anytime",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.age : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"24",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Today",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.age : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"168",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This Week",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.age : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"720",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This Month",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.age : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"8760",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This Year",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.age : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n          </select>\n        </div>\n        <div>\n          <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Length",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n          <select id=\"content-length-filters\">\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"any",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Any Length",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.length : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"1-10",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"1 - 10 Parts",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.length : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"10-20",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"10 - 20 Parts",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.length : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,"20-50",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"20 - 50 Parts",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.length : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || alias2).call(alias1,">50",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"> 50 Parts",{"name":"trans","hash":{},"data":data}),(depth0 != null ? depth0.length : depth0),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n          </select>\n        </div>\n\n        <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Content",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n        <ul class=\"lists-menu list-unstyled\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Content",{"name":"trans","hash":{},"data":data}))
    + "\">\n          <li>\n            <a href=\"#\" role=\"button\" class=\"on-setfilter search-filter"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.stories : depth0)) != null ? stack1.filters : stack1)) != null ? stack1.complete : stack1), depth0),{"name":"stories.filters.complete","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-filter=\"complete\" >"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",17,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled",17,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Only show completed",{"name":"trans","hash":{},"data":data}))
    + "</a>\n          </li>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMatureUser : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});