(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "StoryPartCreateModel",
    app.models.BaseModel.extend({
      defaults: {
        title: "Untitled Part 1",
        text: "<p><br></p>",
        copyright: 1,
        flag1: 0,
        groupid: 0,
        req_type: "new",
        id: "new",
        cover_url: "",
        error_count: 0,
        errors: [],
        story_url: "",
        skip_title: 0
      },

      fields: [
        "id",
        "title",
        "category1",
        "category2",
        "language",
        "title",
        "text",
        "flag1",
        "groupid",
        "author",
        "private",
        "deleted",
        "text_hash"
      ],

      v2CreateUrl: "/apiv2/newstory",
      v2DeleteUrl: "/apiv2/deletestory",
      v2UpdateUrl: "/apiv2/editstory",
      urlRoot: "/api/v3/story_parts/",

      initialize: function() {
        this.listenTo(this, "change:text", this.saveLocalText);
      },

      resource: function() {
        return "part." + parseInt(this.get("id"), 10) + ".metadata";
      },

      parse: function(model) {
        this.set("last_text_hash", model.text_hash);
        return app.models.BaseModel.prototype.parse.apply(this, arguments);
      },

      save: function(model, xhrOptions) {
        var self = this;

        // Initialize the failed save counter if it doesn't already exist
        this.failedSaveCount = this.failedSaveCount || 0;
        self.trigger("partModel:save-online");

        // If there is already a save request in progress, kill it so we don't end up with a queue of save requests
        if (this.saveXhr) {
          this.saveXhr.abort();
        }

        this.saveXhr = app.models.BaseModel.prototype.save.apply(
          this,
          arguments
        );
        this.saveXhr.done(function(response) {
          self.saveXhr = null;
          self.failedSaveCount = 0;

          // if saving was previously in the offline state, switch it back to online
          if (!self.saveOnline) {
            self.trigger("partModel:save-online");
            self.saveOnline = true;
            self.failedSaveCount = 0;
          }
        });
        this.saveXhr.fail(function(response) {
          if (response.statusText === "abort") {
            // this is necessary to avoid the next save conflicting a previously successfully POSTed, but not completed xhr
            self.unset("last_text_hash");
          }

          self.failedSaveCount++;

          if (self.failedSaveCount >= 4) {
            self.saveOnline = false;
            self.trigger("partModel:save-offline");
          }
        });
        return this.saveXhr;
      },

      publish: function() {
        this.set({
          publish: 1,
          part_id: this.get("id"),
          draft: 0
        });
        return this.save();
      },

      unpublish: function() {
        return this.save(
          {
            publish: 0,
            part_id: this.get("id"),
            draft: 1
          },
          {
            url: this.url()
          }
        );
      },

      saveLocalText: function() {
        try {
          window.localStorage.setItem(
            parseInt(this.get("groupid"), 10) +
              "." +
              parseInt(this.get("id"), 10) +
              ".storyText",
            this.get("text"),
            true
          );
        } catch (e) {
          // For the time being there is no UI bound to this event, but there eventually will be
          app.trigger("works:storytext-localsave-failed", this);
        }
      }
    })
  );

  app.mixin(app.models.StoryPartCreateModel, "ApiV2");
})(window, _, wattpad, window.app);
