(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * Used to handle ads that follow the user while scrolling along the page
   *
   * This will interact with elements which have the 'sticky-ad' class.
   * These elements should have the data attributes 'start-selector' and 'end-selector' to identify the two
   * elements that should bound the sticky ad.
   *
   * For example, the '#start' selector will stop the sticky ad from moving above the element with id="start"
   * and the '#end' selector will stop the sticky ad from moving below the element with id="end".
   * This also works with classes, but will only select the first element with the given class.
   *
   * i.e.
   *  <div class="sticky-ad-container">
   *    {{{ advertisement ...
   *        sticky=true
   *        stickyStart='.sticky-ad-container'
   *        stickyEnd='.col-sm-8' }}}
   *  </div>
   *
   * You can also use the same selector for both start and end to have the ad only move within the bounds of
   * a single element.
   */
  app.add(
    "StickyAds",
    Monaco.Mixin.create({
      render: function(next) {
        var self = this;

        // want the DOM to load it's contents before controlling sticky ads
        var renderedView = next();

        $(window).on(
          "scroll.screen_" + self.cid,
          self.handleStickyAds.bind(self)
        );

        return renderedView;
      },

      remove: function(next) {
        $(window).off("scroll.screen_" + this.cid);
        next();
      },

      handleStickyAds: function(noop) {
        $.each($(".sticky-ad"), function(index, el) {
          // Selectors for the start and end elements
          var startSelector = $(el).data("start-selector");
          var endSelector = $(el).data("end-selector");

          // rect for the ad itself and it's vertical size
          var rect = el.getBoundingClientRect();
          var adHeight = rect.bottom - rect.top;

          // middle of the page: this is how we know if the ad is centered in the view
          var midPage =
            (window.innerHeight || document.documentElement.clientHeight) / 2;

          // the start and end point: start = top of the start element, end = bottom of the end element
          // the fallback start and end points are is the document body's top and bottom
          var start =
            startSelector && $(startSelector).length
              ? $(startSelector)[0].getBoundingClientRect().top
              : document.body.getBoundingClientRect().top;
          var end =
            endSelector && $(endSelector).length
              ? $(endSelector)[0].getBoundingClientRect().bottom
              : document.body.getBoundingClientRect().bottom;

          if (start >= midPage - adHeight / 2) {
            // middle of page is above the start position of the ad unit so keep it stuck at the start
            $(el).css({ position: "absolute", top: "0" });
            // ADSX-1259 -125 as half the ad so it can start sticky properly at the botom
          } else if (midPage > start && midPage < end - 125 - adHeight / 2) {
            // the ad is now on the center of the page and can be fixed
            // to the viewable top of the screen now (DW header + funbar = 114 px)
            $(el).css({
              position: "fixed",
              top: "114px"
            });
          } else {
            // middle of page reached the end position so keep it stuck at the end
            $(el).css({
              position: "absolute",
              //ADSX-1259 unstick earlier for comment ad.  250 is the height of the comment ad.
              top: end - start - adHeight - 250 + "px"
            });
          }
        });

        noop();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
