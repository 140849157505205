var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div id=\"module-collection\" class=\"collection\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.modules : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n\n  <div id=\"preview-modal\"></div>\n\n  <div class=\"show-more center-text hidden\">\n      <button class=\"btn btn-link on-showmore\">\n          <span class='show-more-message'>\n              <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n              <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n          </span>\n          <span class='loading-message hidden'>\n              "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n              <span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n          </span>\n      </button>\n  </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <div class=\"discover-module\">\n"
    + ((stack1 = container.invokePartial(partials["core.collection_views.discover_module"],depth0,{"name":"core.collection_views.discover_module","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "  <p class=\"no-item\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"No recommendations available.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"home\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasRows : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(alias1,"WPModal","modal-home",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});