(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add("Authsignup", Monaco.Model.extend({}));

  app.mixin(
    app.models.Authsignup,
    "ValidationModel",
    "SignupModel",
    "GetAppValRules"
  );
})(window, _, wattpad, wattpad.utils, window.app);
