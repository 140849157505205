(function(window, _, wattpad, app, utils) {
  "use strict";

  app.add(
    "SupportTicket",
    app.models.BaseModel.extend({
      cacheLocal: false,

      urlRoot: "/v4/support/tickets",

      defaults: {
        name: null, // name & email temporary holds value
        email: null, // don't send it to the api
        comment: "",
        reportType: "",
        reason: "",
        ticket_form_id: null,
        username: null,
        custom_fields: []
      },

      fields: ["comment", "ticket_form_id", "custom_fields"],

      initialize: function(option) {
        if (!wattpad.utils.currentUser().authenticated()) {
          this.validationRules = {
            name: [
              { func: "isRequired", msg: utils.trans("Name is required.") },
              {
                func: "hasLetter",
                msg: utils.trans("Please enter your name or Wattpad username.")
              }
            ],
            email: [
              { func: "isRequired", msg: utils.trans("Email is required.") },
              { func: "isEmail", msg: utils.trans("Invalid email.") }
            ]
          };
        }
      },

      // used to custom fields created by community on Zendesk,
      //     a custom field entry looks like this:
      //     { id: 123456789, value: 'a value for the custom field' }
      setCustomField: function(fieldId, val) {
        var customFields, index, newEntry;

        newEntry = { id: fieldId, value: val };
        customFields = this.get("custom_fields");
        index = _.findIndex(customFields, { id: fieldId });

        if (index < 0) {
          customFields.push(newEntry);
        } else {
          customFields[index] = newEntry;
        }
      },

      // requester info stored in `name` and `email` by validation model
      // need to move it into `requester` field for zendesk
      setRequester: function() {
        if (this.has("name") && this.has("email")) {
          this.set("requester", {
            name: this.get("name"),
            email: this.get("email")
          });
          this.unset("name");
          this.unset("email");
        }
      }
    })
  );

  app.mixin(app.models.SupportTicket, "ValidationModel");
})(window, _, wattpad, window.app, wattpad.utils);
