(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Following",
    app.collections.Base.extend({
      model: app.models.User,

      init: function(collection, options) {
        options = options || {};
        this._user = options.user;
      },

      //=== ENTITIES ===//
      _user: null,

      user: function() {
        return this._user;
      },

      //=== MONACO ===//
      resource: function() {
        return "user." + this.user().get("username") + ".following";
      },

      url: function() {
        return "/api/v3/users/" + this.user().get("username") + "/following";
      },

      _clearCache: function(currentUserName, profileUserName) {
        // clear cached data as necessary
        app.local.clear("user." + currentUserName);
        app.local.clear("user." + currentUserName + ".following");
        app.local.clear("user." + currentUserName + ".profile.following");

        app.local.clear("user." + profileUserName);
        app.local.clear("user." + profileUserName + ".profile.followers");
      },

      follow: function(user) {
        var self = this;

        if (typeof user === "string") {
          return this._doFollowRequest(user).done(function() {
            self._clearCache(self.user().get("username"), user);
          });
        } else if (
          typeof user === "object" &&
          user instanceof app.models.User
        ) {
          return this._doFollowRequest(user.get("username")).done(function() {
            self._clearCache(self.user().get("username"), user.get("username"));
          });
        }

        return $.Deferred()
          .reject()
          .promise();
      },

      _doFollowRequest: function(username) {
        return $.post(this.url() + "?users=" + username);
      },

      unfollow: function(user) {
        var self = this;

        if (typeof user === "string") {
          return this._doUnFollowRequest(user).done(function() {
            self._clearCache(self.user().get("username"), user);
          });
        } else if (
          typeof user === "object" &&
          user instanceof app.models.User
        ) {
          return this._doUnFollowRequest(user.get("username")).done(function() {
            self._clearCache(self.user().get("username"), user.get("username"));
          });
        }

        return $.Deferred()
          .reject()
          .promise();
      },

      _doUnFollowRequest: function(username) {
        return $.ajax(this.url() + "/" + username, {
          type: "DELETE"
        });
      }
    })
  );
})(window, _, wattpad, window.app);
