(function(window, _, wattpad, app, utils) {
  "use strict";

  app.add(
    "StoryCreateModel",
    app.models.StoryModel.extend({
      defaults: {
        cover: "",
        title: "",
        description: "",
        skip_title: 0,
        tags: []
      },

      fields: [
        "id",
        "title",
        "voteCount",
        "readCount",
        "commentCount",
        "description",
        "url",
        "user",
        "cover",
        "cover_timestamp",
        "completed",
        "numParts",
        "lastPublishedPart",
        "cover_source_id",
        "cover_partner",
        {
          parts: [
            "id",
            "title",
            "url",
            "draft",
            "modifyDate",
            "createDate",
            "length",
            "videoId",
            "photoUrl",
            "user",
            "pages",
            "text_url",
            "rating",
            "voted",
            "private",
            "flag1",
            "dedication",
            "voteCount",
            "readCount",
            "commentCount",
            "source",
            "deleted",
            "text_hash",
            "hasBannedImages",
            "hasBannedHeader",
            "wordCount",
            "scheduledPublishDatetime"
          ]
        },
        "tags",
        "categories",
        "rating",
        "ratingLocked",
        "mature",
        "language",
        "copyright",
        "deleted",
        "hasBannedCover"
      ],

      v2Fields: [
        "id",
        "title",
        "description",
        "language",
        "copyright",
        "textids",
        "complete",
        "category1",
        "dedication",
        "human_rating",
        "transaction_key",
        "skip_title"
      ],
      v2CreateUrl: "/apiv2/newstorygroup",
      v2UpdateUrl: "/apiv2/editstorygroup",
      v2DeleteUrl: "/apiv2/deletestorygroup",

      parse: function(data) {
        if (data.parts) {
          data.textids = _.pluck(data.parts, "id").join(",");
        }

        data.published = false;
        _.each(data.parts, function(p) {
          p.stats = p.readCount || p.voteCount || p.commentCount ? true : false;
          p.flag1 = p.private ? 1 : 0;
          p.source = p.source ? p.source.url : "";
          data.published = data.published || !p.draft;
        });

        return app.models.StoryModel.prototype.parse.call(this, data);
      },

      initialize: function(option) {
        this.validationRules = {
          description: [
            {
              func: "maxLength",
              length: 2000,
              msg: utils.trans(
                "Your description may be no longer than 2000 characters."
              )
            }
          ],

          title: [
            {
              func: "maxLength",
              length: 80,
              msg: utils.trans(
                "Your title may be no longer than 80 characters."
              )
            },
            {
              func: "isRequired",
              msg: utils.trans("Please enter a title for your story.")
            },
            {
              func: "isNotEq",
              val: utils.trans("Untitled Story"),
              msg: utils.trans("Please enter a title for your story.")
            }
          ]
        };
      },

      resource: function() {
        return "story." + parseInt(this.get("id"), 10) + ".metadata";
      },

      expireLocal: 1
    })
  );

  app.mixin(app.models.StoryModel, "ApiV2Patch", "ApiV2", "ValidationModel");
})(window, _, wattpad, window.app, wattpad.utils);
