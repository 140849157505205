(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Archive",
    app.collections.NodeLibrary.extend({
      resource: function() {
        return "user." + this.user.get("username") + ".archive.new";
      },

      defaultUrl: function() {
        return "/api/v3/users/" + this.user.get("username") + "/archive";
      },

      libraryType: "archive",

      reportAdd: function(id) {
        window.te.push("event", "wattpad", "story", "archive", "add", {
          storyid: id
        });
      },

      reportRemove: function(id) {
        window.te.push("event", "wattpad", "story", "archive", "remove", {
          storyid: id
        });
      }
    })
  );
})(window, _, wattpad, window.app);
