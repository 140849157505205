(function(window, _, wattpad, app) {
  "use strict";

  /**
   * APIv2 Fallback Mixin
   * Automatically will choose the correct v2 API when saving the model.
   * Known collision with Backbone.save().
   * Requires one or more of: v2CreateUrl (string | function),
   *                          v2UpdateUrl (string | function),
   *                          v2Url (string | function)
   */
  app.add(
    "ApiV2",
    Monaco.Mixin.create({
      save: function(attributes, options, next) {
        options = options || {};

        // If URL is already set, we are done.
        if (options.url) {
          return next(attributes, options);
        }

        // If the mixin was used, make sure it was on purpose.
        if (
          !this.v2CreateUrl &&
          !this.v2UpdateUrl &&
          !this.v2DeleteUrl &&
          !this.v2Url
        ) {
          throw new Error(
            "No v2 API Urls were found on this object. " +
              _.result(this, "resource")
          );
        }

        // If there is no ID, use the Create URL
        if (!this.get("id") || this.get("id") === "new") {
          options.url =
            _.result(this, "v2CreateUrl") ||
            _.result(this, "v2Url") ||
            _.result(this, "url");
          this.set("req_type", "new");

          // If the ID is set, use the Update URL
        } else {
          options.url =
            _.result(this, "v2UpdateUrl") ||
            _.result(this, "v2Url") ||
            _.result(this, "url");
          this.set("req_type", "edit");
        }

        // V2 APIs can't accept application/json
        options.data = this.toJSON();
        options.emulateJSON = true;
        options.type = "POST";

        // This mixin overrides the default `save` function.
        // Need to call `next` so that the ajax fetch actually occurs.
        return next(attributes, options);
      },

      destroy: function(options, next) {
        options = options || {};

        // If URL is already set, we are done.
        if (options.url) {
          return next(options);
        }

        // If the mixin was used, make sure it was on purpose.
        if (
          !this.v2CreateUrl &&
          !this.v2UpdateUrl &&
          !this.v2DeleteUrl &&
          !this.v2Url
        ) {
          throw new Error(
            "No v2 API Urls were found on this object. " +
              _.result(this, "resource")
          );
        }

        options.url =
          _.result(this, "v2DeleteUrl") ||
          _.result(this, "v2Url") ||
          _.result(this, "url");

        // V2 APIs can't accept application/json
        options.data = this.toJSON();
        options.emulateJSON = true;
        options.type = "POST";

        // This mixin overrides the default `destroy` function.
        // Need to call `next` so that the ajax request actually occurs.
        return next(options);
      }
    })
  );
})(window, _, wattpad, window.app);
