(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "NewMessageReplies",
    app.collections.IncrementalFetch.extend({
      model: app.models.Message,

      cacheLocal: false, // always clear the replies

      arrayKey: "replies",

      fields: [
        {
          replies: [
            "id",
            "body",
            "createDate",
            "from",
            "isReply",
            "parentId",
            "isOffensive",
            "eventId"
          ]
        },
        "message",
        "nextUrl"
      ], // selector for what returns from the database

      nextUrl: null, // null -> first fetch, string -> there's more, false -> no more

      initialize: function(collection, options) {
        options = options || {};
        this.parentId = options.parentId;
        this.messageOwnerName = options.messageOwnerName;

        this.on("add remove", function() {
          app.local._clearItem(this.resource());
        });

        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      _prepareModel: function(attrs, options) {
        if (!attrs.ownername) {
          attrs.ownername = this.messageOwnerName || attrs.from.name;
        }
        return app.collections.IncrementalFetch.prototype._prepareModel.apply(
          this,
          arguments
        );
      },

      parse: function(response) {
        // set the nextUrl if any for loading more replies
        if (
          response.hasOwnProperty("nextUrl") === true &&
          response.nextUrl !== ""
        ) {
          this.nextUrl = response.nextUrl;
        } else {
          this.nextUrl = false;
        }

        // grab the parent message
        if (response.hasOwnProperty("message") === true) {
          this.parentMessage = new app.models.Message({
            id: response.message.id,
            body: response.message.body,
            createDate: response.message.createDate,
            from: response.message.from,
            isReply: response.message.isReply,
            parentId: response.message.parentId,
            ownername: response.message.from.name
          });
        }
        return app.collections.IncrementalFetch.prototype.parse.apply(
          this,
          arguments
        );
      },

      revertParseExtras: function(result) {
        result.nextUrl = this.nextUrl;
      },

      fetchNextSet: function(options) {
        // first load
        if (this.nextUrl === null) {
          return this.fetch(
            _.extend(
              {
                data: {
                  limit: this.limit,
                  fields: this.getFieldsString().data.fields,
                  direction: 1,
                  omitParentReply: 1,
                  replyId: options.replyId || null
                },
                fresh: true,
                remove: false
              },
              options || {}
            )
          );

          // show more
        } else if (this.nextUrl !== false) {
          return this.fetch(
            _.extend(
              {
                fresh: true,
                remove: false
              },
              options || {}
            )
          );
        }
        // if fetch conditions are not met, return a rejected promise
        // TODO: update to A+/es6 compliance
        return $.Deferred()
          .reject()
          .promise();
      },

      comparator: function(aReply) {
        // older === lower index
        return moment(aReply.get("createDate")).unix();
      },

      hasMore: function() {
        return this.nextUrl !== false;
      },

      url: function() {
        if (typeof this.nextUrl !== "string") {
          return "/v4/messages/" + this.parentId;
        }
        return this.nextUrl;
      },

      // what manco uses for caching local storage
      resource: function() {
        return "user.messages." + this.parentId + ".replies";
      }
    })
  );
})(window, _, wattpad, window.app);
