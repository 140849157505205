var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <button type=\"button\" class=\"btn btn-link btn-teal on-copy\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Copy Embed Code",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<button type=\"button\" class=\"btn btn-link pull-right close\" data-dismiss=\"modal\">\n    <span aria-hidden=\"true\" class=\"fa fa-remove\"></span><span class=\"sr-only\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Close",{"name":"trans","hash":{},"data":data}))
    + "</span>\n</button>\n<div id=\"quote-embed-modal\">\n    <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Embed Preview",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n    <iframe id=\"quote-embed-iframe\" src=\"https://embed.wattpad.com/quote/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "?quote_text="
    + alias3((helpers.urlEncode || (depth0 && depth0.urlEncode) || alias2).call(alias1,(depth0 != null ? depth0.quoteText : depth0),{"name":"urlEncode","hash":{},"data":data}))
    + "\"\n            frameborder=\"0\" allowfullscreen=\"\"></iframe>\n    <div class=\"on-select embed-code\">\n      <span id=\"embed-url\">\n          &lt;iframe width=\"500\" height=\"280\" frameborder=\"0\" allowfullscreen=\"\"\n          src=\"https://embed.wattpad.com/quote/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "?quote_text="
    + alias3((helpers.urlEncode || (depth0 && depth0.urlEncode) || alias2).call(alias1,(depth0 != null ? depth0.quoteText : depth0),{"name":"urlEncode","hash":{},"data":data}))
    + "\"\n          &gt;&lt;/iframe&gt;\n      </span>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.copyExec : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});