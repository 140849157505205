import shareEmbed from "platform/components/templates/modal/share-embed.hbs";
import worksStatusBanner from "platform/create/templates/works-status-banner.hbs";
import worksItemEditableTag from "platform/create/templates/works-item-editable-tag.hbs";
import worksItemDetails from "platform/create/templates/works-item-details.hbs";
import worksItemCharacter from "../../../../desktop-web-create/application/create/templates/works-character-item.hbs";
import worksTableOfContents from "../../../../desktop-web-create/application/create/templates/works-table-of-contents.hbs";
import worksContestBanner from "../../../../desktop-web-create/application/create/templates/works-contest-banner.hbs";
import { EditorTabs } from "../../../../../components/views/works-item-details/constants";
import { MODAL_ID } from "../../../../../components/shared-components";
import {
  ELIGIBLE_FLAG,
  NEAR_ELIGIBLE_FLAG,
  SUBMITTED_FLAG
} from "../../../../../components/views/contests/ContestForm/constants";

import setPropsForReactInHb from "../../../../../helpers/handlebars/set-props-for-react-in-hb";
import formatScheduledPartDate from "../../../../../helpers/format-scheduled-part-date";

(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Desktop-Web My Works Item Details -- Parts Listing and Management for a Work Item
   *
   * Editing meta-story information and story parts of a single story group
   * Manages the rendering of individual parts and the associated events
   * Note: the new version of WorksItemDetails.
   *
   * @Class WorksItemDetails
   * @constructor
   */
  app.add(
    "WorksItemDetails",
    app.views.Base.extend({
      // Properties
      // ----------
      events: {
        // Saving story details
        "tap .on-edit-save": "onEditSaveSelected",
        "tap .on-publish": "onPublish",
        "tap .on-edit-cancel": "onEditCancelSelected",

        // MAIN EDIT FORM

        // Editing covers
        "mouseenter .on-cover-edit-icon": "onCoverEditIconEnter",
        "mouseleave .image-placeholder": "onCoverEditLeave",

        // Story title and descriptions
        "keyup .story-title": "onStoryTitleKeyUp",
        "focus .story-title": "onStoryTitleFocus",
        "blur .story-title": "onStoryTitleBlur",
        "blur .story-description": "onStoryDescriptionBlur",
        "paste [contenteditable]": "pastePlainText", // also applies for tags

        // Story category
        "change #categoryselect": "onCategorySelected",

        // Characters
        "keyup .character-input": "onCharacterInputKeyUp",
        "tap .on-add-character": "onAddCharacter",
        "click .on-add-character": "stopEvent",
        "tap .on-remove-character": "onRemoveCharacter",
        "click .on-remove-character": "stopEvent",

        // Tags
        "tap .on-add-tag": "onAddTagSelected",
        "blur .on-tag-input": "onTagInputBlurred",
        "keydown .on-tag-input": "onTagInputKeydown",

        // Mature rating
        "change #mature-switch": "ratingChange",

        // Adding new parts
        "tap .on-new-part": "onNewPartClicked",
        "tap .on-stats": "onStatsClicked",

        // Deleting stories and parts
        "tap .on-delete-story": "onDeleteStoryClicked",
        "tap .on-delete-published-story": "onDeletePublishedStoryClicked",

        // Embed this story modal
        "tap .on-select": "onSelectAll",
        "tap .on-embed": "onEmbedClicked",

        // Unpublishing and deleting story groups/parts
        "tap .on-delete-part": "onDeletePartClicked",
        "tap .on-do-delete-part": "onDoDeletePart",
        "tap .on-delete-published-part": "onDeletePublishedPartClicked",
        "submit #delete-story-form": "onDoDeleteStory",

        "tap .on-change-part-schedule": "onChangePartScheduleClicked",
        "click .on-change-part-schedule": "stopEvent",

        "tap .on-unpublish-part": "onUnpublishPartClicked",
        "tap .on-unpublish-story": "onUnpublishStoryClicked",
        "tap .on-do-unpublish-part": "onDoUnpublishPart",
        "tap .on-do-unpublish-story": "onDoUnpublishStory",

        // Writer tips popups
        "click .on-okay": "stopEvent",
        "tap .on-okay": "onPopoverOk",
        "click .popover-icon": "stopEvent",
        "tap .popover-icon": "onPopover",
        "mousedown .on-trigger-tooltip": "onTriggerTooltip",
        "click .on-trigger-tooltip": "stopEvent",
        "tap .on-trigger-tooltip": "stopEvent",
        "click  .on-contest-popover": "onContestFormPopover",
        "tap  .on-contest-popover": "onContestFormPopover",

        // Modal functionality
        "tap .on-edit-close": "onEditClose",

        // view as reader story landing version
        "click .on-view-as-reader": "stopEvent",
        "tap .on-view-as-reader": "viewAsReader",

        // acknowledge
        "click #acknowledge-checkbox": "onAcknowledge"
      },

      templates: {
        worksItemDetails: worksItemDetails,
        character: worksItemCharacter,
        tag: worksItemEditableTag,
        status: worksStatusBanner,
        contestBanner: worksContestBanner
      },

      initialize: function(options) {
        options = options || {};

        _.bindAll(this, "onCloseTooltip");

        this.redirectParams = wattpad.utils.getRedirectParams();

        this.defaultStoryTitle = utils.trans("Untitled Story");

        this.isNew = options.isNew || false;
        this.showTips = options.showTips || false;
        this.isPublish = options.isPublish || false;
        this.isPreview = options.isPreview || false;
        this.partModel = options.partModel || null;
        this.model = options.model;
        this.storyLabelsModel = options.storyLabelsModel;
        this.didEditCharacters = false;
        this.charactersLimit = 20;
        this.maxNumParts = wattpad.utils.currentUser().getMaxNumParts();
        this.prefilledTag = options.prefilledTag || null;
        this.storyStatus = options.storyStatus;
        this.wattysEligibility = options.eligibility;
        this.displayWattysBanner =
          options.eligibility === ELIGIBLE_FLAG ||
          options.eligibility === NEAR_ELIGIBLE_FLAG ||
          options.eligibility === SUBMITTED_FLAG;

        // Set #wattysXXXX contest as default, stickied autocompleted tag
        this.SUGGESTED_WRITER_TAGS =
          this.wattysEligibility === ELIGIBLE_FLAG
            ? [wattpad.wattysActiveKey]
            : [];

        if (this.isNew) {
          this.model.set("title", this.defaultStoryTitle);
        }

        Handlebars.registerPartial(
          "desktop.create.works_item_editable_tag",
          this.templates.tag
        );
        Handlebars.registerPartial(
          "desktop.components.modal.share_embed",
          shareEmbed
        );
        Handlebars.registerPartial(
          "desktop.create.works_table_of_contents",
          worksTableOfContents
        );
        Handlebars.registerPartial(
          "desktop.create.works_contests",
          worksContestBanner.bind(this, {
            storyId: this.model.id
          })
        );

        Handlebars.registerPartial(
          "desktop.create.works_character_item",
          worksItemCharacter
        );
      },

      render: function() {
        var renderModel = this.model.toJSON();
        var self = this;
        renderModel.published = false;

        // Set flags to show stats and acknowledge part status
        _.each(renderModel.parts, function(p) {
          p.stats = p.readCount || p.VoteCount || p.commentCount ? true : false;
          renderModel.published =
            renderModel.published || (!p.draft && !p.deleted);

          // Publishing Scheduler properties
          p.isScheduledPart = p.hasOwnProperty("scheduledPublishDatetime");
          p.formattedScheduledPublishDatetime = p.isScheduledPart
            ? formatScheduledPartDate(p.scheduledPublishDatetime)
            : null;

          if (
            p.isScheduledPart &&
            self.redirectParams.triggerScheduleSuccess &&
            self.redirectParams.partId == p.id
          ) {
            setPropsForReactInHb(p, "scheduleSuccessModalSettings", {
              storyId: renderModel.id,
              partId: p.id,
              title: p.title,
              scheduledPublishDatetime: p.scheduledPublishDatetime,
              showModal: true
            });
          }
        });

        renderModel.descriptionRemaining =
          2000 - renderModel.description.length;
        if (wattpad?.testGroups?.UNSUPPORTED_LANGUAGES) {
          renderModel.allLanguages = wattpad.supportedLangs;
          renderModel.languages = _.filter(wattpad.supportedLangs, function(
            language
          ) {
            return language?.categories?.includes("STORY");
          });
        } else {
          renderModel.languages = _.filter(wattpad.supportedLangs, function(
            language
          ) {
            return language.id !== 52;
          });
        }

        renderModel.categories = _.reduce(
          app.get("categories").toJSON(),
          function(result, category) {
            if (_.contains(category.roles, "writing")) {
              result.push(category);
            }

            return result;
          },
          []
        );

        if (wattpad?.testGroups?.UNSUPPORTED_LANGUAGES) {
          const storyLanguageId = this.isNew
            ? wattpad.utils.currentUser().get("language")
            : renderModel.language;
          const storyLanguage = _.find(renderModel.allLanguages, function(
            lang
          ) {
            return lang.id == storyLanguageId;
          });
          if (!storyLanguage?.categories?.includes("STORY")) {
            renderModel.languages.push(storyLanguage);
            renderModel.isSupportedLanguage = false;
          } else {
            renderModel.isSupportedLanguage = true;
          }
          renderModel.language = {
            id: storyLanguage?.id,
            name: storyLanguage?.name
          };
        } else {
          renderModel.language = {
            id:
              renderModel.language ||
              wattpad.utils.currentUser().get("language"),
            name: _.result(
              _.find(renderModel.languages, function(lang) {
                return lang.id == renderModel.language;
              }),
              "name"
            )
          };
        }

        renderModel.everyone = !renderModel.mature && !renderModel.ratingLocked;
        renderModel.writerMature =
          renderModel.mature && !renderModel.ratingLocked;
        renderModel.maturePrivate = renderModel.private && renderModel.mature;
        renderModel.communityMature =
          renderModel.mature && renderModel.ratingLocked;

        // Set Background Cover
        renderModel.bcover = renderModel.cover.replace("/cover/", "/bcover/");

        renderModel.tooManyTagsInTheKitchen = _.size(renderModel.tags) > 20;

        var needCover,
          needTitle,
          needDescription,
          needCategory,
          needTag,
          needAcknowledge;

        if (this.isPublish) {
          if (renderModel.cover === "") {
            needCover = true;
          }
          if (
            renderModel.title.trim() === "" ||
            renderModel.title.trim() === this.defaultStoryTitle
          ) {
            needTitle = true;
          }
          if (renderModel.description === "") {
            needDescription = true;
          }
          if (!renderModel.categoryObject) {
            needCategory = true;
          }
          if (renderModel.tags.length === 0) {
            needTag = true;
          }

          if (!renderModel.published) {
            needAcknowledge = true;
          }
        }

        var partTitle = this.partModel ? this.partModel.get("title") : "";
        var hasBannedCover = this.model.get("hasBannedCover");

        var tags = this.formatTags(renderModel.tags, this.prefilledTag);
        var lang = wattpad.utils
          .currentUser()
          .get("locale")
          .slice(0, 2);

        // Set story tags
        window.store.dispatch(
          window.app.components.actions.setStoryTags({
            tags: tags,
            withIcon: true
          })
        );

        // Set state for Wattys Banner and Form
        window.store.dispatch(
          window.app.components.actions.setContestFormSubmissionStatus(
            this.wattysEligibility === SUBMITTED_FLAG
          )
        );
        window.store.dispatch(
          window.app.components.actions.setContestFormNearEligibleStatus(
            this.wattysEligibility === NEAR_ELIGIBLE_FLAG
          )
        );
        window.store.dispatch(
          window.app.components.actions.setContestFormStoryStatus(
            this.storyStatus
          )
        );

        //hasBannedImages take into account both banned inline images and banned Story Cover

        this.model.set({
          hasBannedImages:
            _.some(this.model.get("parts"), function(p) {
              if (p.deleted !== true && p.hasBannedImages) {
                return true;
              }
            }) || hasBannedCover
        });

        window.store.dispatch(
          window.app.components.actions.updateActiveTab("toc")
        );

        const showAcknowledgeOriginalWorks =
          this.isPublish && !renderModel.published;
        this.$el.html(
          this.templates.worksItemDetails({
            story: renderModel,
            showNewStats: true,
            isNew: this.isNew,
            showTips: this.showTips,
            isPublish: this.isPublish,
            needCover: needCover,
            needTitle: needTitle,
            needDescription: needDescription,
            displayWattysBanner: this.displayWattysBanner,
            canvaApiKey: wattpad.canvaApiKey,
            createCanvaCover: this.createCanvaCover.bind(this),
            needCategory: needCategory,
            needTag: needTag,
            partTitle: partTitle,
            testGroups: wattpad.testGroups,
            lang: lang,
            targetAudience: this.storyLabelsModel.get("target_audience"),
            characters: this.storyLabelsModel.get("characters"),
            charactersLimit: this.charactersLimit,
            prefilledTag: this.prefilledTag,
            hasBannedImages: this.model.get("hasBannedImages") || false,
            hasBannedCover: hasBannedCover,
            onSwitchTabs: this.onSwitchTabs.bind(this),
            showAcknowledgeOriginalWorks,
            handleUpdateCopyright: this.handleUpdateCopyright.bind(this)
          })
        );

        var numOfDrafts = 0;
        _.each(this.model.get("parts"), function(p) {
          if (p.deleted !== true) {
            numOfDrafts++;
          }
        });

        if (numOfDrafts >= this.maxNumParts) {
          this.$(".on-newpart")
            .parent()
            .addClass("too-many-parts");
        }

        this.postRender();

        return this;
      },

      renderContestBanner: function() {
        var content = null;
        if (this.displayWattysBanner) {
          content = this.templates.contestBanner({
            storyId: this.model.id
          });
        }
        this.$("#contest-banner-container").html(content);
      },

      onDragEnd: function() {
        // Get the order of the items
        var itemOrder = _.map(this.$(".story-part"), function(i) {
          return $(i).data("id");
        });

        if (itemOrder.length !== this.model.get("parts").length) {
          //TODO: Error Handling -- this state should never occur
          return;
        }

        itemOrder = itemOrder.join(",");

        // Set/Save the model
        this.model.set({ textids: itemOrder });
        this.model.save();
      },

      onEditClicked: function() {
        app.router.trigger("route:createWorksEdit", this.model.get("id"));
      },

      onSelectAll: function() {
        var range = window.document.createRange();
        range.selectNode(window.document.getElementById("embed-url"));
        window.getSelection().addRange(range);
      },

      addPaidPartMetadata: function(storyId, parts) {
        return window.store
          .dispatch(
            window.app.components.actions.fetchPaidContentMetadata(
              storyId,
              parts
            )
          )
          .then(function() {
            const { paidMetadata } = window.store.getState();

            _.each(parts, function(p) {
              var partMetadata = paidMetadata[storyId].parts.find(
                part => part.id === p.id
              );
              if (!partMetadata) return;

              p.isBonusPart = partMetadata.isBonusPart;
              p.bonusTypeName = partMetadata.bonusTypeName;
            });
          });
      },

      resetModal: function() {
        this.$(
          "#embed-modal,#stats-modal," +
            ".delete-story-modal,.delete-published-story-modal,.unpublish-story-modal," +
            ".delete-part-modal,.delete-published-part-modal,.unpublish-part-modal"
        ).addClass("hidden");

        this.$("#details-modal .modal-dialog")
          .removeClass("embed")
          .removeClass("stats");
      },

      onEmbedClicked: function() {
        var $iframe = this.$("#embed-modal iframe");

        this.resetModal();
        this.$("#details-modal .modal-dialog").addClass("embed");

        $iframe.attr("src", $iframe.data("href"));
        this.$("#embed-modal").removeClass("hidden");
      },

      onStatsClicked: function(evt) {
        evt.preventDefault();
        app.router.navigate("/myworks/" + this.model.get("id") + "/analytics", {
          trigger: true
        });
        return;
      },

      onDeletePartClicked: function(evt) {
        this.resetModal();

        if (this.model.get("parts").length < 2) {
          this.onDeleteStoryClicked();
        } else {
          this.deleteId = $(evt.currentTarget).data("id");
          this.$(".delete-part-modal").removeClass("hidden");
        }
      },

      onDeletePublishedPartClicked: function(evt) {
        this.resetModal();

        if (this.model.get("parts").length < 2) {
          this.onDeletePublishedStoryClicked();
        } else {
          this.deleteId = $(evt.currentTarget).data("id");
          this.unpublishId = $(evt.currentTarget).data("id");
          this.$(".on-delete-part").data("id", this.deleteId);
          this.$(".delete-published-part-modal").removeClass("hidden");
        }
      },

      onChangePartScheduleClicked: function(evt) {
        let schedulePartId = $(evt.currentTarget).data("id");
        wattpad.utils.redirectWithParams(
          `/myworks/${this.model.get("id")}/write/${schedulePartId}`,
          {
            triggerPublishingScheduler: true,
            partId: schedulePartId
          }
        );
      },

      onUnpublishPartClicked: function(evt) {
        this.resetModal();
        this.unpublishId = $(evt.currentTarget).data("id");
        this.$(".unpublish-part-modal").removeClass("hidden");
      },

      onUnpublishStoryClicked: function(evt) {
        this.resetModal();
        this.unpublishId = $(evt.currentTarget).data("id");
        this.$(".unpublish-story-modal").removeClass("hidden");
      },

      onDeleteStoryClicked: function() {
        this.resetModal();
        this.$(".delete-story-modal .alert").addClass("hidden");
        this.$(".delete-story-modal").removeClass("hidden");
      },

      onDeletePublishedStoryClicked: function() {
        this.resetModal();
        this.$(".delete-published-story-modal").removeClass("hidden");
      },

      validateUsernameFields: function(username, confirmUsername) {
        var errors = [];

        if (
          username !== confirmUsername ||
          username.length === 0 ||
          confirmUsername.length === 0
        ) {
          errors.push(wattpad.utils.trans("Username fields should match"));
        }
        if (
          username !==
          utils
            .currentUser()
            .get("username")
            .trim()
            .toLowerCase()
        ) {
          errors.push(wattpad.utils.trans("Username is incorrect"));
        }
        return errors;
      },

      onDoDeleteStory: function(evt) {
        utils.stopEvent(evt);

        var $form = $(evt.currentTarget);
        var username = $form
          .find("#username")
          .val()
          .trim()
          .toLowerCase();
        var confirmUsername = $form
          .find("#confirm-username")
          .val()
          .trim()
          .toLowerCase();

        var errors = this.validateUsernameFields(username, confirmUsername);

        if (errors.length > 0) {
          var $messages = $form.find(".alert ul.messages");
          $messages.empty();

          for (var i = 0; i < errors.length; i++) {
            $messages.append("<li>" + errors[i] + "</li>");
          }
          $form.find(".alert").removeClass("hidden");
          return;
        }

        $form.find("button, input").attr("disabled", true);

        var self = this;
        this.model.set("transaction_key", utils.currentUser().get("username"));

        Promise.resolve(this.model.destroy())
          .then(function() {
            self.model.set("transaction_key", null);
            //Cache Bust
            return utils.cacheBust(
              "Works",
              { username: utils.currentUser().get("username") },
              self.model.get("id")
            );
          })
          .then(function() {
            app.router.navigate("/myworks/", { trigger: true, replace: true });
          })
          .catch(function(err) {
            self.displayErrors(
              err.responseJSON
                ? err.responseJSON.message
                : err.message || err.errors || err
            );
          });
      },

      onDoDeletePart: function() {
        var self = this,
          part = new app.models.StoryPartCreateModel({
            id: this.deleteId,
            authorid: utils.currentUser().get("id")
          });

        Promise.resolve(part.destroy())
          .then(this.handlePromiseUnexpected)
          ["catch"](this.handlePromiseExpected)
          .then(function() {
            return Promise.resolve(
              self.model.fetch({ fresh: true, data: { drafts: 1 } })
            );
          })
          .then(function() {
            return self.addPaidPartMetadata(
              self.model.get("id"),
              self.model.get("parts")
            );
          })
          .then(function() {
            utils.cacheBust(
              ["Works", "Works"],
              [
                { username: utils.currentUser().get("username") },
                {
                  username: utils.currentUser().get("username"),
                  published: true
                }
              ],
              self.model.storyId
            );
            self
              .$("#details-modal")
              .on("hidden.bs.modal", function() {
                $(this).off("hidden.bs.modal");
                self
                  .$("[data-id='" + self.deleteId + "']")
                  .addClass("fade hide");
                self.render();
                //TODO: Remove element...
              })
              .modal("hide");

            // Check if user can create new parts again
            var numOfDrafts = 0;
            _.each(self.model.get("parts"), function(p) {
              if (p.deleted !== true) {
                numOfDrafts++;
              }
            });

            if (numOfDrafts < self.maxNumParts) {
              self
                .$(".on-newpart")
                .parent()
                .removeClass("too-many-parts");
            }
          });
      },

      onDoUnpublishStory: function(evt) {
        var self = this,
          parts = _.reject(this.model.get("parts"), function(part) {
            return part.draft || part.deleted;
          }),
          author = utils.currentUser().get("id"),
          complete = 0;

        utils.stopEvent(evt);

        this.$("#unpublish-modal .progress-bar")
          .attr("aria-value", 0)
          .width("0%")
          .removeClass("progress-bar-success")
          .addClass("progress-bar-info");

        this.$("#unpublish-modal .cta").attr("disabled", true);

        this.$("#working-wrapper").removeClass("hidden");

        if (parts.length === 0) {
          this.updateUnpublishStatus(1, 1);
          return;
        }

        _.each(parts, function(partObj) {
          var part = new app.models.StoryPartCreateModel({
            id: partObj.id,
            authorid: author
          });

          Promise.resolve(part.unpublish()).then(function() {
            complete++;
            self.updateUnpublishStatus(parts.length, complete);
          });
        });
      },

      onDoUnpublishPart: function() {
        var self = this,
          part = new app.models.StoryPartCreateModel({
            id: this.unpublishId,
            authorid: utils.currentUser().get("id")
          });

        Promise.resolve(part.unpublish())
          .then(function() {
            return Promise.resolve(
              self.model.fetch({ fresh: true, data: { drafts: 1 } })
            );
          })
          .then(function() {
            return self.addPaidPartMetadata(
              self.model.get("id"),
              self.model.get("parts")
            );
          })
          .then(function() {
            self
              .$("#details-modal")
              .on("hidden.bs.modal", function() {
                $(this).off("hidden.bs.modal");
                //TODO: Make this transition nicer... (fade & change?)
                self.render();
              })
              .modal("hide");
          });
      },

      updateUnpublishStatus: function(total, complete) {
        var self = this,
          percent = Math.floor(complete / total) * 100;

        this.$(".unpublish-story-modal .progress-bar")
          .attr("aria-valuenow", percent)
          .width(percent + "%");

        if (total === complete) {
          this.$("#unpublish-working").text(utils.trans("Done"));

          this.$(".unpublish-story-modal .progress-bar")
            .removeClass("progress-bar-info")
            .addClass("progress-bar-success");

          var currentUser = utils.currentUser();
          currentUser._clearStoriesCache();

          utils.cacheBust(
            ["Works", "Works"],
            [
              { username: currentUser.get("username") },
              { username: currentUser.get("username"), published: true }
            ],
            this.selectedStoryId
          );

          this.model.fetch({ fresh: true }).then(function() {
            window.setTimeout(function() {
              self
                .$("#details-modal")
                .on("hidden.bs.modal", function() {
                  $(this).off("hidden.bs.modal");
                  self.render();
                })
                .modal("hide");
            }, 500);
          });
        }
      },

      onNewPartClicked: function(evt) {
        // In this model we have to count the number of drafts
        var numOfDrafts = 0;
        _.each(this.model.get("parts"), function(p) {
          if (p.deleted !== true) {
            numOfDrafts++;
          }
        });

        if (numOfDrafts >= this.maxNumParts) {
          utils.stopEvent(evt);
          return;
        }

        var self = this,
          hasErrors = false,
          uniqueName = false,
          nameCounter = 1,
          newPart = new app.models.StoryPartCreateModel({
            groupid: this.model.get("id"),
            title:
              utils.trans("Untitled Part ") +
              (this.model.get("parts").length + 1),
            language: this.model.get("language"),
            copyright: this.model.get("copyright"),
            category1: this.model.get("categories")[0],
            draft: 1,
            tags: this.model.get("tags").join(" ")
          });

        while (uniqueName === false) {
          if (
            !_.findWhere(this.model.get("parts"), {
              title: newPart.get("title")
            })
          ) {
            uniqueName = true;
          } else {
            newPart.set(
              "title",
              utils.trans("Untitled Part ") +
                (this.model.get("parts").length + 1) +
                " (" +
                nameCounter +
                ")"
            );
            nameCounter++;
          }
        }

        utils.stopEvent(evt);

        Promise.resolve(newPart.save())
          .then(function(response) {
            self.handlePromiseUnexpected(response);
          })
          .catch(function(response) {
            hasErrors = true;
            self.handlePromiseExpected(response);
          })
          .then(function() {
            if (!hasErrors) {
              return Promise.resolve(
                self.model.fetch({ fresh: true, data: { drafts: 1 } })
              );
            }
          })
          .then(function() {
            if (!hasErrors) {
              app.router.navigate(
                "/myworks/" +
                  self.model.get("id") +
                  "/write/" +
                  newPart.get("id"),
                { trigger: true }
              );
            }
          });
      },

      onCharacterInputKeyUp: _.throttle(function(evt) {
        var $input = $(evt.currentTarget),
          $button = $input.parent(".character").find(".btn"),
          $buttonIcon = $button.find(".fa");

        if ($input.val().trim().length > 0) {
          $button.removeClass("btn-grey").addClass("btn-orange");
          $buttonIcon
            .removeClass("fa-wp-neutral-1")
            .addClass("fa-wp-neutral-5");
        } else {
          $button.removeClass("btn-orange").addClass("btn-grey");
          $buttonIcon
            .removeClass("fa-wp-neutral-5")
            .addClass("fa-wp-neutral-1");
        }
      }, 200),

      onAddCharacter: function(evt) {
        var $button = $(evt.currentTarget),
          $input = $button.parent(".character").find("input"),
          character = $input.val().trim();

        if (character.length > 0) {
          this.didEditCharacters = true;
          $button.parent(".character").remove();
          this.$(".characters").append(
            this.templates.character({
              name: character,
              readonly: true
            })
          );
          if (this.$(".character").length < this.charactersLimit) {
            this.$(".characters").append(this.templates.character());
          }
        }
      },

      onRemoveCharacter: function(evt) {
        var $characterContainer = $(evt.currentTarget).parent(".character");
        this.didEditCharacters = true;

        // Edge case: added max 20 characters and then removing
        if (this.$(".character [readonly]").length == this.charactersLimit) {
          this.$(".characters").append(this.templates.character());
        }

        $characterContainer.remove();
        if (this.$(".character").length == 0) {
          this.$(".characters")
            .empty()
            .append(this.templates.character());
        }
      },

      saveCharacters: function() {
        var characters = [],
          self = this;

        _.each(this.$(".characters .character input"), function(el) {
          var character = $(el)
            .val()
            .trim();
          if (character.length > 0) {
            characters.push({ name: character });
          }
        });

        // This is so the POST request calls /v5/stories instead of /v5/stories/:storyId
        // "storyId" is set in storyLabelsModel.fetch() on page load
        this.storyLabelsModel.clear({ silent: true });

        Promise.resolve(
          this.storyLabelsModel.save({
            id: parseInt(this.model.get("id")),
            characters: characters
          })
        ).catch(function() {
          self.handlePromiseExpected("");
        });
      },

      // Keep the cursor on enter or space
      onTagInputKeydown: function(evt) {
        var keyCode = evt.keyCode || evt.which;

        if (keyCode === 13 || keyCode === 32) {
          utils.stopEvent(evt);
          var tagCount = this.onTagInputBlurred();

          if (tagCount < wattpad.tagCountLimit) {
            this.onAddTagSelected();
          }
        }
        if (keyCode === 8 && this.getCurrentInput().length === 0) {
          utils.stopEvent(evt);
          this.$("#editable-tags .tag-item")
            .last()
            .remove();
          this.onAddTagSelected();
        }
      },

      getCurrentInput: function() {
        return this.$("#tag-input")
          .val()
          .trim();
      },

      onAddTagSelected: function(evt) {
        utils.stopEvent(evt);
        this.$("#add-tag").addClass("hidden");
        this.$("#tag-input")
          .val("")
          .removeClass("hidden")
          .focus();

        var defaultTags = _.difference(
          this.SUGGESTED_WRITER_TAGS,
          this.getTags()
        ).map(function(tag) {
          return { label: tag };
        });

        this.initAutocomplete({
          element: "#tag-input-wrapper #tag-input",
          collection: new app.collections.AutocompleteTag(defaultTags),
          title: wattpad.utils.trans("Suggested Tags")
        });
      },

      // Returns tagCount because getTags() relies on the DOM
      onTagInputBlurred: function() {
        var $tagInput = this.$("#tag-input"),
          newTag = this.cleanHTMLToText($tagInput).trim(),
          oldTags = this.getTags(),
          tagCount = oldTags.length,
          tags = [];
        // preventing writers from adding TTS tag manually as this is an experiment right now
        // and we add this only from the backend
        var restrictedTag = ["texttospeech"];

        if (this.autocompleteResults) {
          this.autocompleteResults.remove();
        }

        $tagInput.addClass("hidden");

        if (
          tagCount < wattpad.tagCountLimit &&
          newTag &&
          restrictedTag.indexOf(newTag) === -1 &&
          oldTags.indexOf(newTag) === -1
        ) {
          if (
            newTag === wattpad.wattysActiveKey &&
            this.wattysEligibility === ELIGIBLE_FLAG
          ) {
            window.store.dispatch(
              window.app.components.actions.openContestForm(this.model.id)
            );
          }

          tagCount++;
          tags = this.formatTags(oldTags.concat([newTag]), this.prefilledTag);

          window.store.dispatch(
            window.app.components.actions.setStoryTags({
              tags: tags,
              withIcon: true
            })
          );
        }

        if (tagCount < wattpad.tagCountLimit) {
          this.$("#add-tag").removeClass("hidden");

          if (tagCount > 0) {
            this.$("#add-tag").removeClass("empty-border");
            this.$("#tag-empty-warning").removeClass("red-warning");
          }
        }

        // tagCount can never be 'false'; jQuery event handler only actions on 'false'
        return tagCount;
      },

      getTags: function() {
        const currentTags = window.store.getState().storyTags.tags;
        return currentTags.map(tag => tag.name);
      },

      formatTags: function(tags, prefilledTag) {
        // Format tags to send it off to TagGrid component
        // TODO: When Wattys close, remove/refactor this
        return tags.map(function(tag) {
          var trimmedTag = tag.trim();
          return {
            id: trimmedTag,
            name: trimmedTag,
            active: true,
            className:
              trimmedTag === wattpad.wattysActiveKey
                ? "wattys-tag"
                : prefilledTag && trimmedTag === prefilledTag
                  ? "prefilled-tag"
                  : null
          };
        });
      },

      pastePlainText: function(e) {
        utils.pastePlainText(e);
      },

      cleanHTMLToText: function(el) {
        var cleanedText;

        if (el.is("input") || el.is("textarea")) {
          cleanedText = utils.sanitizeHTMLExceptQuotes(el.val().trim());
        } else {
          cleanedText = utils.sanitizeHTMLExceptQuotes(el.text().trim());
        }

        cleanedText = cleanedText
          .replace(/<(br|p|div)(\s*)>/gi, "\n")
          .replace(/(<([^>]+)>)/gi, "");
        return cleanedText;
      },

      sendViewAsReaderEvent: function(evt) {
        var targetData = $(evt.currentTarget).data(),
          storyId = this.model.get("id"),
          partId = targetData.id;

        /*
       * "View as reader" from pre-publish modal
       */
        if (app.currentView instanceof app.views.WorksWriter) {
          window.te.push("event", "writer", null, null, "reader_view", {
            storyid: storyId,
            source: "prepublish"
          });
        } else {
          /*
         * "View as reader" orange button
         */
          if (targetData.attr === "wid-btn") {
            window.te.push("event", "writer", null, null, "reader_view", {
              storyid: storyId,
              source: "story_details"
            });
          } else if (targetData.attr === "wid-toc") {
            /*
           * "View as reader" from drop-down in Table of Contents
           */
            window.te.push("event", "writer", null, null, "reader_view", {
              storyid: storyId,
              partid: partId,
              source: "table_of_contents"
            });
          }
        }
      },

      viewAsReader: function(evt) {
        var self = this,
          viewAsReaderUrl = $(evt.target).data("url");

        this.sendViewAsReaderEvent(evt);
        utils.stopEvent(evt);
        var missingRequiredFields = this.isMissingRequiredFields();

        if (missingRequiredFields) {
          this.$(".on-switch-type").removeClass("active");
          this.$(".main-edit-form").removeClass("hidden");
          this.$(".table-of-contents").addClass("hidden");
          return;
        }
        /*
       * On the pre-publish WorksItemDetails modal, selecting 'View as Reader'
       * should only save it as a draft -- the default for WorksItemDetails sets
       * publish as true
       */
        this.isPublish = false;

        // The new window is opened here and saved to a variable to bypass the popup blocker that
        // would be triggered by attempting to open the window asynchronously
        var newWindow = window.open("", "_blank");
        Promise.resolve(this.onEditSaveSelected())
          .then(function(res) {
            newWindow.location.href = viewAsReaderUrl;
          })
          .catch(function(err) {
            // In error situations, close the new window/tab. Browser behaviour should return focus to the previous tab/window.
            newWindow.close();
            var errorMessage = err.responseJSON
              ? err.responseJSON.message
              : err.message ||
                err.errors ||
                wattpad.utils.trans(
                  "We seem to have had some issues saving your story. Please try again."
                );
            return self.displayErrors(errorMessage);
          });
      },

      isMissingRequiredFields: function() {
        var title = this.cleanHTMLToText(this.$(".story-title")),
          description = this.cleanHTMLToText(this.$(".story-description")),
          category = this.$("#categoryselect").val(),
          tags = this.$("#editable-tags .tag-item"),
          acknowledged = this.$("#acknowledge-checkbox").hasClass("checked"),
          hasError = false;

        this.$("#publish-status-banner").empty();

        if (this.isPublish && !acknowledged) {
          this.$("#acknowledge-empty-warning")
            .removeClass("hidden")
            .addClass("red-warning");

          utils.scrollIntoView(this.$("#acknowledge-checkbox"));

          hasError = true;
        }

        if (title === this.defaultStoryTitle) {
          this.$(".story-title").addClass("empty-border");
          this.$("#title-warning")
            .removeClass("hidden")
            .addClass("red-warning");
          hasError = true;
        }
        if (description === "") {
          this.$(".story-description").addClass("empty-border");
          this.$("#description-warning")
            .removeClass("hidden")
            .addClass("red-warning");
          hasError = true;
        }
        if (category === "-1") {
          this.$("#categoryselect").addClass("empty-border");
          this.$("#category-empty-warning")
            .removeClass("hidden")
            .addClass("red-warning");
          hasError = true;
        }
        // For new stories: can’t be published without adding a tag
        // For existing tag-less stories:
        //    - New/draft parts can’t be published without adding a tag
        //    - Published parts CAN be edited without adding a tag
        if (tags.length === 0 && this.isPublish) {
          this.$("#add-tag").addClass("empty-border");
          this.$("#tag-empty-warning")
            .removeClass("hidden")
            .addClass("red-warning");
          hasError = true;
        }
        return hasError;
      },

      onAcknowledge: function(event) {
        this.$("#acknowledge-empty-warning")
          .addClass("hidden")
          .removeClass("red-warning");
      },

      onPublish: function(evt) {
        this.isPublish = true;
        this.onEditSaveSelected();
      },

      onEditSaveSelected: function() {
        if (this.model.get("id")) {
          // Save Story Notes
          const storyNotesData = window.store.getState().storyNotes;
          let loadAction = window.app.components.actions.postStoryNotes(
            this.model.get("id"),
            storyNotesData.data
          );
          window.store.dispatch(loadAction);
        }

        if (this.showTips) {
          this.$("#finish-tooltip").popover("show");
          return;
        }

        this.$("button").attr("disabled", true);
        this.$(".save-working").removeClass("hidden");

        // Check if there's some part scheduled for publish
        const parts = this.model.get("parts") ?? [];
        const storyHasScheduledPartForPublish = parts.some(
          part => part.isScheduledPart === true
        );

        if (
          this.isPublish ||
          this.model.get("published") ||
          storyHasScheduledPartForPublish
        ) {
          var missingRequiredFields = this.isMissingRequiredFields();

          if (missingRequiredFields) {
            this.$("button").removeAttr("disabled");
            this.$(".save-working").addClass("hidden");
            return;
          }
        }

        var complete = this.$("#complete-switch").prop("checked") ? 1 : 0,
          mature = this.$("#mature-switch").prop("checked") ? 4 : 1,
          title = this.cleanHTMLToText(this.$(".story-title")),
          description = this.cleanHTMLToText(this.$(".story-description")),
          category = this.$("#categoryselect").val(),
          language = this.$("#languageselect").val();

        this.model.set({
          title: title,
          description: description,
          category1: category,
          language: language,
          complete: complete,
          human_rating: mature
        });

        if (this.partModel) {
          this.partModel.set({
            category1: category
          });
        }

        // When we aren't publishing and the only issue is the title, we can continue.
        var validationResult = this.model.validate(undefined, {
          ignore: {
            title: this.isPublish ? [] : ["isRequired", "isNotEq"]
          }
        });

        if (!validationResult) {
          if (this.isNew) {
            return Promise.resolve(this.create(true, true));
          } else {
            return Promise.resolve(
              this.save().then(() => {
                this.renderContestBanner();
              })
            );
          }
        } else {
          this.displayErrors(_.values(validationResult));
          throw new Error(_.first(_.values(validationResult)));
        }
      },

      onEditCancelSelected: function() {
        if (this.model.get("id")) {
          app.router.navigate("/myworks", { trigger: true });
        } else {
          utils.goBackInHistory();
        }
      },

      displayErrors: function(errors) {
        var top =
          app.currentView instanceof app.views.WorksWriter
            ? ".modal-content"
            : "html, body";
        this.$("#publish-status-banner").html(
          this.templates.status({
            errors: [].concat(errors)
          })
        );
        $(top).animate({
          scrollTop: "0px"
        });
        this.$("button").removeAttr("disabled");
        this.$(".save-working").addClass("hidden");
      },

      // the default argument value is to maintain the existing behaviour where saving on the new story screen will navigate to the
      // writer for the first part. Set to false if you want to save operation to proceed, but not navigate away after completion.
      create: function(navigateToWriter = true, shouldSaveCover = false) {
        var self = this,
          groupNum,
          newPart = new app.models.StoryPartCreateModel({
            title: this.model.get("title"),
            description: this.model.get("description"),
            part_title: utils.trans("Untitled Part 1"),
            language: this.model.get("language"),
            draft: 1
          });
        newPart.set("skip_title", 1);

        // TODO: Proper title validation and error handling using ValidationModel - and when the publish modal shows up if required inputs weren't filled previously.
        return Promise.resolve(
          newPart.save(undefined, {
            validation: {
              ignore: {
                title: ["isRequired", "isNotEq", "maxLength"]
              }
            }
          })
        )
          .then(function(result) {
            self.model.set({
              id: result.groupid,
              textids: result.id.toString(),
              parts: [result]
            });

            self.model.set("skip_title", 1);

            groupNum = result.groupid;
            return Promise.resolve(self.model.save());
          })
          .then(function() {
            if (self.$("#target-audience").val() != "-1") {
              // This is so the POST request calls /v5/stories instead of /v5/stories/:storyId
              // "storyId" is set in storyLabelsModel.fetch() on page load
              self.storyLabelsModel.clear({ silent: true });

              return Promise.resolve(
                self.storyLabelsModel.save({
                  id: parseInt(self.model.get("id")),
                  target_audience: self.$("#target-audience").val()
                })
              ).catch(self.handlePromiseExpected);
            }
            return true;
          })
          .then(function() {
            if (self.didEditCharacters) {
              self.didEditCharacters = false;
              return self.saveCharacters();
            }
            return true;
          })
          .then(function() {
            return self.saveTags();
          })
          .then(function() {
            if (shouldSaveCover) {
              return self.saveCover();
            } else {
              return Promise.resolve();
            }
          })
          .then(function() {
            var currentUser = utils.currentUser();
            currentUser._clearStoriesCache();

            app.local.clear(newPart.resource());
            app.local.clear(self.model.resource());

            utils.cacheBust(
              ["Works"],
              [{ username: currentUser.get("username") }]
            );

            if (navigateToWriter) {
              var url =
                "/myworks/" +
                self.model.get("id") +
                "/write/" +
                self.model.get("parts")[0].id;
              app.router.navigate(url, { trigger: true, replace: false });
            }
          })
          .catch(function(err) {
            self.displayErrors(
              err.responseJSON
                ? err.responseJSON.message
                : err.message ||
                  err.errors ||
                  wattpad.utils.trans(
                    "We seem to have had some issues saving your story. Please try again."
                  )
            );
          });
      },

      bustWorksCache: function() {
        var self = this;
        var currentUser = wattpad.utils.currentUser();
        currentUser._clearStoriesCache();

        var bustPublishedWorks = utils.cacheBust("Works", {
          username: currentUser.get("username"),
          published: true
        });
        var bustWorks = utils.cacheBust("Works", {
          username: currentUser.get("username")
        });
        var bustStoryModel = utils.cacheBust(
          "StoryCreateModel",
          {
            id: self.model.get("id")
          },
          null,
          true
        );
        return Promise.all([bustPublishedWorks, bustWorks, bustStoryModel]);
      },

      save: function() {
        var self = this;

        this.model.set({
          language: this.model.get("language")
        });

        // TODO: Proper title validation and error handling using ValidationModel - and when the publish modal shows up if required inputs weren't filled previously.
        return Promise.resolve(
          this.model.save(undefined, {
            validation: {
              ignore: {
                title: ["isRequired", "isNotEq", "maxLength"]
              }
            }
          })
        )
          .then(function() {
            if (self.$("#target-audience").val() != "-1") {
              self.storyLabelsModel.clear({ silent: true });

              return Promise.resolve(
                self.storyLabelsModel.save({
                  id: parseInt(self.model.get("id")),
                  target_audience: self.$("#target-audience").val()
                })
              ).catch(self.handlePromiseExpected);
            } else {
              return true;
            }
          })
          .then(function() {
            if (self.didEditCharacters) {
              self.didEditCharacters = false;
              return self.saveCharacters();
            }
            return true;
          })
          .then(function() {
            return self.saveTags();
          })
          .then(function() {
            return self.bustWorksCache();
          })
          .then(function() {
            if (self.isPublish) {
              if (self.isPreview) {
                $("#generic-modal").modal("hide");
                $(".draft-tag").addClass("hidden");
                $(".on-publish").addClass("hidden");
                $(".on-write").addClass("hidden");
                $(".preview-alert").addClass("hidden");
                utils.openModal && utils.openModal(MODAL_ID.PUBLISH_MODAL_ID);
              } else {
                utils.openModal && utils.openModal(MODAL_ID.PUBLISH_MODAL_ID);
              }
            } else {
              var successMessage = wattpad.utils.trans("Saved.");
              var top =
                app.currentView instanceof app.views.WorksWriter
                  ? ".modal-content"
                  : "html, body";

              self.$("#publish-status-banner").html(
                self.templates.status({
                  successMessage: successMessage
                })
              );
              $(top).animate({
                scrollTop: "0px"
              });
              self.$("button").attr("disabled", false);
              self.$(".save-working").addClass("hidden");
            }
          })
          .catch(function(err) {
            var errMessage = err.responseJSON
              ? err.responseJSON.message
              : err.message ||
                err.errors ||
                wattpad.utils.trans(
                  "We seem to have had some issues saving your story. Please try again."
                );
            self.displayErrors(errMessage);
            throw new Error(errMessage);
          });
      },

      saveTags: function() {
        var self = this,
          tags = this.getTags();
        return Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/apiv2/editstorytags",
            data: {
              request_type: "add",
              tag_value: tags.join(" "),
              story_id: self.model.get("parts")[0].id,
              remove_old_tags: true
            }
          })
        );
      },

      requestSaveCover: function(id, base64, designId) {
        const body = {
          id: id,
          image: base64
        };
        if (designId) {
          body.cover_source_id = designId;
          body.cover_partner = "canva";
        }
        return Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/apiv2/updatestorycover",
            data: body
          })
        );
      },

      saveCover: function() {
        var self = this;
        // NOTE: Option to remove a story cover is not supported.
        if (this.FileUpload.file) {
          return self.getFile().then(function(data) {
            if (data) {
              utils.cacheBust(
                ["Works", "Works"],
                [
                  { username: utils.currentUser().get("username") },
                  {
                    username: utils.currentUser().get("username"),
                    published: true
                  }
                ],
                self.model.get("id")
              );
              self.bustWorksCache();
              self.$(".banned-overlay").addClass("hidden");

              return self
                .requestSaveCover(
                  self.model.get("id"),
                  data.parsed,
                  data.metadata && data.metadata.designId
                )
                .then(function(data) {
                  self.model.set("cover", data.cover);
                  self.model.set("cover_source_id", data.cover_source_id);
                  self.model.set("cover_partner", data.cover_partner);
                  window.te.push("event", "writer", "cover", null, "add", {
                    storyid: self.model.get("id")
                  });
                });
            }
          });
        } else {
          return true;
        }
      },

      onCoverEditIconEnter: function() {
        this.$("#cover-uploader").addClass("active");
        this.$(".on-cover-edit").removeClass("hidden");
      },

      onCoverEditLeave: function() {
        this.$("#cover-uploader").removeClass("active");
        this.$(".on-cover-edit").addClass("hidden");
      },

      onFileDragEnter: function() {
        this.onCoverEditIconEnter();
      },

      onPopoverOk: function(evt) {
        utils.stopEvent(evt);
        var target = $(evt.currentTarget);
        target
          .parent()
          .parent()
          .parent()
          .popover("hide");
      },

      onCloseTooltip: function(evt) {
        var target = $(evt.currentTarget);
        var popover = target.data("bs.popover");

        if (popover && popover.tip().hasClass("in")) {
          var id = target.attr("id");

          if (id === "image-tooltip") {
            this.$(".add-cover-text").click();
          } else if (id === "description-tooltip") {
            this.$(".story-description").focus();
          } else if (id === "tags-tooltip") {
            this.onAddTagSelected();
          } else if (id === "finish-tooltip") {
            this.showTips = false;
            this.onEditSaveSelected();
          }
        }
      },

      onTriggerTooltip: function(evt) {
        utils.stopEvent(evt);
        var target = $(evt.currentTarget);
        target
          .parent()
          .find('[data-toggle="popover"]')
          .popover("show");
        target.removeClass("on-trigger-tooltip");

        _.delay(function() {
          if (target.hasClass("new-cover-upload")) {
            target.find(".dropdown-holder").attr("data-toggle", "dropdown");
          }
        });
      },

      onContestFormPopover: function(evt) {
        var trans = wattpad.utils.trans;

        var mapLanguageToLinks = {
          1: "https://www.wattpad.com/1229517874-the-2022-watty-awards-how-to-submit", //English
          2: "https://www.wattpad.com/1231193196-les-wattys-2022-comment-soumettre", //French
          3: "https://www.wattpad.com/1231200512-wattys-2022-come-iscriversi", // Italian
          4: "https://www.wattpad.com/1231211429-die-watty-awards-2022-wie-kann-eingereicht-werden", //German
          5: "https://www.wattpad.com/1231224269-los-premios-watty-2022-c%C3%B3mo-inscribirte", //Spanish
          6: "https://www.wattpad.com/1231203499-pr%C3%AAmio-wattys-2022-como-se-inscrever", //Portuguese
          18: "https://www.wattpad.com/1230991528-ang-2022-watty-awards-paano-magsumite", //Filipino
          20: "https://www.wattpad.com/1231219920-penghargaan-wattys-2022-cara-mendaftar", //Indonesian
          23: "https://www.wattpad.com/1231197790-2022-watty-%C3%B6d%C3%BClleri-nas%C4%B1l-ba%C5%9Fvurulur" //Turkish
        };

        this.initPopover(
          "#content-tooltip",
          trans("What is mature content?"),
          trans(
            "These ratings help us understand if your story has sexual content, and if it does, to what magnitude. Stories with any level of sexual content are eligible to win, as long as they abide by our content guidelines."
          ),

          mapLanguageToLinks[app.get("language")]
        );
      },

      postRender: function() {
        var trans = wattpad.utils.trans;

        if (this.isPreview) {
          if (this.model.get("title") === this.defaultStoryTitle) {
            this.$(".story-title").removeClass("has-title");
          }
        }

        this.initPopover(
          "#image-tooltip",
          trans(
            "Stories with a cover image get 23x more reads than ones without"
          ),
          trans(
            "Must be in PNG, GIF, or JPG format, smaller than 2MB. Recommended cover dimensions: 512x800 pixels"
          ),
          "https://support.wattpad.com/hc/articles/201376344-Adding-a-cover-to-your-story"
        );

        this.initPopover(
          "#description-tooltip",
          trans(
            "Stories with descriptions get 100x more reads than ones without"
          ),
          trans(
            "Write a short description that will excite your readers and hook them in"
          ),
          "https://support.wattpad.com/hc/articles/201376264-Adding-a-story-description"
        );

        this.initPopover(
          "#category-tooltip",
          trans("This information helps us understand more about your story"),
          null,
          "https://support.wattpad.com/hc/articles/200774334-Content-Guidelines#categories"
        );

        this.initPopover(
          "#characters-tooltip",
          trans(
            "Add up to 20 Main Characters. This information will not be shown to your readers. Instead, this information will be used to help Wattpad more easily discover your story."
          ),
          null,
          "https://support.wattpad.com/hc/articles/360027382932-Add-Main-Characters-to-a-Story"
        );

        this.initPopover(
          "#tags-tooltip",
          trans("Adding 10 or more tags can help increase discoverability"),
          trans(
            "Tags should be a word or concept, reflective of your story’s themes and subgenres"
          ),
          "https://support.wattpad.com/hc/articles/201409640-Adding-tags-to-a-story"
        );

        this.initPopover(
          "#target-audience-tooltip",
          trans(
            "This information will not be shown to your readers. Instead, this information will be used to help Wattpad more easily discover your story."
          ),
          null,
          "https://support.wattpad.com/hc/articles/360027383472-Add-a-Target-Audience-to-a-Story"
        );

        this.initPopover(
          "#language-tooltip",
          trans(
            "Wattpad has a global audience; make sure your story reaches the right readers"
          )
        );

        this.initPopover(
          "#rating-tooltip",
          trans(
            "Rate your story appropriately to ensure a positive reading experience for your readers with no unwanted surprises"
          ),
          null,
          "https://support.wattpad.com/hc/articles/200774334-Content-Guidelines#ratings"
        );

        const storyTooltipLink =
          '<a href="https://support.wattpad.com/hc/articles/201376684-Marking-your-story-as-complete" target="_blank" rel="noreferrer noopener">Learn more</a>';

        this.initPopover(
          "#story-tooltip",
          trans("If your story is complete, please let your readers know. ") +
            storyTooltipLink
        );

        this.initPopover(
          "#finish-tooltip",
          trans(
            "Don’t worry, you can finish adding your story information later"
          ),
          null,
          "https://www.wattpad.com/story/71065441-secrets-to-getting-more-reads",
          "finish-tooltip-ok"
        );

        if (this.prefilledTag) {
          this.initPopover(
            "#prefilled-tag-tooltip",
            "<p>We've automatically added <span class='bold'>#" +
              this.prefilledTag +
              "</span> to your submission. This ensures we've received your entry.</p>",
            null,
            null,
            null,
            true
          );
          this.$("#prefilled-tag-tooltip").popover("show");
        }
      },

      initPopover: function(
        dom,
        mainContent,
        secondaryContent,
        href,
        okDom,
        isPrefilledTagPopover
      ) {
        var popOverTemplate =
          '<div class="popover writer-tooltip" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3>' +
          '<div class="popover-content"></div></div>';

        var content = "";
        if (mainContent) {
          content += "<div class='main-content'>" + mainContent;

          if (secondaryContent) {
            content +=
              "<div class='secondary-content'>" + secondaryContent + "</div>";
          }
          content += "</div>";
        }

        content += "<div class='tooltip-footer'>";
        if (href) {
          content +=
            "<a target='_blank' href=" +
            href +
            ">" +
            wattpad.utils.trans("Learn More") +
            "</a>";
        }
        content +=
          "<button class='btn on-okay " +
          (isPrefilledTagPopover ? "btn-link " : "btn-purple");
        if (okDom) {
          content += okDom;
        }
        content +=
          "'>" +
          (isPrefilledTagPopover ? "Got It" : wattpad.utils.trans("OK")) +
          "</button></div>";

        var self = this;

        this.$(dom)
          .popover({
            template: popOverTemplate,
            html: true,
            placement: "auto",
            content: function() {
              return content;
            }
          })
          .on("shown.bs.popover", function(e) {
            var popover = $(e.target);
            $(document).on("click.closePopover", function(e) {
              if ($(e.target).parents(".popover").length === 0) {
                popover.popover("hide");
              }
            });
          })
          .on("hide.bs.popover", function(e) {
            $(document).off("click.closePopover");
            self.onCloseTooltip(e);
          });
      },

      ratingChange: function(evt) {
        if ($(evt.currentTarget).prop("checked")) {
          this.$(".everyone").addClass("hidden");
          this.$(".writerMature").removeClass("hidden");
        } else {
          this.$(".everyone").removeClass("hidden");
          this.$(".writerMature").addClass("hidden");
        }
      },

      onStoryTitleKeyUp: function() {
        if (this.$("#categoryselect").val() !== "-1" && this.isNew) {
          this.$(".on-edit-save .text").text(wattpad.utils.trans("Next"));
        }
      },

      onStoryTitleFocus: function() {
        var $storyTitle = this.$(".story-title");

        if ($storyTitle.text().trim() === this.defaultStoryTitle) {
          $storyTitle.text("");
          $storyTitle.addClass("has-title");
        }
      },

      onStoryTitleBlur: function() {
        var $storyTitle = this.$(".story-title");

        if ($storyTitle.text().trim() === "") {
          $storyTitle.text(this.defaultStoryTitle);
          $storyTitle.removeClass("has-title");
        } else {
          if (
            $storyTitle.hasClass("empty-border") &&
            $storyTitle.text() !== ""
          ) {
            $storyTitle.removeClass("empty-border");
            this.$("#title-warning").removeClass("red-warning");
          }
        }
      },

      onCategorySelected: function(evt) {
        if (this.$(".story-title").text() !== "" && this.isNew) {
          this.$(".on-edit-save .text").text(wattpad.utils.trans("Next"));
        }

        var $category = $(evt.currentTarget);
        if ($category.hasClass("empty-border") && $category.val() !== "-1") {
          $category.removeClass("empty-border");
          this.$("#category-empty-warning").removeClass("red-warning");
        }
      },

      onEditClose: function() {
        $(".publish-modal").modal("hide");
      },

      remove: function() {
        $(document).off("click.closePopover");
        app.views.Base.prototype.remove.apply(this, arguments);
      },

      showFile: function() {
        if (this.isNew) {
          this.$(".new-cover-upload").addClass("hidden");
          this.$("#cover-uploader").removeClass("hidden");
          this.$("#image-tooltip").addClass("hidden");
        }
      },

      onStoryDescriptionBlur: function(evt) {
        var $description = $(evt.currentTarget);
        if (
          $description.hasClass("empty-border") &&
          $description.val() !== ""
        ) {
          $description.removeClass("empty-border");
          this.$("#description-warning").removeClass("red-warning");
        }
      },

      onSwitchTabs: function(activeTab) {
        if (
          activeTab === "toc" &&
          this.$(".table-of-contents").hasClass("hidden")
        ) {
          this.$(".table-of-contents").removeClass("hidden");
          this.$(".main-edit-form").addClass("hidden");
        } else if (
          activeTab === "story-detail" &&
          this.$(".main-edit-form").hasClass("hidden")
        ) {
          this.$(".main-edit-form").removeClass("hidden");
          this.$(".table-of-contents").addClass("hidden");
        } else if (activeTab === EditorTabs.NOTES) {
          this.$(".table-of-contents").addClass("hidden");
          this.$(".main-edit-form").addClass("hidden");
        }
      },

      fileReceivedCallback: function() {
        // Callback after user uploads cover. Save it if story exists.
        if (this.model.id) {
          this.saveCover().then(() => {
            // Dispatch/Trigger/Fire the event to update CanvaButton
            window.dispatchEvent(
              new CustomEvent("myworks:cover:saved", {
                detail: this.model.toJSON()
              })
            );
          });
        }
      },

      createCanvaCover: function(options) {
        const self = this;

        // Download file from canva
        var xhr = new XMLHttpRequest();
        xhr.open("GET", options.exportUrl, true);
        xhr.responseType = "blob";
        xhr.onload = function(e) {
          if (this.status == 200) {
            var b = this.response;
            self.FileUpload.file = b;
            self.FileUpload.file.designId = options.designId;
            self.showFile();
            self.FileUpload.$domDrop.addClass("drop-done");

            window.te.push("event", "writer", "cover", null, "create", {
              target: "canva"
            });
          }
        };
        xhr.send();
      },

      handleUpdateCopyright: function(copyright) {
        this.model.set({ copyright });
      }
    })
  );

  app.mixin(
    app.views.WorksItemDetails,
    "FileUpload",
    "DragAndSort",
    "SocialSharing",
    "AutocompleteField"
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
