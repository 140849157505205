import spielbergoAnnouncement from "core/templates/announcements/spielbergo-announcement.hbs";
(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "SpielbergoAnnouncment",
    app.views.Announcement.extend({
      template: spielbergoAnnouncement,

      className: app.views.Announcement.prototype.className + " spielbergo",

      events: _.extend(
        {
          "click  .on-navigate": "stopEvent",
          "tap    .on-navigate": "onNavigate"
        },
        app.views.Announcement.prototype.events
      ),

      render: function() {
        app.views.Announcement.prototype.render.apply(this, arguments);

        this.$el.html(this.template({ isMobile: app.get("device").is.mobile }));
        return this;
      },

      onDismiss: function(evt) {
        utils.setCookie("noSbg", 1);
        app.views.Announcement.prototype.onDismiss.apply(this, arguments);
      },

      onNavigate: function(evt) {
        this.onDismiss(evt);
        window.location.href = $(evt.currentTarget).attr("href");
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
