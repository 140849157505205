var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"button-group dropdown\">\n  <button class=\"btn btn-text btn-options dropdown-toggle\" data-toggle=\"dropdown\" aria-controls=\"more-options\" aria-expanded=\"false\" aria-label=\"more options\">\n"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",20,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n  <div class=\"triangle\"></div>\n  <div class=\"dropdown-menu align-right\">\n    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"CommentOptions",(depth0 != null ? depth0.id : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n\n<div class=\"message-report-modal\">\n</div>\n";
},"useData":true});