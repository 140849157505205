app.router.add({
  "catalog/:catalog": [
    "catalog",
    {
      regexp: {
        catalog: /[\w\-]+/
      }
    }
  ]
});
