(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ContestForm",

    app.views.DummyReactView.extend({
      events: {
        "click  .on-contest-popover": "onContestFormPopover",
        "tap  .on-contest-popover": "onContestFormPopover",
        "tap .on-okay": "onPopoverOk",
        "click .on-okay": "stopEvent"
      },
      component: "ContestForm",

      componentId: "contest-form",

      constructor: function(opts) {
        var options = {
          componentData: opts.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      initPopover: function(dom, mainContent, secondaryContent, href, okDom) {
        var popOverTemplate =
          '<div class="popover" role="tooltip" data-trigger="focus" ><div class="arrow"></div><h3 class="popover-title"></h3>' +
          '<div class="popover-content"></div></div>';

        var content = "";
        if (mainContent) {
          content += "<div class='main-content'>" + mainContent;

          if (secondaryContent) {
            content +=
              "<div class='secondary-content'>" + secondaryContent + "</div>";
          }
          content += "</div>";
        }

        content += "<div class='tooltip-footer'>";
        if (href) {
          content +=
            "<a target='_blank' href=" +
            href +
            ">" +
            wattpad.utils.trans("Learn More") +
            "</a>";
        }
        content += "<button type='button' class='btn on-okay btn-purple";
        if (okDom) {
          content += okDom;
        }
        content += "'>" + wattpad.utils.trans("OK") + "</button></div>";

        this.$(dom)
          .popover({
            template: popOverTemplate,
            html: true,
            placement: "auto",
            content: function() {
              return content;
            }
          })
          .on("shown.bs.popover", function(e) {
            var popover = $(e.target);
            $(document).on("click.closePopover", function(e) {
              if ($(e.target).parents(".popover").length === 0) {
                popover.popover("hide");
              }
            });
          })
          .on("hide.bs.popover", function(e) {
            $(document).off("click.closePopover");
          })
          .on("hidden.bs.popover", function(e) {
            $(".multi-select").removeClass("disable-click");
          });
      },

      onPopoverOk: function(evt) {
        evt.preventDefault();
        $(".popover").popover("hide");
        $(".popover").css("display", "none");
        $(".multi-select").addClass("disable-click");
      },

      onContestFormPopover: function(evt) {
        evt.preventDefault();
        var trans = wattpad.utils.trans;

        var mapLanguageToLinks = {
          1: "https://www.wattpad.com/1229517874-the-2022-watty-awards-how-to-submit", //English
          2: "https://www.wattpad.com/1231193196-les-wattys-2022-comment-soumettre", //French
          3: "https://www.wattpad.com/1231200512-wattys-2022-come-iscriversi", // Italian
          4: "https://www.wattpad.com/1231211429-die-watty-awards-2022-wie-kann-eingereicht-werden", //German
          5: "https://www.wattpad.com/1231224269-los-premios-watty-2022-c%C3%B3mo-inscribirte", //Spanish
          6: "https://www.wattpad.com/1231203499-pr%C3%AAmio-wattys-2022-como-se-inscrever", //Portuguese
          18: "https://www.wattpad.com/1230991528-ang-2022-watty-awards-paano-magsumite", //Filipino
          20: "https://www.wattpad.com/1231219920-penghargaan-wattys-2022-cara-mendaftar", //Indonesian
          23: "https://www.wattpad.com/1231197790-2022-watty-%C3%B6d%C3%BClleri-nas%C4%B1l-ba%C5%9Fvurulur" //Turkish
        };

        this.initPopover(
          "#content-tooltip",
          trans("What is mature content?"),
          trans(
            "These ratings help us understand if your story has sexual content, and if it does, to what magnitude. Stories with any level of sexual content are eligible to win, as long as they abide by our content guidelines."
          ),

          mapLanguageToLinks[app.get("language")]
        );
      }
    })
  );

  app.mixin(app.views.ContestForm);
})(window, _, wattpad, window.app);
