import chooseCategoryModal from "core/templates/components/choose-category-modal.hbs";
import browseCategory from "platform/browse/templates/browse-category.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "CoreBrowseCategory",
    app.views.CoreBrowseStories.extend({
      template: browseCategory,
      events: _.extend(
        {
          "tap .on-change-category": "onChangeCategory",
          "click .on-change-category": "stopEvent"
        },
        app.views.CoreBrowseStories.prototype.events
      ),

      initialize: function(options) {
        options = options || {};
        this.category = options.category;
        this.filter = options.filter || "hot";
        app.views.CoreBrowseStories.prototype.initialize.apply(this, arguments);
        var device = app.get("device");
        if (device.is.mobile) {
          Handlebars.registerPartial(
            "core.components.choose_category_modal",
            chooseCategoryModal
          );
        }
      },

      render: function() {
        var data = { active: {} };
        var locale = wattpad.utils.getCookie("locale");
        if (!locale || locale.trim().length === 0) {
          locale = "en_US"; //default to english
        }
        data.isEnglish = locale === "en_US";
        data.active[this.filter] = true;
        data.category = this.category.toJSON();
        data.categories = app.get("categories").toJSON();
        _.forEach(data.categories, function(category) {
          if (data.category.name == category.name) {
            category.selected = true;
          } else {
            category.selected = false;
          }
        });
        data.dp = window.devicePixelRatio >= 2 ? 2 : 1;
        data.category.subtitle = utils.categorySubtitle(data.category);
        this.$el.html(this.template(data));
        this.setTabContent();
      },

      onChangeCategory: function(evt) {
        var device = app.get("device");
        if (device.is.mobile) {
          this.$(".choose-category-modal").modal({ show: true });
          return;
        }
        // Below is only for desktop
        var categorySlug = $(evt.currentTarget).data("category");
        var category = app
          .get("categories")
          .find(function(model, index, collection) {
            return (
              wattpad.utils.slugify(model.get("name_english")) ===
              categorySlug.toLowerCase()
            );
          });
        app.router.navigate("/stories/" + categorySlug);
        this.category = category;
        this.filter = "hot"; //reset the filters
        wattpad.utils.setTitle(
          utils.trans("%s Stories and Books Free", category.get("name_english"))
        );
        this.render();
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
