// @ts-check

/**
 * TODO
 * Compare total fields in frontend with fields used in web-ads
 * Remove any unused fields / consolidate, update unknown 'any' fields
 */

/**
 * Compiles and formats metadata
 * into adData for passing to ads library
 * Use this to format parameters when you're setting up AdContainers
 * @param {adContextInput} adContext - page context metadata for parsing
 * @param {string} [placement] - Placement name
 * @returns {adData}
 */
module.exports = (adContext, placement) => {
  let adData = {};

  // Required props
  adData.testGroups = adContext.testGroups;

  adData.context = {};

  // Optional props
  if (placement) {
    adData.placement = placement;
  }
  if (adContext.deviceType) {
    adData.deviceType = adContext.deviceType;
  }
  if (adContext.userGeo) {
    adData.userGeo = adContext.userGeo;
  }
  if (adContext.group) {
    adData.context.story = processGroupMetadata(adContext.group);
  }
  if (adContext.part) {
    adData.context.storyPart = processPartMetadata(adContext.part);
  }
  if (adContext.user) {
    adData.context.user = processUserMetadata(adContext.user);
  }

  return adData;
};

function processUserMetadata(user) {
  const { isPremium } = user;

  return {
    isPremium
  };
}

function processGroupMetadata(group) {
  const {
    isAdExempt,
    isBrandSafe,
    isPaywalled,
    rating,
    imagesUnderModeration,
    categories,
    language,
    user,
    brandSafetyLevel,
    brandSafetySource
  } = group;

  return {
    totalViewCount: group.readCount,
    category: group.categories[0],
    id: parseInt(group.id, 10),
    isAdExempt,
    isBrandSafe,
    isPaywalled,
    rating,
    imagesUnderModeration,
    categories,
    language,
    user,
    brandSafetyLevel,
    brandSafetySource
  };
}

function processPartMetadata(part) {
  const {
    id,
    brandSafetyLevel,
    brandSafetySource,
    rank,
    rating,
    imagesUnderModeration
  } = part;

  return {
    totalViewCount: part.readCount,
    id,
    brandSafetyLevel,
    brandSafetySource,
    rank,
    rating,
    imagesUnderModeration
  };
}

/**
 * Types
 */

/**
 * @typedef {Object} AdStoryGroup
 * @property {boolean} isAdExempt - Indicates if a story is ad exempt
 * @property {boolean} isBrandSafe - Indicates if a story is safe for ads to be served
 * @property {boolean} isPaywalled - Indicates if a story has paywalled parts
 * @property {number} rating - A story's rating (1-4)
 * @property {boolean} [imagesUnderModeration] - Whether a story has images under moderation or not
 * @property {Array} [categories]
 * @property {any} [category]
 * @property {number} language // Confirm what this will be here
 * @property {Object} user
 * @property {number} id
 * @property {any} brandSafetyLevel
 * @property {any} brandSafetySource
 * @property {any} totalViewCount // readCount
 */

/**
 * @typedef {Object} AdStoryPart
 * @property {any} id
 * @property {any} brandSafetyLevel
 * @property {any} brandSafetySource
 * @property {any} rank
 * @property {any} rating
 * @property {any} totalViewCount
 * @property {any} imagesUnderModeration
 */

/**
 * @typedef {Object} AdUser
 * @property {boolean} isPremium - If a user is premium or not
 */

/**
 * @typedef {Object} AdContext
 * @property {AdStoryGroup} [story]
 * @property {AdStoryPart} [storyPart]
 * @property {AdUser} [user]
 */

/**
 * @typedef {Object} adData
 * @property {string} [placement]
 * @property {Object} testGroups
 * @property {string} deviceType
 * @property {string} userGeo
 * @property {AdContext} context
 */

/**
 * @typedef {Object} adContextInput
 * @property {Object} [group] - story group metadata
 * @property {Object} [part] - story part metadata
 * @property {Object} [user] - user metadata
 * @property {string} [deviceType] - user's device
 * @property {string} [userGeo] - User's location
 * @property {Object} testGroups - Current testGroups
 */
