(function(window, _, $, wattpad, utils, app, Monaco, Promise) {
  "use strict";

  /*  Drag and Sort Mixin
    Automatically adds sorting drag and sort capabilities to a view with the required HTML
    The base view function `onDragEnd` will automatically be called when appropriate.

    To add Drag and Sort functionality:
    - add 'data-drag="true"' attribute to the parent container (the mixin will add ".drag-item" to all direct children of this container)
    - drag-and-sort.less includes default styling for the drag handlers and placeholders.

    Note:
    - when overwriting onDragEnd to make an API call, don't forget to use utils.cacheBust
    - library/library-lists-landing.js include examples of how to incorporate the Drag and Sort mixin.

    Css Classes:
                 - Item being dragged:       .drag-item and .active-drag
                 - Item being dragged over:  .drag-item-placeholder
  */

  app.add(
    "DragAndSort",
    Monaco.Mixin.create({
      events: {
        "touchstart .drag-handle": "onMouseDown",
        "mousedown .drag-handle": "onMouseDown"
      },

      // Namespaced variables
      DragAndSort: {
        clicked: false,

        drop: "Before",
        dragging: false,

        element: null,
        elementUnder: null,
        elementPlaceHolder: null,

        minimumDelta: 10, //Pixels before we allow dragging

        scrollRate: 0,
        intervalPointer: null,
        windowThird: 0,
        maxScroll: 0,

        mouseEvents: {
          move: "mousemove",
          end: "mouseup"
        },

        touchEvents: {
          move: "touchmove",
          end: "touchend"
        }
      },

      setElement: function(el, next) {
        next(el);

        // Attach classes
        this.setupDOM();
      },

      render: function(next) {
        // Make sure we've rendered first
        var result = next();

        // Attach classes
        this.setupDOM();

        return result;
      },

      setupDOM: function(next) {
        var context = this.DragAndSort;

        // Enable dragging on the DOM
        this.$("[data-drag]")
          .children()
          //.attr( 'draggable', true )
          .addClass("drag-item");

        // Create the placeholder element
        context.elementPlaceHolder =
          context.elementPlaceHolder ||
          $('<div class="drag-item-placeholder drag-item">');

        //NO-OP
        next();
      },

      resolveDrag: function(next) {
        var context = this.DragAndSort;
        context.element.css(
          "top",
          context.offsetY + context.scrollOffset + context.startoffset
        );
        next();
      },

      movePlaceholder: function(nextt) {
        var context = this.DragAndSort,
          next = context.elementPlaceHolder.next(),
          prev = context.elementPlaceHolder.prev(),
          location =
            context.offsetY + context.scrollOffset + context.startoffset;

        if (next[0] === context.element[0]) {
          next = next.next();
        }

        if (next.length && location > next.position().top) {
          context.elementPlaceHolder.detach();
          next.after(context.elementPlaceHolder);
        } else if (
          prev.length &&
          location < context.elementPlaceHolder.position().top
        ) {
          context.elementPlaceHolder.detach();
          prev.before(context.elementPlaceHolder);
        }

        nextt();
      },

      onMouseDown: function(evt, next) {
        var $el,
          self = this,
          context = this.DragAndSort,
          realEvent;

        var touches = evt.touches || evt.originalEvent.touches;
        if (touches && touches.length) {
          realEvent = touches[0];
        } else {
          realEvent = evt;
        }

        if (evt.which !== 1 && evt.type === "mousedown") {
          return;
        }

        utils.stopEvent(evt);

        // Keep track of the mouse having been clicked
        context.clicked = true;
        context.startY = realEvent.clientY;
        context.scrollOffset = 0;
        context.scrollRate = 0;
        context.scrollY = 0;

        context.windowThird = $(window).height() / 3;
        context.maxScroll =
          $(window.document).height() - $(window).height() - 50;

        context.element = $el = $(evt.currentTarget).parents(".drag-item");
        context.elementPlaceHolder
          .css("height", $el.outerHeight())
          .css("width", $el.width());

        $el.width($el.width());

        // Listen to both touch and mouse events
        $(window.document)
          .on(this.DragAndSort.mouseEvents.move, function(evt) {
            self.onDrag(evt);
          })
          .on(this.DragAndSort.mouseEvents.end, function(evt) {
            self.onDragEnd(evt);
          })
          .on(this.DragAndSort.touchEvents.move, function(evt) {
            self.onDrag(evt);
          })
          .on(this.DragAndSort.touchEvents.end, function(evt) {
            self.onDragEnd(evt);
          });

        next();
      },

      onDrag: function(evt, next) {
        var context = this.DragAndSort,
          touches = evt && (evt.touches || evt.originalEvent.touches);

        if (!_.isEmpty(touches)) {
          var ev = touches[0];
        } else {
          var ev = evt;
        }

        utils.stopEvent(evt);

        //calculate our offset from the movement start
        context.clientY = ev ? ev.clientY : context.clientY;
        context.offsetY = context.clientY - context.startY + context.scrollY;

        if (
          context.clicked &&
          !context.dragging &&
          (context.offsetY > context.minimumDelta ||
            context.offsetY < -context.minimumDelta)
        ) {
          context.startoffset = context.element.position().top;
          context.scrollStart =
            window.pageYOffset || window.document.documentElement.scrollTop;
          context.element.before(context.elementPlaceHolder);
          context.element.addClass("active-drag");
          context.dragging = true;
        }

        //TODO: Setup scroll interval

        if (context.clientY < context.windowThird) {
          context.scrollRate = -Math.round(
            ((context.windowThird - context.clientY) / context.windowThird) * 20
          );
        } else if (context.clientY > context.windowThird * 2) {
          context.scrollRate = Math.round(
            ((context.clientY - context.windowThird * 2) /
              context.windowThird) *
              20
          );
        } else {
          context.scrollRate = 0;

          if (context.intervalPointer) {
            window.clearInterval(context.intervalPointer);
            context.intervalPointer = null;
          }
        }

        if (context.scrollRate !== 0 && !context.intervalPointer) {
          context.intervalPointer = this.onScroll();
        }

        //Resolve Dragging
        if (context.dragging) {
          this.resolveDrag();
          this.movePlaceholder();
        }
        next();
      },

      onScroll: function() {
        var self = this,
          context = this.DragAndSort;

        return window.setInterval(function() {
          if (
            (window.scrollY > 100 && context.scrollRate < 0) ||
            (window.scrollY < context.maxScroll && context.scrollRate > 0)
          ) {
            window.scrollBy(0, context.scrollRate);
            context.scrollY += context.scrollRate;
            self.onDrag();
          }
        }, 20);
      },

      onDragEnd: function(evt, next) {
        var context = this.DragAndSort;

        utils.stopEvent(evt);

        //TODO: Switch for touch events
        $(window.document)
          .off(this.DragAndSort.mouseEvents.move)
          .off(this.DragAndSort.mouseEvents.end)
          .off(this.DragAndSort.touchEvents.move)
          .off(this.DragAndSort.touchEvents.end);

        if (context.intervalPointer) {
          window.clearInterval(context.intervalPointer);
          context.intervalPointer = null;
        }
        context.clicked = false;

        // Move things if you're actually dragging and not clicking
        if (context.dragging === true) {
          context.dragging = false;
          context.element.removeAttr("style");
          context.element.removeClass("active-drag");
          context.elementPlaceHolder.replaceWith(context.element);

          next(); // calls view's onDragEnd(), if specified
        }
      }
    })
  );
})(
  window,
  _,
  jQuery,
  wattpad,
  wattpad.utils,
  window.app,
  window.Monaco,
  window.Promise
);
