(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:privacySettings",
    app.router.filter(["getTranslations"], function() {
      var showPage = _.get(wattpad, ".testGroups.CAN_VIEW_CCPA") || false;

      if (!showPage) {
        wattpad.utils.redirectToCSR("/home");
        return;
      }

      var privacySettings = new app.views.DummyReactView({
        component: "PrivacySettings",
        componentId: "privacy-settings"
      });

      app.transitionTo(privacySettings, {
        hasHeader: false,
        hasFooter: true
      });
    })
  );
})(window, wattpad, window.app);
