var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "      "
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"posted a message to",{"name":"trans","hash":{},"data":data}))
    + "\n      <a class=\"on-user\"\n       data-username=\""
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n       href=\"/user/"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n       <strong>"
    + alias1(alias2(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1), depth0))
    + "</strong>\n      </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"warning\">\n  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",14,"wp-lighterror",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This comment may be offensive.",{"name":"trans","hash":{},"data":data}))
    + "</span>\n</div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression;

  return "  <div id=\"replies-"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.id : stack1), depth0))
    + "\"\n      class='replies-list collapse'\n      data-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.id : stack1), depth0))
    + "\"\n      data-profile-owner=\""
    + alias2(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)))
    + "\"\n      data-message-owner=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<header>\n  <a class=\"on-user avatar avatar-sm\"\n     data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n     href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n    <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),32,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n  </a>\n  <div class=\"clearfix\">\n    <a class=\"on-user\"\n     data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n     href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n     <strong>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "</strong>\n    </a>\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.showTarget : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n  <time>"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"dropSuffix":true},"data":data}))
    + "</time>\n</header>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.isOffensive : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<pre>"
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.message : stack1)) != null ? stack1.body : stack1),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n\n"
    + ((stack1 = container.invokePartial(partials["core.events.partials.user_event_footer"],depth0,{"name":"core.events.partials.user_event_footer","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});