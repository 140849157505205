var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<h2><a class=\"on-navigate\" href=\"/stories\">\n  "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Recommended Stories",{"name":"trans","hash":{},"data":data}))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n</a></h2>\n<div class=\"collection\"></div>\n<span class=\"hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please check back later for more recommendations.",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"useData":true});