import searchFilterModal from "core/templates/components/search-filter-modal.hbs";

(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "SearchResults",
    app.views.List.extend({
      templates: {
        searchResults: {}
      },

      events: _.extend(
        {
          "tap .on-setfilter": "setFilter",
          "click .on-setfilter": "stopEvent",

          "tap    #last-updated-filters li a": "onChangeLastUpdate", //for desktop
          "change #last-updated-filters": "onChangeLastUpdate", //for mobile
          "click  #last-updated-filters": "stopEvent",

          "tap    #content-length-filters li a": "onChangeContentLength", //for desktop
          "change #content-length-filters": "onChangeContentLength", //for mobile
          "click  #content-length-filters": "stopEvent",

          "tap .on-show-filter-modal": "showFilterModal",
          "click .on-show-filter-modal": "stopEvent"
        },
        app.views.List.prototype.events
      ),

      className: "feed-item-new panel new-container",

      containerClass: ".list-group",

      initializeListeners: function(collection) {
        collection.once("sync", this.render, this);
        if (collection instanceof app.collections.NodeStorySearch) {
          // Need to update total number and hide loading indicator for stories
          this.listenTo(collection, "fetchNext:done", this.fetchNextDone);
        }
      },

      initialize: function(options) {
        this.advancedFilters = {};
        this.dataKey = options.dataKey;
        this.templates.searchResults[this.dataKey] = options.template;

        Handlebars.registerPartial(options.partial, function() {
          return "";
        });

        app.views.List.prototype.initialize.apply(this, arguments);

        this.initializeListeners(this.collection);

        if (app.get("device").is.mobile) {
          _.bindAll(this, "trackViews");
          this.initializeTracking();
        }

        if (this.collection.searchTerm.trim().length > 0) {
          this.collection.fetch();
        } else {
          this.render();
        }

        var device = app.get("device");
        if (device.is.mobile) {
          Handlebars.registerPartial(
            "core.components.search_filter_modal",
            searchFilterModal
          );
        }
      },

      initializeTracking: function() {
        if (
          this.collection instanceof app.collections.NodeStorySearch &&
          app.get("device").is.mobile
        ) {
          $(window).off("scroll", this.trackViews);
          this.viewedPromotedStories = [];
          this.viewedStories = [];
          $(window).on("scroll", this.trackViews);
        }
      },

      remove: function() {
        if (app.get("device").is.mobile) {
          $(window).off("scroll", this.trackViews);
        }
        app.views.List.prototype.remove.apply(this, arguments);
      },

      render: function() {
        var data = this.advancedFilters;

        data[this.dataKey.toLowerCase()] = this.collection.toJSON();
        data["num" + this.dataKey] =
          this.collection.total || this.collection.models.length;
        data.showMore = this.collection.hasMore
          ? this.collection.hasMore()
          : false;
        data.isMatureUser = utils.currentUser().get("isMature");
        data.isMobile = app.get("device").is.mobile;
        data.showTotal = !data.isMobile || !data.showMore;
        data.searchTerm = this.collection.searchTerm;
        data.nextUrl = this.collection.nextUrl;
        data.resultType =
          this.dataKey.toLowerCase() === "users" ? "people" : "stories";

        this.$el.html(this.templates.searchResults[this.dataKey](data));
        this.$el.find(this.containerClass).empty();

        this.renderCollection();
        this.setButtonState();
        this.bindEvents();

        // After the search results load, check if any promoted stories are on screen
        // If so, send impression events immediately
        if (
          this.collection instanceof app.collections.NodeStorySearch &&
          app.get("device").is.mobile
        ) {
          var self = this;
          _.defer(function() {
            self.trackViews();
          });
        }

        return this;
      },

      renderItem: function(model, $container, index) {
        app.views.IncrementalList.prototype.renderItem.apply(this, arguments);
        if (model instanceof app.models.StoryModel) {
          index = index || this.collection.indexOf(model);
          $container = $container || this.getContainer();
        }
      },

      onChangeContentLength: function(event) {
        this.setFilterGroup(event, "length");
      },

      onChangeLastUpdate: function(event) {
        this.setFilterGroup(event, "age");
      },

      setFilterGroup: function(event, filter) {
        var value;
        var currentTarget = event.currentTarget;
        if (currentTarget.selectedOptions) {
          value = $(currentTarget.selectedOptions[0]).val();
        } else {
          value = $(currentTarget).val();
        }
        if (!value) {
          return;
        }
        // remove current ads
        this.getContainer()
          .find(".ad-unit")
          .remove();
        this.collection.reset();
        this.advancedFilters[filter] = value;
        this.$(".fa.searching").removeClass("hidden");
        this.collection.setFilter(filter, value);
        this.collection.fetch({ fresh: true });
      },

      setFilter: function(event) {
        var $element = $(event.currentTarget);
        $element.toggleClass("active");

        this.getContainer()
          .find(".ad-unit")
          .remove(); // remove current ads
        this.collection.reset();
        this.collection.setFilter(
          $element.data("filter"),
          $element.hasClass("active")
        );
        this.$(".fa.searching").removeClass("hidden");
        this.collection.fetch({ fresh: true });
      },

      showFilterModal: function(event) {
        wattpad.utils.stopEvent(event);

        var device = app.get("device");
        if (device.is.mobile) {
          this.$(".search-filter-modal").modal({ show: true });
          return;
        }
      },

      setButtonState: function() {
        // Old show more button is no longer used for user profile results
        if (this.dataKey === "Users") {
          return;
        }

        if (
          typeof this.collection.hasMore === "function" &&
          this.collection.hasMore()
        ) {
          $(".on-showmore")
            .parent()
            .removeClass("hidden");
        } else {
          $(".on-showmore")
            .parent()
            .addClass("hidden");
        }
      },

      fetchNextDone: function() {
        // Below only apply to stories
        var total = this.collection.total;
        if (total !== null) {
          var totalInGoodFormat = wattpad.utils.count(total);
          var newTotal = utils.sprintf(
            wattpad.utils.ngettext("%s Story", "%s Stories", total),
            [totalInGoodFormat]
          );
          this.$("#header-left").text(newTotal);
        } else {
          // This is a temporary fix for the case when we cannot get back total field from the collection
          this.$("#header-left").text("");
        }
        this.$(".fa.searching").addClass("hidden");
      },

      newSearch: function(newCollection) {
        this.stopListening(this.collection);

        this.collection = newCollection;

        this.initializeListeners(this.collection);
        this.initializeTracking();

        // Show loading indicator and hide when fetch done
        this.$(".fa.searching").removeClass("hidden");

        return newCollection;
      },

      trackViews: _.throttle(function() {
        var self = this;
        _.each(this.collection.models, function(story) {
          var storyId = story.id,
            sources = story.sources || "",
            index = self.collection.indexOf(story),
            searchTerm = self.collection.searchTerm || "";

          if (_.indexOf(self.viewedStories, storyId) === -1) {
            const mobile = utils.getDeviceType() === "mobile";
            const storyEl = mobile
              ? self.$("ul").find("[data-id='" + storyId + "'].shown-xxs")
              : self.$("ul").find("[data-id='" + storyId + "'].hidden-xxs");
            if (utils.isOnScreen(storyEl, 0.5)) {
              self.viewedStories.push(storyId);
              window.te.push("event", "story", null, null, "view", {
                storyid: storyId,
                page: "search",
                position: index,
                query: [searchTerm],
                algo_source: sources
              });
            }
          }
        });

        var promotedStories = this.collection.getPromotedStories();
        _.each(promotedStories, function(story) {
          var storyId = story.id;

          if (_.indexOf(self.viewedPromotedStories, storyId) === -1) {
            var storyEl = self.$("ul").find("[data-id='" + storyId + "']");

            if (utils.isOnScreen(storyEl, 0.67)) {
              self.viewedPromotedStories.push(storyId);
              var viewTrackingUrls = [story.tracking.impressionUrl];

              if (story.tracking.thirdParty) {
                viewTrackingUrls = viewTrackingUrls.concat(
                  story.tracking.thirdParty.impressionUrls
                );
              }
              _.each(viewTrackingUrls, function(url) {
                $.ajax({
                  type: "GET",
                  url: url
                });
              });
            }
          }
        });
      }, 200)
    })
  );

  app.mixin(app.views.SearchResults, "InfiniteScroll");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
