var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"valueprops-container container-fluid\">\n  <div class=\"valueprops\">\n      <div class=\"valueprop\">\n        <h2 id=\"write\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Write stories",{"name":"trans","hash":{},"data":data}))
    + " </h2>\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Whatever story you want to tell, there is a reader waiting for you on Wattpad.",{"name":"trans","hash":{},"data":data}))
    + "\n      </div>\n      <div class=\"valueprop\">\n        <h2 id=\"social\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Read socially",{"name":"trans","hash":{},"data":data}))
    + " </h2>\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Inline commenting lets you share thoughts and interact with the story.",{"name":"trans","hash":{},"data":data}))
    + "\n      </div>\n      <div class=\"valueprop\">\n        <h2 id=\"updates\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get updates",{"name":"trans","hash":{},"data":data}))
    + " </h2>\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Receive real-time notifications as your favorite stories unfold.",{"name":"trans","hash":{},"data":data}))
    + "\n      </div>\n  </div>\n</div>\n";
},"useData":true});