(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:retargeting_landing",
    app.router.filter(["getTranslations"], function() {
      var view = new app.views.RetargetingLanding();

      app.transitionTo(view, {
        hasHeader: false,
        hasFooter: false
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
