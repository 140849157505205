(function(window, app) {
  "use strict";

  // New curation experiment landing page /featured/{list-id}
  app.router.add({
    "featured(/:list)": [
      "featured_picks",
      {
        regexp: {
          list: /[0-9]*/
        }
      }
    ]
  });
})(window, window.app);
