var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"TagpagePaidStoriesContainer","tagpage-paid-stories",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.components.browse_tags"],depth0,{"name":"core.components.browse_tags","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div class=\"browse-results\">\n"
    + ((stack1 = container.invokePartial(partials["desktop.browse.browse_results"],depth0,{"name":"desktop.browse.browse_results","data":data,"indent":"          ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"container\">\n  <header class=\"background text-left\">\n    <h1> "
    + alias3(((helper = (helper = helpers.browseLabel || (depth0 != null ? depth0.browseLabel : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"browseLabel","hash":{},"data":data}) : helper)))
    + " </h1>\n  </header>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"PAID_ROW_TAG_PAGES",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  <div class=\"tags-container\">\n    <div id=\"refine-by-msg\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Refine by tag:",{"name":"trans","hash":{},"data":data}))
    + "</div>\n    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"TagCarousel","relatedTags",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"browse-content-container\">\n    <div id=\"browse-header-container\">\n"
    + ((stack1 = container.invokePartial(partials["core.components.browse_header"],depth0,{"name":"core.components.browse_header","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n    <main id=\"browse-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showExploreList : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "    </main>\n  </div>\n</div>\n<div class=\"right-rail gutter\">\n  <div class=\"sticky\">\n    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"AdContainer","tag-search-ad",(depth0 != null ? depth0.tagSearchProps : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});