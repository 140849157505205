import profileFollowModal from "platform/profile/templates/profile-follow-modal.hbs";
import profileFollowersFollowingModal from "core/templates/profile/profile-followers-following-modal.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "ProfileFollowersFollowingModal",
    app.views.IncrementalList.extend({
      className: "profile-followers-following-modal modal fade",
      template: profileFollowersFollowingModal,
      itemView: app.views.UserRowItem,
      containerId: "#user-row-items",

      initialize: function(options) {
        options = options || {};

        this.isMobile = app.get("device").is.mobile;
        this.showRemoveFollowersOption = false;

        if (options.showFollowing) {
          this.title = utils.sprintf("%s %s", [
            utils.format(this.collection.total),
            utils.trans("Following")
          ]);
        } else {
          if (
            this.model &&
            this.model.get("isPrivate") &&
            this.model.get("username") ===
              wattpad.utils.currentUser().get("username")
          ) {
            this.showRemoveFollowersOption = true;
          }

          this.title = utils.sprintf(
            utils.ngettext(
              "%s Follower",
              "%s Followers",
              this.collection.total
            ),
            [utils.format(this.collection.total)]
          );
        }

        if (this.isMobile) {
          Handlebars.registerPartial(
            "mobile.profile.profile_follow_modal",
            profileFollowModal
          );
        } else {
          Handlebars.registerPartial(
            "desktop.profile.profile_follow_modal",
            profileFollowModal
          );
        }

        this.listenTo(app, "app:user:follow", this.updateToFollowingButton);
        this.listenTo(app, "app:user:unfollow", this.updateToFollowButton);
      },

      showModal: function() {
        this.$el.modal("show");
      },

      getTemplateData: function() {
        return {
          isMobile: this.isMobile,
          title: this.title,
          hasMore: this.collection.offset < this.collection.total
        };
      },

      getItemViewOptions: function() {
        return { showRemoveFollowersOption: this.showRemoveFollowersOption };
      },

      // Override onShowMore() in incremental-list.js
      // Need to setup the newly fetched users for the FollowManager mixin
      onShowMore: function(event) {
        if (event) {
          utils.stopEvent(event);
        }

        this.$(".show-more-message").addClass("hidden");
        this.$(".loading-message").removeClass("hidden");

        var self = this;
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          self.setupDOM(); // for the FollowManager mixin

          self.$(".show-more-message").removeClass("hidden");
          self.$(".loading-message").addClass("hidden");
        });
      },

      updateToFollowingButton: function(username) {
        var user = this.collection.findWhere({ username: username });

        if (!_.isEmpty(user)) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-white").addClass("btn-teal");
          $btn
            .find(".fa")
            .removeClass("fa-follow fa-wp-base-2-dark")
            .addClass("fa-following fa-wp-neutral-5");
          $btn.find(".truncate").text(utils.trans("Following"));

          user.set("following", true);
        }
      },

      updateToFollowButton: function(username) {
        var user = this.collection.findWhere({ username: username });

        if (!_.isEmpty(user)) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-teal").addClass("btn-white");
          $btn
            .find(".fa")
            .removeClass("fa-following fa-wp-neutral-5")
            .addClass("fa-follow fa-wp-base-2-dark");
          $btn.find(".truncate").text(utils.trans("Follow"));

          user.set("following", false);
        }
      }
    })
  );

  app.mixin(
    app.views.ProfileFollowersFollowingModal,
    "InfiniteScroll",
    "FollowManager"
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
