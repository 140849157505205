(function(window, _, wattpad, app) {
  "use strict";

  /**
   * Beautify URL Mixin
   * Parses text written within a message or post (i.e., Profile Conversations message, reply)
   * for a wattpad story/branch link (i.e. wattpad.com/story/:id, my.w.tt).
   * Takes in metadata for that story and renders a small module "beautifying" that URL.
   */
  app.add(
    "BeautifyURL",
    Monaco.Mixin.create({
      getBeautifyUrlSettings: function(data, next) {
        /* Returns options object for fetching metadata of a posted story group URL. Currently supports either
       * the story url (i.e., wattpad.com/story/:id) or branch link (i.e. my.w.tt).
       */
        next(data);

        var storyUrl = data.body.split(/\s+/).filter(function(word) {
          return word.match(wattpad.siteRoot + "/story");
        })[0];

        var branchUrl = data.body.split(/\s+/).filter(function(word) {
          return word.match("http://my.w.tt/");
        })[0];

        if (storyUrl) {
          var id = storyUrl.match(/[0-9]+/);
          return {
            url: "/api/v3/stories/" + id
          };
        } else if (branchUrl) {
          return {
            url:
              "https://api.branch.io/v1/url?url=" +
              branchUrl +
              "&branch_key=" +
              wattpad.branchKey,
            xhr: function() {
              var xhr = jQuery.ajaxSettings.xhr();
              var setRequestHeader = xhr.setRequestHeader;
              xhr.setRequestHeader = function(name, value) {
                if (name == "Authorization") return;
                setRequestHeader.call(this, name, value);
              };
              return xhr;
            }
          };
        } else {
          return false;
        }
      },

      parseMetadata: function(res, next) {
        next(res);

        var metadata = {
          cover: res.cover || res.data.$og_image_url,
          description: res.description || res.data.$og_description,
          url: res.url || res.data.$fallback_url,
          title: res.title || res.data.$og_title,
          storyId: res.id || res.data.$canonical_identifier.match(/[0-9]+/)[0],
          profileOwner: this.get("ownername") || this.get("username"),
          isReply: this.get("isReply")
        };
        this.set("beautifyUrlMetadata", metadata);
        return metadata;
      },

      getMetadataForBeautifyUrl: function(settings, next) {
        /* Extracts metadata for story group URL -
       * When a user replies to a Profile message (e.g., NewFeedMessageReply) with a story URL,
       * extract the metadata of that url and display the metadata for that story.
       */
        next(settings);
        return Promise.resolve($.get(settings)).then(
          this.parseMetadata.bind(this)
        );
      }
    })
  );
})(window, _, wattpad, window.app);
