"use strict";

const tagRegex = /#[\w]+(?=\s|$)/g;

/**
 * Returns a string array containing all tags in the query
 * @param {String} query - search term
 *
 * @returns {Array<String>}
 */
const getStoryTagsEventAttributes = query => {
  const tags = query?.match(tagRegex);
  const tagsArray = tags
    ? tags.map(tag => {
        return tag.replace("#", "");
      })
    : [];
  return tagsArray;
};

/**
 * Returns a string array containing all length options selected
 * @param {Object} options - selected length options
 *
 * @returns {Array<String>}
 */
const getStoryLengthEventAttributes = options => {
  const length = [];
  if (!options || options?.anyLength) {
    length.push("all");
  }
  if (options?.oneToTen) {
    length.push("1-10");
  }
  if (options?.tenToTwenty) {
    length.push("10-20");
  }
  if (options?.twentyToFifty) {
    length.push("20-50");
  }
  if (options?.moreThanFifty) {
    length.push(">=50");
  }
  return length;
};

/**
 * Returns a string representing the selected last updated option
 * @param {Object} options - selected last updated options
 *
 * @returns {String}
 */
const getStoryLastUpdatedEventAttributes = options => {
  let updateTime = "-1";
  if (options?.year) {
    updateTime = "365";
  } else if (options?.month) {
    updateTime = "30";
  } else if (options?.week) {
    updateTime = "7";
  } else if (options?.day) {
    updateTime = "1";
  }
  return updateTime;
};

/**
 * Returns an object representing the selected content options
 * @param {Object} options - selected last updated options
 * @param {Boolean} isMatureUser - indicates if the user is a mature user
 *
 * @returns {Object}
 */
const getStoryContentEventAttributes = (options, isMatureUser) => {
  let mature;
  if (isMatureUser) {
    mature = options?.mature ? 0 : 1;
  } else {
    mature = 0;
  }
  const complete = options?.complete ? 1 : 0;
  const free = options?.free ? 0 : 1;
  const paid = options?.paid ? 0 : 1;
  return { complete, mature, free, paid };
};

module.exports = {
  getStoryTagsEventAttributes,
  getStoryLengthEventAttributes,
  getStoryLastUpdatedEventAttributes,
  getStoryContentEventAttributes
};
