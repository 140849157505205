(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverListsCollection",
    app.collections.IncrementalFetch.extend({
      model: app.models.ReadingList,

      arrayKey: "lists",

      fields: [
        {
          lists: ["id", "name", "sample_covers", { user: ["name", "avatar"] }]
        },
        "total",
        "nexturl"
      ],

      initialize: function(models, options) {
        options = options || {};
        this.user = options.user || wattpad.utils.currentUser();
        this.url = options.url || this.url;
        this.layout =
          options.layout || wattpad.utils.PanelBuilder.Layouts.Default;
        this.cacheKey = options.cache;

        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      resource: function() {
        if (!this.cacheKey) {
          throw new Error(
            "Discover :: Lists :: Collection :: Please set the Resource"
          );
        }

        return (
          "user." +
          (this.user.get("username") || "anonymous") +
          ".discover." +
          this.cacheKey
        );
      },

      url: function() {
        throw new Error(
          "Discover :: Lists :: Collection :: Please set the URL"
        );
      }
    })
  );
})(window, _, wattpad, window.app);
