var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-remove",20,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-remove",25,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class=\"on-dismiss\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"message\">\n  "
    + container.escapeExpression((helpers.connectImage || (depth0 && depth0.connectImage) || helpers.helperMissing).call(alias1,"wattpad-presents/video-icon.png","Wattpad Presents",{"name":"connectImage","hash":{"klass":"icon"},"data":data}))
    + "\n  <span class=\"text\">\n    <span class=\"title\">Wattpad Presents...</span>\n    <span class=\"subtitle\">Videos based on popular stories!</span>\n  </span>\n</div>\n\n<a href=\"/presents\" class=\"on-navigate\">\n  <span class=\"learn-more\">Watch Now</span>\n</a>\n";
},"useData":true});