var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <li><a href=\"#\" class=\"on-back-to-mobile\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Back to Mobile",{"name":"trans","hash":{},"data":data}))
    + "\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Back to Mobile",{"name":"trans","hash":{},"data":data}))
    + "\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Back to Mobile",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <li><a href=\"/privacy/settings\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Do Not Sell My Personal Information",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Do Not Sell My Personal Information",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div id=\"footer\">\n  <ul class=\"footer-list main\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Footer Links",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = (helpers.isCookieSet || (depth0 && depth0.isCookieSet) || alias2).call(alias1,"mw-no",{"name":"isCookieSet","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li><a href=\"/user/wattpadoriginals\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Originals",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Originals",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a class=\"on-premium\" href=\"/premium\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Try Premium",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Try Premium",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"/getmobile\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the App",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the App",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"/settings/language?jq=true\" class=\"on-language\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"https://creators.wattpad.com/\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writers",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writers",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li aria-hidden=\"true\" role=\"none\">|</li>\n    <li><a href=\"https://brands.wattpad.com\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Brand Partnerships",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Brand Partnerships",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"https://company.wattpad.com/jobs\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Jobs",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Jobs",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"https://company.wattpad.com/press\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Press",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Press",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n  </ul>\n  <ul class=\"footer-list legal\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Legal Footer Links",{"name":"trans","hash":{},"data":data}))
    + "\">\n    <li><a href=\"/terms\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"/privacy\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"https://policies.wattpad.com/payment\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Payment Policy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Payment Policy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li><a href=\"https://policies.wattpad.com/accessibility\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Accessibility",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Accessibility",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"CAN_VIEW_CCPA",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li><a href=\"https://support.wattpad.com\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n    <li>&copy; "
    + alias3(((helper = (helper = helpers.currentYear || (depth0 != null ? depth0.currentYear : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"currentYear","hash":{},"data":data}) : helper)))
    + " Wattpad</li>\n  </ul>\n</div>\n";
},"useData":true});