import errorToast from "core/templates/item-views/error-toast.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "ErrorToast",
    app.views.Base.extend({
      className: "error-toast",

      template: errorToast,

      events: {
        "tap  .on-dismiss": "onDismiss"
      },

      initialize: function(error, options) {
        var errorDefaultMsg = wattpad.utils.trans(
          "An error has occurred. We're sorry about that!"
        );
        this.type = "default";
        this.className = "";
        this.delay = 0;
        this.error = error.message
          ? error
          : error.error || { message: errorDefaultMsg };
        this.dismissAfterDuration = 5000;

        if (options) {
          if (options.className) {
            this.className = options.className;
          }
          if (options.type) {
            this.type = options.type;
          }
          if (options.delay) {
            this.delay = options.delay;
          }
          if (options.dismissAfterDuration) {
            this.dismissAfterDuration = options.dismissAfterDuration;
          }
        }
      },

      render: function() {
        var self = this,
          data = {};

        data.error = this.error;

        if (this.className) this.$el.addClass(this.className);

        if (this.type === "dismissable") {
          this.$el.addClass("dismissable");
          data.dismissable = true;
        }

        $("#toast-container").append(this.$el.html(this.template(data)));
        self.showToast();

        return this;
      },

      showToast: function() {
        var self = this;
        //get height of the el and push it down the page
        var height = this.$el.css("height");
        this.$el.css({
          bottom: parseInt(height) * -1 + "px"
        });

        window.setTimeout(function() {
          self.$el.css({
            bottom: "12px",
            opacity: "1",
            position: "relative"
          });
          if (self.type === "default") {
            self.autoDismiss();
          }
        }, self.delay * 1000);
      },

      onDismiss: function(evt) {
        var self = this;
        this.$el.css("opacity", 0);
        window.setTimeout(function() {
          self.remove();
        }, 500);
      },

      autoDismiss: function() {
        var self = this;
        window.setTimeout(function() {
          self.onDismiss();
        }, this.dismissAfterDuration);
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
