import libraryControls from "core/templates/library/library-controls.hbs";
(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "LibraryControls",
    Monaco.View.extend({
      template: libraryControls,

      render: function() {
        this.$el.html(this.template({}));

        return this;
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
