(function(window, _, wattpad, app) {
  "use strict";

  /**
   * Base Collection for doing incremental API calls
   * Not standalone, but extend call can be light ( min reqs: url, resource, cache info )
   * @Class IncrementalFetch    Collection
   * @constructor
   */
  app.add(
    "IncrementalFetch",
    app.collections.BaseCollection.extend({
      constructor: function(models, options) {
        options = options || {};
        // Properties
        // ----------
        // REQUIRED: string matching the collection array key name returned from the API
        this.arrayKey = options.arrayKey || this.arrayKey;

        // REQUIRED: array of sub-properties from arrayKey you want the API to return
        //   ex => arrayKey: 'users', fields: [ 'username', 'name', 'avatar' ]
        this.fields = options.fields || this.fields;

        // number of items to fetch in a single request
        this.limit = options.limit || this.limit || 10;

        // collection position to begin fetching from
        this.offset = models && models.length ? models.length : 0;

        // total number of items in the collection (returned from the API)
        this.total = null;

        if (typeof this.arrayKey !== "string") {
          throw new Error(
            "IncrementalFetch: this.arrayKey { String } must be specified ( necessary for API request )."
          );
        }
        if (!_.isArray(this.fields)) {
          throw new Error(
            "IncrementalFetch: this.fields { Array } must be specified ( necessary for API request )."
          );
        }

        this.on("reset", this.onReset, this);
        this.on("sync", this.onSync, this);

        // call the original constructor
        app.collections.BaseCollection.prototype.constructor.apply(
          this,
          arguments
        );
      },

      // default resource name
      resource: "incremental.list.unknown",

      // Methods
      // -------

      revertParse: function() {
        var result = app.collections.BaseCollection.prototype.revertParse.apply(
          this,
          arguments
        );
        this.revertParseExtras(result);
        return result;
      },

      revertParseExtras: function(result) {},

      // fetches the next set of items from the API  =>  returns `XHR / Promise`
      // updates the collection offset for subsequent requests
      fetchNextSet: function(options) {
        if (this.total === null || this.total > this.offset) {
          options = options || {};

          //Extend the data object first
          options.data = _.extend(
            {
              offset: this.offset,
              limit: this.limit
            },
            options.data || {}
          );

          //Extend the remaining options
          options = _.extend(
            {
              fresh: this.total !== null,
              remove: false
            },
            options
          );

          return this.fetch(options);
        }
        // if fetch conditions are not met, return a rejected promise
        // TODO: update to A+/es6 compliance
        return $.Deferred()
          .reject()
          .promise();
      },

      // called on sync events
      onSync: function() {
        this.updateOffset.apply(this, arguments);
        this.trigger("fetchNext:done");
      },

      updateOffset: function() {
        this.offset = this.models.length;
      },

      hasMore: function() {
        return this.offset < this.total;
      },

      //clear the cached total and offset
      onReset: function() {
        this.total = null;
        this.offset = 0;
      }
    })
  );
})(window, _, wattpad, window.app);
