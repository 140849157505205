(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "Authreset",
    Monaco.Model.extend({
      defaults: {
        confirm: null,
        password: null
      },

      validationRules: {
        password: [
          { func: "isRequired", msg: utils.trans("Password is required.") },
          {
            func: "minLength",
            length: 6,
            msg: utils.trans(
              "Your password must be at least 6 characters long."
            )
          },
          {
            func: "maxLength",
            length: 20,
            msg: utils.trans(
              "Your password may be no longer than 20 characters."
            )
          }
        ],
        confirm: [
          {
            func: "isRequired",
            msg: utils.trans("Please confirm your password.")
          },
          {
            func: "isMatch",
            matchesKey: "password",
            msg: utils.trans("Passwords must match.")
          }
        ]
      }
    })
  );

  app.mixin(app.models.Authreset, "ValidationModel", "GetAppValRules");
})(window, _, wattpad, wattpad.utils, window.app);
