var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<button type=\"button\" class=\"btn btn-link pull-right close\" data-dismiss=\"modal\">\n    <span aria-hidden=\"true\" class=\"fa fa-remove\"></span><span class=\"sr-only\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Close",{"name":"trans","hash":{},"data":data}))
    + "</span>\n</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = "";

  stack1 = ((helper = (helper = helpers.close || (depth0 != null ? depth0.close : depth0)) != null ? helper : alias2),(options={"name":"close","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.close) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "<div id=\"embed-modal\">\n    <h4>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Embed this Story",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n    <h5>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Preview",{"name":"trans","hash":{},"data":data}))
    + "</h5>\n    <iframe width=\"500\" height=\"280\" data-href=\"https://embed.wattpad.com/story/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "?quote_text="
    + alias4(((helper = (helper = helpers.quoteText || (depth0 != null ? depth0.quoteText : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"quoteText","hash":{},"data":data}) : helper)))
    + "\"\n            frameborder=\"0\" allowfullscreen=\"\"></iframe>\n    <div class=\"on-select embed-code\">\n      <span id=\"embed-url\">\n          &lt;iframe width=\"500\" height=\"280\" frameborder=\"0\" allowfullscreen=\"\"\n          src=\"https://embed.wattpad.com/story/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n          &gt;&lt;/iframe&gt;\n      </span>\n    </div>\n</div>\n";
},"useData":true});