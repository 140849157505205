(function(window, _, wattpad, utils, app) {
  "use strict";

  const fullDesktopWidth = 992;

  /**
   * Used to manage placement and refreshing of refreshing ads
   * @mixin RefreshAds
   *
   */
  app.add(
    "RefreshAds",
    Monaco.Mixin.create({
      render: function(next) {
        var self = this;
        // call the base view's render so the DOM has contents before we attempt to load ads
        var renderedView = next();

        self.refreshingAds = [];
        self.isRefreshing = false;

        self.listenTo(app, "checkRefreshingAds", self.checkRefreshingAds);

        return renderedView;
      },

      remove: function(next) {
        var self = this;
        self.stopListening(app, "checkRefreshingAds", self.checkRefreshingAds);
        next();
      },

      setIsRefreshing: function(noop) {
        var self = this;
        self.isRefreshing = true;
        window.setTimeout(function() {
          self.isRefreshing = false;
        }, 5000);

        noop();
      },

      checkRefreshingAds: function(pageData, noop) {
        var self = this;
        var hasRegisteredRefreshingAd = self.refreshingAds.length > 0;
        var pageNumber = pageData.pageNumber || null;
        var isCurrentUserPremium =
          utils.getCurrentUserAttr("isPremium") || false;

        if (
          window.innerWidth < fullDesktopWidth ||
          isCurrentUserPremium ||
          self.isRefreshing ||
          !pageData ||
          !pageNumber ||
          pageNumber === 1
        ) {
          return noop();
        }

        if (hasRegisteredRefreshingAd) {
          self.refreshAd(pageNumber, self.refreshingAds[0].id);
        } else {
          self.insertStickyAd(pageData);
        }
        noop();
      },

      refreshAd: function(activePage, id, noop) {
        var self = this;
        var registeredRefreshingAd = _.find(
          self.refreshingAds,
          { id: id },
          false
        );

        if (!registeredRefreshingAd) {
          return noop();
        }

        /**
         * Only refresh if:
         * Ad exists to refresh
         * Not on first page
         * If on p2, ad is both already loaded and has refreshed
         * User is navigating to any other page
         */
        if (
          activePage > 2 ||
          (activePage === 2 && registeredRefreshingAd.hasRefreshed)
        ) {
          registeredRefreshingAd.timesRefreshed++;
          registeredRefreshingAd.hasRefreshed = true;
          self.setIsRefreshing();
          var new_id = window.wattpad.utils.refreshAd(id);
          registeredRefreshingAd.id = new_id;
        }
        noop();
      },

      insertStickyAd: function(pageData, noop) {
        var self = this;
        var currentPage = pageData.pageNumber;
        var ts = Date.now();
        var user = wattpad.utils.currentUser().get("username");
        var adId, adUnit;
        if (typeof user !== "undefined" && user !== null) {
          adUnit = "readinglong_mid";
        } else {
          adUnit = "readinglong_mid_loggedout";
        }
        adId = adUnit + "-" + ts;
        var advertisement = wattpad.utils.advertisement({
          data: {
            root: pageData
          },
          hash: {
            unit: adUnit,
            experience: "reading",
            storyGroup: pageData.group,
            refresh: true,
            parentClass: "reading-widget",
            adText: pageData.lastPage
              ? wattpad.utils.trans("Story continues below")
              : "",
            adTextClass: "panel-title hidden-md hidden-lg",
            timestamp: ts
          }
        });
        $(`div[data-page-number='${currentPage}'] .right-rail`).append(
          advertisement
        );
        self.refreshingAds.push({
          id: adId,
          hasRefreshed: false,
          timesRefreshed: 0
        });
        self.setIsRefreshing();
        noop();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
