import featuredStories from "core/templates/collection-views/featured-stories.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "FeaturedStories",
    app.views.IncrementalList.extend({
      template: featuredStories,

      filter: null,

      className: "stories",
      containerClass: ".collection",

      itemView: app.views.FeaturedStoryItem,

      initialize: function(options) {
        options = options || {};

        this.collection = new app.collections.FeaturedStories([], {
          limit: options.limit || 3
        });

        Handlebars.registerPartial(
          "core.item_views.featured_story_item",
          function() {
            return "";
          }
        );
      },

      getTemplateData: function() {
        var data = {
          hasStories: this.collection.length > 0,
          "user-device": app.get("device")
        };

        return data;
      },

      render: function() {
        var self = this;
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          app.views.IncrementalList.prototype.render.apply(self, arguments);
        });
        return this;
      }
    })
  );
})(window, _, wattpad, window.app);
