(function(window, _, wattpad, app) {
  "use strict";

  /**
   * APIv2Patch Mixin
   * Automatically will reduce the data sent to the server based on the model's v2Fields property
   * Known collision with Backbone.save() and Backbone.destroy().
   * Requires: v2Fields ( array[ string ] )
   */
  app.add(
    "ApiV2Patch",
    Monaco.Mixin.create({
      save: function(attributes, options, next) {
        options = options || {};

        // Get the Correct Subset of data
        options.data = _.pick(options.data || {}, this.v2Fields);

        // This mixin overrides the default `save` function.
        // Need to call `next` so that the ajax fetch actually occurs.
        return next(attributes, options);
      },

      destroy: function(options, next) {
        // Get the Correct Subset of data
        options.data = _.pick(options.data || {}, this.v2Fields);

        // This mixin overrides the default `destroy` function.
        // Need to call `next` so that the ajax request actually occurs.
        return next(options);
      }
    })
  );
})(window, _, wattpad, window.app);
