(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:home.index",
    app.router.filter(
      ["requireLogin", "getTranslations", "getCategories"],
      function(section) {
        var isMobile = app.get("device").is.mobile;

        var homeIndexView = new app.views.NewHome();

        app.transitionTo(homeIndexView, {
          hasHeader: true,
          hasFooter: true,
          pageTitle: wattpad.utils.trans("Home")
        });

        window.te.push("event", "app", "page", null, "view", {
          page: "home"
        });
      }
    )
  );
})(window, _, jQuery, wattpad, window.app);
