var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-right",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <div class=\"tag-module-tags\">\n            <a class=\"on-navigate\" href=\"/tags/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"><s>#</s>"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "</a>\n        </div>\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showHotListHeader : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div class='collection'>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.stories : depth0),{"name":"each","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n<div class=\"show-more center-text hidden\">\n    <button class=\"btn btn-link on-showmore\">\n        <span class='show-more-message'>\n            <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n        </span>\n        <span class='loading-message hidden'>\n            "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n            <span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n        </span>\n    </button>\n</div>\n\n<div id=\"preview-modal\"></div>\n\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div id='hot-list-header'>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.appendStoriesSuffix : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <p> "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"The Top %1$s: Best %2$s Stories","<span>1000</span>",(depth0 != null ? depth0.categoryNameForHotListHeader : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <p> "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"The Top %1$s: Best %2$s","<span>1000</span>",(depth0 != null ? depth0.categoryNameForHotListHeader : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n";
},"11":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "        <div class=\"browse-story-item "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.completed : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = container.invokePartial(partials["core.item_views.browse_story_item"],depth0,{"name":"core.item_views.browse_story_item","hash":{"showRank":(depths[1] != null ? depths[1].showHotListHeader : depths[1])},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "completed";
},"14":function(container,depth0,helpers,partials,data) {
    return "<p class=\"no-item\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"No stories available.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div id=\"category-tags\" class=\"sub-navigation\">\n  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <div class=\"scrollable\">\n    <div class=\"collection\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tags : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasStories : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true,"useDepths":true});