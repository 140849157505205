(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ActivityMessages",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.PublicMessage,

      cacheLocal: true,
      cacheExpire: 5,

      arrayKey: "messages",

      username: null,

      fields: [
        {
          messages: [
            "id",
            "body",
            "createDate",
            "from",
            "numReplies",
            "isReply",
            "latestReplies",
            "isOffensive",
            "replies"
          ]
        },
        "total",
        "nextUrl"
      ], // selector for what returns from the database

      initialize: function(models, options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error(
            "An Activity Feed collection requires a username string to be passed to the options hash on init"
          );
        }
        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );

        this.username = options.username;

        this.on("remove", function() {
          app.local._clearItem(this.resource());
        });
      },

      parse: function(response) {
        _.forOwn(response[this.arrayKey], function(message) {
          message.type = message.type || "newMessage";
        });
        return app.collections.IncrementalFetchNextUrl.prototype.parse.apply(
          this,
          arguments
        );
      },

      //Override collection prepareModel to inject username into our models
      _prepareModel: function(attrs, options) {
        if (!attrs.username) {
          attrs.username = this.username;
        }
        return app.collections.IncrementalFetch.prototype._prepareModel.apply(
          this,
          arguments
        );
      },

      defaultUrl: function() {
        return "/v4/users/" + this.username + "/messages";
      },

      // what manco uses for caching local storage
      resource: function() {
        return "user." + this.username + ".profile.messages";
      }
    })
  );
})(window, _, wattpad, window.app);
