var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.recommendedTags : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"dropdown-menu new-curation logged-in large\" aria-labelledby=\"discover-dropdown\">\n      <div class=\"col-xs-9\">\n        <ul class=\"bold\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse Stories",{"name":"trans","hash":{},"data":data}))
    + "\" id=\"main-browse-navigate\">\n          <li><a class=\"on-navigate\" href=\"/stories\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n        </ul>\n\n        <div class=\"col-xs-9 discover-categories\">\n          <ul class=\"row\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Categories",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </ul>\n        </div>\n\n        <div class=\"col-xs-3\">\n          <ul aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Trending Tags",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.recommendedTags : stack1),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          </ul>\n        </div>\n      </div>\n\n      <div class=\"col-xs-3 wattpad-picks\">\n        <ul class=\"row\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Picks",{"name":"trans","hash":{},"data":data}))
    + "\">\n          <li class=\"title col-xs-12\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Picks",{"name":"trans","hash":{},"data":data}))
    + "</li>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.newCurationLists : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "              <li class=\"col-xs-6\">\n                <a class=\"on-navigate\" href=\"/stories/"
    + alias1((helpers.slugify || (depth0 && depth0.slugify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name_english : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\">"
    + alias1(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>\n              </li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "              <li><a class=\"on-navigate\" href=\"/tags/"
    + alias1(container.lambda(depth0, depth0))
    + "\">"
    + alias1((helpers.styleHashtag || (depth0 && depth0.styleHashtag) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"styleHashtag","hash":{},"data":data}))
    + "</a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <li class=\"col-xs-12\">\n              <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.path : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\" >"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n            </li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.path || (depth0 != null ? depth0.path : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"path","hash":{},"data":data}) : helper)));
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return " /featured/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)));
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"dropdown-menu new-curation large\" aria-labelledby=\"discover-dropdown\">\n      <div class=\"col-xs-3\">\n        <ul class=\"bold\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse Stories",{"name":"trans","hash":{},"data":data}))
    + "\" id=\"main-browse-navigate\">\n          <li><a class=\"on-navigate\" href=\"/stories\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n        </ul>\n      </div>\n\n      <div class=\"col-xs-6 discover-categories\">\n        <ul class=\"row\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Categories",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n\n      <div class=\"col-xs-3 wattpad-picks\">\n        <ul class=\"row\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Picks",{"name":"trans","hash":{},"data":data}))
    + "\">\n          <li class=\"title col-xs-12\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Picks",{"name":"trans","hash":{},"data":data}))
    + "</li>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.newCurationLists : depth0),{"name":"each","hash":{},"fn":container.program(15, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>\n";
},"13":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "            <li class=\"col-xs-6\">\n              <a class=\"on-navigate\" href=\"/stories/"
    + alias1((helpers.slugify || (depth0 && depth0.slugify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name_english : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\">"
    + alias1(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>\n            </li>\n";
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <li class=\"col-xs-12\">\n              <a href=\"/featured/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n            </li>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"dropdown-menu large\" aria-labelledby=\"discover-dropdown\">\n    <div class=\"col-xs-3\">\n      <ul class=\"bold\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse Stories",{"name":"trans","hash":{},"data":data}))
    + "\" id=\"main-browse-navigate\">\n        <li><a class=\"on-navigate\" href=\"/stories\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n      </ul>\n    </div>\n\n    <div class=\"col-xs-6 discover-categories\">\n      <ul class=\"row\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Categories",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.recommendedTags : stack1),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "          <li class=\"col-xs-6\">\n            <a class=\"on-navigate\" href=\"/stories/"
    + alias1((helpers.slugify || (depth0 && depth0.slugify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name_english : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\">"
    + alias1(container.lambda((depth0 != null ? depth0.name : depth0), depth0))
    + "</a>\n          </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"col-xs-3\">\n        <ul aria-label=\""
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Trending Tags",{"name":"trans","hash":{},"data":data}))
    + "\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0["current-user"] : depth0)) != null ? stack1.recommendedTags : stack1),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var alias1=container.escapeExpression;

  return "            <li><a class=\"on-navigate\" href=\"/tags/"
    + alias1(container.lambda(depth0, depth0))
    + "\">"
    + alias1((helpers.styleHashtag || (depth0 && depth0.styleHashtag) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),depth0,{"name":"styleHashtag","hash":{},"data":data}))
    + "</a></li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"triangle\"></div>\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.newCurationLists : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});