import browseStories from "core/templates/collection-views/browse-stories.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "BrowseStories",
    app.views.IncrementalList.extend({
      template: browseStories,

      filter: null,

      className: "stories",
      containerClass: ".collection",

      itemView: app.views.BrowseStoryItem,

      events: _.extend(
        {
          "tap .on-story-preview": "onStoryPreview",
          "click .on-story-preview": "stopEvent",

          "tap .on-story-details": "sendEventTracking",
          "tap .on-start-reading": "sendEventTracking"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function(options) {
        var self = this;

        //Need to massage data to make this work...
        options = options || {};
        switch (options.filter) {
          case "hot":
            if (options.category) {
              // WEB-6732 Show a header for hot list
              this.showHotListHeader = true;
              this.category = options.category.toJSON();
              this.categoryNameForHotListHeader = this.category.name;
              // WEB-6946 Improvement to the header: nicer text for certain categories
              switch (this.category.id) {
                case 2: //Poetry
                  this.appendStoriesSuffix = false;
                  break;
                case 17: //Short Stories
                  this.categoryNameForHotListHeader = wattpad.utils.trans(
                    "Short Stories"
                  );
                  this.appendStoriesSuffix = false;
                  break;
                default:
                  this.appendStoriesSuffix = true;
              }
              this.collection = new app.collections.BrowseStories([], {
                category: options.category,
                filter: "hot",
                limit: 18
              });
            } else if (options.tag) {
              this.collection = new app.collections.BrowseTagStories([], {
                tags: options.tag,
                filter: "hot",
                limit: 18
              });
            }
            break;
          case "featured":
            if (options.category) {
              this.collection = new app.collections.BrowseStories([], {
                category: options.category,
                filter: "featured",
                limit: 18
              });
            }
            break;
          case "new":
            if (options.category) {
              this.collection = new app.collections.BrowseStories([], {
                category: options.category,
                filter: "new",
                limit: 18
              });
            } else if (options.tag) {
              this.collection = new app.collections.BrowseTagStories([], {
                tags: options.tag,
                filter: "new",
                limit: 18
              });
            }
            break;
          case "recommended":
            this.collection = new app.collections.RecommendedStories([], {
              username: wattpad.utils.currentUser().get("username")
            });
            break;
          case "featured404":
            this.collection = new app.collections.FeaturedStories([], {});
            break;
          default:
            break;
        }
        this.device = app.get("device");
        Handlebars.registerPartial(
          "core.item_views.browse_story_item",
          function() {
            return "";
          }
        );

        this.collection.once("sync", function() {
          self.render();
        });

        this.collection.fetchNextSet();
      },

      getTemplateData: function() {
        var data = {
          hasStories: this.collection.length > 0,
          showHotListHeader: this.showHotListHeader || false,
          appendStoriesSuffix: this.appendStoriesSuffix || false,
          categoryNameForHotListHeader: this.categoryNameForHotListHeader || "",
          category: this.category || "",
          "user-device": this.device,
          tags: this.collection.tags,
          isMobile: this.device.is.mobile
        };
        return data;
      },

      renderItem: function(model, $container, index) {
        app.views.IncrementalList.prototype.renderItem.apply(this, arguments);
        // If ad enbaled, render the placeholders for the ad.
        index = index || this.collection.indexOf(model);
        $container = $container || this.$el.children(".collection");
      },

      render: function() {
        this.render = function() {
          app.views.IncrementalList.prototype.render.apply(this, arguments);
          return this;
        }.bind(this);

        return this;
      },

      onStoryPreview: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        var story = this.collection.get(storyId);
        var preview = new app.views.BrowseStoryPreview({ model: story });

        this.$("#preview-modal")
          .empty()
          .append(preview.render().$el);
        preview.openModal();
      },

      sendEventTracking: function() {
        // User clicks on any part of the preview story modal
        // that navigates to either story details or the story reading page
        window.te.push("event", "app", "story", null, "click", {
          storyid: this.$("#preview-modal .browse-preview.modal").data(
            "story-id"
          ),
          page: "category"
        });
      },

      onShowMore: function(evt) {
        this.collection.limit = 20;
        app.views.IncrementalList.prototype.onShowMore.apply(this, arguments);
      }
    })
  );

  app.mixin(app.views.BrowseStories, "InfiniteScroll");
})(window, _, wattpad, window.app);
