(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Stories",
    app.collections.Base.extend({
      //=== MONACO ===//
      resource: "stories.collection"
    })
  );
})(window, _, wattpad, window.app);
