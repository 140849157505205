(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "OnboardingWriterJourney",
    app.views.DummyReactView.extend({
      component: "OnboardingWriterJourney",
      componentId: "onboarding-writer-journey",

      constructor: function(opts) {
        this.componentData = {
          locale: wattpad.utils.getCookie("locale"),
          username: opts.user ? opts.user.get("username") : ""
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
