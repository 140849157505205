var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<h2>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Recommended People",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n<div class=\"collection row\">\n</div>\n<span class=\"checklater hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please check back later for more recommendations.",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"useData":true});