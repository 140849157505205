(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Discover",
    app.collections.BaseCollection.extend({
      fields: ["listType,url,elements,subtitle,id,title"],

      url: "/v4/discover/",

      initialize: function(options) {
        options = options || {};
        this.user = options.user || wattpad.utils.currentUser();
      },

      resource: function() {
        return (
          "user." + (this.user.get("username") || "anonymous") + ".discover"
        );
      }
    })
  );
})(window, _, wattpad, window.app);
