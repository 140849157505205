var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " unread";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing;

  return "<!-- Added to Reading List -->\n<article class=\"notification list panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <a href=\"/user/"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm2 on-navigate\">\n      <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <a href=\"/list/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.id : stack1), depth0))
    + "-"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias4).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.name : stack1),{"name":"slugify","hash":{},"data":data}))
    + "\" class=\"on-notification-click content\">\n        <div class=\"heading\">\n            "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias4).call(alias1,"<strong>%s</strong> added <strong>%s</strong> to <strong>%s</strong>",(helpers.sanitize || (depth0 && depth0.sanitize) || alias4).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias4).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias4).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias4).call(alias1,"fa-story",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <time datetime=\""
    + alias3(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.calendar || (depth0 && depth0.calendar) || alias4).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n    </a>\n</article>\n";
},"useData":true});