import mediaBanner from "core/templates/story-reading/media-banner.hbs";
import bannedImageMessaging from "core/templates/banned-image-messaging.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "MediaBanner",
    Monaco.View.extend({
      events: {
        "tap .on-video": "onVideoSelected",
        "click .on-video": "stopEvent",

        "tap .on-prev-media": "onPrevMedia",
        "click .on-next-media": "stopEvent",

        "tap .on-next-media": "onNextMedia",
        "click .on-prev-media": "stopEvent",

        "tap .on-carousel-clicked": "onCarouselClicked",
        "click .on-carousel-clicked": "stopEvent",

        "tap .on-show-media-menu": "onShowMediaMenu",
        "click .on-show-media-menu": "stopEvent",

        "mouseleave .background": "onHideMediaMenu",

        "tap .on-remove-video": "onRemoveVideo",
        "click .on-remove-video": "stopEvent",

        "tap .on-remove-image": "onRemovePhoto",
        "click .on-remove-image": "stopEvent",

        "tap .on-replace-image": "onReplacePhoto",
        "click .on-replace-image": "stopEvent",

        "tap .on-replace-video": "onReplaceVideo",
        "click .on-replace-video": "stopEvent",

        "tap .on-cancel": "onCancel",
        "click .on-cancel": "stopEvent",

        "tap .on-add-image": "onAddPhoto",
        "click .on-add-image": "stopEvent",

        "tap .on-add-video": "onAddVideo",
        "click .on-add-video": "stopEvent",

        "keyup .photo-url-field": "onSubmitPhoto",
        "tap .on-submit-photo": "onSubmitPhoto",
        "click .on-submit-photo": "stopEvent",

        "keyup .video-url-field": "onSubmitVideo",
        "tap .on-submit-video": "onSubmitVideo",
        "click .on-submit-video": "stopEvent",

        "tap .media-fields-underlay": "hideMediaFields",
        "click .media-fields-underlay": "stopEvent",

        "paste .video-url-field": "onSubmitVideo",

        "click .on-okay": "stopEvent",
        "tap .on-okay": "onCloseTooltip"
      },

      template: mediaBanner,
      className: "media-container",

      bannedImageMessaging: bannedImageMessaging,

      initialize: function(options) {
        options = options || {};
        this.media = options.media;
        this.bgCover = options.bgCover;
        this.model = options.model || new Monaco.Model();
        this.isEditable = options.isEditable || false;
        this.showTips = options.showTips || false;
        this.socialLinks = options.socialLinks || false;
        this.hasBannedImage = false;
        this.isWriterPreview = options.isWriterPreview;

        this.coverTimestamp = options.coverTimestamp || "";

        this.fileUploadLimits = [
          {
            type: "image/jpeg",
            fileSize: 10000000,
            friendlyName: "JPG",
            friendlySize: "10mb"
          },
          {
            type: "image/png",
            fileSize: 10000000,
            friendlyName: "PNG",
            friendlySize: "10mb"
          },
          {
            type: "image/gif",
            fileSize: 3000000,
            friendlyName: "GIF",
            friendlySize: "3mb"
          }
        ];
      },

      render: function() {
        var data = {};
        data.media = this.media;
        data.bgCover = this.bgCover;
        data.isEditable = this.isEditable;
        data.isWriterPreview = this.isWriterPreview;
        data.showTips = this.showTips;
        data.isDesktop = app.get("device").isDesktop();

        // Hide the backbround cover if we're in edit mode and the user hasn't provided a custom cover
        data.showCover =
          data.isEditable && data.media.length === 0 ? false : true;

        data.showCarousel = data.media.length > 1;

        _.each(data.media, function(mediaItem) {
          data.hasVideo = data.hasVideo || mediaItem.video || false;
          data.hasPhoto = data.hasPhoto || mediaItem.photo || false;
        });

        if (data.media.length > 0 && this.socialLinks) {
          data.media.facebook = this.socialLinks[0].href;
          if (data.media[0].video) {
            data.media.pinterest = utils.qsReplace(
              this.socialLinks[2].href,
              "media",
              encodeURIComponent(
                "http://img.youtube.com/vi/" + data.media[0].ref + "/0.jpg"
              )
            );
          } else {
            data.media.pinterest = utils.qsReplace(
              this.socialLinks[2].href,
              "media",
              encodeURIComponent(data.media[0].ref)
            );
          }
        }
        data.testGroups = wattpad.testGroups;
        this.$el.html(this.template(data));

        if (this.media.length > 1) {
          this.showNextArrow();
        }

        var element = this.$('[data-toggle="popover"]');
        if (element.popover) {
          var popOverTemplate =
            '<div class="popover writer-tooltip" role="tooltip"><div class="arrow"></div><h3 class="popover-title"></h3>' +
            '<div class="popover-content"></div></div>';

          element.popover({ template: popOverTemplate, html: true });
        }

        if ((this.isEditable || this.isWriterPreview) && this.media.length) {
          var self = this;
          window.setTimeout(function() {
            self.setHeaderImageModerationNotice();
          }, 50);
        }

        return this;
      },

      showNextArrow: function() {
        this.$(".media > .on-next-media").removeClass("invisible");
      },

      onPrevMedia: function(evt) {
        var index = $(".media > .media-item").index(
          $(".media-item:not(.hidden)")
        );
        this.showMedia(index, index - 1);
      },

      onNextMedia: function(evt) {
        var index = $(".media > .media-item").index(
          $(".media-item:not(.hidden)")
        );
        this.showMedia(index, index + 1);
      },

      onCarouselClicked: function(evt) {
        var clicked = $("li.on-carousel-clicked").index($(evt.currentTarget)),
          current = $("li.on-carousel-clicked").index(
            $(evt.currentTarget).siblings(".active")
          );
        this.showMedia(current, clicked);
      },

      //Also updates carousel status
      showMedia: function(current, next) {
        var isFirstMedia = current - 1 === 0,
          isLastMedia = current + 1 === this.media.length - 1;

        if (isFirstMedia) {
          this.$(".media > .on-prev-media").addClass("invisible");
          this.$(".media > .on-next-media").removeClass("invisible");
        }
        if (isLastMedia) {
          this.$(".media > .on-next-media").addClass("invisible");
          this.$(".media > .on-prev-media").removeClass("invisible");
        }

        this.$(
          ".media > .media-item:nth-of-type(" + (current + 1) + ")"
        ).addClass("hidden");
        this.$(
          ".carousel-indicators > li.on-carousel-clicked:nth-of-type(" +
            (current + 2) +
            ")"
        ).removeClass("active");

        this.$(
          ".media > .media-item:nth-of-type(" + (next + 1) + ")"
        ).removeClass("hidden");
        this.$(
          ".carousel-indicators > li.on-carousel-clicked:nth-of-type(" +
            (next + 2) +
            ")"
        ).addClass("active");

        if (this.media.length > 0 && this.socialLinks) {
          this.$("#share-header-media-facebook").attr(
            "href",
            this.socialLinks[0].href
          );
          if (this.media[next].video) {
            this.$("#share-header-media-pinterest").attr(
              "href",
              utils.qsReplace(
                this.$("#share-header-media-pinterest").attr("href"),
                "media",
                encodeURIComponent(
                  "http://img.youtube.com/vi/" + this.media[next].ref + "/0.jpg"
                )
              )
            );
          } else {
            this.$("#share-header-media-pinterest").attr(
              "href",
              utils.qsReplace(
                this.$("#share-header-media-pinterest").attr("href"),
                "media",
                encodeURIComponent(this.media[next].ref)
              )
            );
          }
        }

        if (
          (this.isWriterPreview || this.isEditable) &&
          this.media.length > 1 &&
          this.hasBannedImage
        ) {
          this.updateBannedImageMessaging();
        }
      },

      updateBannedImageMessaging: function() {
        var $headerWarning = $("#header-banned-image-warning");

        if ($headerWarning.hasClass("hidden")) {
          $headerWarning.removeClass("hidden");
        } else {
          $headerWarning.addClass("hidden");
        }
      },

      onVideoSelected: function(evt) {
        var $target = $(evt.currentTarget),
          $iframe = $(
            '<iframe width="' +
              ($target.height() * 16) / 9 +
              '" height="' +
              $target.height() +
              '" src="//www.youtube-nocookie.com/embed/' +
              $target.data("youtube") +
              '?rel=0&amp;showinfo=0&amp;autoplay=1" frameborder="0" allowfullscreen></iframe>'
          );
        $target.html($iframe);
      },

      onShowMediaMenu: function(evt) {
        var $target = $(evt.currentTarget);
        $target
          .siblings(".popover")
          .popover()
          .removeClass("faded")
          .show();
      },

      onHideMediaMenu: function(evt) {
        this.$(".media-menu")
          .removeAttr("style")
          .addClass("faded");
      },

      onAddPhoto: function(e) {
        this.onFileSelect();
        // $('.media-fields-underlay').show();
        // this.$( '.media-item' ).addClass( 'hide' );
        // this.$('.media-buttons').addClass( 'hide' );
        // this.$('.photo-url').removeClass( 'hide' );
        // // Placeholder text disappears on focus on IE
        // if ( !app.get( 'device' ).is.ie ) {
        //   this.$('.photo-url-field').focus();
        // }
      },

      onAddVideo: function(e) {
        $(".media-fields-underlay").show();
        this.$(".media-item").addClass("hide");
        this.$(".media-buttons").addClass("hide");
        this.$(".video-url").removeClass("hide");
        // Placeholder text disappears on focus on IE
        if (!app.get("device").is.ie) {
          this.$(".video-url-field").focus();
        }

        this.hideBannedHeaderImageWarning();
      },

      hideBannedHeaderImageWarning: function() {
        if (this.hasBannedImage) {
          $("#header-banned-image-warning").addClass("hidden");
        }
      },

      onRemoveVideo: function(evt) {
        app.trigger("works:editor:remove-video");
      },

      onRemovePhoto: function(evt) {
        this.hideBannedHeaderImageWarning();
        if (this.hasBannedImage) {
          app.trigger("works:editor:remove-banned-indicator");
        }
        app.trigger("works:editor:remove-image");
      },

      onReplacePhoto: function(evt) {
        this.onFileSelect();
      },

      onReplaceVideo: function(evt) {
        this.onAddVideo();
        this.$(".media > .on-next-media").addClass("invisible");
        this.$(".media > .on-prev-media").addClass("invisible");
        this.$(".video-url").addClass("align");
      },
      onCancel: function(evt) {
        if (this.media.length > 1) {
          var index = $(".media > .media-item").index(
            $(".media-item:not(.hidden)")
          );
          if (index === 0) {
            this.$(".media > .on-next-media").removeClass("invisible");
          } else {
            this.$(".media > .on-prev-media").removeClass("invisible");
          }
        }
      },

      onSubmitPhoto: function(evt) {
        var imageUrl = this.$(".photo-url-field").val();

        // Handle both the Enter key for keyup, or tap which would come from tapping the 'Add' button
        if (
          (evt.type == "keyup" && evt.keyCode === 13 && imageUrl !== "") ||
          (evt.type == "tap" && imageUrl !== "")
        ) {
          app.trigger("works:editor:add-image", imageUrl);
          $(".media-fields-underlay").hide();
        }
      },

      onSubmitVideo: function(evt) {
        var videoUrl = this.$(".video-url-field").val();

        if (evt.type == "paste") {
          // IE is fun
          if (
            evt.originalEvent.clipboardData === undefined &&
            window.clipboardData
          ) {
            evt.originalEvent.clipboardData = window.clipboardData;
          }
          videoUrl = evt.originalEvent.clipboardData.getData("Text");

          if (this.isYouTubeUrl(videoUrl)) {
            app.trigger(
              "works:editor:add-video",
              this.extractYouTubeId(videoUrl)
            );
            $(".media-fields-underlay").hide();
          } else {
            $(".save-indicator")
              .text("Please enter a valid YouTube video link.")
              .addClass("warning");
          }
        }

        // Handle both the Enter key for keyup, or tap which would come from tapping the 'Add' button
        if ((evt.type == "keyup" && evt.keyCode === 13) || evt.type == "tap") {
          if (this.isYouTubeUrl(videoUrl)) {
            app.trigger(
              "works:editor:add-video",
              this.extractYouTubeId(videoUrl)
            );
            $(".media-fields-underlay").hide();
          } else {
            $(".save-indicator")
              .text("Please enter a valid YouTube video link.")
              .addClass("warning");
          }
        }
      },

      hideMediaFields: function(evt) {
        this.$(".media-item").removeClass("hide");
        this.$(".media-buttons").removeClass("hide");
        this.$(".photo-url").addClass("hide");
        this.$(".video-url").addClass("hide");
        $(".media-fields-underlay").hide();
      },

      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      },

      isYouTubeUrl: function(url) {
        return (
          url.indexOf("http://youtu.be/") !== -1 ||
          url.indexOf("https://youtu.be/") !== -1 ||
          url.indexOf("http://www.youtube.com/watch?v=") !== -1 ||
          url.indexOf("https://www.youtube.com/watch?v=") !== -1
        );
      },

      extractYouTubeId: function(url) {
        var regExp = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;
        var match = url.match(regExp);
        if (match && match[1].length == 11) {
          // YouTube video IDs are 11 characters long
          return match[1];
        } else {
          return "";
        }
      },

      onCloseTooltip: function(evt) {
        utils.stopEvent(evt);
        var target = $(evt.currentTarget);
        target
          .parent()
          .parent()
          .parent()
          .addClass("hidden");
      },

      onSocialShareSelected: function(evt) {
        window.dataLayer.push({
          event: "ga-event",
          category: "reading",
          action: $(evt.currentTarget).data("gtm-action"),
          label: this.model.get("id")
        });

        window.te.push("event", "reading", "header-media", null, "share", {
          storyid: this.model.get("group").id,
          partid: this.model.get("id"),
          channel: $(evt.currentTarget).data("share-channel")
        });
      },

      onAddImage: function(evt) {
        this.onFileSelect();
      },

      onFileSelected: function() {
        var self = this;
        self
          .getFile()
          ["catch"](function(e) {
            app.trigger("works:editor:image-upload-failed", e.message);
            // notify GA of the error, temporary to see how often this might be happening
            wattpad.utils.pushEvent({
              category: "works-writer",
              action: "image-failed-read"
            });

            // window.te.push( 'event', 'writer', 'media', 'upload', 'failed', {
            //   storyid: self.model.storyId,
            //   partid: self.model.partId,
            //   media_type: 'image',
            //   failure_type: 'browser_image_read',
            //   user_agent: wattpad.utils.getUserAgent( 100 )
            // } );
            return Promise.reject();
          })
          .then(function(imageData) {
            if (self.validateImageData(imageData)) {
              // We don't want to show the media picker anymore
              self.$(".story-media-picker").addClass("hidden");

              // Generate a psuedo-random id for referencing this image
              var newImageId = "image_" + new Date().getTime();

              var targetParagraph = self.fileUploadTarget;

              /*
              These numbers describe the exif orientation data of the image
              5 = mirrored and flipped 270 degrees
              6 = flipped 270 degrees
              7 = mirrored and flipped 90 degrees
              8 = flipped 90 degrees
              If confused, take a look at http://i.stack.imgur.com/VGsAj.gif
            */
              var rotation = [5, 6, 7, 8];

              // Creating an image element to represent the uploaded image which allows us to get its dimensions
              var newImage = $('<img id="' + newImageId + '" />').one(
                "load",
                function() {
                  var size = self.adjustSizes(targetParagraph, this);
                  var width = this.width,
                    height = this.height;

                  if (
                    imageData.orientation &&
                    rotation.indexOf(imageData.orientation) > -1
                  ) {
                    // The image has rotated orientation, so we exchange the width and height
                    width = this.height;
                    height = this.width;
                  }

                  $(this)
                    .attr("data-original-width", width)
                    .attr("data-original-height", height);

                  var imageSrc = this.src;

                  if (self.isCanvasSupported) {
                    var canvasSource = $('<img src="' + imageSrc + '">')[0];
                    var canvas = document.createElement("canvas");
                    var canvasContext = canvas.getContext("2d");
                    canvas.width = size.width / 4;
                    canvas.height = size.height / 4;
                    canvasContext.drawImage(
                      canvasSource,
                      0,
                      0,
                      canvas.width,
                      canvas.height
                    );
                    imageSrc = canvas.toDataURL();
                  }
                  $(".btn-writer-media.on-add-image")
                    .children()
                    .replaceWith(
                      utils.iconifySpin("fa-loading", 25, new utils.Device(), {
                        hash: { className: "fa-wp-base-1 spinner" }
                      })
                    );

                  // var placeholder = placeholderTemplate( {
                  //   id: newImageId,
                  //   width: size.width,
                  //   height: size.height,
                  //   origWidth: width,
                  //   origHeight: height,
                  //   src: imageSrc,
                  //   spinnerLeft: ( size.width - 50 ) / 2,
                  //   spinnerTop: ( size.height - 50 ) / 2,
                  //   device: app.get( 'device' )
                  // } );

                  // this.removeAttribute( 'src' );
                  // targetParagraph.replaceWith( placeholder );
                  $("#" + newImageId)
                    .attr("data-media-type", "image")
                    .attr("data-image-layout", "one-horizontal");

                  self.uploadImage(newImageId, imageData);
                  // self.onTextUpdated();

                  // if( self.$( 'p[data-media-type=image]' ).length === self.warnWhenNumImagesReached ) {
                  //   self.showToast( utils.trans( 'You\'re nearing your 20 images per story limit.' ) );
                  // }
                }
              )[0];
              newImage.src = imageData.data;

              // window.te.push( 'event', 'writer', 'media', null, 'add', {
              //   storyid: self.model.storyId,
              //   partid: self.model.partId,
              //   media_type: imageData.metadata.type
              // } );
            }
          });
      },

      validateImageData: function(imageData) {
        var fileLimits = _.findWhere(this.fileUploadLimits, {
          type: imageData.metadata.type
        });

        if (fileLimits) {
          if (imageData.metadata.size > fileLimits.fileSize) {
            app.trigger(
              "works:editor:image-too-large",
              utils.trans(
                "Your uploaded %s file is too large. The maximum size for %ss is %s.",
                fileLimits.friendlyName,
                fileLimits.friendlyName,
                fileLimits.friendlySize
              )
            );
            // notify GA of the error, temporary to see how often this might be happening
            wattpad.utils.pushEvent({
              category: "works-writer",
              action: "image-too-large"
            });

            // window.te.push( 'event', 'writer', 'media', 'upload', 'failed', {
            //   storyid: this.model.storyId,
            //   partid: this.model.partId,
            //   media_type: 'image',
            //   failure_type: 'too_large',
            //   user_agent: wattpad.utils.getUserAgent( 100 )
            // } );
            return false;
          }
        } else {
          var allowedTypes = _.pluck(
            this.fileUploadLimits,
            "friendlyName"
          ).join(", ");
          app.trigger(
            "works:editor:image-invalid-type",
            utils.trans(
              "Your uploaded image is not a valid image type. Allowed types are: %s.",
              allowedTypes
            )
          );
          // notify GA of the error, temporary to see how often this might be happening
          wattpad.utils.pushEvent({
            category: "works-writer",
            action: "image-invalid-type"
          });

          // window.te.push( 'event', 'writer', 'media', 'upload', 'failed', {
          //   storyid: this.model.storyId,
          //   partid: this.model.partId,
          //   media_type: 'image',
          //   failure_type: 'invalid_image',
          //   user_agent: wattpad.utils.getUserAgent( 100 )
          // } );
          return false;
        }

        return true;
      },

      adjustSizes: function(container, image) {
        var containerWidth = _.result(container, "width");
        if (containerWidth < image.width) {
          var factor = containerWidth / image.width;
          return {
            width: containerWidth,
            height: image.height * factor
          };
        } else {
          return {
            width: image.width,
            height: image.height
          };
        }
      },

      // this won't work in local environment, but will work in production
      // the issues is related how the URLs get redirected/masked in local environment.
      uploadImage: function(imageId, imageData) {
        var self = this;
        this.imageUploads = this.imageUploads || [];
        this.imageUploads.push({ key: imageId, progress: 0 });
        Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/v5/media/story_parts/" + self.model.get("id") + "/images",
            data: {
              data: imageData.parsed
            }
          })
        )
          .then(function(response) {
            _.findWhere(self.imageUploads, { key: imageId }).progress = 1;
            _.delay(
              _.bind(self.createImageElement, self),
              3000,
              imageId,
              JSON.parse(response)
            );
          })
          ["catch"](function(e) {
            var errorMessage;
            if (e) {
              errorMessage = e ? JSON.parse(e.responseText).Message : "";
            }
            app.trigger(
              "works:editor:image-upload-failed",
              // prettier-ignore
              utils.trans( "There was a problem uploading your image. %s", errorMessage ) //eslint-disable-line
            );
            // // notify GA of the error, temporary to see how often this might be happening
            // wattpad.utils.pushEvent( {
            //   category: 'works-writer',
            //   action: 'image-upload-failed'
            // } );

            // window.te.push( 'event', 'writer', 'media', 'upload', 'failed', {
            //   storyid: self.model.storyId,
            //   partid: self.model.partId,
            //   media_type: 'image',
            //   failure_type: 'invalid_data',
            //   user_agent: wattpad.utils.getUserAgent( 100 )
            // } );
          });
      },

      createImageElement: function(imageId, imageData) {
        this.model.set("photos", imageData.Urls[0]);
        this.model.set("photoUrl", imageData.Urls[0]);
        // createImageElement is handling both add image and replace image. We need to check for existing image, before adding a new image to media list
        const newPhoto = { photo: true, ref: this.model.get("photoUrl") };

        const prevPhotoIndex = _.findIndex(this.media, {
          photo: true
        });

        if (prevPhotoIndex === -1) {
          this.media.push(newPhoto);
        } else {
          this.media[prevPhotoIndex] = newPhoto;
        }
        this.render();
      },

      setHeaderImageModerationNotice: function() {
        var self = this;

        // if media array has an element, and it is of type photo, ref attribute is the url;
        _.each(this.media, function(media, index) {
          if (!media.photo) {
            return;
          }
          var headerUrl = media.ref;
          if (self.model.get("hasBannedHeader") !== true) {
            return;
          }
          if (!self.hasBannedImage) {
            self.hasBannedImage = true;
          }

          var $mediaItem = self.$(
            ".media > .image.media-item:nth-of-type(" + (index + 1) + ")"
          );
          $mediaItem.find("img").addClass("banned");
          $mediaItem.find(".banned-overlay").removeClass("hidden");

          // Display message box when banned image indicator is present
          $("#header-banned-image-warning")
            .find(".placeholder")
            .replaceWith(
              self.bannedImageMessaging({
                id: "header-writer",
                src: headerUrl,
                groupId: self.model.get("groupid"),
                partId: self.model.get("id")
              })
            );

          if (!$mediaItem.hasClass("hidden")) {
            $("#header-banned-image-warning").removeClass("hidden");
          }
        });
      }
    })
  );

  app.mixin(app.views.MediaBanner, "SocialSharing", "FileUpload");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
