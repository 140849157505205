var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"default\">\n    <div class=\"message col-xs-12 col-md-8\"> "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Hi %s, please check your email <b>(%s)</b> to complete the sign-up process.",(depth0 != null ? depth0.username : depth0),(depth0 != null ? depth0.email : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + " <a class=\"on-navigate\"><span class=\"learn-more\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn more",{"name":"trans","hash":{},"data":data}))
    + "</span></a>\n    </div>\n\n    <div class=\"controls col-xs-12 col-md-4\">\n      <div class=\"alignment\">\n        <a href=\"#\" class=\"btn btn-grey on-changeemail\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Change email",{"name":"trans","hash":{},"data":data}))
    + "</a>\n        <a href=\"#\" class=\"btn btn-orange on-verify\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Resend email",{"name":"trans","hash":{},"data":data}))
    + " </a>\n      </div>\n    </div>\n  </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"success\">\n    <div class=\"message success\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"We've just sent an email to you. Please click on the link provided to confirm your email address with us.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n      <div class=\"controls\">\n        <a href=\"#\" class=\"btn btn-orange on-dismiss\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss",{"name":"trans","hash":{},"data":data}))
    + " </a>\n      </div>\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"fail\">\n    <div class=\"message error\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Oops, looks like we can't reach that email address.",{"name":"trans","hash":{},"data":data}))
    + " </div>\n      <div class=\"controls\">\n        <a href=\"/settings\" class=\"btn btn-grey\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Change email",{"name":"trans","hash":{},"data":data}))
    + " </a>\n      </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["default"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.success : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fail : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});