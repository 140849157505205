(function(window, _, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User Profile Sub View - Activity Feed
   * @Class ActivityFeed
   * @constructor
   */

  app.add(
    "CoreProfileActivity",
    app.views.CoreFeed.extend({
      // Properties
      // ----------
      className: "activity-feed",

      events: _.extend(
        {
          "tap .on-update-feed": "onUpdateFeed",
          "click .on-update-feed": "onUpdateFeed"
        },
        app.views.CoreFeed.prototype.events
      ),

      initialize: function(options) {
        options = options || {};
        //the collection passed in will be empty
        this.setCollectionfilter(app.models.PublicMessage.prototype.isValid);

        if (
          wattpad.utils.currentUser().authenticated() &&
          this.model.get("username").toLowerCase() !== "wattpad"
        ) {
          this.newMessageView = new app.views.NewMessageNew({
            to: this.model.get("username"),
            canBroadcast:
              wattpad.utils.currentUser().get("username") ===
              this.model.get("username"),
            placeholder: options.placeholder
          });
          this.listenTo(this.newMessageView, "message:new", this.addNewMessage);
        }
      },

      render: function() {
        //The +1 for newestId is important
        //The API is not inclusive, so we have to go one up to make sure that the item we want is actually included
        var fetchOptions = { data: { newest_id: null } },
          self = this;

        Promise.all([
          Promise.resolve(this.collection.fetchNextSet(fetchOptions)),
          Promise.resolve(
            utils
              .currentUser()
              .library()
              .loaded()
          ),
          Promise.resolve(this.pinnedItem ? this.pinnedItem.fetch() : true)
        ]).then(function() {
          if (
            self.pinnedItem &&
            self.pinnedItem.get("id") &&
            self.collection instanceof app.collections.ActivityMessages
          ) {
            if (
              self.collection.length >= 1 &&
              self.collection.first().get("id") === self.pinnedItem.get("id")
            ) {
              self.collection.remove(self.pinnedItem.id);
            }
            self.pinnedItemView = new app.views.PinnedItemMessage({
              model: self.pinnedItem
            });
            self.listenTo(
              self.pinnedItemView,
              "pinnedItem:remove",
              self.removePinnedItem
            );
          }

          // add both views to the container
          app.views.IncrementalList.prototype.render.apply(self, arguments);

          if (self.newMessageView) {
            self.$el
              .children(".collection")
              .before(self.newMessageView.render().$el);
          }

          if (self.pinnedItem && self.pinnedItemView) {
            self.$el
              .children(".collection")
              .before(self.pinnedItemView.render().$el);
          }

          if (
            self.collection.length > 0 &&
            self.collection.at(0).get("type") === "message"
          ) {
            _.defer(function() {
              self.viewPointers[self.collection.at(0).cid].expand();
            });
          }

          self.postRender();
        });
        return this;
      },

      postRender: function() {
        //TODO: Implement me in children
      },

      onUpdateFeed: function(event) {
        //Blow away the cache since we are updating to the newest possible
        app.local.clear(this.collection.resource());

        app.router.navigate("/user/" + this.model.get("username"), {
          trigger: false
        });
        this.collection.reset();
        this.render();
      },

      addNewMessage: function(message) {
        var self = this;
        this.setDirection("up");
        if (this.collection instanceof app.collections.ActivityMessages) {
          message.set("type", message.type || "newMessage");
          message.set("username", this.model.get("username"));
          if (
            message.get("ownername") ===
            wattpad.utils.currentUser().get("username")
          ) {
            if (self.pinnedItemView && self.pinnedItemView.model) {
              this.collection.add(self.pinnedItemView.model.toJSON(), {
                at: 0
              });
              /* set pinnedItem with the new message, and instantiate a new pinnedItemView with the model*/
              this.pinnedItem = message;
              this.stopListening(
                this.pinnedItemView,
                "pinnedItem:remove",
                this.removePinnedItem
              );
              this.pinnedItemView.undelegateEvents();

              this.pinnedItemView = new app.views.PinnedItemMessage({
                model: this.pinnedItem
              });
              this.$(".pinned-item").html(this.pinnedItemView.render().$el);

              this.listenTo(
                this.pinnedItemView,
                "pinnedItem:remove",
                this.removePinnedItem
              );
            } else {
              /* If this is the first message on the profile, create a new pinnedItemView and instantiate it
               with the new pinnedItem model */
              message.set("username", this.model.get("username"));
              if (
                message.get("ownername") ===
                wattpad.utils.currentUser().get("username")
              ) {
                this.pinnedItem = message;
                this.pinnedItemView = new app.views.PinnedItemMessage({
                  model: this.pinnedItem
                });
                this.$(".collection").before(this.pinnedItemView.render().$el);
              } else {
                this.collection.fetchNextSet();
              }
            }
          } else {
            this.collection.add(message.toJSON());
          }
        } else {
          this.collection.add(
            new app.models.PublicMessage({
              id: message.get("eventId"),
              type: "message",
              createDate: message.get("createDate"),
              data: {
                user: {
                  name: message.get("from").name,
                  avatar: message.get("from").avatar
                },
                message: {
                  id: message.get("id"),
                  createDate: message.get("createDate"),
                  body: message.get("body"),
                  to: {
                    name: this.model.get("username"),
                    avatar: this.model.get("avatar")
                  },
                  broadcast: message.get("isBroadcast"),
                  isOffensive: message.get("isOffensive")
                },
                highlight_color: "#00B2B2"
              },
              username: this.model.get("username")
            })
          );
        }
        this.setDirection("down");
        app.local.clear(this.collection.resource());
      },

      removePinnedItem: function(message) {
        /* replace the deleted pinnedItem with the new message by fetching the new pinnedItem from the api,
         applying the appropriate logic, and instanting a new pinnedItemView */
        var self = this;

        this.collection.remove(message);
        this.pinnedItem = new app.models.PinnedMessage({
          username: this.model.get("username"),
          type: "newMessage"
        });

        Promise.resolve(this.pinnedItem.fetch({ fresh: true })).then(
          function() {
            if (self.pinnedItem.id) {
              if (
                self.collection.first().get("id") ===
                self.pinnedItem.toJSON().id
              ) {
                self.collection.remove(self.pinnedItem);
              }
              self.pinnedItemView.undelegateEvents();
              self.pinnedItemView = new app.views.PinnedItemMessage({
                model: self.pinnedItem
              });
              self
                .$(".pinned-item")
                .replaceWith(self.pinnedItemView.render().$el);
              self.listenTo(
                self.pinnedItemView,
                "pinnedItem:remove",
                self.removePinnedItem
              );
            } else {
              self.$(".pinned-item").empty();
            }
          }
        );
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app, window.Monaco);
