var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"default\">\n  <div class=\"message\">\n    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Give feedback about the Wattpad experience and be the first to get new features. Join our beta program.",{"name":"trans","hash":{},"data":data}))
    + "\n    <a class=\"learn-more\" href=\"//support.wattpad.com/hc/articles/201708720-How-do-I-Join-the-Beta-Testing-for-Wattpad-\">\n      "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn More",{"name":"trans","hash":{},"data":data}))
    + "\n    </a>\n  </div>\n  <div class=\"controls\">\n    <a href=\"#\" class=\"btn btn-grey on-joinbeta\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Join Beta Program",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    <a href=\"#\" class=\"btn btn-grey on-dismiss\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss",{"name":"trans","hash":{},"data":data}))
    + " </a>\n  </div>\n</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <div class=\"success\">\n    <div class=\"message success\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Welcome. You are now a member of Wattpad's web beta program.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n    <div class=\"controls\">\n      <a href=\"//support.wattpad.com/hc/articles/201708720-How-do-I-Join-the-Beta-Testing-for-Wattpad-\">\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"For more information",{"name":"trans","hash":{},"data":data}))
    + "\n      </a>\n      <a href=\"#\" class=\"btn btn-grey on-dismiss\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss",{"name":"trans","hash":{},"data":data}))
    + " </a>\n  </div>\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"fail\">\n    <div class=\"message error\"> "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Sorry, we were unable to add you to the beta program.",{"name":"trans","hash":{},"data":data}))
    + " </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["default"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.success : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.fail : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});