var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"addToLibraryModal modal fade\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <header class=\"modal-header\">\n        <h1 class=\"modal-title\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h1>\n        <h2>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add to Reading List",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n        <div class=\"close\" data-dismiss=\"modal\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",17,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n      </header>\n\n      <div class=\"modal-body\">\n        <ul id=\"add-to-list\" class=\"lists-menu list-unstyled\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></ul>\n        <ul class=\"lists-menu list-unstyled\">\n          <li><a class=\"create-list-button\" href data-target=\".addToLibraryListNameModal[data-story-id="
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "]\" data-toggle=\"modal\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",14,"wp-base-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            <span class=\"reading-list-name\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New Reading List",{"name":"trans","hash":{},"data":data}))
    + "</span>\n          </a></li>\n        </ul>\n      </div>\n\n    </div>\n  </div>\n</div>\n<div data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"addToLibraryListNameModal modal fade\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <header class=\"modal-header\">\n        <h1 class=\"modal-title\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New Reading List",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n      </header>\n\n      <div class=\"modal-body\">\n        <div class=\"inputs\">\n          <div class=\"error-feedback\">\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",16,"wp-errorbackground",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Please name your reading list",{"name":"trans","hash":{},"data":data}))
    + "\n          </div>\n          <input type=\"text\" class=\"form-control list-name\" placeholder=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Enter Reading List name",{"name":"trans","hash":{},"data":data}))
    + "\">\n        </div>\n      </div>\n      <div class=\"buttons clearfix\">\n        <button type=\"button\" class=\"btn btn-md btn-grey\" data-dismiss=\"modal\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n        <button type=\"button\" class=\"on-create-list btn btn-md btn-orange\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-dismiss=\"modal\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Create",{"name":"trans","hash":{},"data":data}))
    + "</button>\n      </div>\n\n    </div>\n  </div>\n</div>\n";
},"useData":true});