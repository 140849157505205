(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "User",
    app.models.Base.extend({
      defaults: {
        username: null,
        avatar: null
      },

      fields: [
        "username",
        "name",
        "description",
        "avatar",
        "backgroundUrl",
        "createDate",
        "location",
        "following",
        "followingRequest",
        "numFollowing",
        "follower",
        "followerRequest",
        "numFollowers",
        "numLists",
        "numStoriesPublished",
        "votesReceived",
        "facebook",
        "twitter",
        "website",
        "smashwords",
        "highlight_colour",
        "html_enabled",
        "verified",
        "ambassador",
        "wattpad_squad",
        "is_staff",
        "isPrivate",
        "isMuted",
        "externalId",
        "notes",
        { safety: ["isMuted", "isBlocked"] }
      ],

      //=== METHODS ===//
      authenticate: function(username, password) {
        return $.ajax({
          url: "/api/v3/sessions",
          type: "POST",
          data: {
            username: username,
            password: password
          }
        });
      },

      authenticated: function() {
        return this.get("username") ? true : false;
      },

      getMaxNumParts: function() {
        return this.get("max_parts");
      },

      _resolveDepenencyProperty: function(master, id) {
        this.set(this.idAttribute, master.get(id).name);
      },

      isStaff: function() {
        return this.get("is_staff");
      },

      //=== ENTITIES ===//
      _followers: null,

      followers: function(id) {
        if (!this._followers) {
          this._followers = new app.collections.Followers();
        }

        return id ? this._followers.get(id) : this._followers;
      },

      _following: null,

      following: function(id) {
        if (!this._following) {
          this._following = new app.collections.Following(null, {
            user: this
          });
        }

        return id ? this._following.get(id) : this._following;
      },

      _library: null,

      library: function(id) {
        if (!this._library) {
          this._library = new app.collections.Library(null, {
            user: this
          });
        }

        return id ? this._library.get(id) : this._library;
      },

      _works: null,

      works: function(id) {
        if (!this._works) {
          this._works = new app.collections.Works(null, {
            user: this
          });
        }

        return id ? this._works.get(id) : this._works;
      },

      //=== MONACO ===//
      urlRoot: "/api/v3/users/",

      resource: function() {
        return "user." + this.get("username");
      },
      idAttribute: "username",

      determineFollowStatus: function() {
        var self = this;

        return Promise.resolve(
          $.get("/api/v3/users/" + this.get("username"), {
            fields: "following"
          })
        ).then(function(is) {
          self.set("following", is.following);
        });
      },

      // actions by the CURRENT USER to the MODEL USER
      acceptFollowRequest: function() {
        var currentUser = wattpad.utils.currentUser(),
          self = this;

        return Promise.resolve(
          $.ajax({
            url:
              "/api/v3/users/" +
              currentUser.get("username") +
              "/followers/" +
              this.get("username"),
            type: "POST"
          })
        ).then(function(data) {
          currentUser._clearFollowerCache();
          self._clearFollowingCache();
          return data;
        });
      },

      ignoreFollowRequest: function() {
        var currentUser = wattpad.utils.currentUser(),
          self = this;

        return Promise.resolve(
          $.ajax({
            url:
              "/api/v3/users/" +
              currentUser.get("username") +
              "/followers/" +
              this.get("username") +
              "/requests",
            type: "PUT"
          })
        ).then(function(data) {
          currentUser._clearFollowerCache();
          self._clearFollowingCache();
          return data;
        });
      },

      deleteFollower: function() {
        var currentUser = wattpad.utils.currentUser(),
          self = this;

        return Promise.resolve(
          $.ajax({
            url:
              "/api/v3/users/" +
              currentUser.get("username") +
              "/followers/" +
              this.get("username"),
            type: "DELETE"
          })
        ).then(function(data) {
          currentUser._clearFollowerCache();
          self._clearFollowingCache();
          return data;
        });
      },

      acceptLatestTermsOfService: function() {
        var currentUser = wattpad.utils.currentUser(),
          self = this;

        // indicate that the user has seen this modal, so that in the case
        // that the following call fails, they aren't immediately shown the
        // modal again on the next transition
        self.set("has_seen_latest_tos_notice", true);

        return Promise.resolve(
          $.ajax({
            url: "/api/v3/users/" + currentUser.get("username"),
            method: "PUT",
            data: {
              has_accepted_latest_tos: true
            }
          })
        )
          .then(function(response) {
            self.set(
              "has_accepted_latest_tos",
              response.has_accepted_latest_tos
            );
            return response.has_accepted_latest_tos;
          })
          .catch(function(err) {
            console.error(err);
            return false;
          });
      },

      _clearFollowerCache: function() {
        var userName = this.get("username");
        app.local.clear("user." + userName);
        app.local.clear("user." + userName + ".follow-requests");
        app.local.clear("user." + userName + ".profile.followers");
      },

      _clearFollowingCache: function() {
        var userName = this.get("username");
        app.local.clear("user." + userName);
        app.local.clear("user." + userName + ".following");
        app.local.clear("user." + userName + ".profile.following");
      },

      _clearStoriesCache: function() {
        var userName = this.get("username");
        app.local.clear("user." + userName);
        app.local.clear("user." + userName + ".library");
        app.local.clear("user." + userName + ".profile.works.all");
      }
    })
  );
})(window, _, wattpad, window.app);
