var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<button type=\"button\" class=\"btn btn-link pull-right close\" data-dismiss=\"modal\">\n    <span aria-hidden=\"true\" class=\"fa fa-remove\"></span><span class=\"sr-only\">Close</span>\n</button>\n<div id=\"reading-list-create\">\n    <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Create a Reading List",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n    <h5>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Give your Reading List a name. It helps to be specific.",{"name":"trans","hash":{},"data":data}))
    + "</h5>\n\n    <div class=\"form-group has-feedback\" data-attr=\"name\">\n        <label class=\"sr-only\" for=\"reading-list-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reading List Name",{"name":"trans","hash":{},"data":data}))
    + " </label>\n        <input id=\"reading-list-name\" class=\"form-control\" type=\"text\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"e.g. Best Fantasy books",{"name":"trans","hash":{},"data":data}))
    + "\">\n      "
    + ((stack1 = ((helper = (helper = helpers.validationElements || (depth0 != null ? depth0.validationElements : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"validationElements","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n    </div>\n    <div class=\"clearfix\">\n        <button class=\"btn btn-orange pull-right on-createlist\" data-dismiss=\"modal\">Create List</button>\n        <button class=\"btn pull-right\" data-dismiss=\"modal\">Close</button>\n    </div>\n</div>\n";
},"useData":true});