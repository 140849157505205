(function(window, _, wattpad, app, utils) {
  "use strict";

  // Story data and corresponding Youtube video for wattpad.com/presents
  app.add(
    "StoryVideoModel",
    app.models.BaseModel.extend({
      defaults: {
        cover: "",
        title: ""
      },

      fields: ["id", "title", "url", "cover", { user: ["name"] }, "categories"],

      cacheLocal: true,

      urlRoot: "/api/v3/stories/",

      resource: function() {
        return "wattpad.presents." + parseInt(this.get("id"), 10) + ".metadata";
      },

      fetch: function(options) {
        var baseModelFetch = app.models.BaseModel.prototype.fetch.call(
          this,
          options
        );

        return Promise.resolve(baseModelFetch).then(function(result) {
          return result;
        });

        return app.models.BaseModel.prototype.fetch.call(this, options);
      },

      parse: function(data) {
        if (data.categories && data.categories.length > 1) {
          data.categories = [data.categories[0] || data.categories[1]];

          //We lazy load categories, we have to ensure that we don't crash here
          if (app.get("categories")) {
            var category = app.get("categories").get(data.categories[0]);
            if (category) {
              var categoryObject = category.toJSON();
              data.category = categoryObject.name;
            }
          }
        }

        return data;
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
