var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " in-list";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "  <div class=\"status\">\n    <span class=\"check "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.inList : depth0),{"name":"unless","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "fa-stack fa-lg in-list\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty","auto","wp-base-2 fa-stack-2x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check","auto","wp-base-2 fa-stack-1x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </span>\n    <span class=\"hidden spinner\">\n      "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",18,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"color":"wp-base-2"},"data":data})) != null ? stack1 : "")
    + "\n    </span>\n  </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "hidden ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<li><a class=\"on-modify-list"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.inList : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-list-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),(depth0 != null ? depth0.auto : depth0),"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  <span class=\"reading-list-name\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.hideStatus : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</a></li>\n";
},"useData":true});