(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "Authforgot",
    Monaco.Model.extend({
      defaults: {
        email: null
      },

      validationRules: {
        email: [
          { func: "isRequired", msg: utils.trans("This field is required.") }
        ]
      }
    })
  );

  app.mixin(app.models.Authforgot, "ValidationModel", "GetAppValRules");
})(window, _, wattpad, wattpad.utils, window.app);
