import recommendedFollow from "core/templates/collection-views/recommended-follow.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RecommendedFollow",
    app.views.CollectionView.extend({
      className: "recommended-users-view",

      containerClass: ".collection",

      itemView: app.views.RecommendedFollowItem,

      template: recommendedFollow,

      itemCount: 3,

      initialize: function(options) {
        var self = this;

        options = options || {};

        if (typeof options.username === "undefined") {
          throw new Error(
            "RecommendedFollow: options.username is missing (required)."
          );
        }
        this.cards = !!options.cards;
        this.itemCount = options.itemCount || 3;
        this.collection = new app.collections.RecommendedUsers([], {
          username: options.username,
          limit: options.limit || 10
        });

        this.isInRange = this.isInRangeFactory();
        this.setCollectionfilter(this.isInRange);

        var resolvedPromise = Promise.resolve();

        this.listenTo(this.collection, "update", function() {
          if (self.collection.length < self.itemCount + 2) {
            resolvedPromise = Promise.resolve(self.collection.fetchNextSet());
          }

          resolvedPromise.then(function() {
            self.renderExtraItem();
          });
        });
      },

      render: function() {
        var self = this;
        this.$el.html(this.template({}));

        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          self.bindEvents();
          self.renderCollection({
            cards: self.cards,
            className: app.views.RecommendedFollowItem.prototype.getClassName(
              self.cards
            )
          });
        });
        return this;
      },

      renderCollection: function() {
        if (this.collection.models.length > 0) {
          app.views.CollectionView.prototype.renderCollection.apply(
            this,
            arguments
          );
        } else {
          this.$("span").removeClass("hidden");
        }
      },

      isInRangeFactory: function() {
        var self = this;
        return function() {
          var modelIndex = this.collection.models.indexOf(this);
          return -1 < modelIndex && modelIndex < self.itemCount;
        };
      },

      renderExtraItem: function() {
        var container = this.getContainer();
        var model = this.collection.models[2]
            ? this.collection.models[2]
            : null,
          data = {
            model: model,
            cvIndex: 3,
            cards: this.cards,
            className: this.itemView.prototype.getClassName(!!this.cards)
          };

        if (!model) {
          return;
        }

        this.viewPointers[model.cid] = new this.itemView(data);
        this.viewPointers[model.cid].render();
        container[this.direction](this.viewPointers[model.cid].$el);
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
