(function(window, _, wattpad, utils, app) {
  "use strict";

  /*
  * @mixin Highlighter
  */

  app.add(
    "Highlighter",
    Monaco.Mixin.create({
      events: {
        "mouseup .highlighter": "onShowMenu"
      },

      selection: {},

      onShowMenu: function(evt, next) {
        var self = this;

        _.delay(function() {
          var selection = window.getSelection();

          if (selection.toString().length > 0) {
            self.selection = selection;
            self.renderTooltip(next);
          } else if (self.tooltip !== undefined) {
            self.tooltip.remove();
            delete self.tooltip;
          }
        }, 200);

        next(evt);
      },

      renderTooltip: function(next) {
        var range, rect, mid, top, container, quote;
        range = this.selection.getRangeAt(0);
        rect = range.getBoundingClientRect();
        quote = range.toString();

        // Don't show the tooltip if the selection has more than 500 chars
        if (quote.length <= 500) {
          var storyId = this.model.get("group").id;
          var storyUrl = this.model.get("url");

          this.tooltip = new app.views.HighlightTooltip({
            quoteString: quote,
            storyId: storyId,
            url: storyUrl
          });

          this.tooltip.render();

          container = $(".part-content")[0].getBoundingClientRect();
          top = rect.top - container.top - 57;
          mid = (rect.left + rect.right) / 2 - (container.left + 46);

          $(".highlight-tooltip").css({
            top: top,
            left: mid,
            opacity: 1
          });
        }

        next();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
