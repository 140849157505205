(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ProfileFollowing",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.User,

      cacheExpire: 1,
      cacheLocal: true,

      arrayKey: "users",

      username: null,

      fields: [
        {
          users: [
            "username",
            "name",
            "avatar",
            "following",
            "location",
            "description",
            "backgroundUrl",
            "highlight_colour",
            "numStoriesPublished",
            "numLists",
            "numFollowers",
            "numFollowing",
            "votesReceived"
          ]
        },
        "total",
        "nextUrl"
      ],

      defaultUrl: function() {
        return "/api/v3/users/" + this.username + "/following";
      },

      resource: function() {
        return "user." + this.username + ".profile.following";
      },

      initialize: function(collection, options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error(
            "A ProfileFollowing collection requires a username string to be passed to the options hash on init"
          );
        }
        this.username = options.username;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      }
    })
  );
})(window, _, wattpad, window.app);
