(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:image_appeal",
    app.router.filter(["getTranslations", "requireLogin"], function(query) {
      var imageUrl = query.imageUrl || "";

      var validateImageUrl = wattpad.utils.validateImageURLs(imageUrl);

      if (validateImageUrl === false) {
        return app.router.navigate("/inbox/wattpad");
      }

      var imageAppealFlow = new app.views.ImageAppealFlow({
        imageUrl: imageUrl ? imageUrl.trim() : ""
      });

      app.transitionTo(imageAppealFlow, {
        hasHeader: true,
        hasFooter: true,
        pageTitle: wattpad.utils.trans("Appeal form")
      });
    })
  );
})(window, wattpad, window.app);
