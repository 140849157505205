var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"survey-banner\" data-survey-cookie=\""
    + alias4(((helper = (helper = helpers.cookie_id || (depth0 != null ? depth0.cookie_id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"cookie_id","hash":{},"data":data}) : helper)))
    + "\" data-target=\""
    + alias4(((helper = (helper = helpers.url || (depth0 != null ? depth0.url : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"url","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"message\">\n      "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Do you want to help us improve our website? Take part in a study and tell us what you think.",{"name":"trans","hash":{},"data":data}))
    + "\n    </div>\n	<div class=\"controls\">\n		<a href=\"#\" class=\"btn btn-default on-click\"> "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Click Here",{"name":"trans","hash":{},"data":data}))
    + "</a>\n		<a href=\"#\" class=\"btn btn-default on-dismiss\"> "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss",{"name":"trans","hash":{},"data":data}))
    + " </a>\n	</div>\n</div>\n";
},"useData":true});