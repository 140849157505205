import {
  DEFAULT_API_RESPONSE,
  formatPaidContentMetadata
} from "../../../helpers/format-paid-content-metadata";

(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "PaidMetadataModel",
    app.models.BaseModel.extend({
      fetch: function(options = {}) {
        const storyId = this.attributes.storyId;
        const parts = this.attributes.parts;
        const commaSeparatedParts = _.pluck(parts, "id").join(",");

        return Promise.resolve(
          $.ajax({
            type: "GET",
            url: `/v5/story/${storyId}/paid-content/metadata`,
            data: { parts: commaSeparatedParts }
          })
        )
          .then(metadata => {
            return formatPaidContentMetadata(
              parts,
              metadata,
              window?.wattpad?.utils?.trans
            );
          })
          .catch(() => DEFAULT_API_RESPONSE);
      }
    })
  );
})(window, _, wattpad, window.app);
