import surveyAnnouncement from "platform/components/templates/announcements/survey-announcement.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "Survey",
    app.views.Announcement.extend({
      template: surveyAnnouncement,

      state: "default",

      className: app.views.Announcement.prototype.className + " survey",

      events: _.extend(
        {
          "tap    .on-click": "onClick",
          "click  .on-click": "stopEvent"
        },
        app.views.Announcement.prototype.events
      ),

      onClick: function(evt) {
        var survey = this.$("div.survey-banner");

        wattpad.utils.stopEvent(evt);

        window.document.cookie =
          survey.data("survey-cookie") +
          "=1;" +
          "path=/;" +
          "domain=" +
          window.location.hostname.replace("www", "");

        this.remove();

        window.open(survey.data("target"), "_blank");
      },

      onDismiss: function(evt) {
        var survey = this.$("div.survey-banner");

        wattpad.utils.stopEvent(evt);

        window.document.cookie =
          survey.data("survey-cookie") +
          "=1;" +
          "path=/;" +
          "domain=" +
          window.location.hostname.replace("www", "");

        this.remove();
      }
    })
  );
})(window, _, wattpad, window.app);
