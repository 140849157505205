(function(window, app) {
  "use strict";

  //Story Reading
  app.router.add({
    ":partid(-:slug)(/page/:pagenum)(/comment/:commentid)(/replies/:replyid)": [
      "storyReading",
      {
        regexp: {
          partid: /[0-9]+/,
          slug: /[^\/\?#]*/,
          pagenum: /[0-9]+/,
          commentid: /[0-9a-f_]+/i,
          replyid: /[0-9a-f_]+/i
        }
      }
    ]
  });
})(window, window.app);
