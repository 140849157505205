var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, buffer = 
  "        <li class=\"";
  stack1 = ((helper = (helper = helpers.active || (depth0 != null ? depth0.active : depth0)) != null ? helper : alias2),(options={"name":"active","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.active) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n            <a class=\"on-navigate"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBonusPart : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isScheduledPart : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBlocked : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{"locals":depths[1]},"data":data}))
    + "\">\n              <div class=\"part-title\">\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBonusPart : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n                "
    + alias3(container.lambda((depth0 != null ? depth0.title : depth0), depth0))
    + "\n\n                <div class=\"part-meta\">\n                  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deleted : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.draft : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </div>\n              </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBlocked : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </a>\n        </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "active";
},"4":function(container,depth0,helpers,partials,data) {
    return " is-bonus-part";
},"6":function(container,depth0,helpers,partials,data) {
    return " is-scheduled";
},"8":function(container,depth0,helpers,partials,data) {
    return " blocked";
},"10":function(container,depth0,helpers,partials,data) {
    return "<div class=\"bonus-type\">"
    + container.escapeExpression(container.lambda((depth0 != null ? depth0.bonusTypeName : depth0), depth0))
    + "</div>";
},"12":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted",{"name":"trans","hash":{},"data":data}));
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isScheduledPart : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "");
},"15":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Scheduled",{"name":"trans","hash":{},"data":data}))
    + " - <span class=\"scheduled-date\">"
    + alias3(((helper = (helper = helpers.formattedScheduledPublishDatetime || (depth0 != null ? depth0.formattedScheduledPublishDatetime : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"formattedScheduledPublishDatetime","hash":{},"data":data}) : helper)))
    + "</span></span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    return "                      "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Draft",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isBonusPart : depth0),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-lock",16,"wp-neutral-2","pull-right",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-lock",16,"ds-base-2-60","pull-right",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"dropdown-menu pull-left\">\n    <div class=\"toc-header text-center\">\n        <h6><a class=\"on-navigate\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.title : stack1), depth0))
    + "</a></h6>\n        <small>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Table of contents",{"name":"trans","hash":{},"data":data}))
    + "</small>\n    </div>\n\n    <ul class=\"table-of-contents\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.parts : stack1),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n</div>\n";
},"useData":true,"useDepths":true});