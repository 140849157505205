(function(window, _, wattpad, app) {
  "use strict";

  //ONLY USED FOR POST AND DELETE atm
  app.add(
    "Message",
    app.models.BaseModel.extend({
      defaults: {
        id: null,
        createDate: null,
        from: null,
        isReply: false,
        parentId: null,
        body: null,
        broadcast: false
      },

      cacheLocal: false,

      initialize: function(options) {
        options = options || {};
        //message board owner
        if (
          typeof options.ownername !== "string" ||
          options.ownername.trim() === ""
        ) {
          throw new Error("Message: ownername cannot be null");
        }
        app.models.BaseModel.prototype.initialize.apply(this, arguments);
      },

      url: function() {
        return "/v4/users/" + this.get("ownername") + "/messages";
      },

      sync: function(method, model, options) {
        options = options || {};
        if (method === "delete") {
          options.url = this.url() + "/" + model.get("id");
        }
        return app.models.BaseModel.prototype.sync.apply(this, arguments);
      }
    })
  );

  app.mixin(app.models.Message, "BeautifyURL");
})(window, _, wattpad, window.app);
