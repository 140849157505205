(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ReadingLists",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.ReadingList,

      arrayKey: "lists",

      fields: [
        {
          lists: [
            "id",
            "name",
            { user: ["name"] },
            "numStories",
            "cover",
            "sample_covers",
            "tags",
            "featured",
            "isPaywalled",
            "paidModel"
          ]
        },
        "total",
        "nextUrl"
      ],

      initialize: function(models, options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error(
            "A ReadingLists collection requires a username string to be passed to the options hash on init"
          );
        }

        this.username = options.username;
        if (options.getStories) {
          this.fields[0].lists.push({
            stories: ["id"]
          });
          this.getStories = true;
        }

        this.nonEmpty = !!options.nonEmpty;

        this.on(
          "sync",
          function(eventName) {
            app.local.clear(this.resource());
          },
          this
        );

        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      defaultUrl: function() {
        return (
          "/api/v3/users/" +
          this.username +
          "/lists" +
          (this.nonEmpty ? "?non_empty=1" : "")
        );
      },

      resource: function() {
        return (
          "user." +
          this.username +
          ".profile.reading-lists" +
          (this.getStories ? ".withStories" : "") +
          (this.nonEmpty ? ".non-empty" : "")
        );
      }
    })
  );
})(window, _, wattpad, window.app);
