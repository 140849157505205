(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:library-current-reads",
    app.router.filter(["getTranslations", "getCategories"], function() {
      var view = new app.views.LibraryLanding({ section: "current-reads" });

      var options = {
        hasHeader: true,
        hasFooter: true,
        pageTitle: wattpad.utils.trans("Library - Current Reads")
      };

      if (app.get("device").is.android) {
        options.footerOptions = {
          appUrl: wattpad.utils.getAppLink("library")
        };
      }

      app.transitionTo(view, options);

      if (typeof __atha !== "undefined") {
        var id = __atha.sendPageView("library_current_reads");
      }

      window.te.push("event", "app", "page", null, "view", {
        page: "library"
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
