var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isPublish : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"works-item-toc\">\n      <h4 class=\"vcenter hidden\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Table of Contents",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n      <button class=\"btn btn-orange on-new-part\">+ "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New Part",{"name":"trans","hash":{},"data":data}))
    + "</button>\n\n      <div class=\"parts-list text-left\" data-drag=\"true\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.parts : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, alias5=container.escapeExpression, buffer = 
  "          <div class=\"story-part";
  stack1 = ((helper = (helper = helpers.isBonusPart || (depth0 != null ? depth0.isBonusPart : depth0)) != null ? helper : alias2),(options={"name":"isBonusPart","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isBonusPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.isScheduledPart || (depth0 != null ? depth0.isScheduledPart : depth0)) != null ? helper : alias2),(options={"name":"isScheduledPart","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isScheduledPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\" data-id=\""
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n            <div class=\"drag-handle vcenter\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-menu",28,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <div class=\"part-meta vcenter\">\n              <div class=\"part-name col-xs-12\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasBannedImages : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                \n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isBonusPart : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                <a href=\"/myworks/"
    + alias5(container.lambda(((stack1 = (depths[1] != null ? depths[1].story : depths[1])) != null ? stack1.id : stack1), depth0))
    + "/write/"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n                   class=\"auto-shorten on-navigate\">"
    + alias5((helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),{"name":"sanitize","hash":{},"data":data}))
    + "</a>\n              </div>\n              <div class=\"part-detail\">\n                <div class=\"";
  stack1 = ((helper = (helper = helpers.isScheduledPart || (depth0 != null ? depth0.isScheduledPart : depth0)) != null ? helper : alias2),(options={"name":"isScheduledPart","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(14, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isScheduledPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                    <span class=\"publish-state text-body-sm";
  stack1 = ((helper = (helper = helpers.draft || (depth0 != null ? depth0.draft : depth0)) != null ? helper : alias2),(options={"name":"draft","hash":{},"fn":container.noop,"inverse":container.program(16, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.draft) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.deleted || (depth0 != null ? depth0.deleted : depth0)) != null ? helper : alias2),(options={"name":"deleted","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.deleted) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.isScheduledPart || (depth0 != null ? depth0.isScheduledPart : depth0)) != null ? helper : alias2),(options={"name":"isScheduledPart","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isScheduledPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deleted : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.program(24, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "                    </span>          \n                    <span> - ";
  stack1 = ((helper = (helper = helpers.isScheduledPart || (depth0 != null ? depth0.isScheduledPart : depth0)) != null ? helper : alias2),(options={"name":"isScheduledPart","hash":{},"fn":container.program(32, data, 0, blockParams, depths),"inverse":container.program(34, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isScheduledPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "</span>\n                </div>\n";
  stack1 = ((helper = (helper = helpers.isScheduledPart || (depth0 != null ? depth0.isScheduledPart : depth0)) != null ? helper : alias2),(options={"name":"isScheduledPart","hash":{},"fn":container.noop,"inverse":container.program(36, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isScheduledPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "              </div>\n            </div>\n            <div class=\"button-group vcenter dropdown\">\n              <button class=\"btn btn-white dropdown-toggle\" data-toggle=\"dropdown\" aria-controls=\"profile-more-options-"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" aria-expanded=\"false\" aria-label=\""
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"More options",{"name":"trans","hash":{},"data":data}))
    + " "
    + alias5((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"button",{"name":"trans","hash":{},"data":data}))
    + ".\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",18,"wp-neutral-2","fa-l",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n              <div class=\"triangle\"></div>\n              <ul id=\"works-more-options-"
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"dropdown-menu align-right\"\n                  role=\"menu\">\n                <li role=\"none\">\n                  <a target=\"_blank\" href=\""
    + alias5((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" data-url=\""
    + alias5((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\"\n                     class=\"on-view-as-reader\" data-id=\""
    + alias5(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-attr=\"wid-toc\" role=\"menuitem\">\n                    "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,(depths[1] != null ? depths[1].lang : depths[1]),"en",{"name":"ifequal","hash":{},"fn":container.program(41, data, 0, blockParams, depths),"inverse":container.program(43, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n                  </a>\n                </li>\n";
  stack1 = ((helper = (helper = helpers.isScheduledPart || (depth0 != null ? depth0.isScheduledPart : depth0)) != null ? helper : alias2),(options={"name":"isScheduledPart","hash":{},"fn":container.program(45, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isScheduledPart) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.draft : depth0),{"name":"unless","hash":{},"fn":container.program(47, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
  stack1 = ((helper = (helper = helpers.draft || (depth0 != null ? depth0.draft : depth0)) != null ? helper : alias2),(options={"name":"draft","hash":{},"fn":container.noop,"inverse":container.program(49, data, 0, blockParams, depths),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.draft) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.draft || (depth0 != null ? depth0.draft : depth0)) != null ? helper : alias2),(options={"name":"draft","hash":{},"fn":container.program(51, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.draft) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "              </ul>\n            </div>\n          </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.scheduleSuccessModalSettings : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return " is-paid";
},"6":function(container,depth0,helpers,partials,data) {
    return " is-scheduled";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-image-warning",18,"wp-warning-01",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    var helper;

  return "                    <div class=\"bonus-chapter-display col-xs-12\">"
    + container.escapeExpression(((helper = (helper = helpers.bonusTypeName || (depth0 != null ? depth0.bonusTypeName : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"bonusTypeName","hash":{},"data":data}) : helper)))
    + "</div>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "col-xs-12";
},"14":function(container,depth0,helpers,partials,data) {
    return "col-xs-10 col-sm-5";
},"16":function(container,depth0,helpers,partials,data) {
    return " published";
},"18":function(container,depth0,helpers,partials,data) {
    return " deleted";
},"20":function(container,depth0,helpers,partials,data) {
    return " scheduled";
},"22":function(container,depth0,helpers,partials,data) {
    return "                        "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isScheduledPart : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    return "                          "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Scheduled",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.draft : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    return "                          "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Draft",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    return "                          "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Published",{"name":"trans","hash":{},"data":data}))
    + "\n                        ";
},"32":function(container,depth0,helpers,partials,data) {
    var helper;

  return "<span class=\"scheduled-date\">"
    + container.escapeExpression(((helper = (helper = helpers.formattedScheduledPublishDatetime || (depth0 != null ? depth0.formattedScheduledPublishDatetime : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"formattedScheduledPublishDatetime","hash":{},"data":data}) : helper)))
    + "</span>";
},"34":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.modifyDate : depth0),"MMM DD, YYYY",{"name":"formatDate","hash":{},"data":data}));
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "                <div class=\"col-xs-10 col-sm-5\">\n";
  stack1 = ((helper = (helper = helpers.stats || (depth0 != null ? depth0.stats : depth0)) != null ? helper : alias2),(options={"name":"stats","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.stats) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  stack1 = ((helper = (helper = helpers.stats || (depth0 != null ? depth0.stats : depth0)) != null ? helper : alias2),(options={"name":"stats","hash":{},"fn":container.noop,"inverse":container.program(39, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.stats) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "                </div>\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <div class=\"meta\">\n                      <div class=\"col-xs-4\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</div>\n                      <div class=\"col-xs-4\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</div>\n                      <div class=\"col-xs-4\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-comment",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.commentCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n                      </div>\n                    </div>\n";
},"39":function(container,depth0,helpers,partials,data) {
    return "                    <div class=\"no-stats\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"no stats available",{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"41":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"View as reader",{"name":"trans","hash":{},"data":data}));
},"43":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Preview",{"name":"trans","hash":{},"data":data}));
},"45":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                  <li role=\"none\">\n                    <a role=\"menuitem\" href=\"\"\n                      class=\"on-change-part-schedule\"\n                      data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Change schedule",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                  </li>\n";
},"47":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                  <li role=\"none\">\n                      <a role=\"menuitem\" href=\"\" data-toggle=\"modal\"\n                        class=\"on-unpublish-part\"\n                        data-target=\"#details-modal\"\n                        data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Unpublish",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                  </li>\n";
},"49":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                  <li role=\"none\">\n                    <a role=\"menuitem\" href=\"\" data-toggle=\"modal\"\n                       data-target=\"#details-modal\"\n                       class=\"on-delete-published-part\" data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                      "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Part",{"name":"trans","hash":{},"data":data}))
    + "\n                    </a>\n                  </li>\n";
},"51":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                  <li role=\"none\">\n                    <a role=\"menuitem\" href=\"\" data-toggle=\"modal\"\n                       data-target=\"#details-modal\"\n                       class=\"on-delete-part\" data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                      "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete Part",{"name":"trans","hash":{},"data":data}))
    + "\n                    </a>\n                  </li>\n";
},"53":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ScheduleSuccessModal","schedule-success-modal",(depth0 != null ? depth0.scheduleSuccessModalSettings : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isNew : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});