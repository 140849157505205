(function(window, app) {
  "use strict";

  // Login & Signup
  app.router.add({
    // node would automatically add / to route
    // so the trailing / is not optional
    ":page(/)": [
      "authentication",
      {
        regexp: {
          page: /(login|signup|reset|forgot)?/
        }
      }
    ]
  });

  app.router.add({
    "fb-auth(/)": ["oauth-auth", {}],
    "oauth-auth(/)": ["oauth-auth", {}]
  });
})(window, window.app);
