import discoverModule from "core/templates/collection-views/discover-module.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  //TODO: find out if old home and remove with home cleanup.
  app.add(
    "DiscoverModule",
    app.views.IncrementalList.extend({
      className: "discover-module",
      template: discoverModule,
      containerClass: ".collection",

      events: _.extend(
        {
          "tap .module-header": "onModuleHeader",
          "click .module-header": "stopEvent",

          "tap .on-discover-module-item": "onModuleItem",
          "click .on-discover-module-item": "stopEvent",

          "tap a.module-title": "onModuleTitle",
          "click a.module-title": "stopEvent",

          "tap .on-avatar": "onAvatar",
          "click .on-avatar": "stopEvent",

          "tap .on-story-preview": "onStoryPreview",
          "click .on-story-preview": "stopEvent",

          "tap .on-continue-reading": "onContinueReading",
          "click .on-continue-reading": "stopEvent",

          "tap .send-cover-event": "sendCoverClickTrackingEvent",
          "click .send-cover-event": "stopEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      constructor: function(options) {
        var model = options.model;

        this.pageNum = options.pageNum;
        this.experience = options.experience || "home";
        this.trackedTypes = [
          "storiesByPromotedUser",
          "listPromoted",
          "promotedStoriesRecommended"
        ];

        if (model.get("data").items.stories) {
          this.itemView = app.views.DiscoverModuleStoriesLarge;
          this.collection = new app.collections.DiscoverModuleStories(
            model.get("data").items.stories
          );
        } else if (model.get("data").items.lists) {
          this.itemView = app.views.DiscoverModuleLists;

          var listWithUserInfo = _.clone(model.get("data").items.lists);
          var userInfo = model.get("data").user;
          for (var i = 0; i < listWithUserInfo.length; i++) {
            if (listWithUserInfo[i].user == undefined) {
              listWithUserInfo[i].user = _.clone(userInfo);
            }
          }

          this.collection = new app.collections.DiscoverModuleLists(
            listWithUserInfo
          );
        } else if (model.get("data").items.users) {
          this.itemView = app.views.DiscoverModuleUsers;
          this.collection = new app.collections.DiscoverModuleUsers(
            model.get("data").items.users
          );
        }

        app.views.IncrementalList.prototype.constructor.apply(this, arguments);

        this.listenTo(app, "app:user:follow", this.onUpdateFollow);
        this.listenTo(app, "app:user:unfollow", this.onUpdateUnfollow);

        if (_.indexOf(this.trackedTypes, this.model.get("type")) != -1) {
          this.trackViewsWrapper = _.throttle(this.trackViews, 200);
          this.sentViewTracking = false;
          this.viewTrackingUrls = _.flatten([
            this.model.get("data").tracking.impressionUrl
          ]);
          this.clickTrackingUrls = _.flatten([
            this.model.get("data").tracking.clickUrl
          ]);
          if (this.model.get("data").tracking.thirdParty) {
            this.viewTrackingUrls = this.viewTrackingUrls.concat(
              this.model.get("data").tracking.thirdParty.impressionUrls
            );
            this.clickTrackingUrls = this.clickTrackingUrls.concat(
              this.model.get("data").tracking.thirdParty.clickUrls
            );
          }

          _.bindAll(this, "trackViewsWrapper");
          $(window).on("scroll", this.trackViewsWrapper);
        }
      },

      remove: function() {
        $(window).off("scroll", this.trackViewsWrapper);
        app.views.IncrementalList.prototype.remove.apply(this, arguments);
      },

      renderCollection: function(options) {
        var $container = this.getContainer();
        this.removeAll(); // clear current references (if any exist)

        options = options || {};
        _.extend(options, { isDesktop: app.get("device").isDesktop() });

        if (this.model.get("type") === "storiesRecentlyRead") {
          _.extend(options, { isRecentlyRead: true });
        } else if (this.model.get("data").items.users) {
          _.extend(options, {
            numUsersInCollection: this.model.get("data").items.users.length
          });
        }

        this.collection.each(function(model, index) {
          this.renderItem(model, $container, index, options);

          // Current support displaying one promoted story
          if (this.model.get("type") === "storiesByPromotedUser") {
            // Blurb in template renders first
            $container
              .find(".on-discover-module-item")
              .after($container.find(".promoted-blurb"));
            $container.addClass("promoted-story");
            return false;
          }
        }, this);

        // After the promoted module loads, check if it is on screen
        // If so, send impression events immediately
        if (_.indexOf(this.trackedTypes, this.model.get("type")) != -1) {
          var self = this;
          _.defer(function() {
            self.trackViewsWrapper();
          });
        }

        return this;
      },

      getTemplateData: function() {
        var title,
          subtitle,
          promoted,
          promotedReadingList,
          promotedByUser,
          moduleLink,
          moduleTarget,
          sponsorName,
          sponsorAvatar,
          fromStoryReading,
          device = new utils.Device();

        var urlPath = "/stories/";

        switch (this.model.get("type")) {
          case "listCurated":
            title = this.model.get("data").list.name;
            subtitle = wattpad.utils.trans(
              "A reading list by %s, handpicked for you",
              this.model.get("data").list.user.fullname ||
                this.model.get("data").list.user.name
            );
            moduleLink = "/list/" + this.model.get("data").list.id;
            moduleTarget = this.model.get("data").list.id;
            break;
          case "storiesRecentlyRead":
            title = wattpad.utils.trans("Continue Reading");
            subtitle = wattpad.utils.trans("Find more in your library");
            moduleLink = "/library";
            break;
          case "storiesRecommended":
            title = wattpad.utils.trans("Recommendations");
            subtitle = wattpad.utils.trans(
              "A fresh set of stories, just for you"
            );
            break;
          case "storiesRecommendedExplore":
            title = wattpad.utils.trans("Explore something different");
            subtitle = wattpad.utils.trans(
              "The more you explore, the more personalized your stories get"
            );
            break;
          case "storiesRecommendedInfluential":
            title = wattpad.utils.trans("Community Showcase");
            subtitle = wattpad.utils.trans("Sample what Wattpad has to offer");
            break;
          case "storiesSupported":
            title = wattpad.utils.trans("Paid Stories");
            subtitle = wattpad.utils.trans(
              "Become a supporter and help your favorite writers earn money"
            );
            break;
          case "storiesRecommendedRNN":
            title = wattpad.utils.trans("What to read next");
            subtitle = wattpad.utils.trans(
              "Stories based on your recent reads"
            );
            break;
          case "storiesReadingRecommended":
            title = wattpad.utils.trans("Recommendations");
            fromStoryReading = true;
            break;
          case "promotedStoriesRecommended":
            title = wattpad.utils.trans("Promoted stories");
            fromStoryReading = true;
            break;
          case "storiesRecentlyUpdated":
            title = wattpad.utils.trans("Recently Updated");
            subtitle = wattpad.utils.trans(
              "Stories from your library and connections"
            );
            break;
          case "storiesTop":
            title = wattpad.utils.trans("Most Shared Stories");
            subtitle = wattpad.utils.trans("See what everyone's talking about");
            break;
          case "storiesByFollowedHub":
            title = wattpad.utils.sprintf(
              wattpad.utils.ngettext(
                "Story by %s",
                "Stories by %s",
                (this.model.get("data").items.stories &&
                  this.model.get("data").items.stories.length) ||
                  0
              ),
              [
                this.model.get("data").user.fullname ||
                  this.model.get("data").user.name
              ]
            );
            subtitle = wattpad.utils.trans(
              "Stories written by a profile you follow"
            );
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "tagTop":
            title = wattpad.utils.trans(
              "Hottest stories tagged %s",
              "#" + this.model.get("data").tag.name
            );
            subtitle = wattpad.utils.trans("A tag that might interest you");
            moduleLink = urlPath + this.model.get("data").tag.name;
            moduleTarget = this.model.get("data").tag.name;
            break;
          case "tagRecommended":
            title = wattpad.utils.trans(
              "%s stories",
              "#" + this.model.get("data").tag.name
            );
            subtitle = wattpad.utils.trans("A tag recommended just for you");
            moduleLink = urlPath + this.model.get("data").tag.name;
            moduleTarget = this.model.get("data").tag.name;
            break;
          case "storiesByRecentVote":
            title = wattpad.utils.trans(
              "Because you voted on %s",
              this.model.get("data").story.title
            );
            subtitle = wattpad.utils.trans(
              "We think you'll like these stories too"
            );
            moduleLink = this.model.get("data").story.url;
            moduleTarget = this.model.get("data").story.url;
            break;
          case "hubTop":
            title = wattpad.utils.trans(
              "Reading lists by %s",
              this.model.get("data").user.fullname ||
                this.model.get("data").user.name
            );
            subtitle = wattpad.utils.trans("Collections from a top profile");
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "listsByFollowedHub":
            title = wattpad.utils.trans(
              "Reading lists by %s",
              this.model.get("data").user.fullname ||
                this.model.get("data").user.name
            );
            subtitle = wattpad.utils.trans(
              "Handpicked by a profile you follow"
            );
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "storiesRecentlyVotedByFollowedHubs":
            title = wattpad.utils.trans(
              "%s voted on",
              this.model.get("data").user.fullname ||
                this.model.get("data").user.name
            );
            subtitle = wattpad.utils.trans("Stories you might also like");
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "hubsFollowedByFollowedHub":
            title = wattpad.utils.trans(
              "%s follows",
              this.model.get("data").user.fullname ||
                this.model.get("data").user.name
            );
            subtitle = wattpad.utils.trans("Discover new profiles");
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "listTrending":
            title = this.model.get("data").list.name;
            subtitle = wattpad.utils.trans("A trending reading list");
            moduleLink = "/list/" + this.model.get("data").list.id;
            moduleTarget = this.model.get("data").list.id;
            break;
          case "hubTrending":
            title = wattpad.utils.trans(
              "Reading lists by %s",
              this.model.get("data").user.fullname ||
                this.model.get("data").user.name
            );
            subtitle = wattpad.utils.trans("Selected by a trending profile");
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "tagTrending":
            title = "#" + this.model.get("data").tag.name;
            subtitle = wattpad.utils.trans("A trending tag");
            moduleLink = urlPath + this.model.get("data").tag.name;
            moduleTarget = this.model.get("data").tag.name;
            break;
          case "storiesBySearchResults":
            title = wattpad.utils.trans("Based on recent searches");
            subtitle = wattpad.utils.trans("Stories you might also like");
            moduleLink = urlPath + this.model.get("data").items.tags[0];
            moduleTarget = this.model.get("data").items.tags[0];
            break;
          case "emergentStories":
            title = wattpad.utils.trans("Emerging Stories");
            subtitle = wattpad.utils.trans("Stories we think you'll love");
            break;
          //promoted modules
          case "storiesByPromotedUser":
            title = wattpad.utils.sprintf(
              wattpad.utils.ngettext("Story by %s", "Stories by %s", 1),
              [
                this.model.get("data").user.fullname ||
                  this.model.get("data").user.name
              ]
            );
            subtitle = wattpad.utils.ngettext(
              "Story written by a fellow Wattpad writer",
              "Stories written by a fellow Wattpad writer",
              1
            );
            sponsorName =
              this.model.get("data").user.fullname ||
              this.model.get("data").user.name;
            promoted = true;
            promotedByUser = true;
            moduleLink = "/user/" + this.model.get("data").user.name;
            moduleTarget = this.model.get("data").user.name;
            break;
          case "listPromoted":
            title = this.model.get("data").list.name;
            subtitle = wattpad.utils.trans(
              "A reading list, handpicked for you"
            );
            if (this.model.get("data").sponsor) {
              sponsorName = this.model.get("data").sponsor.name;
              if (this.model.get("data").sponsor.avatar) {
                sponsorAvatar = this.model.get("data").sponsor.avatar;
              }
            } else {
              sponsorName =
                this.model.get("data").list.user.fullname ||
                this.model.get("data").list.user.name;
            }
            moduleLink = "/list/" + this.model.get("data").list.id;
            moduleTarget = this.model.get("data").list.id;
            promoted = true;
            promotedReadingList = true;
            break;
        }

        var data = {
          title: title,
          subtitle: subtitle,
          promoted: promoted,
          promotedReadingList: promotedReadingList,
          promotedByUser: promotedByUser,
          fromStoryReading: fromStoryReading,
          sponsorName: sponsorName,
          sponsorAvatar: sponsorAvatar,
          moduleUser:
            this.model.get("data").user ||
            (this.model.get("data").list && this.model.get("data").list.user),
          list: this.model.get("data").list,
          // Expects URL path in this format: '/[user|list|<pathname>]/...'
          // The URL path is parsed in onModuleTitle()
          moduleLink: moduleLink,
          moduleTarget: moduleTarget,
          type: this.model.get("type"),
          isDesktop: device.is.desktop,
          testGroups: wattpad.testGroups,
          languageId: parseInt(app.get("language"), 10)
        };

        if (this.model.get("data").items.lists) {
          data.isLists = true;
        } else if (this.model.get("data").items.users) {
          data.isUsers = true;
        }

        var items =
          this.model.get("data").items.stories ||
          this.model.get("data").items.lists ||
          this.model.get("data").items.users;
        if (items.length <= 2) {
          data.turnOffLooping = true;
        }
        data.isHome = this.experience === "home";
        return data;
      },

      onAvatar: function(evt) {
        var moduleType = this.model.get("type");
        window.te.push("event", this.experience, "module", "avatar", "click", {
          module_type: moduleType,
          username: evt.currentTarget.getAttribute("data-target")
        });
        if (_.indexOf(this.trackedTypes, moduleType) != -1) {
          window.te.push(
            "event",
            this.experience,
            "promoted_module",
            "avatar",
            "click",
            {
              module_type: moduleType,
              username: evt.currentTarget.getAttribute("data-target")
            }
          );
        }
      },

      onStoryPreview: function(evt) {
        const storyId = $(evt.currentTarget).data("story-id");

        var story = this.collection.get(storyId);

        const shouldSeeOnboarding = utils.shouldSeePaidOnboarding(
          story.get("isPaywalled")
        );

        if (shouldSeeOnboarding) {
          window.app.components.showPaidOnboardingModal({
            premium: wattpad.utils.currentUser().get("isPremium"),
            onOnboardingFinish: () => {
              this.showBrowsePreview(storyId);
            }
          });
        } else {
          this.showBrowsePreview(storyId);
        }
      },

      showBrowsePreview: function(storyId) {
        var story = new app.models.StoryModel({ id: storyId });

        Promise.resolve(story.fetch({ fresh: true })).then(function() {
          var preview = new app.views.BrowseStoryPreview({
            isHome: true,
            showTagRankings: true,
            model: story
          });

          $("#preview-modal")
            .empty()
            .append(preview.render().$el);
          preview.openModal();
        });

        if (_.indexOf(this.trackedTypes, this.model.get("type")) != -1) {
          this.trackClicks();
        }
      },

      onUpdateFollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn.removeClass("btn-white").addClass("btn-teal");
        $btn
          .children(".fa")
          .removeClass("fa-follow fa-wp-neutral-2")
          .addClass("fa-following fa-wp-neutral-5");
        $btn.children(".follow-text").text(utils.trans("Following"));
      },

      onUpdateUnfollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn.removeClass("btn-teal").addClass("btn-white");
        $btn
          .children(".fa")
          .removeClass("fa-following fa-wp-neutral-5")
          .addClass("fa-follow fa-wp-neutral-2");
        $btn.children(".follow-text").text(utils.trans("Follow"));
      },

      onFollow: function(evt) {
        var moduleType = this.model.get("type");
        window.te.push("event", this.experience, "module", "user", "follow", {
          module_type: moduleType,
          username: evt.currentTarget.getAttribute("data-target")
        });
        if (_.indexOf(this.trackedTypes, moduleType) != -1) {
          window.te.push(
            "event",
            this.experience,
            "promoted_module",
            "user",
            "follow",
            {
              module_type: moduleType,
              username: evt.currentTarget.getAttribute("data-target")
            }
          );
        }
      },

      onUnfollow: function(evt) {
        var moduleType = this.model.get("type");
        window.te.push("event", this.experience, "module", "user", "unfollow", {
          module_type: moduleType,
          username: evt.currentTarget.getAttribute("data-target")
        });
        if (_.indexOf(this.trackedTypes, moduleType) != -1) {
          window.te.push(
            "event",
            this.experience,
            "promoted_module",
            "user",
            "unfollow",
            {
              module_type: moduleType,
              username: evt.currentTarget.getAttribute("data-target")
            }
          );
        }
      },

      onContinueReading: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        var story = this.collection.get(storyId);

        Promise.resolve(story.fetch({ fresh: true })).then(function() {
          Promise.resolve(
            $.get("/apiv2/syncreadingposition", {
              story_id: story.get("firstPartId")
            })
          ).then(function(data) {
            var part = _.where(story.get("parts"), {
              id: parseInt(data.part, 10)
            });
            if (part[0]) {
              app.router.navigate(wattpad.utils.formatStoryUrl(part[0].url), {
                trigger: true
              });
            } else {
              app.router.navigate(
                wattpad.utils.formatStoryUrl(story.get("url")),
                { trigger: true }
              );
            }
          });
        });
      },

      onModuleTitle: function(evt) {
        var moduleType = this.model.get("type"),
          itemType = null,
          itemId = $(evt.currentTarget).data("target"),
          // '/user/someusername' or '/list/12345678'
          pathArray = $(evt.currentTarget)
            .attr("href")
            .split("/");

        // Continue Reading module
        if (moduleType === "storiesRecentlyRead") {
          itemId = null;
        } else if (pathArray[1] === "list") {
          itemType = "reading list";
        } else if (pathArray[1] === "user") {
          itemType = "user";
        }

        window.te.push("event", this.experience, "module", "title", "click", {
          module_type: moduleType,
          item_type: itemType,
          itemid: itemId
        });

        if (_.indexOf(this.trackedTypes, moduleType) != -1) {
          window.te.push(
            "event",
            this.experience,
            "promoted_module",
            "title",
            "click",
            {
              module_type: moduleType,
              item_type: itemType,
              itemid: itemId
            }
          );
          this.trackClicks();
        }
      },

      onModuleItem: function(evt) {
        var target = $(evt.currentTarget).find(".content");
        var moduleType = this.model.get("type");

        // Get the identifier of the item that caused the module to come to the
        // user's attention, if any
        var itemid = null;
        switch (moduleType) {
          case "hubsFollowedByFollowedHub":
          case "listsByFollowedHub":
          case "storiesByFollowedHub":
          case "storiesByPromotedUser":
          case "storiesRecentlyVotedByFollowedHubs":
          case "hubTrending":
            itemid = _.get(this.model.get("data"), "user.name");
            break;
          case "listCurated":
          case "listPromoted":
          case "listTrending":
            itemid = _.get(this.model.get("data"), "list.id");
            break;
          case "tagTop":
          case "tagTrending":
          case "tagRecommended":
            itemid = _.get(this.model.get("data"), "tag.name");
            break;
          case "storiesBySearchResults":
            itemid = _.get(this.model.get("data"), "tags[0]");
            break;
        }

        window.te.push("event", this.experience, "module", "content", "click", {
          module_type: moduleType,
          content_type: target.data("type"),
          contentid: target.data("target"),
          itemid: itemid,
          page_number: this.pageNum
        });

        if (_.indexOf(this.trackedTypes, moduleType) != -1) {
          window.te.push(
            "event",
            this.experience,
            "promoted_module",
            "content",
            "click",
            {
              module_type: moduleType,
              content_type: target.data("type"),
              contentid: target.data("target"),
              itemid: itemid
            }
          );
        }
      },

      trackClicks: function() {
        _.each(this.clickTrackingUrls, function(url) {
          $.ajax({
            type: "GET",
            url: url
          });
        });
      },

      trackViews: function() {
        var isOnScreen = utils.isOnScreen(this.$el, 0.67);

        if (isOnScreen && !this.sentViewTracking) {
          this.sentViewTracking = true;

          _.each(this.viewTrackingUrls, function(url) {
            $.ajax({
              type: "GET",
              url: url
            });
          });
        }
      },

      sendCoverClickTrackingEvent: function(evt) {
        window.te.push("event", "discover", "list", "cover", "click", {
          storyid: $(evt.currentTarget).data("story-id"),
          moduletype: this.model.get("type")
        });
      },

      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      }
    })
  );

  app.mixin(app.views.DiscoverModule, "InfiniteScroll", "FollowManager");
})(window, wattpad, wattpad.utils, window.app);
