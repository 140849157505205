import libraryListsControls from "core/templates/library/library-controls/library-lists-controls.hbs";
(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "LibraryListsControls",
    Monaco.View.extend({
      template: libraryListsControls,

      events: {
        "tap .on-newlist": "onOpenNewListModal"
      },

      render: function() {
        this.$el.html(this.template({ islist: true }));

        return this;
      },

      onOpenNewListModal: function() {
        var $previewModal = $("#generic-modal");
        var createList = new app.views.LibraryListCreate();

        $previewModal
          .find(".modal-body")
          .empty()
          .append(createList.render().$el);
        $previewModal.modal("show");
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
