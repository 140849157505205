import socialSharing from "core/templates/mixins/social-sharing.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "SocialSharing",
    Monaco.Mixin.create({
      events: {
        "tap .social-share": "onSocialShareSelected",
        "click .social-share": "stopEvent"
      },

      templates: { row: socialSharing },

      getSocialShareTemplateHTML: function(templateType, links, next) {
        return this.templates[templateType]({ links: links });
      },

      onSocialShareSelected: function(evt, next) {
        if (evt.isPropagationStopped && evt.isPropagationStopped()) {
          next(evt);
          return;
        }

        var $target = $(evt.currentTarget),
          channel = $target.data("share-channel"),
          popupWindow = this.createPopup(
            "", // empty window
            channel,
            $target.data("width") || 600,
            $target.data("height") || 320
          );

        utils.stopEvent(evt);

        // If we have a share URL template, we are supposed to generate a
        // shortened story link.
        if ($target.data("share-url")) {
          var content = utils.urlDecode($target.data("share-content")),
            text = utils.urlDecode($target.data("share-text")),
            shareUrl = utils.urlDecode($target.data("share-url")),
            url = utils.urlDecode($target.data("url")),
            apiUrl = "/v4/link";

          Promise.resolve(
            $.get(apiUrl, {
              source: "web",
              fields: "url",
              content: content,
              medium: channel,
              uuid: utils.getCookie("wp_id"),
              url: url,
              shorten: 1
            })
          ).then(function(response) {
            text = window.sprintf(text, response.url);
            popupWindow.location = wattpad.utils.sprintf(shareUrl, [
              encodeURIComponent(text),
              encodeURIComponent(response.url)
            ]);
          });
        } else {
          popupWindow.location = $target.attr("href");
        }

        next(evt);
      },

      createPopup: function(url, network, width, height, noop) {
        noop();
        return utils.openPopup(
          url,
          network + " " + utils.trans("Share"),
          width,
          height
        );
      },

      _buildSocialShareLinks: function(meta, contentType, noop) {
        noop();

        // These make it easier to copy and paste code between server and client
        var sprintf = window.vsprintf,
          trans = utils.trans,
          slugify = utils.slugify,
          simpleShorten = utils.simpleShorten,
          urlEncode = utils.urlEncode,
          urlEncodeWithSpace = utils.urlEncodeWithSpace,
          getContent = _.bind(this._getContent, this),
          getSourceUrl = _.bind(this._getSourceUrl, this),
          escapeSpecifiers = _.bind(this._escapeSpecifiers, this);

        var url,
          shareHashTags = " #wattpad #" + slugify(meta.group.category),
          allTags = _.reduce(
            meta.group.tags,
            function(ret, tag) {
              ret += slugify(tag.name) + ",";
              return ret;
            },
            ""
          ),
          tumblrTags = allTags + "books,wattpad",
          shareText;

        if (
          contentType === "story_landing" ||
          contentType === "story_reading"
        ) {
          tumblrTags += ",amreading";
        } else {
          tumblrTags += ",amwriting";
        }

        var shareTitle = meta.group.title;
        if (meta.part) {
          url = meta.part.url;
          if (meta.group.title != meta.part.title) {
            shareTitle = trans(
              "%1$s - %2$s",
              meta.group.title,
              meta.part.title
            );
          }
        } else {
          url = meta.group.url;
        }

        // mention twitter user, if available
        var twitterTitle = shareTitle;
        if (
          typeof meta.user !== "undefined" &&
          typeof meta.user.twitter === "string" &&
          meta.user.twitter.length > 0
        ) {
          twitterTitle = trans("%1$s by @%2$s", shareTitle, meta.user.twitter);
        }

        shareText = {
          share: shareTitle,
          combined:
            shareHashTags + " " + simpleShorten(meta.group.description, 300),
          twitter: twitterTitle + " #wattpad",
          tumblr: trans(
            "<b>%1$s</b> (on Wattpad) %%s\n\n%2$s",
            escapeSpecifiers(shareTitle),
            escapeSpecifiers(simpleShorten(meta.group.description))
          )
        };

        return [
          {
            name: "facebook",
            icon: "fa-facebook",
            label: trans("Share via Facebook"),
            href: sprintf("https://www.facebook.com/sharer.php?u=%s", [
              urlEncode(getSourceUrl("facebook", url, contentType))
            ])
          },
          {
            name: "twitter",
            icon: "fa-twitter",
            label: trans("Share via Twitter"),
            content: getContent(contentType),
            text: shareText.twitter,
            urlTemplate: "https://twitter.com/intent/tweet?text=%s&url=%s",
            url: url,
            href: sprintf("https://twitter.com/intent/tweet?text=%s&url=%s", [
              urlEncode(shareText.twitter),
              urlEncode(getSourceUrl("twitter", url, contentType))
            ])
          },
          {
            name: "pinterest",
            icon: "fa-pinterest",
            label: trans("Share via Pinterest"),
            href: sprintf(
              "https://www.pinterest.com/pin/create/button/?media=%s&description=%s&url=%s",
              [
                urlEncode(meta.group.cover),
                urlEncode(shareText.combined),
                urlEncode(getSourceUrl("pinterest", url, contentType))
              ]
            )
          },
          {
            name: "tumblr",
            icon: "fa-tumblr",
            label: trans("Share via Tumblr"),
            text: shareText.tumblr,
            urlTemplate: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%%s",
              [
                urlEncode(meta.group.cover),
                urlEncode(tumblrTags),
                urlEncode(url)
              ]
            ),
            content: getContent(contentType),
            url: url,
            href: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%s",
              [
                urlEncode(
                  window.wattpad.siteRoot + "/banner?id=" + meta.group.id
                ),
                urlEncode(
                  sprintf(shareText.tumblr, [
                    getSourceUrl("tumblr", url, contentType)
                  ])
                ),
                urlEncode(tumblrTags),
                urlEncode(url),
                urlEncode(getSourceUrl("tumblr", url, contentType))
              ]
            )
          },
          {
            name: "email",
            icon: "fa-inbox",
            nopopup: true,
            label: trans("Share via Email"),
            href: sprintf("mailto:?subject=%s&body=%s%%0A%s", [
              urlEncodeWithSpace(shareText.share),
              urlEncodeWithSpace(shareText.share),
              urlEncode(getSourceUrl("email", url, contentType))
            ])
          }
        ];
      },

      buildSocialShareLinksForStory: function(story, contentType, next) {
        contentType = contentType || "story_landing";

        var attrs = story.attributes || story,
          category;
        if (attrs.categoryObject) {
          category =
            attrs.categoryLabels && attrs.categoryLabels.length > 0
              ? attrs.categoryLabels[0].name
              : attrs.categoryObject.name;
        }

        var tags = _.map(attrs.tags, function(tag) {
          return { name: tag };
        });

        if (category) {
          tags.concat(category);
        }

        var meta = {
          group: {
            id: attrs.id,
            title: attrs.title,
            cover: attrs.cover,
            category: category,
            url: attrs.url,
            description: attrs.description,
            tags: tags
          },
          user: {
            twitter: attrs.user.twitter
          }
        };
        var socialLinks = this._buildSocialShareLinks(meta, contentType);
        var additionalLinks = next(story) || [];
        return _.union(socialLinks, additionalLinks);
      },

      buildSocialShareLinksForPart: function(meta, next) {
        var socialLinks = this._buildSocialShareLinks(meta, "story_reading"),
          additionalLinks = next(meta) || [];

        return _.union(socialLinks, additionalLinks);
      },

      // meta = { username: '', name: '', avatarUrl: '', description: '' }
      buildSocialShareLinksForProfile: function(meta, next) {
        // These make it easier to copy and paste code between server and client
        var sprintf = window.vsprintf,
          trans = utils.trans,
          simpleShorten = utils.simpleShorten,
          urlEncode = utils.urlEncode,
          urlEncodeWithSpace = utils.urlEncodeWithSpace,
          getContent = _.bind(this._getContent, this),
          getSourceUrl = _.bind(this._getSourceUrl, this),
          escapeSpecifiers = _.bind(this._escapeSpecifiers, this);

        var profileUrl = wattpad.siteRoot + "/" + meta.username,
          shareText = {
            default: trans("Check out %s's profile on Wattpad", meta.name),
            twitter: trans("Check out %s's profile on #Wattpad", meta.name)
          };

        if (utils.currentUser().get("username") === meta.username) {
          shareText = {
            default: trans(
              "Check out my profile on Wattpad, I'm %s",
              meta.name
            ),
            twitter: trans(
              "Check out my profile on #Wattpad, I'm %s",
              meta.name
            )
          };
        }

        var tumblrTags = "books,wattpad,amreading",
          tumblrText =
            shareText.default +
            " " +
            getSourceUrl("tumblr", profileUrl, "profile") +
            " " +
            simpleShorten(meta.description, 300);

        return [
          {
            name: "facebook",
            icon: "fa-facebook",
            label: trans("Share via Facebook"),
            href: sprintf("https://www.facebook.com/sharer.php?u=%s", [
              urlEncode(getSourceUrl("facebook", profileUrl, "profile"))
            ])
          },
          {
            name: "twitter",
            icon: "fa-twitter",
            label: trans("Share via Twitter"),
            content: getContent("profile"),
            text: shareText.twitter,
            urlTemplate: "https://twitter.com/intent/tweet?text=%s&url=%s",
            url: profileUrl,
            href: sprintf("https://twitter.com/intent/tweet?text=%s&url=%s", [
              urlEncode(shareText.twitter),
              urlEncode(getSourceUrl("twitter", profileUrl, "profile"))
            ])
          },
          {
            name: "pinterest",
            icon: "fa-pinterest",
            label: trans("Share via Pinterest"),
            href: sprintf(
              "https://www.pinterest.com/pin/create/button/?media=%s&description=%s&url=%s",
              [
                urlEncode(meta.avatarUrl),
                urlEncode(
                  shareText.default + " " + simpleShorten(meta.description, 300)
                ),
                urlEncode(getSourceUrl("pinterest", profileUrl, "profile"))
              ]
            )
          },
          {
            name: "tumblr",
            icon: "fa-tumblr",
            label: trans("Share via Tumblr"),
            text: tumblrText,
            urlTemplate: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%%s",
              [
                urlEncode(meta.avatarUrl),
                urlEncode(tumblrTags),
                urlEncode(profileUrl)
              ]
            ),
            content: getContent("profile"),
            url: profileUrl,
            href: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%s",
              [
                urlEncode(meta.avatarUrl),
                urlEncode(tumblrText),
                urlEncode(tumblrTags),
                urlEncode(profileUrl),
                urlEncode(getSourceUrl("tumblr", profileUrl, "profile"))
              ]
            )
          },
          {
            name: "email",
            icon: "fa-inbox",
            nopopup: true,
            label: trans("Share via Email"),
            href: sprintf("mailto:?subject=%s&body=%s%%0A%s%%0A%s", [
              urlEncodeWithSpace(shareText.default),
              urlEncodeWithSpace(shareText.default),
              urlEncode(getSourceUrl("email", profileUrl, "profile")),
              urlEncodeWithSpace(meta.description)
            ])
          }
        ];
      },

      //need list id, name, author, cover
      buildSocialShareLinksForReadingList: function(meta, next) {
        // These make it easier to copy and paste code between server and client
        var sprintf = window.vsprintf,
          trans = utils.trans,
          slugify = utils.slugify,
          urlEncode = utils.urlEncode,
          urlEncodeWithSpace = utils.urlEncodeWithSpace,
          getContent = _.bind(this._getContent, this),
          getSourceUrl = _.bind(this._getSourceUrl, this),
          escapeSpecifiers = _.bind(this._escapeSpecifiers, this);

        var listUrl =
          window.wattpad.siteRoot +
          "/list/" +
          meta.id +
          "-" +
          slugify(meta.name);
        var shareText = {
          share: trans("%s - A reading list by %s", meta.name, meta.author),
          tumblr: trans(
            "Check out this reading list <b>%s</b> on Wattpad %%s",
            escapeSpecifiers(meta.name)
          )
        };
        var tumblrTags = "books,amreading,wattpad";
        var shareHashTags = " #wattpad";
        var shareTextEncoded = urlEncode(shareText.share);
        var combined = urlEncode(shareText.share + shareHashTags);

        var socialLinks = [
          {
            name: "facebook",
            icon: "fa-facebook",
            label: trans("Share via Facebook"),
            href: sprintf("https://www.facebook.com/sharer.php?u=%s", [
              urlEncode(getSourceUrl("facebook", listUrl, "list"))
            ])
          },
          {
            name: "twitter",
            icon: "fa-twitter",
            label: trans("Share via Twitter"),
            href: sprintf("https://twitter.com/intent/tweet?text=%s&url=%s", [
              combined,
              urlEncode(getSourceUrl("twitter", listUrl, "list"))
            ])
          },
          {
            name: "pinterest",
            icon: "fa-pinterest",
            label: trans("Share via Pinterest"),
            href: sprintf(
              "https://www.pinterest.com/pin/create/button/?media=%s&description=%s&url=%s",
              [
                urlEncode(meta.cover),
                shareTextEncoded,
                urlEncode(getSourceUrl("pinterest", listUrl, "list"))
              ]
            )
          },
          {
            name: "tumblr",
            icon: "fa-tumblr",
            label: trans("Share via Tumblr"),
            text: urlEncode(shareText.tumblr),
            urlTemplate: urlEncode(
              sprintf(
                "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%%s",
                [
                  urlEncode(meta.cover),
                  urlEncode(tumblrTags),
                  urlEncode(listUrl)
                ]
              )
            ),
            content: urlEncode(getContent("list")),
            url: urlEncode(listUrl),
            href: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%s",
              [
                urlEncode(meta.cover),
                urlEncode(
                  sprintf(shareText.tumblr, [
                    getSourceUrl("tumblr", listUrl, "list")
                  ])
                ),
                urlEncode(tumblrTags),
                urlEncode(listUrl),
                urlEncode(getSourceUrl("tumblr", listUrl, "list"))
              ]
            )
          },
          {
            name: "email",
            icon: "fa-inbox",
            nopopup: true,
            label: trans("Share via Email"),
            href: sprintf("mailto:?subject=%s&body=%s%%0A%s", [
              urlEncodeWithSpace(shareText.share),
              urlEncodeWithSpace(shareText.share),
              urlEncode(getSourceUrl("email", listUrl, getContent("list")))
            ])
          }
        ];

        var additionalLinks = next(meta) || [];

        return _.union(socialLinks, additionalLinks);
      },

      buildSocialShareLinksForTag: function(meta, next) {
        // These make it easier to copy and paste code between server and client
        var sprintf = window.vsprintf,
          trans = utils.trans,
          slugify = utils.slugify,
          urlEncode = utils.urlEncode,
          getContent = _.bind(this._getContent, this),
          getSourceUrl = _.bind(this._getSourceUrl, this),
          urlPath = "/stories/";

        var tagUrl = window.wattpad.siteRoot + urlPath + meta.tag;
        var ogDescription =
          trans("Find %s stories you'll love.", meta.tag) +
          " " +
          trans("Read free books and stories about %s on Wattpad.", meta.tag);
        var twitterShare = trans(
          "You should read #%s stories on Wattpad",
          meta.tag
        );
        var contentType = "tag";

        if (meta.isHot) {
          tagUrl += "/hot";
          ogDescription = trans(
            "Find the hottest %s stories you'll love. Read hot and popular stories about %s on Wattpad.",
            meta.tag,
            meta.tag
          );
          twitterShare = trans(
            "You should read hot #%s stories on #Wattpad",
            meta.tag
          );
          contentType = "tag_hot";
        } else if (meta.isNew) {
          tagUrl += "/new";
          ogDescription = trans(
            "Find the latest %s stories you'll love. Read new stories about %s on Wattpad.",
            meta.tag,
            meta.tag
          );
          twitterShare = trans(
            "You should read new #%s stories on #Wattpad",
            meta.tag
          );
          contentType = "tag_new";
        } else if (meta.isList) {
          tagUrl += "/lists";
          ogDescription = trans(
            "Find good reading lists of %s stories you'll love. Browse collections of favorite stories about %s on Wattpad.",
            meta.tag,
            meta.tag
          );
          twitterShare = trans(
            "You should read collections of #%s stories on #Wattpad",
            meta.tag
          );
          contentType = "tag_list";
        }

        var shareText = {
          share: ogDescription,
          tumblr: ogDescription
        };

        var tumblrTags = "books,amreading,wattpad," + meta.tag;
        var shareTextEncoded = urlEncode(shareText.share);

        var socialLinks = [
          {
            name: "facebook",
            icon: "fa-facebook",
            label: trans("Share via Facebook"),
            href: sprintf("https://www.facebook.com/sharer.php?u=%s", [
              urlEncode(getSourceUrl("facebook", tagUrl, contentType))
            ])
          },
          {
            name: "twitter",
            icon: "fa-twitter",
            label: trans("Share via Twitter"),
            href: sprintf("https://twitter.com/intent/tweet?text=%s&url=%s", [
              urlEncode(twitterShare),
              urlEncode(getSourceUrl("twitter", tagUrl, contentType))
            ])
          },
          {
            name: "pinterest",
            icon: "fa-pinterest",
            label: trans("Share via Pinterest"),
            href: sprintf(
              "https://www.pinterest.com/pin/create/button/?media=%s&description=%s&url=%s",
              [
                urlEncode(meta.pinterestImage),
                shareTextEncoded,
                urlEncode(getSourceUrl("pinterest", tagUrl, contentType))
              ]
            )
          },
          {
            name: "tumblr",
            icon: "fa-tumblr",
            label: trans("Share via Tumblr"),
            text: shareText.tumblr,
            urlTemplate: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%%s",
              [
                urlEncode(meta.tumblrImage),
                urlEncode(tumblrTags),
                urlEncode(tagUrl)
              ]
            ),
            content: getContent(contentType),
            url: tagUrl,
            href: sprintf(
              "https://tumblr.com/widgets/share/tool?posttype=photo&content=%s&caption=%s&tags=%s&canonicalUrl=%s&clickthroughUrl=%s",
              [
                urlEncode(meta.tag),
                urlEncode(
                  sprintf(shareText.tumblr, [
                    getSourceUrl("tumblr", tagUrl, contentType)
                  ])
                ),
                urlEncode(tumblrTags),
                urlEncode(tagUrl),
                urlEncode(getSourceUrl("tumblr", tagUrl, contentType))
              ]
            )
          }
        ];

        var additionalLinks = next(meta) || [];

        return _.union(socialLinks, additionalLinks);
      },

      // utm_content for GA tracking
      _getContent: function(type, noop) {
        var content = "share_info";

        switch (type) {
          case "list":
            content = "share_readinglist";
            break;
          case "story_landing":
            content = "share_info";
            break;
          case "story_reading":
            content = "share_reading";
            break;
          case "myworks":
            content = "share_myworks";
            break;
          case "myworks_details":
            content = "share_myworks_details";
            break;
          case "profile":
            content = "share_profile";
            break;
          case "tag":
            content = "share_tag";
            break;
          case "tag_hot":
            content = "share_tag_hot";
            break;
          case "tag_new":
            content = "share_tag_new";
            break;
          case "tag_list":
            content = "share_tag_list";
          default:
            break;
        }

        noop();

        return content;
      },

      _getSourceUrl: function(medium, url, type, noop) {
        var content = this._getContent(type);

        noop();

        return wattpad.utils.addSource(url, "web", medium, content);
      },

      _escapeSpecifiers: function(string, noop) {
        noop();
        return string.replace(/%/g, "%%");
      },

      stopEvent: function(evt, noop) {
        utils.stopEvent(evt);
        noop();
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
