(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "UserModel",
    app.models.BaseModel.extend({
      idAttribute: "username",

      fields: ["id,username,avatar,inbox"],

      urlRoot: "/api/v3/users/",

      resource: function() {
        return "user." + this.get("username");
      }
    })
  );
})(window, _, wattpad, window.app);
