/*
 * Base Transition
 * for utility functions that can be used by all transitions
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "BaseTransition",
    Monaco.Transition.extend({
      _canonicalURL: function(options) {
        var curUrl = Monaco.history.fragment || "";
        var newUrl = options.isCaseSensitiveUrl ? curUrl : curUrl.toLowerCase();

        // profile name keep cases
        if (
          typeof options.username !== "undefined" &&
          options.username !== ""
        ) {
          newUrl = newUrl.replace(
            options.username.toLowerCase(),
            options.username
          );
        }

        if (curUrl !== newUrl) {
          app.router.navigate(newUrl, {
            trigger: false,
            replace: true
          });
        }
      },

      _hideAddressBar: function() {
        wattpad.utils.hideAddressBar();
      },

      _pageView: function() {
        wattpad.utils.pushEvent({}, "virtualPageview");
        // WEB-7802 Make sure client side navigation triggers
        // refresh for video ads
        if (typeof window._FskRefresh !== "undefined") {
          window._FskRefresh();
        }
        // spring serve page refresh for outstream
        if (typeof window.initMIA !== "undefined") {
          window.initMIA();
        }
      },

      injectDOM: function(fromView, toView, containerSelector, emptyContainer) {
        var $container = $(containerSelector);

        // bind with the current view if first page load
        if (!fromView && $container.children().length > 0) {
          // todo: this might need to change based on the logic
          //       that the web team wants to follow - right now it
          //       binds the first element of the container to the view
          toView.setElement($container.children(0)[0]);
          $("body").removeClass("js-app-off");

          // Tell Boomerang that we're ready.
          if (window.BOOMR) {
            // Boomerang is already loaded.
            window.BOOMR.page_ready();
            window.BOOMR.addVar("tag", "CSR");
          } else if (document.addEventListener) {
            // Boomerang has not yet loaded, but might still load.
            document.addEventListener("onBoomerangLoaded", function(e) {
              e.detail.BOOMR.page_ready();
              e.detail.BOOMR.addVar("tag", "CSR");
            });
          }

          // Setup for CSR transition timing
          app.router.on("route", function(event) {
            if (window.BOOMR) {
              app.set("transition_timer", window.BOOMR.requestStart());
            }
          });

          app.trigger("app:client-bound", { app: app, view: toView });
        }

        // replace the container content for all other transitions
        else {
          // empty the container by default unless told otherwise
          if (emptyContainer) {
            $container.empty();
          }
          $container.append(toView.$el);
        }
        //
        //  Google AFP prototype code
        //
        // always remove the afp script ad meta tags if present
        var afpIds = [
          "afp-script",
          "afp-meta-host",
          "afp-meta-child",
          "afp-meta-author"
        ];
        afpIds.forEach(function(id) {
          var afpScript = document.getElementById(id);
          if (afpScript) {
            afpScript.remove();
          }
        });

        var clientId, author_username;

        // use clientId directly from the model
        if (
          wattpad.testGroups.USE_AFP &&
          (toView?.model?.attributes.group || // on reading pages
            toView?.model?.attributes.numParts) // on story details pages
        ) {
          author_username = toView.model.attributes.group
            ? toView.model.attributes.group.user.username
            : toView.model.attributes.user.username;
          clientId = toView.model.attributes.group
            ? toView.model.attributes.group.user.afpClientId
            : toView.model.attributes.user.afpClientId;
        }

        if (clientId && document.head) {
          // script tag
          var scriptTag = document.createElement("script");
          scriptTag.setAttribute(
            "src",
            "//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-" +
              clientId +
              "&host=ca-host-" +
              wattpad.afpConfig.hostAccountId
          );
          scriptTag.setAttribute("async", "");
          scriptTag.setAttribute("crossorigin", "anonymous");
          scriptTag.setAttribute("id", "afp-script");
          document.head.appendChild(scriptTag);

          var metaInjector = function(name, content, id) {
            var metaTag = document.createElement("meta");
            metaTag.setAttribute("name", name);
            metaTag.setAttribute("content", content);
            metaTag.setAttribute("id", id);
            document.head.appendChild(metaTag);
          };

          // host meta tag
          //<meta name="google-adsense-platform-account" content="{host-property-code}">
          metaInjector(
            "google-adsense-platform-account",
            "ca-host-" + wattpad.afpConfig.hostAccountId,
            "afp-meta-host"
          );
          // subaccount meta tag
          // <meta name="google-adsense-account" content="{sub-account}">
          metaInjector(
            "google-adsense-account",
            "ca-" + clientId,
            "afp-meta-child"
          );
          // username meta ta
          // <meta name="google-adsense-platform-author" content="{creator-username}">
          metaInjector(
            "google-adsense-platform-author",
            author_username,
            "afp-meta-author"
          );
        } else if (!document.head && clientId) {
          console.warn(
            "head not found, failed to inject AFP meta and script tags"
          );
        }
      }
    })
  );
})(window, Monaco, wattpad, window.app);
