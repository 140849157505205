import beautifyUrl from "core/templates/shared/beautify-url.hbs";
import feedItemMessageOptions from "core/templates/feed-items/feed-item-message-options.hbs";
import { ReportTypes } from "../../../../../components/views/report/ReportModal";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "NewFeedItemMessage",
    app.views.FeedItem.extend({
      reportModalContainer: ".message-report-modal",
      requestType: "inappropriate_comments",
      isMainReportModal: false,
      modalTitle: wattpad.utils.trans("Report a Public Message"),
      conductType: "public_message_conduct",

      events: _.extend(
        {
          //Collapsing Message Replies
          "tap  .on-reply": "onReply",
          "click .on-reply": "stopEvent",

          "tap  .new-reply": "newReply",
          "click .new-reply": "stopEvent",

          "tap  .on-send-event": "sendEventForBeautifyUrl",
          "click .on-send-event": "stopEvent",

          "tap    .send-avatar-event": "sendAvatarClickTrackingEvent",
          "click  .send-avatar-event": "stopEvent",

          "tap    .on-mute": "onMute",
          "click  .on-mute": "stopEvent",

          "tap    .on-unmute": "onUnmute",
          "click  .on-unmute": "stopEvent"
        },
        app.views.FeedItem.prototype.events
      ),

      initialize: function(options) {
        Handlebars.registerPartial(
          "core.feed_items.feed_item_message_options",
          feedItemMessageOptions
        );
        Handlebars.registerPartial("core.shared.beautify_url", beautifyUrl);
        Handlebars.registerPartial(
          "core.feed_items.feed_message_reply",
          function() {
            return "";
          }
        );

        this.template = this.templates.newMessage;
        this.pinnedItem = options.pinnedItem || null;

        var target = this.model.get("username");
        var autoMention = this.model.get("from").name;

        this.newMessageView = new app.views.NewMessageNew({
          to: target,
          parentId: this.model.get("id"),
          placeholder: wattpad.utils.trans("Write a reply..."),
          canBroadcast: false,
          autoMention: autoMention
        });

        this.listenTo(this.newMessageView, "message:new", this.expand);
        this.listenTo(app, "message:scroll", _.debounce(this.scrollToComment));

        // React Report Modal props
        this.reportModalType = "public-message";
        this.reportModalId = {
          name: this.model.get("from").name,
          location: window.location.href,
          body: utils.sanitizeHTML(this.model.get("body"))
        };
      },

      render: function() {
        var data = _.cloneDeep(this.model.toJSON());
        var self = this;

        if (data.type === "message") {
          data.id = data.data.message.id;
        }
        data.currentUser = wattpad.utils.currentUser().toJSON();
        data.numReplies = data.numReplies - data.latestReplies.length;
        data.showTarget = false;
        data.pinnedItem = this.pinnedItem;

        this.getOptionsData(data);

        this.collection = new app.collections.NewMessageReplies(
          data.latestReplies,
          {
            parentId: data.id,
            messageOwnerName: this.model.get("username"),
            limit: 10
          }
        );

        this.repliesView = new app.views.MessageRepliesList({
          collection: this.collection,
          forFeeds: true
        });

        /* Checks the message model body to see if the user has embedded a valid story link -
       * if so, fetch extra metadata to beautify the URL and render the panel.
       */
        var beautifyUrlSettings = this.model.getBeautifyUrlSettings(data);
        if (beautifyUrlSettings && !this.model.get("beautifyUrlMetadata")) {
          self.model
            .getMetadataForBeautifyUrl(beautifyUrlSettings)
            .then(function(result) {
              data.beautifyUrlMetadata = result;
              self.$el.html(self.template(data));
              self.renderLatestReplies();
            });
        }
        this.$el.html(this.template(data));
        this.renderLatestReplies();
        app.trigger("message:scroll");
        return this;
      },

      /* This method determines if the Mute/Unmute <USER> option should appear
      * in the public message menu, and which option should be displayed
      */
      getOptionsData: function(data) {
        // show Mute or Unmute option if user is logged in
        var feedItemAuthor = this.model.get("from").name;
        data.showMuteOption =
          wattpad.utils.currentUser().get("username") &&
          feedItemAuthor !== wattpad.utils.currentUser().get("username");

        data.canMute =
          window.store &&
          window.store.getState().profileData &&
          window.store.getState().profileData.mutedUsers &&
          data.showMuteOption &&
          !window.store
            .getState()
            .profileData.mutedUsers.some(
              user => user.username == feedItemAuthor
            );
        data.feedItemMessageOptionsAuthor = feedItemAuthor;

        data.onDeleteComment = this.onDeleteMessage.bind(this);

        data.canDeleteData = {
          canDelete: false,
          isCommentAuthor: false
        };

        if (this.model.get("username")) {
          var isProfileOwner =
            wattpad.utils.currentUser().get("username") ===
            this.model.get("username");
          var isMessageOwner =
            wattpad.utils.currentUser().get("username") ===
            this.model.get("from").name;
          var isAdmin = wattpad.utils.currentUser().get("isSysAdmin");

          data.canDeleteData = {
            canDelete: isProfileOwner || isMessageOwner || isAdmin,
            isCommentAuthor: isMessageOwner
          };
        }

        data.commentAuthor = this.model.get("from").name;
        data.reportType = ReportTypes.PUBLIC_MESSAGE;
        data.canLink = false;
        data.commentBody = this.model.get("body");

        return;
      },

      scrollToComment: function() {
        var self = this,
          target = window.location.hash,
          notificationId = target.replace("#", "");

        if (notificationId === self.model.get("id").toString()) {
          var commentNode = $("#comment-" + notificationId);

          if (commentNode.length) {
            // Scroll to the comment node
            $("html, body").animate(
              {
                scrollTop: commentNode.offset().top - $("#header").height()
              },
              1000
            );

            // Focus on the reply box for that comment and display the @<reply-to>
            self.newMessageView._setActiveState();
            self.newMessageView.$("textarea").focus();
          }
        }
      },

      sendEventForBeautifyUrl: function(e) {
        var data = $(e.currentTarget)
            .parent(".beautify-url")
            .data(),
          storyId = data.storyId,
          profileOwner = data.profileOwner;

        window.te.push("event", "profile", "message", "story", "click", {
          username: profileOwner,
          storyid: storyId
        });
      },

      renderLatestReplies: function() {
        var self = this;
        this.stopListening(this.newMessageView, "message:new", this.expand);
        this.autoMention = this.model.get("from").name;
        var $target = this.$(".replies-list");

        this.collection.listenTo(
          this.newMessageView,
          "message:new",
          this.collection.add
        );

        this.newMessageView.listenTo(
          this.repliesView,
          "message:mention",
          this.newMessageView.addMention
        );

        if (this.$(".panel-footer").children(".message-new").length === 0) {
          this.$(".panel-footer")
            .empty()
            .append(this.newMessageView.render().$el);
          this.newMessageView.delegateEvents();
        }
        $target.append(this.repliesView.render().$el);
        $target.collapse("show");

        //Remove the view when the box gets hidden
        $target.one("hidden.bs.collapse", function() {
          self.repliesView.remove();
          self.collection.reset(); //kill the collection and the cache
          self.collection.stopListening(self.newMessageView, "message:new");
          app.local.clear(self.collection.resource());
        });

        $target.addClass("no-collapse");
      },

      onReply: function(evt) {
        var target = $(evt.target).data("target"),
          self = this;

        if (this.$(target).hasClass("collapsing")) {
          return;
        } else if (
          this.$(target).hasClass("collapse") &&
          this.$(target).hasClass("in") &&
          !this.$(target).hasClass("no-collapse")
        ) {
          this.listenTo(this.newMessageView, "message:new", this.expand);

          this.$(target).collapse("hide");
          $(evt.target).blur();
        } else {
          this.autoMention = this.model.get("from").name;
          var $target = this.$(target);

          /* Hack to make feed-item-message backwards compatible with new-feed-item-message.
        * Will be adjusted/removed.
        */
          var options = {};
          if (this.collection.models.length) {
            options.replyId = this.collection.models[0].get("id");
          }

          Promise.resolve(this.collection.fetchNextSet(options)).then(function(
            response
          ) {
            self.updateReplyCount(response.replies.length);

            //Remove the view when the box gets hidden
            $target.one("hidden.bs.collapse", function() {
              self.repliesView.remove();
              self.collection.reset(); //kill the this.collection and the cache
              self.collection.stopListening(self.newMessageView, "message:new");
              app.local.clear(self.collection.resource());
            });
          });
        }
      },

      newReply: function(evt) {
        this.$(".panel-footer")
          .empty()
          .append(this.newMessageView.render().$el);
        this.newMessageView.focus();
      },

      //TODO: Fix wrong totals being saved into Monaco when we modify this incremental list
      updateReplyCount: function(count) {
        var $el = this.$el.children(".on-reply");
        var str = $el.text().trim();
        var regexp = new RegExp(/([0-9]*)([M|K]?)(\s)? more (reply|replies)/gi);
        var res = regexp.exec(str);

        //Ignore anything that has been shrunk down by the 'count' function ( M or K abbreviation )
        if (res[2]) {
          return;
        }

        var oldCount = 0;
        if (res[1].trim().length > 0) {
          oldCount = parseInt(res[1], 10);
        }
        var difference = oldCount - count;
        var text = wattpad.utils.sprintf(
          wattpad.utils.ngettext(
            "View %s more reply",
            "View %s more replies",
            difference
          ),
          [wattpad.utils.count(difference)]
        );

        $el.html(text);

        if (difference > 0) {
          this.$el.children(".on-reply").removeClass("hidden");
        }

        if (difference <= 0) {
          this.$el.children(".on-reply").addClass("hidden");
          $(this.$el.children(".on-reply").data("target")).removeClass(
            "no-collapse"
          );
        }
      },

      //This function handles any sort of auto-expansion for any collapse
      //elements associated with it
      expand: function() {
        this.$(
          'button[data-target="#replies-' + this.model.get("id") + '"]'
        ).trigger("tap");
      },

      onDeleteMessage: function(evt) {
        var modelData = this.model.toJSON();
        var message = new app.models.Message({
          id: modelData.id,
          body: modelData.body,
          createDate: modelData.createDate,
          from: {
            name: modelData.from.name,
            avatar: modelData.from.avatar,
            isActive: true
          },
          isReply: false,
          parentId: null,
          ownername:
            this.model.get("ownername") ||
            this.model.get("username") ||
            wattpad.utils.currentUser().get("username")
        });
        message.destroy();
        this.model.collection.remove(this.model);
      },

      reportMoreInfo: function() {
        var self = this;
        return [
          { key: "Message Author", val: this.model.get("from").name },
          { key: "Message Location", val: window.location.href },
          {
            key: "Message Body",
            val: function() {
              return utils.sanitizeHTML(self.model.get("body"));
            }
          }
        ];
      },

      sendAvatarClickTrackingEvent: function(evt) {
        window.te.push("event", "newsfeed", "avatar", "", "click", {
          feed_item_type: this.model.get("type")
        });
      }
    })
  );

  app.mixin(
    app.views.NewFeedItemMessage,
    "InputCounterManagement",
    "ReportManagement",
    "ReportConduct"
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
