(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "Landing",
    app.views.DummyReactView.extend({
      component: "LandingPage",
      componentId: "landing-page",

      constructor: function(opts) {
        this.componentData = {
          locale: wattpad.utils.getCookie("locale"),
          page: opts.page,
          model: opts.model,
          mobile: opts.mobile
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );

  // This lets the events for Facebook & Google auth bind
  app.mixin(app.views.Landing, "FacebookConnect");
})(window, _, wattpad, window.app, wattpad.utils);
