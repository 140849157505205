import recommendedStoryItem from "core/templates/item-views/recommended-story-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RecommendedStoryItem",
    app.views.Base.extend({
      className: "recommended-story",

      template: recommendedStoryItem,

      events: {
        "tap   .btn-dismiss": "onDismiss",
        "click .btn-dismiss": "stopEvent",

        mouseenter: "onShowControls",
        mouseleave: "onHideControls"
      },

      render: function() {
        var self = this;

        this.$el.html(this.template(this.model.toJSON()));
        _.defer(function() {
          self.$(".recommended-story [data-toggle=tooltip]").tooltip();
        });
        return this;
      },

      onAddedToLibrary: function(storyId) {
        var self = this,
          $btn = this.$(
            this.libraryButton + "[data-story-id='" + storyId + "']"
          );
        $btn.text(wattpad.utils.trans("In Library"));
        $btn
          .attr("title", wattpad.utils.trans("Added to Library"))
          .tooltip("fixTitle");

        if (storyId === parseInt(this.model.get("id"), 10)) {
          if (utils.supportsTransition()) {
            this.$el.addClass("faded");
          }
          _.delay(function() {
            if (self.model.collection) {
              self.model.collection.removeAndDismiss(self.model);
            }
          }, 300);
        }
      },

      onRemovedFromLibrary: function(storyId) {
        var $btn = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $btn.text(wattpad.utils.trans("Add"));
        $btn
          .attr("title", wattpad.utils.trans("Add to Library"))
          .tooltip("fixTitle");
      },

      onShowControls: function(evt) {
        this.$(".on-story-hover").fadeIn(150);
      },

      onHideControls: function(evt) {
        this.$(".on-story-hover").fadeOut(150);
      },

      onDismiss: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        if (storyId === parseInt(this.model.get("id"), 10)) {
          if (utils.supportsTransition()) {
            this.$el.addClass("faded");
          }
          if (this.model.collection) {
            this.model.collection.removeAndDismiss(this.model);
          }
        }
      }
    })
  );

  app.mixin(app.views.RecommendedStoryItem, "LibraryManagement");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
