import libraryListsLanding from "core/templates/library/library-lists-landing.hbs";
// This is the content for "Reading Lists" tab in Library Page - refers to the main content underneath library-controls div.

(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "LibraryListsLanding",
    app.views.List.extend({
      template: libraryListsLanding,

      className: "library-lists-landing",

      containerClass: ".reading-list-tab",

      itemView: app.views.LibraryListCard,

      initialize: function() {
        Handlebars.registerPartial(
          "core.library.library_reading_list_item",
          function() {
            return "";
          }
        );

        this.listenTo(this.collection, "sync", this.render);
        this.collection.fetchNextSet();
      },

      getTemplateData: function() {
        return {
          hasLists: this.collection.total > 0
        };
      },

      onDragEnd: function() {
        var $readingList = this.DragAndSort.element;

        utils.cacheBust("ReadingLists", [
          {
            username: utils.currentUser().get("username")
          }
        ]);

        Promise.resolve(
          $.ajax({
            type: "PUT",
            url:
              "/api/v3/users/" +
              utils.currentUser().get("username") +
              "/lists/" +
              $readingList
                .children()
                .first()
                .data("id") +
              "/position",
            data: {
              pos: $readingList.index()
            }
          })
        ).catch(function(err) {
          console.error(err);
        });
      }
    })
  );

  app.mixin(app.views.LibraryListsLanding, "InfiniteScroll", "DragAndSort");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
