import storiesSimilar from "core/templates/collection-views/stories-similar.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "StoriesSimilar",
    app.views.IncrementalList.extend({
      template: storiesSimilar,

      events: {
        "tap .on-story-preview": "onStoryPreview",
        "click .on-story-preview": "stopEvent"
      },

      filter: null,

      className: "stories",

      itemView: app.views.StoryItem,

      initialize: function(options) {
        //Need to massage data to make this work...
        options = options || {};

        if (options.is404) {
          this.itemView = app.views.RecommendedStoryItemCover;
        }

        this.fromStoryReading = options.fromStoryReading || false;
        this.baseStory = options.currentStory || {};
        this.alwaysFetch = options.alwaysFetch || false;

        this.collection =
          options.collection ||
          new app.collections.SimilarStories([], {
            storyId: options.storyId,
            limit: options.limit || 4,
            baseStory: this.baseStory,
            showDescription: options.showDescription || false
          });
      },

      getTemplateData: function() {
        var data = {
          hasStories: this.collection.length > 0
        };

        return data;
      },

      render: function() {
        var self = this;
        Promise.resolve(
          this.collection.fetch({ fresh: this.alwaysFetch })
        ).then(function() {
          app.views.IncrementalList.prototype.render.apply(self, arguments);
          self.trigger("postRender");
        });
        return this;
      },

      onStoryPreview: function(evt) {
        var target = $(evt.currentTarget);
        var storyId = target.data("story-id");
        var story = this.collection.get(storyId),
          baseStoryId = !_.isEmpty(this.baseStory)
            ? this.baseStory.get("group").id
            : null,
          self = this;

        if (this.fromStoryReading) {
          window.te.push(
            "event",
            "reading",
            "similar_stories",
            "story",
            "click",
            {
              current_storyid: baseStoryId,
              storyid: storyId
            }
          );
        }

        if (story) {
          Promise.resolve(story.fetch({ fresh: true })).then(function() {
            var preview = new app.views.BrowseStoryPreview({
              isHome: false,
              model: story
            });

            self
              .$("#preview-modal")
              .empty()
              .append(preview.render().$el);
            preview.openModal();
          });
        }
      }
    })
  );
})(window, _, wattpad, window.app);
