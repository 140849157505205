import genericList from "core/templates/collection-views/generic-list.handlebars";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "List",
    app.views.IncrementalList.extend({
      itemView: {},
      template: genericList,
      className: "list container",
      containerClass: ".collection",

      initialize: function(options) {
        if (options.itemView === undefined) {
          throw new Error("List View: options.itemView cannot be null");
        }

        this.itemView = options.itemView;
        this.className = options.className ? options.className : this.className;
        this.title = options.title ? options.title : "";
        if (options.pageTitle) {
          this._setTitle(options.pageTitle);
        }
      },

      _setTitle: function(newTitle) {
        if (window.document.title !== newTitle) {
          window.document.title = newTitle;
        }
      },

      getTemplateData: function() {
        return { hasItems: this.collection.length > 0, title: this.title };
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
