import browseStoryItem from "core/templates/item-views/browse-story-item.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  /**
   * BrowseStoryItem ItemView - small story card displaying general meta info, cover image & link
   * For Browse specifically; this pops a modal that displays some extra information, as well as provide buttons
   * for Story Landing and Add to Library
   *
   * @Class BrowseStoryItem    View
   * @constructor
   */
  app.add(
    "BrowseStoryItem",
    app.views.StoryItem.extend({
      // Properties
      // ----------
      template: browseStoryItem,
      className: "browse-story-item",

      initialize: function(options) {
        options = options || {};
        this.showStoryRank = options.showStoryRank || false; // show hot list rank for tags
      },

      // Methods
      // -------
      render: function() {
        var data = this.model.toJSON();

        data.rank = this.showStoryRank
          ? this.model.collection.indexOf(this.model) + 1
          : null;
        // Passing down utils/Monaco app data for the react version of helpers during CSR
        data.app = {
          deviceType: wattpad.utils.getDeviceType(),
          devicePixelRatio: window.devicePixelRatio,
          matchMedia: window.matchMedia,
          msMatchMedia: window.msMatchMedia
        };

        this.$el.html(this.template(data));

        if (this.model.get("completed")) {
          this.$el.addClass("completed");
        }

        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
