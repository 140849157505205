var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "    <div class=\"media-wrapper\">\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.media : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"media-wrapper\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "        <div class=\"media-wrapper hidden\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    return "style=\"background-color: #827C78;background-image: url("
    + container.escapeExpression((helpers.resizeCoverAsBackground || (depth0 && depth0.resizeCoverAsBackground) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.bgCover : depth0),1920,{"name":"resizeCoverAsBackground","hash":{},"data":data}))
    + ");\"";
},"10":function(container,depth0,helpers,partials,data) {
    return " hasStoryInfo";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditable : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0, blockParams, depths),"inverse":container.program(15, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.media : depth0),{"name":"each","hash":{},"fn":container.program(17, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditable : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0, blockParams, depths),"inverse":container.program(37, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditable : depth0),{"name":"if","hash":{},"fn":container.program(39, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-left",24,"wp-neutral-2","btn btn-link on-prev-media hidden-xs invisible",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-left",24,"wp-neutral-5","btn btn-link on-prev-media hidden-xs invisible",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"17":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.storyInfo : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.video : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.photo : depth0),{"name":"if","hash":{},"fn":container.program(30, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"18":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "                    <div class=\"media-item story-info"
    + ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(19, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        <img class= \"cover\" src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),140,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3(container.lambda((depths[2] != null ? depths[2].title : depths[2]), depth0))
    + "\"/>\n                        <div class=\"text\">\n                            <p class=\"item-header\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"YOU ARE READING",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                            <h3 class=\"item-title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n                            <a class=\"item-category on-navigate\" href='/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,(depth0 != null ? depth0.categoryEnglish : depth0),{"name":"slugify","hash":{},"data":data}))
    + "'>"
    + alias3(((helper = (helper = helpers.category || (depth0 != null ? depth0.category : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"category","hash":{},"data":data}) : helper)))
    + "</a>\n                            <p class=\"item-description\">"
    + alias3((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),250,false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</p>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].isDesktop : depths[1]),{"name":"if","hash":{},"fn":container.program(21, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depths[1] != null ? depths[1].isDesktop : depths[1]),{"name":"unless","hash":{},"fn":container.program(24, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"19":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"21":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                                <p class=\"item-tags\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? depths[1].group : depths[1])) != null ? stack1.tags : stack1),{"name":"each","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                </p>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                        <span class=\"tag\"><a class=\"on-navigate\" href=\"/stories/"
    + alias2(alias1(depth0, depth0))
    + "\"><s>#</s><strong>"
    + alias2(alias1(depth0, depth0))
    + "</strong></a></span>\n";
},"24":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                            <div class=\"item-tags\">\n                                <div class=\"collection\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depths[1] != null ? depths[1].group : depths[1])) != null ? stack1.tags : stack1),{"name":"each","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                                </div>\n                            </div>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                                            <span class=\"pill\"><a class=\"on-navigate\" href=\"/stories/"
    + alias2(alias1(depth0, depth0))
    + "\">"
    + alias2(alias1(depth0, depth0))
    + "</a></span>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"media-item video on-video "
    + ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-youtube=\""
    + alias4(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "\">\n                        <img src=\"https://img.youtube.com/vi/"
    + alias4(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "/0.jpg\"/>\n                        <div class=\"play\">\n                            <span class=\"fa fa-stack-1x triangle-right\">&#x25b6;</span>\n                        </div>\n                    </div>\n";
},"28":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"30":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                    <div class=\"media-item image "
    + ((stack1 = helpers["if"].call(alias1,(data && data.index),{"name":"if","hash":{},"fn":container.program(28, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + ((stack1 = helpers.unless.call(alias1,(depths[1] != null ? depths[1].isWriterPreview : depths[1]),{"name":"unless","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                        <img data-index=\""
    + alias4(((helper = (helper = helpers.index || (data && data.index)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"index","hash":{},"data":data}) : helper)))
    + "\" src=\""
    + alias4(((helper = (helper = helpers.ref || (depth0 != null ? depth0.ref : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"ref","hash":{},"data":data}) : helper)))
    + "\" alt=\""
    + alias4(container.lambda((depths[2] != null ? depths[2].title : depths[2]), depth0))
    + "\"/>\n"
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].isEditable : depths[1]),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depths[1] != null ? depths[1].isWriterPreview : depths[1]),{"name":"if","hash":{},"fn":container.program(33, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"31":function(container,depth0,helpers,partials,data) {
    return " on-full-size-banner ";
},"33":function(container,depth0,helpers,partials,data) {
    return "                                <span class='banned-overlay hidden'>\n                                    <div id=\"header-banned-image-warning\" class=\"hidden\" contenteditable=\"false\">\n                                        <div class=\"placeholder\"></div>\n                                    </div>\n                                </span>\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-right",24,"wp-neutral-2","btn btn-link on-next-media hidden-xs invisible",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-right",24,"wp-neutral-5","btn btn-link on-next-media hidden-xs invisible",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                    <div class=\"btn-primary btn-icon edit-media on-show-media-menu\">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(alias1,"edit",16,"wp-neutral-2","fa-4x edit-media on-show-media-menu","fill=\"#222222\" stroke=\"#ffffff\"",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    </div>\n\n                    <div class=\"popover bottom media-menu\">\n                        <div class=\"arrow\"></div>\n                        <div class=\"media-menu-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasVideo : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasPhoto : depth0),{"name":"if","hash":{},"fn":container.program(44, data, 0),"inverse":container.program(46, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasVideo : depth0),{"name":"if","hash":{},"fn":container.program(48, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasPhoto : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                        </div>\n                    </div>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "                                <a href=\"#\" class=\"on-replace-video\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Replace video",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"42":function(container,depth0,helpers,partials,data) {
    return "                                <a href=\"#\" class=\"on-add-video\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add YouTube video",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"44":function(container,depth0,helpers,partials,data) {
    return "                                 <a href=\"#\" class=\"on-replace-image\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Replace image",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"46":function(container,depth0,helpers,partials,data) {
    return "                                <a href=\"#\" class=\"on-add-image\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Add image",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"48":function(container,depth0,helpers,partials,data) {
    return "                                <a href=\"#\" class=\"on-remove-video remove-options\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Remove video",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"50":function(container,depth0,helpers,partials,data) {
    return "                              <a href=\"#\" class=\"on-remove-image remove-options\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Remove image",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isEditable : depth0),{"name":"if","hash":{},"fn":container.program(53, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"53":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <div class=\"btn-group media-buttons\" role=\"group\">\n                        <button type=\"button\" class=\"btn btn-white btn-lg btn-writer-media on-add-image\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Insert image",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-image",36,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n                        <button type=\"button\" class=\"btn btn-white btn-lg btn-writer-media on-add-video\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Insert YouTube video",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-video",36,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showTips : depth0),{"name":"if","hash":{},"fn":container.program(54, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                            <div class=\"popover writer-tooltip fade left in\" role=\"tooltip\" id=\"header-media-tooltip\" style=\"top: 59px; left: 322px; display: block;\"><div class=\"arrow\"></div><h3 class=\"popover-title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add header media",{"name":"trans","hash":{},"data":data}))
    + "</h3><div class=\"popover-content\"><div class=\"main-content\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Set the mood for your story by adding multimedia.",{"name":"trans","hash":{},"data":data}))
    + "</div><div class=\"tooltip-footer\"><a href=\"\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Learn More",{"name":"trans","hash":{},"data":data}))
    + "</a><button class=\"btn btn-orange on-okay\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"OK",{"name":"trans","hash":{},"data":data}))
    + "</button></div></div></div>\n";
},"56":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <div class=\"media-fields\">\n                  <div class=\"input-group input-group-lg photo-url hide\">\n                    <input type=\"text\" name=\"photoURL\" class=\"photo-url-field form-control\" value=\""
    + alias4(((helper = (helper = helpers.photoURL || (depth0 != null ? depth0.photoURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"photoURL","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Paste image URL",{"name":"trans","hash":{},"data":data}))
    + "\" />\n                    <span class=\"input-group-btn\">\n                        <button class=\"btn btn-grey on-submit-photo\" type=\"button\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add",{"name":"trans","hash":{},"data":data}))
    + "</button>\n                    </span>\n                  </div>\n                  <div class=\"input-group input-group-lg video-url hide\">\n                    <input type=\"text\" name=\"videoURL\" class=\"video-url-field form-control\" value=\""
    + alias4(((helper = (helper = helpers.videoURL || (depth0 != null ? depth0.videoURL : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoURL","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Paste YouTube URL",{"name":"trans","hash":{},"data":data}))
    + "\" />\n                    <span class=\"input-group-btn\">\n                        <button class=\"btn btn-grey on-submit-video\" type=\"button\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add",{"name":"trans","hash":{},"data":data}))
    + "</button>\n                    </span>\n                    <span class=\"input-group-btn\">\n                        <button class=\"btn btn-grey media-fields-underlay on-cancel\" type=\"button\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n                    </span>\n                  </div>\n                </div>\n";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.media : depth0),{"name":"each","hash":{},"fn":container.program(59, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"59":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <li class=\"on-carousel-clicked "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(data && data.index),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(60, data, 0),"data":data})) != null ? stack1 : "")
    + "\"></li>\n";
},"60":function(container,depth0,helpers,partials,data) {
    return "active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.lambda, alias4=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditable : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.program(3, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "    <div class=\"hover-wrapper\"  "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCover : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <div class=\"background-lg media background"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.storyInfoFlag : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.media : depth0)) != null ? stack1.length : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.program(52, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEditable : depth0),{"name":"if","hash":{},"fn":container.program(56, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            <ol class=\"carousel-indicators\">\n                <li class=\"icon-container\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-flag",12,"wp-neutral-3 on-report-image-banner",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showCarousel : depth0),{"name":"if","hash":{},"fn":container.program(58, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                <li class=\"icon-container\">\n                    <a href=\""
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.media : depth0)) != null ? stack1.facebook : stack1), depth0))
    + "\" class=\"social-share\" id=\"share-header-media-facebook\"\n                       data-gtm-action=\"shareinlinemedia:facebook\" data-share-channel=\"facebook\" target=\"_blank\"\n                       title=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share on Facebook",{"name":"trans","hash":{},"data":data}))
    + "\">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-facebook",12,"wp-neutral-5 on-share-image-banner facebook",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    </a>\n                </li>\n                <li class=\"icon-container\">\n                    <a href=\""
    + alias4(alias3(((stack1 = (depth0 != null ? depth0.media : depth0)) != null ? stack1.pinterest : stack1), depth0))
    + "\" class=\"social-share\" id=\"share-header-media-pinterest\"\n                       data-gtm-action=\"shareinlinemedia:pinterest\" data-share-channel=\"pinterest\" target=\"_blank\"\n                       title=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share on Pinterest",{"name":"trans","hash":{},"data":data}))
    + "\">\n                        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-pinterest",12,"wp-neutral-5 on-share-image-banner pinterest",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    </a>\n                </li>\n            </ol>\n        </div>\n    </div>\n    <div class=\"file-upload hidden\">\n        <input accept=\".jpeg,image/jpeg, image/png, image/gif\" type=\"file\" name=\"file\">\n    </div>\n    <div class=\"media-fields-underlay\"></div>\n</div>\n";
},"useData":true,"useDepths":true});