var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " on-send-reply-event ";
},"3":function(container,depth0,helpers,partials,data) {
    return " on-send-event ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"panel-body beautify-url\" data-story-id=\""
    + alias4(((helper = (helper = helpers.storyId || (depth0 != null ? depth0.storyId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"storyId","hash":{},"data":data}) : helper)))
    + "\" data-originator=\""
    + alias4(((helper = (helper = helpers.originator || (depth0 != null ? depth0.originator : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"originator","hash":{},"data":data}) : helper)))
    + "\">\n    <a href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"panel story on-navigate "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n        <div class=\"cover cover-md pull-left\">\n            <img src=\""
    + alias4((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),123,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n        </div>\n        <div class=\"content\">\n            <h4 class=\"h3\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n            <p>"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),400,{"name":"simpleShorten","hash":{},"data":data}))
    + "</p>\n        </div>\n    </a>\n</div>\n";
},"useData":true});