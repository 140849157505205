(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "PleaseVerifyModal",
    app.views.DummyReactView.extend({
      component: "PleaseVerify",
      componentId: "please-verify",

      constructor: function(opts) {
        this.componentData = {
          email: wattpad.utils.currentUser().attributes.email,
          userId: wattpad.utils.currentUser().get("id"),
          hideModal: this.onClose
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      onClose: function(evt) {
        $("#generic-modal").modal("hide");
        // remove custom class with a delay so that there's no pop-in during
        // jQuery modal close animation
        setTimeout(function() {
          $("#generic-modal .modal-content").removeClass(
            "please-verify-modal-wrapper"
          );
        }, 500);
      }
    })
  );

  app.mixin(app.views.PleaseVerifyModal);
})(window, _, wattpad, window.app, wattpad.utils);
