import { SUBMITTED_FLAG } from "../../../../components/views/contests/ContestForm/constants";

(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:contest_form",
    app.router.filter(["getTranslations"], function(data) {
      var success, storyId;
      if (data) {
        success = data.success;
        storyId = data.storyId;
      }

      let wattysCheckEligibility = Promise.resolve({});
      let isWattysFormSubmitted = success === "true";

      if (!isWattysFormSubmitted && wattpad.testGroups.WATTYS_TAG) {
        const wattysEligibilityUrl = `/v5/contests/wattys/story/${storyId}/eligibility`; // information on story eligibility
        wattysCheckEligibility = Promise.resolve($.get(wattysEligibilityUrl));
      }

      wattysCheckEligibility
        .then(({ eligibility }) => {
          isWattysFormSubmitted = eligibility === SUBMITTED_FLAG;
        })
        .catch(() => ({}))
        .finally(() => {
          // Set Contest From submission state
          window.store.dispatch(
            window.app.components.actions.setContestFormSubmissionStatus(
              isWattysFormSubmitted
            )
          );

          let contestFormView = new app.views.ContestForm({
            componentData: {
              storyId: storyId,
              success: success === "true",
              isStandalonePage: true
            }
          });

          app.transitionTo(contestFormView, {
            hasHeader: false,
            hasFooter: false
          });
        });
    })
  );
})(window, _, jQuery, wattpad, window.app);
