"use strict";
var isServer = require("./is-server");

const getEducationalCategories = () => {
  if (isServer()) {
    const configFilePath = "../server/config";
    const mod = module;
    const config = mod.require(configFilePath);
    return config.harm;
  } else {
    return window?.wattpad?.harm;
  }
};

module.exports = function(term, res) {
  const SPECIAL_CHARS_REGEX = /[ -/|:-@|[-`|{-~]/g;
  const result = [];
  const terms = term.toLowerCase().replace(SPECIAL_CHARS_REGEX, "");

  const educationalCategories = getEducationalCategories();

  for (const category of educationalCategories) {
    const trans = isServer() ? res.i18n.trans : window?.wattpad?.utils.trans;
    if (
      category.words
        .map(str => trans(str).toLowerCase())
        .some(word => terms.includes(word.replace(SPECIAL_CHARS_REGEX, "")))
    ) {
      result.push(trans(category.message[0], ...category.message.slice(1)));
    }
  }

  return result;
};
