var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<section class=\"modal profile-modal fade\" id=\"userTimeout\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"userTimeout\" aria-hidden=\"true\">\n    <div class=\"modal-dialog modal-sm\">\n        <div class=\"modal-content\">\n            <header class=\"modal-header\">\n                <div class=\"pull-right top-right close\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                <h3 class=\"modal-title\" id=\"myModalLabel\">User Time-Out</h3>\n            </header>\n            <div class=\"modal-body\">\n                <p>Duration: 2 days</p>\n                <p>Reason:</p>\n                <textarea class=\"form-control\" role=\"textbox\" aria-multiline=\"true\" id=\"timeout-reason\" name=\"timeout-reason\" rows=\"10\" cols=\"46\"></textarea>\n            </div>\n            <footer class=\"modal-footer\">\n                <button type=\"button\" class=\"btn btn-orange on-timeout-user\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Save",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </footer>\n        </div>\n    </div>\n</section>\n";
},"useData":true});