/**
 * Generates the adUnit as well as any additional classes
 * since they're based on a similar set of variables
 * @param {string} position - where the ad is located i.e. 'top', 'bottom', 'comments'
 * @param {object} context - context passed in whence this was called
 * @param {boolean} isLoggedIn - if the user is logged in
 * @param {boolean} isMobile - if the user is on a mobile device
 */
module.exports = function(position, context, isLoggedIn, isMobile) {
  let attributes = context.source?.attributes
    ? context.source?.attributes
    : context;
  // check some vars
  let contentLength = attributes.length;
  let isMicroPart = attributes.isMicroPart || attributes.pages < 2;

  let adUnitString = `reading${isMicroPart ? "short" : "long"}_` +
    `${position}` +
    `${isLoggedIn ? "" : "_loggedout"}` +
    `${isMobile ? "_mweb" : ""}`; // prettier-ignore

  return {
    adUnit: adUnitString,
    classes: isMicroPart && contentLength > 1500 ? "longer-micro-part" : ""
  };
};
