var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"full-screen background-1\"></div>\n<div class=\"container\">\n  <div class=\"onboardingModal step1 modal fade in\" role=\"dialog\" data-backdrop=\"static\" data-keyboard=\"false\" style=\"display:block;\">\n    <div class=\"modal-dialog\">\n      <form class=\"form-horizontal\" role=\"form\" method=\"POST\">\n        <div class=\"modal-content\">\n          <div class=\"modal-header\">\n            <center>"
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/logos/wp-logo-orange.png","Wattpad",{"name":"connectImage","hash":{"klass":"wp-logo","width":"174"},"data":data}))
    + "</center>\n          </div>\n          <div class=\"modal-body\">\n            <h2 class=\"text-center\" id=\"personal-greeting\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Hi %s",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data}))
    + " </h2>\n            <p class=\"text-center\" id=\"gender-select-copy\"> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"To get the best Wattpad experience, tell us about yourself!",{"name":"trans","hash":{},"data":data}))
    + " <br /> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Your response will be kept private.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n            <div class=\"gender-selector\" id=\"inputGender\">\n              <div class=\"opt male\" value=\"male\">\n                <input id=\"male-icon\" type=\"radio\" name=\"gender-icon\" value=\"male\" />\n                <label class=\"gender-card male-icon\" for=\"male-icon\"></label>\n                <label class=\"gender-text\" for=\"male-icon\" value=\"male\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"He",{"name":"trans","hash":{},"data":data}))
    + "</label>\n              </div>\n              <div class=\"opt female\" value=\"female\">\n                <input id=\"female-icon\" type=\"radio\" name=\"gender-icon\" value=\"female\" />\n                <label class=\"gender-card female-icon\" for=\"female-icon\"></label>\n                <label class=\"gender-text\" for=\"female-icon\" value=\"female\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"She",{"name":"trans","hash":{},"data":data}))
    + "</label>\n              </div>\n              <div class=\"opt other\" value=\"other\">\n                  <input id=\"other-icon\" type=\"radio\" name=\"gender-icon\" value=\"other\" />\n                  <label class=\"gender-card other-icon\" for=\"other-icon\"></label>\n                  <label class=\"gender-text\" for=\"other-icon\" value=\"other\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"They",{"name":"trans","hash":{},"data":data}))
    + "</label>\n              </div>\n          </div>\n          </div>\n          <div class=\"modal-footer\">\n            <center><button class=\"btn btn-orange on-continue continue-button disabled\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Next",{"name":"trans","hash":{},"data":data}))
    + "</button></center>\n          </div>\n        </div>\n      </form>\n    </div>\n  </div>\n</div>";
},"useData":true});