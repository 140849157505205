(function(window, _, wattpad, app) {
  "use strict";

  //This is collection of stories based off the new Story Model
  app.add(
    "StoryGroup",
    app.collections.IncrementalFetch.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "cover",
            "tags",
            "rating",
            "url",
            "id",
            "description",
            "categories",
            "user",
            "lastPublishedPart",
            { parts: "" }
          ]
        },
        "total",
        "nextUrl"
      ],

      resource: "storygroup.collection"
    })
  );
})(window, _, wattpad, window.app);
