import resultsStories from "core/templates/search/partials/results-stories.hbs";
import resultsStoryItem from "core/templates/search/partials/results-story-item.hbs";
import resultsPeople from "core/templates/search/partials/results-people.hbs";
import resultsPersonItem from "core/templates/search/partials/results-person-item.hbs";
import searchLanding from "platform/search/templates/search-landing.hbs";
import userBadges from "core/templates/components/user-badges.hbs";
import searchTabs from "core/templates/search/partials/search-tabs.hbs";
import searchFilters from "core/templates/search/partials/search-filters.hbs";
import storiesList from "core/templates/search/partials/stories-list.hbs";
import searchHeader from "core/templates/search/partials/search-header.hbs";
import blankSearch from "core/templates/search/partials/blank-search.hbs";
import rightRailAd from "core/templates/search/partials/right-rail-ad.hbs";

import {
  getStoryContentEventAttributes,
  getStoryLastUpdatedEventAttributes,
  getStoryLengthEventAttributes,
  getStoryTagsEventAttributes
} from "../../../../helpers/search-events-helper";
import getEducationalBannerMessages from "../../../../helpers/get-educational-banner-messages";
import setPropsForReactInHb from "../../../../helpers/handlebars/set-props-for-react-in-hb";
import getAdData from "../../../../helpers/ads/get-ad-data";

const SEARCH_AD_REFRESH_TIME = 5000;
const SEARCH_AD_PLACEMENT = "search";
const SEARCH_AD_LOGGEDOUT_PLACEMENT = "search_loggedout";

(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "SearchLanding",
    app.views.RegionManager.extend({
      template: searchLanding,
      tabsTemplate: searchTabs,
      filtersTemplate: searchFilters,
      newStoryResultsTemplate: storiesList,
      searchHeaderTemplate: searchHeader,
      blankSearchTemplate: blankSearch,
      rightRailAdTemplate: rightRailAd,

      events: {
        "tap .on-tab-navigate": "onTabNavigation",
        "keyup .on-tab-navigate": "onTabNavigation",
        "click .on-tab-navigate": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};

        this.registerPartials();
        this.initBackboneListeners();

        // Initialize viewed stories
        this.initializeStoryTracking();

        this.isMatureUser =
          wattpad?.utils?.currentUser()?.get("isMature") || false;
        this.collections = {
          stories: new app.collections.NodeStorySearch([], {
            searchTerm: options.query,
            limit: options.limits.stories
          }),
          users: new app.collections.NodeUserSearch([], {
            searchTerm: options.query,
            limit: options.limits.users
          })
        };
        this.adEligibility = options.adEligibility[0];

        // mature search filter check
        this.collections.stories.mature = this.isMatureUser;

        if (options.query.indexOf("user:") === 0) {
          this.collections.stories.mature = true;
        }

        this.query = options.query;
        this.limits = options.limits;
        this.resultType = options.resultType;
        this.browseTopics = options.browseTopics;
        this.lastRefreshAdTime = Date.now();

        if (!app.get("device").is.mobile) {
          this.collections.stories.fetch();
        }

        _.defer(function() {
          app.trigger("navbar:search:disable");
        });
        _.defer(function() {
          app.trigger("navbar:search:set", options.query, false);
        });

        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.searchHeader = new app.views.SearchHeader({
            storyCollection: this.collections.stories,
            userCollection: this.collections.users,
            topicCollection: this.browseTopics,
            searchTerm: this.query
          });
        }
      },
      /**
       * Helper function to setup HBS templates and reduce noise in backbone init func.
       * Should hopefully be removed or reduced to one template eventually
       */
      registerPartials: function() {
        // Search header; also responsible for empty search screen (/search),
        // thats different to empty search results
        if (wattpad.testGroups["NEW_SEARCH_LAYOUT"]) {
          Handlebars.registerPartial(
            "core.search.partials.search_header",
            this.searchHeaderTemplate
          );
        } else {
          Handlebars.registerPartial(
            "core.search.partials.search_header",
            function() {
              return "";
            }
          );
        }

        // Search Results tabs and results containers
        Handlebars.registerPartial(
          "core.search.partials.search_tabs",
          this.tabsTemplate
        );
        Handlebars.registerPartial(
          "core.search.partials.results_people",
          function() {
            return "";
          }
        );
        Handlebars.registerPartial(
          "core.search.partials.results_stories",
          function() {
            return "";
          }
        );

        // Signup component
        Handlebars.registerPartial("core.signup_prompt", function() {
          return "";
        });

        // User badges - can be removed now?
        Handlebars.registerPartial("core.components.user_badges", userBadges);

        // new React stuffs for the new DW filters
        if (!app.get("device").is.mobile) {
          Handlebars.registerPartial(
            "core.search.partials.search_filters",
            this.filtersTemplate
          );

          // new react Story Search results container
          // moves away from StorySearchResults view controller
          // to use react and collection for fetching/state.
          Handlebars.registerPartial(
            "core.search.partials.stories_list",
            this.newStoryResultsTemplate
          );
        }

        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          Handlebars.registerPartial(
            "core.search.partials.blank_search",
            this.blankSearchTemplate
          );
        }
        if (!app.get("device").is.mobile) {
          Handlebars.registerPartial(
            "core.search.partials.right_rail_ad",
            this.rightRailAdTemplate
          );
        }
      },

      initBackboneListeners: function() {
        this.listenTo(app, "navbar:search:query", this.dispatchQuery);

        this.listenTo(
          app,
          "app:component:TagMetaItem:click",
          this.navigateToTagPage
        );
        this.listenTo(
          app,
          "app:component:TagMetaNumNotShown:click",
          this.onStoryDetails
        );

        // Track viewed stories
        this.listenTo(app, "app:component:stories:view", this.addViewedStory);

        // New filter listener handlers
        // Length
        this.listenTo(
          app,
          "app:component:filters:updateLength",
          this.filterSearchResultsByLength
        );
        // Last Updated
        this.listenTo(
          app,
          "app:component:filters:updateAge",
          this.filterSearchResultsByAge
        );
        // Content
        this.listenTo(
          app,
          "app:component:filters:updateContent",
          this.filterSearchResultsByContent
        );
        // Refine by tag
        this.listenTo(
          app,
          "app:component:filters:addTag",
          this.filterSearchResultsByAddedTag
        );

        this.listenTo(
          app,
          "app:component:filters:toggleTag",
          this.filterSearchResultsByTag
        );

        // Clear cache when follow/following button on a ProfileCard is clicked
        this.listenTo(
          app,
          "app:component:search:profile:follow:clearCache",
          this.clearCacheOnProfileCardFollowClick
        );

        // Clear cache when a ProfileCard is clicked
        this.listenTo(
          app,
          "app:component:search:profile:click:clearCache",
          this.clearUserCollectionCache
        );

        // Refresh search ad when trigger is fired
        this.listenTo(
          app,
          "app:component:search:refreshSearchAd",
          this.refreshSearchAd
        );
      },

      navigateToTagPage: function(evt) {
        wattpad.utils.stopEvent(evt);
        var tag = $(evt.currentTarget)
          .text()
          .trim();
        var urlPath = "/stories/";

        app.router.navigate(urlPath + encodeURIComponent(tag), {
          trigger: true
        });
      },

      onStoryDetails: function(evt) {
        wattpad.utils.stopEvent(evt);
        var storyId = $(evt.currentTarget).data("story-id"),
          story = this.collections.stories.get(storyId);
        app.router.navigate(wattpad.utils.formatStoryUrl(story.get("url")), {
          trigger: true
        });
      },

      render: function() {
        this.userSearchResultsView = new app.views.SearchResults({
          collection: this.collections.users,
          itemView: this.itemViewFactory(resultsPersonItem, "Users"),
          template: resultsPeople,
          dataKey: "Users",
          partial: "core.search.partials.results_person_item",
          manualRegistration:
            app.get("device").is.mobile && this.resultType !== "people"
        });

        if (!app.get("device").is.mobile) {
          // carried this across from search-results. It's needed to display stories for
          // delayed scenarios like coming from the empty search / browse topics screen
          // There is scope to improve this probably. With at least a default container
          // then we can evaluate all of the repeat calls this page makes.

          this.collections.stories.once(
            "sync",
            this.updateSearchComponents,
            this
          );

          // before we relied on search-results to call fetch.
          // possibly need to account for scenarios where search term is 0
          // as search-results would just render for those
          this.collections.stories.fetch();
        } else {
          this.storySearchResultsView = new app.views.SearchResults({
            collection: this.collections.stories,
            itemView: this.itemViewFactory(resultsStoryItem, "Stories"),
            template: resultsStories,
            dataKey: "Stories",
            partial: "core.search.partials.results_story_item",
            manualRegistration:
              app.get("device").is.mobile && this.resultType !== "stories"
          });
        }

        wattpad.utils.setCookie(
          "signupFrom",
          "search",
          0,
          window.location.hostname.replace("www", "")
        );

        this.signUpPrompt = new app.views.SignUpPrompt({
          model: new app.models.Authsignup(),
          nextUrl: window.location.href,
          subtitle: utils.trans(
            "Be part of a global community of readers and writers, all connected through the power of story."
          ),
          focus: false // prevents facebook login button from stealing focus
        });

        const templateData = {
          isLogged: utils.currentUser().authenticated(),
          isMobile: app.get("device").is.mobile,
          resultType: this.resultType,
          searchTerm: this.query,
          numStories: this.collections?.stories?.total,
          isMatureUser: this.isMatureUser,
          recentSearches: this.getRecentSearches(true),
          browseTopics: this.browseTopics.toJSON()
        };

        if (!app.get("device").is.mobile) {
          const placement = wattpad.utils.currentUser().authenticated()
            ? SEARCH_AD_PLACEMENT
            : SEARCH_AD_LOGGEDOUT_PLACEMENT;
          const searchAdProps = {
            placement,
            adData: getAdData(
              {
                user: wattpad.utils.currentUser().attributes,
                testGroups: wattpad.testGroups,
                deviceType: wattpad.utils.getDeviceType(),
                userGeo: wattpad.userCountryCode
              },
              placement
            ),
            className: "search-ad",
            refresh: true
          };
          searchAdProps.adData.adEligibility = this.adEligibility;

          setPropsForReactInHb(templateData, "searchAdProps", searchAdProps);
        }

        this.$el.html(this.template(templateData));

        if (!app.get("device").is.mobile) {
          this.updateFiltersContainer(
            this.query,
            this.resultType,
            this.collections?.stories?.total,
            this.collections?.users?.length,
            this.getWrappedTags(this.collections?.stories?.tags?.toJSON()),
            this.selectedOptionsAge,
            this.selectedOptionsLength,
            this.selectedOptionsContent,
            this.isMatureUser
          );
        }

        this.$("#results-people-region").empty();
        this.$("#results-stories").empty();

        // We don't have the auth panel on Mobile Web
        if (
          !utils.currentUser().authenticated() &&
          !app.get("device").is.mobile
        ) {
          this.setRegion("#authentication-panel", this.signUpPrompt.render());
        }

        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.updateSearchHeader(this.query);
        } else {
          this.$("#search-header").empty();
          this.setRegion("#search-header", this.searchHeader.render());
        }

        this.setRegion("#results-people-region", this.userSearchResultsView);

        if (!app.get("device").is.mobile) {
          this.updateStoriesList();
        } else {
          this.setRegion("#results-stories", this.storySearchResultsView);
          this.storySearchResultsView.setPaused(true);
          this.storySearchResultsView.deregisterFromScrolling();
        }

        this.userSearchResultsView.setPaused(true);
        this.userSearchResultsView.deregisterFromScrolling();

        return this;
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);

        this.checkRegion("#search-header");

        this.checkRegion("#authentication-panel");
        this.checkRegion("#results-people-region");
        this.checkRegion("#results-stories");

        if (this.searchHeader && this.searchHeader.$el.is(":visible")) {
          const input = this.searchHeader.$("#search-field");

          if (input.length > 0) {
            const strLength = input.val().length * 2;

            _.defer(function() {
              input.focus();
              input[0].setSelectionRange(strLength, strLength);
            });
          }
        }
      },

      checkRegion: function(idName) {
        if (this.regions[idName] && this.regions[idName].view) {
          this.regions[idName].view.setElement(
            this.$(idName + " > div:first-child")
          );
        }
      },

      unhideSearchResults: function(resultType) {
        if (resultType === "stories") {
          this.$("#results-people").addClass("hidden"); // hide users MW
          this.$("#section-results-people").addClass("hidden"); // hide users DW
          this.$("#section-results-stories").removeClass("hidden"); // show stories
        } else {
          this.$("#section-results-stories").addClass("hidden"); // hide stories
          this.$("#results-people").removeClass("hidden"); // show users MW
          this.$("#section-results-people").removeClass("hidden"); // show users DW
        }
      },

      initializeStoryTracking: function() {
        this.viewedStories = [];
      },

      addViewedStory: function(storyId) {
        this.viewedStories.push(storyId);
      },

      redundantQuery: function(query) {
        if (
          query &&
          (this.collections?.stories?.total === 0 ||
            this.collections?.stories?.total == null)
        ) {
          const storageArray = this.getRecentSearches(false);
          storageArray.shift();
          localStorage.setItem("recentSearches", JSON.stringify(storageArray));
        }
      },

      // Unhides the loading skeleton for stories and profiles
      showLoadingSkeleton: function() {
        if (!app.get("device").is.mobile) {
          $(".story-card-skeleton-container").removeClass("hidden");
          $(".story-card-container").addClass("hidden");

          $(".profile-skeleton-container").removeClass("hidden");
          $("#results-people .list-group").addClass("hidden");
          $("#results-people .load-more").addClass("hidden");
        }
      },

      dispatchQuery: function(query) {
        query = query.trim();

        // No updates need to be made if the search term is not updated
        if (this.query.trim() === query) {
          //only include searches that returned search results for redundant calls
          this.redundantQuery(query);
          return;
        }
        this.query = query;
        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.showLoadingSkeleton();
        }
        this.updateSearch(query);
        this.updateTitle(query);
        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.updateHeader(query);
        }

        utils.scrollToTop();

        //Refresh ad when new search term fires
        this.refreshSearchAd();
      },

      updateTitle: function(query) {
        query = query.trim();

        const title = utils.getSearchTitle(query);

        if (!query || query.length === 0) {
          utils.setTitle(title, false);
        } else {
          utils.setTitle(title);
        }
      },

      handleSearchSubmit: function(searchTerm) {
        app.trigger("navbar:search:set", searchTerm);
      },

      handleSaveSearchTerm: function(searchTerm) {
        app.trigger("navbar:search:save", searchTerm);
      },

      updateSearchHeader: function(query) {
        this.$("#search-header .container").html(
          this.searchHeaderTemplate({
            bannerMessages: getEducationalBannerMessages(query),
            searchTerm: query,
            isMobile: app.get("device").is.mobile,
            handleSearchSubmit: this.handleSearchSubmit,
            handleSaveSearchTerm: this.handleSaveSearchTerm
          })
        );
      },

      updateSearchComponents: function() {
        this.updateStoriesList();
        this.updateFiltersContainer(
          this.query,
          this.resultType,
          this.collections?.stories?.total,
          this.collections?.users?.length,
          this.getWrappedTags(this.collections?.stories?.tags?.toJSON()),
          this.selectedOptionsAge,
          this.selectedOptionsLength,
          this.selectedOptionsContent,
          this.isMatureUser
        );
      },

      updateBlankSearch: function(searchTerm) {
        this.$("#blank-search").html(
          this.blankSearchTemplate({
            searchTerm,
            recentSearches: this.getRecentSearches(true),
            browseTopics: this.browseTopics.toJSON()
          })
        );
      },

      updateFiltersContainer: function(
        query,
        resultType,
        numStories,
        numUsers,
        relatedTags,
        selectedOptionsAge,
        selectedOptionsLength,
        selectedOptionsContent,
        isMatureUser
      ) {
        this.$("#search-filters-container").html(
          this.filtersTemplate({
            searchTerm: query,
            resultType,
            numStories,
            numUsers,
            relatedTags,
            selectedOptionsAge,
            selectedOptionsLength,
            selectedOptionsContent,
            isMatureUser
          })
        );
      },
      updateStoriesList: function() {
        this.$("#results-stories").html(
          this.newStoryResultsTemplate({
            resultType: this.resultType,
            stories: this.collections?.stories?.toJSON(),
            searchTerm: this.query,
            nextUrl: this.collections?.stories?.nextUrl,
            numStories: this.collections?.stories?.total,
            isMobile: wattpad.utils.getDeviceType() === "mobile",
            viewedStories: this.viewedStories
          })
        );
      },

      // update tabs so it has the latest search term
      updateTabs: function(query) {
        this.$("#search-tabs-container").html(
          this.tabsTemplate({
            searchTerm: query,
            resultType: this.resultType
          })
        );
      },

      updateSearch: _.debounce(function(query, isFilterUpdate = false) {
        // TODO: determine if there's any value in aborting these promises.
        // Maybe they can cause a double refresh/render (aborted search and new search)
        // From a promise POV, cancelling isn't really a thing. The network request has
        // already been sent, and we could just ignore the old promise response and listen
        // for the new one in code.
        if (this.xhrs) {
          if (
            this.xhrs?.user !== true &&
            this.xhrs?.user?.state?.() === "pending"
          ) {
            this.xhrs?.user?.abort?.();
          }

          if (
            this.xhrs?.story !== true &&
            this.xhrs?.story?.state?.() === "pending"
          ) {
            this.xhrs?.story?.abort?.();
          }
        }

        var tabPath =
          app.get("device").is.mobile && !_.isEmpty(query.trim())
            ? "/" + this.resultType
            : "";

        app.router.navigate(
          "/search/" + encodeURIComponent(query.trim()) + tabPath,
          { trigger: false, replace: false }
        );

        // Reset viewed stories
        this.initializeStoryTracking();

        if (!isFilterUpdate) {
          this.collections.users = this.userSearchResultsView.newSearch(
            new app.collections.NodeUserSearch([], {
              searchTerm: query,
              limit: this.limits.users
            })
          );
        }

        var newStoriesCollection;

        // removing `search-results` view for stories on new search
        if (!app.get("device").is.mobile) {
          newStoriesCollection = new app.collections.NodeStorySearch([], {
            searchTerm: query,
            limit: this.limits.stories
          });
        } else {
          newStoriesCollection = this.storySearchResultsView.newSearch(
            new app.collections.NodeStorySearch([], {
              searchTerm: query,
              limit: this.limits.stories
            })
          );
        }

        newStoriesCollection.complete = this.collections.stories.complete;
        newStoriesCollection.mature = this.collections.stories.mature;
        newStoriesCollection.hot = this.collections.stories.hot;
        newStoriesCollection.age = this.collections.stories.age;
        newStoriesCollection.minParts = this.collections.stories.minParts;
        newStoriesCollection.maxParts = this.collections.stories.maxParts;
        newStoriesCollection.free = this.collections.stories.free;
        newStoriesCollection.paid = this.collections.stories.paid;

        if (!wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.searchHeader.updateCollection(newStoriesCollection);
        }
        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.updateBlankSearch(query);
        }
        this.updateTabs(query);

        if (query) {
          this.$("main").removeClass("hidden");
          this.unhideSearchResults(this.resultType);
        } else {
          this.$("main").addClass("hidden");
        }

        if (wattpad?.testGroups["NEW_SEARCH_LAYOUT"]) {
          this.updateSearchHeader(query);
        }

        this.collections.stories = newStoriesCollection;

        let fetchUsersPromise = isFilterUpdate
            ? Promise.resolve()
            : this.collections.users.fetch({ fresh: true }),
          fetchStoriesPromise = this.collections.stories.fetch({ fresh: true });

        this.xhrs = {
          user: fetchUsersPromise,
          story: fetchStoriesPromise
        };

        var self = this;
        Promise.all([fetchUsersPromise, fetchStoriesPromise])
          .then(function(responses) {
            var users = responses[0],
              stories = responses[1]; //bug: search something with 0 results, then something with >=1 result. this will take on the value true

            if (!app.get("device").is.mobile) {
              self.updateFiltersContainer(
                query,
                self.resultType,
                newStoriesCollection?.total,
                self.collections?.users?.length,
                self.getWrappedTags(newStoriesCollection?.tags?.toJSON()),
                self.selectedOptionsAge,
                self.selectedOptionsLength,
                self.selectedOptionsContent,
                self.isMatureUser
              );

              self.updateStoriesList();
            }
            //only include searches that returned search results
            if (
              query &&
              (newStoriesCollection?.total === 0 ||
                newStoriesCollection?.total == null)
            ) {
              const storageArray = self.getRecentSearches(false);
              storageArray.shift();
              localStorage.setItem(
                "recentSearches",
                JSON.stringify(storageArray)
              );
            }
          })
          .catch(error => {
            console.error("Error fetching search results", error);
          });
      }, 500),

      //TODO: Remove this function once search-header is migrated.
      updateHeader: function(query) {
        this.searchHeader.updateQuery(query);
      },

      getWrappedTags: function(relatedTags) {
        return { tags: relatedTags };
      },

      // Returns the most recent search terms
      getRecentSearches: function(topThree = true) {
        const recentSearches =
          (localStorage &&
            JSON.parse(localStorage.getItem("recentSearches"))) ||
          [];

        return topThree ? recentSearches.slice(0, 3) : recentSearches;
      },

      // clears the cached user collection to ensure data is in sync
      clearUserCollectionCache: function() {
        this.collections?.users?.clearCache();
      },

      // clears the profile and following cache for a user
      clearUserProfileCache: function(username) {
        app?.local?.clear(`user.${username}`);
        app?.local?.clear(`user.${username}.following`);
        app?.local?.clear(`user.${username}.profile.following`);
      },

      updateFollowStatus: function(username, isFollowing) {
        let followStatus = app?.get("followStatus") || {};
        followStatus[username] = isFollowing;
        app?.set("followStatus", followStatus);
      },

      clearCacheOnProfileCardFollowClick: function(username, isFollowing) {
        const currentUser = utils?.currentUser()?.get("username");

        // Clear cached user collection so that the 'following' field is updated if the
        // current user does a CSR to get back to the search page after clicking the follow
        // button on the Profile page.
        this.clearUserCollectionCache();

        // Set/Update the followStatus, used by FollowManager mixin to ensure the follow
        // button on the Profile page shows the correct state.
        this.updateFollowStatus(username, isFollowing);

        // Clear target and current user's profile data cache to ensure that new updated data is fetched.
        this.clearUserProfileCache(currentUser);
        this.clearUserProfileCache(username);
      },

      itemViewFactory: function(template, dataType) {
        return Monaco.View.extend({
          tagName: "li",
          className: "list-group-item",
          template: template,
          render: function() {
            var templateData = this.model.toJSON();
            // add metadata for click event
            templateData.searchTerm = this.model.collection.searchTerm;
            const id = this.model.cid;
            templateData.index = this.model.collection.models.findIndex(
              model => model.cid === id
            );
            templateData.isMobile = app.get("device").is.mobile;
            if (dataType === "Stories") {
              templateData.tagMeta = {
                tags: templateData.tags,
                storyId: templateData.id,
                numShown:
                  app.get("device") && app.get("device").is.mobile ? 2 : 3
              };
              templateData.reactName = "storyTags-" + templateData.id;
              templateData.sources = this.model.get("sources") || "";
            } else if (dataType === "Users") {
              if (templateData.badges.length !== 0) {
                if (templateData.badges.indexOf("staff") > -1) {
                  templateData.staff = true;
                } else if (templateData.badges.indexOf("verified") > -1) {
                  templateData.verified = true;
                } else if (templateData.badges.indexOf("ambassador") > -1) {
                  templateData.ambassador = true;
                }
              }
            }
            this.$el.html(this.template(templateData));
            return this;
          }
        });
      },

      onTabNavigation: function(evt) {
        // Allow navigation using Enter key
        if (evt.originalEvent instanceof KeyboardEvent && evt.keyCode !== 13) {
          return;
        }
        let $tab = $(evt.currentTarget);

        // We don't need to do anything if active tab is clicked again
        if ($tab.data("target") === this.resultType) {
          return;
        }

        if (typeof __atha !== "undefined") {
          var id = __atha.sendPageView(`search_${$tab.data("target")}`);
        }

        this.resultType = $tab.data("target");

        let link = this.query
          ? "/search/" + encodeURIComponent(this.query)
          : "/search";

        if (this.resultType !== "stories" && this.query) {
          link = link + "/" + this.resultType;
        }

        // update the URL without creating an entry in the browser's history
        app.router.navigate(link, { replace: true });

        // Highlight tab
        this.$("#tabs a").removeClass("active");
        $tab.addClass("active");

        // Hide/show appropriate section
        if (this.resultType === "stories") {
          if (!app.get("device").is.mobile) {
            this.updateFiltersContainer(
              this.query,
              "stories",
              this.collections?.stories?.total,
              this.collections?.users?.length,
              this.getWrappedTags(this.collections?.stories?.tags?.toJSON()),
              this.selectedOptionsAge,
              this.selectedOptionsLength,
              this.selectedOptionsContent,
              this.isMatureUser
            );
          }
        } else {
          if (!app.get("device").is.mobile) {
            this.updateFiltersContainer(
              this.query,
              "users",
              this.collections?.stories?.total,
              this.collections?.users?.length,
              this.getWrappedTags(this.collections?.stories?.tags?.toJSON()),
              this.selectedOptionsAge,
              this.selectedOptionsLength,
              this.selectedOptionsContent,
              this.isMatureUser
            );
          }
        }
        this.unhideSearchResults(this.resultType);

        //refresh search ad
        this.refreshSearchAd();
      },

      pushEvent: function(searchTerm = this.query) {
        const length = getStoryLengthEventAttributes(
          this.selectedOptionsLength
        );
        const updateTime = getStoryLastUpdatedEventAttributes(
          this.selectedOptionsAge
        );
        const content = getStoryContentEventAttributes(
          this.selectedOptionsContent,
          this.isMatureUser
        );
        const sort = "relevance";

        const tags = getStoryTagsEventAttributes(searchTerm);

        window.te.push("event", "search", "filter", null, "apply", {
          search: searchTerm,
          tags,
          Length: length,
          Update_time: updateTime,
          Mature: content.mature,
          Completed: content.complete,
          free: content.free,
          paid: content.paid,
          sort,
          entry_point: "sidebar"
        });
      },

      filterSearchResultsByAge: function(state) {
        this.selectedOptionsAge = state;
        const name = "age";
        let value = undefined;

        if (state.year) {
          value = 24 * 365;
        } else if (state.month) {
          value = 24 * 30;
        } else if (state.week) {
          value = 24 * 7;
        } else if (state.day) {
          value = 24;
        }

        this.collections.stories.setFilter(name, value);
        this.pushEvent();
        this.updateSearch(this.query, true);
      },

      filterSearchResultsByLength: function(state) {
        this.selectedOptionsLength = state;
        const name = "length";
        let minParts = 0;
        let maxParts = 0;

        for (const key in state) {
          if (state[key]) {
            switch (key) {
              case "oneToTen":
                minParts = 1;
                maxParts = Math.max(10, maxParts);
                break;

              case "tenToTwenty":
                minParts = minParts === 0 ? 10 : Math.min(10, minParts);
                maxParts = Math.max(20, maxParts);
                break;

              case "twentyToFifty":
                minParts = minParts === 0 ? 20 : Math.min(20, minParts);
                maxParts = Math.max(50, maxParts);
                break;

              case "moreThanFifty":
                minParts = minParts === 0 ? 50 : minParts;
                maxParts = 0;
                break;

              default:
                break;
            }
          }
        }
        this.collections.stories.setFilter(name, {
          minParts: minParts,
          maxParts: maxParts
        });
        this.pushEvent();
        this.updateSearch(this.query, true);
      },

      filterSearchResultsByAddedTag: function(searchTerm) {
        this.pushEvent(searchTerm);
        // old tag add event. possibly remove based on RJ-4504
        window.te.push("event", "search", "story", "tag", "add", {
          tags: searchTerm.match(/#[\w]+(?=\s|$)/g)
        });

        this.handleSearchSubmit(searchTerm);
      },

      filterSearchResultsByTag: function(event, tag, searchTerm) {
        var $element = $(event.currentTarget);

        if ($element.hasClass("active")) {
          let searchTermWords = searchTerm.split(" ");
          searchTermWords = searchTermWords.filter(
            word => word.trim() && word !== `#${tag}`
          );
          searchTerm = searchTermWords.join(" ").trim();
          this.collections?.stories?.tags?.get(tag).set("active", false);
        } else {
          searchTerm = `${searchTerm.trim()} #${tag}`;
          this.collections?.stories?.tags?.get(tag).set("active", true);
        }

        this.pushEvent(searchTerm);
        // old tag add event. possibly remove based on RJ-4504
        window.te.push("event", "search", "story", "tag", "add", {
          tags: searchTerm.match(/#[\w]+(?=\s|$)/g)
        });

        app.trigger("navbar:search:set", searchTerm);
      },

      filterSearchResultsByContent: function(state) {
        this.selectedOptionsContent = state;
        const mature = "mature";
        const complete = "complete";
        const free = "free";
        const paid = "paid";

        this.collections.stories.setFilter(complete, state.complete); // if only showing completed stories, set the completed filter to true
        this.collections.stories.setFilter(
          mature,
          !this.isMatureUser ? false : !state.mature
        ); // if hiding mature stories, set the mature filter to false
        this.collections.stories.setFilter(free, !state.free); // if hiding free stories, set the free filter to false
        this.collections.stories.setFilter(paid, !state.paid); // if hiding paid stories, set the paid filter to false
        this.pushEvent();
        this.updateSearch(this.query, true);
      },

      refreshSearchAd: function() {
        if (!app.get("device").is.mobile) {
          const currentTime = Date.now();
          if (currentTime - this.lastRefreshAdTime > SEARCH_AD_REFRESH_TIME) {
            this.lastRefreshAdTime = currentTime;
            const selectedAdUnit = wattpad.utils.currentUser().authenticated()
              ? SEARCH_AD_PLACEMENT
              : SEARCH_AD_LOGGEDOUT_PLACEMENT;
            const event = new CustomEvent(`refresh-${selectedAdUnit}`);
            window.dispatchEvent(event);
          }
        }
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
