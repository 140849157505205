import userRowItem from "core/templates/item-views/user-row-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User ItemView - small user card displaying general meta info & follow/unfollow button
   * @Class UserRowItem View
   * @constructor
   */
  app.add(
    "UserRowItem",
    app.views.Base.extend({
      // Properties
      // ----------
      className: "col-xs-12",
      template: userRowItem,

      events: {
        "tap    .on-navigate": "onNavigate",
        "click  .on-navigate": "stopEvent",
        "tap    .on-remove-follower": "onRemoveFollower",
        "click  .on-remove-follower": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};
        this.showRemoveFollowersOption = options.showRemoveFollowersOption;
      },

      render: function() {
        var data = this.model.toJSON(),
          templateData = {},
          metadata = [],
          self = this;

        // Show first two of three metadata
        if (data.numStoriesPublished) {
          metadata.push(
            utils.sprintf(
              utils.ngettext("%s Work", "%s Works", data.numStoriesPublished),
              [utils.count(data.numStoriesPublished)]
            )
          );
        }
        if (data.numLists) {
          metadata.push(
            utils.sprintf(
              utils.ngettext(
                "%s Reading List",
                "%s Reading Lists",
                data.numLists
              ),
              [utils.count(data.numLists)]
            )
          );
        }
        if (metadata.length < 2 && data.numFollowers) {
          metadata.push(
            utils.sprintf(
              utils.ngettext("%s Follower", "%s Followers", data.numFollowers),
              [utils.count(data.numFollowers)]
            )
          );
        }
        templateData.metadata = metadata;
        templateData.showRemoveFollowersOption = this.showRemoveFollowersOption;

        data.disabled = this.model.get("following") === undefined;

        if (data.disabled) {
          Promise.resolve(this.model.determineFollowStatus()).then(function() {
            self.$el.html(
              self.template(_.extend(templateData, self.model.toJSON()))
            );
          });
        }

        this.$el.html(this.template(_.extend(templateData, data)));
        return this;
      },

      // Private profiles can remove followers
      onRemoveFollower: function(e) {
        var self = this,
          username = this.model.get("name") || this.model.get("username"),
          confirmation = utils.trans(
            "Are you sure you want to remove your follower %s?",
            username
          );

        if (confirm(confirmation)) {
          this.model.deleteFollower().then(function() {
            if (self.model.collection) {
              self.model.collection.remove(self.model);
            }
          });
        }
      },

      onNavigate: function() {
        // Users can navigate directly to the follow modal via /followers or /following.
        // We don't want this entry in the browser history.
        app.router.navigate("/user/" + this.model.collection.username, {
          replace: true
        });

        app.router.navigate("/user/" + this.model.get("username"), {
          trigger: true
        });
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
