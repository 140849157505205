var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nextPart : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nextPage : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <a class=\"on-navigate\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.nextPart : depth0)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Continue reading next part",{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <a class=\"on-load-more-page load-more-page next-up grey\" href=\""
    + alias3(((helper = (helper = helpers.nextPage || (depth0 != null ? depth0.nextPage : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"nextPage","hash":{},"data":data}) : helper)))
    + "\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Load More Pages",{"name":"trans","hash":{},"data":data}))
    + "\">\n                "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"color":"wp-neutral-2"},"data":data})) != null ? stack1 : "")
    + "\n                <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Load More Pages",{"name":"trans","hash":{},"data":data}))
    + "...</span>\n            </a>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "        <a href=\""
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.nextPart : depth0)) != null ? stack1.url : stack1), depth0))
    + "\" class=\"on-navigate next-part-link\" aria-label=\""
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"Continue reading next part",{"name":"trans","hash":{},"data":data}))
    + "\">\n          <div class=\"next-up next-part orange"
    + ((stack1 = helpers.unless.call(alias2,(depth0 != null ? depth0.currentPartIsBlocked : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias2,(depth0 != null ? depth0.currentPartIsBlocked : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(15, data, 0),"data":data})) != null ? stack1 : "")
    + "            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-right",16,"wp-neutral-5","next-up-icon",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n          </div>\n        </a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isBonusPart : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    return "                    <span>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"No, thanks. Skip to the next part",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"15":function(container,depth0,helpers,partials,data) {
    return "                <span>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Continue reading next part",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "        <div class=\"next-up next-part orange"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nextPage : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <div class=\"next-up-title\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.completed : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "                <strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.title : stack1), depth0))
    + "</strong>\n"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.completed : stack1),{"name":"unless","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n";
},"18":function(container,depth0,helpers,partials,data) {
    return "                    <div>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Finished Reading",{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    return "                    <div>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"To be Continued...",{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    <div>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Last updated: %s",(helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.modifyDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"24":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nextPart : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.nextPage : depth0),{"name":"unless","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data) {
    return "        </a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.currentPartIsBlocked : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.currentPartIsBlocked : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.nextPart : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.currentPartIsBlocked : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});