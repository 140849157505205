(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  /**
   * Manages the deployment of the Story Administration Panel for Story related Views
   * * note: the `model` provided must be a `StoryModel` or a `StoryPartModel`
   *
   *    HTML Requirements
   *    =================
   *    The rendered view will be appended into given element, but the activation button is separated
   *    from the template (for customization purposes).
   *
   *    - The <element> you supply to initAdminPanel must have the classes "button-group", "relative"
   *    - The <element> must contain a child <button> with the classes "dropdown-toggle", "on-admin-dropdown"
   */
  app.add(
    "StoryAdminManager",
    Monaco.Mixin.create({
      events: {
        "tap    .on-admin-dropdown": "onAdminDropdown",
        "click  .on-admin-dropdown": "stopEvent"
      },

      /**
       * @param   model   { Backbone.Model }  StoryModel || StoryPartModel
       * @param   element { String }          id/class name of the element we want to render the Admin Panel to
       */
      initAdminPanel: function(model, element, noop) {
        if (
          wattpad.utils.currentUser().get("isSysAdmin") ||
          wattpad.utils.currentUser().get("ambassador")
        ) {
          var isStoryPart = model instanceof app.models.StoryPartModel, // assumption: else === StoryModel
            username = isStoryPart
              ? model.get("group").user.username
              : model.get("user").username,
            userModel = new app.models.User({ username: username }),
            userOptions = {
              fresh: true,
              data: {
                fields: userModel._recursiveFields(
                  userModel.fields.concat("age", "country")
                )
              }
            },
            partModel = isStoryPart ? model : null,
            storyModel = isStoryPart
              ? new app.models.StoryModel({
                  id: model.get("group").id,
                  categories: model.get("group").categories,
                  firstPartId: model.get("id"),
                  language: model.get("group").language.id,
                  deleted: model.get("group").deleted
                })
              : model,
            self = this;

          Promise.all([
            userModel.loaded(userOptions),
            $.get(
              "/api/v3/users/" +
                username +
                "/stories?fields=stories(readCount)&limit=200"
            )
          ]).then(function(results) {
            var totalReads = 0;
            if (results[1].stories && results[1].stories.length) {
              _.each(results[1].stories, function(story) {
                totalReads += story.readCount;
              });
            }

            userModel.set("totalReads", totalReads);
            self.adminView = new app.views.StoryAdmin({
              author: userModel,
              story: storyModel,
              part: partModel
            });

            self.$(element).append(self.adminView.render().$el);
          });
        }
        noop();
      },

      onAdminDropdown: function(evt, noop) {
        // TODO: jess is going to implement a generalized way for dropdowns to dismiss when clicked outside
        var $btnGrp = $(evt.currentTarget).parent(".button-group");

        if ($btnGrp.hasClass("open")) {
          $btnGrp.removeClass("open");
        } else {
          $btnGrp.addClass("open");
        }
        noop();
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
