(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Base",
    Monaco.Collection.extend({
      _loaded: null,
      _dependencies: null,

      initialize: function() {
        this._loaded = $.Deferred();

        this.on("sync", this._setResolved, this);
        this.on("error", this._setRejected, this);

        if (this.init) {
          this.init.apply(this, arguments);
        }

        this._bindEvents();
      },

      _bindEvents: function() {
        var self = this;

        if (this.events && typeof this.events === "object") {
          _.forEach(this.events, function(value, key) {
            self.on(key, self[value]);
          });
        }
      },

      resource: function() {
        throw new Error("resource must be implemented to use localCache");
      },

      _setResolved: function() {
        this._loaded.resolve(this);
      },

      _setRejected: function() {
        this._loaded.reject(this);
      },

      dependsOn: function(master, id) {
        this._dependencies = this._dependencies || [];
        this._dependencies.push(master);
      },

      generateFields: function(options) {
        options = options || {};

        if (this.fields && (!options.data || !options.data.fields)) {
          options.data = options.data || {};
          options.data.fields = this._recursiveFields(this.fields);
        }

        return options;
      },

      _recursiveFields: function(fields) {
        var self = this,
          result = "";

        if (typeof fields === "string") {
          return fields;
        } else if (Array.isArray(fields)) {
          result += _.map(fields, function(field) {
            return self._recursiveFields(field);
          }).join(",");
        } else {
          var temp = [];
          _.forIn(fields, function(val, key) {
            temp.push(key + "(" + self._recursiveFields(val) + ")");
          });

          result += temp.join(",");
        }

        return result;
      },

      loaded: function(options) {
        var self = this;
        options = this.generateFields(options);

        if (!this.url || (typeof this.url === "function" && !this.url())) {
          this._loaded.resolve();
        } else if (this._loaded.state() === "pending") {
          if (!this._dependencies) {
            this.fetch(options);
          } else {
            $.when
              .apply(
                $,
                _.map(this._dependencies, function(d) {
                  return d.loaded();
                })
              )
              .done(function() {
                self.fetch(options);
              });
          }
        }

        return this._loaded;
      },

      lookup: function(id) {
        var model = this.get(id),
          deferred = $.Deferred();

        if (model) {
          deferred.resolve(model);
        } else {
          model = new this.model({
            id: id
          });

          model.collection = this;
          this.add(model);

          model.loaded().done(function(model) {
            deferred.resolve(model);
          });
        }

        // Returning a Promise so that only this function can modify
        // the Deferred object
        return deferred.promise();
      },

      ghost: function(id) {
        var ghost = new this.model({
          id: id
        });
        ghost.collection = this;
        this.add(ghost);
        return ghost;
      }
    })
  );
})(window, _, wattpad, window.app);
