var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"rating label label-info\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mature",{"name":"trans","hash":{},"data":data}))
    + "</div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "        <span class=\"status label label-info\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Completed",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <time datetime=\""
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.modifyDate : depth0),{"name":"fromNow","hash":{},"data":data}))
    + "\">\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Updated %s",(helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.modifyDate : depth0),{"name":"fromNow","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n        </time>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "        <pre class=\"description\"> "
    + alias1((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.description : depth0),500,false,{"name":"simpleShorten","hash":{},"data":data}))
    + " </pre>\n        <div class=\"meta tags related\">\n            <span class=\"tag\">"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.categoryObject : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n        </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"on-unarchive-story btn btn-block btn-grey\">\n            "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Unarchive",{"name":"trans","hash":{},"data":data}))
    + "\n        </button>\n\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "        <button type=\"button\" class=\"on-archive-story btn btn-block btn-grey\">\n            "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Archive",{"name":"trans","hash":{},"data":data}))
    + "\n        </button>\n\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal-content\" style=\"background-image: url("
    + alias3((helpers.resizeCoverAsBackground || (depth0 && depth0.resizeCoverAsBackground) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),640,{"name":"resizeCoverAsBackground","hash":{},"data":data}))
    + ");\">\n    <div class=\"modal-header\">\n        <button type=\"button\" class=\"close\" data-dismiss=\"modal\" aria-label=\"Close\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",20,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </button>\n        <h4 class=\"modal-title\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n        <div class=\"username\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mature : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.completed : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "        <div class=\"meta tags\">\n            <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",13,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n            <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",13,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n            <span class=\"tag pull-right\">"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.categoryObject : depth0)) != null ? stack1.name : stack1), depth0))
    + "</span>\n        </div>\n    </div>\n    <div class=\"modal-body\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <button type=\"button\" class=\"on-story-details btn btn-block btn-grey\">\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story Details",{"name":"trans","hash":{},"data":data}))
    + "\n        </button>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.archived : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"modal-footer\">\n        <button type=\"button\" class=\"btn btn-block btn-grey\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Close",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n</div>\n";
},"useData":true});