var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <span class=\"btn btn-default on-open-embed\" role=\"button\" href=\"#\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-code",16,"wp-readinggrey",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Embed",{"name":"trans","hash":{},"data":data}))
    + "\n      </span>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"highlight-tooltip\">\n  <div class=\"highlight-options arrow_box\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.embedQuote : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <span class=\"btn btn-default on-facebook-share\" role=\"button\" href=\"#\">\n      <div class=\"facebook-quote-icon\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-facebook",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n      "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share",{"name":"trans","hash":{},"data":data}))
    + "\n    </span>\n  </div>\n</div>\n";
},"useData":true});