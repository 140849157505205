(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:user_settings",
    app.router.filter(["getTranslations"], function(data) {
      var model = new app.models.UserSettings(wattpad.user);

      Promise.all([model.fetch({ fresh: true }), model.fetchIgnoreList()]).then(
        function() {
          var userSettings = new app.views.UserSettings({
            model: model,
            mutedUsers: model.mutedUsers(),
            errorMessage: data && data.errorMessage
          });

          app.transitionTo(userSettings, {
            hasHeader: true,
            hasFooter: true,
            pageTitle: wattpad.utils.trans("User Settings")
          });
        },
        function() {
          // error handling
          app.router.trigger("route:error-404", "user-settings");
        }
      );
    })
  );
})(window, wattpad, window.app);
