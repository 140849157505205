(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "RecommendedStories",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      username: null,
      cacheLocal: true,
      cacheExpire: 5,

      nextUrl: null,

      fields: [
        {
          stories: [
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "cover",
            "tags",
            "rating",
            "url",
            "id",
            "description",
            "categories",
            "firstPartId",
            "numParts",
            { user: ["name", "avatar"] }
          ]
        },
        "total",
        "nextUrl"
      ],

      initialize: function(collection, options) {
        options = options || {};
        if (!options.username) {
          throw new Error(
            "Recommended Collection: options.username cannot be null"
          );
        }
        this.username = options.username;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      parse: function(response) {
        if (
          response.hasOwnProperty("nextUrl") === true &&
          response.nextUrl !== ""
        ) {
          this.nextUrl = response.nextUrl;
        } else {
          this.nextUrl = false;
        }

        return app.collections.IncrementalFetchNextUrl.prototype.parse.apply(
          this,
          arguments
        );
      },

      revertParseExtras: function(result) {
        result.nextUrl = this.nextUrl;
      },

      removeAndDismiss: function(model) {
        var id = model.get("id"),
          self = this,
          resolvedPromise = Promise.resolve();

        window.te.push("event", "recommended", "stories", null, "dismiss", {
          dismissed_storyid: id
        });

        if (this.length < 5) {
          resolvedPromise = Promise.resolve(this.fetchNextSet());
        }

        resolvedPromise.then(function() {
          self.remove(model);
          self.trigger("update");
        });
      },

      //=== MONACO ===//
      resource: function() {
        return this.username + ".recommended-stories";
      },

      defaultUrl: function() {
        return "/api/v3/users/" + this.username + "/recommended";
      }
    })
  );
})(window, _, wattpad, window.app);
