import onboardingStep1 from "core/templates/onboarding/onboarding-step1.hbs";
(function(window, _, $, wattpad, utils, app, Monaco, Promise) {
  "use strict";

  /**
   * Onboarding - Step 1
   * @Class OnboardingPage1
   * @constructor
   */

  app.add(
    "OnboardingStep1",
    Monaco.View.extend({
      events: {
        "click .on-continue": "onContinue",
        "click .opt": "onSelectOpt"
      },

      template: onboardingStep1,

      className: "onboarding-wrapper",

      render: function() {
        var context = {
          username: wattpad.utils.currentUser().get("username")
        };
        this.$el.html(this.template(context));
        return this;
      },

      onSelectOpt: function(evt) {
        evt.stopPropagation();
        var opt_clicked = evt.target;
        var sel_opt_text;

        // needs refactoring, if (opt_clicked.classList.contains("gender-card")) should work but doesn't
        if (opt_clicked.classList.contains("gender-card") !== -1) {
          sel_opt_text = $(opt_clicked).siblings(".gender-text");
        } else if (opt_clicked.classList.contains("gender-text") !== -1) {
          sel_opt_text = $(opt_clicked);
        } else if (opt_clicked.classList.contains("opt") !== -1) {
          sel_opt_text = $(opt_clicked).children(".gender-text");
        }

        var all_opts = this.$el.find(".gender-text");

        _.forEach(all_opts, function(val) {
          val.classList.remove("selected");
        });

        if (sel_opt_text) {
          sel_opt_text.get(0).classList.add("selected");
        }

        this.$(".continue-button").removeClass("disabled");
      },

      onSkipStep: function(evt) {
        utils.stopEvent(evt);
        var nextUrl = wattpad.utils.getCookie("nextUrl");
        if (nextUrl && nextUrl !== "/home" && nextUrl[0] === "/") {
          window.te.push("event", "onboarding", null, null, "complete");
          app.router.navigate(nextUrl);
        } else {
          app.router.navigate("/start/genres");
        }
      },

      onContinue: function(evt) {
        utils.stopEvent(evt);

        var currentUser = wattpad.utils.currentUser(),
          data;

        //grab value of radio button
        var gender = this.$el.find(".selected").attr("value");

        if (gender) {
          gender = gender.slice(0, 1);
        }

        data = {
          gender: gender
        };
        // TODO: Post data to recommendation service once defined
        Promise.resolve(
          $.ajax({
            url: "/api/v3/users/" + currentUser.get("username"),
            type: "PUT",
            data: data
          })
        ).then(function(data) {
          // Update current user object
          var currentUser = wattpad.utils.currentUser();
          currentUser.set(data);
          var nextUrl = wattpad.utils.getCookie("nextUrl");
          if (nextUrl && nextUrl !== "/home" && nextUrl[0] === "/") {
            window.te.push("event", "onboarding", null, null, "complete");
            app.router.navigate(nextUrl, { trigger: true });
          } else {
            app.router.navigate("/start/genres", { trigger: true });
          }
        });
      }
    })
  );
  app.mixin(app.views.OnboardingStep1);
})(
  window,
  _,
  jQuery,
  wattpad,
  wattpad.utils,
  window.app,
  window.Monaco,
  window.Promise
);
