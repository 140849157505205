import autocomplete from "core/templates/header/autocomplete.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "SearchFieldBase",
    app.views.Base.extend({
      autocompleteTemplate: autocomplete,

      events: {
        "tap    .on-autocomplete-item": "onAutocompleteItem"
      },

      initialize: function(options) {
        this.listenTo(app, "navbar:search:save", this.saveSearchTerm);
      },

      getSearchUrl: function(mode) {
        return "/search/";
      },

      saveSearchTerm: function(searchTerm) {
        try {
          searchTerm = searchTerm.trim();

          if (!_.isEmpty(searchTerm)) {
            this.sendSearchTracking(searchTerm);

            let recentSearches =
              JSON.parse(localStorage.getItem("recentSearches")) || [];
            recentSearches.unshift(searchTerm);
            recentSearches = _.uniq(recentSearches);

            // Store max four recent searches
            localStorage.setItem(
              "recentSearches",
              JSON.stringify(recentSearches.slice(0, 4))
            );
          }
        } catch (e) {
          // User doesn't have localstorage; will always see the popular search terms
        }
      },

      sendSearchTracking: function(searchTerm) {
        window.te.push("event", "search", "story", null, "search", {
          search: searchTerm.trim(),
          tags: searchTerm.match(/#[\w]+(?=\s|$)/g)
        });
      },

      onAutocompleteItem: function(evt) {
        var url = $(evt.currentTarget).attr("href");

        const searchTerm = $(evt.currentTarget).data("target") ?? "";
        if (searchTerm.length > 0) {
          this.saveSearchTerm(searchTerm);
        }

        if (!app.get("device").is.mobile) {
          $(".story-card-skeleton-container").removeClass("hidden");
          $(".story-card-container").addClass("hidden");

          $(".profile-skeleton-container").removeClass("hidden");
          $("#results-people .list-group").addClass("hidden");
          $("#results-people .load-more").addClass("hidden");
        }

        wattpad.utils.pushEvent({
          autocomplete: {
            click: true,
            category: "Nav",
            action: "Autocomplete",
            label: url
              .split("/")
              .slice(1)
              .join(":")
          }
        });

        this.$(this.targetSelector).blur();

        if (!this.enabled) {
          //we interrupt the suggested searches anchor interaction for CSR renders
          wattpad.utils.stopEvent(evt);
          this.saveSearchTerm(searchTerm);
          app.trigger("navbar:search:set", searchTerm);
        }
      }
    })
  );
})(window, _, wattpad, window.app);
