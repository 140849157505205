import themeSettings from "core/templates/profile/theme-settings.hbs";
import privateProfileDisclaimer from "core/templates/profile/private-profile-disclaimer.hbs";
import profilePrivate from "platform/profile/templates/profile-private.hbs";
import profileMain from "platform/profile/templates/profile-main.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Desktop-Web User Profile -- Region Manager for Main Tab/Section
   * Manages views for the user's "about" section, list of works, reading lists & conversations.
   * Also manages a view for posting a message to the user.
   * @Class ProfileMain
   * @constructor
   */
  app.add(
    "ProfileMain",
    app.views.RegionManager.extend({
      // Properties
      // ----------
      className: "user-information row",
      template: {
        public: profileMain,
        private: profilePrivate
      },

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.isPrivateToMe = options.isPrivateToMe;

        this.listenTo(this, "scroll-to-element", this.scrollToElement);

        Handlebars.registerPartial(
          "core.profile.private_profile_disclaimer",
          privateProfileDisclaimer
        );
        Handlebars.registerPartial(
          "core.profile.theme_settings",
          themeSettings
        );
        Handlebars.registerPartial("core.signup_prompt", function() {
          return "";
        });
      },

      render: function() {
        var followerRequested =
            this.model.get("followerRequest") === "req" ||
            this.model.get("followerRequest") === "ign",
          data = _.extend(this.model.toJSON(), {
            followerRequested: followerRequested,
            // Allow React theme settings component to show the subscription modal
            showSubscriptionModal: this.showSubscriptionModal.bind(this),
            isPremium: utils.getCurrentUserAttr("isPremium")
          }),
          template = this.isPrivateToMe
            ? this.template["private"]
            : this.template["public"];

        this.$el.html(template(data));

        if (followerRequested) {
          var requestView = new app.views.ProfileRequest({ model: this.model });
          this.setRegion("#profile-request", requestView.render());
        }
        if (this.isPrivateToMe) {
          return this; // nothing else to see here
        }

        var pinnedItem = new app.models.PinnedMessage({
          username: this.model.get("username")
        });

        // generate subviews & collections
        var maxNumlists = 2;
        var maxNumUserFollowings = 14;

        var collections = {
          lists: new app.collections.ReadingLists([], {
            username: this.model.get("username"),
            limit: maxNumlists,
            nonEmpty: true
          }),
          following: new app.collections.ProfileFollowing([], {
            username: this.model.get("username"),
            limit: maxNumUserFollowings
          }),
          works: new app.collections.ProfileWorks([], {
            username: this.model.get("username"),
            limit: 3
          })
        };
        var views = {
          about: new app.views.ProfileAbout({
            model: this.model,
            following: collections.following
          }),
          lists: new app.views.ProfileLists({
            model: this.model,
            collection: collections.lists,
            manualRegistration: true
          }),
          works: new app.views.ProfileWorks({
            model: this.model,
            collection: collections.works,
            manualRegistration: true
          }),
          quests: new app.views.DummyReactView({
            component: "Quests",
            componentId: "quests",
            componentData: this.model
          }),
          pinnedItem: new app.views.PinnedMessage({ model: pinnedItem })
        };

        //set cookie for signup form
        wattpad.utils.setCookie(
          "signupFrom",
          "user_profile",
          0,
          window.location.hostname.replace("www", "")
        );

        if (!wattpad.utils.currentUser().authenticated()) {
          views.signup = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            subtitle: wattpad.utils.trans(
              "Sign up to follow <strong>@%s</strong>",
              data.username
            ),
            focus: false
          });
          this.setRegion("#authentication-panel", views.signup.render());
        }

        this.setRegion("#profile-lists", views.lists);
        this.setRegion("#profile-works", views.works);

        // inject subviews into regions & render
        // -------------------------------------
        this.setRegion("#profile-about", views.about.render());

        var self = this;
        Promise.resolve(collections.lists.fetchNextSet())
          .then(function() {
            views.lists.render();
          })
          .then(function() {
            if (self.scrollToElementId) {
              _.delay(function() {
                self.trigger("scroll-to-element");
              }, 500);
            }
          });

        Promise.resolve(collections.following.fetchNextSet()).then(function() {
          views.about.render();
        });

        var self = this;
        Promise.resolve(pinnedItem.fetch()).then(function() {
          if (pinnedItem.get("id")) {
            self.$("#profile-pinned-item").empty();
            self.setRegion("#profile-pinned-item", views.pinnedItem);
            views.pinnedItem.render();
          }
        });

        return this;
      },

      showSubscriptionModal: function() {
        if ($("#subscription-modal").length) {
          var subscriptionModal = new app.views.SubscriptionModal({
            model: this.model
          });
          this.$("#subscription-modal")
            .empty()
            .append(subscriptionModal.render().$el);
          subscriptionModal.showModal();
        }
      },

      setElement: function(el) {
        var $el = $(el),
          view,
          selector;
        app.views.RegionManager.prototype.setElement.call(this, el);

        // Attach child views
        for (var region in this.regions) {
          if (this.regions.hasOwnProperty(region)) {
            view = this.regions[region].view;
            selector = view.className.trim().replace(/ /g, ".");
            view.setElement($el.find("." + selector));
          }
        }
      },

      setScrollToElementId: function(elementId, fireTriggerNow) {
        this.scrollToElementId = elementId;
        if (fireTriggerNow) {
          this.trigger("scroll-to-element");
        }
      },

      scrollToElement: function() {
        if (this.scrollToElementId) {
          $("html, body").animate(
            {
              scrollTop:
                this.$("#" + this.scrollToElementId).offset().top -
                $("#header").height()
            },
            500
          );
        }
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
