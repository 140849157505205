(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ProfileFollowers",
    app.collections.IncrementalFetchNextUrl.extend({
      cacheExpire: 5,
      cacheLocal: true,

      arrayKey: "users",
      model: app.models.User,

      username: null,
      usernameErr:
        "A ProfileFollowers collection requires a username string to be passed to the options hash on init",

      fields: [
        {
          users: [
            "username",
            "name",
            "avatar",
            "following",
            "numStoriesPublished",
            "numLists",
            "numFollowers",
            "numFollowing",
            "location",
            "description",
            "backgroundUrl",
            "highlight_colour"
          ]
        },
        "total",
        "nextUrl"
      ],

      defaultUrl: function() {
        return "/api/v3/users/" + this.username + "/followers";
      },

      resource: function() {
        return "user." + this.username + ".profile.followers";
      },

      initialize: function(models, options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error(this.usernameErr);
        }
        this.username = options.username;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      }
    })
  );
})(window, _, wattpad, window.app);
