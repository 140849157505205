(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "RecommendedSearches",
    Monaco.Collection.extend({
      resource: "recommendedSearchTerms",

      url: "/api/v3/search/recommended",

      initialize: function(collection, options) {
        this.currentPosition = 0;
        this.fetch();
      },

      parse: function(response) {
        return response.search_terms;
      },

      getNextTerm: function() {
        var currentTerm = "";
        if (this.length > 0) {
          currentTerm = this.at(this.currentPosition++)
            .keys()
            .toString();
          if (this.currentPosition >= this.length) {
            this.currentPosition = 0;
          }
        }

        return currentTerm;
      },

      getListOfSearchTerms: function() {
        return _.map(this.toJSON(), function(searchTerm) {
          return Object.keys(searchTerm)[0];
        });
      }
    })
  );
})(window, _, wattpad, window.app);
