import beautifyUrl from "core/templates/shared/beautify-url.hbs";
import postToProfileModal from "core/templates/story-reading/post-to-profile-modal.hbs";
(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "PostToProfileModal",
    app.views.Base.extend({
      template: postToProfileModal,

      className: "post-to-profile-modal",

      initialize: function(options) {
        Handlebars.registerPartial("core.shared.beautify_url", beautifyUrl);
        this.groupUrl =
          wattpad.siteRoot + "/story/" + this.model.toJSON().group.id;

        this.newMessageView = new app.views.NewMessageNew({
          to: options.target,
          placeholder: wattpad.utils.trans("Write a message..."),
          canBroadcast: true,
          autoMention: options.target,
          additionalText: "\n\n" + this.groupUrl
        });
        this.listenTo(this.newMessageView, "message:new", this.onSuccess);
      },

      render: function() {
        var data = _.extend(this.model.toJSON().group, {
          username: this.options.target,
          isMobile: app.get("device").is.mobile,
          url: this.groupUrl
        });
        this.$el.html(this.template(data));
        this.$(".post-comment")
          .empty()
          .append(this.newMessageView.render().$el);
        return this;
      },

      onSuccess: function() {
        this.$(".post-comment").remove();
        this.$(".success-message").removeClass("hidden");

        // GA tracking
        wattpad.utils.pushEvent({
          category: "reading",
          action: "share:post-to-profile",
          label: this.model.get("id")
        });
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
