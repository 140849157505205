(function(window, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:storyRanking",
    app.router.filter(["getTranslations"], function(storyId, slug, params) {
      if (isNaN(parseInt(storyId))) {
        app.router.trigger("route:error-404", "story");
        return;
      }

      // WEB-7096 If the params is an object it contains redirectFrom, it means we need to show the toast
      if (
        typeof params === "object" &&
        (params["redirectfrom"] || params["redirectFrom"])
      ) {
        var toast = new app.views.ErrorToast(
          {
            message:
              wattpad.utils.trans(
                "We're sorry! The page you requested no longer exists."
              ) +
              " " +
              wattpad.utils.trans("So we have brought you to a related page.")
          },
          { type: "dismissable" }
        );
        toast.render();
      }

      var model = new app.models.StoryModel({ id: storyId });

      Promise.resolve(model.fetch())
        .then(function() {
          if (_.isEmpty(model.get("tagRankings"))) {
            throw "Story does not have tag rankings";
          }

          var view = new app.views.StoryRanking({ model: model }),
            title =
              model.get("title") +
              (model.get("user") ? " - " + model.get("user").name : "");

          // Vietnamese is special-cased to deal with scrapers with good SEO
          var lang = parseInt(app.get("language"), 10);
          if (lang === 19) {
            title =
              "Đọc Truyện " +
              model.get("title") +
              " - " +
              model.get("user").name;
          }

          app.transitionTo(view, {
            pageTitle: wattpad.utils.unsanitizeHTML(title),
            hasHeader: true,
            hasFooter: true
          });
        })
        .catch(function() {
          app.router.trigger("route:error-404");
        });
    })
  );
})(window, wattpad, window.app);
