import readingListContainer from "core/templates/mixins/reading-list-container.hbs";
(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   *
   *
   *  @mixin ReadingList
   */

  app.add(
    "ReadingList",
    app.mixins.StoryInListsManagement.extend({
      templates: _.extend(
        {},
        app.mixins.StoryInListsManagement.mixinFunctions.templates,
        {
          container: readingListContainer
        }
      ),

      events: function(next) {
        return _.extend(
          {},
          next(),
          app.mixins.StoryInListsManagement.mixinFunctions.events
        );
      },

      onAddToListsReady: function($btn, storyid, next) {
        this.$(".reading-list-dropdown").addClass("open");
        $(window.document).one(
          "click.bs.dropdown.data-api",
          { context: this },
          this.closeReadingListSelector
        );
        next($btn, storyid);
      },

      closeReadingListSelector: function(noop) {
        this.$(".reading-list-dropdown").removeClass("open");
        noop();
      },

      fetchListsData: function($btn, storyId, callback, next) {
        this.$(".reading-list-dropdown").remove();
        $btn.after(this.templates.container({ id: storyId }));
        app.mixins.StoryInListsManagement.mixinFunctions.fetchListsData.apply(
          this,
          arguments
        );
        next($btn, storyId, callback);
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
