import featuredStoryItem from "core/templates/item-views/featured-story-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "FeaturedStoryItem",
    app.views.Base.extend({
      className: "featured-story-item story-item",

      template: featuredStoryItem,

      render: function() {
        this.$el.html(this.template(this.model.toJSON()));

        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
