(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "NewHome",
    app.views.DummyReactView.extend({
      component: "Home",

      componentId: "new-home-landing",

      constructor: function() {
        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
