import transitionGroupAnnouncement from "platform/components/templates/announcements/transition-group-announcement.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "TransitionGroup",
    app.views.Announcement.extend({
      template: transitionGroupAnnouncement,

      state: "default",

      className:
        app.views.Announcement.prototype.className + " transition-group",

      events: _.extend(
        {
          "click  .on-learnmore": "stopEvent",
          "tap    .on-learnmore": "onLearnMore"
        },
        app.views.Announcement.prototype.events
      ),

      getData: function() {
        var data = {};
        data[this.state] = true;
        return data;
      },

      onDismiss: function(el) {
        this.remove();
        wattpad.utils.setCookie("noRe", "1");
      },

      onLearnMore: function(e) {
        wattpad.utils.stopEvent(e);
        this.remove();
        wattpad.utils.setCookie("noRe", "1");

        var language = wattpad.utils.getCookie("locale") || "en_US";

        switch (language) {
          case "en_US":
            window.location =
              "https://support.wattpad.com/hc/en-us/articles/204991780";
            break;
          case "fr_FR":
            window.location =
              "https://support.wattpad.com/hc/en-us/articles/204775074";
            break;
          case "es_ES":
            window.location =
              "https://support.wattpad.com/hc/es/articles/204991780";
            break;
          case "pt_PT":
            window.location =
              "https://support.wattpad.com/hc/en-us/articles/204776024";
            break;
          case "ru_RU":
            window.location =
              "https://support.wattpad.com/hc/en-us/articles/204777014";
            break;
          case "de_DE":
            window.location =
              "https://support.wattpad.com/hc/en-us/articles/204991230";
            break;
          case "tr_TR":
            window.location =
              "https://support.wattpad.com/hc/tr/articles/204991780";
            break;
          default:
            window.location =
              "https://support.wattpad.com/hc/en-us/articles/204991780";
            break;
        }
      }
    })
  );
})(window, _, wattpad, window.app);
