(function(window, _, wattpad, app) {
  "use strict";

  /**
   * Generate Fields Mixin
   * Automatically compiles the fields parameter on the class into a string
   * Requires: fields (array | object | string)
   */
  app.add(
    "GenerateFields",
    Monaco.Mixin.create({
      fetch: function(options, next) {
        options = options || {};

        //Probably using nexturl, so skip it all
        if (_.result(this, "url").indexOf("fields") > -1) {
          return next(options);
        }

        if (_.isArray(this.fields) && this.fields.length === 0) {
          return next(options);
        }

        // Generate fields
        options = this.getFieldsString(options);

        // Ensure that that fields have been specified.
        if (!options.data || !options.data.fields) {
          throw new Error(
            "Fields were not specified for API call:" + _.result(this, "url")
          );
        }

        // We are overriding Monaco.fetch
        // Calling `next` will call the original method so that the fetch actually occurs
        return next(options);
      },

      // Generates the fields string if not supplied.
      getFieldsString: function(options, next) {
        options = options || {};

        //Check that there are fields to generate and that they weren't overridden.
        if (this.fields && (!options.data || !options.data.fields)) {
          options.data = options.data || {}; // Set up the object if required.
          options.data.fields = this.generateFieldsString(this.fields); // Start the recursive process.
        }

        // `next` should be no-op since nothing is being overridden
        next(options);

        return options;
      },

      // Recursive method that builds the fields string.
      // Valid field values can be any combination of: Arrays, Objects, Strings.
      generateFieldsString: function(fields, next) {
        var self = this,
          result = "";

        // Base Case - The field value is a string .
        if (typeof fields === "string") {
          return fields;
        }

        // Fields is an array.
        else if (_.isArray(fields)) {
          // Loop through each array value and call generateFieldsString with it.
          // Once complete, join the individual call results into a single string.
          result += _.map(fields, function(field) {
            return self.generateFieldsString(field);
          }).join(",");
        }

        // Fields is an object.
        else {
          var temp = [];

          // For each key in the object, use the value as child properties for the key.
          _.forIn(fields, function(val, key) {
            temp.push(key + "(" + self.generateFieldsString(val) + ")");
          });

          // Once complete, join the individual call results into a single string.
          result += temp.join(",");
        }

        // `next` should be no-op since nothing is being overridden
        next(result);

        return result;
      }
    })
  );
})(window, _, wattpad, window.app);
