(function(window, _, wattpad, app) {
  "use strict";

  /**
   * CollectionParsing Mixin
   * Default implementation of parse & revertParse for collections.
   */
  app.add(
    "CollectionParsing",
    Monaco.Mixin.create({
      parse: function(response, next) {
        if (!this.arrayKey) {
          return response;
        }
        this.total = response.total;
        this.nextUrl = response.nextUrl;

        // Allow collection to perform additional parsing
        next(response);

        return response[this.arrayKey];
      },

      revertParse: function(next) {
        var result = {};
        if (!this.arrayKey) {
          return this.toJSON();
        }
        result.total = this.total;
        result[this.arrayKey] = this.toJSON();

        // Allow collection to revertParse additional properties
        next(result);

        return result;
      }
    })
  );
})(window, _, wattpad, window.app);
