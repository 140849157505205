var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "sr-only";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<main id=\"getapp\" class=\"container-fluid\">\n  <div class=\"row\">\n      <div class=\"text-container\">\n      <div id=\"text\">\n        <div class=\"h1-container\">\n          <h1>\n            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Take stories with you.",{"name":"trans","hash":{},"data":data}))
    + "\n          </h1>\n        </div>\n\n        <form id=\"send-link\" class=\"form-inline\" method=\"post\">\n          <div class=\"cta-text\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get the app in seconds:",{"name":"trans","hash":{},"data":data}))
    + "</div>\n\n          <div class=\"form-group has-feedback\" data-attr=\"getMobile\">\n            <label class=\"control-label "
    + ((stack1 = (helpers.supportsPlaceholder || (depth0 && depth0.supportsPlaceholder) || alias2).call(alias1,(depth0 != null ? depth0.device : depth0),{"name":"supportsPlaceholder","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" for=\"sendTo\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"enter email",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <input id=\"sendTo\" class=\"form-control input-md enable\" type=\"text\" name=\"sendTo\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"enter email",{"name":"trans","hash":{},"data":data}))
    + "\">\n            "
    + ((stack1 = ((helper = (helper = helpers.validationElements || (depth0 != null ? depth0.validationElements : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"validationElements","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n          </div>\n          <button type=\"submit\" class=\"btn-secondary on-send-app-link\">\n            <span class=\"hidden-if-sent\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Send Link",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            <span class=\"shown-if-sent\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n            <span class=\"shown-if-sending\">"
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0.device : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "</span>\n          </button>\n        </form>\n        <div class=\"row\" id=\"download-row\">\n          <div class=\"col-sm-5 google-play-logo\">\n            <a href=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.appUrl : depth0)) != null ? stack1.android : stack1), depth0))
    + "\" data-gtm=\"Download|getmobile|Android\" target=\"_blank\">\n              "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,(depth0 != null ? depth0.googlePlayImage : depth0),"Google Play",{"name":"connectImage","hash":{"size":"170x70"},"data":data}))
    + "\n            </a>\n          </div>\n          <div class=\"col-sm-5 apple-store-logo\">\n            <a href=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.appUrl : depth0)) != null ? stack1.ios : stack1), depth0))
    + "\" data-gtm=\"Download|getmobile|iOS\" target=\"_blank\">\n              "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"homepages/appstore_dark.png","App Store",{"name":"connectImage","hash":{"klass":"footer-appstore"},"data":data}))
    + "\n            </a>\n          </div>\n          <div class=\"col-sm-5 huawei-appgallery-logo\">\n            <a href=\""
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.appUrl : depth0)) != null ? stack1.huawei : stack1), depth0))
    + "\" data-gtm=\"Download|getmobile|Huawei\" target=\"_blank\">\n              "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"homepages/huawei_appgallery_dark.png","Huawei Appgallery",{"name":"connectImage","hash":{},"data":data}))
    + "\n            </a>\n          </div>\n        \n        </div>\n      </div>\n\n  </div>\n    <div id=\"devices\" class=\"devices\">\n      <div id=\"android-horizontal\"></div>\n      <div id=\"iphonex\"></div>\n    </div>\n  </div>\n</main>\n"
    + ((stack1 = container.invokePartial(partials["core.getmobile.values"],depth0,{"name":"core.getmobile.values","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});