(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "SignupModel",
    Monaco.Mixin.create({
      defaults: {
        username: null,
        email: null,
        password: null,
        month: null,
        day: null,
        year: null
      },

      validationRules: {
        email: [
          { func: "isRequired", msg: utils.trans("Email is required.") },
          {
            func: "isEmail",
            msg: utils.trans("That is not a valid email address.")
          },
          { func: "uniqueEmail", async: true }
        ],

        // same as regular email but without the unique validation, since we
        // expect that the email address provided to reset a forgotten password
        // will already exist
        forgotEmail: [
          { func: "isRequired", msg: utils.trans("Email is required.") },
          {
            func: "isEmail",
            msg: utils.trans("That is not a valid email address.")
          }
        ],

        username: [
          { func: "isRequired", msg: utils.trans("Username is required.") },
          {
            func: "isAlphaNumeric",
            msg: utils.trans(
              "Your username may only contain letters and numbers."
            )
          },
          {
            func: "hasLetter",
            msg: utils.trans("Your username must contain at least one letter.")
          },
          {
            func: "minLength",
            length: 6,
            msg: utils.trans("Your username must be at 6-20 characters long.")
          },
          {
            func: "maxLength",
            length: 20,
            msg: utils.trans("Your username must be at 6-20 characters long.")
          },
          {
            func: "uniqueUsername",
            async: true,
            msg: utils.trans(
              "The username you have chosen is invalid. Please try another name."
            )
          }
        ],

        password: [
          { func: "isRequired", msg: utils.trans("Password is required.") },
          {
            func: "minLength",
            length: 6,
            msg: utils.trans(
              "Your password must be at least 6 characters long."
            )
          },
          {
            func: "maxLength",
            length: 20,
            msg: utils.trans(
              "Your password may be no longer than 20 characters."
            )
          }
        ],

        month: [
          { func: "isRequired", msg: "Month is required." },
          { func: "isValidFullDate", msg: "The date provided is invalid." }
        ],

        day: [
          { func: "isRequired", msg: "Day is required." },
          { func: "isValidFullDate", msg: "The date provided is invalid." }
        ],

        year: [
          { func: "isRequired", msg: "Year is required." },
          { func: "isValidFullDate", msg: "The date provided is invalid." }
        ]
      },

      isValidFullDate: function(value, options) {
        var day = this.changed.day ? value : this.get("day"),
          month = this.changed.month ? value : this.get("month"),
          year = this.changed.year ? value : this.get("year");

        // Skip if any are null, need a full date to do this
        if (day === null || month === null || year === null) {
          return false;
        }

        var fullDate = moment(
          month + "-" + day + "-" + year,
          "MM-DD-YYYY",
          true
        );

        return fullDate.isValid();
      },

      // Async tests
      uniqueEmail: function(value, options, noop) {
        noop();
        return Promise.resolve(
          $.ajax("/api/v3/users/validate", {
            data: { email: value }
          })
        );
      },

      uniqueUsername: function(value, options, noop) {
        noop();
        return Promise.resolve(
          $.ajax("/api/v3/users/validate", {
            data: { username: value }
          })
        );
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
