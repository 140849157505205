var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-lock",14,"fa-mediumgrey",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Private",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<button class=\"btn btn-orange on-newlist\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New Reading List",{"name":"trans","hash":{},"data":data}))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",13,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n\n<div class=\"pull-right page-status\">\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.islist : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n\n\n";
},"useData":true});