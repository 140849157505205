(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:helpDmca",
    app.router.filter(["getTranslations"], function() {
      const view = new app.views.DummyReactView({
        component: "HelpDmcaPage",
        componentId: "help-dmca-page"
      });

      app.transitionTo(view, {
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, wattpad, window.app);
