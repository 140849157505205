(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "User",
    app.collections.Base.extend({
      //=== MONACO ===//
      resource: "users.collection",
      model: app.models.User
    })
  );
})(window, _, wattpad, window.app);
