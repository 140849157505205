(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "MediaSharing",
    Monaco.Mixin.create({
      events: {
        "tap #media-share .share-facebook": "onShareFacebook",
        "click #media-share .share-facebook": "stopEvent",

        "tap #media-share .share-pinterest": "onSharePinterest",
        "click #media-share .share-pinterest": "stopEvent"
      },

      onShareFacebook: function(evt, next) {
        utils.stopEvent(evt);
        wattpad.utils.pushEvent({
          category: "reading",
          action: "shareinlinemedia:facebook"
        });
        var imageUrl = $(evt.currentTarget)
            .parent()
            .parent()
            .parent()
            .find("img")
            .attr("src"),
          url = window.decodeURIComponent(
            $(evt.currentTarget)
              .attr("href")
              .slice(38)
          ),
          title =
            this.model.get("group").title + " - " + this.model.get("title"),
          description = this.model.get("group").description,
          replaceWith = "w=1200&h=600";

        imageUrl = imageUrl.replace(/w=\d+&h=\d+/, replaceWith);

        wattpad.facebook_share_dialog(url, title, description, imageUrl);

        next(evt);
      },

      preparePinterestUrl: function(imageUrl, pinterestUrl, next) {
        var replaceWith = "w=735&h=1102";
        imageUrl = imageUrl.replace(/w=\d+&h=\d+/, replaceWith);
        pinterestUrl = pinterestUrl.replace(
          /media=.*&description/g,
          "media=" + imageUrl + "&description"
        );

        next();
        return pinterestUrl;
      },

      onSharePinterest: function(evt, next) {
        wattpad.utils.pushEvent({
          category: "reading",
          action: "shareinlinemedia:pinterest"
        });

        next(evt);
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
