var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "  <a class=\"on-navigate\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n      <div class=\"cover cover-xs pull-left\">\n          <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),50,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n      </div>\n      <div class=\"content\">\n          <h5 class=\"h6\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h5>\n          <small>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</small>\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.outgoing : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      </div>\n      <div class=\"meta\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n      </div>\n      <button class=\"btn btn-triangle btn-library\" data-icon=\"true\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n          <span class=\"toggle-triangle\"></span>\n          <span class=\"toggle-shadow\"></span>\n      </button>\n  </a>\n";
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "<p>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Dedicated to %s",((stack1 = ((stack1 = (depths[2] != null ? depths[2].data : depths[2])) != null ? stack1.dedicatee : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</p>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<small class=\"reads\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<small class=\"votes\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1), depth0),{"name":"data.story","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true,"useDepths":true});