var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, buffer = 
  "            <a class=\"on-login btn btn-link form-switch\" data-form=\"login\" data-label=\"top-right\" rel=\"nofollow\" href=\"/login";
  stack1 = ((helper = (helper = helpers.nextUrl || (depth0 != null ? depth0.nextUrl : depth0)) != null ? helper : alias2),(options={"name":"nextUrl","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.nextUrl) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Log in",{"name":"trans","hash":{},"data":data}))
    + "\n            </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "?nextUrl="
    + container.escapeExpression(container.lambda(depth0, depth0));
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, buffer = 
  "            <a class=\"btn btn-link\" href=\"/";
  stack1 = ((helper = (helper = helpers.nextUrl || (depth0 != null ? depth0.nextUrl : depth0)) != null ? helper : alias2),(options={"name":"nextUrl","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.nextUrl) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\">\n                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sign up",{"name":"trans","hash":{},"data":data}))
    + "\n            </a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <li class=\"col-xs-4 col-sm-3"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                    <a href='#' data-code=\""
    + alias4(((helper = (helper = helpers.locale || (depth0 != null ? depth0.locale : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"locale","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n                </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return " selected";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-check",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"full-screen\"></div>\n<div id=\"auth-header\" class=\"container clearfix\">\n    <a href=\"home\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/logos/wp-logo-orange.png","Wattpad",{"name":"connectImage","hash":{"klass":"wp-logo","width":123},"data":data}))
    + "\n    </a>\n     \n    <div class=\"buttons-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.signup : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.login : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <div class=\"button-group relative\">\n            <button class=\"btn btn-link dropdown-toggle\" data-toggle=\"dropdown\"\n                    role=\"button\" aria-controls=\"language-options\" aria-expanded=\"false\" aria-label=\"more options\">\n                    "
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.interfaceLang : depth0)) != null ? stack1.name : stack1), depth0))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-down",16,"wp-neutral-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </button>\n            <div class=\"triangle\"></div>\n            <ul id=\"language-options\" class=\"dropdown-menu align-right columns\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.translatedLangs : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </div>\n</div>\n<div class=\"container\">\n    <div class=\"row\">\n        <div class=\"col-sm-6 col-md-7 center-text\">\n            "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/landing/hero-devices.png","devices",{"name":"connectImage","hash":{"id":"phone-image"},"data":data}))
    + "\n        </div>\n        <div class=\"col-sm-6 col-md-5 login-page-form-header\">\n            <main id=\"authentication-panel\" class=\"panel panel-transparent\">\n                <div class=\"login-modal-wrapper-new\">\n"
    + ((stack1 = container.invokePartial(partials["core.authentication.auth_form"],depth0,{"name":"core.authentication.auth_form","data":data,"indent":"                    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "                </div>\n            </main>\n        </div>\n    </div>\n</div>\n\n<div id=\"get-app-modal-container\">\n"
    + ((stack1 = container.invokePartial(partials["core.components.get_app"],depth0,{"name":"core.components.get_app","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"WPModal","signup-modal",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});