(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:catalog",
    app.router.filter(["getTranslations"], function(catalog) {
      const isPremium = wattpad.utils.currentUser().get("isPremium");
      const allowedCatalogs = ["wattpadoriginals", "premiumpicks"];

      // WEBSERVICE-1364: Paid Repackaging
      if (catalog === "paidstories") {
        return wattpad.utils.redirectToServer("/catalog/wattpadoriginals");
      }

      if (!catalog || allowedCatalogs.indexOf(catalog) === -1) {
        wattpad.utils.redirectToServer("/home");
      }

      var catalogView = new app.views.DummyReactView({
        component: "Catalog",
        componentId: "catalog",
        componentData: {
          catalog: catalog,
          isMobile: wattpad.utils.getDeviceType() === "mobile",
          isPremium
        }
      });

      app.transitionTo(catalogView, {
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
