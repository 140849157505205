(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "CoreBrowseStories",
    app.views.RegionManager.extend({
      filter: "hot", //default
      category: null,
      tag: "",

      id: "browse-stories-page",

      events: {
        "tap    .on-tab-nav": "onSectionNavigate",
        "click  .on-tab-nav": "stopEvent",
        "tap .on-filter-completed": "onShowCompleted",
        "click .on-filter-completed": "stopEvent"
      },

      initialize: function(options) {
        Handlebars.registerPartial(
          "core.collection_views.browse_stories",
          function() {
            return "";
          }
        );
        Handlebars.registerPartial("core.signup_prompt", function() {
          return "";
        });
      },

      setTabContent: function() {
        var view = new app.views.BrowseStories({
          filter: this.filter,
          category: this.category,
          tag: this.tag
        });
        this.$("#tab-content").empty();
        this.clearRegion("#tab-content");
        this.setRegion("#tab-content", view.render());
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);
        if (this.regions["#tab-content"] && this.regions["#tab-content"].view) {
          this.regions["#tab-content"].view.setElement(
            this.$("#tab-content > div")
          );
        }
        if (
          this.regions["#authentication-panel"] &&
          this.regions["#authentication-panel"].view
        ) {
          this.regions["#authentication-panel"].view.setElement(
            this.$("#authentication-panel > div")
          );
        }
      },

      onShowCompleted: function(evt) {
        //Turning it off
        var $el = $(evt.currentTarget);
        if ($el.hasClass("active")) {
          var onIcon = wattpad.utils.iconify("fa-check", 16, "wp-base-2-dark");
          $el.removeClass("active");
          $el.children(".fa").replaceWith(offIcon);
          $el
            .children(".show-completed-text")
            .removeClass("fa-wp-base-2")
            .addClass("fa-wp-neutral-2");
          this.$el.removeClass("completed-only");
        } else {
          var offIcon = wattpad.utils.iconify(
            "fa-circle-empty",
            16,
            "wp-neutral-3"
          );
          $el.addClass("active");
          this.$el.addClass("completed-only");
          $el.children(".fa").replaceWith(onIcon);
          $el
            .children(".show-completed-text")
            .removeClass("fa-wp-neutral-2")
            .addClass("fa-wp-base-2");
        }
      },

      // Event Handlers
      onSectionNavigate: function(e) {
        wattpad.utils.stopEvent(e);
        var $target = $(e.currentTarget),
          section = $target.parent().data("section");
        this.setSection(section);
      },

      setSection: function(newSection) {
        var $pageNav = this.$("#filter-tabs");
        $pageNav.find("nav li").removeClass("active");
        $pageNav
          .find("nav li[data-section=" + newSection + "]")
          .addClass("active");
        var newUrl = window.location.href.replace(/\/?(hot|new|featured)$/, "");

        if (newSection !== "hot") {
          newUrl += "/" + newSection;
        }

        app.router.navigate(utils.formatStoryUrl(newUrl), { trigger: false });
        this.filter = newSection;
        this.setTabContent();
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
