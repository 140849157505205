import profileFollowRequestItem from "core/templates/profile/profile-follow-request-item.hbs";
import followRequestItem from "core/templates/notifications/items/follow-request-item.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "FollowRequestItem",
    app.views.Base.extend({
      className: "follow-request-item",

      template: {
        notification: followRequestItem,
        profile: profileFollowRequestItem
      },

      events: {
        "tap   .on-approve": "onApprove",
        "click .on-approve": "stopEvent",
        "tap   .on-ignore": "onIgnore",
        "click .on-ignore": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};
        this.inProfile = !!options.inProfile;
        this.ignored = !!options.ignored;
      },

      render: function() {
        var template = this.inProfile
            ? this.template.profile
            : this.template.notification,
          data = this.inProfile
            ? _.extend(this.model.toJSON(), { followerIgnored: this.ignored })
            : this.model.toJSON();

        this.$el.html(template(data));

        return this;
      },

      onApprove: function(evt) {
        var self = this;
        this.model.acceptFollowRequest().then(function() {
          if (self.model.collection) {
            self.model.collection.remove(self.model);
          }
        });
      },

      onIgnore: function(evt) {
        var self = this;
        this.model.ignoreFollowRequest().then(function() {
          if (self.model.collection) {
            self.model.collection.remove(self.model);
          }
        });
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
