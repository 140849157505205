import error404 from "core/templates/error/error-404.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "Error404",
    app.views.RegionManager.extend({
      id: "error-404",

      template: error404,

      initialize: function(options) {
        options = options || {};
        this.type = options.type;
        this.modelId = options.modelId;
        this.browseTopics = options.browseTopics;

        Handlebars.registerPartial("core.header.search_field", function() {
          return "";
        });

        this.searchFieldView = new app.views.SearchField();
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);

        var regionTag = "#error-404-stories";
        if (this.regions[regionTag] && this.regions[regionTag].view) {
          if (this.$(regionTag).length > 0) {
            this.regions[regionTag].view.setElement(
              $(this.$(regionTag + " > div"))
            );
          }
        }
      },

      render: function() {
        var title,
          data = {},
          backgrounds = JSON.parse(wattpad.tagBgColours),
          colours = JSON.parse(wattpad.tagTextColours);

        title = wattpad.utils.trans("Page not found");
        console.log("type:" + this.type);
        data.heading = wattpad.utils.trans("This page seems to be missing...");
        switch (this.type) {
          case "story":
            title = wattpad.utils.trans("Story not found");
            data.subHeading = wattpad.utils.trans(
              "The story has either been deleted or removed."
            );
            break;
          case "user":
            title = wattpad.utils.trans("User not found");
            data.subHeading = wattpad.utils.trans(
              "We couldn't find the profile you were looking for."
            );
            break;
          default:
            title = wattpad.utils.trans("Page not found");
            data.subHeading = wattpad.utils.trans(
              "We couldn't find what you were looking for."
            );
            break;
        }
        data.subHeading =
          data.subHeading +
          " " +
          wattpad.utils.trans(
            "You could try searching for something else you love!"
          );

        data.browseTopics = _.map(this.browseTopics.toJSON(), function(
          topic,
          index
        ) {
          var i = index % colours.length;
          return {
            name: topic.name,
            topic: topic.topic,
            background: backgrounds[i],
            color: colours[i],
            browseURL: topic.browseURL
          };
        });

        wattpad.utils.setTitle(title);

        this.$el.html(this.template(data));

        if (this.searchFieldView) {
          this.$("#header-item-search").html(this.searchFieldView.render().$el);
        }
      },

      getCategories: function() {
        var categories = app.get("categories")
          ? app.get("categories").toJSON()
          : [];
        return _.sortBy(categories, "name");
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
