var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<header>"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-pin",22,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Last Message",{"name":"trans","hash":{},"data":data}))
    + "</header>\n\n<div class=\"panel panel-default2\">\n  <a href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm3 pull-left on-navigate\">\n    "
    + alias3((helpers.connectAvatar || (depth0 && depth0.connectAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.avatar : stack1),((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1),32,{"name":"connectAvatar","hash":{},"data":data}))
    + "\n  </a>\n  <a class=\"username on-navigate\" href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a>\n\n  <span class=\"latest-msg-date\">"
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{},"data":data}))
    + "</span>\n\n  <pre>"
    + ((stack1 = (helpers.preFormat || (depth0 && depth0.preFormat) || alias2).call(alias1,(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.body : depth0),200,true,{"name":"simpleShorten","hash":{},"data":data}),true,{"name":"preFormat","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n\n  <a class=\"btn btn-grey on-navigate on-view-conversations\" href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "/conversations\">\n    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View all Conversations",{"name":"trans","hash":{},"data":data}))
    + "\n  </a>\n</div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<div class=\"panel\">\n  <hr />\n\n  <div class=\"section-header\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Last Message",{"name":"trans","hash":{},"data":data}))
    + "</div>\n\n  <div class=\"user-info\">\n    <a href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm3 pull-left on-navigate\">\n      "
    + alias3((helpers.connectAvatar || (depth0 && depth0.connectAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.avatar : stack1),((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1),32,{"name":"connectAvatar","hash":{},"data":data}))
    + "\n    </a>\n    <a class=\"username on-navigate\" href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a>\n\n    <span class=\"latest-msg-date\">"
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{},"data":data}))
    + "</span>\n  </div>\n\n  <pre>"
    + ((stack1 = (helpers.preFormat || (depth0 && depth0.preFormat) || alias2).call(alias1,(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.body : depth0),200,true,{"name":"simpleShorten","hash":{},"data":data}),true,{"name":"preFormat","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n\n  <button class=\"btn btn-grey btn-block btn-left-align on-navigate on-view-conversations\" href=\"/user/"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "/conversations\">\n    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View all Conversations",{"name":"trans","hash":{},"data":data}))
    + "\n  </button>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});