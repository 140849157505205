var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),(depth0 != null ? depth0.nameMaxLength : depth0),false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "");
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),(depth0 != null ? depth0.nameMaxLength : depth0),false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"writer-avatar\">\n  <a class=\"on-navigate\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    <img src=\""
    + alias4(((helper = (helper = helpers.avatar || (depth0 != null ? depth0.avatar : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"avatar","hash":{},"data":data}) : helper)))
    + "\">\n  </a>\n</div>\n<div class=\"writer-info\">\n  <div class=\"writer-name\">\n    <a class=\"on-navigate\" href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n      "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\n    </a>\n  </div>\n\n  <div class=\"writer-meta\">\n    <span class=\"works\">"
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Work","%s Works",(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</span>\n    <span class=\"reading-lists\">"
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Reading List","%s Reading Lists",(depth0 != null ? depth0.numLists : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numLists : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</span>\n    <span class=\"followers\">"
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Follower","%s Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "</span>\n  </div>\n\n  <div class=\"writer-description\">\n    "
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),200,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});