var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a class=\"on-navigate\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/conversations\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View on your profile",{"name":"trans","hash":{},"data":data}))
    + "</a>\n        ";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a class=\"on-navigate\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/conversations\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View on your profile",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal-dialog\" id=\"generic-modal\">\n  <div class=\"modal-content\">\n    <header class=\"modal-header\">\n      <h2 class=\"modal-title text-center\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Post to Your Profile",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n      <div class=\"close\" data-dismiss=\"modal\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </header>\n    <div class=\"modal-body clearfix\">\n      <div class=\"post-comment\"></div>\n\n      <div class=\"success-message text-center hidden\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",12,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Your message has been posted!",{"name":"trans","hash":{},"data":data}))
    + "\n\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n\n      <div class=\"feed-item-new panel\">\n"
    + ((stack1 = container.invokePartial(partials["core.shared.beautify_url"],depth0,{"name":"core.shared.beautify_url","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n</div>\n";
},"usePartial":true,"useData":true});