(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverModuleStoryItem",
    app.models.StoryModel.extend({
      defaults: {
        id: null,
        title: null,
        cover: null,
        voteCount: null,
        readCount: null,
        url: null
      }
    })
  );
})(window, _, wattpad, window.app);
