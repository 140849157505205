import libraryManagementList from "core/templates/mixins/library-management-list.handlebars";
(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * This mixin allows users to add/remove stories to their Library/Reading
   * lists, as well as potentially add NEW reading lists
   *
   * Requires a few elements on the page (one of each per storyID please):
   *
   * 1) .btn-story-lists[ data-story-id={{ storyId }} ]
   *    - The element to 'activate' on (ie. click for dropdown of lists )
   *
   * 2) ul.lists-menu[ data-story-id= {{ storyId }} ]
   *    - The element that the reading list / library options will be injected
   *    into
   *
   * 3) input.list-name && .on-create-list ( optional )
   *    - The NEW reading list name && the element to activate 'Add' on
   *
   * Rules:
   *
   * 1) If there is an SSR load, it's up to you to determine what the initial state should be
   *
   * The <li> elements generated, when clicked, will toggle between 'add' /
   * 'remove' states for the story with the ID that is marked by its parent
   * ul's data-story-id.
   *
   * Similarly, all the <li> elements injected into the <ul> will be reflective
   * of the state based off of .btn-story-lists's data-story-id.
   *
   * How this works:
   *
   * 1) [ onAddClicked ]
   * On .btn-story-lists click, a call is made for
   *    a) the current user's reading lists
   *    b) a hash that specifies whether the story, marked by .btn-story-lists's
   *    data-story-id, is in the user's library/archive/reading lists
   *
   * 2) [ fetchListsData ]
   * The library / reading list items are injected into the ul with
   * data-story-id equal to .btn-story-lists's data-story-id
   *
   * NOTE: function onAddToListsReady( storyId )
   * If the host view declares this function, it will be called after
   * fetchListsData is done its rendering (and it is ready for display).
   * This allows us to open dropdowns or modals only when the data is ready.
   *
   * 3) [ onModifyList ]
   * On click, the listID is pulled out of the <li> and the decision to
   * add/remove is based off of looking up the hash fetched in 1b;
   *
   *    a) If listID === 0 -> add/remove out of library
   *    b) If listID > 0 -> add/remove from respective ReadingList pulled out
   *    of the fetch in 1a
   *
   *    Note: All these operations are done on EMPTY collections (we didn't
   *    fetch for them) because the operations made $.ajax calls. This is for
   *    performance reasons, don't change it =)
   *
   * 4) [ onListModified ]
   * Once the Ajax call is successful, events are fired to update the state
   * of the list item in the reading list, and subsequent collections/models
   * are also updated to reflect this state
   *
   *
   * IF ADDING NEW LISTS
   *
   * 1) [ onCreateList ]
   * The list name is pulled out of the input and a call is made to create the
   * list. The story which the input box is assigned to is also added
   * automatically to the new list. There is also a one-time event assigned that
   * will fire onListCreated when the list has synced with the server successfully.
   * If an error occurs, an alert box pops up at the moment with the message delivered
   * from the server.
   *
   * 2) [ onListCreated ]
   * When the list has been successfully created, it will add the events to
   * listen to on the new item, add it to the end of the ul where the storyID
   * matches, and clear cache.
   *
   *
   *  @mixin StoryInListsManagement
   */

  app.add(
    "StoryInListsManagement",
    app.mixins.AuthPromptManagement.extend({
      events: {
        "tap .on-library-login": "doAuthPrompt",
        "click .on-library-login": "stopEvent",

        "tap .on-modify-list": "onModifyList",
        "click .on-modify-list": "stopEvent",

        "tap .on-lists-add-clicked": "onAddClicked",
        "click .on-lists-add-clicked": "stopEvent",

        "tap .on-create-list": "onCreateList",
        "click .on-create-list": "stopEvent",

        "focus .list-name": "clearError"
      },

      templates: { list: libraryManagementList },

      readingLists: null,

      storyInLists: null,

      listsContainer: "ul.lists-menu",

      addButton: ".btn-story-lists",

      setElement: function(el, next) {
        next(el);
        if (this.$(this.addButton).length > 0) {
          this.setupDOM();
        }
      },

      render: function(next) {
        var result = next();
        this.setupDOM();
        return result;
      },

      setupDOM: function(next) {
        var currentUser = wattpad.utils.currentUser();

        this.$(this.addButton).prop("disabled", true);

        if (currentUser.authenticated()) {
          this.renderAddButtons();
        } else {
          this.$(this.addButton).prop("disabled", false);
          this.setLoginState();
        }

        next();
      },

      setLoginState: function(next) {
        this.$(this.addButton).addClass("on-library-login");
        next();
      },

      renderAddButtons: function(next) {
        this.$(this.addButton)
          .addClass("on-lists-add-clicked")
          .prop("disabled", false);
        next();
      },

      onAddClicked: function(e, next) {
        //One more chance to modify things before calling fetch
        next(e);
        var storyId = $(e.currentTarget).data("story-id");
        var $btn = $(e.currentTarget);
        this.fetchListsData($btn, storyId, this.onAddToListsReady);
      },

      fetchListsData: function($btn, storyId, callback, next) {
        var currentUser = wattpad.utils.currentUser(),
          $list = this.$(
            this.listsContainer + "[data-story-id='" + storyId + "']"
          ),
          data,
          self = this;

        this.readingLists = new app.collections.ReadingLists(null, {
          username: currentUser.get("username"),
          limit: 200 // Effectively fetch all reading lists; platform default is 10
        });

        this.storyInLists = new app.models.StoryInLists({ storyId: storyId });

        Promise.all([
          this.readingLists.fetchNextSet(),
          this.storyInLists.fetch()
        ]).then(function() {
          $list.empty();

          data = {
            id: 0,
            name: wattpad.utils.trans("My Library (Private)"),
            inList: self.storyInLists.get(storyId).library || false,
            "user-device": app.get("device"),
            icon: "fa-library"
          };

          $list.append(self.templates.list(data));

          //Bind events for the library
          self.listenTo(currentUser.library(), "added", function(model) {
            self.onListModified(true, 0, model.get("id"));
          });

          self.listenTo(currentUser.library(), "removed", function(storyId) {
            self.onListModified(false, 0, storyId);
          });

          _.each(
            self.readingLists.models,
            function(readingList) {
              data = {
                id: readingList.get("id"),
                name: readingList.get("name"),
                inList:
                  self.storyInLists.get(storyId)[readingList.get("id")] ||
                  false,
                "user-device": app.get("device"),
                icon: "fa-reading-list"
              };

              $list.append(this.templates.list(data));

              //Bind events on each reading list
              this.listenTo(readingList, "list:added", function(
                listId,
                storyId
              ) {
                self.onListModified(true, listId, storyId);
              });
              this.listenTo(readingList, "list:removed", function(
                listId,
                storyId
              ) {
                self.onListModified(false, listId, storyId);
              });
            },
            self
          );

          self.checkListScrollable($list);

          if (callback) {
            callback.call(self, $btn, storyId);
          }
        });

        next($btn, storyId, callback);
      },

      onModifyList: function(e, next) {
        var currentUser = utils.currentUser(),
          listId = $(e.currentTarget).data("list-id"),
          storyId = $(e.currentTarget)
            .parent()
            .parent()
            .data("story-id"),
          story = new app.models.StoryModel({
            id: storyId
          }),
          readingList,
          storyCollections = this.storyInLists.get(storyId);

        if (listId === 0) {
          //Library
          if (storyCollections.library) {
            currentUser.library().deleteItem(story);
            storyCollections.library = false;
            this.storyInLists.set(storyId, storyCollections);
          } else {
            currentUser.library().saveItem(story);
            storyCollections.library = true;
            this.storyInLists.set(storyId, storyCollections);
          }
        } else {
          readingList = this.readingLists.get(listId);

          //Is in list
          if (storyCollections[listId]) {
            readingList.stories().remove(story);
            storyCollections[listId] = false;
            this.storyInLists.set(storyId, storyCollections);
          } else {
            //Not in list
            readingList.stories().add(story);
            storyCollections[listId] = true;
            this.storyInLists.set(storyId, storyCollections);
          }
        }

        $(e.currentTarget).prop("disabled", true);
        var status = $(e.currentTarget).children(".status");
        status.children(".in-list").addClass("hidden");
        status.children(".spinner").removeClass("hidden");

        next(e);
      },

      onListModified: function(isAdd, listId, storyId, next) {
        //Find the item to inject into
        var $listMenu = this.$(
            this.listsContainer + "[data-story-id='" + storyId + "']"
          ),
          $list = $listMenu
            .children("li")
            .children("a[data-list-id= " + listId + "]");

        $list.find(".status .spinner").addClass("hidden");

        if (isAdd) {
          $list.find(".status .in-list").removeClass("hidden");
          $list.addClass("in-list");
        } else {
          $list.find(".status .in-list").addClass("hidden");
          $list.removeClass("in-list");
        }

        $list.prop("disabled", false);

        //Clear cache
        if (listId === 0) {
          app.local.clear(
            wattpad.utils
              .currentUser()
              .library()
              .resource()
          );
        } else {
          app.local.clear(this.readingLists.resource());
          app.local.clear(
            this.readingLists
              .get(listId)
              .stories()
              .resource()
          );
        }

        next();
      },

      onCreateList: function(event, next) {
        var readingListName = this.$("input.list-name")
            .val()
            .trim(),
          storyId = $(event.currentTarget).data("story-id"),
          self = this;

        if (readingListName.length > 0) {
          var newList = new app.models.ReadingList();

          this.clearError();

          this.listenToOnce(newList, "sync", function(model) {
            self.onListCreated(model, storyId);
          });

          app.local.clear(this.readingLists.resource());

          newList
            .save(
              { name: readingListName, stories: storyId.toString() },
              {
                error: function(model, response) {
                  var resp = JSON.parse(response.responseText);
                  window.alert(resp.message);
                }
              }
            )
            .done(function() {
              utils.cacheBust("ReadingLists", {
                username: utils.currentUser().get("username")
              });
            });
        } else {
          this.$(".inputs").addClass("has-error");
          this.$("input.list-error-placeholder").attr(
            "placeholder",
            wattpad.utils.trans("Reading list name required")
          );
          wattpad.utils.stopEvent(event);
        }

        next();
      },

      onListCreated: function(list, storyId, next) {
        var $listMenu,
          data,
          self = this,
          storyCollections = this.storyInLists.get(storyId);

        //We gotta reset the state of the model because it doesn't resync the
        //stories from the server once it's saved

        storyCollections[list.get("id")] = true;
        this.storyInLists.set(storyId, storyCollections);
        this.readingLists.add(list);

        //Bind events on each reading list
        this.listenTo(list, "list:added", function(listId, storyId) {
          self.onListModified(true, listId, storyId);
        });

        this.listenTo(list, "list:removed", function(listId, storyId) {
          self.onListModified(false, listId, storyId);
        });

        $listMenu = this.$(
          this.listsContainer + "[data-story-id='" + storyId + "']"
        );
        data = {
          id: list.get("id"),
          name: list.get("name"),
          inList: true,
          "user-device": app.get("device"),
          icon: "fa-reading-list"
        };

        $listMenu.append(this.templates.list(data));
        $listMenu.scrollTop($listMenu[0].scrollHeight);
        this.checkListScrollable($listMenu);

        //Clear field
        this.$("input.list-name").val("");

        next();
      },

      stopEvent: function(e, noop) {
        wattpad.utils.stopEvent(e);
        noop();
      },

      clearError: function(noop) {
        this.$(".inputs").removeClass("has-error");
        this.$("input.list-error-placeholder").attr(
          "placeholder",
          wattpad.utils.trans("Add new reading list...")
        );
        noop();
      },

      checkListScrollable: function($list, noop) {
        if ($list.length === 0) {
          return;
        }

        if ($list[0].scrollHeight > 200) {
          $list.addClass("scrollable");
        } else {
          $list.removeClass("scrollable");
        }

        noop();
      },

      doAuthPrompt: function(evt, noop) {
        var self = this;
        if ($("body").hasClass("modal-open")) {
          $(".modal.in").one("hidden.bs.modal", function() {
            self.onAuthPrompt(evt);
          });
          $(".modal.in").modal("hide");
        } else {
          self.onAuthPrompt(evt);
        }

        noop();
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
