var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<ul class=\"meta on-edit-hide\" id=\"reading-list-meta\">\n    <li>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reading List",{"name":"trans","hash":{},"data":data}))
    + "</li>\n    <li>"
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Story","%s Stories",(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.numStories : stack1),{"name":"count","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.numStories : stack1),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "</li>\n</ul>\n";
},"useData":true});