import inactiveStoriesWarning from "core/templates/library/inactive-stories-warning.hbs";
import libraryErrors from "core/templates/library/library-errors.hbs";
import libraryListing from "core/templates/library/library-listing.hbs";
import showLibraryAd from "../../../../../helpers/ads/show-library-ad";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "LibraryListing",
    app.views.List.extend({
      template: libraryListing,

      partials: {
        errorTemplate: libraryErrors,
        inactiveStoriesWarning: inactiveStoriesWarning
      },

      itemView: app.views.LibraryCard,

      containerClass: ".collection",

      activeCard: null,

      events: function() {
        var events = {};

        if (app.get("device").is.desktop) {
          events["mouseenter .on-story-preview"] = "activateCard";
          events["mouseleave .on-story-preview"] = "deactivateCard";
        } else {
          events["tap .on-story-preview"] = "toggleCard";
          events["click .on-story-preview"] = "stopEvent";
        }

        if (wattpad.testGroups.INACTIVE_STORIES) {
          events["tap .on-inactive-stories"] = "onShowInactiveStories";
          events["click .on-inactive-stories"] = "stopEvent";
        }

        events["tap .on-read"] = "readCard";
        events["click .on-read"] = "stopEvent";

        events["tap .on-details"] = "detailsCard";
        events["click .on-details"] = "stopEvent";

        events["tap .on-archive-story"] = "archiveCard";
        events["click .on-archive-story"] = "stopEvent";

        events["tap .on-unarchive-story"] = "unarchiveCard";
        events["click .on-unarchive-story"] = "stopEvent";

        events["tap .on-delete-story"] = "deleteCard";
        events["click .on-delete-story"] = "stopEvent";

        return _.extend(events, app.views.List.prototype.events);
      },

      initialize: function() {
        Handlebars.registerPartial(
          "core.library.library_story_item",
          function() {
            return "";
          }
        );
        _.bindAll(this, ["createErrors", "checkForErrors", "confirm"]);

        this.collection.once("sync", this.render, this);
        this.collection.fetchNextSet({ fresh: true }); //fetch fresh set so that the reading position is upto date

        if (wattpad.testGroups.INACTIVE_STORIES) {
          if (this.collection.libraryType === "current-reads") {
            this.listenToOnce(
              this.collection,
              "fetchNext:done",
              this.showInactiveStoriesWarning
            );
          }
        }
      },

      render: function() {
        app.views.List.prototype.render.apply(this, arguments);

        var isDesktop = app.get("device").is.desktop;

        if (this.collection.total > 0) {
          const element = this.$el;
          showLibraryAd(element, isDesktop);
        }

        return this;
      },

      getTemplateData: function() {
        return {
          hasStories: this.collection.length > 0
        };
      },

      // Card Related Events //
      getActiveCard: function(event) {
        var $el = $(event.currentTarget);

        //Hammer.js isn't giving us the correct element
        if (!$el.hasClass("on-story-preview")) {
          $el = $el.parents(".on-story-preview");
        }
        return this.viewPointers[$el.data("cid")];
      },

      activateCard: function(event) {
        this.toggleCard("activate", event);
      },

      deactivateCard: function(event) {
        this.toggleCard("deactivate", event);
        this.closeReadingListSelector();
      },

      readCard: function(event) {
        this.getActiveCard(event).read();
      },

      detailsCard: function(event) {
        this.getActiveCard(event).details();
      },

      archiveCard: function(event) {
        this.getActiveCard(event)
          .archive()
          .then(this.checkForErrors)
          .then(this.cacheBust);
      },

      unarchiveCard: function(event) {
        this.getActiveCard(event)
          .unarchive()
          .then(this.checkForErrors)
          .then(this.cacheBust);
      },

      deleteCard: function(event) {
        var card = this.getActiveCard(event);

        this.confirm(
          utils.trans(
            "Are you sure you want to remove this story from your library/archive?"
          )
        )
          .then(card.discard)
          .then(this.cacheBust);
      },

      toggleCard: function(action, event) {
        var card = this.getActiveCard(event || action);

        if (!event) {
          card[card.active ? "deactivate" : "activate"]();
          card.active = card.active ? false : true;
        } else {
          card[action]();
        }
      },

      cacheBust: function() {
        return utils.cacheBust(["NodeLibrary", "Archive"], [{}, {}]);
      },

      confirm: function(prompt) {
        if (window.confirm(prompt)) {
          return Promise.resolve();
        }

        return Promise.reject();
      },

      /**
       * Error handling for the library / archive API calls
       * All API calls return as OK 200. The response body will contain a list of successful IDs and 3 separate lists of
       * potential errors.
       * **/
      checkForErrors: function(response) {
        var errors = [];

        errors.push.apply(
          errors,
          this.createErrors(
            response.failed.notFound,
            utils.trans('The story "%s" could not be found.')
          )
        );
        errors.push.apply(
          errors,
          this.createErrors(
            response.failed.notInLibrary,
            utils.trans('The story "%s", was not found in your library.')
          )
        );
        errors.push.apply(
          errors,
          this.createErrors(
            response.failed.notInArchive,
            utils.trans('The story "%s", was not found in your archive.')
          )
        );
        errors.push.apply(
          errors,
          this.createErrors(
            response.failed.notMoved,
            utils.trans('The story "%s" was not moved. Please try again later.')
          )
        );

        if (errors.length) {
          $("#generic-modal")
            .html(this.partials.errorTemplate({ errors: errors }))
            .modal("show");
        }
      },

      /*
    * Shows a warning to the user (once they reach the end of their Current Reads)
    * that some stories in their Current Reads are inactive and have been removed,
    * i.e., stories that have been unpublished or deleted.
    */
      showInactiveStoriesWarning: function() {
        var username = wattpad.utils.currentUser().get("username");
        var self = this;

        var getInactiveLibraryItems = $.ajax({
          type: "GET",
          url: "/v4/users/" + username + "/library/inactive"
        });

        Promise.resolve(getInactiveLibraryItems).then(function(data) {
          if (data.stories.length > 0) {
            self.$el.append(
              self.partials.inactiveStoriesWarning({
                stories: data.stories
              })
            );
          }
        });
      },

      onShowInactiveStories: function() {
        var username = wattpad.utils.currentUser().get("username");
        var deleteInactiveLibraryItems = $.ajax({
          type: "DELETE",
          url: "/v4/users/" + username + "/library/inactive"
        });

        this.$el.find(".inactive-stories").modal("show");
        return Promise.resolve(deleteInactiveLibraryItems);
      },

      createErrors: function(list, message) {
        var self = this;

        return _.map(list, function(id) {
          return utils.sprintf(message, [self.collection.get(id).get("title")]);
        });
      }
    })
  );

  app.mixin(app.views.LibraryListing, "InfiniteScroll", "ReadingList");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
