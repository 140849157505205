var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.isPaywalled : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPaidPreview : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    return "                <div class=\"supported-marker\">\n                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Wattpad Original",{"name":"trans","hash":{},"data":data}))
    + "\n                </div>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.freePartsUntilPaywall : depth0),-1,{"name":"greaterThan","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    <div class=\"free-parts-warning\">\n"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.freePartsUntilPaywall : depth0),0,{"name":"ifequal","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "                    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "                            "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"This is the last free part",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                            "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"There is %s more free part","There are %s more free parts",(depth0 != null ? depth0.freePartsUntilPaywall : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.freePartsUntilPaywall : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "<span class=\"deleted-tag\">("
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Deleted",{"name":"trans","hash":{},"data":data}))
    + ")</span>";
},"12":function(container,depth0,helpers,partials,data) {
    return "<span class=\"draft-tag\">("
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Draft",{"name":"trans","hash":{},"data":data}))
    + ")</span>";
},"14":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  data-toggle=\"tooltip\" data-placement=\"bottom\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Read","%s Reads",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n";
},"16":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  data-toggle=\"tooltip\" data-placement=\"bottom\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Vote","%s Votes",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  data-toggle=\"tooltip\" data-placement=\"bottom\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Comment","%s Comments",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.commentCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.commentCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n";
},"20":function(container,depth0,helpers,partials,data) {
    return " hidden-lg";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "                <li class=\"story-extra dedication btn btn-link\">\n                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Dedicated to",{"name":"trans","hash":{},"data":data}))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.dedication : depth0)) != null ? stack1.url : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "")
    + "                </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <a href=\""
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.dedication : depth0)) != null ? stack1.url : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.dedication : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        <strong>"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.dedication : depth0)) != null ? stack1.name : stack1), depth0))
    + "</strong>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"top-ad-unit-wrapper\">\n"
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_top_ad"],depth0,{"name":"core.story_reading.part_top_ad","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"col-sm-12 col-md-8 col-lg-8 col-md-offset-2 col-lg-offset-2\">\n    <header class=\"panel panel-reading text-center\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPaidStory : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <h1 class=\"h2\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.deleted : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.draft : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</h1>\n\n        <div class=\"story-stats\">\n            <span class=\"reads\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                >\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n            </span>\n\n            <span class=\"votes\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                >\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n            </span>\n\n            <span class=\"comments on-comments\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.commentCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                >\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-comment",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                <a href='#'>"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.commentCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</a>\n            </span>\n        </div>\n\n        <div class=\"author"
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.nextPart : depth0)) != null ? stack1.isBlocked : stack1),{"name":"unless","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n            <a class=\"on-navigate avatar avatar-sm center-block\" href=\"/user/"
    + alias3(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">\n                <img class=\"on-hover\" data-toggle=\"tooltip\" data-placement=\"right\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writer: %s",((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),{"name":"trans","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writer: %s",((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),{"name":"trans","hash":{},"data":data}))
    + "\" src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),16,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n            </a>\n            "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by <a class='on-navigate' href='/user/%s'>%s</a>",((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.user : stack1)) != null ? stack1.username : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n        </div>\n\n\n        <div class=\"meta\">\n            <ul class=\"story-extras\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.dedication : depth0)) != null ? stack1.name : stack1),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </div>\n    </header>\n</div>\n"
    + ((stack1 = (helpers.isDevice || (depth0 && depth0.isDevice) || alias2).call(alias1,"mobile",{"name":"isDevice","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});