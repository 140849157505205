import updatedPolicyNotice from "core/templates/updated-policy-notice.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "UpdatedPolicyNotice",
    app.views.Base.extend({
      className: "updated-policy-notice",
      template: updatedPolicyNotice,

      events: {
        "tap    .on-continue": "onContinue",
        "click  .on-continue": "stopEvent"
      },

      initialize: function(options) {
        this.model = options.model;
        this.title = options.title || "";
        this.body = options.body || "";
      },

      render: function(options) {
        var templateOptions = {
          title: this.title,
          body: this.body
        };

        this.$el.html(this.template(templateOptions));
        return this;
      },

      showModal: function() {
        var $modal = $("#generic-modal");
        $modal.find(".modal-body").html(this.render().$el);
        // add a class to customize the size of this modal, since it needs to be
        // smaller than the generic one
        $modal.find(".modal-dialog").addClass("updated-policy-notice-modal");
        $modal.modal({ backdrop: "static", keyboard: false });
      },

      hideModal: function() {
        var $modal = $("#generic-modal");
        $modal.modal("hide");

        // clean up class after the hide animation has had time to finish
        setTimeout(function() {
          $modal
            .find(".modal-dialog")
            .removeClass("updated-policy-notice-modal");
        }, 500);
      },

      onContinue: function() {
        this.model.acceptLatestTermsOfService().then(function(result) {
          if (!result) {
            var toast = new app.views.ErrorToast({
              message: wattpad.utils.trans(
                "Sorry, something went wrong. Please try again."
              )
            });
            toast.render();
          }
        });
        this.hideModal();
      }
    })
  );
})(window, _, wattpad, window.app);
