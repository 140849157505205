var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"%s yrs old",((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.age : stack1),{"name":"trans","hash":{},"data":data}));
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.country : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"4":function(container,depth0,helpers,partials,data) {
    return "&middot;";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.country : stack1), depth0));
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.part : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "                <li><a href=\"/admin/db/text.php?PME_sys_rec="
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.part : depth0)) != null ? stack1.id : stack1), depth0))
    + "&PME_sys_operation=View\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Ranking Details",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.part : depth0),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "            <li><a href=\""
    + alias3(((helper = (helper = helpers.translateLink || (depth0 != null ? depth0.translateLink : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"translateLink","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Translate",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.part : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "            <li><a class=\"on-options-edit-part\" href=\"/mystories?compose=true&id="
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.part : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit Story Part",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "            <li><a class=\"on-options-edit-details\" href=\"/myworks/"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Edit Story Details",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "<li><a href=\"/api/alltext?id="
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.part : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Plain Text",{"name":"trans","hash":{},"data":data}))
    + "</a></li>";
},"18":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <li><a href=\""
    + alias3(((helper = (helper = helpers.reportLink || (depth0 != null ? depth0.reportLink : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"reportLink","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Report Story",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a class=\"on-options-hot-list-review\" href=\"#\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Hot List Review",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <li class=\"story-admin-dropdown-row options-row\">\n            <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Paid Story",{"name":"trans","hash":{},"data":data}))
    + ":</strong>\n            <span class=\"row-controls\"><ul class=\"option-items\">\n                <li><a target=\"_blank\" rel=\"noopener noreferrer\" href=\"/admin/paid_stories_manager?storyId="
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Manage",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            </ul></span>\n            <span class=\"response-status pull-right\"></span>\n        </li>\n";
},"22":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <li class=\"story-admin-dropdown-row options-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Download:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\"><ul class=\"option-items\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.part : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <li><a href=\"/apiv2/storytext?group_id="
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "&no_format=false&include_deleted=true&include_drafts=true&include_private=true&output=zip\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story Group",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n        </ul></span>\n    </li>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "<li><a href=\"/apiv2/storytext?id="
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.part : depth0)) != null ? stack1.id : stack1), depth0))
    + "&no_format=false&include_deleted=true&include_drafts=true&include_private=true&output=zip\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Story Part",{"name":"trans","hash":{},"data":data}))
    + "</a></li>";
},"25":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.draft : stack1),{"name":"unless","hash":{},"fn":container.program(26, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"26":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=helpers.blockHelperMissing, buffer = 
  "\n    <li class=\"story-admin-dropdown-row language-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Language:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <select id=\"story-language-select\" class=\"form-control\">\n";
  stack1 = ((helper = (helper = helpers.languages || (depth0 != null ? depth0.languages : depth0)) != null ? helper : alias2),(options={"name":"languages","hash":{},"fn":container.program(27, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.languages) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "            </select>\n        </span>\n        <span class=\"response-status pull-right\"></span>\n    </li>\n    <li class=\"story-admin-dropdown-row category-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Category:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <select id=\"story-category-select\" class=\"form-control\">\n                <option value=\"-1\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Select a category",{"name":"trans","hash":{},"data":data}))
    + "</option>\n";
  stack1 = ((helper = (helper = helpers.categories || (depth0 != null ? depth0.categories : depth0)) != null ? helper : alias2),(options={"name":"categories","hash":{},"fn":container.program(29, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.categories) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </select>\n        </span>\n        <span class=\"response-status pull-right\"></span>\n    </li>\n    <li class=\"story-admin-dropdown-row cover-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cover:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <ul class=\"option-items\">\n                <li>\n                    <div class='button' data-file-upload=\"drop\">\n                        <a class=\"file-select\" role=\"button\" href=\"#\">\n                            "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upload",{"name":"trans","hash":{},"data":data}))
    + "\n                        </a>\n                        <input accept=\"image/jpeg, image/png, image/gif\" type=\"file\" name=\"file\">\n                    </div>\n                </li>\n                <li><a class=\"on-cover-remove\" href=\"#\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Remove",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n                <li><a href=\"/banner?id="
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Banner",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            </ul>\n\n        </span>\n        <span class=\"response-status pull-right\"></span>\n    </li>\n    ";
},"27":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.name : depth0),((stack1 = (depths[1] != null ? depths[1].story : depths[1])) != null ? stack1.language : stack1),{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"29":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.select || (depth0 && depth0.select) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.name : depth0),((stack1 = ((stack1 = (depths[1] != null ? depths[1].story : depths[1])) != null ? stack1.categories : stack1)) != null ? stack1["0"] : stack1),depth0,{"name":"select","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "    <li class=\"story-admin-dropdown-row rating-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Rating:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <ul class=\"option-items\">\n                <li><a href=\"/admin/community_reports?model_type=2&model_id="
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reports",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n                <li><a href=\"/admin/story_rating_history.php?story_id="
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.part : depth0),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"History",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            </ul>\n        </span>\n    </li>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "&part_id="
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.part : depth0)) != null ? stack1.id : stack1), depth0));
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <li class=\"story-admin-dropdown-row total-reads-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Total Reads By Author:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <ul class=\"option-items\">\n                <li>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.totalReads : stack1), depth0))
    + " ("
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.totalReads : stack1),{"name":"count","hash":{},"data":data}))
    + ")</li>\n            </ul>\n        </span>\n    </li>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression, alias2=container.lambda;

  return "    <li class=\"story-admin-dropdown-row admin-notes\">\n        <strong class=\"row-name\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Admin Notes:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <ul class=\"option-items\">\n                 <div class=\"author-info clearfix\">\n                <li style=\"white-space: pre-line;\">"
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.notes : stack1), depth0))
    + "</li>\n                <li><a href=\"https://admin.wattpad.com/admin/manage_user.php?&username="
    + alias1(alias2(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.username : stack1), depth0))
    + "\">Add/Delete notes</a></li>\n            </div>\n             </ul>    \n        </span>\n    </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<div class=\"author-info clearfix\">\n    <span class=\"avatar avatar-md pull-left\">"
    + alias3((helpers.connectAvatar || (depth0 && depth0.connectAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.avatar : stack1),((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.username : stack1),48,{"name":"connectAvatar","hash":{},"data":data}))
    + "</span>\n    <span class=\"author-info-text\"><strong>"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.username : stack1), depth0))
    + "</strong></span>\n\n    <span class=\"author-info-text\">\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.age : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.age : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.author : depth0)) != null ? stack1.country : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    </span>\n\n</div>\n<ul class=\"lists-menu\">\n    <li class=\"story-admin-dropdown-row error-row hidden\">\n        <div class=\"alert alert-danger\" role=\"alert\"></div>\n    </li>\n\n    <li class=\"story-admin-dropdown-row review-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Review:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\">\n            <ul class=\"option-items\">\n                <li><a href=\"/contentreview?groupid="
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.id : stack1), depth0))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n        </span>\n    </li>\n\n    <li class=\"story-admin-dropdown-row options-row\">\n        <strong class=\"row-name\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Options:",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n        <span class=\"row-controls\"><ul class=\"option-items\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0, blockParams, depths),"inverse":container.program(18, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        </ul></span>\n        <span class=\"response-status pull-right\"></span>\n    </li>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isStaff : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.deleted : stack1),{"name":"unless","hash":{},"fn":container.program(25, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(31, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isAdmin : depth0),{"name":"if","hash":{},"fn":container.program(36, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true,"useDepths":true});