import userEventConvoBtn from "core/templates/events/partials/buttons/user-event-convo-btn.hbs";
import userEventUserMeta from "core/templates/events/partials/user-event-user-meta.hbs";
import userEventStoryMeta from "core/templates/events/partials/user-event-story-meta.hbs";
import userEventFooter from "core/templates/events/partials/user-event-footer.hbs";
import dedicate from "core/templates/events/dedicate.hbs";
import placeholder from "core/templates/events/placeholder.hbs";
import vote from "core/templates/events/vote.hbs";
import upload from "core/templates/events/upload.hbs";
import message from "core/templates/events/message.hbs";
import library from "core/templates/events/library.hbs";
import follow from "core/templates/events/follow.hbs";
import commentInline from "core/templates/events/comment-inline.hbs";
import comment from "core/templates/events/comment.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Event view - class to handle display of all events, used in feeds
   * @Class Event
   * @constructor
   */
  app.add(
    "UserEvent",
    app.views.Base.extend({
      // Properties
      // ----------
      tagName: "article",
      className: "user-event",

      // populated with the appropriate template in this.templates on init
      template: null,
      userEventConvoBtn: userEventConvoBtn,

      templates: {
        comment: comment,
        inline_comment: commentInline,
        follow: follow,
        library: library,
        message: message,
        upload: upload,
        vote: vote,
        placeholder: placeholder,
        dedicate: dedicate
      },

      partials: {
        regions: {
          "core.events.partials.user_event_footer": userEventFooter,
          "core.events.partials.user_event_story_meta": userEventStoryMeta,
          "core.events.partials.user_event_user_meta": userEventUserMeta
        }
      },

      events: {
        // links
        "tap    .on-user": "onUser",
        "click  .on-user": "stopEvent",

        "tap    .on-user-mention": "onNavigate",
        "click  .on-user-mention": "stopEvent",

        // actions
        "tap    .on-add-to-library": "onAddToLibrary",
        "tap    .on-remove-from-library": "onRemoveFromLibrary",

        "tap    .on-hide": "onHideUserEvent"
      },

      // Methods
      // -------
      initialize: function() {
        this.template = this.templates[this.model.get("type")];

        //TODO Make this work like above for meta (story vs user meta)
        //Register other region partials
        _.each(this.partials.regions, function(value, key, list) {
          Handlebars.registerPartial(key, value);
        });
      },

      //TODO: Break this out so we don't have a 500 line test
      render: function() {
        var templateData,
          type = this.model.get("type"),
          modelData = this.model.get("data");

        templateData = this.model.toJSON();
        templateData.isValidMessage = false;

        switch (type) {
          case "comment":
          case "inline_comment":
            // block HTML tags in message / comment body
            templateData.data.comment.body = utils.sanitizeHTML(
              modelData.comment.body
            );
            templateData.leftButton = this.userEventConvoBtn(templateData);
            break;
          case "upload":
            templateData.data.story.isNew = modelData.story.numParts === 1;
            break;
          case "dedicate":
            templateData.outgoing =
              modelData.dedicator.name === this.model.get("username");
            templateData.data.dedicatee.hasWorks =
              modelData.dedicatee.numWorks > 0;

            //Reformat data for user-event-user-meta handlebars
            templateData.meta = templateData.meta || {};
            templateData.meta.user = templateData.data.dedicatee;
            break;
          default:
            break;
        }

        //Relevant only to types with stories
        if (modelData.story && modelData.story.part) {
          templateData.data.story.inLibrary = utils
            .currentUser()
            .library()
            .get(modelData.story.id)
            ? true
            : false;

          if (
            modelData.type !== "upload" &&
            modelData.story.title === modelData.story.part.title
          ) {
            templateData.data.story.part.title = "";
          }

          //Format the urls
          templateData.data.story.url = wattpad.utils.formatStoryUrl(
            modelData.story.url
          );
          templateData.data.story.part.url = wattpad.utils.formatStoryUrl(
            modelData.story.part.url
          );
        }

        //If no menu options available, hide the menu
        templateData.hideMenu =
          (this.model.get("username") !==
            wattpad.utils.currentUser().get("username") &&
            !templateData.isValidMessage) ||
          !(this.model instanceof app.models.PublicMessage);

        this.$el.html(this.template(templateData));

        //Indicate whether this is the profile owner's activity or not
        if (type !== "placeholder") {
          if (type !== "dedicate") {
            if (modelData.user.name === this.model.get("username")) {
              this.$el.addClass("self");
            }
          } else if (templateData.outgoing) {
            this.$el.addClass("self");
          }
        }
        return this;
      },

      // Navigation Events
      // -----------------
      onUser: function(evt) {
        var $target = $(evt.currentTarget);
        var matcher = new RegExp("user/" + $target.data("username") + "($|/)");

        // If I am already looking at the user's profile, don't navigate
        if (Backbone.history.fragment.match(matcher) === null) {
          app.router.navigate("/user/" + $target.data("username"), {
            trigger: true
          });
        }
      },

      onHideUserEvent: function(evt) {
        utils.stopEvent(evt);
        this.model.destroy();
      },
      // Action Events
      // -------------

      onAddToLibrary: function(evt) {
        var currentUser = utils.currentUser();
        var storyGroup = new app.models.StoryGroup({
          id: this.model.get("data").story.id
        });
        currentUser.library().add(storyGroup);
        this.deactivateLibraryButton();
      },

      onRemoveFromLibrary: function(evt) {
        var currentUser = utils.currentUser();
        var storyGroup = new app.models.StoryGroup({
          id: this.model.get("data").story.id
        });
        currentUser.library().remove(storyGroup);
        this.activateLibraryButton();
      },

      activateLibraryButton: function() {
        var buttonBody =
          wattpad.utils.iconify("fa-plus", 14, "wp-base-2-dark") +
          " " +
          wattpad.utils.trans("Add to Library");
        this.$(".on-remove-from-library").html(buttonBody);
        this.$(".on-remove-from-library").addClass("on-add-to-library");
        this.$(".on-remove-from-library").addClass("btn-white");
        this.$(".on-remove-from-library").removeClass("btn-teal");
        this.$(".on-remove-from-library").removeClass("on-remove-from-library");
      },

      deactivateLibraryButton: function() {
        var buttonBody =
          wattpad.utils.iconify("fa-check", 14, "wp-neutral-5") +
          " " +
          wattpad.utils.trans("Added to Library");
        this.$(".on-add-to-library").html(buttonBody);
        this.$(".on-add-to-library").addClass("on-remove-from-library");
        this.$(".on-add-to-library").addClass("btn-teal");
        this.$(".on-add-to-library").removeClass("btn-white");
        this.$(".on-add-to-library").removeClass("on-add-to-library");
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
