(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:onboardingStep1",
    app.router.filter(["getTranslations"], function() {
      var step1 = new app.views.OnboardingStep1();
      app.transitionTo(step1, { hasHeader: false, hasFooter: false });

      window.te.push("event", "onboarding", null, null, "start");
    })
  );

  app.router.on(
    "route:onboardingGenres",
    app.router.filter(["getTranslations"], function() {
      var user = wattpad.utils.currentUser();
      const browseTopicsCollection = new app.collections.BrowseTopics([], {
        type: "onboarding"
      });
      let genres = [];
      Promise.resolve(browseTopicsCollection.fetch())
        .then(collection => {
          // filter out wattpad originals tag
          genres = collection.topics.filter(topic => {
            return (
              topic.name !== "wattpadoriginals" &&
              topic.name !== wattpad.wattysActiveKey &&
              topic.name !== "bhm2021"
            );
          });
        })
        .catch(() => {
          genres = null;
        })
        .finally(() => {
          var onboardingGenres = new app.views.OnboardingGenres({
            user: user,
            genres: genres
          });
          app.transitionTo(onboardingGenres, {
            hasHeader: false,
            hasFooter: false
          });
        });
    })
  );

  app.router.on(
    "route:onboardingWriterJourney",
    app.router.filter(["getTranslations"], function() {
      var user = wattpad.utils.currentUser();
      var onboardingWriterJourney = new app.views.OnboardingWriterJourney({
        user: user
      });

      app.transitionTo(onboardingWriterJourney, {
        hasHeader: false,
        hasFooter: false
      });
    })
  );

  app.router.on(
    "route:onboardingSuccess",
    app.router.filter(["getTranslations"], function() {
      var onboardingSuccess = new app.views.OnboardingSuccess();
      app.transitionTo(onboardingSuccess, {
        hasHeader: false,
        hasFooter: false
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
