var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"action-wrapper icon-wrapper\">\n        <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.reportLink || (depth0 != null ? depth0.reportLink : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"reportLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"on-report-image\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-flag",20,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",20,"wp-neutral-5","on-dismiss",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "on-dismiss";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <div class=\"background-wrapper icon-wrapper\">\n        <div class=\"social-wrapper\" id=\"media-share\">\n"
    + ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.mediaShare : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n    </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "                <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),18,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                </a> \n";
},"7":function(container,depth0,helpers,partials,data) {
    return " social-share\" target=\"_blank";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function";

  return "<div id=\"picture-viewer\" class=\"modal fade\" role=\"dialog\" data-keyboard=\"true\" tabindex='-1'>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTouchDevice : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <div class=\"vertical-align-helper "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isTouchDevice : depth0),{"name":"unless","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        <div class=\"modal-dialog\">\n            <div class=\"modal-content\">\n                <div class=\"modal-body\">\n                <a href=\""
    + container.escapeExpression(((helper = (helper = helpers.reportLink || (depth0 != null ? depth0.reportLink : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"reportLink","hash":{},"data":data}) : helper)))
    + "\" target=\"_blank\" class=\"on-report-image\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-flag",20,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",20,"wp-neutral-3","on-dismiss",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                  "
    + ((stack1 = ((helper = (helper = helpers.contents || (depth0 != null ? depth0.contents : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contents","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n                </div>\n            </div>\n        </div>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isTouchDevice : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});