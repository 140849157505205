var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.lists : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    --><div class=\"reading-list-item-row\">\n"
    + ((stack1 = container.invokePartial(partials["core.library.library_reading_list_item"],depth0,{"name":"core.library.library_reading_list_item","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div><!--\n";
},"4":function(container,depth0,helpers,partials,data) {
    return "    --><p>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"You currently have no reading lists.",{"name":"trans","hash":{},"data":data}))
    + "</p><!--\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "\n<div class=\"reading-list-tab container col-md-12 col-sm-12\" id=\"reading-list-tab\" data-drag=\"true\" role=\"list\"><!--\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasLists : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "--></div>\n\n\n<div class=\"show-more center-text hidden\">\n  <button class=\"btn btn-link on-showmore\">\n  <strong>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Show More",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n  </button>\n</div>\n";
},"usePartial":true,"useData":true});