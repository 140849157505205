(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * File Upload Mixin
   * Automatically adds file upload capabilities to a view with the required HTML
   * Use view.getFile to read the contents of the file upload.
   * Expects an html block with the .file-upload class.
   */
  app.add(
    "FileUpload",
    Monaco.Mixin.create({
      events: {
        "tap .file-select": "onFileSelect",
        "change .file-upload": "onFileSelected",

        "dragenter .file-drop": "onFileDragEnter",
        "dragleave .file-drop": "onFileDragLeave",
        "dragover .file-drop": "onFileDragOver",
        "dragend .file-drop": "onFileDragEnd",
        "drop .file-drop": "onFileDrop"
      },

      FileUpload: {},

      setElement: function(el, next) {
        this.attachWindow();
        next(el);
        this.connectMixin();
      },

      render: function(next) {
        var result = next();

        this.connectMixin();

        return result;
      },

      connectMixin: function(next) {
        var $domRegion = this.$("[data-file-upload]");

        $domRegion.addClass("file-upload");

        if (this.$(".file-select").length < 1) {
          $domRegion.find("button").addClass("file-select");
        }

        if (
          $domRegion.data("file-upload") === "drop" &&
          this.$(".file-drop").length < 1
        ) {
          $domRegion.addClass("file-drop");
        }

        //Save these for efficiency
        this.FileUpload.$domRegion = $domRegion;
        this.FileUpload.$domDrop = this.$(".file-drop");

        next();
      },

      attachWindow: function(next) {
        var doc = window.document,
          self = this;

        window
          .$(doc)
          .unbind("dragover.fileUpload")
          .bind("dragover.fileUpload", function(evt) {
            self.onWindowDragEnter(evt);
          });

        window
          .$("body")
          .unbind("dragleave.fileUpload")
          .bind("dragleave.fileUpload", function(evt) {
            self.onWindowDragLeave(evt);
          });

        next();
      },

      onFileSelect: function(next) {
        // Click trigger for the file select
        this.FileUpload.file = null;
        this.$(".file-upload input").trigger("click");
        next();
      },

      onFileSelected: function(next) {
        this.FileUpload.file = this.$(".file-upload > input")[0].files[0];
        this.FileUpload.$domDrop.addClass("drop-done");
        // Show the image that was selected
        this.showFile();
        next();
      },

      onFileDragEnter: function(evt, next) {
        utils.stopEvent(evt);

        this.FileUpload.$domDrop.addClass("drag-over");
        next();
      },

      onFileDragLeave: function(evt, next) {
        utils.stopEvent(evt);
        next();
      },

      onFileDragOver: function(evt, next) {
        utils.stopEvent(evt);

        this.FileUpload.$domDrop.addClass("drag-over");
        next();
      },

      onFileDragEnd: function(evt, next) {
        utils.stopEvent(evt);

        this.FileUpload.$domDrop
          .removeClass("drag-window")
          .removeClass("drag-over");

        next();
      },

      onWindowDragEnter: function(evt, next) {
        utils.stopEvent(evt);

        this.FileUpload.$domDrop.addClass("drag-window");

        next();
      },

      onWindowDragLeave: function(evt, next) {
        var from = evt.relatedTarget || evt.toElement;

        utils.stopEvent(evt);

        if (!from || from.nodeName == "HTML") {
          this.FileUpload.$domDrop.removeClass("drag-window");
        }

        next();
      },

      onFileDrop: function(evt, next) {
        utils.stopEvent(evt);

        this.FileUpload.file = evt.originalEvent.dataTransfer.files[0];
        this.showFile();
        this.onFileDragEnd(evt);
        this.FileUpload.$domDrop.addClass("drop-done");

        next();
      },

      showFile: function(next) {
        var self = this;
        this.getFile().then(function(file) {
          self
            .$(".file-upload > img")
            .attr(
              "src",
              "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
            )
            .css("background-image", "url(" + file.data + ")");
          self.fileReceivedCallback && self.fileReceivedCallback(file);
        });

        next && next();
      },

      base64ToArrayBuffer: function(base64, next) {
        var binary_string = window.atob(base64);
        var len = binary_string.length;
        var bytes = new Uint8Array(len);
        for (var i = 0; i < len; i++) {
          bytes[i] = binary_string.charCodeAt(i);
        }

        next();
        return bytes.buffer;
      },

      getOrientation: function(base64, next) {
        var orientationData = null;
        var view = new DataView(this.base64ToArrayBuffer(base64));
        // Check if it's a jpeg image
        if (view.getUint16(0, false) == 0xffd8) {
          var length = view.byteLength,
            offset = 2;

          while (offset < length) {
            var marker = view.getUint16(offset, false);
            offset += 2;

            if (marker == 0xffe1) {
              // Check if there is orientation data
              if (view.getUint32((offset += 2), false) == 0x45786966) {
                var little = view.getUint16((offset += 6), false) == 0x4949;
                offset += view.getUint32(offset + 4, little);
                var tags = view.getUint16(offset, little);
                offset += 2;

                for (var i = 0; i < tags; i++) {
                  if (view.getUint16(offset + i * 12, little) == 0x0112) {
                    orientationData = view.getUint16(
                      offset + i * 12 + 8,
                      little
                    );
                  }
                }
              }
            } else if ((marker & 0xff00) != 0xff00) {
              break;
            } else {
              offset += view.getUint16(offset, false);
            }
          }
        }

        next();
        return orientationData;
      },

      getFile: function(next) {
        var self = this;
        var reader = new window.FileReader(),
          // Get the file
          file = this.FileUpload.file,
          result = new Promise(function(resolve, reject) {
            // Check that there is a file
            if (file) {
              reader.onload = function(data) {
                // We have to check if the exif contains any orientation data,
                // more info at http://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
                var base64 = data.target.result.match(/;base64,(.+)$/)[1];
                var orientationData = self.getOrientation(base64);

                // File was read successfully,
                resolve({
                  metadata: file,
                  data: data.target.result,
                  parsed: data.target.result.match(/;base64,(.+)$/)[1],
                  orientation: orientationData
                });

                self
                  .$(".file-upload > input")
                  .replaceWith(self.$(".file-upload > input").clone());
              };

              reader.onerror = function() {
                // Bad File, rejected!
                reject({ code: 1, message: utils.trans("Error reading file") });
              };

              // Initiate file read
              reader.readAsDataURL(file);
            } else {
              // No File, rejected!
              reject({
                code: 0,
                message: utils.trans("Please select a file to upload")
              });
            }
          });

        next();

        return result;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
