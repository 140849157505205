(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "TagModuleItem",
    app.models.Base.extend({
      defaults: {
        id: null,
        type: null,
        createDate: null,
        data: null
      },

      // list of supported types
      validTypes: [
        "hotStoriesForTag",
        "newStoriesForTag",
        "relatedWritersForTag",
        "relatedReadingListsForTag",
        "relatedTagsForTag"
      ],

      isValid: function() {
        var curType = this.get("type");
        return _.contains(this.validTypes, curType);
      }
    })
  );
})(window, _, wattpad, window.app);
