var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<article class=\"notification aggregate follower panel unread\">\n    <div class=\"count\">0</div>\n    <div class=\"content\">\n        <div class=\"heading\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You have no new notifications",{"name":"trans","hash":{},"data":data}))
    + "</div>\n        <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Updates about what is happening to you and your stories will be shown here",{"name":"trans","hash":{},"data":data}))
    + "</p>\n    </div>\n</article>\n";
},"useData":true});