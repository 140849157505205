import discoverTags from "core/templates/discover/discover-tags.hbs";
import discoverCategories from "core/templates/discover/discover-categories.hbs";
import discoverLanding from "platform/discover/templates/discover-landing.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "DiscoverLanding",
    app.views.RegionManager.extend({
      template: discoverLanding,

      partials: {
        "core.discover.discover_categories": discoverCategories,
        "core.discover.discover_tags": discoverTags,
        "core.collection_views.recommended_follow": function() {
          return "";
        },
        "core.signup_prompt": function() {
          return "";
        }
      },

      id: "discover-landing",

      events: {
        // stuff
      },

      initialize: function(options) {
        options = options || {};
        _.each(this.partials, function(value, key) {
          Handlebars.registerPartial(key, value);
        });
      },

      render: function() {
        var currentUser = utils.currentUser();
        var data = {
          panelGroups: []
        };

        //Don't do this stuff unless the app has started. setElement will do it.
        if (utils.appOn()) {
          var collection = this.collection.toJSON();
          for (var i = 0; i < collection.length; i++) {
            if (
              collection[i].listType === "stories" ||
              collection[i].listType === "reading_lists_with_users"
            ) {
              data.panelGroups.push({
                placeholder: true,
                placeholderType: collection[i].listType,
                title: utils.PanelBuilder.linkifyTitle(
                  collection[i].title,
                  collection[i].elements
                ),
                subtitle: collection[i].subtitle,
                url: collection[i].url,
                cache: collection[i].id
              });
            }

            if (i === 2) {
              data.panelGroups.push({
                title: utils.trans("Suggested Tags"),
                tags: currentUser.get("recommendedTags")
              });
            }
          }

          data.panelGroups[0].layout = utils.PanelBuilder.Layouts.FourUpInside;
          data.panelGroups[1].layout = utils.PanelBuilder.Layouts.FourUpNone;

          data.categories = app.get("categories").toJSON();
          data.currentUser = utils.currentUser().get("username");

          //set cookie for signupForm
          data.onboarding = true;
          wattpad.utils.setCookie(
            "signupFrom",
            "discover",
            0,
            window.location.hostname.replace("www", "")
          );

          this.$el.html(this.template(data));

          this.bindDiscoveryPanelGroups();
        }

        var signUpPrompt = new app.views.SignUpPrompt({
          model: new app.models.Authsignup(),
          subtitle: wattpad.utils.trans("Sign Up"),
          sideWay: true
        });
        this.setRegion("#authentication-panel", signUpPrompt.render());

        if (currentUser.get("username")) {
          var recommendedUsers = new app.views.RecommendedFollow({
            username: currentUser.get("username"),
            cards: true,
            itemCount: 4
          });
          this.setRegion("#recommended-users", recommendedUsers.render());
        }

        return this;
      },

      bindDiscoveryPanelGroups: function() {
        var self = this;
        self.subViews = self.subViews || [];

        this.$("[data-type=discover-stories]").each(function() {
          var $this = $(this);

          self.subViews.push(
            new app.views.DiscoverStories({
              el: this,
              placeholder: $this.data("placeholder"),
              collection: new app.collections.DiscoverCollection([], {
                url: $this.data("url"),
                cache: $this.data("cache"),
                layout: $this.data("layout")
              })
            })
          );
        });

        this.$("[data-type=discover-reading_lists_with_users]").each(
          function() {
            var $this = $(this);

            self.subViews.push(
              new app.views.DiscoverLists({
                el: this,
                placeholder: $this.data("placeholder"),
                model: self.model,
                collection: new app.collections.DiscoverListsCollection([], {
                  url: $this.data("url"),
                  cache: $this.data("cache"),
                  layout: $this.data("layout")
                })
              })
            );
          }
        );
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);
        if (
          this.regions["#recommended-users"] &&
          this.regions["#recommended-users"].view
        ) {
          this.regions["#recommended-users"].view.setElement(
            this.$("#recommended-users > div")
          );
        }

        if (
          this.regions["#authentication-panel"] &&
          this.regions["#authentication-panel"].view
        ) {
          this.regions["#authentication-panel"].view.setElement(
            this.$("#authentication-panel > div")
          );
        }

        this.bindDiscoveryPanelGroups();
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
