var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<p data-media-type=\"image\">\n  <span contenteditable='false' class=\"on-remove-image\">\n    <span class=\"play fa-stack fa-lg\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled","auto","wp-neutral-1 fa-stack-2x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove","auto","wp-neutral-5 fa-stack-1x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </span>\n  </span>\n  <iframe data-video-id=\""
    + alias4(((helper = (helper = helpers.videoId || (depth0 != null ? depth0.videoId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data}) : helper)))
    + "\" width=\"764\" height=\"330\" src=\"//www.youtube-nocookie.com/embed/"
    + alias4(((helper = (helper = helpers.videoId || (depth0 != null ? depth0.videoId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"videoId","hash":{},"data":data}) : helper)))
    + "?rel=0&showinfo=0&enablejsapi=1\" frameborder=\"0\" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>\n</p>\n";
},"useData":true});