app.router.add({
  "story/:storyid(-:slug)(/)(:section)(/)": [
    "storyDetails",
    {
      regexp: {
        storyid: /[^\/\?#-]+/,
        slug: /[^\/\?#]*/,
        section: /parts/
      }
    }
  ]
});

app.router.add({
  "story/:storyid(-:slug)/rankings(/)": [
    "storyRanking",
    {
      regexp: {
        storyid: /[^\/\?#-]+/,
        slug: /[^\/\?#]*/
      }
    }
  ]
});
