var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"listsModal modal fade\" aria-hidden=\"true\" role=\"dialog\">\n  <div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n      <header class=\"modal-header\">\n        <div class=\"pull-right close\" data-dismiss=\"modal\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove-alt",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n        <h3 class=\"modal-title text-center\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add to Another Reading List",{"name":"trans","hash":{},"data":data}))
    + " </h3>\n      </header>\n\n      <div class=\"modal-body\">\n        <ul id=\"add-to-list\" class=\"lists-menu list-unstyled\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></ul>\n      </div>\n\n    </div>\n  </div>\n</div>\n\n";
},"useData":true});