import feedItemOptions from "core/templates/feed-items/feed-item-options.hbs";
import feedDedicateItem from "core/templates/feed-items/aggregates/item-views/feed-dedicate-item.hbs";
import feedVoteItem from "core/templates/feed-items/aggregates/item-views/feed-vote-item.hbs";
import feedLibraryItem from "core/templates/feed-items/aggregates/item-views/feed-library-item.hbs";
import feedFollowItem from "core/templates/feed-items/aggregates/item-views/feed-follow-item.hbs";
import feedCommentItem from "core/templates/feed-items/aggregates/item-views/feed-comment-item.hbs";
import feedDedicateGroup from "core/templates/feed-items/aggregates/feed-dedicate-group.hbs";
import feedVoteGroup from "core/templates/feed-items/aggregates/feed-vote-group.hbs";
import feedLibraryGroup from "core/templates/feed-items/aggregates/feed-library-group.hbs";
import feedFollowGroup from "core/templates/feed-items/aggregates/feed-follow-group.hbs";
import feedCommentGroup from "core/templates/feed-items/aggregates/feed-comment-group.hbs";
import feedPlaceholder from "core/templates/feed-items/feed-placeholder.hbs";
import feedDedicate from "core/templates/feed-items/feed-dedicate.hbs";
import feedVote from "core/templates/feed-items/feed-vote.hbs";
import newFeedMessage from "core/templates/feed-items/new-feed-message.hbs";
import feedStory from "core/templates/feed-items/feed-story.hbs";
import feedLibrary from "core/templates/feed-items/feed-library.hbs";
import feedFollow from "core/templates/feed-items/feed-follow.hbs";
import feedComment from "core/templates/feed-items/feed-comment.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Event view - class to handle display of all events, used in feeds
   * @Class Event
   * @constructor
   */
  app.add(
    "FeedItem",
    Monaco.View.extend({
      // Properties
      // ----------
      tagName: "article",
      className: "feed-item-new panel",

      // populated with the appropriate template in this.templates on init
      template: null,

      templates: {
        comment: feedComment,
        inline_comment: feedComment,
        follow: feedFollow,
        library: feedLibrary,
        upload: feedStory,
        update: feedStory,
        newMessage: newFeedMessage,
        message: newFeedMessage,
        vote: feedVote,
        dedicate: feedDedicate,
        placeholder: feedPlaceholder
      },

      groupTemplates: {
        comment: feedCommentGroup,
        inline_comment: feedCommentGroup,
        follow: feedFollowGroup,
        library: feedLibraryGroup,
        upload: feedStory,
        update: feedStory,
        vote: feedVoteGroup,
        dedicate: feedDedicateGroup
      },

      itemTemplates: {
        comment: feedCommentItem,
        inline_comment: feedCommentItem,
        follow: feedFollowItem,
        library: feedLibraryItem,
        vote: feedVoteItem,
        dedicate: feedDedicateItem
      },

      events: {
        // actions
        "tap    .on-hide": "onHideUserEvent",
        "click    .on-hide": "stopEvent",

        "tap    .send-avatar-event": "sendAvatarClickTrackingEvent",
        "click  .send-avatar-event": "stopEvent"
      },

      // Methods
      // -------
      initialize: function() {
        //Aggregates
        if (this.model.get("group")) {
          this.template = this.groupTemplates[this.model.get("type")];
        } else {
          this.template = this.templates[this.model.get("type")];
        }

        //Register aggregate partials
        Handlebars.registerPartial(
          "core.feed_items.aggregates.item_views.feed_comment_item",
          this.itemTemplates.comment
        );
        Handlebars.registerPartial(
          "core.feed_items.aggregates.item_views.feed_follow_item",
          this.itemTemplates.follow
        );
        Handlebars.registerPartial(
          "core.feed_items.aggregates.item_views.feed_library_item",
          this.itemTemplates.library
        );
        Handlebars.registerPartial(
          "core.feed_items.aggregates.item_views.feed_dedicate_item",
          this.itemTemplates.dedicate
        );
        Handlebars.registerPartial(
          "core.feed_items.aggregates.item_views.feed_vote_item",
          this.itemTemplates.vote
        );
        Handlebars.registerPartial(
          "core.feed_items.feed_item_options",
          feedItemOptions
        );

        //Listen for the follow manager
        if (
          this.model.get("type") === "follow" ||
          this.model.get("type") === "dedicate"
        ) {
          this.listenTo(app, "app:user:follow", this.onUpdateFollow);
          this.listenTo(app, "app:user:unfollow", this.onUpdateUnfollow);
        }
      },

      render: function() {
        var data = this.model.toJSON(),
          self = this;
        if (data.group) {
          _.each(data.group, function(model) {
            if (data.data.story) {
              model.data.story.inLibrary = utils
                .currentUser()
                .library()
                .get(model.data.story.id)
                ? true
                : false;
            }
          });
          data.groupCount = data.children.length + 1;
        }

        if (data.type === "dedicate") {
          data.outgoing =
            data.data.dedicatee.name !==
            wattpad.utils.currentUser().get("username");
        }

        if (data.username) {
          //only available via profile
          data.profile = data.username;
        }

        this.$el.html(this.template(data));

        _.defer(function() {
          self.$(".panel-body [data-toggle=tooltip]").tooltip();
        });

        return this;
      },

      onUpdateFollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn
          .children(".fa")
          .removeClass("fa-follow fa-wp-neutral-2")
          .addClass("fa-following fa-wp-neutral-5");
        $btn.children(".toggle-triangle").addClass("active");
        $btn.children(".toggle-shadow").addClass("active");
        $btn
          .attr("title", wattpad.utils.trans("Following"))
          .tooltip("fixTitle");
      },

      onUpdateUnfollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn
          .children(".fa")
          .removeClass("fa-following fa-wp-neutral-5")
          .addClass("fa-follow fa-wp-neutral-2");
        $btn.children(".toggle-triangle").removeClass("active");
        $btn.children(".toggle-shadow").removeClass("active");
        $btn.attr("title", wattpad.utils.trans("Follow")).tooltip("fixTitle");
      },

      onAddedToLibrary: function(storyId) {
        var $btn = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $btn
          .children(".fa")
          .removeClass("fa-wp-neutral-2 fa-plus")
          .addClass("fa-wp-neutral-5 fa-check");
        $btn.children(".toggle-triangle").addClass("active");
        $btn.children(".toggle-shadow").addClass("active");
        $btn
          .attr("title", wattpad.utils.trans("Added to Library"))
          .tooltip("fixTitle");
      },

      onRemovedFromLibrary: function(storyId) {
        var $btn = this.$(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $btn
          .children(".fa")
          .addClass("fa-wp-neutral-2 fa-plus")
          .removeClass("fa-wp-neutral-5 fa-check");
        $btn.children(".toggle-triangle").removeClass("active");
        $btn.children(".toggle-shadow").removeClass("active");
        $btn
          .attr("title", wattpad.utils.trans("Add to Library"))
          .tooltip("fixTitle");
      },

      onHideUserEvent: function(evt) {
        utils.stopEvent(evt);
        this.model.destroy();
      },

      sendAvatarClickTrackingEvent: function(evt) {
        window.te.push("event", "newsfeed", "avatar", "", "click", {
          feed_item_type: this.model.get("type")
        });
      },

      stopEvent: function(evt) {
        utils.stopEvent(evt);
      }
    })
  );

  app.mixin(app.views.FeedItem, "LibraryManagement", "FollowManager");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
