(function(window, _, wattpad, app) {
  "use strict";

  //For viewing on profile
  app.add(
    "ProfileWorks",
    app.collections.StoryGroup.extend({
      //smaller subset of fields
      fields: [
        {
          stories: [
            "title",
            "lastPublishedPart",
            "voteCount",
            "readCount",
            "commentCount",
            "cover",
            "tags",
            "url",
            "id",
            "description",
            "categories",
            "completed",
            "mature",
            "rating",
            "rankings",
            "tagRankings",
            "numParts",
            "firstPartId",
            "parts",
            "isPaywalled",
            "paidModel"
          ]
        },
        "total",
        "nextUrl",
        // Returned by /stories/all
        "numPublished",
        "numDrafts"
      ],

      url: function() {
        if (wattpad.utils.currentUser().get("username") === this.username) {
          // Drafts included
          return "/v4/users/" + this.username + "/stories/all";
        } else {
          return "/v4/users/" + this.username + "/stories/published";
        }
      },

      // Needed because the 'numPublished' and 'numDrafts' fields are returned outside
      // of the 'arrayKey' (stories).
      // See parse() in collection-parsing.js.
      parse: function(response) {
        this.numPublished = response.numPublished;
        this.numDrafts = response.numDrafts;
        return app.collections.StoryGroup.prototype.parse.apply(
          this,
          arguments
        );
      },

      // Needed because the 'numPublished' and 'numDrafts' fields are returned outside
      // of the 'arrayKey' (stories).
      // Specifies the fields stored in localstorage
      revertParse: function() {
        var result = app.collections.StoryGroup.prototype.revertParse.apply(
          this,
          arguments
        );

        result.numPublished = this.numPublished;
        result.numDrafts = this.numDrafts;
        return result;
      },

      resource: function() {
        if (wattpad.utils.currentUser().get("username") === this.username) {
          return "user." + this.username + ".profile.works.all";
        }

        return "user." + this.username + ".profile.works";
      },

      initialize: function(collection, options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error(
            "A ProfileWorks collection requires a username string to be passed to the options hash on init"
          );
        }
        this.username = options.username;

        app.collections.StoryGroup.prototype.initialize.apply(this, arguments);
      }
    })
  );
})(window, _, wattpad, window.app);
