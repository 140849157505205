(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Home",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.DiscoverModule,

      arrayKey: "modules",

      fields: [{ modules: ["type", "data"] }, "nextUrl"],

      defaultUrl: function() {
        return "/v4/home";
      },

      resource: function() {
        return "v4.home";
      }
    })
  );
})(window, _, wattpad, window.app);
