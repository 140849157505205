(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "StoryPartLabelsModel",
    app.models.BaseModel.extend({
      fields: ["id", "time_setting"],
      idAttribute: "partId",
      urlRoot: "/v5/parts",

      resource: function() {
        return "part." + this.get("id") + ".labels";
      }
    })
  );
})(window, _, wattpad, window.app);
