var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "  <div class=\"default\">\n    <div class=\"message\"> "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Welcome home! %s Learn more %s about the new design for the Wattpad site.","<a href='#' class='btn-link on-learnmore' style='margin-right: 0px'>","</a>",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + " </div>\n      <div class=\"controls\">\n        <a href=\"#\" class=\"btn btn-grey on-dismiss\"> "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss",{"name":"trans","hash":{},"data":data}))
    + " </a>\n      </div>\n  </div>\n";
},"useData":true});