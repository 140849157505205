var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "  <a class=\"btn btn-more btn-grey btn-block on-update-feed\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"See latest activity.",{"name":"trans","hash":{},"data":data}))
    + "\n  </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "  <div class=\"title-wrapper\" id=\"feed-title\">\n    <h4 class=\"title\"><span class=\"title-text\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Recent Activity",{"name":"trans","hash":{},"data":data}))
    + "</span></h4>\n  </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"feed-item-new panel pinned-item\">\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_pinned_message"],depth0,{"name":"core.feed_items.feed_pinned_message","data":data,"indent":"\t","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), buffer = "";

  stack1 = ((helper = (helper = helpers.showActivityOutofDate || (depth0 != null ? depth0.showActivityOutofDate : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"showActivityOutofDate","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias1,options) : helper));
  if (!helpers.showActivityOutofDate) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isFeed : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.shouldRenderPinned : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(partials["desktop.components.incremental_list"],depth0,{"name":"desktop.components.incremental_list","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"usePartial":true,"useData":true});