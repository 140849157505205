(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "AutocompleteFollowing",
    Monaco.Collection.extend({
      resource: function() {
        return (
          "autocomplete:following:" +
          (this.searchTerm ? ":" + this.searchTerm : "")
        );
      },

      url: function() {
        return "/api/v3/autocomplete/following?term=" + this.searchTerm;
      },

      parse: function(resp) {
        return _.map(resp, function(item) {
          return { label: item.label };
        });
      },

      cacheLocal: false
    })
  );
})(window, _, wattpad, window.app);
