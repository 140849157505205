var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <div class=\"description\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.html_enabled : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <pre>"
    + ((stack1 = (helpers.preFormat || (depth0 && depth0.preFormat) || alias2).call(alias1,(helpers.linkify || (depth0 && depth0.linkify) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"linkify","hash":{"sanitization":"unsanitize"},"data":data}),{"name":"preFormat","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <pre>"
    + ((stack1 = (helpers.preFormat || (depth0 && depth0.preFormat) || alias2).call(alias1,(helpers.linkify || (depth0 && depth0.linkify) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"linkify","hash":{"sanitization":"sanitize"},"data":data}),{"name":"preFormat","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"no-description\">\n      <div>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Help people get to know you",{"name":"trans","hash":{},"data":data}))
    + "</div>\n      <button class=\"btn btn-orange on-edit\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add Description",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <li class=\"location col-xs-12 col-sm-12\">\n      <span class=\"user-meta-info-content\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-location",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + container.escapeExpression(((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"location","hash":{},"data":data}) : helper)))
    + "\n      </span>\n    </li>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "("
    + container.escapeExpression((helpers.formatDate || (depth0 && depth0.formatDate) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.createDate : depth0),{"name":"formatDate","hash":{},"data":data}))
    + ")";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <hr />\n  <ul id=\"profile-links\" class=\"list-unstyled row\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.website : stack1),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.facebook : stack1),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.smashwords : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </ul>\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <li class=\"website col-xs-12 col-sm-12\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-link",18,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      <span class=\"sr-only\">website:</span>\n      <a class=\"alt-link\" href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.website : stack1)) != null ? stack1.url : stack1), depth0))
    + "\" target=\"_blank\" rel=\"nofollow noopener\">\n        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.website : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n      </a>\n    </li>\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <li class=\"facebook col-xs-12 col-sm-12\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-facebook-square",18,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      <span class=\"sr-only\">facebook:</span>\n      <a class=\"alt-link\" href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.facebook : stack1)) != null ? stack1.url : stack1), depth0))
    + "\" target=\"_blank\" rel=\"nofollow noopener\">\n        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.facebook : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n      </a>\n    </li>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "    <li class=\"smashwords col-xs-12 col-sm-12\">\n      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-link",18,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      <span class=\"sr-only\">smashwords:</span>\n      <a class=\"alt-link\" href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.smashwords : stack1)) != null ? stack1.url : stack1), depth0))
    + "\" target=\"_blank\" rel=\"nofollow noopener\">\n        "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.links : depth0)) != null ? stack1.smashwords : stack1)) != null ? stack1.name : stack1), depth0))
    + "\n      </a>\n    </li>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <hr />\n  <div id=\"following-panel\">\n    <div class=\"title\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</div>\n\n    <div class=\"users clearfix\" data-section=\"following\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.userFollowings : depth0),{"name":"each","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingMobile : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingTablet : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingDesktop : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowingDesktopWide : depth0),{"name":"if","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n  </div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <a class=\"avatar avatar-sm2 on-navigate pull-left hide\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n        <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n      </a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <a href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/following\" class=\"on-nav-item on-navigate num-following pull-left mobile-break hide\">+"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowingMobile : depth0),{"name":"count","hash":{},"data":data}))
    + "</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <a href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/following\" class=\"on-nav-item on-navigate num-following pull-left tablet-break hide\">+"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowingTablet : depth0),{"name":"count","hash":{},"data":data}))
    + "</a>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <a href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/following\" class=\"on-nav-item on-navigate num-following pull-left desktop-break hide\">+"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowingDesktop : depth0),{"name":"count","hash":{},"data":data}))
    + "</a>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <a href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/following\" class=\"on-nav-item on-navigate num-following pull-left desktop-wide-break hide\">+"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowingDesktopWide : depth0),{"name":"count","hash":{},"data":data}))
    + "</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", buffer = 
  "<div class=\"panel-body\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.description : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n  <ul class=\"list-unstyled user-meta-info row\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.location : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <li class=\"date col-xs-12 col-sm-12\">\n      <span class=\"user-meta-info-content\">\n        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Joined",{"name":"trans","hash":{},"data":data}))
    + "</span>"
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fullDate":true},"data":data}))
    + "\n\n        ";
  stack1 = ((helper = (helper = helpers.isAmbassador || (depth0 != null ? depth0.isAmbassador : depth0)) != null ? helper : alias2),(options={"name":"isAmbassador","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isAmbassador) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n      </span>\n    </li>\n  </ul>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.links : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.userFollowings : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  <hr />\n  <div id=\"profile-share-links\">\n    <div class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share profile",{"name":"trans","hash":{},"data":data}))
    + "</div>\n    "
    + ((stack1 = ((helper = (helper = helpers.socialShareSection || (depth0 != null ? depth0.socialShareSection : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"socialShareSection","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n  </div>\n\n</div>\n";
},"useData":true});