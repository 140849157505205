(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "ChangeEmailModal",
    Monaco.Model.extend({
      defaults: {
        email: null
      },

      validationRules: {
        email: [
          { func: "isRequired", msg: utils.trans("Email is required.") },
          {
            func: "isEmail",
            msg: utils.trans("That is not a valid email address.")
          },
          { func: "uniqueEmail", async: true }
        ]
      },

      // Async tests
      uniqueEmail: function(value, options) {
        return Promise.resolve(
          $.ajax("/api/v3/users/validate", {
            data: { email: value }
          })
        );
      },

      uniqueUsername: function(value, options) {
        return Promise.resolve(
          $.ajax("/api/v3/users/validate", {
            data: { username: value }
          })
        );
      }
    })
  );

  app.mixin(app.models.ChangeEmailModal, "ValidationModel");
})(window, _, wattpad, wattpad.utils, window.app);
