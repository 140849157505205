var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<header>\n  <a class=\"on-user avatar avatar-sm avatar-logo\"\n     data-username=\"wattpad\"\n     href=\"/user/wattpad\">\n    <img src=\""
    + alias3(((helper = (helper = helpers.assetServer || (depth0 != null ? depth0.assetServer : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"assetServer","hash":{},"data":data}) : helper)))
    + "/img/mobile-web/white_wattpad_icon.png\">\n  </a>\n  <div class=\"clearfix\">\n    wattpad "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"recommends",{"name":"trans","hash":{},"data":data}))
    + "\n     <a class=\"on-navigate\"\n      href=\""
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.url : stack1), depth0))
    + "\">\n      <strong>"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1), depth0))
    + "</strong>\n    </a>\n  </div>\n  <time>"
    + alias3((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"dropSuffix":true},"data":data}))
    + "</time>\n</header>\n<hr>\n"
    + ((stack1 = container.invokePartial(partials["core.events.partials.user_event_story_meta"],depth0,{"name":"core.events.partials.user_event_story_meta","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n";
},"usePartial":true,"useData":true});