(function(window, _, wattpad, utils, app) {
  "use strict";

  var SHARE_URL = "https://twitter.com/intent/tweet?";

  /**
   * @mixin TwitterConnect
   */
  app.add(
    "TwitterConnect",
    Monaco.Mixin.create({
      twitterShare: function(options, next) {
        options = options || {};

        utils.openPopup(
          SHARE_URL + $.param(options),
          utils.trans("Share Wattpad With Twitter"),
          575,
          400
        );
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
