import userItem from "platform/components/templates/item-views/user-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * User ItemView - small user card displaying general meta info & follow/unfollow button
   * @Class UserItem    View
   * @constructor
   */
  app.add(
    "UserItem",
    app.views.Base.extend({
      // Properties
      // ----------
      className: "col-md-3 col-sm-4 col-xs-6 ",
      template: userItem,

      events: {
        "tap  .on-dismiss": "onRemoveFollower" // stay consistent with recommended-follow-item
      },

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.showRemove = options.showRemoveFollower;

        this.listenTo(app, "app:user:follow", this.updateToFollowingButton);
        this.listenTo(app, "app:user:unfollow", this.updateToFollowButton);
      },

      render: function() {
        var data = _.extend(this.model.toJSON(), {
            showRemove: this.showRemove
          }),
          self = this;

        data.disabled = this.model.get("following") === undefined;

        if (data.disabled) {
          Promise.resolve(this.model.determineFollowStatus()).then(function() {
            data = _.extend(self.model.toJSON(), {
              showRemove: self.showRemove
            });
            self.$el.html(self.template(data));
          });
        }

        this.$el.html(this.template(data));
        return this;
      },

      updateToFollowingButton: function(username) {
        if (this.model.get("username") === username) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-white").addClass("btn-teal");
          $btn
            .find(".fa")
            .removeClass("fa-follow fa-wp-base-2-dark")
            .addClass("fa-following fa-wp-neutral-5");
          $btn.find(".truncate").text(utils.trans("Following"));

          this.model.set("following", true);
        }
      },

      updateToFollowButton: function(username) {
        if (this.model.get("username") === username) {
          var $btn = this.$('.btn-fan[data-target="' + username + '"]');

          $btn.removeClass("btn-teal").addClass("btn-white");
          $btn
            .find(".fa")
            .removeClass("fa-following fa-wp-neutral-5")
            .addClass("fa-follow fa-wp-base-2-dark");
          $btn.find(".truncate").text(utils.trans("Follow"));

          this.model.set("following", false);
        }
      },

      onRemoveFollower: function() {
        var self = this;

        this.model.deleteFollower().then(function() {
          if (self.model.collection) {
            self.model.collection.remove(self.model);
          }
        });
      },

      onNavigate: function() {
        app.router.navigate("/user/" + this.model.get("username"), {
          trigger: true
        });
      },

      stopEvent: function(event) {
        utils.stopEvent(event);
      }
    })
  );

  app.mixin(app.views.UserItem, "FollowManager");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
