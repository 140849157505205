(function(window, _, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * IncrementalList = IncrementalFetch + CollectionView
   * uses a "Read More" button to load more items from the server,
   * watches the collection total to set the visibility of the show more button
   *
   * Meant to be used specifically with a collection that extends off of
   * IncrementalFetch
   * @Class IncrementalList { View }
   * @constructor
   */
  app.add(
    "IncrementalList",
    app.views.CollectionView.extend({
      // Properties
      // ----------
      containerClass: ".collection", // sub-element to inject ItemViews into

      events: {
        "tap        .on-showmore": "onShowMore",
        "touchstart .on-showmore": "setTouchState",
        "touchend   .on-showmore": "removeTouchState"
      },

      // Methods
      // -------
      constructor: function(options) {
        app.views.CollectionView.prototype.constructor.apply(this, arguments);

        this.listenTo(this.collection, "fetchNext:done", this.setButtonState);
      },

      render: function() {
        this.$el.empty().append(this.template(this.getTemplateData()));
        this.renderCollection(this.getItemViewOptions());
        this.setButtonState();
        this.bindEvents();
        return this;
      },

      getItemViewOptions: function() {
        return null;
      },

      getTemplateData: function() {
        var data = this.model ? this.model.toJSON() : {};
        return data;
      },

      setButtonState: function() {
        const showMoreButton = this.collection.commentLocation
          ? "#show-more-" + this.collection.commentLocation
          : ".show-more";
        if (
          typeof this.collection.hasMore === "function" &&
          this.collection.hasMore()
        ) {
          this.$(showMoreButton).removeClass("hidden");
        } else {
          this.$(showMoreButton).addClass("hidden");
        }
      },

      onShowMore: function(event) {
        var self = this;
        if (event) {
          utils.stopEvent(event);
        }

        this.$(".show-more-message").addClass("hidden");
        this.$(".loading-message").removeClass("hidden");
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          self.$(".show-more-message").removeClass("hidden");
          self.$(".loading-message").addClass("hidden");
        });
      },

      setTouchState: function(event) {
        this.$(".on-showmore").addClass("touched");
      },

      removeTouchState: function(event) {
        this.$(".on-showmore").removeClass("touched");
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app, window.Monaco);
