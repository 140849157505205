import messageReplies from "core/templates/messages/message-replies.handlebars";
import feedMessageReplies from "core/templates/feed-items/feed-message-replies.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * This view is used to display a list of replies to a message thread on the
   * user's activity feed
   */

  app.add(
    "MessageRepliesList",
    app.views.IncrementalList.extend({
      // Properties
      // ----------
      className: "message-replies",
      template: null,
      itemView: app.views.MessageReply,

      initialize: function(options) {
        options = options || {};
        //TODO: Remove this when the FEEDS is rolled out
        if (options.forFeeds) {
          this.template = feedMessageReplies;
        } else {
          this.template = messageReplies;
        }

        this.forFeeds = options.forFeeds || false;
      },

      renderItem: function(model) {
        var self = this;

        if (this.forFeeds) {
          this.itemView = app.views.FeedMessageReply;
        }

        app.views.IncrementalList.prototype.renderItem.apply(this, arguments);
        this.listenTo(this.viewPointers[model.cid], "message:mention", function(
          name
        ) {
          // pass it to the parent view
          self.trigger("message:mention", name);
        });
      },

      getTemplateData: function() {
        return {
          hasReplies: this.collection.models.length !== 0
        };
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
