var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return " unread";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"<strong>%1$s</strong> mentioned you in a comment on <strong>%2$s - %3$s</strong>",((stack1 = ((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.parentId : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"<strong>%1$s</strong> replied to a comment on <strong>%2$s - %3$s</strong>",((stack1 = ((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "          "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"<strong>%1$s</strong> commented on <strong>%2$s - %3$s</strong>",((stack1 = ((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),(helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.story : depth0)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"sanitize","hash":{},"data":data}),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        ";
},"10":function(container,depth0,helpers,partials,data) {
    return "expanded-text";
},"12":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=helpers.helperMissing;

  return "<!-- Individual Comment -->\n<article class=\"notification comment panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n  <a href=\"/user/"
    + alias2(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"on-navigate avatar avatar-sm2\">\n      <img src=\""
    + ((stack1 = (helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias3).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data})) != null ? stack1 : "")
    + "\">\n  </a>\n  <div class=\"content\">\n"
    + ((stack1 = container.invokePartial(partials["core.item_views.notification_item_options"],depth0,{"name":"core.item_views.notification_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n    <a href=\""
    + alias2((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias3).call(alias1,((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.deeplink_url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-notification-click\">\n      <div class=\"heading\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMentions : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "      </div>\n    </a>\n\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias1,"fa-comment",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <time datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.calendar || (depth0 && depth0.calendar) || alias3).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n\n    <p class=\"shown-message "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isTruncated : depth0),{"name":"unless","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias3).call(alias1,((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.body : stack1),(depth0 != null ? depth0.maxTextLength : depth0),true,{"name":"simpleShorten","hash":{"doUrl":false,"doTag":false},"data":data})) != null ? stack1 : "")
    + "\n      <span class=\"show-full"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isTruncated : depth0),{"name":"unless","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"><strong>"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias1,"more",{"name":"trans","hash":{},"data":data}))
    + "</strong></span>\n      </p>\n    <p class=\"hidden full-message\">"
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || alias3).call(alias1,((stack1 = (depth0 != null ? depth0.comment : depth0)) != null ? stack1.body : stack1),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n  </div>\n</article>\n";
},"usePartial":true,"useData":true});