import discoverModuleLists from "core/templates/item-views/discover-module-lists.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "DiscoverModuleLists",
    app.views.Base.extend({
      className: "discover-module-lists on-discover-module-item",
      template: discoverModuleLists,

      render: function() {
        var data = this.model ? this.model.toJSON() : { empty: true };

        data.type = "reading_lists";
        data.isDesktop = app.get("device").isDesktop();

        this.$el.html(this.template(data));

        return this;
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
