var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "active";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"PremiumPicksBanner","premium-picks-banner",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div>\n"
    + ((stack1 = container.invokePartial(partials["core.library.library_controls.library_lists_controls"],depth0,{"name":"core.library.library_controls.library_lists_controls","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <div>\n"
    + ((stack1 = container.invokePartial(partials["core.library.library_controls"],depth0,{"name":"core.library.library_controls","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <div class=\"library-lists-landing\">\n"
    + ((stack1 = container.invokePartial(partials["core.library.library_lists_landing"],depth0,{"name":"core.library.library_lists_landing","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              <div>\n"
    + ((stack1 = container.invokePartial(partials["core.library.library_listing"],depth0,{"name":"core.library.library_listing","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "              </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=helpers.blockHelperMissing, buffer = 
  "\n <div id=\"library-landing-page\">\n    <div class=\"container\">\n        <header>\n            <h1>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Library",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n        </header>\n        <div class=\"sub-navigation\">\n            <div class=\"container\">\n                <nav class=\"pull-left\">\n                    <ul>\n                        <li class=\"";
  stack1 = ((helper = (helper = helpers.islibrary || (depth0 != null ? depth0.islibrary : depth0)) != null ? helper : alias2),(options={"name":"islibrary","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.islibrary) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                            <a class=\"tab-control\" data-section=\"current-reads\" href=\"/library\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Current reads",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                        </li>\n                        <li class=\"";
  stack1 = ((helper = (helper = helpers.isarchive || (depth0 != null ? depth0.isarchive : depth0)) != null ? helper : alias2),(options={"name":"isarchive","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isarchive) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                            <a data-section=\"archive\" href=\"https://www.wattpad.com/archive\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Archive",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                        </li>\n                        <li class=\"";
  stack1 = ((helper = (helper = helpers.islist || (depth0 != null ? depth0.islist : depth0)) != null ? helper : alias2),(options={"name":"islist","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.islist) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\">\n                            <a class=\"tab-control\" data-section=\"reading-lists\" href=\"/list\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reading Lists",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                        </li>\n                    </ul>\n                </nav>\n            </div>\n        </div>\n    </div>\n";
  stack1 = ((helper = (helper = helpers.isPremiumEligible || (depth0 != null ? depth0.isPremiumEligible : depth0)) != null ? helper : alias2),(options={"name":"isPremiumEligible","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isPremiumEligible) { stack1 = alias5.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "    <div class=\"container\">\n        <div id=\"library-controls\" class=\"page-controls clearfix\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.islist : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <main id=\"tab-content\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.islist : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "        </main>\n    </div>\n</div>\n<div id=\"preview-modal\"></div>\n";
},"usePartial":true,"useData":true});