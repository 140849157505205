var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <a class=\"on-navigate\" href=\"/user/"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"avatar avatar-md pull-left\">\n        <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),48,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </div>\n    <div class=\"content\">\n        <h4 class=\"h5\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n        <small>\n            @"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numWorks : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </small>\n    </div>\n      <button class=\"btn btn-triangle btn-fan\" data-target=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-following=\""
    + alias4(((helper = (helper = helpers.following || (depth0 != null ? depth0.following : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"following","hash":{},"data":data}) : helper)))
    + "\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follow",{"name":"trans","hash":{},"data":data}))
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <span class=\"toggle-triangle\"></span>\n        <span class=\"toggle-shadow\"></span>\n      </button>\n  </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "&middot; "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Work","%s Works",(depth0 != null ? depth0.numWorks : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numWorks : depth0),{"name":"sprintf","hash":{},"data":data}));
},"4":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "&middot; "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Follower","%s Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numFollowers : depth0),{"name":"sprintf","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.followed : stack1), depth0),{"name":"data.followed","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});