(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "FeedEventFollow",
    app.views.UserEvent.extend({
      events: _.extend(
        {
          "tap    .on-follow-user": "onFollowUser",
          "tap    .on-unfollow-user": "onUnfollowUser"
        },
        app.views.UserEvent.prototype.events
      ),

      render: function() {
        var templateData,
          modelData = this.model.get("data");

        templateData = this.model.toJSON();
        templateData.leftButton = "";
        templateData.data.followed.hasWorks = modelData.followed.numWorks > 0;

        //Reformat data for user-event-user-meta handlebars
        templateData.meta = templateData.meta || {};
        templateData.meta.user = templateData.data.followed;

        this.$el.html(this.template(templateData));

        if (modelData.user.name === this.model.get("username")) {
          this.$el.addClass("self");
        }
      },
      // Action Events
      // -------------
      onFollowUser: function(evt) {
        var data = this.model.get("data"),
          currentUser = utils.currentUser(),
          currentUserName = currentUser.get("username"),
          followedUserName = data.followed.name,
          self = this;

        // POST follow request to the API
        $.when(currentUser.following().follow(followedUserName)).done(
          function() {
            // update UI elements
            self.deactivateFollowButton();

            // toggle following state
            data.followed.following = true;
            self.model.set("data", data);

            // clear cached data as necessary
            app.local.clear("user." + currentUserName);
            app.local.clear("user." + currentUserName + ".following");
            app.local.clear("user." + currentUserName + ".profile.following");

            app.local.clear("user." + followedUserName);
            app.local.clear("user." + followedUserName + ".following");
            app.local.clear("user." + followedUserName + ".profile.following");
          }
        );
      },

      onUnfollowUser: function(evt) {
        var data = this.model.get("data"),
          currentUser = utils.currentUser(),
          currentUserName = currentUser.get("username"),
          followedUserName = data.followed.name,
          self = this;

        // POST unfollow request to the API
        $.when(currentUser.following().unfollow(followedUserName)).done(
          function() {
            // update UI elements
            self.activateFollowButton();

            // toggle following state
            data.followed.following = false;
            self.model.set("data", data);

            // clear cached data as necessary
            app.local.clear("user." + currentUserName); // reference to follow count
            app.local.clear("user." + currentUserName + ".following"); // user.following
            app.local.clear("user." + currentUserName + ".profile.following"); // profile following list (collection)

            app.local.clear("user." + followedUserName);
            app.local.clear("user." + followedUserName + ".following");
            app.local.clear("user." + followedUserName + ".profile.following");
          }
        );
      },

      activateFollowButton: function() {
        var buttonBody =
          wattpad.utils.iconify("fa-follow", 14, "wp-base-2-dark") +
          " " +
          wattpad.utils.trans("Follow");
        this.$(".on-unfollow-user").html(buttonBody);
        this.$(".on-unfollow-user").addClass("on-follow-user");
        this.$(".on-unfollow-user").addClass("btn-white");
        this.$(".on-unfollow-user").removeClass("btn-teal");
        this.$(".on-unfollow-user").removeClass("on-unfollow-user");
      },

      deactivateFollowButton: function() {
        var buttonBody =
          wattpad.utils.iconify("fa-following", 14, "wp-neutral-5") +
          " " +
          wattpad.utils.trans("Following");
        this.$(".on-follow-user").html(buttonBody);
        this.$(".on-follow-user").addClass("on-unfollow-user");
        this.$(".on-follow-user").addClass("btn-teal");
        this.$(".on-follow-user").removeClass("btn-white");
        this.$(".on-follow-user").removeClass("on-follow-user");
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
