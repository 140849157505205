import { setStorageItem, getStorageItem } from "../../../../components/helpers";
import { getPrivateMessageFeatureFlags } from "../../../../helpers/wattpad-backend-feature-flags-helper";

(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:inbox",
    app.router.filter(["getTranslations", "requireLogin"], function(
      activeThread
    ) {
      let privateMessageFeatureFlags = null;

      const featureFlags = window?.prefetched?.featureFlags;

      if (featureFlags) {
        // always write prefetch, just in case we've somehow re-SSR'd and grabbed some updated feature flags.
        setStorageItem({
          key: "wattpad-backend-feature-flags",
          value: featureFlags,
          type: "sessionStorage"
        });

        privateMessageFeatureFlags = getPrivateMessageFeatureFlags(
          featureFlags
        );
      } else {
        // check localStorage to see if we fetched feature flags already in this session
        const sessionStorageFeatureFlags = getStorageItem({
          key: "wattpad-backend-feature-flags",
          type: "sessionStorage"
        });
        if (sessionStorageFeatureFlags !== null) {
          // get privateMessage feature flags from feature flags.
          privateMessageFeatureFlags = getPrivateMessageFeatureFlags(
            sessionStorageFeatureFlags
          );
        }
      }

      var currentUser = wattpad.utils.currentUser();
      // Clear conversation/threads view to prevent glitchy transitions.
      var clearAction = activeThread
        ? window.app.components.actions.resetConversation()
        : window.app.components.actions.resetThreads();
      window.store.dispatch(clearAction);

      var inboxView = new app.views.DummyReactView({
        component: "Inbox",
        componentId: "inbox",
        componentData: {
          username: currentUser.get("username"),
          avatar: currentUser.get("avatar"),
          privateMessageFeatureFlags
        }
      });

      app.transitionTo(inboxView, {
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
