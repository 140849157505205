(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "PinnedMessage",
    app.models.PublicMessage.extend({
      fields: [
        "id",
        "body",
        "createDate",
        "from",
        "numReplies",
        "isReply",
        "latestReplies",
        "isOffensive"
      ],

      url: function() {
        return "/v4/users/" + this.get("username") + "/messages/latest/";
      },

      resource: function() {
        return "user." + this.get("username") + ".latest.activity.";
      },

      idAttribute: "createDate" // string
    })
  );

  app.mixin(app.models.PinnedMessage, "GenerateFields");
})(window, _, wattpad, window.app);
