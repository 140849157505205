import discoverLists from "platform/discover/templates/discover-lists.hbs";
(function(window, wattpad, utils, app, _) {
  "use strict";

  app.add(
    "DiscoverLists",
    app.views.Base.extend({
      template: discoverLists,

      initialize: function(options) {
        options = options || {};

        this.setElement(options.el);

        if (options.placeholder) {
          this.collection.once("sync", this.render, this);
        }
        this.collection.fetch();

        this.listenTo(app, "app:user:follow", this.onUpdateFollow);
        this.listenTo(app, "app:user:unfollow", this.onUpdateUnfollow);
      },

      render: function() {
        var data = {
          lists: _.take(this.collection.toJSON(), 3)
        };

        // We won't have the title, so just replace the stories area.
        this.$(".loading-placeholder")
          .addClass("hidden")
          .after($(this.template(data)).find(".reading-list-grid")); //only want to append the grid

        return this;
      },

      onUpdateFollow: function(username) {
        var $btn = this.$(this.followButton + "[data-target=" + username + "]");
        $btn
          .children(".fa")
          .removeClass("fa-follow fa-wp-neutral-2")
          .addClass("fa-following fa-wp-neutral-2");
      },

      onUpdateUnfollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn
          .children(".fa")
          .removeClass("fa-following fa-wp-neutral-2")
          .addClass("fa-follow fa-wp-neutral-2");
      }
    })
  );

  app.mixin(app.views.DiscoverLists, "FollowManager");
})(window, wattpad, wattpad.utils, window.app, _);
