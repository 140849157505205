var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showAdminOptions : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "        <section id=\"profile-admin\" class=\"bg-danger\">\n          <header>\n            <h3>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Admin",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n          </header>\n\n          <div class=\"description\">\n            "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.email : stack1), depth0))
    + " ("
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.age : stack1), depth0))
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.gender : stack1), depth0))
    + ")\n            <hr>\n            <a href=\"https://admin.wattpad.com/admin/manage_user.php?&id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "\">Manage user tool</a>\n            <hr>\n            <a href=\"https://www.wattpad.com/staff/writer_summary?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">User Overview Report</a>\n            <hr>\n\n            <a href=\"http://redash.wattpad.com/queries/1229?p_userid="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"text-danger\">\n              Login Date / IP\n            </a>\n            <hr>\n\n            "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.categories : stack1), depth0))
    + "\n            <hr>\n\n            Has Mobile App: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.mobileApp : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <hr>\n\n            Currently Featured: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.isFeatured : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <hr>\n\n            Is Premium: "
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.isPremium : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <hr>\n\n            Wallet Coins: "
    + ((stack1 = helpers.each.call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.wallet : stack1),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <hr>\n\n            <a href=\"https://admin.wattpad.com/admin/db/users.php?PME_sys_rec="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&PME_sys_operation=View\" class=\"text-danger\">details</a> |\n            <a href=\"https://admin.wattpad.com/admin/user_library?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"text-danger\">view library</a>\n            <hr>\n\n            <a href=\"https://admin.wattpad.com/admin/sendMessagesWattpad.php?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">Send Message To "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + " As Wattpad</a>\n            <hr>\n\n            <a href=\"https://admin.wattpad.com/admin/view_messages_from_wattpad_to_user.php?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\">View Conversation Between Wattpad And "
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "</a>\n            <hr>\n\n            <a href=\"/admin/mute_list?id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&type=muted\" class=\"text-danger\">muted</a> |\n            <a href=\"/admin/mute_list?id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&type=mutedby\" class=\"text-danger\">muted by</a>\n            <hr>\n\n            <a href=\"/admin/block_list?id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&type=blocked\" class=\"text-danger\">blocked</a> |\n            <a href=\"/admin/block_list?id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&type=blockedby\" class=\"text-danger\">blocked by</a>\n            <hr>\n\n            Activities:\n              <a href=\"https://admin.wattpad.com/admin/db/comments.php?PME_sys_qf2="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"text-danger\">comments</a> |\n              <a href=\"https://admin.wattpad.com/admin/db/messages.php?PME_sys_qf1="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"text-danger\">messages</a> |\n              <a href=\"https://admin.wattpad.com/admin/user_changes?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"text-danger\">change history</a>\n            <hr>\n\n            Stories:\n              <a href=\"/mystories?display_user="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"text-danger\">manage</a> |\n              <a href=\"/contentreview?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"text-danger\">review</a> |\n              <a href=\"/contentreview?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "&deleted=1&spam=1\" class=\"text-danger\">deleted</a> |\n              <a href=\"https://admin.wattpad.com/admin/restore_all?id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"text-danger\">restore all</a>\n            <hr>\n\n            Timeout:\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.timeout : stack1),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "            | <a href=\"/admin/user_timeouts.php?username="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.username : stack1), depth0))
    + "\" class=\"text-danger\">view history</a>\n            <hr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.ambassador : stack1),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(17, data, 0),"data":data})) != null ? stack1 : "")
    + "            <hr>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.isAuthor : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "")
    + "            <hr>\n\n"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.vetted : stack1),"v",{"name":"ifequal","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.vetted : stack1),"f",{"name":"ifequal","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.vetted : stack1),"c",{"name":"ifequal","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            <hr>\n            \n            <h4>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Admin notes",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n            <hr>\n\n            <span style=\"white-space: pre-line;\">"
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.notes : stack1), depth0))
    + " </span>\n            <hr>\n\n            <a href=\"https://admin.wattpad.com/admin/manage_user.php?&id="
    + alias3(alias4(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "\">Add/Delete notes</a>\n          </div>\n        </section>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "Yes";
},"5":function(container,depth0,helpers,partials,data) {
    return "No";
},"7":function(container,depth0,helpers,partials,data) {
    return container.escapeExpression(container.lambda((depth0 != null ? depth0.amount : depth0), depth0))
    + " ";
},"9":function(container,depth0,helpers,partials,data) {
    return " None ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.timeout : stack1), depth0))
    + "\n";
},"13":function(container,depth0,helpers,partials,data) {
    return "                None\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            IS AMBASSADOR <a href=\"/admin/userflag.php?flag=moderator&id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=clear\" class=\"text-danger\">[set non-ambassador]</a>\n";
},"17":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            IS <strong>NOT</strong> AMBASSADOR <a href=\"/admin/userflag.php?flag=moderator&id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=set\" class=\"text-danger\">[set ambassador]</a>\n";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            VERIFIED <a href=\"/admin/userflag.php?flag=author&id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=clear\" class=\"text-danger\">[set non-verified]</a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            NOT VERIFIED <a href=\"/admin/userflag.php?flag=author&id="
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=set\" class=\"text-danger\">[set verified]</a>\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            VETTED: <a href=\"/admin/user_vetted.php?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=c\" class=\"text-danger\">[set not vetted]</a> <a href=\"/admin/user_vetted.php?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=f\" class=\"text-danger\">[set failed]</a>\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            FAILED: <a href=\"/admin/user_vetted.php?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=v\" class=\"text-danger\">[set vetted]</a> <a href=\"/admin/user_vetted.php?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=c\" class=\"text-danger\">[set not vetted]</a>\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "            NOT VETTED: <a href=\"/admin/user_vetted.php?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=v\" class=\"text-danger\">[set vetted]</a> <a href=\"/admin/user_vetted.php?id="
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.admin : depth0)) != null ? stack1.profile : stack1)) != null ? stack1.id : stack1), depth0))
    + "&action=f\" class=\"text-danger\">[set failed]</a>\n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isPremiumEligible || (depth0 != null ? depth0.isPremiumEligible : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isPremiumEligible","hash":{},"fn":container.program(30, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isPremiumEligible) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <section id=\"profile-theme\">\n"
    + ((stack1 = container.invokePartial(partials["core.profile.theme_settings"],depth0,{"name":"core.profile.theme_settings","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "        </section>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <section id=\"authentication-panel\" class=\"panel panel-default2\">\n            <div class=\"signup-modal\">\n                "
    + ((stack1 = ((helper = (helper = helpers.signupContent || (depth0 != null ? depth0.signupContent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"signupContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "\n            </div>\n        </section>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1, helper;

  return "        <section id=\"profile-pinned-item\">"
    + ((stack1 = ((helper = (helper = helpers.pinnedItemContent || (depth0 != null ? depth0.pinnedItemContent : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"pinnedItemContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n";
},"36":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || helpers.helperMissing).call(alias1,"PROFILE_QUESTS",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(37, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"37":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"EmbeddedQuests","embedded-quests",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", buffer = 
  "<div class=\"col-sm-12 col-md-4\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.admin : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <br>\n        <section id=\"profile-request\">"
    + ((stack1 = ((helper = (helper = helpers.requestContent || (depth0 != null ? depth0.requestContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"requestContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n        <section id=\"profile-about\">"
    + ((stack1 = ((helper = (helper = helpers.aboutContent || (depth0 != null ? depth0.aboutContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"aboutContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : alias2),(options={"name":"isLoggedIn","hash":{},"fn":container.noop,"inverse":container.program(32, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = (helpers.isWattpad || (depth0 && depth0.isWattpad) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isWattpad","hash":{},"fn":container.noop,"inverse":container.program(34, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"col-sm-8\">\n        <br>\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        <section id=\"profile-works\">"
    + ((stack1 = ((helper = (helper = helpers.worksContent || (depth0 != null ? depth0.worksContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"worksContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n        <section id=\"profile-lists\">"
    + ((stack1 = ((helper = (helper = helpers.listsContent || (depth0 != null ? depth0.listsContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"listsContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</section>\n        <section id=\"profile-message\" class=\"message-user\"></section>\n    </div>\n";
},"usePartial":true,"useData":true});