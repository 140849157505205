(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "NotificationEvent",
    app.models.UserEvent.extend({
      defaults: {
        id: null,
        type: null,
        createDate: null,
        data: null,
        isRead: false,
        children: [],
        group_url: ""
      },

      // list of supported types
      validTypes: [
        "follow",
        "follower_approved",
        "vote",
        "comment",
        "inline_comment",
        "library",
        "message",
        "dedicate",
        "forum_post",
        "upload",
        "friend_join"
      ]
    })
  );
})(window, _, wattpad, window.app);
