(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Generic View Manager for layouts requiring active regions within a parent View (populated by sub-Views).
   * Can be used to manage complex layouts.  Meant to be inherited/extended, not a standalone view.
   * @Class RegionManager
   * @constructor
   */
  app.add(
    "RegionManager",
    app.views.Base.extend({
      regionMethod: "append",

      constructor: function() {
        this.regions = {};
        app.views.Base.apply(this, arguments);
      },

      clearRegion: function(elementName) {
        if (typeof this.regions[elementName] !== "undefined") {
          this.regions[elementName].view.remove();
          delete this.regions[elementName].view;
          delete this.regions[elementName];
        }
      },

      setRegion: function(elementName, viewInstance) {
        var viewExists;

        // TODO: do we need to check if a view is already set for a region?
        //       can't we have the same instances set to different regions?
        for (var key in this.regions) {
          // TODO: verify if comparing instances comparing instances
          //       is the best approach
          if (
            this.regions.hasOwnProperty(key) &&
            this.regions[key].view === viewInstance
          ) {
            viewExists = true;
            break;
          }
        }

        if (viewExists) {
          throw new Error(
            "RegionManager: The subview (in element " +
              elementName +
              ") is already bound to another region on this view."
          );
        }

        //TODO: Consider removing the 'view' portion and just assign the
        //viewInstance directly to this.regions[ elementName ]
        if (typeof this.regions[elementName] === "undefined") {
          // create a new region
          this.regions[elementName] = {
            view: null
          };
        } else if (typeof this.regions[elementName].view !== "undefined") {
          this.regions[elementName].view.remove();
          delete this.regions[elementName].view;
        }

        this.regions[elementName].view = viewInstance;
        this.$el.find(elementName)[this.regionMethod](viewInstance.$el);
      },

      attachRegions: function() {
        var self = this;
        _.forOwn(this.regions, function(region, name) {
          if (region.view) {
            region.view.setElement(self.$(name));
          }
        });
      },

      remove: function() {
        _.forOwn(
          this.regions,
          function(region, regionName) {
            if (region.view) {
              this.clearRegion(regionName);
            }
          },
          this
        );

        app.views.Base.prototype.remove.apply(this, arguments);
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
