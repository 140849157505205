(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "OnboardingGenres",
    app.views.DummyReactView.extend({
      component: "OnboardingGenres",
      componentId: "onboarding-genres",

      constructor: function(opts) {
        this.componentData = {
          locale: wattpad.utils.getCookie("locale"),
          username: opts.user ? opts.user.get("username") : "",
          genres: opts.genres
        };
        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
