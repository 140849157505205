(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:cookieManager",
    app.router.filter(["getTranslations"], function() {
      if (!wattpad?.testGroups?.COOKIE_MANAGER) {
        wattpad.utils.redirectToCSR("/home");
        return;
      }

      var cookieManagerView = new app.views.DummyReactView({
        component: "CookieManager",
        componentId: "cookie-manager"
      });

      app.transitionTo(cookieManagerView, {
        hasHeader: true,
        hasFooter: false
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
