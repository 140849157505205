var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing, alias4="function";

  return "              <li>\n                <a href=\"/"
    + alias1(container.lambda((depths[1] != null ? depths[1].urlBasePath : depths[1]), depth0))
    + "/"
    + alias1(((helper = (helper = helpers.tabValue || (depth0 != null ? depth0.tabValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"tabValue","hash":{},"data":data}) : helper)))
    + "\" data-section=\""
    + alias1(((helper = (helper = helpers.tabValue || (depth0 != null ? depth0.tabValue : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"tabValue","hash":{},"data":data}) : helper)))
    + "\" class=\"tab-control"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias3).call(alias2,(depths[1] != null ? depths[1].activeTab : depths[1]),(depth0 != null ? depth0.tabValue : depth0),{"name":"ifequal","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                    "
    + alias1(((helper = (helper = helpers.translatedTabName || (depth0 != null ? depth0.translatedTabName : depth0)) != null ? helper : alias3),(typeof helper === alias4 ? helper.call(alias2,{"name":"translatedTabName","hash":{},"data":data}) : helper)))
    + "\n                    <span class=\"fa-stack check "
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias3).call(alias2,(depths[1] != null ? depths[1].activeTab : depths[1]),(depth0 != null ? depth0.tabValue : depth0),{"name":"ifequal","hash":{},"fn":container.noop,"inverse":container.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\">\n                      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-circle-empty fa-stack-2x",16,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                      "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias3).call(alias2,"fa-check fa-stack-1x",10,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    </span>\n                </a>\n              </li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"4":function(container,depth0,helpers,partials,data) {
    return " hidden ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return " <div class=\"panel-heading\">\n        <h4 class=\"text-light all-caps\" id=\"header-left\">"
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Story","%s Stories",(depth0 != null ? depth0.numStories : depth0),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numStories : depth0),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "</h4>\n        <div class=\"button-group relative inline-block\">\n          <button class=\"btn btn-text dropdown-toggle\" id=\"sortby-button\" data-toggle=\"dropdown\">\n            <span id=\"sortby-text\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Sort by: ",{"name":"trans","hash":{},"data":data}))
    + " "
    + alias3(((helper = (helper = helpers.translatedActiveTab || (depth0 != null ? depth0.translatedActiveTab : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"translatedActiveTab","hash":{},"data":data}) : helper)))
    + "</span>\n            <span class=\"caret\"></span>\n          </button>\n          <ul class=\"dropdown-menu dropdown-menu-right\" id=\"sortby-dropdown\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tabs : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </ul>\n      </div>\n    </div>";
},"useData":true,"useDepths":true});