(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "CategoryModuleItem",
    app.models.Base.extend({
      validTypes: [
        "hotStoriesForCategory",
        "featuredStoriesForCategory",
        "newStoriesForCategory"
      ],

      isValid: function() {
        return _.contains(this.validTypes, this.get("type"));
      }
    })
  );
})(window, _, wattpad, window.app);
