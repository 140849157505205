var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-left",22,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-left",19,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<ul>\n  <li class=\"first\">\n      <a href=\"/stories/"
    + alias3((helpers.urlEncodeWithSpace || (depth0 && depth0.urlEncodeWithSpace) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"urlEncodeWithSpace","hash":{},"data":data}))
    + "/hot\" class=\"on-rank-navigate\" data-rank=\""
    + alias3(((helper = (helper = helpers.rank || (depth0 != null ? depth0.rank : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"rank","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n      <span class=\"rank\">\n        <span class=\"rank-number-symbol\">#</span>\n        <span class=\"rank-number\">"
    + alias3(((helper = (helper = helpers.rank || (depth0 != null ? depth0.rank : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"rank","hash":{},"data":data}) : helper)))
    + "</span>\n      </span>\n\n      <span class=\"rank-tag\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n      <span class=\"rank-total\">\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"out of %s stories",(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"count","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n      </span>\n\n      <span class=\"right-side\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/shared/ranking-medal.png","ranking",{"name":"connectImage","hash":{"width":"22","height":"27","klass":"tag-ranking-medal"},"data":data}))
    + "\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",14,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </span>\n    </a>\n  </li>\n</ul>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "  <li>\n      <a href=\"/stories/"
    + alias3((helpers.urlEncodeWithSpace || (depth0 && depth0.urlEncodeWithSpace) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"urlEncodeWithSpace","hash":{},"data":data}))
    + "/hot\" class=\"on-rank-navigate\" data-rank=\""
    + alias3(((helper = (helper = helpers.rank || (depth0 != null ? depth0.rank : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"rank","hash":{},"data":data}) : helper)))
    + "\" data-name=\""
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n      <span class=\"rank\">\n        <span class=\"rank-number-symbol\">#</span>\n        <span class=\"rank-number\">"
    + alias3(((helper = (helper = helpers.rank || (depth0 != null ? depth0.rank : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"rank","hash":{},"data":data}) : helper)))
    + "</span>\n      </span>\n\n      <span class=\"rank-tag\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</span>\n\n      <span class=\"right-side\">\n        <span class=\"rank-total\">\n          "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"out of %s stories",(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.total : depth0),{"name":"count","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n        </span>\n\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-right",14,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </span>\n    </a>\n  </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.storyUrl : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-navigate back-to-story\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</a>\n<h1>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story Rankings",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n\n<h2>"
    + alias3(((helper = (helper = helpers.storyTitle || (depth0 != null ? depth0.storyTitle : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"storyTitle","hash":{},"data":data}) : helper)))
    + "</h2>\n\n<h3>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Most Impressive Ranking",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n"
    + ((stack1 = helpers["with"].call(alias1,(depth0 != null ? depth0.topRank : depth0),{"name":"with","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<h3>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Other Rankings",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n<ul>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.otherRankings : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n";
},"useData":true});