(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "BrowseTagStories",
    app.collections.IncrementalFetchNextUrl.extend({
      tags: null,

      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "tags",
            {
              user: ["name", "username", "avatar"]
            },
            "description",
            "cover",
            "completed",
            "rating",
            "mature",
            "url",
            "numParts",
            "modifyDate",
            "categories",
            "firstPartId"
          ]
        },
        "total",
        "nextUrl"
      ],

      initialize: function(collection, options) {
        options = options || {};
        if (!options.category && !options.tags) {
          throw new Error("BrowseTagStories - Need to pass in a tag(s)");
        }

        this.filter = options.filter || "hot";
        this.tags = options.tags; // A string of comma delimited tags
        this.nextUrl = this.nextUrl || options.nextUrl || null;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      resource: function() {
        return "stories.tag." + this.tags + "." + this.filter;
      },

      url: function() {
        if (!this.nextUrl) {
          return this.defaultUrl();
        }
        return this.filter === "hot" ? this.nextUrl : this.nextUrl;
      },

      defaultUrl: function() {
        // Format tags like so: %23tag1+%23tag2
        // But don't prepend %23 for the /v5/hotlist endpoint since this will cause an empty list to be returned.
        var tags = this.tags
          .split(",")
          .map(function(tag) {
            return "%23" + tag;
          })
          .join("+");

        if (this.filter === "hot") {
          return "/v5/hotlist?tags=" + this.tags;
        }

        if (this.variation) {
          return (
            "/v4/stories?filter=" +
            this.filter +
            "&query=" +
            tags +
            "&variation=" +
            this.variation
          );
        }

        return "/v4/stories?filter=" + this.filter + "&query=" + tags;
      }
    })
  );
})(window, _, wattpad, window.app);
