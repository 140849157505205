import reactPlaceholder from "core/templates/components/react-placeholder.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  // This view's only purpose is to render templates containing React components
  // Useful for CSR when using RegionManager
  // Data on client is expected as a nested `componentData` property
  // e.g. this.componentData = {myProp: "propValue", myOtherProp: "otherPropValue", ...}
  // On server, data is expected in flat form
  // e.g. {component: "ComponentName", componentId: "component-id", myProp: "propValue", myOtherProp: "otherPropValue", ...}
  app.add(
    "DummyReactView",
    app.views.Base.extend({
      template: reactPlaceholder,

      initialize: function(options) {
        options = options || {};

        if (!options.component) {
          throw new Error("Must pass in a React component");
        } else if (!options.componentId) {
          throw new Error("Must pass in a React componentId");
        }

        this.component = options.component;
        this.componentId = options.componentId;
        this.componentData = options.componentData || {};
      },

      render: function() {
        this.$el.html(
          this.template(
            _.extend(this.componentData, {
              component: this.component,
              componentId: this.componentId
            })
          )
        );
        return this;
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
