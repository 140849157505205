var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      "
    + ((stack1 = container.lambda(((stack1 = (depth0 != null ? depth0.image : depth0)) != null ? stack1.outerHTML : stack1), depth0)) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<figure contenteditable=\"false\">\n  <p data-p-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-media-type=\"image\" data-image-layout=\"one-horizontal\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.images : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  \n    <span class='banned-overlay hidden'>\n        <span class=\"banned-messaging hide\"></span>\n    </span>\n    <span contenteditable='false' class=\"on-remove-image\">\n      <span class=\"play fa-stack fa-lg\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled","auto","wp-neutral-1 fa-stack-2x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",18,"wp-neutral-5 fa-stack-1x",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </span>\n    </span>\n   \n  </p>\n  <figcaption contenteditable=\"false\">\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",24,"wp-error-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Oops! This image does not follow our content guidelines. To continue publishing, please remove it or upload a different image.",{"name":"trans","hash":{},"data":data}))
    + "\n  </figcaption>\n</figure>\n";
},"useData":true});