import storyRanking from "core/templates/story-details/story-ranking.hbs";
(function(_, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "StoryRanking",
    app.views.Base.extend({
      id: "story-ranking",

      template: storyRanking,

      events: {
        "tap .on-rank-navigate": "onRankNavigate",
        "click .on-rank-navigate": "stopEvent"
      },

      // Used for styling
      className: function() {
        var names = "container";

        if (app.get("device").is.mobile) {
          names += " mobile";
        }
        return names;
      },

      initialize: function() {
        var tags = this.model
          .get("tagRankings")
          .map(function(tag) {
            return tag.name + "|" + tag.rank;
          })
          .join("|");

        window.te.push("event", "tag_ranking", null, null, "view", {
          storyid: this.model.get("id"),
          tags: tags
        });
      },

      render: function() {
        var tagRankings = _.cloneDeep(this.model.get("tagRankings")),
          topRank = tagRankings[0];

        tagRankings.shift();

        var otherRankings = tagRankings,
          templateData = {
            storyTitle: this.model.get("title"),
            storyUrl: this.model.get("url"),
            topRank: topRank,
            otherRankings: otherRankings,
            isDesktop: app.get("device").isDesktop(),
            testGroups: wattpad.testGroups
          };

        this.$el.html(this.template(templateData));
        return this;
      },

      onRankNavigate: function(evt) {
        var $tag = $(evt.currentTarget);

        window.te.push("event", "tag_ranking", "ranking", null, "click", {
          storyid: this.model.get("id"),
          tag: $tag.data("name"),
          rank: $tag.data("rank")
        });

        this.onNavigate(evt);
      }
    })
  );
})(_, jQuery, wattpad, wattpad.utils, window.app);
