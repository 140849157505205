(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "TagModules",
    app.collections.IncrementalFetch.extend({
      model: app.models.TagModuleItem,

      arrayKey: "modules",

      fields: [
        {
          modules: ["type", "data"]
        }
      ],

      // Match URL path name to module type i.e. /hot
      typeByPathName: {
        hot: "hotStoriesForTag",
        new: "newStoriesForTag",
        writers: "relatedWritersForTag",
        lists: "relatedReadingListsForTag"
      },

      initialize: function(models, options) {
        options = options || {};
        if (!options.tags) {
          throw new Error(
            "A tag module collection needs a tag(s) to be passed in"
          );
        } else {
          this.tags = options.tags; // A string of comma delimited tags
        }
        this.user = options.user || wattpad.utils.currentUser();
        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      resource: function() {
        return (
          "user." +
          (this.user.get("username") || "anonymous") +
          "." +
          this.tags +
          ".tag-modules"
        );
      },

      parse: function(response) {
        return app.collections.IncrementalFetch.prototype.parse.call(
          this,
          response
        );
      },

      url: function() {
        if (wattpad.utils.currentUser().authenticated()) {
          return (
            "/v5/discover/tags?tags=" +
            encodeURIComponent(this.tags) +
            "&language=" +
            app.get("language")
          );
        } else {
          return (
            "/v5/discover/tags?tags=" +
            encodeURIComponent(this.tags) +
            "&language=" +
            app.get("language")
          );
        }
      },

      getTypeByUrlPath: function(pathName) {
        return this.typeByPathName[pathName];
      }
    })
  );
})(window, _, wattpad, window.app);
