(function(window, _, wattpad, utils, app) {
  "use strict";

  app.add(
    "CategoryModules",
    app.collections.BaseCollection.extend({
      model: app.models.CategoryModuleItem,

      arrayKey: "modules",
      fields: { modules: ["type", "data"] },

      typeByPathName: {
        hot: "hotStoriesForCategory",
        new: "newStoriesForCategory",
        featured: "featuredStoriesForCategory"
      },

      initialize: function(models, options) {
        options = options || {};
        this.categoryId = options.categoryId;
      },

      resource: function() {
        return (
          "user." +
          (utils.currentUser().get("username") || "anonymous") +
          ".category.modules." +
          this.categoryId
        );
      },

      url: function() {
        return "/v5/discover/categories/" + this.categoryId;
      },

      getTypeByUrlPath: function(pathName) {
        return this.typeByPathName[pathName];
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
