var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.readCount : stack1),{"name":"count","hash":{},"data":data}))
    + " </span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <span> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.voteCount : stack1),{"name":"count","hash":{},"data":data}))
    + " </span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.inLibrary : stack1),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "");
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <button class=\"btn btn-sm btn-teal on-remove-from-library\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",14,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Added to Library",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "        <button class=\"btn btn-sm btn-white on-add-to-library\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",14,"wp-base-2-dark",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add to Library",{"name":"trans","hash":{},"data":data}))
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, buffer = 
  "<div class=\"story-meta\">\n  <a class=\"on-navigate cover cover-sm\"\n    href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.url : stack1), depth0))
    + "\">\n      <img src=\""
    + alias2((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.cover : stack1),50,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n  </a>\n  <div class=\"clearfix\">\n    <a class=\"on-navigate alt-link\"\n      href=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.url : stack1), depth0))
    + "\">\n      <strong class=\"title\">"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1), depth0))
    + "</strong>\n    </a>\n\n    <div class=\"subtitle meta\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.readCount : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.voteCount : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n";
  stack1 = ((helper = (helper = helpers.isLoggedIn || (depth0 != null ? depth0.isLoggedIn : depth0)) != null ? helper : alias4),(options={"name":"isLoggedIn","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias3,options) : helper));
  if (!helpers.isLoggedIn) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "  </div>\n</div>\n";
},"useData":true});