import retargeting from "core/templates/retargeting/retargeting.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RetargetingLanding",
    app.views.Base.extend({
      template: retargeting,

      id: "retargeting-landing",

      events: {
        "tap   .on-signup": "loadSignupModal",
        "click .on-signup": "stopEvent"
      },

      render: function() {
        var data = {
          isMobile: app.get("device").is.mobile,
          languageId: parseInt(app.get("language"), 10)
        };
        this.$el.html(this.template(data));
        _.defer(this.afterRender.bind(this));
        return this;
      },

      afterRender: function() {
        if (app.get("device").is.mobile) {
          this.initCarousel();
        }
      },

      loadSignupModal: function(evt) {
        var view = new app.views.SignUpPrompt({
          model: new app.models.Authsignup(),
          title: wattpad.utils.trans("Join Wattpad"),
          formType: "navbar_modal",
          form: "signup"
        });
        $("#generic-modal .modal-content").addClass("auth-modal");
        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal").modal("show");
        $("#generic-modal").one("shown.bs.modal", function() {
          $(this)
            .find("[autofocus]")
            .focus();
        });
      },

      initCarousel: function() {
        var options = {
          nav: true,
          navText: [
            '<span class="fa fa-left fa-wp-neutral-2 carousel-left"></span>',
            '<span class="fa fa-right fa-wp-neutral-2 carousel-right"></span>'
          ],
          //Autoplay
          autoPlay: 5000,
          stopOnHover: false
        };

        if (utils.rtlLanguage(parseInt(app.get("language"), 10))) {
          _.extend(options, { rtl: true });
        }

        if (app.get("device").is.mobile) {
          _.extend(options, {
            responsive: {
              0: { items: 1 },
              600: { items: 2 },
              900: { items: 3 }
            }
          });
        } else {
          _.extend(options, {
            items: 2,
            autoWidth: true, // don't let the carousel adjust the width of the items
            margin: 30 // margin-right(px) on item
          });
        }

        // Unhide carousel items once they're loaded
        this.$(".owl-carousel").one("initialized.owl.carousel", function() {
          $(this).removeClass("invisible");
        });

        this.$(".owl-carousel.no-loop, .owl-carousel.users").owlCarousel(
          options
        );

        // Loop items in modules except for the users' module
        _.extend(options, { loop: true });
        this.$(".owl-carousel:not(.no-loop):not(.users)").owlCarousel(options);
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
