(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "SubscriptionCheckout",
    app.views.DummyReactView.extend({
      component: "SubscriptionPurchaser",

      componentId: "subscription-purchaser",

      constructor: function() {
        var locale = wattpad.utils.getCookie("locale");

        this.componentData = {
          locale: locale,
          sendStripeToken: this.sendStripeToken.bind(this),
          createSubscription: this.createSubscription.bind(this),
          username: utils.getCurrentUserAttr("username")
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      sendStripeToken: function(token, email) {
        // TODO: hit the API
        return Promise.resolve();
      },

      createSubscription: function(sku) {
        // TODO: hit the API
        return new Promise(function(resolve, reject) {
          setTimeout(resolve, 5000); // simulate API loading time
        });
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
