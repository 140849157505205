(function(window, _, wattpad, app) {
  "use strict";

  //TODO: This should extend off of app.collections.StoryGroup but the API is
  //not formatted for us to do that
  app.add(
    "SimilarStories",
    app.collections.BaseCollection.extend({
      baseStoryId: null,

      model: app.models.StoryModel,

      fields: [
        "id",
        "title",
        "voteCount",
        "readCount",
        "createDate",
        "lastPublishedPart",
        {
          user: ["name", "avatar"]
        },
        "cover",
        "url",
        "numParts",
        "isPaywalled",
        "paidModel",
        "completed",
        "mature"
      ],

      //=== MONACO ===//
      resource: function() {
        return "group." + this.baseStoryId + ".similar";
      },
      cacheLocal: false,

      initialize: function(collection, options) {
        options = options || {};

        this.baseStoryId = options.storyId;
        this.limit = options.limit || 3;
        this.baseStory = options.baseStory || {};

        //Only need description for story-landing
        if (options.showDescription) {
          this.fields.push("description");
        }
      },

      fetch: function(options) {
        options = options || {};

        options.data = _.extend(
          {
            limit: this.limit
          },
          options.data || {}
        );

        return app.collections.BaseCollection.prototype.fetch.call(
          this,
          options
        );
      },

      parse: function(res) {
        return _.filter(res, function(story) {
          return story.user && story.cover; // deleted story <=> does not have user and cover?
        });
      },

      url: function() {
        return "/api/v3/stories/" + this.baseStoryId + "/recommended";
      }
    })
  );
})(window, _, wattpad, window.app);
