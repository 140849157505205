var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<a class=\"cover "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.coverSize : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + " empty-cover\">\n    <div class=\"fixed-ratio fixed-ratio-cover\">\n    </div>\n</a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.coverSize || (depth0 != null ? depth0.coverSize : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"coverSize","hash":{},"data":data}) : helper)));
},"4":function(container,depth0,helpers,partials,data) {
    return "cover-home";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<a class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecentlyRead : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(9, data, 0),"data":data})) != null ? stack1 : "")
    + "cover "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.coverSize : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n    <div class=\"fixed-ratio fixed-ratio-cover\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(15, data, 0),"inverse":container.program(31, data, 0),"data":data})) != null ? stack1 : "");
},"7":function(container,depth0,helpers,partials,data) {
    return "on-continue-reading ";
},"9":function(container,depth0,helpers,partials,data) {
    return "on-story-preview ";
},"11":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),142,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.title : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "            <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),92,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.title : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"15":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"content\" data-target=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"info\">\n            <a class=\"title meta "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecentlyRead : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),(depth0 != null ? depth0.titleCharLimit : depth0),false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</a>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hideAuthor : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"meta social-meta\">\n            <span class=\"read-count\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",15,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n            </span>\n\n            <span class=\"vote-count\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(27, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",15,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "\n            </span>\n\n            <span class=\"part-count\"\n"
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(29, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-list",15,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"count","hash":{},"data":data}))
    + "\n            </span>\n        </div>\n    </div>\n";
},"16":function(container,depth0,helpers,partials,data) {
    return "on-continue-reading";
},"18":function(container,depth0,helpers,partials,data) {
    return "on-story-preview";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a class=\"username meta on-navigate\" href=\"/user/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.program(23, data, 0),"data":data})) != null ? stack1 : "")
    + "            </a>\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),14,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),14,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  data-toggle=\"tooltip\" data-placement=\"top\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Read","%s Reads",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n                ";
},"27":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  data-toggle=\"tooltip\" data-placement=\"top\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Vote","%s Votes",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n                ";
},"29":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                  data-toggle=\"tooltip\" data-placement=\"top\" title=\""
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Part","%s Parts",(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"format","hash":{},"data":data}),{"name":"ngettext","hash":{},"data":data}),(helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"format","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + "\"\n                ";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"content\" data-target=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"info\">\n            <a class=\"title meta "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isRecentlyRead : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + alias4((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.title : depth0),(depth0 != null ? depth0.titleCharLimit : depth0),false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</a>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hideAuthor : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(32, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n\n        <div class=\"meta social-meta\">\n            <span class=\"read-count\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n            <span class=\"vote-count\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n            <span class=\"part-count\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-list",12,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"count","hash":{},"data":data}))
    + " </span>\n        </div>\n    </div>\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <a class=\"username meta on-navigate\" href=\"/user/"
    + container.escapeExpression(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),{"name":"if","hash":{},"fn":container.program(33, data, 0),"inverse":container.program(35, data, 0),"data":data})) != null ? stack1 : "")
    + "            </a>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.fullname : stack1),13,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"by %s",(helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),13,false,{"name":"simpleShorten","hash":{},"data":data}),{"name":"trans","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.empty : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"useData":true});