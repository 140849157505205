(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "FeaturedStories",
    app.collections.IncrementalFetchNextUrl.extend({
      category: null,

      model: app.models.StoryModel,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "tags",
            {
              user: ["name", "avatar"]
            },
            "description",
            "cover",
            "completed",
            "rating",
            "mature",
            "url",
            "numParts",
            "modifyDate",
            "categories",
            "firstPartId"
          ]
        },
        "total",
        "nextUrl"
      ],

      resource: function() {
        return "stories.featured";
      },

      defaultUrl: function() {
        return "/api/v3/stories?filter=featured";
      }
    })
  );
})(window, _, wattpad, window.app);
