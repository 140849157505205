(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:getmobile",
    app.router.filter(["getTranslations"], function() {
      var getMobileView = new app.views.GetMobile({
        model: new app.models.GetMobile({})
      });

      app.transitionTo(getMobileView, {
        hideAddressBar: false,
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
