(function(window, app) {
  "use strict";

  // this is needs to be the very FIRST route
  app.router.add({
    ":others": [
      "error-404",
      {
        regexp: {
          others: /.*/
        }
      }
    ]
  });
})(window, window.app);
