import newMessageNew from "core/templates/new-message-new.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "NewMessageNew",
    app.views.NewPost.extend({
      className: "message-new new-message-new",
      template: newMessageNew,
      broadcastLimit: 3,
      blurTimeout: null,

      events: _.extend(app.views.NewPost.prototype.events, {
        "change .on-broadcast-checkbox": "onCheckboxSelected",
        "tap .broadcast-info": "onBroadcastInfo"
      }),

      constructor: function(options) {
        options = options || {};

        if (options.canBroadcast) {
          this.id = "broadcast-container";
        }
        app.views.NewPost.prototype.constructor.apply(this, arguments);
      },

      initialize: function(options) {
        app.views.NewPost.prototype.initialize.apply(this, arguments);
        options = options || {};

        if (typeof options.to !== "string" || options.to.trim() === "") {
          throw new Error("NewMessageNew View : options.to cannot be null");
        }

        this.to = options.to; //Who's message board am I posting to?
        this.parentId = options.parentId || null;
        this.placeholder = options.placeholder || "";
        this.canBroadcast = options.canBroadcast || false;
        this.autoMention = options.autoMention || null;
        this.additionalText = options.additionalText || null;

        try {
          this.unsent = JSON.parse(localStorage.getItem("unsent-repsonse"));
          this.unsent =
            this.unsent.id === this.parentId ? this.unsent.body : void 0;
        } catch (err) {
          // Local storage failure, we don't care.
        }
      },

      render: function() {
        var data = {};
        data.avatar = wattpad.utils.currentUser().get("avatar");
        data.canBroadcast = this.canBroadcast;
        data.placeholder = this.unsent || this.placeholder;

        this.$el.html(this.template(data));
        this.defaultText = this.$("textarea").val();
        if (utils.isOperaMini) {
          this._setActiveState();
        }

        _.defer(this.afterRender.bind(this));

        return this;
      },

      afterRender: function() {
        if (this.canBroadcast) {
          $(".on-broadcast-info").tooltip({
            title: utils.trans(
              "By using this feature, this message will be sent to all your followers’ notification feeds. Please note that you can only post up to %s announcements per day.",
              this.broadcastLimit
            )
          });
        }
      },

      // Mobile-web only: no hover state
      // Show tooltip on click
      onBroadcastInfo: function(evt) {
        utils.stopEvent(evt);
        this.$(".on-broadcast-info").tooltip("toggle");
      },

      isBroadCast: function() {
        return Boolean(this.$("input[type='checkbox']").prop("checked"));
      },

      _setActiveState: function() {
        var toSelf =
            this.autoMention === wattpad.utils.currentUser().get("username"),
          message =
            this.autoMention && !toSelf ? "@" + this.autoMention + " " : "",
          self = this;

        this.$("textarea")
          .val(this.unsent || message)
          .addClass("active");

        window.setTimeout(function() {
          self
            .$(".post-ui")
            .removeClass("hidden")
            .children("button")
            .prop("disabled", self.unsent ? false : true);
        }, 250);
      },

      onTextInputBlur: function() {
        var self = this;
        if (
          !this.$("textarea").val() ||
          this.$("textarea")
            .val()
            .trim() ===
            "@" + this.autoMention
        ) {
          this.blurTimeout = window.setTimeout(function() {
            self._setInactiveState();
          }, 500);
        }
      },

      addMention: function(name) {
        app.views.NewPost.prototype.addMention.apply(this, arguments);
        this.autoMention = name;
      },

      onCheckboxSelected: function(evt) {
        window.clearTimeout(this.blurTimeout);
        //Checking off the checkbox
        if (this.isBroadCast()) {
          this.onTextInputFocus();
          this.$("#broadcast-submit").text(utils.trans("Announce"));

          // Display once
          try {
            if (!JSON.parse(localStorage.getItem("shownAnnouncementModal"))) {
              this.showAnnouncementModal();
              localStorage.setItem("shownAnnouncementModal", true);
            }
          } catch (e) {
            this.showAnnouncementModal();
          }

          //Unchecking the checkbox
        } else {
          this.onTextInputBlur();
          this.$("#broadcast-submit").text(utils.trans("Post"));
        }
      },

      showAnnouncementModal: function() {
        var modal = new app.views.DummyReactView({
          component: "AnnouncementModal",
          componentId: "announcement-modal"
        });
        modal.render();

        $("#generic-modal .modal-body").html(modal.$el);
        $("#generic-modal").modal("show");
      },

      onPost: function(evt) {
        utils.stopEvent(evt);

        //Hide any prior warnings
        this.$(".warning").addClass("hidden");

        var body = $.trim(this.$("textarea").val()),
          button = this.$(evt.currentTarget),
          newMessageParam,
          newMessageModel,
          self = this;

        if (body === "") {
          return; // nothing to post
        }

        if (!wattpad.utils.currentUser().authenticated()) {
          // user can post
          try {
            localStorage.setItem(
              "unsent-repsonse",
              JSON.stringify({ body: body, id: this.parentId })
            );
          } catch (err) {
            // If the user doesn't have local storage, there's not much we can do.
          }
          app.router.navigate("/login?nexturl=" + window.location.href, {
            trigger: true
          });
        } else {
          localStorage.removeItem("unsent-repsonse");
          this.unsent = void 0;
        }

        // disable post button until response
        button.prop("disabled", true);

        newMessageModel = new app.models.Message({ ownername: this.to });

        newMessageParam = {
          broadcast: this.isBroadCast(),
          parent_id: this.parentId,
          body: this.additionalText ? body + this.additionalText : body
        };

        newMessageModel.save(newMessageParam, {
          success: function(model, response, options) {
            self.trigger("message:new", model);
            self
              .$("textarea")
              .val("")
              .blur();

            self.$("input[type='checkbox']").prop("checked", false);
            self.$("#broadcast-submit").text(utils.trans("Post"));
            button.prop("disabled", false);
          },
          error: function(model, response, options) {
            var errorMessage;
            if (response) {
              if (response.responseJSON) {
                errorMessage = response.responseJSON.error.message;
              } else {
                errorMessage = response.statusText;
              }
            } else {
              errorMessage = wattpad.utils.trans(
                "There was a problem posting your message, please try again later."
              );
            }
            self.onError(errorMessage);

            throw new Error(
              "MessageNew View" +
                " | status: " +
                (options
                  ? options.xhr
                    ? options.xhr.status
                      ? options.xhr.status
                      : "none"
                    : "none"
                  : "none") +
                " | response: " +
                (response ? JSON.stringify(response) : "none") +
                " | isBroadcast: " +
                self.isBroadcast()
            );
          }
        });
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
