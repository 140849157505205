import authForm from "core/templates/authentication/auth-form.hbs";
import signupPrompt from "core/templates/signup-prompt.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  // Subview used to embed the signup prompt on pages
  //
  app.add(
    "SignUpPrompt",
    app.views.Base.extend({
      className: "signup-modal login-modal",
      template: signupPrompt,

      events: {
        // links
        "tap    .on-pw-toggle": "onPasswordToggle",

        "tap    .on-close": "onClose",
        "click  .on-close": "stopEvent",

        "tap    .on-password-forgot": "onPasswordForgot",
        "click  .on-password-forgot": "stopEvent",

        "tap    .on-signup": "onSignup",
        "click  .on-signup": "stopEvent",

        "tap    .on-login": "onLogin",
        "click  .on-login": "stopEvent",

        // AUDI-751 to trigger validation when leaving a picker and not choosing a value
        "blur     select": "onBlurPicker"
      },

      initialize: function(options) {
        options = options || {};

        // Retrieve the URL the user should be directed to after they have logged in
        this.nextUrl = wattpad.utils.getNextUrl(
          options.nextUrl,
          window.location.href
        );
        this.nextUrlFirstPart = options.nextUrlFirstPart;
        this.title = options.title || null;
        this.subtitle = options.subtitle || null;
        this.bulletPoints = options.bulletPoints || null;
        this.form = options.form || "signup";
        this.forcedSignup = options.forcedSignup || false;
        this.focus = options.focus === false ? options.focus : true;

        // modals will always be generated via CSR but cant garuntee that for SSR so default to sidebar
        this.formType = options.formType || "sidebar";

        this.skipOnboarding = options.skipOnboarding || false;
        this.libraryStoryId = options.libraryStoryId || null;

        Handlebars.registerPartial("core.authentication.auth_form", authForm);

        $("#generic-modal").one("hidden.bs.modal", function(evt) {
          $("#generic-modal .modal-dialog").removeClass(
            "login-modal-wrapper signup-modal-wrapper"
          );
        });
      },

      render: function() {
        this.signupAction =
          "https://" +
          window.location.hostname +
          (window.location.port ? ":" + window.location.port : "") +
          "/" +
          this.form;

        var templateOptions = {
          title: this.title,
          subtitle: this.subtitle,
          bulletPoints: this.bulletPoints,
          signup: this.form === "signup",
          login: this.form === "login",
          forgot: this.form === "forgot",
          nextUrl: this.nextUrl,
          nextUrlFirstPart: this.nextUrlFirstPart,
          signupFrom: wattpad.utils.getCookie("signupFrom"),
          signupAction: this.signupAction,
          formType: this.formType,
          modal: this.formType.indexOf("modal") !== -1,
          skipOnboarding: this.skipOnboarding,
          libraryStoryId: this.libraryStoryId,
          forcedSignup: this.forcedSignup,
          model: new app.models.Authsignup({}),
          focus: this.focus
        };

        this.$el.html(this.template(templateOptions));
        if (this.focus) {
          this.$el.find("[autofocus]").focus();
        }
        _.defer(this.afterRender.bind(this));

        return this;
      },

      afterRender: function() {
        var $dobTooltip = this.$(".on-dob-hover");
        $dobTooltip.tooltip({
          trigger: "click hover manual",
          title:
            "We ask for your date of birth so you can have the right Wattpad experience for your age."
        });
      },

      onPasswordToggle: function(evt) {
        var $switch = $(evt.currentTarget),
          $pwInput = $switch.siblings("input"),
          type = $pwInput.attr("type");

        window.clearTimeout(this.timeout);

        if (type === "password") {
          $pwInput.attr("type", "text").focus();
          $switch.addClass("active");
        }

        if (type === "text") {
          $pwInput.attr("type", "password").focus();
          $switch.removeClass("active");
        }
      },

      onClose: function(evt) {
        wattpad.utils.setCookie("prSu", true, 0, null);
        $("#generic-modal").modal("hide");
        $("#generic-modal .modal-dialog").removeClass(
          "login-modal-wrapper signup-modal-wrapper"
        );
      },

      onSignup: function(evt) {
        this.title = wattpad.utils.trans("Join Wattpad");
        this.form = "signup";
        this.render();
        wattpad.utils.stopEvent(evt);
      },

      onLogin: function(evt) {
        this.title = wattpad.utils.trans("Log in to Wattpad");
        this.form = "login";
        this.render();
        wattpad.utils.stopEvent(evt);
      },

      // Remember the username/email entered in the '#login-user' text box and use
      // it to autofill the forgot-password form.
      onPasswordForgot: function(evt) {
        var login = $("#login-user").val();

        this.title = wattpad.utils.trans("Lost your password?");
        this.subtitle = wattpad.utils.trans(
          "Type your username or email below and we'll send you instructions on how to reset it."
        );
        this.form = "forgot";
        this.render();

        $("#forgot-email").prop("value", login);
      },

      onBlurPicker: function(evt) {
        var picker = $(evt.currentTarget),
          value = picker.val(),
          attribute = picker.closest(".form-group").data("attr");

        if (value === null) {
          this.model.validate(attribute);
        }
      }
    })
  );

  app.mixin(app.views.SignUpPrompt, "ValidationView", "FacebookConnect");
})(window, _, wattpad, window.app);
