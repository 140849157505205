(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:discover",
    app.router.filter(["getTranslations", "getCategories"], function() {
      var collection = new app.collections.Discover();
      var view = new app.views.DiscoverLanding({ collection: collection });

      Promise.resolve(collection.fetch()).then(function() {
        app.transitionTo(view, {
          hasHeader: true,
          hasFooter: true,
          pageTitle: wattpad.utils.trans("Discover")
        });
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
