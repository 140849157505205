"use strict";

const SPACE_TAG_IDENTIFIER = encodeURIComponent(" #");

/**
 * This function takes a list of related tags and uses a list of #tags to either:
 * * mark an existing matching tag with an 'active' attribute
 * * insert a new tag with an 'active' attribute
 *
 * @param {Array<{id: String, name: String>} relatedTags a list of tags related to a story.
 * @param {Array<String>} tags a list of tags stripped from the query string
 */
const updateWithActiveTags = (relatedTags, tags) => {
  if (!Array.isArray(relatedTags)) {
    throw new TypeError("relatedTags must be an array");
  }

  tags.reverse().forEach(tag => {
    tag = tag.slice(1);

    const matchingTagIndex = relatedTags.findIndex(i => i.name === tag);
    if (matchingTagIndex < 0) {
      relatedTags.unshift({ id: tag, name: tag, active: true });
    } else {
      relatedTags[matchingTagIndex].active = true;
    }
  });
};

const validateString = (value, parameterName) => {
  if (typeof value !== "string" || !value.length) {
    throw new TypeError(`${parameterName} must be a non empty string`);
  }
};

/**
 * Removes the tagName to the provided URL
 *
 * @param {String} url
 * @param {String} tagName
 * @returns {String}
 */
const removeTagFromUrl = (url, tagName) => {
  validateString(url, "url");
  validateString(tagName, "tagName");
  return url.replace(
    new RegExp(
      SPACE_TAG_IDENTIFIER + tagName + "(?=" + SPACE_TAG_IDENTIFIER + "|$)",
      "g"
    ),
    ""
  );
};

/**
 * Returns the provided URL with the provided tagName added onto the end
 *
 * @param {String} url
 * @param {String} tagName
 * @returns {String}
 */
const addTagToUrl = (url, tagName) => {
  validateString(url, "url");
  validateString(tagName, "tagName");

  return url + SPACE_TAG_IDENTIFIER + tagName;
};

/**
 * This function takes the provided URL and generates a link attribute for each tag in the provided tags array.
 * For tags that are not marked as active, their link will be generated, inserting the current tag name from the URL.
 * For tags that are marked as active, their link will be generated, removing the current tag name from the URL.
 *
 * @param {Array<{id: String, name: String, active: Boolean>} relatedTags a list of tags related to a story.
 * @param String url a string representing the currentl URL used to generate the link attribute
 * @returns {Array<{id: String, name: String, active: Boolean, link: String>}
 */
const addLinksToTags = (relatedTags, url) => {
  if (!Array.isArray(relatedTags) || !relatedTags.length) {
    throw new TypeError("relatedTags must be an array of tags");
  }

  let tagsWithLinks;
  if (typeof url === "string" && url.length > 0) {
    tagsWithLinks = relatedTags.map(tag => {
      tag.link = tag.active
        ? removeTagFromUrl(url, tag.name)
        : addTagToUrl(url, tag.name);
      return tag;
    });
  } else {
    // no valid url is specified, use fallback category link.
    tagsWithLinks = relatedTags.map(tag => {
      tag.link = `stories/${tag.name}`;
      return tag;
    });
  }

  return tagsWithLinks;
};

/**
 * This function takes the provided list of tags and sorts it so that active tags are at the front of the list.
 *
 * @param {Array<{id: String, name: String, active: Boolean>} relatedTags a list of tags related to a search.
 * @returns {Array<{id: String, name: String, active: Boolean}
 */
const sortActiveTags = relatedTags => {
  if (!Array.isArray(relatedTags)) {
    throw new TypeError("relatedTags must be an array");
  }

  const activeTags = [];
  const inactiveTags = [];
  relatedTags.forEach(tag => {
    if (tag.active) {
      activeTags.push(tag);
    } else {
      inactiveTags.push(tag);
    }
  });
  return [...activeTags, ...inactiveTags];
};

module.exports = {
  updateWithActiveTags,
  addLinksToTags,
  addTagToUrl,
  removeTagFromUrl,
  sortActiveTags
};
