(function(window, _, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "Categories",
    Monaco.Collection.extend({
      resource: function() {
        return "system.categories." + app.get("language");
      },

      url: function() {
        return "/api/v3/categories";
      },

      initialize: function() {
        this.on("sync", function() {
          // update the cached translated text because the lazy load will not be updated until now
          app.set("categories", this);
        });
      },

      comparator: function(a, b) {
        if (a.get("name") > b.get("name")) {
          return 1;
        }

        if (a.get("name") < b.get("name")) {
          return -1;
        }

        // a must be equal to b
        return 0;
      },

      parse: function(response) {
        var tagline = "",
          banner = "",
          bgImage = "";

        _.each(response, function(category) {
          switch (category.id) {
            case 1: //Teen Fiction
              tagline = wattpad.utils.trans(
                "Read about the ins, outs, ups, and downs of the teenage experience. These free books and stories offer the best in teen fiction covering topics like gay and lesbian romance, high school, popularity and young love."
              );
              banner = "teen_fiction.jpg";
              bgImage = "bg_teen_fiction.png";
              break;
            case 2: //Poetry
              tagline = wattpad.utils.trans(
                "Delve into powerful feelings on love, life, romance, teen life, and everything in between. Read the best free poems online, and find your next inspiration."
              );
              banner = "poetry.jpg";
              bgImage = "bg_poetry.png";
              break;
            case 3: //Fantasy
              tagline = wattpad.utils.trans(
                "So you're into stories about magic, unicorns, dragons, thieves, elves and kingdoms? We have you covered. Read fantasy stories anywhere, anytime on Wattpad."
              );
              banner = "fantasy.jpg";
              bgImage = "bg_fantasy.png";
              break;
            case 4: //Romance
              tagline = wattpad.utils.trans(
                "Free books and love stories for any passion or persuasion; from sweet contemporary romance to steamy new adult, young love, sexy romance, to great historical novels. Find the greatest love stories from passionate authors who know about love."
              );
              banner = "romance.jpg";
              bgImage = "bg_romance.png";
              break;
            case 5: //Science Fiction
              tagline = wattpad.utils.trans(
                "Get lost in the speculative Science Fiction stories of the future: dystopian novels, space odysseys, post-apocalyptic worlds from the great authors and writers of today. We have the best in free books and stories online by authors who love to write alien, fantasy, space time travel, and short sci fi."
              );
              banner = "science_fiction.jpg";
              bgImage = "bg_science_fiction.png";
              break;
            case 6: //Fanfiction
              tagline = wattpad.utils.trans(
                "From Supernatural to Harry Potter, One Direction to Percy Jackson, and more. We have the best fic for whatever fandom you're into. Discover your next fanfiction on the site or on your phone with Wattpad."
              );
              banner = "fanfic.jpg";
              bgImage = "bg_fanfiction.png";
              break;
            case 7: //Humor
              tagline = wattpad.utils.trans(
                "Funny stories to keep you cackling. We have the best comedy stories online for adults and teens, whether you like satire, tongue-in-cheek critique, or witty social commentary. "
              );
              banner = "humor.jpg";
              bgImage = "bg_humor.png";
              break;
            case 8: //Mystery / Thriller
              tagline = wattpad.utils.trans(
                "Short mystery stories and thrillers exploring murder and crime investigations, detective work, and uncovering the answers to a problem or mystery. We have the best free mystery and thriller stories online for teens and adults."
              );
              banner = "mystery_thriller.jpg";
              bgImage = "bg_mystery_thriller.png";
              break;
            case 9: //Horror
              banner = "horror.jpg";
              bgImage = "bg_horror.png";
              break;
            case 10: //Classics
              tagline = wattpad.utils.trans(
                "Read your favorite classic novels, stories, and books anywhere. Classic literature are must read stories written over 100 years ago that stand the test of time and are still loved today."
              );
              banner = "classics.jpg";
              bgImage = "bg_classics.png";
              break;
            case 11: //Adventure
              tagline = wattpad.utils.trans(
                "Risk, danger and excitement will fuel your next adventure on Wattpad. Find your quest and get ready for the journey of a lifetime with the best free adventure books and stories online about worldly travel, pirates, heroes, villains, challenges, and more."
              );
              banner = "adventure.jpg";
              bgImage = "bg_adventure.png";
              break;
            case 12: //Paranormal
              tagline = wattpad.utils.trans(
                "Read the best free paranormal romance stories and series with strong female leads, vampires, and werewolf drama. These stories are way beyond the normal."
              );
              banner = "paranormal.jpg";
              bgImage = "bg_paranormal.png";
              break;
            case 13: //Spiritual
              tagline = wattpad.utils.trans(
                "Read powerful spiritual stories about women and healing. These free online stories are spiritual but not necessarily religious, playing on existential themes that make us question who we are and why we’re here."
              );
              banner = "spiritual.jpg";
              bgImage = "bg_spiritual.png";
              break;
            case 14: //Action
              tagline = wattpad.utils.trans(
                "If you love action; adventure, excitement and risk await you. Find your heroes and villains in the best action books and stories for teens and young adults online from author around the world."
              );
              banner = "action.jpg";
              bgImage = "bg_action.png";
              break;
            case 16: //Non-fiction
              tagline = wattpad.utils.trans(
                "Read non-fiction stories by the best original authors on topics ranging from personal growth to exciting memoirs."
              );
              banner = "non_fiction.jpg";
              bgImage = "bg_non_fiction.png";
              break;
            case 17: //Short Stories
              tagline = wattpad.utils.trans(
                "Get lost in short stories about love, life, friendship, and bullying. Our short stories can be funny, sad, or dramatic. They all share a slice of life that everyone can relate to."
              );
              banner = "short_story.jpg";
              bgImage = "bg_short_story.png";
              break;
            case 18: //Vampire
              tagline = wattpad.utils.trans(
                "Read the best in free vampire stories about vampires and romance, chasers, brothers, werewolf vs vampire, gay and lesbian, and more."
              );
              banner = "vampire.jpg";
              bgImage = "bg_vampire.png";
              break;
            case 19: //Random
              tagline = wattpad.utils.trans(
                "Read stories that defy definition. When you’re looking for things to do when you’re bored, top 10 lists, guides, rants, diary entries, contests and stories, you will find your fix on Wattpad Random and never be bored again."
              );
              banner = "random.jpg";
              bgImage = "bg_random.png";
              break;
            case 21: //General Fiction
              tagline = wattpad.utils.trans(
                "Get your contemporary fiction fix with the best free fiction books and stories written by authors from around the world."
              );
              banner = "general_fiction.jpg";
              bgImage = "bg_general_fiction.png";
              break;
            case 22: //Werewolf
              tagline = wattpad.utils.trans(
                "Read free fanfiction and werewolf fiction online by the best authors. These exciting werewolf reads cover stories about true transformation, gay and lesbian themes, scary, creepy, romance, vampires, and more."
              );
              banner = "werewolf.jpg";
              bgImage = "bg_werewolf.png";
              break;
            case 23: //Historical Fiction
              tagline = wattpad.utils.trans(
                "Short stories and free books by exciting authors in historical fiction. Discover the best stories about war, the vikings and fictional period pieces that capture the essence of history recounting the world as it once was."
              );
              banner = "historical_fiction.jpg";
              bgImage = "bg_historical_fiction.png";
              break;
            case 24: //Chick Lit
              tagline = wattpad.utils.trans(
                "From classic British chick lit to dating drama disasters, discover your next favorite chicklit author online or on your phone with Wattpad. We have tons of free stories for you to read wherever you go."
              );
              banner = "chicklit.jpg";
              bgImage = "bg_chicklit.png";
              break;
          }
          category.tagline = tagline;
          category.banner = banner;
          category.bg_image = bgImage;
        });

        return Monaco.Collection.prototype.parse.apply(this, arguments);
      },

      cacheLocal: true,

      expireLocal: 720 // 12 hours
    })
  );
})(window, _, Monaco, wattpad, window.app);
