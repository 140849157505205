var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"notification aggregate follower panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"on-expand\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <div class=\"count\">"
    + alias4(((helper = (helper = helpers.children || (depth0 != null ? depth0.children : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"children","hash":{},"data":data}) : helper)))
    + "</div>\n        <div class=\"content\">\n            <div class=\"heading\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You have <strong>%s new followers</strong>",(depth0 != null ? depth0.children : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<time datetime=\""
    + alias4(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4((helpers.calendar || (depth0 && depth0.calendar) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n        </div>\n    </div>\n</article>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " unread";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing;

  return "<article class=\"notification follower panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <a href=\"/user/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.follower : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm2 on-navigate\">\n        <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias1,((stack1 = (depth0 != null ? depth0.follower : depth0)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <a href=\"/user/"
    + alias3(alias2(((stack1 = (depth0 != null ? depth0.follower : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"on-notification-click content\">\n        <div class=\"heading\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias4).call(alias1,"<strong>%s</strong> followed you",((stack1 = (depth0 != null ? depth0.follower : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias4).call(alias1,"fa-following",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<time datetime=\""
    + alias3(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.calendar || (depth0 && depth0.calendar) || alias4).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n    </a>\n</article>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.children : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});