(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:library-reading-lists",
    app.router.filter(["getTranslations", "getCategories"], function(listId) {
      var view;
      if (isNaN(listId)) {
        view = new app.views.LibraryLanding({ section: "reading-lists" });
        app.transitionTo(view, {
          hasHeader: true,
          hasFooter: true,
          pageTitle: wattpad.utils.trans("Library - Reading Lists")
        });

        if (typeof __atha !== "undefined") {
          var id = __atha.sendPageView("library_reading_lists");
        }
      } else {
        var model = new app.models.ReadingList({ id: listId });
        Promise.resolve(model.fetch()).then(
          function() {
            view = new app.views.CoreLibraryReadingList({ model: model });

            app.transitionTo(view, {
              hasHeader: true,
              hasFooter: true,
              pageTitle: model.get("name") + " - " + model.get("user").name
            });

            window.te.push("event", "app", "page", null, "view", {
              page: "reading_list",
              reading_listid: listId
            });
          },
          function() {
            //  error handling
            app.router.trigger("route:error-404", "list");
          }
        );
      }
    })
  );
})(window, _, jQuery, wattpad, window.app);
