(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * Purpose: canonical way of adding a report modal to a view
   *
   * Requirements:
   *    - `this.reportModalContainer` = selector of the container where the modal element will be injected into
   *        Note: the container must be part of the rendered target view
   *    - `this.reportForm` = object, contains 3 fields
   *        1. `id` = id of the Ticket Form on Zendesk
   *        2. `offenses` = an array of objects that describes the list of
   *                        possible options related to this report form, 3 fields
   *            a. `name` = string, description of the option
   *            b. `tag` = the Zendesk tag of this option
   *            c. `moreDetail` = boolean, if a follow up message from the user is required
   *        3. `title` = title of the report modal
   *    - `this.requestType` = support request type associated with the Ticket Form on Zendesk
   *    - an button in the dom with class `on-report`
   *    - `this.isMainReportModal` = flag to mark a particular view's report modal as the main report modal on a page
   *        Only one view should have this flag set to true on a page.
   *        Used for mobile-web when there's multiple report modals and a footer report button.
   *    - (MW only requirement) in the client controller for a particular view, the call to app.transitionTo should pass
   *        `footerOptions.report = ( footer report button label )`. This field is also used as a flag to indicate that
   *        the particular page has a main report modal to activate by the report button in the footer
   *        Need to be mirrored on the server as well
   *        See mobile-web story-reading client side controller, and story server controller as an example
   * Options:
   *    - implement `this.reportMoreInfo` field that contains or `this.reportMoreInfo()` function that returns
   *        any additional information to be added to the comment of the ticket, ex. username.
   *        Note: this is an array with key value pairs [ { key: 'field-1', val: 'A' }, { key: 'field-2', val: 'B' } ]
   *
   * @mixin ReportManagement
   */
  app.add(
    "ReportManagement",
    Monaco.Mixin.create({
      events: {
        "tap   .on-report": "onReport",
        "click .on-report": "stopEvent"
      },

      render: function(next) {
        var result = next();
        this.renderReportModal();
        return result;
      },

      setElement: function(el, next) {
        next(el);
      },

      renderReportModal: function(noop) {
        noop();
      },

      onReport: function(evt, noop) {
        wattpad.utils.stopEvent(evt);
        // check if report is coming from the inline-comments-modal
        var isInline = (
          $("#inline-comments-modal .inline-comments-modal").data("bs.modal") ||
          {}
        ).isShown; // OR operator to avoid race condition

        this.reportModalContainer = isInline
          ? "#inline-comments-modal .inline-comments-modal"
          : "#generic-modal";

        if (!this.reportModalId.name) {
          var name = this.$(evt.currentTarget).data("username");
          this.reportModalId.name = name;
        }

        var view = new app.views.ReactReportModal({
          reportType: this.reportModalType,
          requesterUsername: wattpad.utils.currentUser().get("username"),
          requesterEmail: wattpad.utils.currentUser().get("email"),
          reportId: this.reportModalId,
          reportModalContainer: this.reportModalContainer
        });

        if (isInline) {
          $(`${this.reportModalContainer} .modal-header`).remove();
        }

        $(`${this.reportModalContainer} .modal-body`).html(view.render().$el);
        $(`${this.reportModalContainer} .modal-content`).addClass(
          "report-modal-wrapper"
        );
        // #inline-comment-modal already has a modal backdrop
        $(this.reportModalContainer).modal({
          backdrop: !isInline
        });

        // if modal is closed by using any means besides the X button,
        // redirect to use ReactReportModal onClose method
        $(this.reportModalContainer).on(
          "hidden.bs.modal",
          view.onClose.bind(view)
        );
        return;
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
