import betaAnnouncement from "platform/components/templates/announcements/beta-announcement.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "BetaProgram",
    app.views.Announcement.extend({
      template: betaAnnouncement,

      state: "default",

      className: app.views.Announcement.prototype.className + " beta",

      events: _.extend(
        {
          "click  .on-joinbeta": "stopEvent",
          "tap    .on-joinbeta": "onJoinBeta"
        },
        app.views.Announcement.prototype.events
      ),

      getData: function() {
        var data = {};
        data[this.state] = true;
        return data;
      },

      onDismiss: function(el) {
        this.remove();
        wattpad.utils.setCookie("nobb", "1");
      },

      onJoinBeta: function(e) {
        wattpad.utils.stopEvent(e);

        var self = this;
        var currentUser = wattpad.utils.currentUser();

        if (currentUser) {
          $.ajax({
            type: "PUT",
            url: "/api/v3/users/" + currentUser.get("username"),
            data: {
              beta_enabled: true
            },
            success: function(data) {
              self.state = data && data.beta_enabled ? "success" : "fail";
              self.render();
            },
            error: function(data) {
              self.state = "fail";
              self.render();
            }
          });
        }
      }
    })
  );
})(window, _, wattpad, window.app);
