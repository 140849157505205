import generateContainerId from "../../../helpers/generate-container-id";

(function(window, _, $, wattpad, utils, app, Monaco) {
  app.add(
    "ReactComponentWrapper",
    Monaco.View.extend({
      initialize: function(
        componentName,
        componentId,
        options,
        handlebarsOptions
      ) {
        options = options || {};
        if (!componentName) {
          return new Error("componentName not provided");
        } else if (!componentId) {
          return new Error("componentId not provided");
        }

        if (!window.app.components[componentName]) {
          return new Error(
            componentName + " is not defined in window.app.components"
          );
        }

        // bind options.model?
        // bind options.collection?
        // bind options.provider?
        this.componentId = componentId;

        // capturing the URL path the component was requested on, to ensure it's still valid during retries.
        this.pathname = window.location.pathname;

        this.containerId = generateContainerId(
          componentName,
          this.componentId,
          utils.urlEncode(this.pathname)
        );

        this.container = this.generateContainer(componentName);

        this.model = options.model;

        this.componentName = componentName;

        this.listenTo(this.model, "change", _.bind(this.render, this));
        return this;
      },

      generateContainer: function(componentName) {
        return $(
          "<div class='component-wrapper' id='" + this.containerId + "'></div>"
        );
      },

      remove: function() {
        if (this.container && this.container[0]) {
          ReactDOM.unmountComponentAtNode(this.container[0]);
        }
        Monaco.View.prototype.remove.apply(this, arguments);
      },

      render: function() {
        this.retryCount = this.retryCount || 0;
        // confirm that our generated container already exists in the DOM (required by React)
        // If the DOM node exists, it was probably SSR rendered so let's use it instead of our generated one
        if (document.getElementById(this.containerId)) {
          this.setElement(document.getElementById(this.containerId));
          this.container = $(document.getElementById(this.containerId));
          _.defer(
            _.bind(function() {
              this.retryCount = 0;

              var component = window.app.components[this.componentName];
              var wrappedComponent = window.app.components.withRedux(
                component,
                window.store
              );

              ReactDOM.render(
                React.createElement(wrappedComponent, this.model.toJSON()),
                document.getElementById(this.containerId)
              );
            }, this)
          );
        } else {
          // if the DOM node doesn't exist, we're likely doing a CSR render, and React components can only be bound to actual
          // mounted DOM nodes, since the amount of time to complete that is non-deterministic we have to retry periodically until it succeeds
          if (this.retryCount < 5) {
            //Hypothesis: We get 'mounting failed' errors because the retry is occuring for page A after we've already navigated to page B
            //Test: Throw a new error if the mount was intended for a different path, if confirmed via Datadog, we can abort the retry.
            if (this.pathname !== window.location.pathname) {
              throw new Error(
                `Attempted to mount component from ${this.pathname} on: ${
                  window.location.pathname
                }`
              );
            }
            _.delay(
              _.bind(function() {
                this.retryCount++;
                this.render();
              }, this),
              this.retryCount * 200
            );
          } else {
            // retried after retrycount, so we should stop trying and throw an error
            throw new Error(this.containerId + " mounting failed.");
          }
          return (
            "<div class='component-wrapper' id='" +
            this.containerId +
            "'></div>"
          );
        }
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
