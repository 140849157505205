import tableOfContents from "platform/story-reading/templates/table-of-contents.hbs";
import setPropsForReactInHb from "../../../../../../helpers/handlebars/set-props-for-react-in-hb";
import publishPartModal from "../../../../../../helpers/publishing/client.publish-part-modal";
import { MODAL_ID } from "../../../../../../components/shared-components";

(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "StoryReadingFunbar",
    app.views.BaseStoryReadingFunbar.extend({
      isDesktop: true,

      events: {
        "mouseenter #funbar-reading-progress": "openProgressTooltip",
        "mouseleave #funbar-reading-progress": "closeProgressTooltip",
        "tap .on-publish": "onPublish",
        "click .on-publish": "stopEvent"
      },

      initialize: function() {
        Handlebars.registerPartial(
          "desktop.story_reading.table_of_contents",
          tableOfContents
        );
        app.views.BaseStoryReadingFunbar.prototype.initialize.apply(
          this,
          arguments
        );
      },

      render: function() {
        var data = this.model.toJSON(),
          self = this;

        this.listenTo(this.model, "reading-progress", this.updateProgress);
        this.listenTo(this.model, "change:pageNumber", this.pageChanged);

        data.showAdminPanel = false;
        data.isAdmin = false;
        data.isDesktop = this.isDesktop;

        if (wattpad.utils.currentUser().get("isSysAdmin")) {
          data.showAdminPanel = true;
          data.isAdmin = true;
        }

        if (wattpad.utils.currentUser().get("ambassador")) {
          data.showAdminPanel = true;
        }

        if (
          wattpad.utils.currentUser().get("username") ===
          data.group.user.username
        ) {
          data.isAuthor = true;
          data.group.url =
            "/myworks/" + data.group.id + "-" + utils.slugify(data.group.title);

          this.publishModel = new app.models.StoryPartCreateModel({
            id: this.model.get("id"),
            authorid: utils.currentUser().get("id"),
            title: this.model.get("title"),
            groupid: this.model.get("group")?.id,
            language: this.model.get("language"),
            copyright: this.model.get("copyright"),
            category1: this.model.get("group")?.categories?.[0],
            textUrl: this.model.get("text_url"),
            url: this.model.get("url"),
            scheduledPublishDatetime: this.model
              .get("group")
              ?.parts?.find(p => p.id == this.model.get("id"))
              ?.scheduledPublishDatetime
          });
        }

        const story = self.model.get("group");

        data.isPreview = this.options ? this.options.isPreview : false;

        setPropsForReactInHb(data, "publishButtonSettings", {
          storyModel: story,
          partModel: self.publishModel,
          isPreview: true,
          onPublish: e => {
            utils.setRedirectParams(window.location.pathname, {
              isPostPublishModal: true
            });
            window.location.reload();
          },
          onSchedule: e => {
            utils.redirectWithParams(
              `/myworks/${self.model.get("group").id}-${utils.slugify(
                self.model.get("title")
              )}`,
              {
                triggerScheduleSuccess: true,
                partId: self.publishModel.get("id")
              }
            );
          },
          onUnschedule: e => {
            window.location.reload();
          }
        });

        var blockedPart = data.blockedPart || {};
        const isPaywalled = this.model.get("group").isPaywalled;
        if (isPaywalled) {
          const walletClick = () => {
            const buyCoinsModal = utils.getModal(MODAL_ID.BUY_COINS_MODAL);
            buyCoinsModal.setData({
              page: "reading",
              storyId: story?.id,
              blockedPart: data?.blockedPart?.id,
              appUrl: utils.getAppUrl(),
              device: app.get("device")
            });
            buyCoinsModal.show();
          };

          data.onWalletClick = walletClick.bind(this);
        }

        this.$el.html(this.template(data));
        this.initAdminPanel(this.model, "#admin-panel");
        _.defer(function() {
          self.$("[data-toggle=tooltip]").tooltip();
        });
        return this;
      },

      stopEvent: function(evt) {
        utils.stopEvent(evt);
      },

      //For the LibraryManagement mixin
      onAddToListsReady: function($btn, storyId) {
        var btnGrp = $btn.parent(".button-group");

        btnGrp.addClass("open");
        $btn.removeClass("on-lists-add-clicked");
        $btn.addClass("on-dismiss-lists");
      },

      onDismissLists: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id"),
          btn = this.$(this.addButton + "[data-story-id='" + storyId + "']"),
          btnGrp = btn.parent(".button-group");

        btnGrp.removeClass("open");
        btn.removeClass("on-dismiss-lists");
        btn.addClass("on-lists-add-clicked");
      },

      openProgressTooltip: function(evt) {
        this.$("#progresstooltip").tooltip("show");
      },

      closeProgressTooltip: function(evt) {
        this.$("#progresstooltip").tooltip("hide");
      },

      _timeToComplete: function(numWords, percentDone) {
        var wordsLeft = numWords * (1 - percentDone),
          wordsPerMinute = 250,
          timeLeft = wordsLeft / wordsPerMinute; // minutes

        return moment.duration(timeLeft, "minutes").humanize();
      },

      updateProgress: function(percent) {
        // minimum CSS width of 0.5% so the progress bar is always visible
        if (percent * 100 > 0.5) {
          this.$("#progressbar").width(percent * 100 + "%");
        } else {
          this.$("#progressbar").removeAttr("style");
        }

        var words = this.model.get("wordCount");
        if (words && percent !== 1) {
          var timeLeft = wattpad.utils.trans(
            "%s left",
            this._timeToComplete(words, percent)
          );
          this.$("#progresstooltip").attr("data-original-title", timeLeft);
        } else {
          this.$("#progresstooltip").removeAttr("data-original-title");
        }
      },

      pageChanged: function() {
        this.openProgressTooltip();
        _.delay(_.bind(this.closeProgressTooltip, this), 1000);
      },

      showPostPublishModal: function() {
        var self = this;
        var postPublishModal = new app.views.PostPublishModal({
          model: self.model,
          links:
            self.model.get("socialShareVisible") || app.currentView.socialLinks
        });
        $("#post-publish-modal")
          .empty()
          .append(postPublishModal.render().$el);
        postPublishModal.showModal();
      },

      onPublish: function(e) {
        var self = this;
        publishPartModal(self.model.get("group"), self.publishModel, true)
          .then(isComplete => {
            if (isComplete) {
              app.local.clear(self.model.resource());
              $(".draft-tag").addClass("hidden");
              self.showPostPublishModal();
            }
          })
          .catch(errors => {
            errors && console.error(errors);
          });
      }
    })
  );

  app.mixin(app.views.StoryReadingFunbar, "StoryAdminManager");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
