import {
  setStorageItem,
  getStorageItem
} from "../../../../../components/helpers";
import { getPrivateMessageFeatureFlags } from "../../../../../helpers/wattpad-backend-feature-flags-helper";
import getFeatureFlags from "../../../../../helpers/get-wattpad-backend-feature-flags";

(function(window, _, $, wattpad, app) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});

  app.router.on(
    "route:userProfile",
    app.router.filter(["getTranslations", "getCategories"], function(
      username,
      section,
      params
    ) {
      let privateMessageFeatureFlags = null;
      let featureFlagsPromise = Promise.resolve();

      const featureFlags = window?.prefetched?.featureFlags;
      if (featureFlags) {
        // always write prefetch, just in case we've somehow re-SSR'd and grabbed some updated feature flags.
        setStorageItem({
          key: "wattpad-backend-feature-flags",
          value: featureFlags,
          type: "sessionStorage"
        });

        privateMessageFeatureFlags = getPrivateMessageFeatureFlags(
          featureFlags
        );
      } else {
        // check localStorage to see if we fetched feature flags already in this session
        const sessionStorageFeatureFlags = getStorageItem({
          key: "wattpad-backend-feature-flags",
          type: "sessionStorage"
        });
        if (sessionStorageFeatureFlags !== null) {
          // get privateMessage feature flags from feature flags.
          privateMessageFeatureFlags = getPrivateMessageFeatureFlags(
            sessionStorageFeatureFlags
          );
        } else {
          // We don't have featureflags any of our sources; make a fresh call and update sessionStorage
          // We pass this promise down to the view, so that once rendering is done, it can wait for
          // resolve and update the button visbility through backbone triggers and JQuery.
          featureFlagsPromise = getFeatureFlags().then(featureFlags => {
            setStorageItem({
              key: "wattpad-backend-feature-flags",
              value: featureFlags,
              type: "sessionStorage"
            });
            privateMessageFeatureFlags = getPrivateMessageFeatureFlags(
              featureFlags
            );
            return privateMessageFeatureFlags;
          });
        }
      }

      var userModel = null;
      // WEB-7096 If params field contains redirectFrom, it means we need to show the toast
      if (
        typeof params === "object" &&
        (params["redirectfrom"] || params["redirectFrom"])
      ) {
        var toast = new app.views.ErrorToast(
          {
            message:
              wattpad.utils.trans(
                "We're sorry! The page you requested no longer exists."
              ) +
              " " +
              wattpad.utils.trans("So we have brought you to a related page.")
          },
          { type: "dismissable" }
        );
        toast.render();
      }

      // default to about if no section is informed
      section = section || "about";

      //Account for whenever there are query params in the url
      //We need to check for 'message'
      if (typeof params === "object") {
        if (params.message) {
          params = null;
        }
      }

      // Model
      userModel = new app.models.User({ username: username });

      if (
        wattpad.utils.currentUser().get("username") &&
        window.store &&
        !window.store.getState().profileData.hasLoadad
      ) {
        window.store.dispatch(
          window.app.components.actions.initializeMutedUsers(
            wattpad.utils.currentUser().get("username")
          )
        );
      }

      Promise.resolve(userModel.loaded()).then(
        function() {
          var isPrivateToMe = !userModel.has("description"); // definitely requested, but not given === private
          let isMuted = userModel?.get("safety")?.isMuted;
          let isBlocked = userModel?.get("safety")?.isBlocked;

          // clear extraneous url
          if (isPrivateToMe) {
            app.router.navigate("/user/" + username, {
              trigger: false,
              replace: true
            });
          }
          const noIndex =
            wattpad.utils.isUnsafe(userModel.get("name")) ||
            wattpad.utils.isUnsafe(userModel.get("username")) ||
            wattpad.utils.isUnsafe(userModel.get("description"));

          if (isBlocked) {
            var blockedView = new app.views.RestrictedProfile({
              profileType: "block",
              model: userModel,
              isMobile: app.get("device").is.mobile
            });

            app.transitionTo(blockedView, {
              username: username,
              hasHeader: true,
              hasFooter: true,
              noIndex: noIndex
            });
          } else if (isMuted) {
            var mutedView = new app.views.RestrictedProfile({
              profileType: "mute",
              model: userModel,
              isMobile: app.get("device").is.mobile
            });

            app.transitionTo(mutedView, {
              username: username,
              hasHeader: true,
              hasFooter: true,
              noIndex: noIndex
            });
          } else {
            var profileView = new app.views.ProfileLayout({
              model: userModel,
              section: !isPrivateToMe ? section : "about",
              username: username,
              isPrivateToMe: isPrivateToMe,
              showEditProfile: params && params.showEditProfile,
              // Promise is needed by view incase response is delayed, so we don't block render.
              featureFlagsPromise,
              privateMessageFeatureFlags
            });

            app.transitionTo(profileView, {
              username: username,
              hasHeader: true,
              hasFooter: true,
              noIndex: noIndex
            });
          }

          window.te.push("event", "app", "page", null, "view", {
            page: "profile",
            username: username
          });
        },
        function() {
          //  error handling
          app.router.trigger("route:error-404", "user");
        }
      );
    })
  );
})(window, _, jQuery, wattpad, window.app);
