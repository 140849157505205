var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression;

  return "         <strong>"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1), depth0))
    + " - "
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1), depth0))
    + "</strong>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "         <strong>"
    + container.escapeExpression(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1), depth0))
    + "</strong>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<header>\n  <a class=\"on-user avatar avatar-sm\"\n     data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n     href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n    <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),32,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n  </a>\n  <div class=\"clearfix\">\n    "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"%s commented on",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\n    <a class=\"on-navigate\"\n       href=\""
    + alias2(alias1(((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.part : stack1)) != null ? stack1.url : stack1), depth0))
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "    </a>\n  </div>\n  <time>"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"dropSuffix":true},"data":data}))
    + "</time>\n</header>\n<hr>\n"
    + ((stack1 = container.invokePartial(partials["core.events.partials.user_event_story_meta"],depth0,{"name":"core.events.partials.user_event_story_meta","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "<br>\n\n<blockquote>"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.comment : stack1)) != null ? stack1.highlighted_text : stack1),200,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</blockquote>\n\n<div class=\"reply\">\n  <header>\n    <a class=\"on-user avatar avatar-sm\"\n       data-username=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\"\n       href='/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "'>\n     <img src=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1), depth0))
    + "\">\n    </a>\n  <div class=\"clearfix title\"> "
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + " </div>\n  </header>\n  <pre>"
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.comment : stack1)) != null ? stack1.body : stack1),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n</div>\n\n"
    + ((stack1 = container.invokePartial(partials["core.events.partials.user_event_footer"],depth0,{"name":"core.events.partials.user_event_footer","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n";
},"usePartial":true,"useData":true});