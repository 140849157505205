var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "      <small class=\"x-small offensive\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",13,"wp-error-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"this message may be\n        offensive",{"name":"trans","hash":{},"data":data}))
    + "</small>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <pre>"
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.body : depth0),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.beautifyUrlMetadata || (depth0 != null ? depth0.beautifyUrlMetadata : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"beautifyUrlMetadata","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.beautifyUrlMetadata) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.shared.beautify_url"],depth0,{"name":"core.shared.beautify_url","data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<div class=\"panel-body message latest-replies reply-new\">\n  <a class=\"avatar avatar-xs2 on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n    <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.avatar : stack1),30,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n  </a>\n\n  <div class=\"message-wrapper\">\n    <h3 class=\"h6 from-name\"><a class=\"username on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias2(alias1(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a></h3>\n    <div class=\"body dark-grey\">\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.isOffensive : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.body : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias3,(depth0 != null ? depth0.beautifyUrlMetadata : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <div class=\"reply-meta\">\n      <time class=\"timestamp\" datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n      <span class=\"bullet\">&bull;</span>\n      <span class=\"reply-wrapper\"><a class=\"on-reply\" role=\"button\" href=\"#\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"Reply",{"name":"trans","hash":{},"data":data}))
    + "</a></span>\n    </div>\n  </div>\n\n  <div class=\"button-group dropdown\">\n    <button class=\"btn btn-text btn-options dropdown-toggle\" data-toggle=\"dropdown\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias4).call(alias3,"fa-more",20,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n    <div class=\"triangle\"></div>\n    <div class=\"dropdown-menu align-right\">\n      "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias4).call(alias3,"CommentOptions",(depth0 != null ? depth0.id : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div> \n  </div>\n\n  <div class=\"message-report-modal-reply\">\n  </div>\n\n</div>\n";
},"usePartial":true,"useData":true});