(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "RecentlyRead",
    app.collections.BaseCollection.extend({
      model: app.models.StoryGroup,

      arrayKey: "stories",

      fields: [
        {
          stories: [
            "id",
            "title",
            "readingPosition",
            "cover",
            "url",
            {
              user: ["name"]
            },
            {
              parts: ["id", "url"]
            }
          ]
        }
      ],

      initialize: function(collection, options) {
        options = options || {};
        if (!options.username) {
          throw new Error(
            "Recently Read Collection: options.username cannot be null"
          );
        }
        this.username = options.username;

        app.collections.BaseCollection.prototype.initialize.apply(
          this,
          arguments
        );
      },

      resource: function() {
        return "user." + this.username + ".recently-read";
      },

      url: function() {
        return "/v4/users/" + this.username + "/recent_reads";
      }
    })
  );
})(window, _, wattpad, window.app);
