var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"user-badge\" data-toggle=\"tooltip\" data-placement=\"right\" title=\""
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Staff",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"staff",(depth0 != null ? depth0.badgeSize : depth0),"wp-base-1","","viewBox=\"0 0 20 20\"",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"user-badge\" data-toggle=\"tooltip\" data-placement=\"right\" title=\""
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Verified",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"verified",(depth0 != null ? depth0.badgeSize : depth0),"wp-base-1","","viewBox=\"0 0 20 20\"",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <div class=\"user-badge\" data-toggle=\"tooltip\" data-placement=\"right\" title=\""
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Ambassador",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"ambassador",(depth0 != null ? depth0.badgeSize : depth0),"wp-base-1","","viewBox=\"0 0 20 20\"",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.staff : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.verified : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.ambassador : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});