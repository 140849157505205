var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<div id=\"follower-modal\"></div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"BuyCoinsReactModal","buy-coins-modal",(depth0 != null ? depth0.buyCoinsModal : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n  \n";
},"5":function(container,depth0,helpers,partials,data) {
    return "<header class=\"background background-lg\" style=\"background-image: url( '"
    + container.escapeExpression((helpers.profileBackground || (depth0 && depth0.profileBackground) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.backgroundUrl : depth0),1920,{"name":"profileBackground","hash":{},"data":data}))
    + "' );\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  <header class=\"background background-lg\" "
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.highlight_colour : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var helper;

  return "style=\"background-color: "
    + container.escapeExpression(((helper = (helper = helpers.highlight_colour || (depth0 != null ? depth0.highlight_colour : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"highlight_colour","hash":{},"data":data}) : helper)))
    + "\"\n    ";
},"10":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <h1 class=\"profile-name h3\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%s's profile",(depth0 != null ? depth0.name : depth0),{"name":"trans","hash":{},"data":data}))
    + "\">\n        "
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n      </h1>\n";
},"12":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <h1 class=\"profile-name h3\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%s's profile",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data}))
    + "\">\n        "
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\n      </h1>\n";
},"14":function(container,depth0,helpers,partials,data) {
    return "scroll-to-element";
},"16":function(container,depth0,helpers,partials,data) {
    return "on-followers";
},"18":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "data-toggle=\"tooltip\" data-placement=\"top\"\n        title=\""
    + alias3((helpers.format || (depth0 && depth0.format) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"format","hash":{},"data":data}))
    + " "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Followers",{"name":"trans","hash":{},"data":data}))
    + "\" ";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "          <nav>\n            <ul>\n              <li data-section=\"about\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_about : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"on-nav-item\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"About",{"name":"trans","hash":{},"data":data}))
    + "</a>\n              </li>\n              <li data-section=\"conversations\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_activity : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/conversations\" class=\"on-nav-item\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Conversations",{"name":"trans","hash":{},"data":data}))
    + "</a>\n              </li>\n              <li data-section=\"following\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_following : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/following\" class=\"on-nav-item\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</a>\n              </li>\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </ul>\n          </nav>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return "active";
},"23":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || helpers.helperMissing).call(alias1,"PROFILE_QUESTS",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"24":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "              <li data-section=\"quests\" class=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.active_quests : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n                <a href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/quests\" class=\"on-nav-item\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Quests",{"name":"trans","hash":{},"data":data}))
    + "</a>\n              </li>\n";
},"26":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showAdminOptions : depth0),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(29, data, 0),"data":data})) != null ? stack1 : "");
},"27":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <a class=\"btn btn-red\" role=\"menuitem\"\n              href=\"/user/"
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-lock",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n            <div class=\"button-group relative inline-block\">\n\n            <button class=\"btn btn-red dropdown-toggle remove-image\" data-toggle=\"dropdown\">Remove Image</button>\n             <div class=\"triangle\"></div>\n\n            <ul class=\"dropdown-menu dropdown-menu-right\">\n              <li> \n                <a class=\"on-change-invalid-image\"  href=\"#\" data-imagetype=\"avatar\">\n                  Avatar\n                </a>\n              </li>\n              <li> \n                <a class=\"on-change-invalid-image\" href=\"#\" data-imagetype=\"banner\">\n                   Background\n                </a>\n              </li>\n              <li> \n                <a class=\"on-change-invalid-image\" href=\"#\" data-imagetype=\"both\">\n                   Avatar and Background\n                </a>\n              </li>\n            </ul> \n          </div> \n";
},"29":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <a class=\"btn btn-red\" role=\"menuitem\"\n              href=\"/user/"
    + container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/admin\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-unlock",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n";
},"31":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <span class=\"user-balance\" role=\"menuitem\">\n              "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"WalletWithOnboarding","profile",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </span>\n\n            <button class=\"btn btn-white on-edit-profile\" role=\"menuitem\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-settings",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span class=\"hidden-xs\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Edit Profile",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            </button>\n";
},"33":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=helpers.blockHelperMissing, buffer = 
  "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPrivate : depth0),{"name":"if","hash":{},"fn":container.program(34, data, 0),"inverse":container.program(39, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPrivateToMe : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(54, data, 0),"data":data})) != null ? stack1 : "")
    + "\n            <div class=\"button-group inline-block relative\">\n";
  stack1 = ((helper = (helper = helpers.isAmbassador || (depth0 != null ? depth0.isAmbassador : depth0)) != null ? helper : alias2),(options={"name":"isAmbassador","hash":{},"fn":container.noop,"inverse":container.program(58, data, 0),"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isAmbassador) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.isAmbassador || (depth0 != null ? depth0.isAmbassador : depth0)) != null ? helper : alias2),(options={"name":"isAmbassador","hash":{},"fn":container.program(60, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isAmbassador) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  buffer += "\n";
  stack1 = ((helper = (helper = helpers.isAdmin || (depth0 != null ? depth0.isAdmin : depth0)) != null ? helper : alias2),(options={"name":"isAdmin","hash":{},"fn":container.program(62, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.isAdmin) { stack1 = alias4.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </div>\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.followingRequested : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.program(37, data, 0),"data":data})) != null ? stack1 : "")
    + "\n";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <button role=\"menuitem\" class=\"btn btn-teal on-following-request\" data-action=\"unfollow\" role=\"menuitem\" aria-pressed=\"true\"\n              data-gtm=\"profile|unfollow\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following-request",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span\n                class=\"hidden-xs\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Requested",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            </button>\n\n";
},"37":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <button role=\"menuitem\" class=\"btn btn-white on-following-request\" data-action=\"follow\" role=\"menuitem\" aria-pressed=\"false\"\n              data-gtm=\"profile|follow\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",16,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span class=\"hidden-xs\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Request",{"name":"trans","hash":{},"data":data}))
    + "</span>\n            </button>\n";
},"39":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, buffer = 
  "            <button role=\"menuitem\"\n              class=\"btn btn-fan "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(40, data, 0),"inverse":container.program(42, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = helpers.disabled || (depth0 != null ? depth0.disabled : depth0)) != null ? helper : alias2),(options={"name":"disabled","hash":{},"fn":container.program(44, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias3 ? helper.call(alias1,options) : helper));
  if (!helpers.disabled) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + " on-follow-user\"\n              data-target=\""
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" data-following=\""
    + alias4(((helper = (helper = helpers.following || (depth0 != null ? depth0.following : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"following","hash":{},"data":data}) : helper)))
    + "\"\n              data-gtm=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(46, data, 0),"inverse":container.program(48, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.following : depth0),{"name":"if","hash":{},"fn":container.program(50, data, 0),"inverse":container.program(52, data, 0),"data":data})) != null ? stack1 : "")
    + "            </button>\n";
},"40":function(container,depth0,helpers,partials,data) {
    return "btn-teal";
},"42":function(container,depth0,helpers,partials,data) {
    return "btn-white";
},"44":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"46":function(container,depth0,helpers,partials,data) {
    return "profile|unfollow";
},"48":function(container,depth0,helpers,partials,data) {
    return "profile|follow";
},"50":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-following no-right-padding",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span class=\"hidden-xs truncate\">\n                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Following",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"52":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow no-right-padding",16,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "<span class=\"hidden-xs truncate\">\n                "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follow",{"name":"trans","hash":{},"data":data}))
    + "</span>\n";
},"54":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.isWattpad || (depth0 && depth0.isWattpad) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.username : depth0),{"name":"isWattpad","hash":{},"fn":container.noop,"inverse":container.program(55, data, 0),"data":data})) != null ? stack1 : "");
},"55":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                <button role=\"menuitem\" class=\"btn btn-white on-message "
    + ((stack1 = helpers.unless.call(alias1,((stack1 = (depth0 != null ? depth0.privateMessageFeatureFlags : depth0)) != null ? stack1.canSendPrivateMessage : stack1),{"name":"unless","hash":{},"fn":container.program(56, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-gtm=\"profile|message\">\n                  "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-inbox no-right-padding",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                  <span class=\"hidden-xs hidden-sm\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Message",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                </button>\n";
},"56":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"58":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ProfileMoreOptionsButton","options",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"60":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ProfileMoreOptionsButton","options",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"62":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.showAdminOptions : depth0),{"name":"if","hash":{},"fn":container.program(63, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"63":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.isAmbassador || (depth0 != null ? depth0.isAmbassador : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"isAmbassador","hash":{},"fn":container.noop,"inverse":container.program(64, data, 0),"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.isAmbassador) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"64":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"ProfileMoreOptionsButton","options",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"66":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Toast","unmute-info",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"68":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "  "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Toast","unblock-info",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", buffer = 
  ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<div id=\"edit-controls\" class=\"hidden faded edit-profile clearfix\">\n  <div class=\"container\">\n    <strong>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Currently editing your profile",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n    <div class=\"actions pull-right\">\n      <button class=\"btn btn-orange on-edit-save\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Save Changes",{"name":"trans","hash":{},"data":data}))
    + "</button>\n      <button class=\"btn btn-grey on-edit-cancel\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Cancel",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n  </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.backgroundUrl : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "    <div id=\"edit-overlay\" class=\"hidden faded edit-profile\"></div>\n\n    <div id=\"edit-background\" class=\"hidden faded edit-profile center-block\">\n      <span class=\"loading faded\"></span>\n      <button class=\"btn btn-glass\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-camera",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Change Background Image",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n\n    <div class=\"avatar avatar-profile center-block\">\n      "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"Avatar","display",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n      <div id=\"edit-avatar\" class=\"hidden faded edit-profile\">\n        <span class=\"loading-bg faded\"></span>\n        <span class=\"loading faded\"></span>\n        <button>"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-camera",60,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n      </div>\n    </div>\n\n    <div class=\"badges\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(partials["core.components.user_badges"],depth0,{"name":"core.components.user_badges","hash":{"badgeSize":"20"},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n\n    <p id=\"alias\" aria-label=\"also known as "
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + ".\">@"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</p>\n\n    <div id=\"edit-name\" class=\"hidden faded edit-profile center-block\">\n      <input type=\"text\" class=\"form-control\" data-field=\"name\" value=\""
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\"\n        placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Full name",{"name":"trans","hash":{},"data":data}))
    + "\">\n    </div>\n\n    <div class=\"row header-metadata\">\n      <div class=\"col-xs-4 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\"profile-works\">\n        <p>"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"count","hash":{},"data":data}))
    + "</p>\n        <p>"
    + alias3((helpers.npgettext || (depth0 && depth0.npgettext) || alias2).call(alias1,"Profile Metadata","Work","Works",(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"npgettext","hash":{},"data":data}))
    + "</p>\n      </div>\n      <div class=\"col-xs-4 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numLists : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" data-id=\"profile-lists\">\n        <p>"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numLists : depth0),{"name":"count","hash":{},"data":data}))
    + "</p>\n        <p>"
    + alias3((helpers.npgettext || (depth0 && depth0.npgettext) || alias2).call(alias1,"Profile Metadata","Reading List","Reading Lists",(depth0 != null ? depth0.numLists : depth0),{"name":"npgettext","hash":{},"data":data}))
    + "</p>\n      </div>\n      <div class=\"col-xs-4 "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"\n        "
    + ((stack1 = (helpers.greaterThan || (depth0 && depth0.greaterThan) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),999,{"name":"greaterThan","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n        <p class=\"followers-count\">"
    + alias3((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}))
    + "</p>\n        <p>"
    + alias3((helpers.npgettext || (depth0 && depth0.npgettext) || alias2).call(alias1,"Profile Metadata","Follower","Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"npgettext","hash":{},"data":data}))
    + "</p>\n      </div>\n    </div>\n\n    <div id=\"page-navigation\" class=\"sub-navigation\">\n      <div id=\"edit-navblock\" class=\"hidden faded edit-profile\"></div>\n\n      <div class=\"container\">\n        <div class=\"navigation-content\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isPrivateToMe : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "\n          <div class=\"actions\" role=\"menu\">\n\n";
  stack1 = ((helper = (helper = helpers.isAdmin || (depth0 != null ? depth0.isAdmin : depth0)) != null ? helper : alias2),(options={"name":"isAdmin","hash":{},"fn":container.program(26, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.isAdmin) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(31, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.noop,"inverse":container.program(33, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n      </div>\n    </div>\n  </header>\n\n  <main id=\"content\" class=\"container\" role=\"main\">"
    + ((stack1 = ((helper = (helper = helpers.mainContent || (depth0 != null ? depth0.mainContent : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"mainContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</main>\n\n  <div class=\"profile-options-modal\">\n"
    + ((stack1 = container.invokePartial(partials["desktop.profile.profile_mute_modal"],depth0,{"name":"desktop.profile.profile_mute_modal","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials["desktop.profile.profile_close_modal"],depth0,{"name":"desktop.profile.profile_close_modal","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials["desktop.profile.profile_timeout_modal"],depth0,{"name":"desktop.profile.profile_timeout_modal","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "  </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.recentlyUnmuted : depth0),{"name":"if","hash":{},"fn":container.program(66, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.recentlyUnblocked : depth0),{"name":"if","hash":{},"fn":container.program(68, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});