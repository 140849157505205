import browseHeader from "core/templates/components/browse-header.hbs";
import browseTags from "core/templates/components/browse-tags.hbs";
import browseResults from "platform/browse/templates/browse-results.hbs";
import browse from "platform/browse/templates/browse.hbs";
import setPropsforReactInHb from "../../../../helpers/handlebars/set-props-for-react-in-hb";

const TAG_SEARCH_AD_PLACEMENT = "tag_search";
const TAG_SEARCH_AD_LOGGEDOUT_PLACEMENT = "tag_search_loggedout";

(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "Browse",
    app.views.RegionManager.extend({
      id: "browse-container",
      template: browse,

      subviews: {
        results: app.views.BrowseResults,
        dummyReactView: app.views.DummyReactView
      },

      events: {
        "tap    .tab-control": "onSwitchTabs",
        "click  .tab-control": "stopEvent",

        "tap  .on-topic": "onTopicClick",
        "click  .on-topic": "onTopicClick"
      },

      constructor: function(options) {
        this.tagTabs = [
          {
            moduleType: "hotStoriesForTag",
            tabValue: "hot",
            translatedTabName: utils.trans("Hot")
          },
          {
            moduleType: "newStoriesForTag",
            tabValue: "new",
            translatedTabName: utils.trans("New")
          },
          {
            moduleType: "relatedWritersForTag",
            tabValue: "writers",
            translatedTabName: utils.trans("Related Writers")
          },
          {
            moduleType: "relatedReadingListsForTag",
            tabValue: "lists",
            translatedTabName: utils.trans("Related Reading Lists")
          }
        ];

        app.views.RegionManager.prototype.constructor.apply(this, arguments);
      },

      initialize: function(options) {
        options = options || {};
        this.tag = options.tag || null; // A string of comma delimited tags
        this.topicModel = options.topicModel;
        this.rootTopicModel = options.rootTopicModel;
        this.lastTopicModel = options.lastTopicModel;
        this.errors = [];
        this.adEligibility = options.adEligibility[0];

        this.topic = options.topic || null;
        this.browseTopicsCollection = options.browseTopicsCollection || null;
        this.isTopicsLevel = !_.isEmpty(this.topic);
        this.browseLabel = this.getBrowseLabel(); // For multiple tags, display the first selected tag
        this.section = options.section || "hot";

        this.collection = options.collection;

        this.listenTo(
          app,
          "app:component:TagCarouselItem:click",
          this.navigateToTagPage
        );

        // Register partials for SSR
        if (app.get("device").is.mobile) {
          Handlebars.registerPartial(
            "mobile.browse.browse_results",
            browseResults
          );
        } else {
          Handlebars.registerPartial(
            "desktop.browse.browse_results",
            browseResults
          );
          Handlebars.registerPartial("core.components.browse_tags", browseTags);
          Handlebars.registerPartial(
            "core.components.browse_header",
            browseHeader
          );
        }
      },

      render: function() {
        var self = this;
        var relatedTags = [];

        if (self.section !== "new" && self.section !== "hot") {
          app.router.trigger("route:error-404", "browse-tag");
          return this;
        }

        var resolve = function() {
          // Tabs/sections setup
          var tabs = self.getTabs();

          var hasRelatedTags = _.findWhere(self.collection.toJSON(), {
            type: "relatedTagsForTag"
          });
          if (hasRelatedTags) {
            // Map needed because the module API doesn't return the set of tags in the format the component expects
            relatedTags = hasRelatedTags.data.items.tags.map(function(tag) {
              return {
                id: tag,
                name: tag,
                link: `/stories/${tag}`
              };
            });
          }
          // Add the active tags to the beginning of the tag list in the order they were added
          var currTags = self.isTopicsLevel ? self.topic : self.tag;
          var reversedCurrTags = currTags.split(",").reverse();
          _.forEach(reversedCurrTags, function(tag) {
            relatedTags.unshift({
              id: tag,
              name: tag,
              active: true
            });
          });
          if (currTags.split(",").length === 1) {
            relatedTags[0].noRemoveIcon = true;
          }

          if (_.isEmpty(self.errors)) {
            var locale = wattpad.utils.getCookie("locale");

            if (!locale || locale.trim().length === 0) {
              locale = "en_US"; //default to english
            }

            const tagSearchProps = {
              placement: wattpad.utils.currentUser().authenticated()
                ? TAG_SEARCH_AD_PLACEMENT
                : TAG_SEARCH_AD_LOGGEDOUT_PLACEMENT,
              adData: {
                adEligibility: self.adEligibility,
                testGroups: wattpad.testGroups,
                context: {
                  user: wattpad.utils.currentUser().attributes
                },
                deviceType: wattpad.utils.getDeviceType(),
                userGeo: wattpad.userCountryCode
              },
              className: "tag-search-ad",
              refresh: true
            };

            var templateData = {
              tabs: tabs,
              relatedTags: {
                tags: relatedTags,
                withIcon: true
              },
              urlBasePath: self.getUrlBasePath(self.tag || self.topic),
              activeTab: self.section,
              translatedActiveTab: utils.trans(
                self.section.charAt(0).toUpperCase() + self.section.slice(1)
              ),
              browseLabel: self.browseLabel,
              isEnglish: locale === "en_US",
              testGroups: wattpad.testGroups,
              rootTopic: self.rootTopicModel
            };

            setPropsforReactInHb(
              templateData,
              "tagSearchProps",
              tagSearchProps
            );

            if (self.numStories) {
              _.extend(templateData, { numStories: self.numStories });
            }
            self.$el.html(self.template(templateData));
            self.renderBrowseRegion("#browse-content");
            self.renderBrowseHeader("#browse-header-container");
          } else {
            app.router.trigger("route:error-404", "browse-tag");
          }
        };

        _.defer(resolve);

        app.trigger("navbar:browse-label:set", this.browseLabel, this.topic);
        return this;
      },

      renderBrowseRegion: function(regionId) {
        var view,
          isMobile = utils.getDeviceType() === "mobile";

        this.$(regionId).empty();

        if (this.isTopicsLevel && this.section === "explore") {
          var newTopicsObject = [],
            prevTagQuery = isMobile
              ? ""
              : "?prev=" + encodeURIComponent(this.topic);

          var urlPath = "/stories/" + encodeURIComponent(this.topic);

          var hasRelatedTagsForTag = this.collection.findWhere({
            type: "relatedTagsForTag"
          });
          if (hasRelatedTagsForTag) {
            _.each(
              _.get(hasRelatedTagsForTag.get("data"), "items.tags", []),
              function(tag) {
                newTopicsObject.push({
                  key: tag,
                  name: tag,
                  url: urlPath + encodeURIComponent(",") + tag + prevTagQuery
                });
              }
            );
          }

          if (isMobile) {
            view = new this.subviews.dummyReactView({
              component: "MenuOptions",
              componentId: "exploreTags",
              componentData: {
                options: newTopicsObject
              }
            });
          }
        } else {
          var model = this.collection.findWhere({
            type: this.collection.getTypeByUrlPath(this.section)
          });

          if (model) {
            view = new this.subviews.results({
              model: model,
              filter: this.section,
              tag: this.tag,
              topicModel: this.topicModel
            });
          }
        }

        if (view) {
          this.setRegion(regionId, view.render());
        }
      },

      renderBrowseHeader: function(regionId) {
        this.$(regionId).empty();
        var isMobile = utils.getDeviceType() === "mobile";

        if (!isMobile) {
          var moduleType = _.findWhere(this.tagTabs, {
            tabValue: this.section
          }).moduleType;
          var moduleData = this.collection.findWhere({
            type: moduleType
          });
          var numStories = moduleData.get("data").items.total || 0;
          var context = {
            numStories: numStories,
            activeTab: this.section,
            urlBasePath: this.getUrlBasePath(this.tag || this.topic),
            translatedActiveTab: utils.trans(
              this.section.charAt(0).toUpperCase() + this.section.slice(1)
            ),
            tabs: this.getTabs()
          };
          this.$el.find(regionId).html(browseHeader(context));
        }
      },

      getTabs: function() {
        var tabsWithResults = [],
          resultModuleTypes = this.collection.pluck("type");
        for (var i = 0; i < this.tagTabs.length; i++) {
          if (_.indexOf(resultModuleTypes, this.tagTabs[i].moduleType) !== -1) {
            tabsWithResults.push(this.tagTabs[i]);
          }
        }

        return tabsWithResults;
      },

      getUrlBasePath: function(tag) {
        return "stories/" + encodeURIComponent(tag);
      },

      // Attempts to grab the previous page's tag from the URL
      // `?prev=first-level-topic` indicates that this tag page originated from a topic page
      // Tag pages coming from another tag page will have /tags/prev-prev-tag%2Cprev-tag%2Ccurrent-tag (comma-delimited)
      getPrevPageTag: function() {
        var urlTagsArray = this.tag ? this.tag.split(",") : [],
          tag,
          label;

        // Multiple tags; check /tags/prev-prev-tag%2Cprev-tag%2Ccurrent-tag
        if (urlTagsArray.length > 1) {
          var urlTagsArrayCopy = _.clone(urlTagsArray);
          urlTagsArrayCopy.pop();

          tag =
            encodeURIComponent(urlTagsArrayCopy.join(",")) +
            this.getPrevRootTopicQuery();
          label = urlTagsArray[urlTagsArray.length - 2];
        }
        // One tag; check ?prev=first-level-topic
        else if (this.rootTopicModel) {
          tag = this.rootTopicModel.get("name");
          label = this.rootTopicModel.get("topic");
        } else {
          return null;
        }

        return { tag: tag, label: label };
      },

      // ?prev=first-level-topic
      getPrevRootTopicQuery: function() {
        // query param not used on mobile-web
        if (app.get("device").is.mobile) {
          return "";
        }
        return this.lastTopicModel
          ? "?prev=" + this.lastTopicModel.get("name")
          : "";
      },

      getBrowseLabel: function() {
        // The browse label should be the first tag clicked.
        // However, if the first tag is also a topic, it should be formatted with proper spacing/capitalization
        // (which it doesn't have in tag form)

        var browseLabel;
        if (this.rootTopicModel) {
          browseLabel = this.rootTopicModel.get("topic");
        } else if (this.tag) {
          var firstTag = this.tag.split(",")[0];
          browseLabel =
            firstTag.charAt(0).toUpperCase() + firstTag.slice(1).toLowerCase();
        } else {
          browseLabel = this.topicModel.get("topic");
        }
        browseLabel = utils.trans("%s Stories", utils.trans(browseLabel));

        return browseLabel;
      },

      onSwitchTabs: function(evt) {
        var $tab = $(evt.currentTarget);
        this.section = $tab.data("section");

        this.$(".tab-control").removeClass("active");
        $tab.addClass("active");

        var allIcons = this.el.querySelectorAll(".fa-stack.check");
        for (var i = 0; i < allIcons.length; i++) {
          allIcons[i].classList.add("hidden");
        }
        var selIcon = $tab.find(".fa-stack.check");
        selIcon.removeClass("hidden");

        this.renderBrowseRegion("#browse-content");
        this.renderBrowseHeader("#browse-header-container");

        var tagOrTopic = (
          encodeURIComponent(this.tag || this.topic) + "/"
        ).toLowerCase();
        app.router.navigate(
          "/stories/" +
            tagOrTopic +
            $tab.attr("data-section") +
            this.getPrevRootTopicQuery(),
          {
            trigger: false
          }
        );
      },

      navigateToTagPage: function(tagName, evt) {
        var urlPath = "/stories/";
        var tagClicked = $(evt.currentTarget);
        var removeTag = tagClicked.hasClass("active");
        var currTag = (this.tag || this.topic).toLowerCase();

        var tagURL = removeTag
          ? urlPath + encodeURIComponent(this.removeOneTag(currTag, tagName))
          : urlPath +
            encodeURIComponent(currTag + "," + tagName) +
            this.getPrevRootTopicQuery();

        app.router.navigate(tagURL, { trigger: true });
      },

      removeOneTag: function(tagsString, tag) {
        var tagsArr = tagsString.split(",");
        if (tagsArr.length !== 1) {
          tagsArr.splice(tagsArr.indexOf(tag), 1);
        }
        return tagsArr.join(",");
      },

      onTopicClick: function(evt) {
        var sel_topic = $(evt.target).text();
        window.te.push("event", "browse", "topic", null, "click", {
          topic: sel_topic
        });
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app);
