(function(window, app) {
  "use strict";

  app.router.add({
    library: ["library-current-reads"]
  });

  app.router.add({
    archive: ["library-archive"]
  });

  app.router.add({
    "list(/:list)": [
      "library-reading-lists",
      {
        regexp: {
          list: /[0-9]*/
        }
      }
    ]
  });

  app.router.add({
    "list/:list(-:slug)": [
      "library-reading-lists",
      {
        regexp: {
          list: /[0-9]*/,
          slug: /[^\/\?#]*/
        }
      }
    ]
  });
})(window, window.app);
