(function(window, app) {
  "use strict";

  app.router.add({
    "stories/:tag(/:filter)": [
      "browse-tag",
      {
        regexp: {
          tag: /[^\s\?\/]+/,
          filter: /explore|featured|hot|rising|new|writers|lists/
        }
      }
    ]
  });
})(window, window.app);
