(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "PublicMessage",
    app.models.UserEvent.extend({
      fields: ["id", "type", "createDate", "data"], // selector for what returns from the database

      // put the supported types of activities here
      validTypes: [
        "follow",
        "vote",
        "comment",
        "inline_comment",
        "library",
        "upload",
        "message",
        "dedicate",
        "newMessage"
      ],

      initialize: function(options) {
        options = options || {};
        if (
          typeof options.username !== "string" ||
          options.username.trim() === ""
        ) {
          throw new Error("PublicMessage: username cannot be null");
        }
        app.models.UserEvent.prototype.initialize.apply(this, arguments);
      },

      url: function() {
        return (
          "/api/v3/users/" +
          this.get("username") +
          "/activity/" +
          this.get("id")
        );
      },

      resource: function() {
        return "user." + this.get("username") + ".activity." + this.get("id");
      },

      idAttribute: "createDate" // string
    })
  );
})(window, _, wattpad, window.app);
