(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Tags",
    app.collections.BaseCollection.extend({
      model: app.models.BaseModel,

      arrayKey: "tags"
    })
  );
})(window, _, wattpad, window.app);
