(function(window, _, wattpad, app) {
  "use strict";

  //DO NOT CACHE THIS
  app.add(
    "StoryInLists",
    Monaco.Model.extend({
      initialize: function(options) {
        if (!options.storyId) {
          throw new Error("StoryInLists - Missing parameter storyId");
        }
        this.storyId = options.storyId;
      },

      url: function() {
        return "/v4/stories/collections?storyIds=" + this.storyId;
      },

      arrayKey: "collections",

      parse: function(response) {
        return response[this.arrayKey];
      }
    })
  );
})(window, _, wattpad, window.app);
