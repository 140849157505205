"use strict";
var _ = require("lodash");
var isServer = require("./is-server");

/**
 * ADSX-1474
 * Story Brand Safety
 *
 * @param {object} storyGroupModel - Story group model info to extract groupid + language id
 * @param {object} storyPartModel - Story part model info to extract part id if existing
 * @param {object} serverReq - (Required only for backend) The request object when this utility is called on the server
 * @param {object} serverRes - (Required only for backend) The response object when this utility is called on the server
 * @returns {{brandSafetyLevel: Number, brandSafetySource: String}} - Brand safety level is a number from 0-3
 * that indicates how inappropriate the content is for the user.  0 is the lowest and 3 is the highest.
 * Brand safety Source is the method in which the brandsafetylevel was calculated.
 */

const BRAND_SAFETY_DEFAULT_ERROR = {
  brandSafetyLevel: 3,
  brandSafetySource: "MANUAL_FALLBACK"
};

const serverAPICall = (url, req, res) => {
  const utilsFilePath = "../server/utils";
  const mod = module;
  const utils = mod.require(utilsFilePath);
  return utils.apiHttp
    .get(url, null, res, req)
    .then(result => {
      //apihttp does not catch 400 errors, but catches 500
      if (result?.code >= 400 && result?.code < 500) {
        throw result;
      } else {
        return result;
      }
    })
    .catch(err => {
      throw err;
    });
};

const clientAPICall = url => {
  return Promise.resolve(
    $.ajax({
      type: "GET",
      url: url
    })
  );
};

const logErrorCb = errorRes => {
  //Client side
  if (errorRes.status === 400 && errorRes.responseJSON) {
    console.log("getContentRating Error: " + errorRes.responseJSON.message);
    //Server side
  } else if (errorRes.code === 400 && errorRes.message) {
    console.log("getContentRating Error: " + errorRes.message);
  } else {
    console.log("getContentRating Error: No response received");
  }
};

const apiCall = isServer() ? serverAPICall : clientAPICall;

module.exports = function(
  storyGroupModel,
  storyPartModel,
  serverReq,
  serverRes
) {
  return new Promise(async function(resolve) {
    const groupId = _.get(storyGroupModel, "id");
    const partId = _.get(storyPartModel, "id");
    const storyType = partId ? "parts" : "description";
    const useImageMod = isServer()
      ? serverRes?.locals?.testGroups.USE_IMAGE_MOD
      : window?.wattpad?.testGroups.USE_IMAGE_MOD;
    const languageId =
      _.get(storyPartModel, "group.language.id") ||
      _.get(storyGroupModel, "language");
    const url = `/v5/stories/${groupId}/ads-metadata/brand-safety/${storyType}?languageId=${languageId}${
      partId ? `&partIds=${partId}` : ""
    }${useImageMod ? `&useImageModeration=true` : ""}`;

    return resolve(
      await apiCall(url, serverReq, serverRes)
        .then(brandSafetyResult => {
          return brandSafetyResult;
        })
        .catch(err => {
          logErrorCb(err);
          return BRAND_SAFETY_DEFAULT_ERROR;
        })
    );
  });
};
