import notificationBase from "core/templates/notifications/items/notification-base.hbs";
import notifications from "core/templates/notifications/notifications.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "Notifications",
    app.views.IncrementalList.extend({
      containerClass: ".collection",

      template: notifications,

      itemView: app.views.NotificationEvent,

      initialize: function() {
        //TODO: Refactor this so we move it into the view and out of the item
        Handlebars.registerPartial(
          "core.notifications.items.notification_base",
          notificationBase
        );
        this.setCollectionfilter(
          app.models.NotificationEvent.prototype.isValid
        );
      },

      renderCollection: function(options) {
        var result = app.views.CollectionView.prototype.renderCollection.apply(
          this,
          arguments
        );
        if (this.collection.length === 0) {
          this.$(".empty-message").removeClass("hidden");
        } else {
          this.$(".empty-message").addClass("hidden");
        }
        return result;
      },

      getTemplateData: function() {
        return {
          notifications: {
            hasMore: this.collection.hasMore(),
            hasItems: this.collection.length > 0
          },
          device: app.get("device")
        };
      }
    })
  );

  app.mixin(app.views.Notifications, "InfiniteScroll");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
