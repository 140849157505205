var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "/myworks/new";
},"3":function(container,depth0,helpers,partials,data) {
    return "#";
},"5":function(container,depth0,helpers,partials,data) {
    return " \"myWorksButtonClick\" ";
},"7":function(container,depth0,helpers,partials,data) {
    return "/myworks";
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <li>\n        <a href=\"https://creators.wattpad.com/creators-101/\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Helpful writer resources",{"name":"trans","hash":{},"data":data}))
    + "</a>\n      </li>\n      <li>\n        <a href=\"https://creators.wattpad.com/programs-and-opportunities/\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad programs & opportunities",{"name":"trans","hash":{},"data":data}))
    + "</a>\n      </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"triangle\"></div>\n<div class=\"dropdown-menu large\" aria-labelledby=\"create-dropdown\">\n  <ul aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Write",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"header-list\">\n    <li>\n      <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Create a new story",{"name":"trans","hash":{},"data":data}))
    + "\" class="
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  data-form=\"signup\">\n        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/icons/create-story.svg",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Create a new story",{"name":"trans","hash":{},"data":data}),{"name":"connectImage","hash":{},"data":data}))
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Create a new story",{"name":"trans","hash":{},"data":data}))
    + "\n      </a>\n    </li>\n    <li>\n      <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"My Stories",{"name":"trans","hash":{},"data":data}))
    + "\" class="
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0["current-user"] : depth0),{"name":"unless","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  data-form=\"signup\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"My Stories",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    </li>\n    <li role=\"presentation\" class=\"divider\"></li>\n"
    + ((stack1 = (helpers.ifequal || (depth0 && depth0.ifequal) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.interfaceLang : depth0)) != null ? stack1.name : stack1),"English",{"name":"ifequal","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    <li>\n      <a href=\"/go/writing-contests/\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writing contests",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    </li>\n  </ul>\n</div>\n";
},"useData":true});