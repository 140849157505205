import { getStoryReadingEligibility } from "../../../../helpers/ads/get-ad-eligibility";

(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:storyReading",
    app.router.filter(["getTranslations", "getCategories"], function(
      partId,
      slug,
      pageNum,
      commentId,
      replyId,
      queryParams
    ) {
      var redirectParams = wattpad.utils.getRedirectParams();
      var partId = parseInt(partId, 10),
        model = new app.models.StoryPartModel({
          id: partId
        }),
        isComment = !!(commentId && commentId.toString().match(/[0-9_]+/)),
        // isDeeplinkedReply indicates whether the deeplinked comment is a reply to a parent comment.
        isDeeplinkedReply = !!(replyId && replyId.toString().match(/[0-9_]+/));

      let promises = [model.fetch()],
        deepLinkedComment;
      if (isNaN(partId)) {
        app.router.trigger("route:error-404", "story");
        return;
      }
      if (!wattpad.utils.appOn()) {
        pageNum = pageNum ? parseInt(pageNum, 10) + 1 : 2;
      }

      // AUDI-4617: Initialize mutedUsers to get the correct option
      // (Mute/Unmute user) in comment-item-options
      if (
        wattpad.utils.currentUser().get("username") &&
        window.store &&
        !window.store.getState().profileData.hasLoadad
      ) {
        promises.push(
          Promise.resolve(
            window.store.dispatch(
              window.app.components.actions.initializeMutedUsers(
                wattpad.utils.currentUser().get("username")
              )
            )
          )
        );
      }

      if (isComment) {
        /*
         * We always pass in the commentId (whether it is a deeplinked parent comment or reply comment) because we need to
         * render the parent comment for context even if the deeplinked comment is only a reply.
         */
        deepLinkedComment = new app.models.CommentModel(
          {
            id: commentId
          },
          {
            isDeepLink: true,
            commentIdToHighlight: replyId || commentId,
            isDeeplinkedReply: isDeeplinkedReply,
            errorHandlerMixinDisabled: true
          }
        );

        promises.push(
          new Promise(resolve => {
            deepLinkedComment
              .fetch()
              .then(
                result => resolve(result),
                () => resolve({ id: commentId })
              );
          })
        );
      }

      let adEligibility = [];

      Promise.all(promises)
        .then(() => {
          // ADS-885: Ads Pageview Event
          if (typeof __atha !== "undefined") {
            var id = __atha.sendPageView(
              "reading",
              parseInt(model.get("group").id, 10),
              model.get("id"),
              model.get("group"),
              model.attributes
            );
            model.set("pageviewId", id);
          }
          return new Promise(resolve => {
            if (!wattpad?.testGroups.AD_ELIGIBILITY) return resolve();

            getStoryReadingEligibility({
              story_id: Number(model.get("group").id),
              part_id: Number(partId)
            }).then(response => {
              adEligibility = response;
              return resolve();
            });
          });
        })
        .then(() => {
          const story = model.get("group");

          if (!story.isPaywalled) {
            // No need to fetch metadata if story isn't paid
            return;
          }

          const storyId = story.id;
          return window.store.dispatch(
            window.app.components.actions.fetchPaidContentMetadata(
              storyId,
              story.parts
            )
          );
        })
        .then(
          function() {
            model.getPage(pageNum || 1).then(function(page) {
              model.set("pageNumber", pageNum || 1);

              var storyText = page ? page.get("text") : "",
                isPreview = !!redirectParams?.preview,
                // Vietnamese is special-cased to deal with scrapers with good SEO
                isVietnamese = parseInt(app.get("language"), 10) === 19,
                isFirstPublish = !!redirectParams?.firstPublish,
                isPostPublishModal = app.views.WorksWriter
                  ? app.currentView instanceof app.views.WorksWriter &&
                    !isPreview &&
                    !model.get("draft")
                  : !!redirectParams?.isPostPublishModal,
                deviceOptions = {},
                storyTitle;

              if (isVietnamese) {
                storyTitle = [
                  "Đọc Truyện" + model.get("title"),
                  model.get("group").title,
                  model.get("group").user.name,
                  "Wattpad"
                ].join(" - ");
              } else {
                storyTitle = [
                  model.get("title"),
                  model.get("group").title,
                  "Wattpad"
                ].join(" - ");
              }

              var options = {
                model: model,
                storyText: storyText,
                fromLanding:
                  app.currentView instanceof app.views.NewStoryDetails,
                isPreview: isPreview,
                deepLinkModel: deepLinkedComment,
                isFirstPublish: isFirstPublish,
                isPostPublishModal: isPostPublishModal,
                adEligibility
              };

              var view = new app.views.StoryReading(options);

              if (app.get("device").is.mobile) {
                deviceOptions = {
                  footerOptions: {
                    appUrl: wattpad.utils.getAppLink("story-reading", model)
                  }
                };
              }

              const isPaywalled = model.get("group").isPaywalled;
              const noIndex = model.get("group").noIndex;
              app.transitionTo(
                view,
                _.extend(
                  {
                    pageTitle: storyTitle,
                    hasHeader: true,
                    hasFooter: true,
                    noIndex: noIndex,
                    viewChangedCallback: function() {
                      view.updateScrollState(true);
                    },
                    branchMetadata: { isPaywalled }
                  },
                  deviceOptions
                )
              );

              const shouldSeeOnboarding = wattpad.utils.shouldSeePaidOnboarding(
                isPaywalled && wattpad.utils.isPaidStory(model.get("group"))
              );
              if (shouldSeeOnboarding) {
                const storyId = model.get("id");
                wattpad.utils.showPaidOnboardingAfterDelay(storyId);
              }
            });
          },
          function() {
            //  error handling
            app.router.trigger("route:error-404", "story");
          }
        );
    })
  );
})(window, wattpad, window.app);
