var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.sample_covers : depth0)) != null ? stack1["0"] : stack1),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "            <div class=\"content\">\n                <h4 class=\"h3\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n                <small>"
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Story","%s Stories",(depth0 != null ? depth0.numStories : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numStories : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</small>\n            </div>\n        </a>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <a href=\"/list/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\" class=\"header panel-heading\" style=\"background-image: url("
    + alias3((helpers.resizeCoverAsBackground || (depth0 && depth0.resizeCoverAsBackground) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.sample_covers : depth0)) != null ? stack1["0"] : stack1),640,{"name":"resizeCoverAsBackground","hash":{},"data":data}))
    + ");\">\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <a href=\"/list/"
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\" class=\"header panel-heading\" style=\"background-image: url("
    + alias3((helpers.resizeCoverAsBackground || (depth0 && depth0.resizeCoverAsBackground) || alias2).call(alias1,((stack1 = (depths[1] != null ? depths[1].story : depths[1])) != null ? stack1.cover : stack1),640,{"name":"resizeCoverAsBackground","hash":{},"data":data}))
    + ");\">\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<header class=\"panel-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm2 on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n      <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n\n    <h3 class=\"h6\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"%s added %s to %s",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.list : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</h3>\n    <time datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_options"],depth0,{"name":"core.feed_items.feed_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n\n<div class=\"panel-body\">\n    <article class=\"panel list\">\n\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.list : stack1), depth0),{"name":"data.list","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <ul class=\"list-group\">\n            <li class=\"list-group-item single-item\">\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.aggregates.item_views.feed_library_item"],depth0,{"name":"core.feed_items.aggregates.item_views.feed_library_item","data":data,"indent":"              ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </li>\n        </ul>\n\n    </article>\n</div>\n\n<footer class=\"panel-footer\">\n    <a href=\"/list/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.list : stack1)) != null ? stack1.id : stack1), depth0))
    + "\" class=\"btn btn-text on-navigate\">\n      "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"View reading list",{"name":"trans","hash":{},"data":data}))
    + "\n    </a>\n</footer>\n";
},"usePartial":true,"useData":true,"useDepths":true});