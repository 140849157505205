(function(window, _, wattpad, utils, app) {
  "use strict";
  var RequestAnimationFrameDelegator, delegator;

  app.add(
    "InfiniteScroll",
    Monaco.Mixin.create({
      initialize: function(options, next) {
        options = options || {};

        this.paused = false;

        if (!this instanceof app.views.IncrementalList) {
          throw Error("Mixin can only be applied to IncrementalList views");
        }

        this.removed = false;

        next(options);

        if (options.postpone) {
          this.paused = true;
        }

        if (
          this.collection &&
          this.collection.hasMore &&
          !options.manualRegistration
        ) {
          this.registerForScrolling();
        }
      },

      registerForScrolling: function() {
        delegator.addDelegate(this.cid, this.onFrameRequest, this);
      },

      deregisterFromScrolling: function() {
        delegator.removeDelegate(this.cid);
      },

      onFrameRequest: function(noop) {
        if (
          this.collection &&
          this.collection.hasMore &&
          this.collection.hasMore() &&
          this.removed === false
        ) {
          this.checkDistance();
        } else {
          this.deregisterFromScrolling();
        }

        noop();
      },

      setButtonState: function(next) {
        next();
        this.paused = false;
      },

      checkDistance: _.throttle(function() {
        var $showMore = this.$(".on-showmore"),
          browserWindowHeight = window.innerHeight || window.screen.height;
        //Need to check if the collection has more again because this function is throttled.
        if (
          !this.paused &&
          this.collection &&
          this.collection.hasMore &&
          this.collection.hasMore() &&
          $showMore &&
          $showMore.length > 0 &&
          browserWindowHeight * 1.5 > $showMore.offset().top - window.scrollY
        ) {
          this.paused = true;
          this.performLoad();
        }
      }, 500),

      performLoad: function(noop) {
        this.onShowMore();
        noop();
      },

      setPaused: function(state, noop) {
        if (_.isBoolean(state)) {
          this.paused = state;
        }
      },

      remove: function(next) {
        this.removed = true;
        next();
      }
    })
  );

  RequestAnimationFrameDelegator = function() {
    this.delegates = {};

    //Test suite does not like this line. Guarding for it's stupidity.
    if (this.trigger.bind) {
      this.trigger = this.trigger.bind(this);
    }
  };

  RequestAnimationFrameDelegator.prototype.addDelegate = function(
    ident,
    callback,
    context
  ) {
    var keys = _.keys(this.delegates).length,
      self = this;

    this.delegates[ident] = callback.bind(context);

    //If we had 0 keys, we need to restart the listeners.
    if (keys < 1) {
      //Choose strategy depending on browser availability of R.A.F.
      if (window.requestAnimationFrame) {
        self.trigger();
      } else {
        $(window).on(
          "scroll.screen_animation_frame_delegate_fallback",
          function() {
            self.trigger();
          }
        );
      }
    }
  };

  RequestAnimationFrameDelegator.prototype.removeDelegate = function(ident) {
    delete this.delegates[ident];
  };

  RequestAnimationFrameDelegator.prototype.trigger = function() {
    _.forOwn(this.delegates, function(callback) {
      callback();
    });

    this.cleanup();
  };

  RequestAnimationFrameDelegator.prototype.cleanup = function() {
    //Cleanup listener or Continue R.A.F.
    if (_.keys(this.delegates).length > 0) {
      //Need to continue requesting frames
      if (window.requestAnimationFrame) {
        window.requestAnimationFrame(this.trigger);
      }
    } else if (!window.requestAnimationFrame) {
      //Only need to cleanup scroll listener. Req.ani.frame will take care of itself.
      $(window).off("scroll.screen_animation_frame_delegate_fallback");
    }
  };

  delegator = new RequestAnimationFrameDelegator();
  window.wattpad.RequestAnimationFrameDelegator = RequestAnimationFrameDelegator;
  window.wattpad.RequestAnimationFrameDelegator.instance = delegator;
})(window, _, wattpad, wattpad.utils, window.app);
