import layoutHeader from "platform/components/templates/header/layout-header.hbs";
import searchField from "core/templates/header/search-field.hbs";
import profileMenu from "platform/components/templates/header/partials/profile-menu.hbs";
import discoverMenu from "core/templates/header/discover-menu.hbs";
import browseTopics from "platform/browse/templates/browse-topics.hbs";
import communityMenu from "core/templates/header/community-menu.hbs";
import createMenu from "platform/components/templates/header/partials/create-menu.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "Header",
    app.views.Base.extend({
      template: null,

      partials: {
        "core.header.community_menu": communityMenu,
        "desktop.browse.browse_topics": browseTopics,
        "core.header.discover_menu": discoverMenu,
        "desktop.components.header.partials.profile_menu": profileMenu,
        "core.header.search_field": searchField,
        "desktop.components.header.partials.create_menu": createMenu
      },

      events: {
        "tap    .on-language": "onLanguageSelected",
        "click  .on-language": "stopEvent",

        "tap    #header-item-login": "onAuthModal",
        "click  #header-item-login": "stopEvent",

        "tap    #header-item-signup": "onAuthModal",
        "click  #header-item-signup": "stopEvent",

        "tap .on-logout": "onLogout",
        "click .on-logout": "stopEvent",

        "tap .on-premium": "onPremium",
        "tap .confirm-button": "onTooltip",

        //To fix a bug with the dropdown dismissal
        "click  .on-navigate": "onNavigate",

        "mouseleave .show-related-tags": "onUnhoverCategory",
        "mouseenter .show-related-tags": "onCategoryHovered",
        "tap .show-related-tags": "onCategoryHovered",
        "click .show-related-tags": "stopEvent",

        "tap .on-topic": "onTopicClick",
        "click .on-topic": "onTopicClick",

        "click .triangle": "onPopupClick",
        "click .header-list": "onPopupClick",

        "click .myWorksButtonClick": "onMyWorksButtonClick"
      },

      // for the auth modal mixin
      title: function() {
        wattpad.utils.trans("Join Wattpad");
      },
      subtitle: function() {
        wattpad.utils.trans(
          "Be part of a global community of readers and writers, all connected through the power of story."
        );
      },

      initialize: function(options) {
        options = options || {};

        this.template = layoutHeader;
        _.each(this.partials, function(value, key, list) {
          Handlebars.registerPartial(key, value);
        });
        this.searchFieldView = new app.views.SearchField();

        var device = app.get("device");
        this.showCurationPanel = device.is.desktop;

        if (app?.get("currentUser")?.get("language")) {
          var supportedLanguages = app
            ?.get("supported-languages")
            ?.get(app?.get("currentUser")?.get("language"));
        }

        this.language = supportedLanguages?.get("code") || "en";
      },

      render: function() {
        var data = {},
          loggedOutNotEnglish = false,
          collection,
          curatedLists = null,
          curationProfile = null,
          promises = [],
          self = this;

        data.currentUrl = wattpad.utils.getNextUrl(window.location.href);

        if (wattpad.utils.currentUser().authenticated()) {
          data["current-user"] = wattpad.utils.currentUser().toJSON();
          data["current-user"].recommendedTags =
            window.wattpad.userRecommendedTags;
        } else if (parseInt(app.get("language"), 10) !== 1) {
          loggedOutNotEnglish = true;
        }

        _.each(JSON.parse(wattpad.newCurationProfiles), function(pair) {
          if (pair.code === self.language) {
            curationProfile = pair.username;
          }
        });

        if (curationProfile) {
          curatedLists = new app.collections.ReadingLists([], {
            username: curationProfile
          });
          promises.push(curatedLists.fetchNextSet());
        }
        collection = new app.collections.BrowseTopics();
        promises.push(collection.fetch());

        Promise.all(promises)
          .then(function() {
            data.showWattpadPicks = !loggedOutNotEnglish;
            data.browseTopics = wattpad.utils.sortByColumns(
              collection.toJSON(),
              3
            );
            data.newCurationLists =
              curationProfile && self.showCurationPanel
                ? curatedLists.toJSON()
                : null;
            data.testGroups = wattpad.testGroups;
            self.renderAllViews(data);
          })
          .catch(function() {
            self.renderAllViews(data);
          });

        return this;
      },

      renderAllViews: function(templateData) {
        this.$el.html(this.template(templateData));

        if (this.searchFieldView) {
          this.$("#header-item-search").html(this.searchFieldView.render().$el);
        }
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);
        if (this.searchFieldView) {
          this.searchFieldView.setElement(this.$("#header-item-search > div"));
        }
      },

      onLanguageSelected: function(evt) {
        var $iframe = $("#languageModal iframe");

        $iframe.attr("src", "/settings/language?jq=true");
        $iframe[0].onload = function() {
          $iframe[0].contentWindow.callbackFn = function(language) {
            $("#languageModal").modal("hide");

            if (language) {
              wattpad.utils.destroyCookie("hc");
              window.location.reload();
            }
          };
        };

        $("#languageModal")
          .modal({ show: true })
          .removeClass("hide");
      },

      onAuthModal: function(evt) {
        var form = evt.currentTarget.getAttribute("data-form");
        if (_.get(window, "wattpad.testGroups.NEW_ONBOARDING_1", false)) {
          var view = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            formType: "navbar_modal",
            form: form,
            nextUrl: window.location.href
          });
          const classWrapper = form.concat("-modal-wrapper");
          $("#generic-modal .modal-body").html(view.render().$el);

          var elem = $("#generic-modal").find(".modal-dialog");

          elem.addClass(classWrapper);
          $("#generic-modal").modal("show");
        } else {
          var title =
            form === "signup"
              ? wattpad.utils.trans("Join Wattpad")
              : wattpad.utils.trans("Log in to Wattpad");
          var view = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            title: title,
            subtitle: wattpad.utils.trans(
              "Be part of a global community of readers and writers, all connected through the power of story."
            ),
            formType: "navbar_modal",
            form: form,
            nextUrl: window.location.href
          });
          const classWrapper = form.concat("-modal-wrapper");
          $("#generic-modal .modal-body").html(view.render().$el);

          var elem = $("#generic-modal").find(".modal-dialog");

          elem.addClass(classWrapper);
          $("#generic-modal").modal("show");
        }
      },

      onMyWorksButtonClick: function(evt) {
        var form = evt.currentTarget.getAttribute("data-form");
        if (_.get(window, "wattpad.testGroups.NEW_ONBOARDING_1", false)) {
          var view = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            title: wattpad.utils.trans(
              "Sign up to write on Wattpad and create the next big hit"
            ),
            form: "signup",
            formType: "navbar_modal",
            nextUrl: window.location.href
          });
          const classWrapper = form.concat("-modal-wrapper");
          $("#generic-modal .modal-body").html(view.render().$el);
          var elem = $("#generic-modal").find(".modal-dialog");

          elem.addClass(classWrapper);
          $("#generic-modal").modal("show");
        }
      },

      onPremium: function(evt) {
        window.te.push("event", "subscriptions", "cta", null, "view", {
          cta_type: "header_button"
        });
      },

      onTooltip: function(evt) {
        let elem = $(".write-tooltip");
        elem.remove();
        wattpad.utils.destroyCookie("seen-new-onboarding", true);
      },

      onNavigate: function(evt) {
        evt.preventDefault();
        app.views.Base.prototype.onNavigate.apply(this, arguments);
      },

      onLogout: function(evt) {
        wattpad.utils.destroyCookie("hc");
        window.localStorage.clear();
        wattpad.utils.redirectToServer("/logout");
      },

      // Add a delay so that if user's mouse goes over another category
      // when try to visit content next to this menu
      // the category won't be changed
      currentTimer: null,
      onCategoryHovered: function(evt) {
        this.currentTimer = setTimeout(function() {
          // Hide the currently active category
          var $active = this.$("#main-browse-navigate .active");
          $($active.data("id")).addClass("hide");
          $active.removeClass("active");

          // Reveal the selected category
          var $target = $(evt.target);
          $($target.data("id")).removeClass("hide");
          $target.addClass("active");
        }, 50);
      },

      onUnhoverCategory: function(evt) {
        clearTimeout(this.currentTimer);
      },

      onTopicClick: function(evt) {
        var target = $(evt.target).text();
        window.te.push("event", "browse", "item", null, "click", {
          page: "navigation",
          target: target
        });
      },
      onPopupClick: function(evt) {
        if ($(evt.target).attr("class") === "on-navigate on-topic") {
          $("#discover-dropdown").removeClass("open");
        }
        if ($(evt.target).attr("class") === "on-navigate") {
          $("#discover-dropdown").removeClass("open");
          $("#profile-dropdown").removeClass("open");
        }
        evt.stopPropagation();
      }
    })
  );
})(window, _, wattpad, window.app);
