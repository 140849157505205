(function(window, _, wattpad, utils, app) {
  "use strict";

  /*
   * @mixin ErrorHandler
   */

  app.add(
    "ErrorHandler",
    Monaco.Mixin.create({
      seenErrors: {}, //variable to keep track of models that the error occured on, so that we dont show the same error multiple times

      delegateEvents: function(events, next) {
        next(events);

        if (this.errorHandlerMixinDisabled) {
          return;
        }

        if (this.model && this.model instanceof Monaco.Model) {
          this.attachErrorHandlers(this.model);
        }
        if (this.collection && this.collection instanceof Monaco.Collection) {
          this.attachErrorHandlers(this.collection);
        }

        _.bindAll(this, "handlePromiseUnexpected", "handlePromiseExpected");
      },

      // this is the function that will actually handle the errors passed through
      // to it after normalization
      handleError: function(errors, options, next) {
        if (this.errorHandlerMixinDisabled) {
          return;
        }

        var toastOptions = {};
        if (options) {
          toastOptions.type = options.type ? options.type : "default";
        }
        _.each(errors, function(error, index) {
          toastOptions.delay = index;
          var toast = new app.views.ErrorToast(error, toastOptions);
          toast.render();
        });
        next();
      },

      attachErrorHandlers: function(obj, next) {
        this.listenTo(obj, "sync", this.handleUnexpected);
        this.listenTo(obj, "error", this.handleExpected);
        next();
      },

      detachErrorHandlers: function(obj, next) {
        this.stopListening(obj, "sync", this.handleUnexpected);
        this.stopListening(obj, "error", this.handleExpected);
        next();
      },

      // normalize errors received from 200 responses
      // push errors to the error queue
      handleUnexpected: function(model, response, options, next) {
        var errors = [],
          error = {};

        // comes from V2 API
        if (response.success === false || response.error_count > 0) {
          var currentTime = $.now() / 1000;

          if (this.seenErrors[model]) {
            //if this model has already errored out, check the time before we show it again
            if (currentTime - this.seenErrors[model] > 1) {
              this.seenErrors[model] = currentTime;
            } else {
              //if it's been less than a second since we last showed the error on the same model, dont show error
              this.seenErrors[model] = currentTime;
              return next();
            }
          } else {
            this.seenErrors[model] = currentTime;
          }

          if (response.error_count === 1) {
            error.message =
              response.error || _.first(_.values(response.errors));
            errors.push(error);
          } else {
            _.each(response.errors, function(err) {
              error.message = err;
              errors.push(_.clone(error));
            });
          }
          this.handleError(errors, options, next);
        }

        next();
      },

      // normalize and send error from 400 response
      handleExpected: function(model, response, options, next) {
        var currentTime = $.now() / 1000;

        if (this.seenErrors[model]) {
          //if this model has already errored out, check the time before we show it again
          if (currentTime - this.seenErrors[model] > 1) {
            this.seenErrors[model] = currentTime;
          } else {
            //if it's been less than a minute since we last showed the error on the same model, dont show error
            this.seenErrors[model] = currentTime;
            return next();
          }
        } else {
          this.seenErrors[model] = currentTime;
        }

        var errors = [],
          error = {};
        if (response.responseJSON) {
          error = response.responseJSON;
        }

        errors.push(error);
        this.handleError(errors, options, next);
        next();
      },

      // these two methods are used to catch exceptions that happen
      // from promises inside of views outside the bound events on
      // models' sync and error
      handlePromiseUnexpected: function(resp, options, next) {
        this.handleUnexpected({}, resp, options, next);
        next();
      },

      handlePromiseExpected: function(resp, options, next) {
        this.handleExpected({}, resp, options, next);
        next();
      },

      /*
     *  /\___/\
     * ( o   o )
     * (  =^=  )
     * (        )
     * (         )
     * (          )))))))))))
     */
      iCanHazErrors: function() {
        this.errorHandlerMixinDisabled = true;

        if (this.model && this.model instanceof Monaco.Model) {
          this.detachErrorHandlers(this.model);
        }
        if (this.collection && this.collection instanceof Monaco.Collection) {
          this.detachErrorHandlers(this.collection);
        }
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);

// FOR TESTING PURPOSES WILL REMOVE
window.app.callErrors = function(errors, options) {
  "use strict";
  app.mixins.ErrorHandler.prototype.constructor.mixinFunctions.handleError(
    errors,
    options,
    function() {}
  );
};
