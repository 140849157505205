import profileWorks from "platform/profile/templates/profile-works.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  // TODO: Combine with mobile-web user-works.js
  app.add(
    "ProfileWorks",
    app.views.IncrementalList.extend({
      className: "profile-works-content",
      template: profileWorks,

      // Item View properties
      itemView: app.views.DiscoverModuleStoriesLarge,
      containerId: "#works-item-view",

      events: _.extend(
        {
          "tap    .on-story-preview": "onStoryPreview",
          "click  .on-story-preview": "stopEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function(options) {
        var self = this;
        Promise.resolve(this.collection.fetchNextSet())
          .then(function() {
            self.render();

            // We wanted to display 3 stories on load
            // but fetch 10 stories at a time on 'show more'
            self.collection.limit = 10;
          })
          .then(this.afterRender.call(this));
      },

      afterRender: function() {
        var self = this;

        _.defer(function() {
          self.$('[data-toggle="tooltip"]').tooltip();
        });
      },

      getTemplateData: function() {
        var isProfileUser =
          utils.currentUser().get("username") === this.model.get("username");

        return {
          name: this.model.get("name") || this.model.get("username"),
          username: this.model.get("username"),
          totalWorks: this.collection.total,
          numPublished: isProfileUser
            ? this.collection.numPublished
            : this.collection.total,
          numDrafts: this.collection.numDrafts
        };
      },

      getItemViewOptions: function() {
        return {
          isProfileWorksView: true,
          user: {
            fullname: this.model.get("name") || null,
            name: this.model.get("username")
          },
          descCharLimit: 215,
          titleCharLimit: 70
        };
      },

      onStoryPreview: function(evt) {
        var storyId = $(evt.currentTarget).data("story-id");
        var previewModal = new app.views.BrowseStoryPreview({
          showTagRankings: true,
          model: this.collection.get(storyId)
        });

        this.$("#preview-modal")
          .empty()
          .append(previewModal.render().$el);
        previewModal.openModal();

        window.te.push("event", "profile", "works", "story", "click", {
          storyid: storyId,
          username: this.collection.username
        });
      }
    })
  );

  app.mixin(app.views.ProfileWorks, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
