(function(window, _, wattpad, $, utils, app) {
  "use strict";
  app.add(
    "RestrictedProfile",
    app.views.DummyReactView.extend({
      component: "RestrictedProfile",
      componentId: "restricted-profile",

      reportModalContainer: ".profile-options-modal",
      requestType: "reported_users", // request type for the form
      isMainReportModal: true,
      modalTitle: utils.trans("Report a User"),
      conductType: "user_account_conduct",
      events: {
        // user moderation
        "tap    .on-timeout-user": "onTimeoutUser",
        "tap    .on-close-user-account": "onCloseUserAccount"
      },

      constructor: function(opts) {
        this.reportModalType = "user";
        this.reportModalId = {
          name: opts.model?.get("username"),
          location: window.location.href
        };
        this.profileType = opts.profileType;
        this.model = opts.model;
        this.recentlyMuted = opts.recentlyMuted || false;
        this.recentlyBlocked = opts.recentlyBlocked || false;
        this.isMobile = opts.isMobile;
        this.isMuted = opts.isMuted || false;
        this.isAmbassador = utils.currentUser()?.get("ambassador");
        let options = {
          componentData: this.getComponentData(),
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      getComponentData: function() {
        return {
          safety: this.model?.get("safety"),
          profileType: this.profileType,
          username: this.model.get("username"),
          avatar: this.model.get("avatar"),
          name: this.model.get("name"),
          backgroundUrl: this.model.get("backgroundUrl"),
          numLists: this.model.get("numLists"),
          numStoriesPublished: this.model.get("numStoriesPublished"),
          numFollowers: this.model.get("numFollowers"),
          onUnmuteUser: this.onUnmuteUser.bind(this),
          onUnblock: this.onUnblockUser.bind(this),
          onBlock: this.onBlockUser.bind(this),
          recentlyMuted: this.recentlyMuted,
          recentlyBlocked: this.recentlyBlocked,
          isMobile: this.isMobile || app.get("device").is.mobile,
          isMuted: this.isMuted,
          isAmbassador: this.isAmbassador
        };
      },

      onUnmuteUser: function() {
        let self = this;

        // Perform a CSR of the newly unmuted profile page
        let viewData = self.getComponentData();

        const isPrivateToMe = self.model.get("isPrivate");
        let profileView;

        var userModel = new app.models.User({ username: viewData.username });
        Promise.resolve(userModel.loaded()).then(function() {
          userModel.set("safety", {
            isMuted: false,
            isBlocked: userModel?.get("safety")?.isBlocked
          });
          if (app.get("device").is.mobile) {
            profileView = new app.views.UserProfile({
              model: userModel,
              section: "about",
              username: viewData.username,
              recentlyUnmuted: true,
              isPrivateToMe: isPrivateToMe
            });
          } else {
            profileView = new app.views.ProfileLayout({
              model: userModel,
              section: "about",
              username: viewData.username,
              recentlyUnmuted: true,
              isPrivateToMe: isPrivateToMe
            });
          }

          // clear cached data for this user
          app.local.clear("user." + viewData.username);
          wattpad.utils.clearCommentLocalStorage();

          app.transitionTo(profileView, {
            username: viewData.username,
            hasHeader: true,
            hasFooter: true
          });
        });
      },

      reportMoreInfo: function() {
        return [{ key: "Reported User", val: window.location.href }];
      },

      reportUserName: function() {
        return this.model.get("username");
      },
      onCloseUserAccount: function(evt, win) {
        win = win || window;
        let el = document.getElementById("close-reason");
        let reason = el.value;

        if (!reason) {
          win.alert("You must select an option");
        } else {
          let reasonText = el.options[el.selectedIndex].text;

          let confirmation = `You are closing this users' account because of ${reasonText}. Are you sure?`;
          if (win.confirm(confirmation)) {
            $.ajax({
              type: "POST",
              url: "/api/v3/users/" + this.model.get("username") + "/close",
              data: {
                reason
              },
              success: function() {
                win.alert("successfully closed user account");
                win.location.reload();
              },
              error: function(data) {
                win.alert(JSON.parse(data.responseText).message);
              }
            });
          } else {
            win.alert("User's account has not been closed");
          }
        }
      },

      onTimeoutUser: function(evt, win) {
        win = win || window;
        let reason = document.getElementById("timeout-reason").value;

        if (reason === "") {
          win.alert("You must specify a reason");
        } else {
          let duration = "172800";
          $.ajax({
            type: "POST",
            url: "/v4/users/" + this.model.get("username") + "/timeout",
            data: {
              duration,
              reason
            },
            success: function() {
              win.alert("successfully timed-out user");
              win.location.reload();
            },
            error: function(data) {
              if (data.responseText) {
                win.alert(JSON.parse(data.responseText).message);
              } else {
                win.alert("Something went wrong. Please try again");
              }
            }
          });
        }
      },

      onUnblockUser: function(username) {
        let self = this;

        $.ajax({
          type: "DELETE",
          url: `/v4/users/${username}/blocks`,
          success: function() {
            window.store.dispatch(
              window.app.components.actions.setBlockedUsersFromBackBone(
                username,
                "unblock"
              )
            );
            // Perform a CSR of the newly unblocked profile page
            let viewData = self.getComponentData();

            const isPrivateToMe = self.model.get("isPrivate");
            let profileView;

            var userModel = new app.models.User({ username: username });
            Promise.resolve(userModel.loaded()).then(function() {
              userModel.set("safety", {
                isBlocked: false,
                isMuted: self.model?.get("safety")?.isMuted
              });
              // The following is for the use case where the user has previously been muted (Muted->Blocked->Unblocked)
              // Although the user has been unblocked, they are still muted
              if (userModel?.get("safety")?.isMuted) {
                app.local.clear("user." + username);
              } else {
                if (app.get("device").is.mobile) {
                  profileView = new app.views.UserProfile({
                    model: userModel,
                    section: "about",
                    username: viewData.username,
                    recentlyUnblocked: true,
                    isPrivateToMe: isPrivateToMe
                  });
                } else {
                  profileView = new app.views.ProfileLayout({
                    model: userModel,
                    section: "about",
                    username: viewData.username,
                    recentlyUnblocked: true,
                    isPrivateToMe: isPrivateToMe
                  });
                }
                // clear cached data for this user
                app.local.clear("user." + username);
                utils.clearCommentLocalStorage();
                utils.clearStoriesLocalStorage();

                app.transitionTo(profileView, {
                  username: viewData.username,
                  hasHeader: true,
                  hasFooter: true
                });
              }
            });
          },
          error: function() {
            var toast = new app.views.ErrorToast(
              {
                message: utils.trans(
                  "There was an error unblocking this user. Please try again."
                )
              },
              { type: "dismissable" }
            );
            toast.render();
          }
        });
      },
      onBlockUser: function(username) {
        let self = this;
        $.ajax({
          type: "PUT",
          url: `/v4/users/${username}/blocks`,
          success: function(response) {
            window.store.dispatch(
              window.app.components.actions.setBlockedUsersFromBackBone(
                username,
                "block"
              )
            );
            if (response.success === false) {
              let errorMessage;
              try {
                if (response?.error?.message) {
                  errorMessage = response.error.message;
                }
              } catch {
                errorMessage = utils.trans("There was an error blocking this user. Please try again."); //prettier-ignore
              }
              utils.showToast(errorMessage, {
                type: "dismissable"
              });
            } else {
              // Perform a CSR of the newly unmuted profile page
              let viewData = self.getComponentData();

              const isPrivateToMe = self.model.get("isPrivate");
              let profileView;

              var userModel = new app.models.User({ username: username });
              Promise.resolve(userModel.loaded()).then(function() {
                userModel.set("safety", {
                  isBlocked: true,
                  isMuted: self.model?.get("safety")?.isMuted
                });
                // The follwowing is for blocking a user who has already been muted
                if (userModel?.get("safety")?.isMuted) {
                  app.local.clear("user." + username);
                } else {
                  if (app.get("device").is.mobile) {
                    profileView = new app.views.UserProfile({
                      model: userModel,
                      section: "about",
                      username: viewData.username,
                      recentlyBlocked: true,
                      isPrivateToMe: isPrivateToMe
                    });
                  } else {
                    profileView = new app.views.ProfileLayout({
                      model: userModel,
                      section: "about",
                      username: viewData.username,
                      recentlyBlocked: true,
                      isPrivateToMe: isPrivateToMe
                    });
                  }
                  // clear cached data for this user
                  app.local.clear("user." + username);
                  utils.clearCommentLocalStorage();
                  utils.clearStoriesLocalStorage();

                  app.transitionTo(profileView, {
                    username: viewData.username,
                    hasHeader: true,
                    hasFooter: true
                  });
                }
              });
            }
          },
          error: function() {
            var toast = new app.views.ErrorToast(
              {
                message: utils.trans(
                  "There was an error blocking this user. Please try again."
                )
              },
              { type: "dismissable" }
            );
            toast.render();
          }
        });
      }
    })
  );

  app.mixin(app.views.RestrictedProfile, "ReportManagement", "ReportConduct");
})(window, _, wattpad, jQuery, wattpad.utils, window.app);
