"use strict";

export default function(query) {
  let app = window?.app;

  let locale = window?.wattpad?.utils?.getCookie("locale");
  const languages = app?.get("supported-languages");

  if (!locale || locale.trim().length === 0) {
    locale = "en_US"; //default to english
  }

  let title = "";
  const interfaceLang = languages.where({ locale: locale })[0].toJSON();

  if (query) {
    title = query;
  } else if (interfaceLang.code === "en") {
    title = "Wattpad - Where stories live";
  } else {
    title = `Wattpad ${interfaceLang.name} - ${window?.wattpad?.utils?.trans(
      "Where stories live"
    )}`;
  }

  return title;
}
