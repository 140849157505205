(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:library-archive",
    app.router.filter(["getTranslations", "getCategories"], function() {
      return wattpad.utils.redirectToServer("/archive");
      // var view = new app.views.LibraryLanding({ section: "archive" });

      // app.transitionTo(view, {
      //   hasHeader: true,
      //   hasFooter: true,
      //   pageTitle: wattpad.utils.trans("Library - Archive")
      // });

      // if (typeof __atha !== "undefined") {
      //   var id = __atha.sendPageView("library_archive");
      // }
    })
  );
})(window, _, jQuery, wattpad, window.app);
