import bottomBanner from "core/templates/shared/bottom-banner.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "BottomBanner",
    Monaco.View.extend({
      template: bottomBanner,
      className: "bottom-banner-container",

      events: {
        "tap .bottom-login": "onAuthModal",
        "click .bottom-login": "stopEvent",

        "tap .bottom-signup": "onAuthModal",
        "click .bottom-signup": "stopEvent",

        "tap .dismiss": "onDismiss",
        "click .dismiss": "stopEvent",

        "tap .on-add-story": "onAddToLibrary",
        "click .on-add-story": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};
        this.image = options.image;
        this.title = options.bannerTitle;
        this.showBottomBanner = options.showBottomBanner;
        this.dismissibleBanner = !wattpad.utils.getCookie("dB");
        this.fatBanner = options.fatBanner;
        this.listenTo(app, "app:story:vote", this.onUpdateVote);
        this.view = options.view || {};
        // To handle requests to login on browser from the AMP version of the download app banner
        this.openAuthModalOnLoad = window.location.hash === "#signin-popup";
      },

      render: function() {
        var data = {},
          branch_options = {},
          isTablet = app.get("device").is.tablet;
        data.showBottomBanner = this.showBottomBanner;
        data.bottomBannerImage = this.image;
        data.bottomBannerTitle = this.bannerTitle;
        data.dismissibleBanner = this.dismissibleBanner;
        data.fatBanner = this.fatBanner;
        data.isiOS = app.get("device").is.ios;
        data.isAndroid = app.get("device").is.android;

        if (["/presents", "/help/dmca"].includes(window.location.pathname)) {
          data.showBottomBanner = false;
        }

        // Set the element with template regardless if there's a deeplink callback because we
        // use css transition which needs the DOM element before style change event.
        this.$el.html(this.template(data));
        if (this.deepLink) {
          // branch_options.source = isTablet ? "tabletweb" : "mobileweb";
          // branch_options.page = this.deepLinkPage;
          // branch_options.content = "mobileweb";
          // branch_options.medium = isTablet
          //   ? "stickybar_tablet"
          //   : "stickybar_mobile";
          // var generateBranchLink = wattpad.utils.generateBranchLink(
          //   wattpad.utils.getDesktopUrl(),
          //   this.deepLink,
          //   branch_options
          // );
          // var self = this;
          // Promise.resolve(generateBranchLink).then(function(result) {
          //   var link = result;
          //   // Manually set open app link rather than passing it as data.branchLink
          //   // with template, since it is rendered before the call back.
          //   self.$(".open-app a").attr("href", link.url);
          //   self.$(".dismissible a").attr("href", link.url);
          //   self.$(".dismissible").addClass("show");
          //   self.$(".nondismissible").addClass("show");
          //   self.$(".bottom-banner a").attr("href", link.url);
          //   // WEB-6969 Hide the branding section to avoid duplication
          //   if (self.dismissibleBanner) {
          //     self.$(".branding").addClass("hidden");
          //   }
          // });
        }
        _.defer(this.afterRender.bind(this));
        return this;
      },

      afterRender: function() {
        if (this.openAuthModalOnLoad) {
          this.onAuthModal();
        }

        self.$(".open-app a").attr("href", this.deepLink);
        self.$(".dismissible a").attr("href", this.deepLink);
        self.$(".dismissible").addClass("show");
        self.$(".nondismissible").addClass("show");
        self.$(".bottom-banner a").attr("href", this.deepLink);
        if (self.dismissibleBanner) {
          self.$(".branding").addClass("hidden");
        }
      },

      // Once we know the page is loaded from a request to login from browser from the AMP banner,
      // we force the login modal to open. This is in order to replicate the same behaviour in the
      // non-AMP version
      onAuthModal: function(evt) {
        var form =
            typeof evt !== "undefined"
              ? evt.currentTarget.getAttribute("data-form")
              : "login",
          title =
            form === "signup"
              ? wattpad.utils.trans("Join Wattpad")
              : wattpad.utils.trans("Log in to Wattpad"),
          view = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            formType: "navbar_modal",
            form: form,
            skipOnboarding: true
          });
        if (form === "login") {
          $("#generic-modal .modal-dialog").addClass("login-modal-wrapper");
        } else if (form === "signup") {
          $("#generic-modal .modal-dialog").addClass("signup-modal-wrapper");
        }
        $("#generic-modal .modal-content").addClass("auth-modal");
        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal").modal("show");
      },

      onAddToLibrary: function(evt) {
        if (!wattpad.utils.currentUser().authenticated()) {
          this.onAuthModal(evt);
        }
      },
      onDismiss: function(evt) {
        utils.stopEvent(evt);
        this.$(".dismissible").slideUp("50");
        this.$(".dismissible").removeClass("show");
        // WEB-6969 Show the branding section if exists
        this.$(".branding").removeClass("hidden");
        // Set session cookie dB for dismiss bottom banner.
        wattpad.utils.setCookie("dB", 1, 0);
      },

      setDeepLink: function(deepLink, page) {
        /**
         * https://wattpad.atlassian.net/browse/RJ-3913
         *
         * Manually override deepLink to allow redirect to
         * app store if user does not have app installed.
         *
         * This breaks app context linking (e.g. wattpad://story/12432334/part/23023)
         * but app download is top priority for upcoming campaign in India.
         */
        deepLink = "https://read.w.tt/f4RR/eadd1193";

        this.deepLink = deepLink;
        this.deepLinkPage = page;
        this.render();
      },
      onUpdateVote: function(data) {
        if (!data || data.storyId !== this.model.get("id")) {
          return;
        }
        if (data.voted) {
          this.voteSelected();
        } else {
          this.voteDeselected();
        }
      },

      voteSelected: function() {
        var newIcon = wattpad.utils.iconify("fa-vote", 16, "wp-base-1"),
          newText = wattpad.utils.trans("Voted");
        this.$("#funbar-controls .on-vote .fa-vote").replaceWith(newIcon);
        this.$("#funbar-controls .on-vote .story-vote").text(newText);
      },

      voteDeselected: function() {
        var newIcon = wattpad.utils.iconify("fa-vote", 16, "wp-neutral-3"),
          newText = wattpad.utils.trans("Vote");
        this.$("#funbar-controls .on-vote .fa-vote").replaceWith(newIcon);
        this.$("#funbar-controls .on-vote .story-vote").text(newText);
      },

      getSharingItems: function(data) {
        var mainCategory;
        if (data.group.category) {
          mainCategory = app
            .get("categories")
            .get(data.group.category)
            .get("name");
        }
        var meta = {
          group: {
            id: data.group.id,
            title: data.group.title,
            cover: data.group.cover,
            category: mainCategory,
            description: data.group.description,
            tags: _.map(data.group.tags, function(tag) {
              return { name: tag };
            }).concat([{ name: mainCategory }]),
            completed: data.group.completed
          },
          part: {
            id: data.id,
            title: data.title,
            url: data.url
          },
          user: {
            twitter: data.group.user.twitter
          }
        };
        return this.buildSocialShareLinksForPart(meta);
      }
    })
  );

  app.mixin(
    app.views.BottomBanner,
    "VoteManagement",
    "LibraryManagement",
    "SocialSharing"
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
