(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "StorySearch",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.StoryGroup,

      arrayKey: "stories",

      expireLocal: 5,

      fields: [
        {
          stories: [
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "cover",
            "tags",
            "rating",
            "url",
            "id",
            "description",
            "categories",
            {
              user: ["name"]
            }
          ]
        },
        "total",
        "nextUrl"
      ],

      initialize: function(collection, options) {
        options = options || {};

        this.searchTerm = options.searchTerm;
        this.filterComplete = options.filterComplete || false;

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
        this.parentFetchNextSet =
          app.collections.IncrementalFetchNextUrl.prototype.fetchNextSet;
      },

      fetchNextSet: function() {
        if (this.searchTerm !== "") {
          return app.collections.IncrementalFetchNextUrl.prototype.fetchNextSet.apply(
            this,
            arguments
          );
        }

        // if fetch conditions are not met, return a rejected promise
        // TODO: update to A+/es6 compliance
        return $.Deferred()
          .resolve()
          .promise();
      },

      resource: function() {
        return (
          "search-results.story.query-" +
          this.searchTerm +
          this.completeFilter()
        );
      },

      completeFilter: function() {
        if (this.filterComplete) {
          return "&filter=complete";
        }
        return "";
      },

      defaultUrl: function() {
        return (
          "/api/v3/stories/?query=" +
          encodeURIComponent(this.searchTerm) +
          this.completeFilter()
        );
      }
    })
  );
})(window, _, wattpad, window.app);
