var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <li class=\"list-group-item\">\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.aggregates.item_views.feed_comment_item"],depth0,{"name":"core.feed_items.aggregates.item_views.feed_comment_item","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<button data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"btn btn-text on-expand\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"See more",{"name":"trans","hash":{},"data":data}))
    + "</button>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=container.escapeExpression, alias2=depth0 != null ? depth0 : (container.nullContext || {}), alias3=helpers.helperMissing;

  return "<header class=\"panel-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm2 on-navigate\" href=\"/user/"
    + alias1(container.lambda(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n      <img src=\""
    + alias1((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias3).call(alias2,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <h3 class=\"h6\">"
    + alias1((helpers.trans || (depth0 && depth0.trans) || alias3).call(alias2,"%s posted %s comments",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),(depth0 != null ? depth0.groupCount : depth0),{"name":"trans","hash":{},"data":data}))
    + "</h3>\n    <time datetime=\""
    + alias1(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias3),(typeof helper === "function" ? helper.call(alias2,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias1((helpers.fromNow || (depth0 && depth0.fromNow) || alias3).call(alias2,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_options"],depth0,{"name":"core.feed_items.feed_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n\n<ul class=\"list-group\">\n"
    + ((stack1 = helpers.each.call(alias2,(depth0 != null ? depth0.group : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</ul>\n\n<footer class=\"panel-footer\">\n  "
    + ((stack1 = helpers["if"].call(alias2,((stack1 = (depth0 != null ? depth0.children : depth0)) != null ? stack1["0"] : stack1),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</footer>\n";
},"usePartial":true,"useData":true});