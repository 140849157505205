import newPost from "core/templates/new-post.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "NewPost",
    Monaco.View.extend({
      // Properties
      // ----------
      className: "new-post",
      template: newPost,

      events: {
        "focus  textarea": "onTextInputFocus",
        "blur   textarea": "onTextInputBlur",
        "keyup  textarea": "onTextInput",

        "tap    .on-post": "onPost",
        "click  .on-post": "stopEvent",

        keyup: "onKeyUp"
      },

      // Methods
      // -------
      initialize: function(options) {
        options = options || {};
        this.placeholder = options.placeholder || "";
        this.source = options.source || null;
        _.bindAll(this, "onKeyUp");
      },

      render: function() {
        var data = {};
        data.avatar = wattpad.utils.currentUser().get("avatar");
        data.placeholder = this.placeholder;
        data.viewId = this.getViewId();

        this.$el.html(this.template(data));
        return this;
      },

      onTextInputFocus: function() {
        if (!utils.currentUser().authenticated()) {
          var $inlineComment = $(
            "#inline-comments-modal .inline-comments-modal"
          );
          if ($inlineComment) {
            $inlineComment.modal("hide");
          }
          var view = new app.views.SignUpPrompt({
            model: new app.models.Authsignup(),
            title: wattpad.utils.trans("Join Wattpad"),
            subtitle: wattpad.utils.trans(
              "Be part of a global community of readers and writers, all connected through the power of story."
            ),
            nextUrl: window.location.href
          });
          $("#generic-modal .modal-content").addClass("auth-modal");
          $("#generic-modal .modal-body").html(view.render().$el);
          $("#generic-modal").modal("show");
          return;
        } else if (!utils.currentUser().attributes.verified_email) {
          var $inlineComment = $(
            "#inline-comments-modal .inline-comments-modal"
          );
          if ($inlineComment) {
            $inlineComment.modal("hide");
          }
          $(".count-input").blur();
          utils.showPleaseVerifyModal();
          return;
        }

        var textarea = this.$("textarea"),
          self = this;

        if (!textarea.hasClass("active")) {
          this._setActiveState();

          window.setTimeout(function() {
            //necessary for chrome
            self.moveCaretToEnd(textarea[0]);
          }, 1);
        }
      },

      //moves the cursor to the end of the textarea
      moveCaretToEnd: function(element) {
        if (!element) {
          return;
        }
        if (typeof element.selectionStart == "number") {
          element.selectionStart = element.selectionEnd = element.value.length;
        } else if (typeof element.createTextRange != "undefined") {
          //I.E. support
          element.focus();
          var range = element.createTextRange();
          range.collapse(false);
          range.select();
        }
      },

      onTextInputBlur: function() {
        var self = this;
        if (!this.$("textarea").val()) {
          this.blurTimeout = window.setTimeout(function() {
            self._setInactiveState();
          }, 500);
        }
      },

      _setActiveState: function() {
        this.$("textarea").val("");
        this.$("textarea").addClass("active");
        this.$(".post-ui")
          .removeClass("hidden")
          .children("button")
          .prop("disabled", true);
      },

      _setInactiveState: function() {
        this.$("textarea").removeClass("active");
        this.$(".post-ui").addClass("hidden");
      },

      onTextInput: function(evt) {
        var message = this.$("textarea")
            .val()
            .trim(),
          $postBtn = this.$(".post-ui button");

        if (message === "") {
          $postBtn.prop("disabled", true);
        } else {
          $postBtn.prop("disabled", false);
        }
      },

      //TODO: Implement this on your own, children!
      onPost: function() {
        return;
      },

      addMention: function(name) {
        var prefixMentionPattern = /^(\@[\w\-]+)\s/i,
          mentionText = "@" + name + " ",
          oldTextVal = this.$("textarea").val(),
          newTextVal;

        // text area not empty, there's an old mention
        if (prefixMentionPattern.test(oldTextVal)) {
          newTextVal = oldTextVal.replace(
            prefixMentionPattern.exec(oldTextVal)[0],
            mentionText
          );
          // text area not empty, there's no old mention
        } else if (
          $.trim(oldTextVal) !== "" &&
          oldTextVal !== this.placeholder
        ) {
          newTextVal = mentionText + oldTextVal;
        } else {
          //every other case
          newTextVal = mentionText;
        }
        this._setActiveState();
        this.$("textarea").focus();
        this.$("textarea").val(newTextVal);
      },

      onKeyUp: function(event) {
        if (event.which === 27) {
          this.$("textarea").blur();
        }
      },

      focus: function() {
        this.$("textarea").trigger("focus");
      },

      onPostContentUpdate: function(isAcceptable) {
        this.$(".on-post").prop("disabled", !isAcceptable);
        if (isAcceptable) {
          this.$(".post-counter").removeClass("count-error");
        } else {
          this.$(".post-counter").addClass("count-error");
        }
      },

      getViewId: function() {
        return "new-post-" + Date.now();
      },

      onError: function(warningMessage) {
        this.$(".warning > .text").text(warningMessage);
        this.$(".warning").removeClass("hidden");
        this.$(".post-ui button").prop("disabled", false);
      }
    })
  );

  app.mixin(app.views.NewPost, "InputCounterManagement");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
