var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dedicatee : stack1), depth0),{"name":"data.dedicatee","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <a class=\"on-navigate\" href=\"/user/"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\">\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.backgroundUrl : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.program(5, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "\n                <div class=\"avatar avatar-lg pull-left\">\n                    <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),72,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n                </div>\n                <div class=\"content\">\n                    <h4 class=\"h3\">"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n                    <small>\n                        @"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numWorks : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                    </small>\n                </div>\n                <button class=\"btn btn-triangle btn-fan\" data-target=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-following=\""
    + alias4(((helper = (helper = helpers.following || (depth0 != null ? depth0.following : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"following","hash":{},"data":data}) : helper)))
    + "\">\n                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n                    <span class=\"toggle-triangle\"></span>\n                    <span class=\"toggle-shadow\"></span>\n                </button>\n            </header>\n        <a/>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "            <header class=\"panel-heading\" style=\"background-image: url( "
    + container.escapeExpression((helpers.profileBackground || (depth0 && depth0.profileBackground) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.backgroundUrl : depth0),640,{"name":"profileBackground","hash":{},"data":data}))
    + " );\">\n";
},"5":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1;

  return "            <header class=\"panel-heading\" style=\"background-color: "
    + container.escapeExpression(container.lambda(((stack1 = (depths[2] != null ? depths[2].data : depths[2])) != null ? stack1.highlight_colour : stack1), depth0))
    + ";\">\n";
},"7":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "&middot; "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Work","%s Works",(depth0 != null ? depth0.numWorks : depth0),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numWorks : depth0),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}));
},"9":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "&middot; "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Follower","%s Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, alias5="function", buffer = 
  "<header class=\"panel-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm2 on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dedicator : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n      <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dedicator : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <h3 class=\"h6\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"%s dedicated %s - %s to %s",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dedicator : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.title : stack1),((stack1 = ((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.part : stack1)) != null ? stack1.title : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.dedicatee : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</h3>\n    <time datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === alias5 ? helper.call(alias3,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_options"],depth0,{"name":"core.feed_items.feed_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n\n<div class=\"panel-body\">\n    <div class=\"panel dedication\">\n\n";
  stack1 = ((helper = (helper = helpers.outgoing || (depth0 != null ? depth0.outgoing : depth0)) != null ? helper : alias4),(options={"name":"outgoing","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data}),(typeof helper === alias5 ? helper.call(alias3,options) : helper));
  if (!helpers.outgoing) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\n        <ul class=\"list-group\">\n            <li class=\"list-group-item single-item\">\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.aggregates.item_views.feed_vote_item"],depth0,{"name":"core.feed_items.aggregates.item_views.feed_vote_item","data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </li>\n        </ul>\n    </div>\n</div>\n\n<footer class=\"panel-footer\">\n    <a href=\""
    + alias2((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"btn btn-text on-navigate\" data-story-id=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.id : stack1), depth0))
    + "\">\n        "
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"Story details",{"name":"trans","hash":{},"data":data}))
    + "\n    </a>\n</footer>\n\n";
},"usePartial":true,"useData":true,"useDepths":true});