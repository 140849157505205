import storiesSimilar from "core/templates/collection-views/stories-similar.hbs";
import shareTools from "core/templates/components/share-tools.hbs";
import addToLibraryDropdown from "core/templates/components/add-to-library-dropdown.hbs";
import partAuthorInfo from "platform/story-reading/templates/part-author-info.hbs";
import shareEmbed from "platform/components/templates/modal/share-embed.hbs";
import desktopStoryReading from "platform/story-reading/templates/desktop-story-reading.hbs";
import formatScheduledPartDate from "../../../../../helpers/format-scheduled-part-date";

(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "StoryReading",
    app.views.CoreStoryReading.extend({
      template: desktopStoryReading,

      isDesktop: true,

      partials: _.extend(app.views.CoreStoryReading.prototype.partials, {
        "core.shared.bottom_banner": function() {
          return "";
        },
        "desktop.components.modal.share_embed": shareEmbed,
        "desktop.story_reading.part_author_info": partAuthorInfo,
        "core.components.add_to_library_dropdown": addToLibraryDropdown,
        "core.components.share_tools": shareTools,
        "core.story_reading.cta": function() {
          return "";
        }
      }),

      events: _.extend(app.views.CoreStoryReading.prototype.events, {
        "mouseenter .on-hover": "onHover",
        "mouseexit  .on-hover": "offHover",
        "tap   .on-comments": "stopEvent",
        "click .on-comments": "onComments",

        "mouseenter .panel-reading [data-media-type=image]": "onImageHoverOn",
        "mouseleave .panel-reading [data-media-type=image]": "onImageHoverOut",
        "tap        .on-report-image": "onReportImage",
        "click      .on-report-image": "stopEvent",

        "tap        .on-report-image-banner": "onReportImageBanner",
        "click      .on-report-image-banner": "stopEvent",

        // .fixed-ratio images are ones wider than the container
        "tap    .panel-reading .fixed-ratio[data-media-type=image] img":
          "onShowFullSizeImage",
        "click  .panel-reading .fixed-ratio[data-media-type=image] img":
          "stopEvent",
        "tap    .panel-reading .fixed-ratio[data-media-type=image] .image":
          "onShowFullSizeImage",
        "click  .panel-reading .fixed-ratio[data-media-type=image] .image":
          "stopEvent",

        "tap .on-full-size-banner": "onFullSizeBanner",
        "click .on-full-size-banner": "stopEvent",

        // To prevent readers from selecting and dragging story text out of the browser window
        "dragstart .page": "detectTextDrag"
      }),

      regions: {},

      subviews: {
        funbar: app.views.StoryReadingFunbar,
        media: app.views.MediaBanner,
        comments: app.views.Comments,
        bottomBanner: app.views.BottomBanner
      },

      // With the quote sharing feature we've allowed users to highlight story text, but this means they're also
      // able to drag the text out of the browser window and into MS Word for example. We don't like that.
      detectTextDrag: function(event) {
        // temporary GA event to get a sense of how often this is happening
        // wattpad.utils.pushEvent( {
        //   category: 'story-text',
        //   action:   'drag',
        //   label:    this.model.get( 'id' )
        // } );
        // now kill the event
        this.stopEvent(event);
      },

      initialize: function(options) {
        _.bindAll(this, "trackWidgetStoryVisibility");

        app.views.CoreStoryReading.prototype.initialize.apply(this, arguments);

        this.listenTo(app, "app:user:follow", this.onUpdateFollow);
        this.listenTo(app, "app:user:unfollow", this.onUpdateUnfollow);
      },

      render: function() {
        app.views.CoreStoryReading.prototype.render.apply(this, arguments);
        var self = this,
          data = this.model.toJSON();

        if (data.group.isPaywalled) {
          const { paidMetadata } = window.store.getState();
          this.processPaidMetadata(data, paidMetadata);
          data.onBuyCoins = () => this.onBuyCoins(data.blockedPart);
        }

        if (
          data.group.user?.username ==
          wattpad.utils.currentUser().get("username")
        ) {
          data.group.parts.map(part => {
            part.isScheduledPart = part.hasOwnProperty(
              "scheduledPublishDatetime"
            );
            part.formattedScheduledPublishDatetime = part.isScheduledPart
              ? formatScheduledPartDate(part.scheduledPublishDatetime)
              : null;
          });
        }

        data.text = this.options.storyText;
        data.firstPage = data.pageNumber === 1;
        data.lastPage = data.pageNumber >= data.pages;
        data.nextPage =
          data.pageNumber < data.pages
            ? data.url.replace(
                /\.(com|io)\/(.*)/,
                ".$1/$2/page/" + (data.pageNumber + 1)
              )
            : "";
        data.isMicroPart = data.pages < 2;
        data.isSmallPart = data.pages >= 2 && data.pages < 5;
        data.isMediumPart = data.pages >= 5 && data.pages < 12;
        data.isBigPart = data.pages >= 12;
        data.group.category = _.find(data.group.categories, function(category) {
          return category > 0;
        });
        data.isAdmin = wattpad.utils.currentUser().get("isSysAdmin");

        var mainCategory;
        if (data.group.category) {
          mainCategory = app
            .get("categories")
            .get(data.group.category)
            .get("name");
        }

        var device = app.get("device");

        var meta = {
          group: {
            id: data.group.id,
            title: data.group.title,
            cover: data.group.cover,
            category: mainCategory,
            description: data.group.description,
            tags: _.map(data.group.tags, function(tag) {
              return { name: tag };
            }).concat([{ name: mainCategory }]),
            completed: data.group.completed
          },
          part: {
            id: data.id,
            title: data.title,
            url: data.url
          },
          user: {
            twitter: data.group.user.twitter
          }
        };

        var currentIndex = _.findIndex(data.group.parts, { url: data.url });
        if (currentIndex < data.group.parts.length - 1) {
          data.nextPart = data.group.parts[currentIndex + 1];
        }

        data.isTablet = device.is.tablet;
        data.isDesktop = device.is.desktop;

        var socialLinks = (this.socialLinks = this.buildSocialShareLinksForPart(
          meta
        ));

        var mediaShare = [];
        mediaShare.push(socialLinks[0]);
        mediaShare.push(socialLinks[2]);

        data.socialShareHidden = socialLinks.splice(4, 2);
        data.socialShareVisible = socialLinks;

        // remove "embed" on mobile (last element in socialShareVisible)
        if (device.is.mobile) {
          data.socialShareVisible.pop();
        }

        data.mediaShare = mediaShare;

        data.socialShareHidden.push({
          name: "report",
          icon: "fa-warning",
          label: utils.trans("Report Story"),
          href: utils.sprintf(
            "http://www.wattpad.com/help/content?message=I+am+reporting+the+following+content%3A%0A%s&story_link=%s",
            [
              window.encodeURIComponent(meta.part.url),
              window.encodeURIComponent(meta.part.url)
            ]
          ),
          partId: data.id.toString(),
          storyId: data.group.id.toString(),
          storyTitle: data.group.title,
          author: data.group.user?.username,
          language: data.group.language?.name
        });

        data.anonymousUser = !wattpad.utils.currentUser().authenticated();

        _.defer(function() {
          // MON-560 - left rail isn't rendered if current part is blocked, so don't affix in that case
          // TODO: fix behavior so affix works in all cases
          if (!data.currentPartIsBlocked) {
            self.initAffix(".left-rail", ".part-content", 128);

            $(window).on("scroll", function() {
              self.affixOnScroll();
            });
          }

          self.$(".author [data-toggle=tooltip]").tooltip();
          self.$(".meta [data-toggle=tooltip]").tooltip();

          //Story Reading Similar Stories
          //We need to clear it as we registered empty partials in the DOM
          if (!self.model.get("deleted")) {
            data.descCharLimit = data.isDesktop ? 170 : 130;

            var promotedStoryView = new app.views.PromotedStoryItem({
              storyId: self.model.get("group").id,
              baseStoryCategory: self.model.get("group").categories[0],
              zoneIds: data.isMicroPart ? [170121] : [155799],
              template: storiesSimilar,
              fromPage: "storyReading",
              descCharLimit: data.descCharLimit,
              promotedEl: ".promoted-stories-container"
            });

            self.setRegion(".promoted-stories", promotedStoryView.render());

            // Don't render if similar-stories doesn't exist in DOM.
            if (self.$(".similar-stories").length) {
              var view = new app.views.StoriesSimilar({
                filter: "similar",
                storyId: self.model.get("group").id,
                //if the number of pages is 2 or more, we show 7 similar stories. Otherwise, show 2.
                //this allows an appropriate number of stories shown depending on the spacing available.
                limit: data.pages > 1 ? 7 : 2,
                currentStory: self.model,
                fromStoryReading: true,
                showDescription: true,
                alwaysFetch: true
              });
              self.setRegion(".similar-stories", view.render());
            }

            self.trackedSimilarStoryIds = {};
            $(window).on("scroll", self.trackWidgetStoryVisibility);

            self.$(".similar-stories-container").removeClass("hidden");

            if (wattpad.utils.currentUser().authenticated()) {
              var promotedStoryView = new app.views.PromotedStoryItem({
                storyId: self.model.get("group").id,
                baseStoryCategory: self.model.get("group").categories[0],
                zoneIds: [170121],
                fromPage: "storyReading",
                promotedEl: ".promoted-recommended-stories-view"
              });

              self.setRegion(
                ".promoted-recommended-stories-view",
                promotedStoryView.render()
              );

              var recommendedStoryCollection = new app.collections.RecommendedStories(
                [],
                {
                  username: wattpad.utils.currentUser().get("username"),
                  limit: 8
                }
              );
              Promise.resolve(recommendedStoryCollection.fetchNextSet()).then(
                function() {
                  var recommendedStoryItems = {
                    items: {
                      stories: recommendedStoryCollection.toJSON()
                    }
                  };
                  var recommendedStoriesModel = new Monaco.Model();
                  recommendedStoriesModel.set("data", recommendedStoryItems);
                  recommendedStoriesModel.set(
                    "type",
                    "storiesReadingRecommended"
                  );

                  var recommendedStories = new app.views.DiscoverModule({
                    model: recommendedStoriesModel,
                    experience: "story_reading"
                  });
                  recommendedStories.setElement($(".recommended-stories-view"));
                  recommendedStories.render();
                }
              );
            }
          }
        });

        data.isDesktop = this.isDesktop;

        //set cookie for signup form
        wattpad.utils.setCookie(
          "signupFrom",
          "story_reading",
          0,
          window.location.hostname.replace("www", "")
        );

        //send event for reading time tracking
        var publishedParts = utils.publishedPartsInStory(data.group);
        window.te.push("event", "reading", null, null, "start", {
          storyid: data.group.id,
          partid: data.id,
          published_parts: publishedParts.length
        });

        this.$el.html(this.template(data));
        this.setRegions(data.currentPartIsBlocked);

        if (this.options.isPostPublishModal) {
          _.defer(function() {
            /* Show Broadcast variation for post publish modal if it is a first publish.
           */
            self.showPostPublishModal(self.options.isFirstPublish || false);
          });
        }

        return this;
      },

      showPostPublishModal: function(showBroadcastMessaging) {
        if (showBroadcastMessaging) {
          window.te.push(
            "event",
            "modal",
            "story_publish",
            "broadcast_message",
            "view",
            {
              storyid: this.model.get("group").id
            }
          );
        }

        var postPublishModal = new app.views.PostPublishModal({
          model: this.model,
          links: this.socialLinks.slice(),
          showBroadcastMessaging: showBroadcastMessaging
        });
        this.$("#post-publish-modal")
          .empty()
          .append(postPublishModal.render().$el);
        postPublishModal.showModal();
      },

      //For the LibraryManagement mixin
      onAddToListsReady: function($btn, storyId) {
        this.clearLists();
        $btn.parent(".button-group").addClass("open");
        $btn.removeClass("on-lists-add-clicked");
        $btn.addClass("on-dismiss-lists");

        $(window.document).one(
          "click.bs.dropdown.data-api",
          { context: this },
          this.clearLists
        );
      },

      //Custom dropdown dismiss behaviour
      clearLists: function(event) {
        let listClicked = false;

        if (event && event.which === 3) {
          return false;
        }

        var $el = null;
        if (event) {
          $el = $(event.target);
        }

        var btns = $(".btn-story-lists");
        _.each(btns, function(btn) {
          var $btn = $(btn);
          if (!$el || $btn.parent(".button-group").find($el).length === 0) {
            $btn.parent(".button-group").removeClass("open");
            $btn.removeClass("on-dismiss-lists");
            $btn.addClass("on-lists-add-clicked");
          } else if ($el) {
            listClicked = true;
          }
        });

        if (listClicked) {
          var ctx = event.data.context;
          $(window.document).one(
            "click.bs.dropdown.data-api",
            { context: ctx },
            ctx.clearLists
          );
        }
        return true;
      },

      onDismissLists: function(event) {
        var $btn = $(event.currentTarget);

        $btn.parent(".button-group").removeClass("open");
        $btn.removeClass("on-dismiss-lists");
        $btn.addClass("on-lists-add-clicked");
      },

      buildSocialShareLinksForPart: function(meta) {
        return [
          {
            name: "post-to-profile",
            icon: "fa-user",
            nopopup: true,
            label: wattpad.utils.trans("Post to Your Profile"),
            href: "/"
          },
          {
            name: "embed",
            icon: "fa-code",
            nopopup: true,
            label: wattpad.utils.trans("Embed Story"),
            href: wattpad.utils.sprintf("https://embed.wattpad.com/story/%s", [
              meta.group.id
            ])
          }
        ];
      },

      onShareEmbed: function(event) {
        var $modal = $("#generic-modal");
        var self = this;

        utils.stopEvent(event);

        $modal
          .find(".modal-dialog")
          .addClass("embed")
          .find(".modal-body")
          .html(
            this.partials["desktop.components.modal.share_embed"]({
              id: this.model.get("group").id,
              close: true
            })
          );

        var $embedCode = $modal
          .find(".on-select")
          .on("tap", this.onSelectEmbedUrl);

        var $iframe = $modal.find("iframe");
        $iframe.attr("src", $iframe.data("href"));

        var cleanup = function() {
          $embedCode.off("tap", self.onSelectEmbedUrl);
          $modal.off("hide.bs.modal", cleanup);
        };

        $modal.modal("show").on("hide.bs.modal", cleanup);
      },

      onSelectEmbedUrl: function() {
        var range = window.document.createRange();
        range.selectNode(window.document.getElementById("embed-url"));
        window.getSelection().addRange(range);
      },

      onComments: function(evt) {
        var scrollTop,
          self = this;
        const id = "story-part-comments";
        this.lockScrollUpdate();
        _.defer(function() {
          scrollTop = self.$(`#${id}`)?.offset()?.top;
          window.scrollTo({
            top: scrollTop - Math.min(window.innerHeight * 0.5),
            behavior: "smooth"
          });
        });
      },

      onHover: function(evt) {
        $(evt.currentTarget).tooltip("show");
      },

      offHover: function(evt) {
        $(evt.currentTarget).tooltip("show");
      },

      onImageHoverOn: function(evt) {
        var $paragraph = $(evt.currentTarget),
          $image = $paragraph.find("img"),
          position = $image.position(),
          width = $image.width(),
          height = $image.height(),
          shareConainer = $(".image-options-container").clone();

        var sharePinterest = shareConainer.find(".share-pinterest");
        var pinterestUrl = sharePinterest.attr("href"),
          imageUrl = $image.attr("src");

        pinterestUrl = this.preparePinterestUrl(imageUrl, pinterestUrl);

        sharePinterest.attr("href", pinterestUrl);

        $paragraph.append(
          '<div class="image-options">' + shareConainer.html() + "</div>"
        );

        var $imageOptions = $paragraph.find(".image-options");
        $imageOptions.css({ top: position.top + height, left: position.left });
        $imageOptions.width(width);
        $imageOptions.removeClass("hide");
      },

      onImageHoverOut: function(evt) {
        this.$(".image-options").remove();
      },

      onReportImage: function(evt) {
        var imageLink = $(evt.currentTarget)
          .parents("p")
          .find("img")
          .attr("src");

        var url = utils.sprintf(
          "http://www.wattpad.com/help/content/1?story_link=%s&media_link=%s",
          [window.encodeURIComponent(window.location.href), imageLink]
        );
        return utils.openPopup(url, utils.trans("Report Story"), 700, 400);
      },

      onReportImageBanner: function(evt) {
        var imageLink = $(".on-report-image-banner")
          .parent()
          .parent()
          .siblings(".media-item")
          .children("img")
          .attr("src");
        var url = utils.sprintf(
          "http://www.wattpad.com/help/content/1?story_link=%s&media_link=%s",
          [window.encodeURIComponent(window.location.href), imageLink]
        );
        return utils.openPopup(url, utils.trans("Report Story"), 700, 400);
      },

      onUpdateFollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn.removeClass("btn-white").addClass("btn-teal");
        $btn
          .children(".fa")
          .removeClass("fa-follow fa-wp-neutral-2")
          .addClass("fa-following fa-wp-neutral-5");
        $btn.children(".follow-text").text("Following");
      },

      onUpdateUnfollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn.removeClass("btn-teal").addClass("btn-white");
        $btn
          .children(".fa")
          .removeClass("fa-following fa-wp-neutral-5")
          .addClass("fa-follow fa-wp-neutral-2");
        $btn.children(".follow-text").text("Follow");
      },

      trackWidgetStoryVisibility: _.throttle(function(evt) {
        var $similarStories = $("#similar-stories .on-story-preview"),
          baseStoryId = this.model.get("group").id,
          numVisibleSimilarStories = $similarStories.filter(":visible").length,
          self = this;

        // If all the events for the similar stories module have been sent, remove the event handler listening for scroll events.
        if (
          numVisibleSimilarStories > 0 &&
          numVisibleSimilarStories === _.size(self.trackedSimilarStoryIds)
        ) {
          $(window).off("scroll", self.trackWidgetStoryVisibility);
          return;
        }

        _.each($similarStories, function(element) {
          var $element = $(element);
          if (utils.isOnScreen($element, 1)) {
            var storyId = $element.data("story-id");

            if (!self.trackedSimilarStoryIds[storyId]) {
              window.te.push(
                "event",
                "reading",
                "similar_stories",
                "story",
                "view",
                {
                  current_storyid: baseStoryId,
                  storyid: storyId
                }
              );
              self.trackedSimilarStoryIds[storyId] = true;
            }
          }
        });
      }, 350),

      remove: function() {
        $(window).off("scroll", this.trackWidgetStoryVisibility);
        app.views.CoreStoryReading.prototype.remove.apply(this, arguments);
      },

      showFullSizeMedia: function(bounds, isBanner) {
        // Put the dialog directly overlaying the image, and then let the browser
        // (through transitions) animate us to the right spot.
        var viewer = $("#picture-viewer");

        if (bounds) {
          // First constrain
          viewer.find(".modal-dialog").css({
            margin: 0,
            width: "100%"
          });
          viewer.find(".modal-content").css("border", "none");
          viewer.find(".modal-body").css("padding", 0);

          viewer.css({
            display: "block",
            width: bounds.width,
            height: bounds.height,
            left: bounds.left,
            top: bounds.top
          });

          _.delay(function() {
            // .. then unset everything and let the transition animate us
            viewer.addClass("displayed");
            viewer.find(".modal-dialog").css({
              margin: "",
              width: ""
            });
            viewer.find(".modal-content").css("border", "");
            viewer.find(".modal-body").css("padding", "");

            if (!isBanner) {
              var windowHeight = $(window).height(),
                modalImage = viewer.find(".zoomed-image");

              if (modalImage.data("original-height") > windowHeight) {
                // Set the height of the image to the max of the viewport height, while preserving the aspect ratio
                modalImage.css({
                  "max-height": windowHeight
                });
              }
            }

            viewer.css({
              top: "",
              left: "",
              width: "",
              height: ""
            });
          });
        }
      },

      onShowFullSizeImage: function(evt) {
        app.views.CoreStoryReading.prototype.onShowFullSizeImage.apply(
          this,
          arguments
        );

        // Put the dialog directly overlaying the image, and then let the browser
        // (through transitions) animate us to the right spot.
        var bounds = evt.currentTarget.getBoundingClientRect();

        this.showFullSizeMedia(bounds, false);
      },

      onFullSizeBanner: function(evt) {
        app.views.CoreStoryReading.prototype.onFullSizeBanner.apply(
          this,
          arguments
        );

        // Put the dialog directly overlaying the image, and then let the browser
        // (through transitions) animate us to the right spot.
        var bounds = evt.currentTarget.getBoundingClientRect();
        this.showFullSizeMedia(bounds, true);
      }
    })
  );

  app.mixin(
    app.views.StoryReading,
    "MediaSharing",
    "SocialSharing",
    "AffixElement",
    "VoteManagement",
    "LibraryManagement",
    "StoryInListsManagement",
    "AuthPromptManagement",
    "FacebookConnect",
    "FollowManager",
    "Highlighter",
    "StickyAds"
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
