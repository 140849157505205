import autocompleteResults from "platform/components/templates/autocomplete-results.hbs";
(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "AutocompleteResults",
    Monaco.View.extend({
      // Properties
      className: "autocomplete-results",
      template: autocompleteResults,

      enabled: true,

      events: function() {
        var eventList = {};

        eventList["tap li"] = "onTapOption";
        eventList["click li"] = "stopEvent";

        eventList["mouseover li"] = "onHoverOption";
        return eventList;
      },

      // Methods
      initialize: function(options) {
        options = options || {};
        this.boundField = options.boundField;
        this.targetSelector = options.boundField.selector;
        this.searchType = options.searchType || "mixed";
        this.title = options.title || null;

        this.selectedItem = null;
        this.anchoredResults = this.collection.length
          ? this.collection.toJSON()
          : [];
        this.searchResults = this.anchoredResults.length
          ? this.collection.toJSON()
          : null;

        _.bindAll(this, "onSearchKeyDown", "onSearchInput");
        $("body").on("keydown", this.targetSelector, this.onSearchKeyDown);
        $("body").on("input", this.targetSelector, this.onSearchInput);
      },

      render: function() {
        if (this.collection.length) {
          var data = {
            items: this.collection.toJSON(),
            title: this.title
          };
          this.$el.html(this.template(data));
          this.showResults();
        } else {
          this.hideResults();
        }

        return this;
      },

      callSearchAPI: _.debounce(function(searchTerm) {
        var self = this;

        this.collection.searchTerm = searchTerm;

        Promise.resolve(this.collection.fetch()).then(function() {
          self.cleanup();

          _.each(self.anchoredResults, function(tag) {
            self.collection.add(tag, { at: 0 });
          });

          if (self.collection.length) {
            self.searchResults = self.collection.toJSON();
            //TODO: Remove the #search-query val check once debounces are cancellable (lodash 2.4.1)
            if (self.searchResults && $(self.targetSelector).val() !== "") {
              self.render();
              self.showResults();
            }
          }
        });
      }, 200),

      showResults: function() {
        this.$el.show();
      },

      hideResults: function() {
        this.$el.empty();
        this.cleanup();
      },

      cleanup: function() {
        this.selectedItem = null;
        this.searchResults = this.anchoredResults.length
          ? this.anchoredResults
          : null;
      },

      // Results Traversal
      selectPrev: function() {
        // If no current selection, move pointer to the end of the list and apply highlight
        if (this.selectedItem === null) {
          this.selectedIndex = this.collection.length - 1;
          this.selectedItem = this.collection.at(this.selectedIndex);
          this.highlightSelected();
          return;
        }

        this.selectedIndex--;

        // If we press up with the first item selected, should loop to the end of the list
        if (this.selectedIndex < 0) {
          this.selectedIndex = this.collection.length - 1;
        }

        this.selectedItem = this.collection.at(this.selectedIndex);
        this.highlightSelected();
      },

      selectNext: function() {
        // If no current selection, move pointer to the start of the list and apply highlight
        if (this.selectedItem === null) {
          this.selectedIndex = 0;
          this.selectedItem = this.collection.at(this.selectedIndex);
          this.highlightSelected();
          return;
        }

        this.selectedIndex++;

        // If we press down with the last item selected, should loop to the start of the list
        if (this.selectedIndex >= this.collection.length) {
          this.selectedIndex = 0;
        }

        this.selectedItem = this.collection.at(this.selectedIndex);
        this.highlightSelected();
      },

      highlightSelected: function() {
        if (this.selectedItem) {
          this.$("li").removeClass("selected");
          this.$("li:not(.title)")
            .eq(this.selectedIndex)
            .addClass("selected");
          this.boundField.val(this.selectedItem.toJSON().label);
        }
      },

      loadSelection: function() {
        if (this.selectedItem) {
          this.boundField.val(this.selectedItem.toJSON().label);
          this.hideResults();
        }
      },

      onHoverOption: function(evt) {
        this.selectedItem = this.collection.findWhere({
          label: $(evt.currentTarget).text()
        });
        this.selectedIndex = this.collection.indexOf(this.selectedItem);
        this.highlightSelected();
      },

      onTapOption: function(evt) {
        this.selectedItem = this.collection.findWhere({
          label: $(evt.currentTarget).text()
        });
        this.selectedIndex = this.collection.indexOf(this.selectedItem);
        this.loadSelection();
      },

      // Event Handlers
      onSearchKeyDown: function(evt) {
        if (
          (evt.which === 13 && this.selectedItem) || // prevent enter
          evt.which === 38 ||
          evt.which === 40
        ) {
          // prevent caret move
          wattpad.utils.stopEvent(evt);
        }

        if (evt.which === 27) {
          // esc
          this.hideResults();
          return;
        }

        // don't handle the keys when there's no results
        if (!this.searchResults || this.searchResults.length < 1) {
          return;
        }

        switch (evt.which) {
          case 38: // up key
            this.selectPrev();
            break;
          case 40: // down key
            this.selectNext();
            break;
          case 13: // enter key
          case 9: // tab key
            this.loadSelection();
            break;
        }
      },

      remove: function() {
        $("body").off("keydown", this.targetSelector, this.onSearchKeyDown);
        $("body").off("input", this.targetSelector, this.onSearchInput);
        this.hideResults();
        return Monaco.View.prototype.remove.apply(this, arguments);
      },

      onSearchInput: function(evt) {
        var searchTerm = $(this.targetSelector)
          .val()
          .trim();
        this.hideResults();

        if (searchTerm.length >= 2) {
          this.callSearchAPI(searchTerm);
        }
      }
    })
  );
})(window, _, wattpad, window.app);
