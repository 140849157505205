(function(window, app) {
  "use strict";

  // Authorpreneur Identification Onboarding
  app.router.add({
    "start/writerjourney(/)*path": ["onboardingWriterJourney", {}]
  });

  // Main onboarding page
  app.router.add({
    "start(/)": ["onboardingStep1", {}]
  });

  // Genre selection page
  app.router.add({
    "start/genres(/)*path": ["onboardingGenres", {}]
  });

  // Finish page
  app.router.add({
    "start/success(/)*path": ["onboardingSuccess", {}]
  });
})(window, window.app);
