/*
 * ModalTransition
 * - hack job, to be removed when onboarding is changed to NOT use modals
 */
(function(window, Monaco, wattpad, app) {
  "use strict";

  app.add(
    "ModalTransition",
    app.transitions.DefaultTransition.extend({
      start: function(fromView, toView, options) {
        options = options || { hasHeader: true, hasFooter: true };

        //Set the page title ( don't need to set it on SSR )
        if (fromView && options.pageTitle && options.pageTitle.length > 0) {
          wattpad.utils.setTitle(options.pageTitle);
        }

        // empty the container by default
        if (options.emptyContainer !== void 0 && !options.emptyContainer) {
          $("#app-container").empty();
        }

        this.changeViews(fromView, toView, options);

        // SSR load
        if (!fromView) {
          if (options.hasHeader) {
            this.setupHeader();
          }
          this.setupFooter();
          this.setupAnnouncements();
        } else {
          // CSR
          this._pageView();
        }

        if (options.hasHeader) {
          this.showHeader();
        } else if (options.hasHeader !== undefined) {
          this.hideHeader();
        }

        if (options.hasFooter) {
          this.showFooter();
        } else if (options.hasFooter !== undefined) {
          this.hideFooter();
        }

        $("#header")
          .find("#search-query")
          .val("");

        wattpad.utils.hideAddressBar();

        this.scrollTo(window, fromView);

        return toView;
      }
    })
  );
})(window, Monaco, wattpad, window.app);
