(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ReadingListStories",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",

      listId: null,

      eventsOn: false,

      limit: 30,

      fields: [
        "id",
        "name",
        {
          stories: [
            "id",
            "title",
            "cover",
            "description",
            "url",
            "voteCount",
            "readCount",
            "numParts",
            "firstPartId",
            "completed",
            "tags",
            "mature",
            { user: ["name", "avatar"] }
          ]
        },
        "total",
        "nextUrl"
      ],

      // this is needed to pass the limit variable down
      constructor: function(models, options) {
        app.collections.IncrementalFetchNextUrl.prototype.constructor.apply(
          this,
          arguments
        );
      },

      initialize: function(models, options) {
        options = options || {};
        if (!options.listId) {
          throw new Error("ReadingListStories: listId cannot be null");
        }

        this.listId = options.listId;

        if (options.nextUrl) {
          this.nextUrl = options.nextUrl;
        }

        app.collections.IncrementalFetchNextUrl.prototype.initialize.apply(
          this,
          arguments
        );
      },

      defaultUrl: function() {
        return "/api/v3/lists/" + this.listId + "/stories";
      },

      resource: function() {
        return "reading-list." + this.listId + ".stories";
      },

      remove: function(models, options) {
        var self = this,
          args = arguments;

        options = options || {};

        var singular = !_.isArray(models);
        models = singular ? [models] : _.clone(models);

        var ids = _.pluck(models, "id").join(",");
        Promise.resolve(
          $.ajax({
            url: this.defaultUrl() + "/" + encodeURIComponent(ids),
            type: "DELETE"
          })
        )
          .then(function() {
            app.collections.StoryGroup.prototype.remove.apply(self, args);
            app.local.clear(self.resource());

            // Update collection in memory
            self.offset -= models.length;
            self.total -= models.length;
            // Force incremental-fetch-nexturl.js to rebuild nextUrl
            self.nextUrl = null;

            // trigger manual events; cannot always depend on having a collection to actually remove the story, see StoryInListsManagement
            _.each(models, function(model) {
              self.trigger("removedStory", model);
            });

            if (typeof options.success === "function") {
              options.success();
            }
          })
          .catch(function() {
            if (typeof options.error === "function") {
              options.error();
            }
          });

        return singular ? models[0] : models;
      },

      add: function(models, options) {
        var self = this,
          args = arguments;

        options = options || {};

        var singular = !_.isArray(models);
        models = singular ? [models] : _.clone(models);

        var ids = _.pluck(models, "id").join(",");
        Promise.resolve(
          $.ajax({
            url: this.url(),
            data: { stories: ids },
            type: "POST"
          })
        )
          .then(function() {
            app.collections.StoryGroup.prototype.add.apply(self, args);
            app.local.clear(self.resource());
            //trigger manual events; cannot always depend on having a collection to actually remove the story, see StoryInListsManagement
            _.each(models, function(model) {
              self.trigger("addedStory", model);
            });

            if (typeof options.success === "function") {
              options.success();
            }
          })
          .catch(function() {
            if (typeof options.error === "function") {
              options.error();
            }
          });

        return singular ? models[0] : models;
      }
    })
  );
})(window, _, wattpad, window.app);
