var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<header>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Follow Request",{"name":"trans","hash":{},"data":data}))
    + "</header>\n\n<div class=\"request-item-container panel panel-default2\">\n  <div class=\"follow-request-item\">"
    + ((stack1 = container.invokePartial(partials["core.profile.profile_follow_request_item"],depth0,{"name":"core.profile.profile_follow_request_item","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</div>\n</div>\n";
},"usePartial":true,"useData":true});