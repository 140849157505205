import invitePanel from "core/templates/invite-friends/components/invite-panel.hbs";
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "InvitePanel",
    app.views.Base.extend({
      template: invitePanel,
      className: "wp-component invite-panel-component",

      events: {
        "tap .provider-facebook": "onFacebookProvider",
        "tap .provider-twitter": "onTwitterProvider",
        "tap .provider-mailto": "onMailtoProvider",

        "click .invite-providers > li": "stopEvent",

        "focus .provider-email > input": "clearMessage",

        "tap .provider-email > button": "onEmailProvider",
        "click .provider-email > button": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};
        this.action = options.action;
        this.content = options.content;
      },

      render: function() {
        this.$el.html(this.template({}));
        return this;
      },

      clearMessage: function() {
        this.$(".email-message").text("");
      },

      onEmailProvider: function() {
        var self = this,
          $elements = this.$(
            ".provider-email > button, .provider-email > input"
          ),
          $inputElement = this.$(".provider-email > input"),
          $buttonElement = this.$(".provider-email > button");

        var email = $inputElement.val();

        if (
          email.match(
            /^[a-zA-Z0-9_\.\'\+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-\.]+$/
          ) === null
        ) {
          this.$(".email-message")
            .text(utils.trans("The email address is invalid."))
            .addClass("warning");
          return;
        }

        $elements.attr("disabled", "disabled");
        $buttonElement.children().toggle();

        var invite = new app.models.UserInvite({
          emails: email
        });

        this.trackEvent("email");

        Promise.resolve(invite.save())
          .then(function() {
            self
              .$(".email-message")
              .text(utils.trans("You have successfully sent an invite"))
              .removeClass("warning");
            _.delay(function() {
              self.$(".email-message").text("");
            }, 3000);
            $inputElement.val("");
          })
          .catch(function(error) {
            // Catch here so we can always re-enable the button / remove event listeners.
            const message =
              error.responseJSON.message ??
              utils.trans("Something went wrong. Please try again later.");
            utils.showToast(message, {
              type: "dismissable"
            });
          })
          .then(function() {
            $buttonElement.children().toggle();
            $elements.removeAttr("disabled");
          });
      },

      onFacebookProvider: function() {
        var self = this;
        this.trackEvent("facebook");
        Promise.resolve(this.createShareLink("facebook")).then(function(link) {
          self.facebookSend(link.url);
        });
      },

      onTwitterProvider: function() {
        var self = this;
        this.trackEvent("twitter");
        Promise.resolve(this.createShareLink("twitter")).then(function(link) {
          self.twitterShare({
            text: utils.trans(
              "Follow me on Wattpad! Username %s",
              utils.currentUser().get("username")
            ),
            url: link.url
          });
        });
      },

      onMailtoProvider: function() {
        var self = this;
        this.trackEvent("email");
        Promise.resolve(this.createShareLink("email")).then(function(link) {
          var subject = utils.trans("Follow me on Wattpad!");
          var emailUrl = encodeURIComponent(link.url);
          var body = utils.trans(
            "Follow me on Wattpad, a free, one-of-a-kind social reading and writing app. My username is: %s%%0A\n%s",
            utils.currentUser().get("username"),
            emailUrl
          );

          window.location.href =
            "mailto:?to=&subject=" + subject + "&body=" + body;
        });
      },

      createShareLink: function(medium) {
        var username = utils.currentUser().get("username");
        var profileUrl = "https://www.wattpad.com/user/" + username;
        var deeplink = "wattpad://user/" + username;

        var branch_options = {
          source: "web",
          medium: medium,
          campaign: "invitefriends",
          content: "sharing",
          page: this.content,
          desktopRedirect: true
        };

        return utils.generateBranchLink(profileUrl, deeplink, branch_options);
      },

      trackEvent: function(label) {
        // GA tracking
        wattpad.utils.pushEvent({
          category: "invite_friends",
          action: this.action,
          label: label
        });

        // WEB-6451 invite-friends tracking
        var details = {
          source: "web",
          medium: label,
          campaign: "invitefriends",
          content: "invite-friends",
          page: this.content
        };
        window.te.push(
          "event",
          "app",
          "invite_friends",
          null,
          "deeplink",
          details
        );
      }
    })
  );

  app.mixin(app.views.InvitePanel, "FacebookConnect", "TwitterConnect");
})(window, window.wattpad, window.wattpad.utils, window.app);
