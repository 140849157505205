(function(window, _, wattpad, app, utils) {
  "use strict";

  /*
   * A Comment Model can represent:
   * - an inline comment
   * - an end-of-part story comment
   * - a reply comment to an inline comment
   * - a reply comment to an end-of-part comment
   */
  app.add(
    "CommentModel",
    app.models.BaseModel.extend({
      cacheLocal: false, //TODO: Investigate why this is false

      fields: [],

      initialize: function(attrs, options) {
        options = options || {};
        attrs = attrs || {};
        this.isDeepLink = options.isDeepLink || false;
        this.isDeeplinkedReply = options.isDeeplinkedReply || false;
        this.commentIdToHighlight = options.commentIdToHighlight;
        this.errorHandlerMixinDisabled = options.errorHandlerMixinDisabled;
        app.models.BaseModel.prototype.initialize.apply(this, arguments);
      },

      url: function() {
        /*  If we are asked to deep link to a specific comment and it is a reply, then
       *  we have a custom URL that includes the parent ID and the child ID.
       *  This fetch will give us back both the parent comment
       *  and the requested reply comment.
       *
       *  Otherwise, this returns the usual comment URL.
       *
       *  TODO: remove split( '#' ).join( '_' ) once PLAT fixes parentId bug.
       */

        if (this.isDeepLink && this.get("parentId")) {
          return (
            "/v4/comments/" +
            this.get("parentId")
              .toString()
              .split("#")
              .join("_") +
            "/replies/" +
            this.get("id")
          );
        } else {
          return "/v4/comments/" + this.get("id");
        }
      },

      /*
     * This overrides XHRs as POST requests for replies and inline comments require a different url.
     */
      sync: function(method, model, options) {
        var self = this;
        options = options || {};
        options.error = function(error, response, body) {
          return self.handlePromiseExpected(error);
        };

        if (model.get("paragraphId") && !model.get("isReply")) {
          /*
         * Modify the URL for inline comments (NOT for replies to inline comments).
         */
          if (method === "create") {
            options.url =
              "/v4/parts/" +
              model.get("partId") +
              "/paragraphs/" +
              model.get("paragraphId") +
              "/comments";
          } else {
            options.url = "/v4/comments/" + model.get("id");
          }
        } else if (model.get("parentId")) {
          /*
         * Modify the URL for all Reply comments (both inline and end of part).
         * TODO: remove split( '#' ).join( '_' ) once PLAT fixes parentId bug.
         */
          if (method === "create") {
            var parentIdString = model
              .get("parentId")
              .toString()
              .split("#")
              .join("_");
            if (parentIdString.match(/[0-9_]+/)) {
              options.url = "/v4/comments/" + parentIdString;
            }
          } else {
            options.url = "/v4/comments/" + model.get("id");
          }
        }
        /*
       * All other types of comments don't need their url changed.
       */
        return app.models.BaseModel.prototype.sync.apply(this, arguments);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
