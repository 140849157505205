(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:featured_picks",
    app.router.filter(["getTranslations", "getCategories"], function(listId) {
      var model = new app.models.ReadingList({ id: listId });

      Promise.all([model.fetch()])
        .then(function() {
          var view = new app.views.FeaturedPicks({
            model: model
          });

          app.transitionTo(view, {
            hasHeader: true,
            hasFooter: true
          });

          window.te.push("event", "app", "page", null, "view", {
            page: "featured",
            reading_listid: listId
          });
        })
        .catch(function(err) {
          // We don't want to swallow any errors as they should be self-descriptive.
          if (err instanceof Error) {
            throw err;
          }

          // Check that the incoming obj is the expected rejected promise format.
          if (err) {
            // format we expect, console log message
            console.error(
              `Error in featured controller: ${
                err.responseJSON
                  ? err.responseJSON.message
                  : err.message || err.errors || err
              }`
            );
          }

          app.router.trigger("route:error-404", "featured-picks");
        });
    })
  );
})(window, wattpad, window.app);
