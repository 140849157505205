(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "NodeStorySearch",
    app.collections.StorySearch.extend({
      model: app.models.StoryModel,

      arrayKey: "stories",
      cacheLocal: false,

      fields: [
        {
          stories: [
            "id",
            "title",
            "voteCount",
            "readCount",
            "commentCount",
            "description",
            "mature",
            "completed",
            "cover",
            "url",
            "numParts",
            "isPaywalled",
            "paidModel",
            "length",
            { language: ["id"] },
            { user: ["name"] },
            { lastPublishedPart: ["createDate"] },
            "promoted",
            { sponsor: ["name", "avatar"] },
            "tags",
            {
              tracking: [
                "clickUrl",
                "impressionUrl",
                { thirdParty: ["impressionUrls", "clickUrls"] }
              ]
            },
            { contest: ["endDate", "ctaLabel", "ctaURL"] }
          ]
        },
        "total",
        "tags",
        "nextUrl"
      ],

      initialize: function(collections, options) {
        options = options || {};
        this.searchTerm = options.searchTerm;
        this.limit = options.limit;
        this.promotedStories = [];
      },

      resource: function() {
        return (
          "search.stories.results." +
          this.searchTerm.trim() +
          "." +
          (this.complete ? "true" : "false") +
          "." +
          (this.mature ? "true" : "false")
        );
      },

      parse: function(data) {
        var self = this;

        const activeTags = this.searchTerm?.match(/#[^\s]+/gi) || [];
        let incomingTags = data.tags ? [...data.tags] : [];

        wattpad.utils.tagsHelper.updateWithActiveTags(incomingTags, activeTags);

        let tagsWithActiveTagsAndLinks = [];

        if (incomingTags?.length > 0) {
          tagsWithActiveTagsAndLinks = wattpad.utils.tagsHelper.addLinksToTags(
            incomingTags,
            encodeURIComponent(this.searchTerm)
          );

          tagsWithActiveTagsAndLinks = wattpad.utils.tagsHelper.sortActiveTags(
            incomingTags
          );
        }

        this.tags = new Monaco.Collection(tagsWithActiveTagsAndLinks);
        this.tags._app = app; //Bug in monaco, temporary band-aid

        if (
          data.stories &&
          data.stories.length &&
          typeof data.stories[0].parts !== "number"
        ) {
          _.each(data.stories, function(story) {
            story.category = null;

            var category = _.find(story.categories, function(cat) {
              return cat > 0;
            });

            if (category) {
              category = _.findWhere(self.categories.toJSON(), {
                id: category
              });
              story.category = category ? category.name : null;
            }

            if (story.promoted) {
              self.promotedStories.push(story);
            }
          });
        }

        this.limit = this.limit === 3 ? 10 : this.limit; // Mobile starts with a limit of 3

        //No return because of mixin
      },

      getPromotedStories: function() {
        return this.promotedStories;
      },

      revertParse: function(result) {
        result.tags = this.tags.toJSON();
      },

      defaultUrl: function() {
        return (
          "/v4/search/stories/?query=" +
          encodeURIComponent(this.searchTerm.trim()) +
          this.addFilter("complete") +
          this.addFilter("mature") +
          this.addFilter("hot") +
          this.addFilter("age") +
          this.addFilter("length") +
          this.addFilter("category") +
          this.addFilter("free") +
          this.addFilter("paid") +
          "&limit=" +
          this.limit
        );
      },

      toJSON: function() {
        var data = app.collections.StorySearch.prototype.toJSON.apply(
          this,
          arguments
        );

        var category = app.get("categories").find({ id: this.category });

        data.filters = {
          complete: this.complete,
          mature: this.mature,
          age: this.age,
          category: category?.get("name"),
          free: this.free,
          paid: this.paid
        };

        return data;
      },

      addFilter: function(filter) {
        switch (filter) {
          case "complete":
            if (this.complete) {
              return "&filter=complete";
            }
            break;
          case "mature":
            if (this.mature) {
              return "&mature=true";
            }
            break;
          case "age":
            if (this.age) {
              return "&updateYoungerThan=" + this.age;
            }
            break;
          case "length":
            var filter = "";
            if (this.minParts) {
              filter = "&minParts=" + this.minParts;
            }
            if (this.maxParts) {
              filter = filter + "&maxParts=" + this.maxParts;
            }
            return filter;
            break;
          case "hot":
            if (this.hot) {
              return "&hot=1";
            }
            break;
          case "category":
            if (this.category) {
              return "&category=" + this.category;
            }
            break;
          case "free":
            if (this.free) {
              return "&free=1";
            }
            break;
          case "paid":
            if (this.paid) {
              return "&paid=1";
            }
            break;
        }

        return "";
      },

      setFilter: function(filterName, value) {
        this[filterName] = value;
        if (filterName === "age") {
          if (value > 0) {
            this["age"] = value;
          } else {
            // Any time
            delete this["age"];
          }
        } else if (filterName === "length") {
          if (!app.get("device").is.mobile) {
            const { minParts, maxParts } = value;

            if (minParts === 0) {
              delete this["minParts"];
            } else {
              this["minParts"] = minParts;
            }

            if (maxParts === 0) {
              delete this["maxParts"];
            } else {
              this["maxParts"] = maxParts;
            }
          } else {
            if (value === "1-10") {
              this["minParts"] = 1;
              this["maxParts"] = 10;
            } else if (value === "10-20") {
              this["minParts"] = 10;
              this["maxParts"] = 20;
            } else if (value === "20-50") {
              this["minParts"] = 20;
              this["maxParts"] = 50;
            } else if (value === ">50") {
              this["minParts"] = 50;
              delete this["maxParts"];
            } else {
              // Any length
              delete this["maxParts"];
              delete this["minParts"];
            }
          }
        } else if (filterName === "free") {
          this["free"] = value;
        } else if (filterName === "paid") {
          this["paid"] = value;
        }
      }
    })
  );

  //Need to re-apply because of override
  app.mixin(app.collections.NodeStorySearch, "CollectionParsing");
})(window, _, wattpad, window.app);
