import userLink from "platform/components/templates/user-link.handlebars";
import profileAbout from "platform/profile/templates/profile-about.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Profile Sub-view -- About Me
   * @class ProfileAbout
   * @constructor
   */
  app.add(
    "ProfileAbout",
    Monaco.View.extend({
      className: "about-info panel panel-default2",
      template: profileAbout,

      partials: {
        link: userLink
      },

      events: {
        // Edit description
        "tap   .on-edit": "onEdit",
        "click .on-edit": "stopEvent"
      },

      render: function() {
        var data = this.model.toJSON(),
          self = this;

        data.links = this.getLinkValues(data);

        // Generate the following part of the about section
        data.userFollowings = this.options.following.toJSON(0, 14);

        // For the following +num button thing
        var totalFollowings = this.options.following.total;

        data.numFollowingMobile =
          totalFollowings > 14 ? totalFollowings - 14 : null;
        data.numFollowingTablet =
          totalFollowings > 3 ? totalFollowings - 3 : null;
        data.numFollowingDesktop =
          totalFollowings > 4 ? totalFollowings - 4 : null;
        data.numFollowingDesktopWide =
          totalFollowings > 6 ? totalFollowings - 6 : null;

        data.socialShareSection = this.getSocialShareHTML();

        this.$el.html(this.template(data));

        if (data.facebook) {
          $.ajax({
            url: "https://graph.facebook.com/" + data.facebook,
            data: { fields: "link" },
            cache: false
          }).done(function(response) {
            self.renderFacebookUrl(response);
          });
        }
        return this;
      },

      // fill in the social data
      getLinkValues: function(data) {
        var links, hasProtocol, name;
        if (!data.facebook && !data.website && !data.smashwords) {
          return null;
        }

        links = {};
        hasProtocol = /^[\w]{3,5}:\/\//i; // regexp obj for protocol match & replacement
        name = data.name ? data.name.split(" ")[0] : data.username;

        // setup facebook
        if (data.facebook) {
          links.facebook = {};
          links.facebook.name = utils.trans("%s's Facebook profile", name);
          links.facebook.url = "https://www.facebook.com/" + data.facebook;
        }

        // setup website
        if (data.website) {
          links.website = {};
          links.website.name = data.website.replace(hasProtocol, "");
          links.website.url = data.website.match(hasProtocol)
            ? data.website
            : "http://" + data.website;
        }

        // setup smashwords
        if (data.smashwords) {
          links.smashwords = {};
          var linkPath = data.smashwords.split("/");
          links.smashwords.name =
            "smashwords.com/" + linkPath[linkPath.length - 1];
          links.smashwords.url = data.smashwords;
        }

        return links;
      },

      getSocialShareHTML: function() {
        try {
          var meta = {
            username: this.model.get("username"),
            name: this.model.get("name") || this.model.get("username"),
            avatarUrl: this.model.get("avatar"),
            description: this.model.get("description")
          };

          var links = this.buildSocialShareLinksForProfile(meta);

          return this.getSocialShareTemplateHTML("row", links);
        } catch (e) {
          return "";
        }
      },

      onEdit: function() {
        app.trigger("app:profile:edit");
      },

      // Get Facebook Vanity URL via graph API
      renderFacebookUrl: function(response) {
        if (response.link) {
          var $el = this.$("li.facebook"),
            data = {
              name: response.link.replace(/^([\w]{3,5}:\/\/)*(w{3}\.)*/i, ""),
              url: response.link
            };

          // render link template with `data` and inject in the DOM
          $el.children("a").remove();
          $el.append(this.partials.link(data));
        }
      }
    })
  );

  app.mixin(app.views.ProfileAbout, "SocialSharing");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
