import profileConversations from "platform/profile/templates/profile-conversations.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Profile sub view -- Conversations
   * @Class ProfileConversations
   * @constructor
   */

  app.add(
    "ProfileConversations",
    app.views.RegionManager.extend({
      // Properties
      // ----------
      className: "user-activity row",
      template: profileConversations,

      // Methods
      // -------
      //
      initialize: function(options) {
        options = options || {};
        var currentUser = wattpad.utils.currentUser();
        if (
          currentUser.authenticated() &&
          currentUser.get("username") === this.model.get("username")
        ) {
          options.placeholder = wattpad.utils.trans("Post a Message");
        } else {
          options.placeholder = wattpad.utils.trans(
            "Write to %s",
            this.model.get("username")
          );
        }
      },

      render: function() {
        var data = this.model.toJSON(),
          template = this.template,
          self = this;

        this.$el.html(template(data));

        var messages = new app.views.ProfileInteractions({
          model: this.model,
          pinnedItem: new app.models.PinnedMessage({
            username: this.model.get("username"),
            type: "newMessage"
          }),
          collection: new app.collections.ActivityMessages([], {
            username: this.model.get("username"),
            limit: 10
          }),
          isMessage: true
        });

        this.setRegion("#profile-messages", messages.render());

        _.defer(function() {
          self.$("#profile-messages [data-toggle=tooltip]").tooltip();
        });

        return this;
      },

      setElement: function(el) {
        var $el = $(el),
          view,
          selector;
        app.views.RegionManager.prototype.setElement.call(this, el);

        // Attach child views
        for (var region in this.regions) {
          if (this.regions.hasOwnProperty(region)) {
            view = this.regions[region].view;
            selector = view.className.trim().replace(/ /g, ".");
            view.setElement($el.find(region + " ." + selector));
          }
        }
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
