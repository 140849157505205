import tagModuleWriters from "core/templates/item-views/tag-module-writers.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "TagModuleWriters",
    app.views.Base.extend({
      // Properties
      // ----------
      className: "tag-module-writers",
      template: tagModuleWriters,

      // Methods
      // -------
      render: function() {
        var data = this.model ? this.model.toJSON() : { empty: true };

        data.nameMaxLength = app.get("device").isDesktop() ? 30 : 28;
        this.$el.html(this.template(data));

        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
