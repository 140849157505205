import postPublishModal from "platform/components/templates/modal/post-publish-modal.hbs";
(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "PostPublishModal",
    app.views.Base.extend({
      template: postPublishModal,

      className: "post-publish-modal modal fade",

      events: {
        "tap .share-embed": "hideModal",
        "click .share-embed": "stopEvent",

        "tap .on-post-message": "onPostMessage",
        "click .on-post-message": "stopEvent"
      },

      showModal: function() {
        this.$el.modal("show");
      },

      hideModal: function() {
        this.$el.modal("hide");
      },

      render: function() {
        var self = this;
        this.$el.html(
          this.template(
            _.extend(this.model.toJSON(), {
              links: self.options.links,
              showBroadcastMessaging: self.options.showBroadcastMessaging,
              username: utils.currentUser().get("username"),
              testGroups: wattpad.testGroups
            })
          )
        );
        return this;
      },

      sendPrometheusEventFromBroadcast: function() {
        var storyId = this.model.get("group").id;

        window.te.push(
          "event",
          "modal",
          "story_publish",
          "broadcast_message",
          "click",
          {
            storyid: storyId
          }
        );
      },

      onPostMessage: function(evt) {
        utils.stopEvent(evt);

        var body = this.$(".broadcast-message")
            .val()
            .trim(),
          storyGroupId = this.model.get("group").id,
          storyUrl = wattpad.siteRoot + "/story/" + storyGroupId,
          newMessageModel;

        if (body === "") {
          return;
        }

        newMessageModel = new app.models.Message({
          ownername: utils.currentUser().get("username")
        });

        var options = {
          broadcast: true,
          body: body + " " + storyUrl
        };

        newMessageModel.save(options, {
          success: this.onBroadcastMessageSuccess.bind(this),
          error: this.onBroadcastMessageError.bind(this)
        });
      },

      onBroadcastMessageSuccess: function(model, response, options) {
        this.sendPrometheusEventFromBroadcast();
        this.trigger("message:new", model);
        this.$(".broadcast-message")
          .val("")
          .blur();

        var successMessage =
          utils.iconify("fa-check", 16, "wp-base-2") +
          utils.trans("Your message has been posted!");
        this.$(".message-info").html(successMessage);
        this.$(".message-wrapper").addClass("hidden");
        this.$(".profile-link").removeClass("hidden");
      },

      onBroadcastMessageError: function(model, response, options) {
        var errorMessage =
          utils.iconify("fa-warning", 16, "wp-lighterror") +
          utils.trans(
            "There was a problem posting your message, please try again later."
          );
        this.$(".message-info").html(errorMessage);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
