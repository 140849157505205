var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-info\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Completed",{"name":"trans","hash":{},"data":data}))
    + "</span>&nbsp;&nbsp;";
},"3":function(container,depth0,helpers,partials,data) {
    return "<span class=\"label label-danger\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Mature",{"name":"trans","hash":{},"data":data}))
    + "</span>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"clearfix\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"drag-handle on-edit-show pull-left\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-menu",28,"wp-neutral-2","fa-xl",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n    <a class=\"cover cover-sm pull-left on-navigate\" href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n        <img src=\""
    + alias4((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),80,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n    </a>\n\n    <div class=\"circular-checkbox on-check-story fa-stack pull-right on-edit-show\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <span class=\"circle-empty vhcenter\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",30,"wp-neutral-3 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",26,"wp-neutral-5 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n        <span class=\"checked-in vhcenter\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-filled",22,"wp-base-2 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",17,"wp-neutral-5 vhcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n    </div>\n\n    <div class=\"content\">\n        <h5><a class=\"on-navigate\" href=\""
    + alias4((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">"
    + alias4(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</a></h5>\n        <div class=\"meta\">\n          <small class=\"reads\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>\n          <small class=\"votes\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>\n          <small class=\"numParts\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-list",14,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numParts : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>\n        </div>\n\n        <p class=\"on-edit-hide\">"
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),320,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n\n        <div class=\"story-status on-edit-hide\">\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.completed : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.mature : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>\n";
},"useData":true});