(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:error-404",
    app.router.filter(["getTranslations"], function(type, modelId) {
      // hit the server to make sure
      if (!$("body").hasClass("js-app-off")) {
        wattpad.utils.redirectToServer(window.location.href);
        return;
      }
      if (type === "user") {
        var view = new app.views.DummyReactView({
          component: "UserError404",
          componentId: "userError-404"
        });
        app.transitionTo(view, {
          hasHeader: true,
          hasFooter: true
        });
      } else if (type === "story") {
        var view = new app.views.DummyReactView({
          component: "StoryError404",
          componentId: "story-error-404"
        });
        app.transitionTo(view, {
          hasHeader: true,
          hasFooter: true
        });
      } else {
        var browseTopicsCollection = new app.collections.BrowseTopics();
        //Fetching browse topics
        Promise.resolve(browseTopicsCollection.fetch()).then(function() {
          var view = new app.views.Error404({
            type: type,
            modelId: modelId,
            browseTopics: browseTopicsCollection
          });
          app.transitionTo(view, {
            hasHeader: true,
            hasFooter: true
          });
        });
      }
    })
  );
})(window, _, jQuery, wattpad, window.app);
