import pinnedMessage from "core/templates/profile/pinned-message.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  app.add(
    "PinnedMessage",
    app.views.Base.extend({
      className: "pinned-message",
      template: pinnedMessage,

      render: function() {
        this.$el.html(
          this.template(_.extend(this.formatViewData(), this.model.toJSON()))
        );
        return this;
      },

      formatViewData: function() {
        return { isDesktop: app.get("device").isDesktop() };
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
