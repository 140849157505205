import followRequests from "core/templates/notifications/follow-requests.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "FollowRequests",
    app.views.IncrementalList.extend({
      containerClass: ".collection",

      template: followRequests,

      itemView: app.views.FollowRequestItem,

      getTemplateData: function() {
        return {
          requests: {
            hasItems: this.collection.length > 0,
            hasMore: this.collection.hasMore()
          },
          device: app.get("device")
        };
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
