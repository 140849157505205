(function(window, _, wattpad, app) {
  "use strict";

  /* Base Collection
   * Base application collection class that all other collections inherit. Please
   * consult with team before adding new methods to this class.
   */
  app.add(
    "BaseCollection",
    Monaco.Collection.extend({
      resource: "resource-not-set",
      cacheLocal: true
    })
  );

  // Add Generate Fields Mixin
  app.mixin(
    app.collections.BaseCollection,
    "GenerateFields",
    "CollectionParsing"
  );
})(window, _, wattpad, window.app);
