(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "ValidationSuccess",
    app.views.DummyReactView.extend({
      component: "ValidationSuccess",
      componentId: "email-validation-success",

      constructor: function() {
        var options = {
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      }
    })
  );
})(window, _, wattpad, window.app);
