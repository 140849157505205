(function(window, wattpad, app) {
  "use strict";

  app.router.on(
    "route:userActivationSuccess",
    app.router.filter(["getTranslations"], function(query) {
      var validationSuccess = new app.views.ValidationSuccess({});

      app.transitionTo(validationSuccess, {
        hasHeader: true,
        hasFooter: true
      });
    })
  );
})(window, wattpad, window.app);
