(function(window, _, wattpad, app) {
  "use strict";

  //This is the old StoryGroup model....app.models.StoryGroup
  //TODO: Merge this and StoryModel ( story-model.js )
  app.add(
    "StoryGroup",
    app.models.Base.extend({
      events: {
        "change:parts": "initializeParts",
        "change:categories": "initializeCategories"
      },

      //=== METHODS ===//
      slug: function() {
        return (
          this.get("id") + "-" + wattpad.utils.slugify(this.get("title"), true)
        );
      },

      initializeParts: function() {
        var self = this,
          supportModel = null,
          newModel;

        this._parts = [];

        _.each(this.get("parts"), function(part) {
          if (self._dependencies) {
            supportModel = _.find(self._dependencies, function(d) {
              return d instanceof app.models.Story && d.get("id") === part.id;
            });

            if (supportModel) {
              self._parts.push(supportModel);
            } else {
              newModel = new app.models.Story(part);
              newModel._group = self;
              self._parts.push(newModel);
            }
          } else {
            newModel = new app.models.Story(part);
            newModel._group = self;
            self._parts.push(newModel);
          }
        });
      },

      initializeCategories: function() {
        var categories = this.get("categories"),
          stringCategories = app.get("categories"),
          self = this;

        this._categories = [];

        if (stringCategories) {
          _.each(categories, function(c) {
            var category = stringCategories.get(c);

            if (category) {
              self._categories.push(category.get("name"));
            }
          });
        }
      },

      //=== ENTITIES ===//
      _author: null,

      author: function() {
        if (this._author === null) {
          this._author = new app.models.User();
          this._author.dependsOn(this, "user");
        }

        return this._author;
      },

      _parts: null,

      parts: function(n) {
        if (!this._parts) {
          this._parts = [];
        }

        if (n !== undefined) {
          return this._parts[n];
        }

        return this._parts;
      },

      _categories: null,

      categories: function(n) {
        if (!this._categories || this._categories.length === 0) {
          this.initializeCategories();
        }

        if (n !== undefined) {
          return this._categories[n];
        }

        return this._categories;
      },

      //TECHDEBT
      _comments: null,

      comments: function() {},

      //=== MONACO ===//
      resource: function() {
        return "group." + this.get("id") + ".metadata";
      },

      urlRoot: "/api/v3/stories"
    })
  );
})(window, _, wattpad, window.app);
