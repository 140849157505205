var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <article class=\"notification aggregate message panel"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <div class=\"on-expand\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n      <div class=\"count\">"
    + alias4(((helper = (helper = helpers.children || (depth0 != null ? depth0.children : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"children","hash":{},"data":data}) : helper)))
    + "</div>\n      <div class=\"content\">\n        <div class=\"heading\">\n          "
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"You have %s new announcement","You have %s new announcements",(depth0 != null ? depth0.children : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.children : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "\n        </div>\n        "
    + alias4((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"shared/megaphone_grey.png","announcement notification",{"name":"connectImage","hash":{"height":"11px","width":"17px","id":"megaphone-icon"},"data":data}))
    + "\n        <time datetime=\""
    + alias4(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4((helpers.calendar || (depth0 && depth0.calendar) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n      </div>\n    </div>\n  </article>\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " unread";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, alias4=helpers.helperMissing;

  return "<article class=\"notification message panel"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.isReply : stack1),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isRead : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n    <a href=\"/user/"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm2 on-navigate\">\n        <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <div class=\"content "
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isTruncated : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      <div class=\"heading\">\n        <a href=\"/user/"
    + alias3(alias2(((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"on-navigate\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMentions : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(18, data, 0),"data":data})) != null ? stack1 : "")
    + "        </a>\n      </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.wasBroadcast : stack1),{"name":"if","hash":{},"fn":container.program(32, data, 0),"inverse":container.program(34, data, 0),"data":data})) != null ? stack1 : "")
    + "\n      <time datetime=\""
    + alias3(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias3((helpers.calendar || (depth0 && depth0.calendar) || alias4).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"calendar","hash":{},"data":data}))
    + "</time>\n\n      <p class=\"shown-message\">\n        "
    + ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias4).call(alias1,((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.body : stack1),(depth0 != null ? depth0.maxTextLength : depth0),true,{"name":"simpleShorten","hash":{"doUrl":false,"doTag":false},"data":data})) != null ? stack1 : "")
    + "\n        <span class=\"show-full"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isTruncated : depth0),{"name":"unless","hash":{},"fn":container.program(36, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\"><strong>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias1,"more",{"name":"trans","hash":{},"data":data}))
    + "</strong></span>\n      </p>\n      <p class=\"hidden full-message\">"
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || alias4).call(alias1,((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.body : stack1),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n\n    </div>\n</article>\n";
},"5":function(container,depth0,helpers,partials,data) {
    return " reply";
},"7":function(container,depth0,helpers,partials,data) {
    return "expanded-text";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.wasBroadcast : stack1),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%s</strong> mentioned you on their feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.theirFeed : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.toMe : depth0),{"name":"if","hash":{},"fn":container.program(14, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "");
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%s</strong> mentioned you on your feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%1$s</strong> mentioned you on <strong>%2$s</strong>&#39;s feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            ";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.wasBroadcast : stack1),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.program(21, data, 0),"data":data})) != null ? stack1 : "");
},"19":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%s</strong> posted a new announcement",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"21":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.toMe : depth0),{"name":"if","hash":{},"fn":container.program(22, data, 0),"inverse":container.program(27, data, 0),"data":data})) != null ? stack1 : "");
},"22":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.parentId : stack1),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.program(25, data, 0),"data":data})) != null ? stack1 : "");
},"23":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%s</strong> replied to a message on your feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"25":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%s</strong> posted a message to your feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.parentId : stack1),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "          ";
},"28":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%1$s</strong> replied to a message on %2$s&#39;s feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "              "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"<strong>%1$s</strong> posted a message to %2$s&#39;s feed",((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.from : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.message : depth0)) != null ? stack1.to : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    return "        "
    + container.escapeExpression((helpers.connectImage || (depth0 && depth0.connectImage) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"shared/megaphone_grey.png","announcement notification",{"name":"connectImage","hash":{"height":"11px","width":"17px","id":"megaphone-icon"},"data":data}))
    + "\n";
},"34":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-inbox",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"36":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.children : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});