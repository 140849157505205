var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <li class=\"col-xs-6\"><a class=\"on-change-category\" data-category=\""
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,(depth0 != null ? depth0.name_english : depth0),{"name":"slugify","hash":{},"data":data}))
    + "\" href=\"#\"> "
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.selected : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                    </a></li>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                            "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-check",18,"wp-base-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"4":function(container,depth0,helpers,partials,data) {
    return " class=\"active\"";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                      <li data-section=\"featured\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.active : depth0)) != null ? stack1.featured : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <a href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "/featured\" class=\"on-tab-nav\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Featured",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                      </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4=container.lambda;

  return "<div class=\"container\">\n    <header class=\"background background-lg text-left category-banner\">\n        <div class=\"category-header\">\n            <h2 class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Category",{"name":"trans","hash":{},"data":data}))
    + "</h2>\n            <div class=\"dropdown\">\n                <a href='#' data-toggle=\"dropdown\" aria-haspopup=\"true\" aria-expanded=\"false\">\n                    <h1 class=\"h2\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name : stack1), depth0))
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-down-small",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</h1>\n                </a>\n                <div class=\"triangle\"></div>\n                <ul class=\"dropdown-menu columns\" role=\"menu\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.categories : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </ul>\n            </div>\n            <h2 class=\"subtitle\">"
    + alias3(alias4(((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.subtitle : stack1), depth0))
    + "</h2>\n        </div>\n    </header>\n    <div id=\"filter-tabs\" class=\"sub-navigation\">\n        <div class=\"container\">\n            <nav class=\"pull-left\">\n                <ul>\n                    <li data-section=\"hot\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.active : depth0)) != null ? stack1.hot : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <a href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "\" class=\"on-tab-nav\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Hot",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                    </li>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isEnglish : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "                    <li data-section=\"new\""
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.active : depth0)) != null ? stack1["new"] : stack1),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ">\n                        <a href=\"/stories/"
    + alias3((helpers.slugify || (depth0 && depth0.slugify) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.category : depth0)) != null ? stack1.name_english : stack1),{"name":"slugify","hash":{},"data":data}))
    + "/new\" class=\"on-tab-nav\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New",{"name":"trans","hash":{},"data":data}))
    + "</a>\n                    </li>\n                </ul>\n            </nav>\n            <div class=\"actions pull-right\" role=\"menu\">\n                <div class=\"show-completed\"><a href=\"#\" role=\"button\" class=\"on-filter-completed\"> "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-circle-empty",11,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " <span class=\"show-completed-text fa-wp-neutral-2\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Only show completed",{"name":"trans","hash":{},"data":data}))
    + "</span></a></div>\n            </div>\n        </div>\n    </div>\n</div>\n\n<main id=\"tab-content\" class=\"container\">\n    <div class=\"stories\">\n"
    + ((stack1 = container.invokePartial(partials["core.collection_views.browse_stories"],depth0,{"name":"core.collection_views.browse_stories","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "    </div>\n</main>\n";
},"usePartial":true,"useData":true});