var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Select your premium theme",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                    "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Select your theme",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div id=\"subscription-modal\"></div>\n<div id=\"theme-settings\">\n    <div class=\"about-themes panel panel-default panel-default2\">\n        <div class=\"panel-body\">\n            <div class=\"title\" id=\"panel-body-title\" aria-hidden=\"true\">\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.getCurrentUserAttr || (depth0 && depth0.getCurrentUserAttr) || alias2).call(alias1,"isPremium",{"name":"getCurrentUserAttr","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "            </div>\n            <hr>\n            "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"ThemeSettings","premium-theme",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </div>\n    </div>\n</div>\n";
},"useData":true});