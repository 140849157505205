(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "BrowseTopics",
    app.collections.BaseCollection.extend({
      cacheLocal: true,

      arrayKey: "topics",
      fields: { topics: ["name", "categoryID", "browseURL", "tagURL"] },

      initialize: function(models, options) {
        options = options || {};
        this.topic = options.topic || null;
        this.discoverURL = options.discoverURL || null;
        this.image = options.image || null;
        this.type = options.type || null;
      },

      resource: function() {
        var topicName = this.topic ? "." + this.topic : "";
        return "topics" + topicName;
      },

      url: function() {
        const userLang =
          wattpad.utils.currentUser().get("language") || app.get("language");
        if (this.discoverURL) {
          return (
            this.discoverURL.replace(/^.*\/\/[^\/]+/, "") +
            "?language=" +
            userLang
          );
        } else {
          var topicName = this.topic ? "/" + this.topic : "";
          let type = this.type ? "&type=" + this.type : "";
          return (
            "/v5/browse/topics" + topicName + "?language=" + userLang + type
          );
        }
      }
    })
  );
})(window, _, wattpad, window.app);
