var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "btn-text";
},"3":function(container,depth0,helpers,partials,data) {
    return "btn-no-background";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"button-group inline-block relative pull-right comment-item-options-btn\">\n  <button class=\"btn "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isCommentNotification : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + " dropdown-toggle\"\n    data-toggle=\"dropdown\" aria-controls=\"comment-more-options\" aria-expanded=\"false\" aria-label=\"more options\">\n    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </button>\n  <div class=\"triangle\"></div>\n  <div class=\"dropdown-menu align-right\">\n      "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"CommentOptions",(depth0 != null ? depth0.id : depth0),{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n";
},"useData":true});