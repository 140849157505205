(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "UserEvent",
    app.models.Base.extend({
      defaults: {
        id: null,
        type: null,
        createDate: null,
        data: null
      },

      // list of supported types
      validTypes: [
        "follow",
        "vote",
        "comment",
        "inline_comment",
        "library",
        "upload",
        "message",
        "placeholder",
        "dedicate",
        "newMessage"
      ],

      isValid: function() {
        var curType = this.get("type");
        return _.contains(this.validTypes, curType);
      },

      createCommentDeeplinkUrl: function(attrs) {
        /*
       * Creating deeplinks for comments (both inline, end-of-part and replies).
       */
        if (attrs.data.comment.parentId) {
          return (
            attrs.data.story.part.url +
            "/comment/" +
            attrs.data.comment.parentId +
            "/replies/" +
            attrs.data.comment.id
          );
        } else {
          return (
            attrs.data.story.part.url + "/comment/" + attrs.data.comment.id
          );
        }
      },

      parse: function(attrs, options) {
        /*
       * Creating deeplinks for comments (both inline, end-of-part and replies).
       */
        if (
          (attrs.type === "comment" || attrs.type === "inline_comment") &&
          !_.get(attrs, "data.comment.deeplink_url")
        ) {
          attrs.data.comment.deeplink_url = this.createCommentDeeplinkUrl(
            attrs
          );
        }

        /* Message and newMessage attrs are formatted slightly differently.
      * Making this compatible with FeedItemMessage and newFeedItemMessage on Profile Conversations.
       */
        if (attrs.type === "message") {
          var feedId = attrs.id;
          _.extend(attrs, attrs.data.message);
          attrs.id = feedId;
          attrs.username = attrs.username || attrs.to.name;
          attrs.latestReplies = attrs.latestReplies || [];
        }

        //Handle aggregations
        if (attrs.children) {
          attrs.group = [];
          attrs.group.push({
            id: attrs.id,
            type: attrs.type,
            createDate: attrs.createDate,
            data: attrs.data
          });
        }
        return app.models.Base.prototype.parse(attrs, options);
      }
    })
  );

  app.mixin(app.models.UserEvent, "BeautifyURL");
})(window, _, wattpad, window.app);
