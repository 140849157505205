import defaultAnnouncement from "core/templates/components/default-announcement.hbs";
(function(window, _, wattpad, utils, app) {
  "use strict";
  app.add(
    "Announcement",
    Monaco.View.extend({
      template: defaultAnnouncement,

      className: "announcement",

      events: {
        "click  .on-dismiss": "stopEvent",
        "tap    .on-dismiss": "onDismiss"
      },

      initialize: function(options) {
        this.options = options;
      },

      render: function() {
        this.$el.html(this.template(this.getData()));

        return this;
      },

      getData: function() {
        return this.options || {};
      },

      onDismiss: function(el) {
        $("#announcements").remove();
        this.remove();

        utils.setCookie("anDi", 1, 3);
      },

      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
