var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<footer id=\"auth-footer\">\n    <div class=\"container\">\n        <ul role=\"navigation\" aria-label=\"Footer Links\">\n            <li><a href=\"/about\" title=\"About us\" aria-label=\"About Us\" rel=\"nofollow\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"About Us",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://creators.wattpad.com/\" title=\"Writers\" aria-label=\"Writers\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Writers",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://company.wattpad.com/jobs\" title=\"Jobs\" aria-label=\"Jobs\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Jobs",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"/go/directory\" title=\"Directory\" aria-label=\"Directory\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Directory",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"http://business.wattpad.com\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Business",{"name":"trans","hash":{},"data":data}))
    + "\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Business",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Business",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://company.wattpad.com/press\" title=\"Press\" aria-label=\"Press\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Press",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"/terms\" title=\"Terms\" aria-label=\"Terms\" rel=\"nofollow\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Terms",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://policies.wattpad.com/payment\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Payment Policy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Payment Policy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"/privacy\" title=\"Privacy\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Privacy",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n            <li><a href=\"https://policies.wattpad.com/accessibility\" title=\"Accessibility\" rel=\"nofollow\" aria-label=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Accessibility",{"name":"trans","hash":{},"data":data}))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Accessibility",{"name":"trans","hash":{},"data":data}))
    + "</a></li>\n        </ul>\n\n        <div class=\"social pull-right\">\n            <a href=\"https://facebook.com/wattpad\" rel=\"nofollow\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-facebook-square",24,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n            <a href=\"https://twitter.com/wattpad\" rel=\"nofollow\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-twitter",24,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n        </div>\n    </div>\n</footer>\n";
},"useData":true});