var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "    <div class=\"collection\">"
    + ((stack1 = ((helper = (helper = helpers.itemsContent || (depth0 != null ? depth0.itemsContent : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"itemsContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n    <div class=\"show-more center-text"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasMore : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(2, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n        <button class=\"btn btn-link on-showmore\" role=\"button\" aria-controls=\""
    + alias4(((helper = (helper = helpers.containerId || (depth0 != null ? depth0.containerId : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"containerId","hash":{},"data":data}) : helper)))
    + "\">\n          <span class='show-more-message'>\n              <span>"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n              <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n          </span>\n          <span class='loading-message hidden'>\n              "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n              <span> "
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading",{"name":"trans","hash":{},"data":data}))
    + " ...</span>\n          </span>\n        </button>\n    </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " hidden";
},"4":function(container,depth0,helpers,partials,data) {
    var helper;

  return "  <div class=\"collection\"><p>"
    + container.escapeExpression(((helper = (helper = helpers.emptyMessage || (depth0 != null ? depth0.emptyMessage : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"emptyMessage","hash":{},"data":data}) : helper)))
    + "</p></div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.count : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});