import tagModuleLists from "core/templates/item-views/tag-module-lists.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "TagModuleLists",
    app.views.Base.extend({
      // Properties
      // ----------
      className: "tag-module-lists",
      template: tagModuleLists,

      // Methods
      // -------
      render: function() {
        var data = this.model ? this.model.toJSON() : { empty: true };
        data.isDesktop = app.get("device").isDesktop();
        data.testGroups = wattpad.testGroups;

        this.$el.html(this.template(data));

        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
