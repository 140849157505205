var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div id=\"sp"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-pg"
    + alias4(((helper = (helper = helpers.pageNumber || (depth0 != null ? depth0.pageNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data}) : helper)))
    + "\"\n     data-page-number=\""
    + alias4(((helper = (helper = helpers.pageNumber || (depth0 != null ? depth0.pageNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data}) : helper)))
    + "\"\n     class=\"page col-sm-12 col-md-10 col-lg-8 col-md-offset-1 col-lg-offset-2"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isHeightZero : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n    <div class=\"panel panel-reading\" dir='"
    + ((stack1 = helpers["if"].call(alias1,(helpers.rtlLanguage || (depth0 && depth0.rtlLanguage) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.language : stack1)) != null ? stack1.id : stack1),{"name":"rtlLanguage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "'>\n"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.isMicroPart : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(16, data, 0),"data":data})) != null ? stack1 : "")
    + "    </div>\n</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.cta : depth0),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n\n\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " height-zero ";
},"4":function(container,depth0,helpers,partials,data) {
    return "rtl";
},"6":function(container,depth0,helpers,partials,data) {
    return "ltr";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <pre>\n              "
    + ((stack1 = (helpers.renderTrinityAudioPlayer || (depth0 && depth0.renderTrinityAudioPlayer) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.group : depth0),{"name":"renderTrinityAudioPlayer","hash":{},"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"OUTSTREAM_ELIGIBLE",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + "            </pre>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.firstPage : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.program(12, data, 0),"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                        "
    + ((stack1 = (helpers.insertOutstreamAd || (depth0 && depth0.insertOutstreamAd) || alias2).call(alias1,(helpers.parseEmbeddedMedia || (depth0 && depth0.parseEmbeddedMedia) || alias2).call(alias1,(depth0 != null ? depth0.text : depth0),720,{"name":"parseEmbeddedMedia","hash":{},"data":data}),{"name":"insertOutstreamAd","hash":{"storyGroup":(depth0 != null ? depth0.group : depth0),"experience":"reading"},"data":data})) != null ? stack1 : "")
    + "\n";
},"12":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                        "
    + ((stack1 = (helpers.parseEmbeddedMedia || (depth0 && depth0.parseEmbeddedMedia) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.text : depth0),720,{"name":"parseEmbeddedMedia","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"14":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.parseEmbeddedMedia || (depth0 && depth0.parseEmbeddedMedia) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.text : depth0),720,{"name":"parseEmbeddedMedia","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"16":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "            <pre>\n              "
    + ((stack1 = (helpers.renderTrinityAudioPlayer || (depth0 && depth0.renderTrinityAudioPlayer) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.group : depth0),{"name":"renderTrinityAudioPlayer","hash":{},"data":data})) != null ? stack1 : "")
    + "\n              "
    + ((stack1 = (helpers.parseEmbeddedMedia || (depth0 && depth0.parseEmbeddedMedia) || alias2).call(alias1,(depth0 != null ? depth0.text : depth0),720,{"name":"parseEmbeddedMedia","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </pre>\n";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.story_reading.cta"],depth0,{"name":"core.story_reading.cta","data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"20":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div id=\"sp"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "-pg"
    + alias4(((helper = (helper = helpers.pageNumber || (depth0 != null ? depth0.pageNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data}) : helper)))
    + "\"\n     data-page-number=\""
    + alias4(((helper = (helper = helpers.pageNumber || (depth0 != null ? depth0.pageNumber : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"pageNumber","hash":{},"data":data}) : helper)))
    + "\"\n     class=\"page highlighter"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isHeightZero : depth0),{"name":"if","hash":{},"fn":container.program(21, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.firstPage : depth0),{"name":"if","hash":{},"fn":container.program(23, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.lastPage : depth0),{"name":"if","hash":{},"fn":container.program(25, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n\n    <div class=\"col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-7 col-md-offset-1 col-lg-6 col-lg-offset-3 panel panel-reading\"\n        dir=\""
    + ((stack1 = helpers["if"].call(alias1,(helpers.rtlLanguage || (depth0 && depth0.rtlLanguage) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.language : stack1)) != null ? stack1.id : stack1),{"name":"rtlLanguage","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "\">\n        <pre>\n          "
    + ((stack1 = (helpers.renderTrinityAudioPlayer || (depth0 && depth0.renderTrinityAudioPlayer) || alias2).call(alias1,(depth0 != null ? depth0.id : depth0),(depth0 != null ? depth0.group : depth0),{"name":"renderTrinityAudioPlayer","hash":{},"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"OUTSTREAM_ELIGIBLE",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(27, data, 0),"inverse":container.program(30, data, 0),"data":data})) != null ? stack1 : "")
    + "        </pre>\n    </div>\n\n    <div class=\"col-xs-10 col-xs-offset-1 col-sm-10 col-sm-offset-1 col-md-4 col-md-offset-0 col-lg-3 col-lg-offset-0 right-rail "
    + ((stack1 = (helpers.isDevice || (depth0 && depth0.isDevice) || alias2).call(alias1,"desktop",{"name":"isDevice","hash":{},"fn":container.program(32, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.firstPage : depth0),{"name":"if","hash":{},"fn":container.program(35, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "     </div>\n</div>\n";
},"21":function(container,depth0,helpers,partials,data) {
    return " height-zero";
},"23":function(container,depth0,helpers,partials,data) {
    return " first-page";
},"25":function(container,depth0,helpers,partials,data) {
    return " last-page";
},"27":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.firstPage : depth0),{"name":"if","hash":{},"fn":container.program(28, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "");
},"28":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "                    "
    + ((stack1 = (helpers.insertOutstreamAd || (depth0 && depth0.insertOutstreamAd) || alias2).call(alias1,(helpers.parseEmbeddedMedia || (depth0 && depth0.parseEmbeddedMedia) || alias2).call(alias1,(depth0 != null ? depth0.text : depth0),720,{"name":"parseEmbeddedMedia","hash":{},"data":data}),{"name":"insertOutstreamAd","hash":{"storyGroup":(depth0 != null ? depth0.group : depth0),"experience":"reading"},"data":data})) != null ? stack1 : "")
    + "\n";
},"30":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                "
    + ((stack1 = (helpers.parseEmbeddedMedia || (depth0 && depth0.parseEmbeddedMedia) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.text : depth0),720,{"name":"parseEmbeddedMedia","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"32":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.unless.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.firstPage : depth0),{"name":"unless","hash":{},"fn":container.program(33, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"33":function(container,depth0,helpers,partials,data) {
    return " sticky-ad-container";
},"35":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.story_reading.part_top_ad"],depth0,{"name":"core.story_reading.part_top_ad","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + ((stack1 = container.invokePartial(partials["core.story_reading.part_similar"],depth0,{"name":"core.story_reading.part_similar","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "\n"
    + ((stack1 = (helpers.isDevice || (depth0 && depth0.isDevice) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"mobile",{"name":"isDevice","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "");
},"usePartial":true,"useData":true});