(function(window, _, $, app, Monaco) {
  "use strict";
  /**
   * Takes a jQuery <element> (or selector string) and "fixes" its position to the screen while <document>
   * scroll position is within the bounds of an outer <wrapper> element (or selector string) also requires
   * an "offset" value === the location (pixels from screen.top) where the <element> will be fixed
   * @mixin AffixElement
   */
  app.add(
    "AffixElement",
    Monaco.Mixin.create({
      initialize: function(options, next) {
        this.afxElements = [];
        next(options);
      },

      initAffix: function(element, wrapper, offset, noop) {
        element = typeof element === "string" ? $(element) : element;
        wrapper = typeof wrapper === "string" ? $(wrapper) : wrapper;

        this.afxElements.push({
          element: element,
          wrap: wrapper,
          offset: offset,
          height: element.height()
        });

        // ensure `element` can be relatively positioned to `wrapper`
        if (wrapper.css("position") === "static") {
          wrapper.css("position", "relative");
        }
        noop();
      },

      affixOnScroll: function(noop) {
        // ** these values need to be set here (rather than in the init function) to
        // support infinite loading content, which can change the value of the bounds
        _.forEach(this.afxElements, function(afx) {
          // If the element isn't currently visible (due to responsive breakpoint etc.) then
          // updating the position isn't useful, and in some browsers can throw errors.
          if (!afx.element.is(":visible")) return;

          var scrollTop = $(window).scrollTop(),
            upperBounds = afx.wrap.offset().top,
            lowerBounds = upperBounds + afx.wrap.height();

          // return afxElement to its natural position
          if (upperBounds > scrollTop + afx.offset) {
            afx.element.css({ position: "", top: "" });
            afx.element.removeClass("affixed bottomed");

            // fix afxElement's position on the screen
          } else if (
            upperBounds <= scrollTop + afx.offset &&
            lowerBounds > scrollTop + afx.height + afx.offset
          ) {
            afx.element.css({ position: "fixed", top: afx.offset + "px" });
            afx.element.addClass("affixed").removeClass("bottomed");

            // lock afxElement to it's lowest position
          } else {
            afx.element.css({
              position: "absolute",
              top: lowerBounds - upperBounds - afx.height + "px"
            });
            afx.element.addClass("bottomed").removeClass("affixed");
          }
        });
        noop();
      }
    })
  );
})(window, _, jQuery, window.app, window.Monaco);
