(function(window, app) {
  "use strict";

  app.router.add({
    "user/:username(/)(:section)(/)": [
      "userProfile",
      {
        regexp: {
          username: /[\w\-]+/,
          section: /about|following|followers|conversations|quests/
        }
      }
    ]
  });

  // User Profile - Admin
  app.router.add({
    "user/:username/admin": [
      "userProfile",
      {
        regexp: {
          username: /[\w\-]+/
        }
      }
    ]
  });
})(window, window.app);
