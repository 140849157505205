var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", alias5=container.lambda;

  return "<div class='cover-container'>\n  <a class=\"on-navigate cover cover-lg on-story-item\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n      <div class=\"fixed-ratio fixed-ratio-cover\">\n          <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),256,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n      </div>\n  </a>\n\n  <div class=\"story-overlay on-story-hover\" style=\"display: none;\">\n      <div class=\"overlay-bg\"></div>\n      <div class=\"overlay-container\">\n          <button class=\"btn btn-glass btn-block btn-library btn-left-align\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-toggle=\"tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add to Library",{"name":"trans","hash":{},"data":data}))
    + "\">\n              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add",{"name":"trans","hash":{},"data":data}))
    + "\n          </button>\n          <a class=\"btn btn-glass btn-block on-navigate btn-left-align\" href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\">\n              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Read",{"name":"trans","hash":{},"data":data}))
    + "\n          </a>\n          <button class=\"btn btn-glass btn-block btn-left-align btn-dismiss\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-toggle=\"tooltip\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss Recommendation",{"name":"trans","hash":{},"data":data}))
    + "\">\n              "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Dismiss",{"name":"trans","hash":{},"data":data}))
    + "\n          </button>\n      </div>\n  </div>\n</div>\n\n<div class=\"content\">\n    <div class=\"info\">\n        <strong>"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</strong>\n        <small>"
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</small>\n    </div>\n    <a href=\"/user/"
    + alias3(alias5(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-sm on-navigate\">\n        <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.avatar : stack1),32,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n</div>\n";
},"useData":true});