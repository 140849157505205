/**********************************
 * Facebook stuff
 * **************/

(function() {
  var wp = (window.wp = window.wp || {});

  window.FBStatus = new function(params) {
    this.loggedIn = false;
    this.permissions = [];
    this.accessToken = "";
  }();

  window.fbAsyncInit = function() {
    FB.init({
      appId: wp.app_id,
      cookie: true,
      xfbml: true,
      version: "v2.4" // FB_JS_SDK_VERSION
    });

    // Check if we need any of the requested permissions
    // returns true or false
    function needPermissions(permissions) {
      return permissions.reduce(function(prev, cur) {
        return prev || !!FBStatus.permissions[cur];
      }, false);
    }

    // Performs the actual request to auth with facebook if we're not logged in or if we are missing permissions.
    function doRequest(permissions, options, callback) {
      var requestedPermissions = permissions.split(",");

      if (!FBStatus.loggedIn || needPermissions(requestedPermissions)) {
        options.scope = requestedPermissions;

        FB.login(resolveRequest(callback), options);
      } else {
        callback();
      }
    }

    function resolveRequest(callback) {
      return function(response) {
        if (response.authResponse) {
          FBStatus.accessToken = response.authResponse.accessToken;
          return callback();
        } else {
          return false;
        }
      };
    }

    wp.facebook_reprompt_permission = function(permissions, callback) {
      doRequest(permissions, { auth_type: "rerequest" }, callback);
    };

    wp.facebook_prompt_permission = function(permissions, callback) {
      doRequest(permissions, {}, callback);
    };

    wp.facebook_share_dialog = function(url, title, description, image) {
      FB.ui({
        method: "share_open_graph",
        action_type: wp.facebook_app_name + ":read",
        action_properties: JSON.stringify({
          story: {
            "og:url": url,
            "og:title": title,
            "og:description": description,
            "og:image": image
          }
        })
      });
    };
  };
})();
