import libraryReadingListItem from "core/templates/library/library-reading-list-item.hbs";
// Refers to a single reading list for the 'Reading Lists' Tab in Library Page

(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "LibraryListCard",
    app.views.Base.extend({
      className: "reading-list-item-row",

      template: libraryReadingListItem,

      events: {
        "tap .on-delete-list": "onDelete",
        "click .on-delete-list": "stopEvent"
      },

      active: false,

      render: function() {
        var data = this.model.toJSON();
        data.socialLinks = this.buildSocialShareLinksForReadingList(
          _.clone(data, {
            author: utils.currentUser().get("username")
          }),
          "list"
        );
        data.testGroups = wattpad.testGroups;
        this.$el.html(this.template(data));

        return this;
      },

      onDelete: function() {
        if (
          window.confirm(
            wattpad.utils.trans(
              "Are you sure you want to delete this reading list?"
            )
          )
        ) {
          this.model.destroy();
        }
      }
    })
  );

  app.mixin(app.views.LibraryListCard, "SocialSharing");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
