(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "Autocomplete",
    Monaco.Collection.extend({
      cacheLocal: false,

      url: function() {
        return "/v4/autocomplete/query?term=" + this.searchTerm;
      },

      resource: function() {
        return "search.autocomplete." + this.searchTerm;
      },

      initialize: function(models, options) {
        options = options || {};
        this.searchTerm = options.searchTerm || "";
      },

      // API returns an array of strings
      parse: function(responses) {
        var collection = [];

        _.each(responses, function(response, index) {
          collection.push({ itemId: index, term: response });
        });

        return collection;
      }
    })
  );
})(window, _, wattpad, window.app);
