// TODO: Delete report-modal.js when New React modal is 100% pushed to prod

(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "ReactReportModal",
    app.views.DummyReactView.extend({
      component: "ReportModal",
      componentId: "report-modal",

      constructor: function(opts) {
        this.reportModalContainer = opts.reportModalContainer;

        this.componentData = {
          title: opts.title,
          reportType: opts.reportType,
          requesterUsername: opts.requesterUsername,
          requesterEmail: opts.requesterEmail,
          reportId: opts.reportId,
          hideModal: this.onClose.bind(this),
          showErrorToast: this.showErrorToast
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      // Temporary method to generate error toast (cannot use toggleToast in backbone)
      // TODO: Delete this method once new error designs come in
      showErrorToast: function(message) {
        const toastOptions = { type: "dismissable" };
        wattpad.utils.showToast(message, toastOptions);
      },

      onClose: function(evt) {
        $(this.reportModalContainer).modal("hide");
        $(this.reportModalContainer).off("hidden.bs.modal");
        $(`${this.reportModalContainer} .modal-content`).removeClass(
          "report-modal-wrapper"
        );

        // trigger the hiding muted comments animation if reported user was also muted
        if (this.componentData.reportType === "comment") {
          var reportedUser = this.componentData.reportId.name;
          var isReportedUserMuted =
            window.store &&
            window.store.getState().profileData &&
            window.store.getState().profileData.mutedUsers &&
            window.store
              .getState()
              .profileData.mutedUsers.some(
                user => user.username == reportedUser
              );

          // Muting a user through the Comment Report Modal should trigger "app:comment:mute",
          // which hides comments from the muted user. See onRemoveMutedComment in core/views/item-views/comment-item.js
          if (isReportedUserMuted) {
            app.trigger("app:comment:mute", { username: reportedUser });
          }
        }
        return;
      }
    })
  );

  app.mixin(app.views.ReactReportModal);
})(window, _, wattpad, window.app, wattpad.utils);
