var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<section class=\"modal profile-modal fade\" id=\"userClose\" tabindex=\"-1\" role=\"dialog\" aria-labelledby=\"userClose\" aria-hidden=\"true\">\n    <div class=\"modal-dialog modal-sm\">\n        <div class=\"modal-content\">\n            <header class=\"modal-header\">\n                <div class=\"pull-right top-right close\" data-dismiss=\"modal\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n                <h3 class=\"modal-title\" id=\"myModalLabel\">Close User Account</h3>\n            </header>\n            <div class=\"modal-body\">\n                <p>Select Reason:</p>\n                <select id=\"close-reason\">\n                    <option value=\"banned_explicit\">Banned - Explicit</option>\n                    <option value=\"banned_self_harm\">Banned - Self Harm</option>\n                    <option value=\"copyright\">Copyright Infringement</option>\n                    <option value=\"csem\">CSEM found</option>\n                    <option value=\"csem_image_moderation\">CSEM found by image moderation</option>\n                    <option value=\"cyberbullying_harassment\">Cyberbullying/Harassment</option>\n                    <option value=\"extremism\">Extremism</option>\n                    <option value=\"poaching\">Poaching</option>\n                    <option value=\"previous_account_closed\">Previous account closed</option>\n                    <option value=\"sexual_messaging\">Sexual Messaging</option>\n                    <option value=\"spam\">Spam</option>\n                    <option value=\"underage\">Underage</option>\n                </select>\n            </div>\n            <footer class=\"modal-footer\">\n                <a role=\"button\" href=\"#\" class=\"btn btn-orange on-close-user-account\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Close Account",{"name":"trans","hash":{},"data":data}))
    + "</a>\n            </footer>\n        </div>\n    </div>\n</section>\n";
},"useData":true});