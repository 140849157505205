import libraryReadingListStoryItem from "core/templates/library/reading-list-details/library-reading-list-story-item.hbs";
// Library Reading List Details Page (/list/:listid)
(function(window, wattpad, utils, app) {
  "use strict";

  app.add(
    "LibraryReadingListStoryItem",
    Monaco.View.extend({
      className: "list-group-item",

      tagName: "li",

      template: libraryReadingListStoryItem,

      initialize: function(options) {
        options = options || {};
        this.isOwner = options.isOwner || false;
        this.listId = options.id || null;
      },

      render: function() {
        var data = this.model.toJSON();
        data.list = {
          isOwner: this.isOwner,
          listId: this.listId
        };
        this.$el.html(this.template(data));
        return this;
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
