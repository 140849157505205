var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div id=\"preview-modal\"></div>\n\n<div class=\"panel panel-default2\">\n\n  <header class=\"panel-heading\">\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"panel-title\">\n      "
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"Story by %s","Stories by %s",(depth0 != null ? depth0.totalWorks : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.name : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "\n    </div>\n\n    <ul class=\"metadata\">\n      <li>"
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Published Story","%s Published Stories",(depth0 != null ? depth0.numPublished : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numPublished : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</li>\n\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,(depth0 != null ? depth0.username : depth0),{"name":"isCurrentUser","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </ul>\n  </header>\n\n  <div class=\"panel-body\">\n    <div id=\"works-item-view\">"
    + ((stack1 = ((helper = (helper = helpers.worksItemViewContent || (depth0 != null ? depth0.worksItemViewContent : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"worksItemViewContent","hash":{},"data":data}) : helper))) != null ? stack1 : "")
    + "</div>\n  </div>\n\n  <div class=\"show-more center-text hidden\">\n    <button class=\"btn btn-grey on-showmore\">\n      <span class='show-more-message'>\n        <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Show more",{"name":"trans","hash":{},"data":data}))
    + "</span>\n        <span class=\"fa fa-down-small fa-wp-neutral-2\"></span>\n      </span>\n      <span class='loading-message hidden'>\n        "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"className":"loading-spinner fa-spin-fix-position"},"data":data})) != null ? stack1 : "")
    + "\n        <span> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Loading...",{"name":"trans","hash":{},"data":data}))
    + "</span>\n      </span>\n    </button>\n  </div>\n</div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "    <a href=\"/myworks\" class=\"settings on-navigate\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-settings",24,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.numDrafts : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <li>\n      "
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Draft","%s Drafts",(depth0 != null ? depth0.numDrafts : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numDrafts : depth0),{"name":"sprintf","hash":{},"data":data}))
    + " ("
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"only visible to you",{"name":"trans","hash":{},"data":data}))
    + ")\n      </li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.totalWorks : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});