var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.backgroundUrl : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.program(4, data, 0, blockParams, depths),"data":data})) != null ? stack1 : "")
    + "        <div class=\"avatar avatar-lg pull-left\">\n            <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),72,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n        </div>\n        <div class=\"content\">\n            <h4 class=\"h3\">"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</h4>\n            <small>\n                @"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numWorks : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </small>\n        </div>\n    </a>\n\n    <button class=\"btn btn-triangle btn-fan\" data-target=\""
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" data-following=\""
    + alias3(((helper = (helper = helpers.following || (depth0 != null ? depth0.following : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"following","hash":{},"data":data}) : helper)))
    + "\" data-toggle=\"tooltip\" data-placement=\"left\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Follow",{"name":"trans","hash":{},"data":data}))
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-follow",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <span class=\"toggle-triangle\"></span>\n        <span class=\"toggle-shadow\"></span>\n    </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <a href=\"/user/"
    + alias3(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"panel on-navigate follow\" style=\"background-image: url( "
    + alias3((helpers.profileBackground || (depth0 && depth0.profileBackground) || alias2).call(alias1,(depth0 != null ? depth0.backgroundUrl : depth0),640,{"name":"profileBackground","hash":{},"data":data}))
    + " );\">\n";
},"4":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.escapeExpression;

  return "        <a href=\"/user/"
    + alias1(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"panel on-navigate follow\" style=\"background-color: "
    + alias1(container.lambda(((stack1 = (depths[1] != null ? depths[1].data : depths[1])) != null ? stack1.highlight_colour : stack1), depth0))
    + "\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "&middot; "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Work","%s Works",(depth0 != null ? depth0.numWorks : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numWorks : depth0),{"name":"sprintf","hash":{},"data":data}));
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "&middot; "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Follower","%s Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numFollowers : depth0),{"name":"sprintf","hash":{},"data":data}));
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing;

  return "<header class=\"panel-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm2 on-navigate\" href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n      <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <h3 class=\"h6\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"%1$s followed %2$s",((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.followed : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</h3>\n    <time datetime=\""
    + alias2(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias4),(typeof helper === "function" ? helper.call(alias3,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias2((helpers.fromNow || (depth0 && depth0.fromNow) || alias4).call(alias3,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_options"],depth0,{"name":"core.feed_items.feed_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n\n<div class=\"panel-body\">\n\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,alias1(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.followed : stack1), depth0),{"name":"data.followed","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n\n<footer class=\"panel-footer\">\n  <a href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.followed : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"btn btn-text on-navigate\">"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"View profile",{"name":"trans","hash":{},"data":data}))
    + "</a>\n</footer>\n";
},"usePartial":true,"useData":true,"useDepths":true});