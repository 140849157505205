import recommendedFollowItemCard from "core/templates/item-views/recommended-follow-item-card.hbs";
import recommendedFollowItem from "core/templates/item-views/recommended-follow-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RecommendedFollowItem",
    Monaco.View.extend({
      className: "recommended-user",

      template: {
        row: recommendedFollowItem,
        card: recommendedFollowItemCard
      },

      events: {
        "tap .on-dismiss": "onDismiss",
        "click .on-dismiss": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};
        this.cards = !!options.cards;
        this.listenTo(app, "app:user:follow", this.onUpdateFollow);
        this.listenTo(app, "app:user:unfollow", this.onUpdateUnfollow);
      },

      // this basically acts as a className function
      // can't use that becuase it would depend on something pass in with initialize,
      //     which would be called after
      getClassName: function(isCard) {
        return isCard
          ? "recommended-user-card col-md-3 col-sm-4 col-xs-6"
          : "recommended-user";
      },

      render: function() {
        var template = this.cards ? this.template.card : this.template.row;
        if (this.model.get("recommendationHint")) {
          this.model.set(
            "recommendationHint",
            utils.unsanitizeHTML(this.model.get("recommendationHint"))
          );
        }
        this.$el.html(template(this.model.toJSON()));
        return this;
      },

      onUpdateFollow: function(username) {
        var self = this,
          $btn = this.$(this.followButton + "[data-target=" + username + "]");
        $btn
          .children(".fa")
          .removeClass("fa-follow fa-wp-neutral-2")
          .addClass("fa-following fa-wp-neutral-2");

        var collectionHolder = this.model.collection;

        if (username === this.model.get("username")) {
          if (utils.supportsTransition()) {
            this.$el.addClass("faded");
          }
          _.delay(function() {
            if (self.model.collection) {
              self.model.collection.remove(self.model);
              collectionHolder.trigger("update");
            }
          }, 300);
        }
      },

      onUpdateUnfollow: function(target) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        $btn
          .children(".fa")
          .removeClass("fa-following fa-wp-neutral-2")
          .addClass("fa-follow fa-wp-neutral-2");
      },

      onDismiss: function(evt) {
        var self = this,
          $btn = $(evt.currentTarget),
          username = $btn.data("target");
        if (username === this.model.get("username")) {
          if (utils.supportsTransition()) {
            this.$el.addClass("faded");
          }
          _.delay(function() {
            if (self.model.collection) {
              self.model.collection.removeAndDismiss(self.model);
            }
          }, 300);
        }
      }
    })
  );

  app.mixin(app.views.RecommendedFollowItem, "FollowManager");
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
