var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers.each.call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.stories : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "      <div class=\"story"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.promoted : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "\" data-story-id=\""
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n"
    + ((stack1 = container.invokePartial(partials["core.item_views.story_item"],depth0,{"name":"core.item_views.story_item","data":data,"indent":"        ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "      </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return " story-item";
},"5":function(container,depth0,helpers,partials,data) {
    return " on-story-preview";
},"7":function(container,depth0,helpers,partials,data) {
    return "    <p class=\"no-item\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"No stories available.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.escapeExpression;

  return "<a class=\"btn btn-link\" href='"
    + alias1(container.lambda(((stack1 = (depth0 != null ? depth0.filter : depth0)) != null ? stack1.link : stack1), depth0))
    + "'>\n  "
    + alias1((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"View More in %s",((stack1 = (depth0 != null ? depth0.filter : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\n</a>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "<div class='collection'>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.hasStories : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.filter : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "<div id=\"preview-modal\"></div>\n";
},"usePartial":true,"useData":true});