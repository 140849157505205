import verifyEmailAnnouncement from "core/templates/components/verify-email-announcement.hbs";
(function(window, _, wattpad, app) {
  "use strict";
  app.add(
    "VerifyEmail",
    app.views.Announcement.extend({
      template: verifyEmailAnnouncement,

      state: "default",

      className: app.views.Announcement.prototype.className + " verify-email",

      events: _.extend(
        {
          "click  .on-verify": "stopEvent",
          "tap    .on-verify": "onVerify",

          "click  .on-changeemail": "stopEvent",
          "tap    .on-changeemail": "onChangeEmail",

          "click  .learn-more": "stopEvent",
          "tap    .learn-more": "onLearnMore",

          "tap    .on-close": "onClose",
          "click  .on-close": "stopEvent"
        },
        app.views.Announcement.prototype.events
      ),

      initialize: function(options) {
        Handlebars.registerPartial("core.signup_prompt", function() {
          return "";
        });
      },

      getData: function() {
        var data = {};
        data[this.state] = true;
        return data;
      },

      onVerify: function(e) {
        wattpad.utils.stopEvent(e);

        var self = this;
        Promise.resolve(
          $.ajax({
            type: "POST",
            url: "/api/v3/users/validate",
            data: { activation_email: "true" }
          })
        )
          .then(function(data) {
            self.onEmailSent({
              isChangeEmailModal: false,
              isSuccess: true
            });
          })
          .catch(function(data) {
            self.onEmailSent({
              isChangeEmailModal: false,
              isSuccess: false
            });
          });
      },

      onEmailSent: function(modalOptions) {
        var view = new app.views.VerifyEmailModal(modalOptions);
        $("#generic-modal .modal-body").html(view.render().$el);
        $("#generic-modal .modal-content").addClass("sent-email-modal-wrapper");
        $("#generic-modal").modal({});
        return;
      },

      onChangeEmail: function(e) {
        wattpad.utils.stopEvent(e);

        var modalOptions = {
          isChangeEmailModal: true,
          isSuccess: false
        };
        wattpad.utils.showChangeEmailModal(modalOptions);
      },

      onLearnMore: function(e) {
        wattpad.utils.stopEvent(e);
        wattpad.utils.showPleaseVerifyModal();
        return;
      },

      onClose: function(evt) {
        $("#generic-modal").modal("hide");
        // remove custom class with a delay so that there's no pop-in during
        // jQuery modal close animation
        setTimeout(function() {
          $("#generic-modal .modal-content").removeClass(
            "sent-email-modal-wrapper"
          );
          $("#generic-modal .modal-content").removeClass(
            "change-email-modal-wrapper"
          );
        }, 500);
      }
    })
  );
})(window, _, wattpad, window.app);
