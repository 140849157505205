(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * This needs to get mixed in at the base view level.
   *
   * Simply create an element in your template with following properties:
   * 1) class .btn-fan
   * 2) data-target="username"
   * 3) optional data-following="true|false"
   *    If data-following is not provided, there will be an asynchronous fetch to grab the status.
   *
   * It will build its own 'database' of following statuses.
   *
   * 1) State management ( Following VS Follow )
   * 2) If user is not logged in, the button will launch a signup modal or redirect, depending on platform
   * 3) Triggers events:
   *    a) On follow: "app:user:follow" with the username of the user that was followed
   *    b) On unfollow: "app:user:unfollow" with the username of the user that was unfollowed
   *
   ** Rules:
   * 1) If there is an SSR load, it's up to you to determine what the initial state should be.
   *
   * @mixin FollowManager
   */
  app.add(
    "FollowManager",
    Monaco.Mixin.create({
      events: {
        "tap .on-follow": "onFollow",
        "click .on-follow": "stopEvent",

        "tap .on-unfollow": "onUnfollow",
        "click .on-unfollow": "stopEvent",

        "tap .on-follow-login": "onFollowLogin",
        "click .on-follow-login": "stopEvent"
      },

      followButton: ".btn-fan",

      initialize: function(options, next) {
        if (!app.get("followStatus")) {
          app.set("followStatus", {});
        }
        next(options);
      },

      setElement: function(el, next) {
        var btns = $(el).find(this.followButton);
        _.each(
          btns,
          function(btn) {
            var name = $(btn).data("target");

            // If name is empty it means the user account is deactivated,
            // so we don't need to do any changes to the follow button.
            if (name === "") return;

            var source = this.$(
              this.followButton + "[ data-target=" + name + "]"
            );
            if (source && source.length !== 0) {
              $(btn).replaceWith(source[0]);
            }
          },
          this
        );

        next(el);
      },

      render: function(next) {
        var result = next();
        this.setupDOM();
        return result;
      },

      setupDOM: function(next) {
        var currentUser = wattpad.utils.currentUser();

        this.$(this.followButton).prop("disabled", true);

        if (currentUser.authenticated()) {
          this.fetchFollowingStatus();
        } else {
          this.$(this.followButton).prop("disabled", false);
          this.setFollowLoginState();
        }

        next();
      },

      fetchFollowingStatus: function(next) {
        var btns = this.$(this.followButton),
          self = this,
          $btn = null,
          followTarget = null;

        _.each(
          btns,
          function(btn) {
            $btn = $(btn);
            followTarget = $(btn).data("target");
            if (followTarget) {
              if (followTarget in app.get("followStatus")) {
                this.updateFollowButtons(
                  followTarget,
                  app.get("followStatus")[followTarget]
                );
              } else {
                var isFollowing = false;
                if ($btn.data("following") !== undefined) {
                  isFollowing = $btn.data("following");
                  this.updateFollowButtons(followTarget, isFollowing);
                } else {
                  var model = new app.models.User({ username: followTarget });
                  Promise.resolve(model.determineFollowStatus()).then(
                    function() {
                      self.updateFollowHash(
                        model.get("username"),
                        model.get("following")
                      );
                      self.updateFollowButtons(
                        model.get("username"),
                        model.get("following")
                      );
                    }
                  );
                }
                //If we sent off a fetch, an entry will be created so we don't double fetch
                this.updateFollowHash(followTarget, isFollowing);
              }
            }
          },
          this
        );

        next();
      },

      setFollowLoginState: function(next) {
        this.$(this.followButton).addClass("on-follow-login");
        next();
      },

      onFollowLogin: function(e, noop) {
        if ($("#generic-modal").length === 0) {
          window.location.href = "/login?nexturl=" + window.location.href;
        } else {
          var target = $(e.currentTarget).data("target");
          if (_.get(window, "wattpad.testGroups.NEW_ONBOARDING_1", false)) {
            var view = new app.views.SignUpPrompt({
              model: new app.models.Authsignup(),
              title: wattpad.utils.trans(
                "Sign up to follow %s and receive updates",
                target
              ),
              formType: "follow_modal",
              form: "signup"
            });
            $("#generic-modal .modal-content").addClass("auth-modal");
            $("#generic-modal .modal-body").html(view.render().$el);
            $("#generic-modal").modal("show");
          } else {
            var view = new app.views.SignUpPrompt({
              model: new app.models.Authsignup(),
              title: wattpad.utils.trans("Join Wattpad"),
              subtitle: wattpad.utils.trans(
                "Follow others to receive story updates!"
              )
            });
            $("#generic-modal .modal-content").addClass("auth-modal");
            $("#generic-modal .modal-body").html(view.render().$el);
            $("#generic-modal").modal("show");
          }
        }
        noop(e);
      },

      updateFollowHash: function(target, isFollowing, next) {
        var followStatus = app.get("followStatus");
        followStatus[target] = isFollowing;
        app.set("followStatus", followStatus);
        next();
      },

      updateFollowButtons: function(target, isFollowing, next) {
        var $btn = this.$(this.followButton + "[data-target=" + target + "]");
        if (isFollowing) {
          $btn.removeClass("on-follow").addClass("on-unfollow");
          app.trigger("app:user:follow", target);
        } else {
          $btn.removeClass("on-unfollow").addClass("on-follow");
          app.trigger("app:user:unfollow", target);
        }

        $btn.prop("disabled", false);
        next();
      },

      onFollow: function(evt, next) {
        //check user's email is verified
        if (!wattpad.user.verified_email) {
          utils.showPleaseVerifyModal();
          return;
        }

        var self = this,
          target = $(evt.currentTarget).data("target"),
          $btn = this.$(this.followButton + "[data-target=" + target + "]");

        wattpad.utils.stopEvent(evt);
        $btn.prop("disabled", true);
        // POST follow request to the API
        Promise.resolve(
          $.when(
            wattpad.utils
              .currentUser()
              .following()
              .follow(target)
          )
        ).then(function() {
          self.updateFollowHash(target, true);
          self.updateFollowButtons(target, true);
          self.clearCache();
        });
        next(evt);
      },

      onUnfollow: function(evt, next) {
        var self = this,
          target = $(evt.currentTarget).data("target"),
          $btn = this.$(this.followButton + "[data-target=" + target + "]");

        wattpad.utils.stopEvent(evt);
        $btn.prop("disabled", true);
        // POST unfollow request to the API
        Promise.resolve(
          $.when(
            wattpad.utils
              .currentUser()
              .following()
              .unfollow(target)
          )
        ).then(function() {
          self.updateFollowHash(target, false);
          self.updateFollowButtons(target, false);
          self.clearCache();
        });
        next(evt);
      },

      clearCache: function(target, noop) {
        var currentUserName = wattpad.utils.currentUser().get("username");
        // clear cached data as necessary
        app.local.clear("user." + currentUserName);
        app.local.clear("user." + currentUserName + ".following");
        app.local.clear("user." + currentUserName + ".profile.following");

        app.local.clear("user." + target);
        app.local.clear("user." + target + ".following");
        app.local.clear("user." + target + ".profile.following");

        noop(target);
      },

      stopEvent: function(e, noop) {
        wattpad.utils.stopEvent(e);
        noop(e);
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
