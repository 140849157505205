(function(window, _, $, app, Monaco) {
  "use strict";
  /**
   *
   * @mixin AutocompleteField
   */
  app.add(
    "AutocompleteField",
    Monaco.Mixin.create({
      initAutocomplete: function(options, noop) {
        this.boundFields = this.boundFields || [];
        this.selector = $(options.element);
        this.autocompleteCollection = options.collection;
        this.title = options.title || null;

        this.autocompleteResults = new app.views.AutocompleteResults({
          collection: this.autocompleteCollection,
          boundField: this.selector,
          title: this.title
        });

        this.selector
          .parent()
          .find(".autocomplete-results")
          .remove();
        this.selector.before(this.autocompleteResults.render().$el);

        noop();
      },

      remove: function(next) {
        if (this.autocompleteResults) {
          this.autocompleteResults.remove();
        }
        next();
      }
    })
  );
})(window, _, jQuery, window.app, window.Monaco);
