import funbar from "core/templates/story-reading/funbar.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";
  app.add(
    "BaseStoryReadingFunbar",
    app.views.Base.extend({
      template: funbar,

      id: "funbar",

      className: "clearfix",

      initialize: function() {
        this.listenTo(app, "app:story:vote", this.onUpdateVote);
      },

      render: function() {
        return this;
      },

      onUpdateVote: function(data) {
        // runs on app.trigger app:story:vote event
        if (!data || data.storyId !== this.model.get("id")) {
          return;
        }
        if (data.voted) {
          this.voteSelected();
        } else {
          this.voteDeselected();
        }
      },

      voteSelected: function() {
        var newIcon = wattpad.utils.iconify("fa-vote", 16, "wp-base-1"),
          newText = wattpad.utils.trans("Voted");
        this.$("#funbar-controls .on-vote .fa-vote").replaceWith(newIcon);
        this.$("#funbar-controls .on-vote .story-vote").text(newText);
      },

      voteDeselected: function() {
        var newIcon = wattpad.utils.iconify("fa-vote", 16, "wp-neutral-3"),
          newText = wattpad.utils.trans("Vote");
        this.$("#funbar-controls .on-vote .fa-vote").replaceWith(newIcon);
        this.$("#funbar-controls .on-vote .story-vote").text(newText);
      }
    })
  );

  app.mixin(app.views.BaseStoryReadingFunbar, "VoteManagement");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
