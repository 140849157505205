(function(window, _, wattpad, app, $) {
  "use strict";

  /*

  Model representing a page-specific story text for a story part (i.e. /:storyid/page/:pageNum/). Also accessible through StoryPartModel's loadedPages attribute.

  Contains story text as HTML payload, { paragraphId : paragraphText } object, and URL.

  */

  app.add(
    "StoryText",
    Monaco.Model.extend({
      // Text comes to us as an HTML payload
      parse: function(data) {
        var paragraphs = this.filterHTML(data).reduce(function(acc, current) {
          var paragraphId = current.getAttribute("data-p-id");
          /*
        * For inline comments, we expose the HTML elements if it is embedded media, and extract only text if otherwise.
        */
          if (current.getAttribute("data-media-type")) {
            acc[paragraphId] = current.outerHTML;
          } else {
            acc[paragraphId] = current.innerHTML.trim();
          }
          return acc;
        }, {});

        return {
          text: data,
          paragraphs: paragraphs
        };
      },

      fetch: function(options) {
        var self = this;

        // Initialize options as necessary
        options = options || {};
        options.dataType = "html"; // Payload comes back as HTML

        // Make the initial request for the story text
        return Promise.resolve(
          Monaco.Model.prototype.fetch.call(this, options)
        )["catch"](function(error) {
          //If there was an error, we should try refreshing the token
          if (!options.reject) {
            return self.refreshToken();
          } else {
            // If the reject option was passed, reject the promise (prevents infinite loop).
            return Promise.reject();
          }
        });
      },

      filterHTML: function(htmlString) {
        return _.filter($.parseHTML(htmlString), function(obj) {
          return obj.nodeType !== 3;
        });
      },

      getParagraphById: function(paragraphId) {
        return this.get("paragraphs")[paragraphId];
      },

      url: function() {
        return this.get("url");
      },

      refreshToken: function() {
        var self = this;
        // If there's no token URL, don't even try
        if (!this.get("token")) {
          return Promise.reject();
        } else {
          // Token URL found, try and get a new token
          return Promise.resolve($.get(this.get("token")))
            ["catch"](function() {
              // Something is going wrong
              return Promise.reject();
            })
            .then(function(data) {
              // Got a token response, let's update the URL and try 1 more time.
              self.set("url", self.url().replace(/\?.*/, "?" + data.token));
              return Promise.resolve(self.fetch({ reject: true }));
            });
        }
      }
    })
  );
})(window, _, wattpad, window.app, jQuery);
