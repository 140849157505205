(function(window, _, wattpad, app) {
  "use strict";

  /**
   * Base Collection for doing incremental API calls using nextUrl.
   * Not standalone, but extend call can be light ( min reqs: url, resource, cache info ). Should be used in conjunction
   * with a class that implements a defaultUrl function.
   * @Class IncrementalFetchNextUrl    Collection
   * @constructor
   */
  app.add(
    "IncrementalFetchNextUrl",
    app.collections.IncrementalFetch.extend({
      constructor: function(models, options) {
        options = options || {};

        this.turnOffPagination = options.turnOffPagination || false;
        this.nextUrl = null;

        app.collections.IncrementalFetch.prototype.constructor.apply(
          this,
          arguments
        );
      },

      // fetches the next set of items from the API  =>  returns `XHR / Promise`
      // updates the collection offset for subsequent requests
      fetchNextSet: function(options) {
        if (this.nextUrl === null) {
          options = options || {};

          //Extend the data object first
          options.data = _.extend(
            {
              offset: this.offset,
              limit: this.limit
            },
            options.data || {}
          );
        }

        //Extend the remaining options
        options = _.extend(
          {
            fresh: this.total !== null,
            remove: false
          },
          options
        );

        return this.fetch(options);
      },

      url: function() {
        return this.nextUrl
          ? this.updateWpToken(this.nextUrl)
          : this.defaultUrl();
      },

      hasMore: function() {
        return !this.turnOffPagination && this.nextUrl !== undefined;
      },

      //clear the cached total and offset
      onReset: function() {
        this.total = null;
        this.nextUrl = null;
        this.offset = 0;
      },

      revertParse: function() {
        var result = app.collections.IncrementalFetch.prototype.revertParse.apply(
          this
        );
        result.nextUrl = this.nextUrl;
        return result;
      },

      updateWpToken: function(url) {
        return url;
      }
    })
  );
})(window, _, wattpad, window.app);
