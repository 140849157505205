// This wrapper can render either the Mute or Unmute Modals
(function(window, _, wattpad, app, utils) {
  "use strict";
  app.add(
    "MuteModal",
    app.views.DummyReactView.extend({
      constructor: function(opts) {
        this.modalType = opts.modalType;
        this.showMutedProfile = opts.showMutedProfile;
        this.component = this.modalType == "mute" ? "MuteModal" : "UnmuteModal";
        this.componentId =
          this.modalType == "mute" ? "mute-modal" : "unmute-modal";

        this.componentData = {
          username: opts.username,
          toggleModal: this.onClose,
          muteUser: this.onMute.bind(this),
          onUnmute: this.onMute.bind(this)
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      // this method takes care of both muting and unmuting a user
      // the modalType determines which action to take
      onMute: function() {
        var username = this.componentData.username,
          urlAction =
            this.modalType == "mute" ? "ignore_user" : "unignore_user",
          url = `/apiv2/ignoreuser?id=${username}&action=${urlAction}`,
          action = this.modalType,
          self = this,
          message;

        window.store.dispatch(
          window.app.components.actions.setMutedUsersFromBackBone(
            username,
            action
          )
        );

        Promise.resolve(
          $.ajax({
            type: "POST",
            url: url
          })
        )
          .then(function() {
            if (self.showMutedProfile) {
              var userModel = new app.models.User({ username: username });
              Promise.resolve(userModel.loaded()).then(function() {
                var mutedView = new app.views.RestrictedProfile({
                  profileType: "mute",
                  model: userModel,
                  recentlyMuted: true,
                  isMobile: app.get("device").is.mobile
                });

                app.transitionTo(mutedView, {
                  username: username,
                  hasHeader: true,
                  hasFooter: true
                });

                window.te.push("event", "app", "page", null, "view", {
                  page: "profile",
                  username: username
                });
              });
            } else {
              if (action == "mute") {
                message = utils.trans("%s is now muted. You can unmute them at any time.", username); //prettier-ignore
              } else {
                message = utils.trans("%s is now unmuted. You can mute them at any time.", username); //prettier-ignore
              }
              utils.showToast(message, { type: "dismissable" });

              if (action == "mute") {
                app.trigger(`app:comment:mute`, { username: username });
              } else {
                self.trigger(`${action}:done`);
              }
            }

            wattpad.utils.clearCommentLocalStorage();
          })
          .catch(function(err) {
            if (action == "mute") {
              // Cannot mute staff, admins or verified users.
              if (err.responseJSON && err.responseJSON.code === 467) {
                message = err.responseJSON.message;
              } else {
                message = utils.trans("There was an error muting this user. Please try again."); //prettier-ignore
              }
            } else if (action == "unmute") {
              message = utils.trans("There was an error unmuting this user. Please try again."); //prettier-ignore
            }

            utils.showToast(message, {
              type: "dismissable"
            });
          });
      },

      onClose: function(evt) {
        $("#generic-modal").modal("hide");
        // remove custom class with a delay so that there's no pop-in during
        // jQuery modal close animation
        setTimeout(function() {
          $("#generic-modal .modal-content").removeClass("mute-modal-wrapper");
          $("#generic-modal .modal-dialog").removeClass("mute-modal-dialog");
        }, 500);
      }
    })
  );
})(window, _, wattpad, window.app, wattpad.utils);
