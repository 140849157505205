var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "              <p>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Your story can be found here",{"name":"trans","hash":{},"data":data}))
    + "</p>\n              <div class=\"taglist\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.tags : stack1),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "              </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                  <span class=\"tag\"><a href=\"/stories/"
    + alias2(alias1(depth0, depth0))
    + "\">#"
    + alias2(alias1(depth0, depth0))
    + "</a> </span>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "              <p>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Get your story discovered!",{"name":"trans","hash":{},"data":data}))
    + "</p><a href=\""
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.url : stack1), depth0))
    + "\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add tags to your story.",{"name":"trans","hash":{},"data":data}))
    + "</a>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <div class=\"info col-xs-12\">\n          <h4 class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share what's great about your story",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n          <div class=\"content\">\n            <div class=\"message-wrapper\">\n              <input class=\"broadcast-message\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Write a message...",{"name":"trans","hash":{},"data":data}))
    + "\">\n              <button class=\"btn btn-orange on-post-message\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Post",{"name":"trans","hash":{},"data":data}))
    + "</button>\n            </div>\n            <div class=\"message-info\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This will post to your profile and notify your followers",{"name":"trans","hash":{},"data":data}))
    + "</div>\n            <a class=\"on-navigate profile-link hidden\" href=\"/user/"
    + alias3(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "/conversations\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"View on your profile",{"name":"trans","hash":{},"data":data}))
    + "</a>\n          </div>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "            <a data-gtm-action=\"share:"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),18,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n";
},"9":function(container,depth0,helpers,partials,data) {
    return " social-share\" target=\"_blank";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"modal-dialog\" id=\"generic-modal\">\n  <div class=\"modal-content\">\n    <header class=\"modal-header\">\n      <h2 class=\"modal-title text-center\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Congratulations!",{"name":"trans","hash":{},"data":data}))
    + "</h1>\n      <div class=\"close\" data-dismiss=\"modal\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n      </div>\n    </header>\n    <div class=\"modal-body clearfix\">\n      <div class=\"col-xs-4\">\n        <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-story-details cover pull-left on-cover\">\n          <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.cover : stack1),150,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "\">\n        </a>\n      </div>\n      <div class=\"col-xs-8\">\n        <div class=\"info col-xs-12\">\n          <h4 class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"You just published %s",(depth0 != null ? depth0.title : depth0),{"name":"trans","hash":{},"data":data}))
    + "!</h4>\n          <div class=\"content\">\n"
    + ((stack1 = helpers["if"].call(alias1,((stack1 = (depth0 != null ? depth0.group : depth0)) != null ? stack1.tags : stack1),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data})) != null ? stack1 : "")
    + "          </div>\n        </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showBroadcastMessaging : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        <h4 class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Share and get discovered:",{"name":"trans","hash":{},"data":data}))
    + "</h4>\n        <div class=\"social-share-links\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.links : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        </div>\n      </div>\n    </div>\n  </div>\n</div>\n";
},"useData":true});