(function(window, _, wattpad, utils, app) {
  "use strict";
  var StoryInLibService, storyInLib;

  /**
   * Simply create an element in your template with the class `.btn-library` and 'data-story-id' as the storyId,
   * and LibraryManagement will handle:
   *
   * 1) State management ( Add to Library vs Added to Library )
   * 2) If user is not logged in, the button will launch a signup modal or redirect, depending on platform
   * 3) Library Collection management ( for current user ) (ie. Add/Remove to/from library )
   * 4) Fetch the library if not currently available
   * 5) optional events:
   *   a. On 'Added to Library': with the id of the story that was added to the library
   *   b. On 'Removed from Library': with the id of the story that was removed from the library
   *
   ** Rules:
   *
   * 1) If there is an SSR load, it's up to you to determine what the initial state should be
   *
   * @mixin LibraryManagement
   */
  app.add(
    "LibraryManagement",
    app.mixins.AuthPromptManagement.extend({
      events: {
        "tap .on-add-story": "onAddToLibrary",
        "click .on-add-story": "stopEvent",

        "tap .on-remove-story": "onRemoveFromLibrary",
        "click .on-remove-story": "stopEvent",

        "tap .on-library-login": "onAuthPrompt",
        "click .on-library-login": "stopEvent"
      },

      libraryButton: ".btn-library",

      initialize: function(options, next) {
        next(options);

        wattpad.utils
          .currentUser()
          .library()
          .on(
            "added",
            function(model) {
              this.onAddedToLibrary(model.get("id"));
            },
            this
          );

        wattpad.utils
          .currentUser()
          .library()
          .on(
            "removed",
            function(id) {
              this.onRemovedFromLibrary(id);
            },
            this
          );
      },

      setElement: function(el, next) {
        var btns = $(el).find(this.libraryButton);
        _.each(
          btns,
          function(btn) {
            var storyId = $(btn).data("story-id");
            var source = this.$(
              this.libraryButton + "[data-story-id='" + storyId + "']"
            );
            if (source) {
              $(btn).replaceWith(source[0]);
            }
          },
          this
        );

        next(el);
      },

      render: function(next) {
        var result = next();
        this.setupDOM();
        return result;
      },

      setupDOM: function(next) {
        var currentUser = wattpad.utils.currentUser(),
          $libraryButton = this.$(this.libraryButton),
          storyId = $libraryButton.data("storyId");

        $libraryButton.prop("disabled", true);

        if (currentUser.authenticated()) {
          if (storyId) {
            storyInLib.addToStoryQueue(storyId, this);
          }
        } else {
          $libraryButton.prop("disabled", false);
          this.setLibraryLoginState();
        }
        next();
      },

      setLibraryLoginState: function(next) {
        var $buttons = this.$(this.libraryButton);
        $buttons.addClass("on-library-login");

        next();
      },

      onAddToLibrary: function(evt, next) {
        var currentUser = utils.currentUser(),
          storyId = $(evt.currentTarget).data("story-id"),
          story = new app.models.StoryModel({ id: storyId });
        app.local.clear(currentUser.library().resource());
        currentUser.library().saveItem(story);
        storyInLib.inLib[storyId] = true;
        wattpad.utils.stopEvent(evt);
        next(evt);
      },

      onRemoveFromLibrary: function(evt, next) {
        var currentUser = utils.currentUser(),
          storyId = $(evt.currentTarget).data("story-id"),
          story = new app.models.StoryModel({ id: storyId });

        app.local.clear(currentUser.library().resource());
        currentUser.library().deleteItem(story);
        storyInLib.inLib[storyId] = false;
        wattpad.utils.stopEvent(evt);
        next(evt);
      },

      onAddedToLibrary: function(storyId, next) {
        var $buttons = $(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $buttons.removeClass("on-add-story").addClass("on-remove-story");
        next(storyId);
      },

      onRemovedFromLibrary: function(storyId, next) {
        var $buttons = $(
          this.libraryButton + "[data-story-id='" + storyId + "']"
        );
        $buttons.removeClass("on-remove-story").addClass("on-add-story");
        next(storyId);
      },

      stopEvent: function(e, noop) {
        wattpad.utils.stopEvent(e);
        noop();
      }
    })
  );

  StoryInLibService = function() {
    this.storyQueue = [];
    this.inLib = {};
  };

  StoryInLibService.prototype.addToStoryQueue = function(storyId, ctx) {
    var nextUp = { storyId: storyId, ctx: ctx };
    // add an item and its context to the queue
    if (this.inLib[storyId] !== undefined) {
      var method = this.inLib[storyId]
        ? "onAddedToLibrary"
        : "onRemovedFromLibrary";
      // defer because the element isnt on the page and classes werent being applied
      _.defer(function() {
        ctx[method](storyId);
        ctx.$(ctx.libraryButton).prop("disabled", false);
      });
    } else {
      this.storyQueue = _.compact(this.storyQueue);
      if (!_.find(this.storyQueue, nextUp)) {
        this.storyQueue.push(nextUp);
        // begin polling the page for other stories
        this.batchFetch();
      }
    }
  };

  StoryInLibService.prototype.batchFetch = _.debounce(
    function() {
      this.storyQueue = _.compact(this.storyQueue);
      if (this.storyQueue.length > 0) {
        var idList = _.pluck(_.unique(this.storyQueue, "storyId"), "storyId");
        this.fetchStoryCollections(idList.join("%2C"));
      }
    },
    100,
    { maxWait: 200 }
  );

  StoryInLibService.prototype.fetchStoryCollections = function(idList) {
    var storyCollectionList = new app.models.StoryInLists({ storyId: idList }),
      self = this;
    Promise.resolve(storyCollectionList.fetch())
      .then(function(data) {
        var collections = data.collections;
        _.each(self.storyQueue, function(story, index) {
          if (story && collections[story.storyId]) {
            var lib = collections[story.storyId].library,
              method = lib ? "onAddedToLibrary" : "onRemovedFromLibrary";
            self.inLib[story.storyId] = lib;
            story.ctx[method](story.storyId);
            story.ctx.$(story.ctx.libraryButton).prop("disabled", false);

            delete self.storyQueue[index];
          }
        });
      })
      ["catch"](function(data) {
        _.each(self.storyQueue, function(story, index) {
          story.ctx.handlePromiseExpected(data);
        });
      });
  };

  storyInLib = new StoryInLibService();
  window.wattpad.StoryInLibService = storyInLib;
})(window, _, wattpad, wattpad.utils, window.app);
