var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"notification follow-request panel in-notifications\">\n    <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"avatar avatar-sm2 on-navigate\">\n        <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <div class=\"content\">\n        <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"heading on-navigate\"><strong>"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</strong></a>\n    </div>\n\n    <div class=\"request-btns\">\n        <button class=\"btn btn-circle btn-teal on-approve\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n        <button class=\"btn btn-circle btn-grey on-ignore\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n    </div>\n</article>\n";
},"useData":true});