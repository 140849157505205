var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                    <option value=\"\"/>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                    <option value=\"X\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Not listed",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                    <option value=\" \">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"I'd rather not say",{"name":"trans","hash":{},"data":data}))
    + "</option>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "<div id=\"edit-profile\" class=\"hidden edit-profile container\">\n    <br><br>\n    <form class=\"form-horizontal\" role=\"form\">\n\n        <p class=\"info-message\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"The information you enter here, including username, profile photo, and background image, will be visible to other users. Learn about safe information sharing <a rel=\"noopener noreferrer\" target=\"_blank\" href=\"https://safetyportal.wattpad.com/youth-portal/sharing-information\">here.</a>",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</p>\n\n        <div class=\"row form-group gender\">\n            <label for=\"update-gender\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Pronouns",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <select name=\"gender\" class=\"form-control\" id=\"update-gender\" data-field=\"gender\" value=\""
    + alias3(((helper = (helper = helpers.gender || (depth0 != null ? depth0.gender : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"gender","hash":{},"data":data}) : helper)))
    + "\">\n                    <option value=\"M\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"He/Him",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                    <option value=\"F\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"She/Her",{"name":"trans","hash":{},"data":data}))
    + "</option>\n                    <option value=\"O\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"They/Them",{"name":"trans","hash":{},"data":data}))
    + "</option>\n"
    + ((stack1 = helpers.unless.call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"NEW_ONBOARDING_1",{"name":"isTestGroup","hash":{},"data":data}),{"name":"unless","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers["if"].call(alias1,(helpers.isTestGroup || (depth0 && depth0.isTestGroup) || alias2).call(alias1,"NEW_ONBOARDING_1",{"name":"isTestGroup","hash":{},"data":data}),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "                </select>\n            </div>\n        </div>\n\n        <!-- Temp remove until v3 API support is updated -->\n        <div class=\"row form-group description\">\n            <label for=\"update-description\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"About",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <textarea id=\"update-description\" class=\"form-control count-input\" data-field=\"description\" data-name=\"Description\" data-limit=\"2000\" data-counter=\"description-counter\" rows=\"10\">"
    + alias3(((helper = (helper = helpers.description || (depth0 != null ? depth0.description : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"description","hash":{},"data":data}) : helper)))
    + "</textarea>\n                <div class=\"description-counter pull-right\"><span>"
    + alias3(((helper = (helper = helpers.aboutCount || (depth0 != null ? depth0.aboutCount : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"aboutCount","hash":{},"data":data}) : helper)))
    + "</span>/2000</div>\n            </div>\n        </div>\n\n        <div class=\"row form-group website\">\n            <label for=\"update-website\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"My Website",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <input id=\"update-website\" type=\"text\" class=\"form-control\" data-field=\"website\" value=\""
    + alias3(((helper = (helper = helpers.website || (depth0 != null ? depth0.website : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"website","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Enter a link to your personal website",{"name":"trans","hash":{},"data":data}))
    + "\">\n            </div>\n        </div>\n\n        <div class=\"row form-group location\">\n            <label for=\"update-location\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Location",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <input id=\"update-location\" type=\"text\" class=\"form-control\" data-field=\"location\" value=\""
    + alias3(((helper = (helper = helpers.location || (depth0 != null ? depth0.location : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"location","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Enter your location",{"name":"trans","hash":{},"data":data}))
    + "\">\n            </div>\n        </div>\n\n        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"AccountSocialMedia","facebook-info",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n\n        <!--<div class=\"row form-group facebook\">\n            <label for=\"update-facebook\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Link with Facebook",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <input id=\"update-facebook\" type=\"text\" class=\"form-control\" data-field=\"facebook\" value=\""
    + alias3(((helper = (helper = helpers.facebook || (depth0 != null ? depth0.facebook : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"facebook","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Connect with Facebook",{"name":"trans","hash":{},"data":data}))
    + "\">\n            </div>\n        </div>\n\n        <div class=\"row form-group twitter\">\n            <label for=\"update-twitter\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Link with Twitter",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <input id=\"update-twitter\" type=\"text\" class=\"form-control\" data-field=\"twitter\" value=\""
    + alias3(((helper = (helper = helpers.twitter || (depth0 != null ? depth0.twitter : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"twitter","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Connect with Twitter",{"name":"trans","hash":{},"data":data}))
    + "\">\n            </div>\n        </div>\n\n        <div class=\"row form-group smashwords\">\n            <label for=\"update-smashwords\" class=\"control-label col-sm-3\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Smashwords",{"name":"trans","hash":{},"data":data}))
    + "</label>\n            <div class=\"col-sm-6\">\n                <input id=\"update-smashwords\" type=\"text\" class=\"form-control\" data-field=\"smashwords\" value=\""
    + alias3(((helper = (helper = helpers.smashwords || (depth0 != null ? depth0.smashwords : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"smashwords","hash":{},"data":data}) : helper)))
    + "\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add your Smashwords Profile",{"name":"trans","hash":{},"data":data}))
    + "\">\n            </div>\n        </div>\n        -->\n\n    </form>\n\n    <br>\n    <br>\n    <br>\n</div>\n";
},"useData":true});