(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "ImageAppealFlow",
    app.views.DummyReactView.extend({
      component: "ImageAppealFlow",

      componentId: "image-appeal-flow",

      constructor: function(opts) {
        this.componentData = {
          imageUrl: opts.imageUrl,
          onSubmissionError: this.onSubmissionError.bind(this),
          isIOS: app.get("device").is.ios,
          isAndroid: app.get("device").is.android
        };

        var options = {
          componentData: this.componentData,
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },

      onSubmissionError: function(err) {
        console.error(err);
        var toast = new app.views.ErrorToast({
          message: err
        });
        toast.render();
      }
    })
  );

  app.mixin(
    app.views.ImageAppealFlow,
    "AuthPromptManagement",
    "FacebookConnect",
    "TwitterConnect"
  );
})(window, _, wattpad, window.app);
