var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<div class=\"default\">\n  <div class=\"message\">\n	"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"<strong>Success!</strong> Your email is verified and sign-up is complete. Enjoy!",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n  <div class=\"controls on-close\">\n    <span>"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",12,"wp-neutral-5","on-close",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n  </div>\n</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0["default"] : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "");
},"useData":true});