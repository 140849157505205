(function(window, _, wattpad, app, utils) {
  "use strict";

  app.add(
    "ReadingList",
    app.models.BaseModel.extend({
      defaults: {
        id: null,
        name: null,
        numStories: 0,
        cover: 0,
        tags: [],
        featured: false
      },

      fields: [
        "id",
        "name",
        {
          user: ["name", "avatar", "following"]
        },
        "numStories",
        "sampleCovers",
        "cover",
        "tags",
        "featured"
      ],

      url: function() {
        return "/api/v3/lists/" + (this.get("id") ? this.get("id") : "");
      },

      validationRules: {
        name: [
          {
            func: "minLength",
            length: 1,
            msg: utils.trans("Your reading list must have a title.")
          }
        ]
      },

      resource: function() {
        return "reading-list." + this.get("id");
      },

      stories: function(limit) {
        if (!this._stories) {
          var options = { listId: this.get("id") };

          if (_.isNumber(limit)) {
            _.extend(options, { limit: limit });
          }
          this._stories = new app.collections.ReadingListStories(
            this.get("stories"),
            options
          );

          this._stories.on(
            {
              removedStory: function(model) {
                this.trigger("list:removed", this.get("id"), model.get("id"));
              },
              addedStory: function(model) {
                this.trigger("list:added", this.get("id"), model.get("id"));
              }
            },
            this
          );
        }
        return this._stories;
      }
    })
  );

  app.mixin(app.models.ReadingList, "ValidationModel");
})(window, _, wattpad, window.app, wattpad.utils);
