import libraryLanding from "core/templates/library/library-landing.hbs";
(function(window, wattpad, utils, app) {
  "use strict";
  app.add(
    "LibraryLanding",
    app.views.RegionManager.extend({
      template: libraryLanding,

      events: {
        "tap .tab-control": "onSwitchTabs",
        "click .tab-control": "stopEvent"
      },

      initialize: function(options) {
        options = options || {};

        Handlebars.registerPartial("core.library.library_controls", function() {
          return "";
        });
        Handlebars.registerPartial(
          "core.library.library_controls.library_lists_controls",
          function() {
            return "";
          }
        );
        Handlebars.registerPartial("core.library.library_listing", function() {
          return "";
        });
        Handlebars.registerPartial(
          "core.library.library_lists_landing",
          function() {
            return "";
          }
        );

        this.active = options.section || "current-reads"; // default

        if (
          !_.contains(
            ["current-reads", "archive", "reading-lists"],
            this.active
          )
        ) {
          throw new Error("Invalid Section Supplied to LibraryLanding View");
        }

        this.setCollection(this.active);
      },

      setCollection: function(section) {
        switch (section) {
          case "current-reads":
            this.collection = new app.collections.NodeLibrary();
            break;
          case "archive":
            this.collection = new app.collections.Archive();
            break;
          case "reading-lists":
            var username = wattpad.utils.currentUser().get("username");
            this.collection = new app.collections.ReadingLists([], {
              username: username
            });
            break;
        }
      },

      getListView: function() {
        var viewName = "LibraryListing";

        if (this.collection instanceof app.collections.ReadingLists) {
          viewName = "LibraryListsLanding";
        }

        return new app.views[viewName]({
          collection: this.collection
        });
      },

      getControlsView: function() {
        var viewName = "LibraryControls";

        if (this.collection instanceof app.collections.ReadingLists) {
          viewName = "LibraryListsControls";
        }

        return new app.views[viewName]({
          collection: this.collection
        });
      },

      render: function() {
        this.libraryControlsView = this.getControlsView();

        this.$el.html(
          this.template({
            islibrary: this.active === "current-reads",
            isarchive: this.active === "archive",
            islist: this.active === "reading-lists"
          })
        );

        this.setRegion("#library-controls", this.libraryControlsView.render());
        this.setRegion("#tab-content", this.getListView());

        return this;
      },

      setElement: function(el) {
        Monaco.View.prototype.setElement.apply(this, arguments);

        this.checkRegion("#tab-content");
        this.checkRegion("#library-controls");
      },

      checkRegion: function(idName) {
        if (this.regions[idName] && this.regions[idName].view) {
          this.regions[idName].view.setElement(this.$(idName + " > div"));

          //Need to set the button state of the listing view for SSR
          if (idName === "#tab-content") {
            this.regions["#tab-content"].view.setButtonState();
          }
        }
      },

      onSwitchTabs: function(evt) {
        var $tab = $(evt.currentTarget);
        var section = $tab.data("section");
        var slug = $tab.attr("href");
        var remixCookie = wattpad.utils.getCookie("remix-library");
        var remixArchiveCookie = wattpad.utils.getCookie("remix-archive");

        /* 
          remix-library cookie is added as a temporary fix from the remix application to ensure remix pages are viewed on CSR from frontend 
          PR for introduce remix-library cookie: https://github.com/Wattpad/wp-web/pull/493
        */
        if (remixCookie && slug === "/library") {
          return wattpad.utils.redirectToServer(slug);
        }
        /* 
          remix-archive cookie is added as a temporary fix from the remix application to ensure remix pages are viewed on CSR from frontend 
          PR for introduce remix-archive cookie: https://github.com/Wattpad/wp-web/pull/870
        */
        if (slug === "/archive") {
          return wattpad.utils.redirectToServer(slug);
        }
        //Update Region
        this.setCollection(section);
        this.setRegion("#tab-content", this.getListView());
        this.setRegion("#library-controls", this.getControlsView().render());

        //Update Active Tabs
        var tabs = this.$(".tab-control");
        tabs.parent().removeClass("active");
        tabs
          .filter('[data-section="' + section + '"]')
          .parent()
          .addClass("active");

        if (typeof __atha !== "undefined") {
          var id = __atha.sendPageView("library_" + section.replace("-", "_"));
        }

        //Update Url
        app.router.navigate(slug, { trigger: false });
      }
    })
  );
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
