var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "                <div class=\"list-name-controls on-edit-show\">\n                    <div class=\"input-group\">\n                        <input id=\"list-name-input\" type=\"text\" class=\"form-control\"\n                        placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Enter a name for this list",{"name":"trans","hash":{},"data":data}))
    + "\" value=\""
    + alias3((helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}))
    + "\">\n                    </div>\n                </div>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "                        <span class=\"tag\"><a href=\"/stories/"
    + alias2(alias1(depth0, depth0))
    + "\">#"
    + alias2(alias1(depth0, depth0))
    + "</a></span>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <div class=\"edit-toolbar\">\n        <button class=\"btn btn-md btn-orange on-edit-list on-edit-hide\"><span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Edit",{"name":"trans","hash":{},"data":data}))
    + "</span></button>\n        <button class=\"btn btn-md btn-orange on-done-edit on-edit-show\"><span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Done",{"name":"trans","hash":{},"data":data}))
    + "</span></button>\n        <button class=\"btn btn-md btn-red on-delete-list on-edit-show\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete List",{"name":"trans","hash":{},"data":data}))
    + "</button>\n    </div>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), alias4=helpers.helperMissing, buffer = 
  "        <div class=\"follow\">\n            <a href=\"/user/"
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" class=\"avatar avatar-md on-navigate\">\n                <img src=\""
    + alias2((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias4).call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.avatar : stack1),40,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\" alt=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\">\n            </a>\n            <ul>\n                <li>"
    + alias2((helpers.trans || (depth0 && depth0.trans) || alias4).call(alias3,"Like these stories?",{"name":"trans","hash":{},"data":data}))
    + "</li>\n\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.following : stack1),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "            </ul>\n\n            <button class=\"btn btn-fan "
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.following : stack1),{"name":"if","hash":{},"fn":container.program(12, data, 0),"inverse":container.program(14, data, 0),"data":data})) != null ? stack1 : "")
    + " ";
  stack1 = ((helper = (helper = helpers.disabled || (depth0 != null ? depth0.disabled : depth0)) != null ? helper : alias4),(options={"name":"disabled","hash":{},"fn":container.program(16, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(alias3,options) : helper));
  if (!helpers.disabled) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "\" data-target=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1), depth0))
    + "\" data-following=\""
    + alias2(alias1(((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.following : stack1), depth0))
    + "\" role=\"button\" aria-pressed=\"false\">\n"
    + ((stack1 = helpers["if"].call(alias3,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.following : stack1),{"name":"if","hash":{},"fn":container.program(18, data, 0),"inverse":container.program(20, data, 0),"data":data})) != null ? stack1 : "")
    + "            </button>\n        </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <li class=\"created-by-user\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Selected by %s",((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</li>\n";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                <li class=\"created-by-user\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Follow %s",((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "</li>\n";
},"12":function(container,depth0,helpers,partials,data) {
    return "btn-teal";
},"14":function(container,depth0,helpers,partials,data) {
    return "btn-white";
},"16":function(container,depth0,helpers,partials,data) {
    return "disabled";
},"18":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-following",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"20":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "                    "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-follow",16,"wp-base-2-dark",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"reading-list-sidebar col-xs-12\">\n    <div class=\"clearfix\">\n        <div class=\"cover cover-reading-list col-xs-5 col-sm-12 pull-right\">\n            <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.cover : stack1),368,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}))
    + "\">\n        </div>\n        <div class=\"reading-list-info col-xs-7 col-sm-12\">\n            <h1 class=\"h2 list-name on-edit-hide\"><span "
    + alias3((helpers.sanitize || (depth0 && depth0.sanitize) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.name : stack1),{"name":"sanitize","hash":{},"data":data}))
    + "</span></h1>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOwner : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = container.invokePartial(partials["core.library.reading_list_details.library_reading_list_meta"],depth0,{"name":"core.library.reading_list_details.library_reading_list_meta","data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            <div class=\"taglist on-edit-hide\">\n"
    + ((stack1 = helpers.each.call(alias1,((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.tags : stack1),{"name":"each","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "            </div>\n        </div>\n    </div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOwner : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n    <div class=\"col-xs-12 on-edit-hide\">\n"
    + ((stack1 = (helpers.isCurrentUser || (depth0 && depth0.isCurrentUser) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.list : depth0)) != null ? stack1.user : stack1)) != null ? stack1.name : stack1),{"name":"isCurrentUser","hash":{},"fn":container.noop,"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "\n        "
    + ((stack1 = (helpers.reactComponent || (depth0 && depth0.reactComponent) || alias2).call(alias1,"SocialSharing","sidebar",{"name":"reactComponent","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});