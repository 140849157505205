(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "DiscoverModuleListItem",
    app.models.Base.extend({
      defaults: {
        id: null,
        name: null,
        numStories: null,
        cover: null
      }
    })
  );
})(window, _, wattpad, window.app);
