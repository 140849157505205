var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression;

  return "            <span class=\"tag on-navigate\"><a href=\"/stories/"
    + alias2(alias1(depth0, depth0))
    + "\">#"
    + alias2(alias1(depth0, depth0))
    + "</a></span>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <li><a role=\"button\" data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-placement=\"top\" title=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"This is your default list; it cannot be deleted",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"disabled\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-info",20,"wp-neutral-2 vcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Primary List",{"name":"trans","hash":{},"data":data}))
    + "\n        </a></li>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "        <li><a role=\"button\" href=\"#\" data-toggle=\"modal\" data-target=\"#delete-modal\"\n        data-stop-details=\"true\" data-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === "function" ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"on-delete-list\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-trash",20,"wp-neutral-2 vcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Delete List",{"name":"trans","hash":{},"data":data}))
    + "\n        </a></li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "        <li><a role=\"button\" class=\"share-"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.nopopup : depth0),{"name":"unless","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\" "
    + alias4(((helper = (helper = helpers.socialSharingAttributes || (depth0 != null ? depth0.socialSharingAttributes : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"socialSharingAttributes","hash":{},"data":data}) : helper)))
    + ">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,(depth0 != null ? depth0.icon : depth0),20,"wp-neutral-2 vcenter",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4(((helper = (helper = helpers.label || (depth0 != null ? depth0.label : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"label","hash":{},"data":data}) : helper)))
    + "\n        </a></li>\n";
},"8":function(container,depth0,helpers,partials,data) {
    return " social-share\" rel=\"nofollow\" target=\"_blank";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<div class=\"reading-list-item row panel\" role=\"listitem\" data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"drag-handle vcenter\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-menu",28,"wp-neutral-2","fa-xl",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n\n  <div class=\"story-wrapper vcenter\">\n    <div class=\"cover cover-sm vcenter\" aria-hidden=\"true\">\n      <a class=\"on-navigate\" href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <img src=\""
    + alias4((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),70,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "\" />\n      </a>\n    </div>\n    <div class=\"story-info vcenter\">\n      <h3 class=\"story-title\" aria-label=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story Item",{"name":"trans","hash":{},"data":data}))
    + ": "
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + ".\">\n      <a class=\"on-show-details on-navigate\" href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <strong>"
    + alias4(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</strong>\n      </a>\n      </h3>\n      <a class=\"on-show-details on-navigate\" href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        <small>"
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Story","%s Stories",(depth0 != null ? depth0.numStories : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numStories : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</small>\n      </a>\n      <div class=\"taglist\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tags : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </div>\n    </div>\n  </div>\n\n\n  <div class=\"dropdown\">\n    <button class=\"btn btn-white dropdown-toggle\" data-toggle=\"dropdown\" role=\"button\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-more",18,"wp-neutral-2","fa-l",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n    </button>\n    <div class=\"triangle\"></div>\n    <ul class=\"dropdown-menu align-right\" role=\"menu\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.featured : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.socialLinks : depth0),{"name":"each","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "     </ul>\n  </div>\n</div>\n";
},"useData":true});