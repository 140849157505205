app.router.add({
  inbox: ["inbox"]
});

app.router.add({
  "inbox/:activeThread": [
    "inbox",
    {
      regexp: {
        activeThread: /[\w\-]+/
      }
    }
  ]
});
