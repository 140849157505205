var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <small>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"You ignored a follow request from %s. You can always approve the request here at any time.",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</small>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "            <button class=\"btn btn-grey on-ignore on-request-addressed\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"fa-remove",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<article class=\"notification follow-request in-profile\">\n    <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"avatar avatar-sm2 on-navigate\">\n        <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <div class=\"content\">\n        <a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"heading on-navigate\">\n            "
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"<strong>%s</strong> wants to follow you",(depth0 != null ? depth0.username : depth0),{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </a>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.followerIgnored : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "    </div>\n\n    <footer>\n        <div class=\"request-btns pull-right\">\n            <button class=\"btn btn-teal on-approve on-request-addressed\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-check",16,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</button>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.followerIgnored : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "        </div>\n    </footer>\n</article>\n";
},"useData":true});