import storyGrid from "platform/components/templates/story-grid.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "StoryGrid",
    app.views.IncrementalList.extend({
      className: "story-grid",
      template: storyGrid,
      itemView: app.views.StoryGridItem,

      events: _.extend(
        {
          "mouseover  .on-story-details": "onStoryDetails",
          "mouseleave .popover": "offStoryDetails",
          "mouseleave .panel-body": "offStoryDetails"
        },
        app.views.IncrementalList.prototype.events
      ),

      getTemplateData: function() {
        var data = {
          stories: this.collection.toJSON()
        };
        return data;
      },

      // Story Details
      onStoryDetails: function(event) {
        var $popover = $(event.currentTarget).siblings(".popover");

        this.offStoryDetails();

        $popover.css("display", "block");
        window.setTimeout(function() {
          $popover.removeClass("faded");
        }, 50);
      },

      // Hides any currently visible story details popovers
      offStoryDetails: function() {
        this.$(".popover")
          .removeAttr("style")
          .addClass("faded");
      },

      render: function() {
        var self = this;
        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          app.views.IncrementalList.prototype.render.apply(self, arguments);
        });
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
