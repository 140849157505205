"use strict";

const UNSAFE_KEYWORDS = [
  "Auto-lesión",
  "bestiality",
  "bunuh diri",
  "child exploitation",
  "child porn",
  "child pornography",
  "columbine",
  "cuts",
  "cutter",
  "cutting",
  "incest",
  "intihar",
  "kid porn",
  "kiddie porn",
  "kill herself",
  "kill himself",
  "kill myself",
  "kill themself",
  "kill themselves",
  "kill yourself",
  "kys",
  "mutilation",
  "pedophile",
  "pedophilia",
  "porn",
  "pornography",
  "pro ana",
  "pro mia",
  "rape",
  "Samookaleczanie",
  "school shooting",
  "selbstmord",
  "Selbstmord",
  "self harm",
  "self harmer",
  "self harming",
  "self-harmer ",
  "self-harming",
  "selfharm",
  "selfharmer",
  "sex chat",
  "sex traffic",
  "sex trafficing",
  "sex traffick",
  "sex trafficking",
  "suicidal",
  "suicidar",
  "suicidarse",
  "suicidas",
  "suicide attempt",
  "suicide boy",
  "suicide girl",
  "suicide mate",
  "suicide method",
  "suicide note",
  "suicide pact",
  "suicide-attempt",
  "suicide-boy",
  "suicide-girl ",
  "suicide-mate",
  "suicide-method",
  "suicide-note",
  "suicide-pact",
  "suicide",
  "suicideattempt",
  "suicideboy",
  "suicidegirl",
  "suicidemate",
  "suicidemethod ",
  "suicidenote",
  "suicidepact",
  "suiciding",
  "suicidio",
  "suicídio",
  "Suizidgefährdet",
  "underage",
  "Zelfmoord",
  "الانتحار"
];

const projectBWords = [
  "BTS",
  "Bangtan",
  "Sonyeondan",
  "Jungkook",
  "Jimin",
  "Taehyung",
  "Namjoon",
  "Suga",
  "Yoongi",
  "Seokjin",
  "hoseok",
  "Hobi",
  "jhope",
  "TXT",
  "TomorrowTogether",
  "Tomorrow x Together",
  "TomorrowxTogether",
  "Tomorrow Together",
  "Soobin",
  "Yeonjun",
  "Huening",
  "Taehyun",
  "Beomgyu",
  "TomorrowTogether",
  "Tomorrow x Together",
  "TomorrowxTogether",
  "Tomorrow Together",
  "Soobin",
  "Yeonjun",
  "Huening",
  "Taehyun",
  "Beomgyu",
  "Enhypen",
  "Heeseung",
  "Jay",
  "Sunghoon",
  "Sunoo",
  "Jungwon",
  "Ni-ki",
  "Niki"
];

/**
 * Returns whether or not the given text is an unsafe keyword or contains an unsafe keyword for crawler indexing
 * @param {String} text - text to check
 *
 * @returns {Boolean}
 */

const isUnsafe = function(text) {
  const unsafeKeywordsSet = new Set(
    UNSAFE_KEYWORDS.map(str => str.toLowerCase())
  );
  const unsafe = str => {
    return unsafeKeywordsSet.has(str);
  };
  const lowerCaseText = text ? text.toLowerCase() : "";
  const splitText = lowerCaseText ? lowerCaseText.split(" ") : "";

  // check if the text itself is unsafe or if it contains an unsafe word
  if (lowerCaseText && (unsafe(lowerCaseText) || splitText.some(unsafe))) {
    return true;
  }
  return false;
};

const isProjB = tag => {
  if (
    projectBWords.some(word => tag?.toLowerCase().includes(word?.toLowerCase()))
  ) {
    return true;
  }
  return false;
};

module.exports = {
  isUnsafe,
  isProjB
};
