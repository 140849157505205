(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "AutocompleteDedication",
    Monaco.Collection.extend({
      initialize: function(options) {
        options = options || {};
        this.userType = options.userType || "users";
      },

      resource: function() {
        return (
          "autocomplete:" +
          this.userType +
          (this.searchTerm ? ":" + this.searchTerm : "")
        );
      },

      url: function() {
        return (
          "/apiv2/dedicate?type=" + this.userType + "&term=" + this.searchTerm
        );
      },

      parse: function(resp) {
        return _.map(resp, function(item) {
          return { label: item };
        });
      },

      cacheLocal: false
    })
  );
})(window, _, wattpad, window.app);
