var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div class=\"wp-panel\">\n    <div class=\"wp-hero vcenter form-inline\">\n        <div>\n            <h3><strong> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Who's missing out?",{"name":"trans","hash":{},"data":data}))
    + "</strong></h3>\n            <h6><strong> "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Invite them to Wattpad",{"name":"trans","hash":{},"data":data}))
    + "</strong></h6>\n            <div class=\"invite-email\">\n                "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/invite-friends/ic_invite_wattpad@2x.png",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Invite your friends to Wattpad",{"name":"trans","hash":{},"data":data}),{"name":"connectImage","hash":{"size":"124x127"},"data":data}))
    + "\n            </div>\n            <div class=\"provider-email\">\n                <input type=\"text\" placeholder=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Email address",{"name":"trans","hash":{},"data":data}))
    + "\" class=\"form-control\">\n                <button class=\"btn btn-orange btn-md\">\n                    <span>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Invite",{"name":"trans","hash":{},"data":data}))
    + "</span>\n                    <span class=\"spinner\">"
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || alias2).call(alias1,"fa-loading",16,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{},"data":data})) != null ? stack1 : "")
    + "</span>\n                </button>\n            </div>\n            <div class=\"email-message\"></div>\n        </div>\n    </div>\n    <ul class=\"invite-providers\">\n        \n        <li class=\"provider-twitter\">\n            "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/invite-friends/ic_invite_twitter@2x.png",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Twitter Logo",{"name":"trans","hash":{},"data":data}),{"name":"connectImage","hash":{"height":"42","width":"42"},"data":data}))
    + "\n            <strong>Twitter</strong>\n            <span class=\"fa fa-right\"></span>\n        </li>\n        <li class=\"provider-mailto\">\n            "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/invite-friends/ic_invite_email@2x.png",(helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Email Logo",{"name":"trans","hash":{},"data":data}),{"name":"connectImage","hash":{"height":"42","width":"42"},"data":data}))
    + "\n            <strong>"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Email",{"name":"trans","hash":{},"data":data}))
    + "</strong>\n            <span class=\"fa fa-right\"></span>\n        </li>\n    </ul>\n    <div class=\"consent\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"We won't contact anyone without your consent.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n</div>\n";
},"useData":true});