(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "UserSettingsModal",
    app.views.DummyReactView.extend({
      component: "UserSettingsModal",
      componentId: "user-settings-modal",

      constructor: function(options) {
        this.type = options.type;

        options = {
          componentData: {
            type: this.type,
            userId: options.userId,
            username: options.username,
            name: options.name,
            onPasswordToggle: this.onPasswordToggle.bind(this),
            hideModal: this.hideModal.bind(this),
            app: {
              language: app.get("language"),
              translatedLanguage: app.get("translatedLanguage")
            }
          },
          component: this.component,
          componentId: this.componentId
        };

        $("body").removeClass("js-app-off");
        app.views.DummyReactView.call(this, options);
      },

      showModal: function() {
        var $modal = $("#generic-modal");
        $modal.find(".modal-body").html(this.render().$el);
        $modal.modal({ backdrop: "static", keyboard: false });
      },

      hideModal: function() {
        var $modal = $("#generic-modal");
        $modal.modal("hide");
      },

      onPasswordToggle: function(evt) {
        var $switch = $(evt.currentTarget).find(".fa-view"),
          $pwInput = $(evt.currentTarget).siblings("input"),
          type = $pwInput.attr("type");

        if (type === "password") {
          $pwInput.attr("type", "text").focus();
          $switch.addClass("active");
        }

        if (type === "text") {
          $pwInput.attr("type", "password").focus();
          $switch.removeClass("active");
        }
      }
    })
  );
})(window, _, wattpad, window.app);
