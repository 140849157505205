import recommendedStories from "core/templates/collection-views/recommended-stories.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "RecommendedStories",
    app.views.CollectionView.extend({
      className: "recommended-stories-view",

      containerClass: ".collection",

      itemView: app.views.RecommendedStoryItem,

      template: recommendedStories,

      initialize: function(options) {
        options = options || {};
        if (typeof options.username === "undefined") {
          throw new Error(
            "RecommendedStories: options.username is missing (required)."
          );
        }
        if (options.is404) {
          this.itemView = app.views.RecommendedStoryItemCover;
        }

        this.collection = new app.collections.RecommendedStories([], {
          username: options.username,
          limit: options.limit || 10
        });
        this.setCollectionfilter(this.isInRange);
        this.listenTo(this.collection, "update", this.renderExtraItem);
      },

      render: function() {
        var self = this;
        this.$el.html(this.template(wattpad.testGroups || {}));

        Promise.resolve(this.collection.fetchNextSet()).then(function() {
          self.bindEvents();
          self.renderCollection();
        });
        return this;
      },

      renderCollection: function() {
        if (this.collection.models.length > 0) {
          app.views.CollectionView.prototype.renderCollection.apply(
            this,
            arguments
          );
        } else {
          this.$("span").removeClass("hidden");
        }
      },

      isInRange: function() {
        var modelIndex = this.collection.models.indexOf(this);
        return 0 <= modelIndex && modelIndex <= 3;
      },

      renderExtraItem: function() {
        var container = this.getContainer();
        var model = this.collection.models[3],
          data = { model: model, cvIndex: 4 };

        if (!model) {
          return;
        }

        this.viewPointers[model.cid] = new this.itemView(data);
        this.viewPointers[model.cid].render();
        container[this.direction](this.viewPointers[model.cid].$el);
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
