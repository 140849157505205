import storyAdminDropdown from "platform/story-reading/templates/story-admin-dropdown.hbs";
(function(window, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "StoryAdmin",
    Monaco.View.extend({
      className: "dropdown-menu story-admin-dropdown",
      template: storyAdminDropdown,

      events: {
        // mod stuff
        "tap    .on-options-hot-list-review": "onHotListReview",
        "click  .on-options-hot-list-review": "stopEvent",

        // admin stuff
        "tap    .on-options-edit-details": "onOptionsEditDetails",
        "click  .on-options-edit-details": "stopEvent",
        "tap    .on-options-edit-part": "onOptionsEditPart",
        "click  .on-options-edit-part": "stopEvent",
        "tap    .on-cover-remove": "onCoverRemove",
        "click  .on-cover-remove": "stopEvent",

        "change #story-language-select": "onLanguageChanged",
        "change #story-category-select": "onCategoryChanged"
      },

      initialize: function(options) {
        options = options || {};
        this.reported = false;

        // Handle Author Data (required)
        if (!options.author || !(options.author instanceof app.models.User)) {
          throw new Error(
            "StoryAdmin View : `options.author` must be defined (`UserModel` reference)"
          );
        }
        this.author = options.author;

        // Handle Story Data (required)
        if (
          !options.story ||
          !(options.story instanceof app.models.StoryModel)
        ) {
          throw new Error(
            "StoryAdmin View : `options.story` must be defined (`StoryModel` reference)"
          );
        }
        this.story = options.story;

        // Handle Part Data (optional)
        if (
          options.part &&
          !(options.part instanceof app.models.StoryPartModel)
        ) {
          throw new Error(
            "StoryAdmin View : `options.part` should be a `StoryPartModel` reference"
          );
        }
        this.part = options.part || null;

        this.langs = this.getLangs();
      },

      render: function() {
        var data = {
          isAdmin: wattpad.utils.currentUser().get("isSysAdmin"), // false === moderator
          isStaff: wattpad.utils.currentUser().get("is_staff"),
          author: this.author.toJSON(),
          story: this.story.toJSON(),
          part: this.part ? this.part.toJSON() : null,
          languages: this.langs,

          reportLink:
            "/help/content?message=" +
            wattpad.utils.urlEncode("I am reporting the following content:\n") +
            "&story_link=" +
            wattpad.utils.urlEncode(window.location.href),
          translateLink:
            "https://translate.google.com/translate?u=" +
            wattpad.utils.urlEncode(window.location.href) +
            "&tl=en&hl=en&ie=UTF-8",
          categories: app.get("categories").toJSON()
        };

        this.$el.html(this.template(data));
        this.connectMixin();
        return this;
      },

      getLangs: function() {
        var langs = [];
        _.each(wattpad.supportedLangs, function(lang) {
          langs.push({
            id: lang.id,
            name:
              lang.id !== 1 && lang.id !== 11
                ? lang.name + " (" + lang.nameEnglish + ")"
                : lang.name
          });
        });
        langs.push({ id: 11, name: "Other" });
        return langs;
      },

      onHotListReview: function(evt) {
        var confirmText = "Are you sure?",
          activePartId = this.part
            ? this.part.get("id")
            : this.story.get("firstPartId"),
          self = this;

        if (this.reported === false && window.confirm(confirmText)) {
          // TODO: Promise.resolve()
          $.post("/api/v3/story_parts/" + activePartId + "/report")
            .done(function(response) {
              self.successFeedback(".options-row");
              self.reported = true;
            })
            .fail(function(jqXHR) {
              self.failureFeedback(".options-row", self.getErrorMessage(jqXHR));
            });
        }
      },

      onOptionsEditDetails: function(evt) {
        utils.stopEvent(evt);
        app.router.navigate("/myworks/" + this.story.get("id"), {
          trigger: true
        });
      },

      onOptionsEditPart: function(evt) {
        utils.stopEvent(evt);
        app.router.navigate(
          "/mystories?compose=true&id=" + this.part.get("id"),
          {
            trigger: true
          }
        );
      },

      onLanguageChanged: function(evt) {
        var newLang = this.$("#story-language-select").val(),
          oldLang = this.story.get("language"),
          confirmText =
            "Set the language to " +
            _.find(this.langs, function(lang) {
              return lang.id == newLang; // str vs. int comparison
            }).name +
            "?",
          self = this;

        if (newLang !== oldLang && window.confirm(confirmText)) {
          this.story.set("language", newLang);

          // TODO: Promise.resolve()
          this.saveStory()
            .done(function(response) {
              self.successFeedback(".language-row");
            })
            .fail(function(jqXHR) {
              self.failureFeedback(
                ".language-row",
                self.getErrorMessage(jqXHR)
              );
              self.story.set("language", oldLang);
              self.$("#story-language-select").val(oldLang);
            });
        } else {
          this.$("#story-language-select").val(oldLang);
        }
      },

      onCategoryChanged: function(evt) {
        var newCategory = this.$("#story-category-select").val(),
          oldCategories = this.story.get("categories"),
          confirmText =
            "Set the category to " +
            app
              .get("categories")
              .get(newCategory)
              .get("name") +
            "?",
          self = this;

        if (newCategory !== oldCategories[0] && window.confirm(confirmText)) {
          this.story.set("categories", [
            parseInt(newCategory),
            oldCategories[1]
          ]);

          // TODO: Promise.resolve()
          this.saveStory()
            .done(function(response) {
              self.successFeedback(".category-row");
            })
            .fail(function(jqXHR) {
              self.failureFeedback(
                ".category-row",
                self.getErrorMessage(jqXHR)
              );
              self.story.set("categories", oldCategories);
              self.$("#story-category-select").val(oldCategories[0]);
            });
        } else {
          this.$("#story-category-select").val(oldCategories[0]);
        }
      },

      onCoverRemove: function() {
        var self = this;
        var reason = window.prompt(
          "Why is this cover being removed? Options: 'copyright', 'banned content', 'self harm'"
        );
        if (!reason) {
          window.alert("Banning was cancelled.");
          return;
        }

        var data = {
          id: this.story.get("id"),
          ownerid: this.author.id,
          req_type: "upload",
          cover: "",
          coverDeleteReason: reason
        };

        //TODO: Promise Resolve when V4 API
        $.post("/apiv2/uploadstorygroup", data)
          .done(function(response) {
            self.successFeedback(".cover-row");
            window.location.reload();
          })
          .fail(function(jqXHR) {
            self.failureFeedback(".cover-row", self.getErrorMessage(jqXHR));
          });
      },

      onFileSelected: function() {
        var self = this;
        self.getFile().then(function(data) {
          if (data) {
            Promise.resolve(
              $.ajax({
                type: "POST",
                url: "/apiv2/updatestorycover",
                data: {
                  id: self.story.get("id"),
                  image: data.parsed
                }
              })
            )
              .then(function() {
                self.successFeedback(".cover-row");
              })
              ["catch"](function() {
                self.failureFeedback(".cover-row");
              });
          }
        });
      },

      saveStory: function() {
        var storyCategories = this.story.get("categories");
        return $.post(
          "/api/v3/story_parts/" + this.story.get("firstPartId") + "/moderate",
          {
            language: this.story.get("language"),
            category1: storyCategories[0],
            category2: storyCategories[1] ? storyCategories[1] : 0
          }
        );
      },

      getErrorMessage: function(jqXHR) {
        return jqXHR && jqXHR.responseJSON
          ? jqXHR.responseJSON.message
          : "Error saving changes. Please try again.";
      },

      successFeedback: function(rowSel) {
        var self = this,
          statusSel = rowSel + " > .response-status";

        self.clearFeedback();
        this.$(statusSel).html(
          wattpad.utils.iconify("fa-check", 24, "wp-success")
        );

        window.setTimeout(function() {
          self.clearFeedback();
        }, 3000);
      },

      failureFeedback: function(rowSel, errMsg) {
        var self = this,
          statusSel = rowSel + " > .response-status";

        self.clearFeedback();
        this.$(statusSel).html(
          wattpad.utils.iconify("fa-warning", 24, "wp-error-1")
        );
        this.$(".error-row > .alert").html(errMsg);
        this.$(".error-row").removeClass("hidden");

        window.setTimeout(function() {
          self.clearFeedback();
        }, 3000);
      },

      clearFeedback: function() {
        this.$(".error-row").addClass("hidden");
        this.$(".response-status").empty();
      },

      stopEvent: function(evt) {
        wattpad.utils.stopEvent(evt);
      }
    })
  );

  app.mixin(app.views.StoryAdmin, "FileUpload");
})(window, wattpad, wattpad.utils, window.app, window.Monaco);
