(function(window, _, Monaco, wattpad, app) {
  "use strict";

  /**
   * TranslatedLanguage Model: Maps to localStorage the
   *
   * @type {*}
   */
  app.add(
    "TranslatedLanguage",
    Monaco.Collection.extend({
      initialize: function(models, options) {
        var self = this;
        this.locale =
          wattpad.utils.getCookie("locale") || navigator.language || "en_US";

        options = options || {};

        var translations = options.translations || {};
        this.jed = new window.Jed(translations);

        this.on("sync", function() {
          self.jed = new window.Jed(self.toJSON()[0]);
        });
      },

      fetch: function() {
        // Perform Housekeeping
        var partialKey = app.local._getKey("strings-");
        var fullKey = app.local._getKey(this.resource());

        _.forOwn(localStorage, function(val, key) {
          // Prune localStorage entries that do not correspond to the current resource.
          if (key.indexOf(partialKey) === 0 && key !== fullKey) {
            localStorage.removeItem(key);
          }
        });

        Monaco.Collection.prototype.fetch.apply(this, arguments);
      },

      url: function() {
        return wattpad.apiDomain + "/v4/strings/" + this.locale;
      },

      resource: function() {
        return "strings-" + wattpad.languagePackage;
      },

      cacheLocal: true,

      expireLocal: 1440 // 24 hours (This does nothing, and is controlled on node in the strings controller)
    })
  );
})(window, _, Monaco, wattpad, window.app);
