(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "RecommendedUsers",
    app.collections.IncrementalFetch.extend({
      model: app.models.User,

      arrayKey: "users",

      fields: [
        {
          users: [
            "username",
            "name",
            "avatar",
            "following",
            "backgroundUrl",
            "numStoriesPublished",
            "numFollowers",
            "highlight_colour",
            "recommendationHint"
          ]
        }
      ],

      username: null,
      cacheLocal: true,
      cacheExpire: 5,

      initialize: function(collection, options) {
        options = options || {};
        if (!options.username) {
          throw new Error(
            "RecommendedUsers Collection: options.username cannot be null"
          );
        }
        this.username = options.username;
        app.collections.IncrementalFetch.prototype.initialize.apply(
          this,
          arguments
        );
      },

      parse: function(response) {
        var result = app.collections.IncrementalFetch.prototype.parse.apply(
          this,
          arguments
        );
        this.total = result.length;
        return result;
      },

      removeAndDismiss: function(model) {
        var username = model.get("username"),
          self = this;

        window.te.push("event", "recommended", "users", null, "dismiss", {
          dismissed_username: username
        });

        $.post(this.url() + "/" + username + "/dismiss").done(function() {
          self.remove(model);
          self.trigger("update");
        });
      },

      //=== MONACO ===//
      resource: function() {
        return this.username + ".recommended-users";
      },

      url: function() {
        return "/api/v3/users/" + this.username + "/recommended/users";
      }
    })
  );
})(window, _, wattpad, window.app);
