import storyItem from "core/templates/item-views/story-item.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  /**
   * Story ItemView - small story card displaying general meta info, cover image & link
   * @Class StoryItem    View
   * @constructor
   */
  app.add(
    "StoryItem",
    app.views.Base.extend({
      // Properties
      // ----------
      className: "story-item clearfix",
      template: storyItem,

      // Methods
      // -------
      render: function() {
        var data = this.model.toJSON();
        data.categories = [];
        _.each(this.model.get("categories"), function(category) {
          var search = app.get("categories").get(category);
          if (search) {
            data.categories.push(search.get("name"));
          }
        });

        if (data.description) {
          data.description = utils.sanitizeHTML(data.description);
        }

        this.$el.html(this.template(data));
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
