import incrementalList from "platform/components/templates/incremental-list.hbs";
import profileLists from "platform/profile/templates/profile-lists.hbs";
(function(window, _, $, wattpad, utils, app) {
  "use strict";

  /**
   * UserList CollectionView - List of ReadingListItem views
   * @Class UserList
   * @constructor
   */
  app.add(
    "ProfileLists",
    app.views.IncrementalList.extend({
      className: "reading-lists",
      template: profileLists,

      itemView: app.views.ReadingListItem,
      containerId: "#lists-item-view",

      events: _.extend(
        {
          "tap .on-newlist": "onOpenNewListModal",
          "tap .on-create-list": "onOpenNewListModal",
          "tap .on-story-preview": "onStoryPreview",
          "click .on-story-preview": "stopEvent"
        },
        app.views.IncrementalList.prototype.events
      ),

      initialize: function() {
        Handlebars.registerPartial(
          "desktop.components.incremental_list",
          incrementalList
        );
      },

      onStoryPreview: function(event) {
        var storyId = $(event.currentTarget).data("story-id");
        var story = new app.models.StoryModel({ id: storyId });
        Promise.resolve(story.fetch({ fresh: true })).then(function() {
          var preview = new app.views.BrowseStoryPreview({
            isHome: true,
            model: story
          });

          self
            .$("#preview-modal")
            .empty()
            .append(preview.render().$el);
          preview.openModal();
        });
      },

      getTemplateData: function() {
        var data = {
          totalLists: this.collection.total,
          username: this.model.get("username")
        };
        return data;
      },

      onOpenNewListModal: function() {
        var $previewModal = $("#generic-modal");
        var createList = new app.views.LibraryListCreate();

        $previewModal
          .find(".modal-body")
          .empty()
          .append(createList.render().$el);
        $previewModal.modal("show");
      }
    })
  );

  app.mixin(app.views.ProfileLists, "InfiniteScroll");
})(window, _, jQuery, wattpad, wattpad.utils, window.app);
