(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "NodeUserSearch",
    app.collections.IncrementalFetchNextUrl.extend({
      model: app.models.UserModel,
      expires: 5,
      arrayKey: "users", // Not actually used but required by IncrementalFetch

      fields: [
        "username",
        "name",
        "avatar",
        "description",
        "numLists",
        "numFollowers",
        "numStoriesPublished",
        "badges",
        "following"
      ],

      initialize: function(collection, options) {
        options = options || {};

        this.searchTerm = options.searchTerm;
        this.limit = options.limit || 3;
        this.offset = 0;
      },

      url: function() {
        return (
          "/v4/search/users/?query=" +
          encodeURIComponent(this.searchTerm.trim()) +
          "&limit=" +
          this.limit +
          "&offset=" +
          this.offset
        );
      },

      resource: function() {
        return "search.users.results." + this.searchTerm.trim();
      },

      // Overriding CollectionParsing mixin; needed for infinite scroll
      // API returns an array of user objects
      parse: function(response) {
        this.offset = this.offset + this.limit;

        if (response.length < this.limit) {
          this.nextUrl = undefined; // IncrementalFetchNextUrl:hasMore() checks for undefined
        } else {
          this.nextUrl = this.url();
        }

        return response;
      },

      clearCache: function() {
        app.local.clear(this.resource());
      }
    })
  );
})(window, _, wattpad, window.app);
