(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * @mixin GetAppValRules
   */
  app.add(
    "GetAppValRules",
    Monaco.Mixin.create({
      defaults: { getApp: null },

      validationRules: {
        getApp: [
          {
            func: "isRequired",
            msg: utils.trans("Please enter a valid SMS number.")
          }
        ]
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
