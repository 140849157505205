var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.recentlyRead || (depth0 != null ? depth0.recentlyRead : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"recentlyRead","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.recentlyRead) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "      <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.readingURL : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"on-navigate library-item\">\n          <div class=\"cover cover-xs\">\n              "
    + alias3((helpers.connectCover || (depth0 && depth0.connectCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),(depth0 != null ? depth0.title : depth0),50,{"name":"connectCover","hash":{},"data":data}))
    + "\n          </div>\n          <div class=\"content\">\n              <h3 class=\"h6\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h3>\n              <small>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + "</small>\n              <div class=\"reading-progress\">\n                  <div class=\"pct-read\" style=\"width:"
    + alias3(((helper = (helper = helpers.percentRead || (depth0 != null ? depth0.percentRead : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"percentRead","hash":{},"data":data}) : helper)))
    + "%\"></div>\n              </div>\n              <span class=\"x-small pull-right\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.percentRead : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + "              </span>\n          </div>\n      </a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    return "                  "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Continue reading",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"5":function(container,depth0,helpers,partials,data) {
    return "                  "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Start reading",{"name":"trans","hash":{},"data":data}))
    + "\n";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "        <div id=\"no-recent-reads\">"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),"Adding %sstories%s to your library allows you to return to them more easily.","<a href='/stories' class='on-navigate'>","</a>",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"collection\">\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.hasItems : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(7, data, 0),"data":data})) != null ? stack1 : "")
    + "</div>\n";
},"useData":true});