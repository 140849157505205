(function(window, _, wattpad, utils, app) {
  "use strict";

  /**
   * @mixin GetMobileValRules
   */
  app.add(
    "GetMobileValRules",
    Monaco.Mixin.create({
      defaults: { getMobile: null },

      validationRules: {
        getMobile: [
          {
            func: "isRequired",
            msg: utils.trans("Please enter a valid email address.")
          },
          {
            func: "isPhoneOrEmail",
            msg: utils.trans("Please enter a valid email address.")
          }
        ]
      },

      isPhoneOrEmail: function(value, options, n) {
        var temp, isEmail, isPhone;

        n();

        isEmail = value.match(this.regex.email) !== null;
        if (isEmail) {
          return true;
        }
        return false;
      }
    })
  );
})(window, _, wattpad, wattpad.utils, window.app);
