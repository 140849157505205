(function(window, _, wattpad, app, Monaco) {
  "use strict";

  /**
   * Generic "Collection View" that also manages "Item Views" for each model in a collection.
   * Meant to be inherited from, not standalone.
   * @Class CollectionView
   * @constructor
   */
  app.add(
    "CollectionView",
    app.views.Base.extend({
      //Based off of $.append and $.prepend
      directions: {
        up: "prepend", //appends new items on the top
        down: "append" //appends new items on the bottom
      },

      constructor: function(options) {
        options = options || {};
        if (typeof this.itemView === "undefined") {
          throw new Error(
            "CollectionView: this.itemView does not exist (required)."
          );
        }
        // Hash of references to the Item Views.
        // Format - `model.cid : itemViewInstance`
        this.viewPointers = {};

        this.direction = this.directions[options.direction || "down"];

        if (this.direction === null) {
          throw new Error("CollectionView: this.direction is not valid.");
        }
        // [ Optional ] CSS class/id { String } to inject ItemViews into
        // containerId overrides containerClass; use one or the other
        // containerId finds the container via `this.$el.find( containerId )`
        // containerClass finds the container via `this.$el.children( containerClass )`
        this.containerId = options.containerId || this.containerId || null;
        this.containerClass =
          options.containerClass || this.containerClass || null;
        this.$container = null; // set on first call to renderItem()

        // [ Optional ] filter on the collection
        this.collectionFilter = null;

        _.bindAll(this, "renderCollection", "renderItem");

        // call the original constructor
        app.views.Base.prototype.constructor.apply(this, arguments);
      },

      // listen for collection events & call the appropriate methods
      bindEvents: function() {
        // If this is the second time render is being called, we don't want to
        // double bind these events.
        this.stopListening(this.collection, "reset sort add remove");
        this.listenTo(
          this.collection,
          "reset sort",
          this.renderCollection,
          this
        );

        this.listenTo(
          this.collection,
          "add",
          function(model) {
            this.renderItem(model);
          },
          this
        );

        this.listenTo(
          this.collection,
          "remove",
          function(model) {
            this.removeItem(model);
          },
          this
        );
      },

      setDirection: function(direction) {
        if (typeof direction !== "string") {
          this.direction = this.directions.down;
          return;
        }

        this.direction = this.directions[direction];
        if (typeof this.direction === "undefined" || this.direction === null) {
          this.direction = this.directions.down;
        }
      },

      /*This is really only useful for SSR.
     All collection views (used for Show More listings)
     will not have the proper container bound when the
     javascript boots; hence when setElement gets
     called again we have to correct the container*/
      setElement: function(el) {
        // Need to rebind the container class on SSR
        var self = this;
        app.views.Base.prototype.setElement.call(this, el);
        // This code will still be fine even on a fresh load; it'll just be null
        this.$container = this.getContainer();

        if (this.collection && this.$container.length !== 0) {
          var childCount = 0;
          _.each(this.collection.models, function(model) {
            //We have to do this check because if the collection filter
            //activated, we will have no views for certain models in the
            //collection
            var modelView = self.viewPointers[model.cid];
            if (modelView) {
              modelView.setElement(self.$container.children()[childCount]);
              childCount++;
            }
          });
        }
      },

      getContainer: function() {
        var $container = null;

        if (this.containerId) {
          $container = this.$el.find(this.containerId);
        } else if (this.containerClass) {
          $container = this.$el.children(this.containerClass);
        } else {
          $container = this.$el;
        }
        return $container;
      },

      // ItemView Generation
      renderItem: function(model, $container, index, options) {
        $container = $container || this.getContainer();
        if (
          this.collectionFilter === null ||
          this.collectionFilter.apply(model) === true
        ) {
          var data = { model: model, cvIndex: index };
          if (options) {
            _.extend(data, options);
          } else {
            _.extend(data, this.getItemViewOptions());
          }
          this.viewPointers[model.cid] = new this.itemView(data);
          this.viewPointers[model.cid].render();
          $container[this.direction](this.viewPointers[model.cid].$el);
        }
      },

      getItemViewOptions: function() {
        return {};
      },

      renderCollection: function(options) {
        var $container = this.getContainer();
        this.removeAll(); // clear current references (if any exist)
        this.collection.each(function(model, index) {
          this.renderItem(model, $container, index, options);
        }, this);

        return this;
      },

      setCollectionfilter: function(filterFunc) {
        this.collectionFilter = filterFunc;
      },

      // Cleanup / Removal
      removeItem: function(model) {
        if (_.has(this.viewPointers, model.cid)) {
          this.viewPointers[model.cid].remove();
          delete this.viewPointers[model.cid];
        }
      },

      removeAll: function() {
        _.each(
          this.viewPointers,
          function(view) {
            this.removeItem(view.model);
          },
          this
        );
      },

      remove: function() {
        this.removeAll();
        app.views.Base.prototype.remove.apply(this, arguments);
      }
    })
  );
})(window, _, wattpad, window.app, Monaco);
