var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<div id=\"generic-modal\" class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <header class=\"modal-header\">\n            <div class=\"close\" data-dismiss=\"modal\">\n                "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove",28,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n        </header>\n        <div class=\"modal-body clearfix\">\n            <div id=\"subscribe-cta\" class=\"background\">\n                <p class=\"title\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Discover the best reading experience with Wattpad Premium",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                "
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Upgrade to discover a new layer of Wattpad with new themes, an ad-free experience, and to show support for the community you love.",{"name":"trans","hash":{},"data":data}))
    + "\n                <div class=\"strong\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"To get started, subscribe through the Wattpad app on your mobile device.",{"name":"trans","hash":{},"data":data}))
    + "</div>\n            </div>\n            <div id=\"how-to-subscribe\">\n                <div class=\"row\">\n                    <div class=\"col-sm-4\">\n                        <div class=\"step-circle\">\n                            <p>1</p>\n                        </div>\n                        <p class=\"step-instruction\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Open the Wattpad app on your mobile device and click on the Go Premium button on the home page.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                    </div>\n                    <div class=\"col-sm-4\">\n                        <div class=\"step-circle\">\n                            <p>2</p>\n                        </div>\n                        <p class=\"step-instruction\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Choose your Wattpad Premium plan.",{"name":"trans","hash":{},"data":data}))
    + "</p>\n                    </div>\n                    <div class=\"col-sm-4\">\n                        <div class=\"step-circle\">\n                            <p>3</p>\n                        </div>\n                        <p class=\"step-instruction\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Once you purchase Wattpad Premium, your account will instantly be upgraded wherever you use Wattpad.",{"name":"trans","hash":{},"data":data}))
    + "\n                        </p>\n                    </div>\n                </div>\n                <div class=\"row instructions-images\">\n                    <div class=\"col-sm-4\">\n                        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/premium-modal/step-1@2x.png","Open the app",{"name":"connectImage","hash":{},"data":data}))
    + "\n                    </div>\n                    <div class=\"col-sm-4\">\n                        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/premium-modal/step-2@2x.png","Select a plan",{"name":"connectImage","hash":{},"data":data}))
    + "\n                    </div>\n                    <div class=\"col-sm-4\">\n                        "
    + alias3((helpers.connectImage || (depth0 && depth0.connectImage) || alias2).call(alias1,"/premium-modal/step-3@2x.png","You're premium!",{"name":"connectImage","hash":{},"data":data}))
    + "\n                    </div>\n                </div>\n            </div>\n        </div>\n        <footer class=\"modal-footer\">\n            <div class=\"strong on-track-event\" data-action-type=\"faq\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Have any questions?",{"name":"trans","hash":{},"data":data}))
    + " <a href=\"https://premium.wattpad.com\" target=\"_blank\" rel=\"noopener noreferrer\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Read our FAQ",{"name":"trans","hash":{},"data":data}))
    + "</a></div>\n        </footer>\n    </div>\n</div>\n";
},"useData":true});