window.mobileApp = window.mobileApp || {};
window.mobileApp.framework = function(
  window,
  $,
  _,
  Backbone,
  Monaco,
  wattpad,
  app
) {
  "use strict";

  wattpad = wattpad || (window.wattpad = {});
  var utils = wattpad.utils || (wattpad.utils = {});

  app.frameworkFilters = app.frameworkFilters || {};

  // make sure all ajax calls have the accept-language header properly setup
  app.frameworkFilters.beforeAjax = function(beforeSend, req) {
    var code = "en";
    try {
      code = app
        .get("supported-languages")
        .get(app.get("language"))
        .get("code");
    } catch (e) {
      // do nothing for now it will default to en
    }
    req.setRequestHeader("Accept-Language", code);
    req.setRequestHeader("Authorization", app.get("api-key-web"));
    if (beforeSend) {
      return beforeSend.apply(this, utils.getWrapParams(arguments));
    }
  };

  app.frameworkFilters.fetch = function(func) {
    var options = arguments[1] || {};
    options.beforeSend = _.wrap(
      options.beforeSend,
      app.frameworkFilters.beforeAjax
    );
    return func.call(this, options);
  };

  app.router.on("route", function(route) {
    var $body = $("body");
    $body.removeClass(function(index, css) {
      return (css.match(/\broute-\S+/g) || []).join(" ");
    });
    $body.addClass("route-" + route);
    // WEB-6999 Ask GetSocial to append hash enabled
    if (window.GSLoader) {
      window.GSLoader.reset();
    }
  });

  /**
   The following code will override Monaco (Backbone) way of dealing with the hashtag
   by using it as a source of data in the same way the query string is handled
  **/

  // saves the original _extractParameters router method ( either Backbone or BQP )
  Monaco.Router.prototype.__extractParameters =
    Monaco.Router.prototype._extractParameters;

  // override the _extractParameters to extract and process the hashtag content
  // and then combine it with the original executing the original
  Monaco.Router.prototype._extractParameters = function(route, fragment) {
    var hashIndex = fragment.lastIndexOf("#"),
      hash = Monaco.history.getHash(),
      hashArgs = {},
      args;

    // sanitize the fragment by removing the hash content
    if (hashIndex >= 0) {
      fragment = fragment.slice(0, fragment.lastIndexOf("#"));
    }

    // get the original arguments
    args = this.__extractParameters(route, fragment);

    // get possible hash arguments
    if (_.isString(hash) && hash.length > 2) {
      hashArgs = Monaco.history.getQueryParameters("?" + hash);

      if (_.size(hashArgs) > 0) {
        // merge hash arguments with qs arguments if qs arguments is avaialble
        if (_.isObject(args[args.length - 1])) {
          args[args.length - 1] = _.extend(args[args.length - 1], hashArgs);
        }
        // append hash arguments if no qs arguments available
        else {
          args[args.length] = hashArgs;
        }
      }
    }

    return args;
  };

  /*
    Override the history.start to disable both pushState and Hash urls
    whenever there is no support for history management
  */
  var historyStart = Monaco.history.start;
  Monaco.history.start = function(options) {
    options = options || {};
    if (!window.history || !window.history.pushState) {
      options.pushState = false;
      options.hashChange = false;
    }
    return historyStart.call(this, options);
  };

  // Adapt Monaco Experiments to use Loki and Prometheus
  // --------------------------------------------------

  var expController = Monaco.Experiment.prototype.controller,
    expView = Monaco.Experiment.prototype.view,
    expTemplate = Monaco.Experiment.prototype.template;

  Monaco.Experiment.prototype.controller = function() {
    this.selected();
    return expController.apply(this, arguments);
  };

  Monaco.Experiment.prototype.view = function() {
    this.selected();
    return expView.apply(this, arguments);
  };

  Monaco.Experiment.prototype.template = function(name) {
    this.selected();
    return expTemplate.apply(this, arguments);
  };

  Monaco.Experiment.prototype.split = function() {
    var variation;
    // server already choose a variation for client
    if (this.groups && !_.isEmpty(this.groups)) {
      variation = Object.keys(this.groups)[0];
    }
    if (variation) {
      this.current =
        variation !== "original" &&
        variation !== "unknown" &&
        (this.options.status === "active" || this.options.status === "finished")
          ? variation
          : this.original;
      this.variationLoki = variation;
    }
    this.selectedFlag = {};
  };

  Monaco.Experiment.prototype.selected = function() {
    var route = window.location.pathname;

    if (
      this.options.status === "active" && // don't send `select` events for inactive experiments
      !this.selectedFlag[route]
    ) {
      // select event has not been fired on this route
      wattpad.utils.experimentSelected(this);
      this.selectedFlag[route] = true;
    }
  };

  Monaco.Experiment.prototype._normalizeGroup = function() {
    return [];
  };

  Monaco.Experiment.prototype.optout = function() {
    throw new Error(
      "Loki experiments don't support opt out from an experiment yet"
    );
  };

  // Monaco.Model and Monaco.Collection - Improvements
  // --------------------------------------------------

  Monaco.Model.prototype.lookup = Monaco.Collection.prototype.lookup = function(
    options
  ) {
    var _self = this;

    options = options || {};

    return new window.Promise(function(resolve, reject) {
      // wrap success callback to resolve the promise
      var success = options.success;
      options.success = function() {
        var result;
        if (success) {
          result = success.apply(_self, arguments);
        }
        resolve(_self);
      };

      // wrap error callback to reject the promise
      var error = options.error;
      options.error = function() {
        var result;
        if (error) {
          result = error.apply(_self, arguments);
        }
        reject.apply(_self, arguments);
      };

      // perform the model/collection fetch
      _self.fetch(options);
    });
  };

  if (window.wattpad.apiDomain === "https://www.wattpad.io") {
    /* For development/debugging purposes only.
     * Replaces the original Monaco.Application.prototype.add with a similar version,
     *  except that the following fields are added:
     *
     *  - `MonacoComponentName`: the name of the component (i.e. "CoreStoryReading", "CommentModel")
     *
     *  - `MonacoComponentType`: the type of the component (i.e. "mixins", "models")
     *
     *  - `MonacoComponentParents`: the names of the inherited parents (i.e. app.views.Comments would be ["IncrementalList", "CollectionView", "Base"] )
     */

    var getAllParents = function(prototype, allParents) {
      if (
        prototype &&
        prototype.__proto__ &&
        prototype.__proto__.MonacoComponentName
      ) {
        var more = allParents.concat(prototype.__proto__.MonacoComponentName);
        return getAllParents(prototype.__proto__, more);
      } else {
        return allParents;
      }
    };

    Monaco.Application.prototype.add = function(className, object) {
      // all objects added through this method needs a namespace
      if (!object.prototype || !object.prototype.namespace) {
        throw new Error(
          "Monaco :: missing required object property 'namespace'"
        );
      }

      // fail on duplicated objects being created on the same namespace
      if (this[object.prototype.namespace][className]) {
        throw new Error(
          "Monaco :: " +
            className +
            " have already been defined in " +
            this.name +
            "." +
            object.prototype.namespace
        );
      }

      // injects the app reference in the object prototype
      object.prototype._app = this;

      // Injects the name of the Monaco component.
      object.prototype.MonacoComponentName = className;

      // Injects the type of the Monaco component (i.e. "mixins", "models")
      object.prototype.MonacoComponentType = object.prototype.namespace;

      // Injects an array of inherited Monaco components.
      object.prototype.MonacoComponentParents = getAllParents(
        object.prototype,
        []
      );

      // adds the object using the proper namespacing
      this[object.prototype.namespace][className] = object;
    };
  }
};
window.mobileApp.framework(window, $, _, Backbone, Monaco, wattpad, window.app);
