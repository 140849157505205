var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"name","hash":{},"data":data}) : helper)));
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"username","hash":{},"data":data}) : helper)));
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Work","%s Works",(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n";
},"6":function(container,depth0,helpers,partials,data) {
    return " &middot; ";
},"8":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return " "
    + container.escapeExpression((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Follower","%s Followers",(depth0 != null ? depth0.numFollowers : depth0),{"name":"ngettext","hash":{},"data":data}),(helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"count","hash":{},"data":data}),{"name":"sprintf","hash":{},"data":data}))
    + " ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<a href=\"/user/"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\" class=\"on-result person-result\" data-id=\""
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\">\n    <div class=\"avatar avatar-md pull-left\">\n        <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(depth0 != null ? depth0.avatar : depth0),48,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </div>\n    <div class=\"content\">\n        <div class=\"name-and-badges\">\n            <h5>"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.name : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "")
    + "</h5>\n            <div class=\"badges\">\n"
    + ((stack1 = container.invokePartial(partials["core.components.user_badges"],depth0,{"name":"core.components.user_badges","hash":{"badgeSize":"14"},"data":data,"indent":"                ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "            </div>\n        </div>\n        <small class=\"username\">@"
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "</small>\n        <small class=\"meta\">\n        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numStoriesPublished : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "        "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numFollowers : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n        </small>\n    </div>\n</a>\n";
},"usePartial":true,"useData":true});