const {
  getBrowseEligibility
} = require("../../../../helpers/ads/get-ad-eligibility");

(function(window, _, $, wattpad, app) {
  "use strict";

  app.router.on(
    "route:browse-tag",
    app.router.filter(["getTranslations", "getCategories"], function(
      tag,
      filter,
      params
    ) {
      if (
        typeof params === "object" &&
        (params["redirectfrom"] || params["redirectFrom"])
      ) {
        var toast = new app.views.ErrorToast(
          {
            message:
              wattpad.utils.trans(
                "We're sorry! The page you requested no longer exists."
              ) +
              " " +
              wattpad.utils.trans("So we have brought you to a related page.")
          },
          { type: "dismissable" }
        );
        toast.render();
      }

      var view,
        tagNames,
        topic = null,
        topicModel = null,
        rootTopicModel = null,
        modulesCollection = null,
        browseTopicsCollection = new app.collections.BrowseTopics(),
        adEligibility = [];

      if (typeof __atha !== "undefined") {
        var id = __atha.sendPageView("tag_search");
      }

      let adEligibilityPromise = wattpad?.testGroups.AD_ELIGIBILITY
        ? getBrowseEligibility()
        : Promise.resolve([]);

      // Fetching first-level topics
      Promise.all([browseTopicsCollection.fetch(), adEligibilityPromise]).then(
        function(response) {
          adEligibility = response[1];
          topicModel = browseTopicsCollection.findWhere({ name: tag });

          // Need to keep track of whether first/last tag selected is a topic
          // First is for display reasons, last is just for tracking clicks from first level topics
          var firstTag = tag.split(",")[0];
          var lastTag = tag.split(",").slice(-1)[0];
          rootTopicModel = browseTopicsCollection.findWhere({
            name: firstTag
          });
          var lastTopicModel = browseTopicsCollection.findWhere({
            name: lastTag
          });

          // Loading a topic page
          if (topicModel) {
            tag = null;
            topic = topicModel.get("name");
            tagNames = topicModel.get("topic");

            // Modules collection
            modulesCollection = new app.collections.TagModules([], {
              tags: topic
            });
          }
          // Loading a tag page
          else {
            tag = tag.toLowerCase();
            tagNames = tag
              .split(",")
              .map(function(tag) {
                return "#" + tag;
              })
              .join(" ");

            // Modules collection
            modulesCollection = new app.collections.TagModules([], {
              tags: tag
            });
          }

          // Fetching the different tab modules and if a topic, the tags under Explore tab
          Promise.resolve(modulesCollection.fetchNextSet()).then(
            function() {
              view = new app.views.Browse({
                tag: tag,
                topic: topic,
                topicModel: topicModel,
                collection: modulesCollection,
                rootTopicModel: rootTopicModel,
                lastTopicModel: lastTopicModel,
                browseTopicsCollection: browseTopicsCollection,
                section: filter,
                adEligibility
              });

              const noIndex = tag ? wattpad.utils.isUnsafe(tag) : false;
              app.transitionTo(view, {
                hasHeader: true,
                hasFooter: true,
                noIndex: noIndex,
                pageTitle: wattpad.utils.trans("%s Stories", tagNames)
              });
            },
            function() {
              //  error handling
              app.router.trigger("route:error-404", "browse-tag");
            }
          );
        },
        function() {
          //  error handling
          app.router.trigger("route:error-404", "browse-tag");
        }
      );

      window.te.push("event", "app", "page", null, "view", {
        page: "tag_page",
        tags: tag,
        filter: filter || null
      });
    })
  );
})(window, _, jQuery, wattpad, window.app);
