import formatScheduledPartDate from "../../../../helpers/format-scheduled-part-date";

(function(app, wattpad) {
  "use strict";
  wattpad = wattpad || (window.wattpad = {});
  app.add(
    "NewStoryDetails",
    app.views.DummyReactView.extend({
      component: "StoryDetails",
      componentId: "story-details-landing",

      constructor: function(opts) {
        this.model = opts.model;
        this.similarStories = opts.similarStories;
        this.userModel = opts.userData;
        this.signupModel = opts.signupModel;
        this.adEligibility = opts.adEligibility[0];

        let options = {
          componentData: this.getComponentData(),
          component: this.component,
          componentId: this.componentId
        };

        app.views.DummyReactView.call(this, options);
      },
      getComponentData: function() {
        const language = parseInt(app.get("language", 10));
        const reportUrl =
          "/help/content?message=" +
          wattpad.utils.urlEncode("I am reporting the following content:\n") +
          "&story_link=" +
          wattpad.utils.urlEncode(window.location.href);
        const translateUrl =
          "https://translate.google.com/translate?u=" +
          wattpad.utils.urlEncode(window.location.href) +
          "&tl=en&hl=en&ie=UTF-8";
        const username = wattpad
          ? wattpad.utils.currentUser().get("username")
          : null;

        let parts = this.model.get("parts");

        let isPaidPreview = false;
        let hasBonusParts = false;
        let numNewParts = 0;
        let numNewBonusParts = 0;
        let premiumPicks = null;

        if (this.model.get("isPaywalled")) {
          const { paidMetadata } = window.store.getState();

          parts = parts.map(part => {
            let partMetadata = paidMetadata[this.model.get("id")].parts.find(
              p => part.id === p.id
            );
            if (!partMetadata) return part;

            part = {
              ...part,
              ...partMetadata
            };

            return part;
          });

          // Check if user has purchased any part of the story.
          // We consider paid stories a "preview", so users are
          // aware they will have to pay. Once the user has paid
          // at least once, they know the story isn't free, so
          // it's no longer a preview.
          isPaidPreview =
            paidMetadata[this.model.get("id")].story.isPaidPreview;
          hasBonusParts =
            paidMetadata[this.model.get("id")].story.hasBonusParts;
          numNewParts = paidMetadata[this.model.get("id")].story.numNewParts;
          numNewBonusParts =
            paidMetadata[this.model.get("id")].story.numNewBonusParts;
          premiumPicks = paidMetadata[this.model.get("id")].story.premiumPicks;
        }

        if (this.model.get("user")?.username == username) {
          parts.map(part => {
            part.isScheduledPart = part.hasOwnProperty(
              "scheduledPublishDatetime"
            );
            part.formattedScheduledPublishDatetime = part.isScheduledPart
              ? formatScheduledPartDate(part.scheduledPublishDatetime)
              : null;
          });
        }

        let firstPartId = this.model.get("firstPartId");
        const draft = this.model.get("draft");

        // If the story is a draft, firstPartId will be the id of first draft part.
        if (draft && parts.length > 0) {
          firstPartId = parts[0]?.id;
        }

        const firstStoryPart = parts
          ? parts.find(part => part.id === firstPartId)
          : {};
        const nextUrl = window.location.href;
        const isLoggedIn =
          window &&
          window.wattpad &&
          wattpad.utils.currentUser().authenticated();

        const onLanguageChange = (
          setLangStatus,
          newLang,
          setDefaultLanguage,
          responseFeedback
        ) => {
          var newLang = parseInt(newLang),
            oldLang = parseInt(this.model.get("language")),
            confirmText =
              "Set the language to " +
              _.find(this.getLangs(), function(lang) {
                return lang.id == newLang; // str vs. int comparison
              }).name +
              "?";

          if (newLang !== oldLang && window.confirm(confirmText)) {
            this.model.set("language", newLang);

            saveStory()
              .done(() => {
                responseFeedback(setLangStatus);
              })
              .fail(jqXHR => {
                responseFeedback(setLangStatus, getErrorMessage(jqXHR));
                this.model.set("language", oldLang);
                setDefaultLanguage(oldLang);
              });
          } else {
            setDefaultLanguage(oldLang);
          }
        };

        const onCoverRemove = (setCoverStatus, responseFeedback) => {
          var reason = window.prompt(
            "Why is this cover being removed? Options: 'copyright', 'banned content', 'self harm'"
          );
          if (!reason) {
            window.alert("Banning was cancelled.");
            return;
          }

          var payload = {
            id: this.model.get("id"),
            ownerid: this.model.get("user").username,
            req_type: "upload",
            cover: "",
            cover_source_id: "",
            coverDeleteReason: reason
          };

          Promise.resolve($.post("/apiv2/uploadstorygroup", payload))
            .then(data => {
              this.model.set("cover", data.cover);
              this.model.set("cover_source_id", data.cover_source_id);
              responseFeedback(setCoverStatus);
              window.location.reload();
            })
            .catch(err => {
              responseFeedback(setCoverStatus, getErrorMessage(err));
            });
        };

        const onFileSelected = (setCoverStatus, responseFeedback) => {
          this.getFile().then(data => {
            if (data) {
              const payload = {
                id: this.model.get("id"),
                image: data.parsed
              };
              if (data.metadata && data.metadata.designId) {
                payload.cover_source_id = data.metadata.designId;
                payload.cover_partner = "canva";
              }

              Promise.resolve(
                $.post({
                  type: "POST",
                  url: "/apiv2/updatestorycover",
                  data: payload
                })
              )
                .then(data => {
                  this.model.set("cover", data.cover);
                  this.model.set("cover_source_id", data.cover_source_id);
                  this.model.set("cover_partner", data.cover_partner);
                  responseFeedback(setCoverStatus);
                  window.location.reload();
                })
                .catch(err => {
                  responseFeedback(setCoverStatus, getErrorMessage(err));
                });
            }
          });
        };

        const onCategoryChange = (
          setCategoryStatus,
          newCategory,
          setDefaultCategory,
          responseFeedback
        ) => {
          var oldCategories = this.model.get("categories"),
            confirmText =
              "Set the category to " +
              app
                .get("categories")
                .get(newCategory)
                .get("name") +
              "?";

          if (
            newCategory > 0 &&
            newCategory !== oldCategories[0] &&
            window.confirm(confirmText)
          ) {
            this.model.set("categories", [
              parseInt(newCategory),
              oldCategories[1]
            ]);

            saveStory()
              .done(() => {
                responseFeedback(setCategoryStatus);
              })
              .fail(jqXHR => {
                responseFeedback(setCategoryStatus, getErrorMessage(jqXHR));
                this.model.set("categories", oldCategories);
                setDefaultCategory(oldCategories[0]);
              });
          } else {
            setDefaultCategory(oldCategories[0]);
          }
        };

        const saveStory = () => {
          var storyCategories = this.model.get("categories");
          return $.post(
            "/api/v3/story_parts/" +
              this.model.get("firstPartId") +
              "/moderate",
            {
              language: this.model.get("language"),
              category1: storyCategories[0],
              category2: storyCategories[1] ? storyCategories[1] : 0
            }
          );
        };

        const getErrorMessage = err => {
          return err && err.responseJSON
            ? err.responseJSON.message
            : "Error saving changes. Please try again.";
        };

        const currentUserObj = wattpad?.utils?.currentUser?.()?.toJSON();
        const adData = {
          adEligibility: this.adEligibility,
          userGeo: wattpad.userCountryCode,
          deviceType: wattpad.utils.getDeviceType(),
          testGroups: wattpad.testGroups,
          context: {
            story: {
              user: this.model.get("user"),
              id: parseInt(this.model.get("id"), 10),
              isAdExempt: this.model.get("isAdExempt"),
              isPaywalled: this.model.get("isPaywalled"),
              isBrandSafe: this.model.get("isBrandSafe"),
              brandSafetyLevel: this.model.get("brandSafetyLevel"),
              brandSafetySource: this.model.get("brandSafetySource"),
              imagesUnderModeration: this.model.get("imagesUnderModeration"),
              categories: this.model.get("categories"),
              totalViewCount: this.model.get("readCount"),
              rating: this.model.get("rating"),
              language: this.model.get("language") // `language` can either be an object or an ID, send the ID
            },
            user: wattpad.utils.currentUser().toJSON()
          }
        };

        const isPremium = wattpad.utils.currentUser().get("isPremium");
        let isCurrentPremiumPick, isUpcomingPremiumPick;

        return {
          currentUserRoles: wattpad.utils.getCurrentUserRoles(currentUserObj),
          username,
          userLang: language,
          isLoggedIn,
          signupModel: this.signupModel,
          languages: this.getLangs(),
          onLanguageChange: onLanguageChange,
          onCategoryChange: onCategoryChange,
          onFileSelected: onFileSelected,
          onCoverRemove: onCoverRemove,
          categories: app.get("categories").toJSON(),
          isDesktop: wattpad.utils.getDeviceType() !== "mobile",
          storyData: {
            id: parseInt(this.model.get("id"), 10),
            title: this.model.get("title"),
            description: this.model.get("description"),
            createDate: this.model.get("createDate"),
            numNewParts,
            hasBonusParts,
            numNewBonusParts,
            premiumPicks,
            user: this.userModel,
            cover: this.model.get("cover"),
            isPaywalled: this.model.get("isPaywalled"),
            paidModel: this.model.get("paidModel"),
            isPaidPreview,
            storyTags: {
              tags: this.mapTags(this.model.get("tags"))
            },
            readCount: this.model.get("readCount"),
            completed: this.model.get("completed"),
            draft,
            deleted: this.model.get("deleted"),
            rating: this.model.get("rating"),
            voteCount: this.model.get("voteCount"),
            numParts: this.model.get("numParts"),
            length: this.model.get("length"),
            firstPartId,
            language: this.model.get("language"),
            storyLanguage: this.model.get("storyLanguage"),
            lastPublishedPart: this.model.get("lastPublishedPart"),
            storyReportUrl: reportUrl,
            storyTranslateUrl: translateUrl,
            tagRankings: this.model.get("tagRankings"),
            parts,
            sources: this.model.sources,
            isBrandSafe: this.model.get("isBrandSafe"),
            writerPreviewHasBannedCover: this.writerPreviewHasBannedCover(),
            copyright: this.model.get("copyright"),
            recommendedStories: this.similarStories.map(story => {
              return { ...story, id: parseInt(story.id, 10) };
            }),
            firstStoryPart,
            nextUrl,
            storyCategories: this.model.get("categories"),
            url: this.model.get("url")
          },
          isPremium,
          adData
        };
      },
      getLangs: function() {
        var langs = [];
        _.each(wattpad.supportedLangs, function(lang) {
          langs.push({
            id: lang.id,
            name:
              lang.id !== 1 && lang.id !== 11
                ? lang.name + " (" + lang.nameEnglish + ")"
                : lang.name
          });
        });
        langs.push({ id: 11, name: "Other" });
        return langs;
      },
      writerPreviewHasBannedCover: function() {
        return (
          wattpad.utils.currentUser() &&
          this.model.get("hasBannedCover") &&
          this.model.get("user").username ===
            wattpad.utils.currentUser().get("username")
        );
      },
      mapTags: function(tags) {
        if (!Array.isArray(tags)) {
          console.error("Story Details: tags are missing or incorrect format");
          return [];
        }

        return tags.map(function(tag) {
          return { id: tag, name: tag };
        });
      }
    })
  );

  // This lets the events for Facebook & Google auth bind
  app.mixin(app.views.NewStoryDetails, "FacebookConnect", "FileUpload");
})(window.app, wattpad);
