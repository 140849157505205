import discoverModuleUsersSmall from "core/templates/item-views/discover-module-users-small.hbs";
import discoverModuleUsersLarge from "core/templates/item-views/discover-module-users-large.hbs";
(function(window, _, $, wattpad, utils, app, Monaco) {
  "use strict";

  app.add(
    "DiscoverModuleUsers",
    app.views.Base.extend({
      className: "on-discover-module-item",

      render: function() {
        var data =
            this.model && this.options.numUsersInCollection > 0
              ? this.model.toJSON()
              : { empty: true },
          isDesktop = app.get("device").isDesktop(),
          useLargeTemplate = false;

        if (
          (isDesktop && this.options.numUsersInCollection < 6) ||
          (!isDesktop && this.options.numUsersInCollection < 3)
        ) {
          useLargeTemplate = true;
        }

        if (useLargeTemplate) {
          this.template = discoverModuleUsersLarge;
          this.$el.addClass("discover-module-users-large");
          data.nameMaxLength = isDesktop ? 35 : 25;
          data.descMaxLength = isDesktop ? 170 : 110;
        } else {
          this.template = discoverModuleUsersSmall;
          this.$el.addClass("discover-module-users-small");
          data.nameMaxLength = isDesktop ? 35 : 28;
        }

        this.$el.html(this.template(data));
        return this;
      }
    })
  );
})(window, _, jQuery, wattpad, wattpad.utils, window.app, window.Monaco);
