(function(window, _, wattpad, app) {
  "use strict";

  app.add(
    "StoryLabelsModel",
    app.models.BaseModel.extend({
      fields: ["characters", "target_audience"],
      idAttribute: "storyId",
      urlRoot: "/v5/stories",

      resource: function() {
        return "story." + this.get("id") + ".labels";
      }
    })
  );
})(window, _, wattpad, window.app);
