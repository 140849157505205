var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "    <small class=\"x-small offensive\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",13,"wp-error-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"this message may be offensive",{"name":"trans","hash":{},"data":data}))
    + "</small>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "      <pre>"
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.body : depth0),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + "</pre>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, buffer = "";

  stack1 = ((helper = (helper = helpers.beautifyUrlMetadata || (depth0 != null ? depth0.beautifyUrlMetadata : depth0)) != null ? helper : helpers.helperMissing),(options={"name":"beautifyUrlMetadata","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data}),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),options) : helper));
  if (!helpers.beautifyUrlMetadata) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer;
},"6":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.shared.beautify_url"],depth0,{"name":"core.shared.beautify_url","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"8":function(container,depth0,helpers,partials,data) {
    return " hidden ";
},"10":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "  <div id=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.pinnedItem : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "replies-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n      class='replies-list collapse'\n      data-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"\n      data-profile-owner=\""
    + alias4(((helper = (helper = helpers.username || (depth0 != null ? depth0.username : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"username","hash":{},"data":data}) : helper)))
    + "\"\n      data-message-owner=\""
    + alias4(container.lambda(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n  </div>\n";
},"11":function(container,depth0,helpers,partials,data) {
    return "pinned-";
},"13":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = container.invokePartial(partials["core.feed_items.feed_message_reply"],depth0,{"name":"core.feed_items.feed_message_reply","data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression, alias5=container.lambda;

  return "<header id=\"comment-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" class=\"panel-heading message-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm3 on-navigate\" href=\"/user/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">\n      <img src=\""
    + alias4((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.avatar : stack1),42,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n\n    <h3 class=\"h6 from-name\"><a class=\"username on-navigate\" href=\"/user/"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "\">"
    + alias4(alias5(((stack1 = (depth0 != null ? depth0.from : depth0)) != null ? stack1.name : stack1), depth0))
    + "</a></h3>\n\n    <time class=\"timestamp\" datetime=\""
    + alias4(((helper = (helper = helpers.createDate || (depth0 != null ? depth0.createDate : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"createDate","hash":{},"data":data}) : helper)))
    + "\">"
    + alias4((helpers.fromNow || (depth0 && depth0.fromNow) || alias2).call(alias1,(depth0 != null ? depth0.createDate : depth0),{"name":"fromNow","hash":{"fuzzyTime":true},"data":data}))
    + "</time>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_message_options"],depth0,{"name":"core.feed_items.feed_item_message_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n<div class=\"panel-body message dark-grey new-message\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isOffensive : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.body : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "</div>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.beautifyUrlMetadata : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<button class=\"btn btn-white btn-block btn-replies on-reply "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.numReplies : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(8, data, 0),"data":data})) != null ? stack1 : "")
    + "\"\n  type=\"button\" data-toggle=\"collapse\" data-target=\"#replies-"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n   "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-comment",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"View %s more reply","View %s more replies",(depth0 != null ? depth0.numReplies : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numReplies : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "\n</button>\n\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isReply : depth0),{"name":"if","hash":{},"fn":container.noop,"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.latestReplies : depth0),{"name":"each","hash":{},"fn":container.program(13, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<footer class=\"panel-footer new-message\">\n  <a href class='btn btn-text btn-reply-link new-reply'>"
    + ((stack1 = (helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Reply",{"name":"trans","hash":{},"data":data})) != null ? stack1 : "")
    + "</a>\n</footer>\n";
},"usePartial":true,"useData":true});