(function(window, _, wattpad, app) {
  "use strict";

  /* Base Model
   * Base application model class that all other models inherit. Please
   * consult with team before adding new methods to this class.
   */
  app.add(
    "BaseModel",
    Monaco.Model.extend({
      cacheLocal: true,

      sync: function(method, model, options) {
        if (_.get(model.__proto__, "bypassBrowserCache")) {
          options.cache = false;
        }
        return Monaco.Model.prototype.sync.apply(this, arguments);
      }
    })
  );

  // Add Generate Fields Mixin
  app.mixin(app.models.BaseModel, "GenerateFields", "ErrorHandler");
})(window, _, wattpad, window.app);
