var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "                  <li>"
    + container.escapeExpression(container.lambda(depth0, depth0))
    + "</li>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, options, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function", buffer = 
  "<div class=\"modal-dialog\">\n    <div class=\"modal-content\">\n        <header class=\"modal-header\">\n            <div class=\"pull-right close\" data-dismiss=\"modal\">\n              "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-remove-alt",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <h2 class=\"modal-title\" id=\"publish-modal-label\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"An Error has Occurred",{"name":"trans","hash":{},"data":data}))
    + " "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",24,"wp-error-1",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "</h2>\n        </header>\n\n        <div class=\"modal-body\">\n          "
    + alias3(((helper = (helper = helpers.error_msg || (depth0 != null ? depth0.error_msg : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"error_msg","hash":{},"data":data}) : helper)))
    + "\n            <ul>\n";
  stack1 = ((helper = (helper = helpers.errors || (depth0 != null ? depth0.errors : depth0)) != null ? helper : alias2),(options={"name":"errors","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data}),(typeof helper === alias4 ? helper.call(alias1,options) : helper));
  if (!helpers.errors) { stack1 = helpers.blockHelperMissing.call(depth0,stack1,options)}
  if (stack1 != null) { buffer += stack1; }
  return buffer + "            </ul>\n        </div>\n\n        <div class=\"modal-footer\">\n            <button class=\"btn btn-orange\" data-dismiss=\"modal\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Close",{"name":"trans","hash":{},"data":data}))
    + "</button>\n        </div>\n    </div>\n</div>\n";
},"useData":true});