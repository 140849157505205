(function(window, _, utils) {
  "use strict";
  // region Helper: Story Panel Creation
  var PanelBuilder = function(stories, layout) {
    this.layout = layout || PanelBuilder.Layouts.Default;
    this.stories = _.clone(stories, true);
    this.panelGroup = [];

    if (stories.length) {
      if (this.layout === PanelBuilder.Layouts.Default) {
        // Deterministic way to choose a 'random' scheme for the panel layout
        var scheme = parseInt(this.stories[0].id, 10) % 100;

        // Default bucket for >= 10 stories
        if (this.stories.length > 9) {
          if (scheme > 49) {
            this.layout = PanelBuilder.Layouts.FourUpOutside;
          } else {
            this.layout = PanelBuilder.Layouts.FourUpInside;
          }
          // Secondary bucket for >= 7 stories
        } else if (this.stories.length > 6) {
          if (scheme > 74) {
            this.layout = PanelBuilder.Layouts.FourUpPanel4;
          } else if (scheme > 49) {
            this.layout = PanelBuilder.Layouts.FourUpPanel3;
          } else if (scheme > 24) {
            this.layout = PanelBuilder.Layouts.FourUpPanel2;
          } else {
            this.layout = PanelBuilder.Layouts.FourUpPanel1;
          }
          // Tertiary bucket for >= 4 stories
        } else {
          this.layout = PanelBuilder.Layouts.FourUpNone;
        }
      }

      this.generateGroup();
    }
  };

  PanelBuilder.Layouts = {
    Default: 1,
    FourUpNone: 2,
    FourUpInside: 3,
    FourUpOutside: 4,
    FourUpPanel1: 5,
    FourUpPanel2: 6,
    FourUpPanel3: 7,
    FourUpPanel4: 8
  };

  PanelBuilder.prototype.generateGroup = function() {
    switch (this.layout) {
      case PanelBuilder.Layouts.FourUpInside:
        this.buildPanel({ panels: 1 });
        this.buildPanel({ panels: 2, stories: 4 });
        this.buildPanel({ panels: 1 });
        break;
      case PanelBuilder.Layouts.FourUpOutside:
        this.buildPanel({ panels: 1, stories: 4 });
        this.buildPanel({ panels: 2 });
        this.buildPanel({ panels: 1, stories: 4 });
        break;
      case PanelBuilder.Layouts.FourUpPanel1:
        this.buildPanel({ panels: 1, stories: 4 });
        this.buildPanel({ panels: 3 });
        break;
      case PanelBuilder.Layouts.FourUpPanel2:
        this.buildPanel({ panels: 1 });
        this.buildPanel({ panels: 1, stories: 4 });
        this.buildPanel({ panels: 2 });
        break;
      case PanelBuilder.Layouts.FourUpPanel3:
        this.buildPanel({ panels: 2 });
        this.buildPanel({ panels: 1, stories: 4 });
        this.buildPanel({ panels: 1 });
        break;
      case PanelBuilder.Layouts.FourUpPanel4:
        this.buildPanel({ panels: 3 });
        this.buildPanel({ panels: 1, stories: 4 });
        break;
      case PanelBuilder.Layouts.FourUpNone:
        this.buildPanel({ panels: 4 });
        break;
      default:
        break;
    }
  };

  PanelBuilder.prototype.buildPanel = function(options) {
    options = _.defaults(options, { panels: 1, stories: 1 });

    for (var i = 0; i < options.panels; i++) {
      if (this.stories.length > options.stories - 1) {
        this.panelGroup.push({
          class: "grid-" + options.stories,
          stories: this.stories.splice(0, options.stories)
        });
      } else {
        // Not enough stories to continue. Stop. All. The. Things.
        break;
      }
    }
  };

  PanelBuilder.linkifyTitle = function(title, elements) {
    var result = title;
    if (!result || !elements) {
      //return the original result as to be non-destructive (undefined vs null vs false).
      return result;
    }

    _.forOwn(elements, function(e) {
      if (e.text) {
        //Need to escape any meaningful regex characters. eg (, ), [, ]
        var re = new RegExp(
          "(" +
            e.text.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&") +
            ")(?!</a>)"
        );

        //Create the link using the cleansed expression.
        result = result.replace(
          re,
          '<a href="' + e.link + '" class="on-navigate">$1</a>'
        );
      }
    });

    return new Handlebars.SafeString(result);
  };
  // endregion

  utils.PanelBuilder = PanelBuilder;
})(window, _, wattpad.utils);
