var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<nav>\n  <ul>\n    <li data-section=\"browse\" class=\"tab-control"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.onBrowse : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      <a href=\"/home/browse\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    </li>\n    <li data-section=\"home\" class=\"tab-control"
    + ((stack1 = helpers.unless.call(alias1,(depth0 != null ? depth0.onBrowse : depth0),{"name":"unless","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\">\n      <a href=\"/home\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Recommended",{"name":"trans","hash":{},"data":data}))
    + "</a>\n    </li>\n  </ul>\n</nav>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return " active";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isMobile : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n<main id=\"home-content\">\n  <div class=\"load-container\">\n    "
    + ((stack1 = (helpers.iconifySpin || (depth0 && depth0.iconifySpin) || helpers.helperMissing).call(alias1,"fa-loading",49,(depth0 != null ? depth0["user-device"] : depth0),{"name":"iconifySpin","hash":{"color":"wp-neutral-3"},"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</main>\n";
},"useData":true});