var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression, alias4="function";

  return "    <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,(depth0 != null ? depth0.url : depth0),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"panel story on-navigate\">\n        <div class=\"cover cover-md pull-left\">\n            <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.cover : depth0),123,{"name":"resizeCover","hash":{},"data":data}))
    + "\">\n        </div>\n        <div class=\"content\">\n            <h4 class=\"h3\">"
    + alias3(((helper = (helper = helpers.title || (depth0 != null ? depth0.title : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"title","hash":{},"data":data}) : helper)))
    + "</h4>\n            <small>"
    + alias3(container.lambda(((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1), depth0))
    + " &middot; "
    + alias3((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Part","%s Parts",(depth0 != null ? depth0.numParts : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numParts : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</small>\n            <div class=\"meta\">\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"if","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n                "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n            </div>\n            <p>"
    + alias3((helpers.simpleShorten || (depth0 && depth0.simpleShorten) || alias2).call(alias1,(depth0 != null ? depth0.description : depth0),140,false,{"name":"simpleShorten","hash":{},"data":data}))
    + "</p>\n        </div>\n    </a>\n\n    <button class=\"btn btn-triangle btn-library\" data-icon=\"true\" data-story-id=\""
    + alias3(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias4 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",16,"wp-neutral-2",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <span class=\"toggle-triangle\"></span>\n        <span class=\"toggle-shadow\"></span>\n    </button>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<small class=\"reads\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-view",16,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.readCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return "<small class=\"votes\">"
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-vote",16,"wp-neutral-3",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + " "
    + container.escapeExpression((helpers.count || (depth0 && depth0.count) || alias2).call(alias1,(depth0 != null ? depth0.voteCount : depth0),{"name":"count","hash":{},"data":data}))
    + "</small>";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "<header class=\"panel-heading\">\n    <a class=\"send-avatar-event avatar avatar-sm2 on-navigate\" href=\"/user/wattpad\">\n      <img src=\""
    + alias3((helpers.resizeAvatar || (depth0 && depth0.resizeAvatar) || alias2).call(alias1,(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"%s/useravatar/Wattpad.128.263229.jpg",(depth0 != null ? depth0.mediaServer : depth0),{"name":"sprintf","hash":{},"data":data}),36,{"name":"resizeAvatar","hash":{},"data":data}))
    + "\">\n    </a>\n    <h3 class=\"h6\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"wattpad recommends...",{"name":"trans","hash":{},"data":data}))
    + "</h3>\n"
    + ((stack1 = container.invokePartial(partials["core.feed_items.feed_item_options"],depth0,{"name":"core.feed_items.feed_item_options","data":data,"indent":"    ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</header>\n\n<div class=\"panel-body\">\n\n"
    + ((stack1 = helpers.blockHelperMissing.call(depth0,container.lambda(((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1), depth0),{"name":"data.story","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n</div>\n\n<footer class=\"panel-footer\">\n    <a href=\""
    + alias3((helpers.formatStoryUrl || (depth0 && depth0.formatStoryUrl) || alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? depth0.data : depth0)) != null ? stack1.story : stack1)) != null ? stack1.url : stack1),{"name":"formatStoryUrl","hash":{},"data":data}))
    + "\" class=\"btn btn-text on-navigate\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Story details",{"name":"trans","hash":{},"data":data}))
    + "</a>\n</footer>\n";
},"usePartial":true,"useData":true});