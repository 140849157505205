var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"reading-list-dropdown\">\n    <div class=\"triangle\"></div>\n    <div class=\"dropdown-menu dropdown-menu-right add-to-library\">\n        <ul class=\"lists-menu\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></ul>\n        <div class=\"input-group\">\n            <label for=\"new-reading-list\" class=\"sr-only\">New Reading List</label>\n            <input id=\"new-reading-list\" type=\"text\" class=\"form-control list-name list-error-placeholder\" placeholder=\"Add New Reading List\">\n        <span class=\"input-group-btn\">\n            <button class=\"btn btn-orange on-create-list\" type=\"button\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n                +\n            </button>\n        </span>\n        </div>\n    </div>\n</div>\n";
},"useData":true});