var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"dropdown-menu new-curation large header-list\" aria-labelledby=\"browse-dropdown\">\n    <div class=\"col-xs-9 discover-categories\">\n      <ul class=\"row\">\n        <li class=\"title col-xs-12\" aria-label=\"Browse Topics\">"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + "</li>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.browseTopics : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.showWattpadPicks : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "  </div>\n";
},"2":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "          <li class=\"col-xs-4\">\n              <a "
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.browseURL : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.program(5, data, 0),"data":data})) != null ? stack1 : "")
    + " class=\"on-navigate on-topic\">"
    + container.escapeExpression(((helper = (helper = helpers.topic || (depth0 != null ? depth0.topic : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"topic","hash":{},"data":data}) : helper)))
    + "</a>\n          </li>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var helper;

  return "href=\""
    + container.escapeExpression(((helper = (helper = helpers.browseURL || (depth0 != null ? depth0.browseURL : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"browseURL","hash":{},"data":data}) : helper)))
    + "\"";
},"5":function(container,depth0,helpers,partials,data) {
    return "href=\""
    + container.escapeExpression((helpers.categoryNameToUrl || (depth0 && depth0.categoryNameToUrl) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),{"name":"categoryNameToUrl","hash":{},"data":data}))
    + "\"";
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "      <div class=\"col-xs-3 wattpad-picks\">\n        <ul class=\"row\" aria-label=\"Wattpad Picks\">\n          <li class=\"title col-xs-12\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Wattpad Picks",{"name":"trans","hash":{},"data":data}))
    + "</li>\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.newCurationLists : depth0),{"name":"each","hash":{},"fn":container.program(8, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "          <li class=\"col-xs-12\">\n            <a href=\"/catalog/premiumpicks\" class=\"on-navigate\">"
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Premium Picks",{"name":"trans","hash":{},"data":data}))
    + "</a>\n          </li>\n      </ul>\n      </div>\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "            <li class=\"col-xs-12\">\n              <a href=\""
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.path : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.program(11, data, 0),"data":data})) != null ? stack1 : "")
    + "\" >"
    + container.escapeExpression(((helper = (helper = helpers.name || (depth0 != null ? depth0.name : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(alias1,{"name":"name","hash":{},"data":data}) : helper)))
    + "</a>\n            </li>\n";
},"9":function(container,depth0,helpers,partials,data) {
    var helper;

  return container.escapeExpression(((helper = (helper = helpers.path || (depth0 != null ? depth0.path : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"path","hash":{},"data":data}) : helper)));
},"11":function(container,depth0,helpers,partials,data) {
    var helper;

  return "/featured/"
    + container.escapeExpression(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : helpers.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"id","hash":{},"data":data}) : helper)));
},"13":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {});

  return "  <div class=\"dropdown-menu large\" aria-labelledby=\"browse-dropdown\">\n    <div class=\"col-xs-3\">\n      <ul class=\"bold\" aria-label=\"Browse Topics\">\n        <li>"
    + container.escapeExpression((helpers.trans || (depth0 && depth0.trans) || helpers.helperMissing).call(alias1,"Browse",{"name":"trans","hash":{},"data":data}))
    + "</li>\n      </ul>\n    </div>\n\n    <div class=\"col-xs-9 discover-categories\">\n      <ul class=\"row\">\n"
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.browseTopics : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n  </div>\n";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return "<div class=\"triangle\"></div>\n\n"
    + ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.newCurationLists : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(13, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});