var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "<div class=\"triangle\"></div>\n<div class=\"dropdown-menu dropdown-menu-right add-to-library\">\n    <ul class=\"lists-menu\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\"></ul>\n    <div class=\"inputs\">\n        <label for=\"new-reading-list\" class=\"sr-only\">"
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"New Reading List",{"name":"trans","hash":{},"data":data}))
    + "</label>\n        "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-warning",12,"wp-errorbackground error-feedback",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        <input id=\"new-reading-list\" type=\"text\" class=\"form-control list-name list-error-placeholder\"\n               placeholder=\""
    + alias4((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"Add new reading list...",{"name":"trans","hash":{},"data":data}))
    + "\">\n        <button class=\"btn btn-orange on-create-list\" type=\"button\" data-story-id=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n          "
    + ((stack1 = (helpers.iconify || (depth0 && depth0.iconify) || alias2).call(alias1,"fa-plus",9,"wp-neutral-5",{"name":"iconify","hash":{},"data":data})) != null ? stack1 : "")
    + "\n        </button>\n    </div>\n</div>\n";
},"useData":true});