var Handlebars = require("handlebars/runtime");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "<a class=\"cover cover-md empty-cover\"><div class=\"fixed-ratio fixed-ratio-cover\"></div></a>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3="function", alias4=container.escapeExpression;

  return "\n<a class=\"cover cover-home on-navigate\" data-target=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\" href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">\n  <div class=\"fixed-ratio fixed-ratio-lists\">\n"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(4, data, 0),"inverse":container.program(6, data, 0),"data":data})) != null ? stack1 : "")
    + "  </div>\n</a>\n\n<div class=\"content\" data-target=\""
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\" data-type=\""
    + alias4(((helper = (helper = helpers.type || (depth0 != null ? depth0.type : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"type","hash":{},"data":data}) : helper)))
    + "\">\n  <a class=\"title meta on-navigate\" href=\"/list/"
    + alias4(((helper = (helper = helpers.id || (depth0 != null ? depth0.id : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"id","hash":{},"data":data}) : helper)))
    + "\">"
    + ((stack1 = helpers["if"].call(alias1,(depth0 != null ? depth0.isDesktop : depth0),{"name":"if","hash":{},"fn":container.program(8, data, 0),"inverse":container.program(10, data, 0),"data":data})) != null ? stack1 : "")
    + "</a>\n  <div class=\"num-stories meta\">"
    + alias4((helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,(helpers.ngettext || (depth0 && depth0.ngettext) || alias2).call(alias1,"%s Story","%s Stories",(depth0 != null ? depth0.numStories : depth0),{"name":"ngettext","hash":{},"data":data}),(depth0 != null ? depth0.numStories : depth0),{"name":"sprintf","hash":{},"data":data}))
    + "</div>\n  <div class=\"tags meta\">\n    "
    + ((stack1 = helpers.each.call(alias1,(depth0 != null ? depth0.tags : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0),"inverse":container.noop,"data":data})) != null ? stack1 : "")
    + "\n  </div>\n</div>\n\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.background : depth0),142,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.name : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing, alias3=container.escapeExpression;

  return "    <img src=\""
    + alias3((helpers.resizeCover || (depth0 && depth0.resizeCover) || alias2).call(alias1,(depth0 != null ? depth0.background : depth0),92,{"name":"resizeCover","hash":{},"data":data}))
    + "\" alt=\""
    + alias3((helpers.trans || (depth0 && depth0.trans) || alias2).call(alias1,"%1$s by %2$s",(depth0 != null ? depth0.name : depth0),((stack1 = (depth0 != null ? depth0.user : depth0)) != null ? stack1.name : stack1),{"name":"trans","hash":{},"data":data}))
    + "\">\n";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),35,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = (helpers.simpleShorten || (depth0 && depth0.simpleShorten) || helpers.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.name : depth0),28,false,{"name":"simpleShorten","hash":{},"data":data})) != null ? stack1 : "");
},"12":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=helpers.helperMissing;

  return " "
    + ((stack1 = (helpers.linkify || (depth0 && depth0.linkify) || alias2).call(alias1,(helpers.sprintf || (depth0 && depth0.sprintf) || alias2).call(alias1,"#%s",depth0,{"name":"sprintf","hash":{},"data":data}),{"name":"linkify","hash":{},"data":data})) != null ? stack1 : "")
    + " ";
},"compiler":[7,">= 4.0.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1;

  return ((stack1 = helpers["if"].call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? depth0.empty : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data})) != null ? stack1 : "");
},"useData":true});